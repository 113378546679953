import { DASHBOARD_VIEWER, DELPHI_ADMINISTRATOR, } from "../constants/permissionConstants";
export const hasDashboardViewerPermission = (roles, selectedRole) => {
    return getPermssionByRoleId(roles, selectedRole).includes(DASHBOARD_VIEWER);
};
const getPermssionByRoleId = (roles, selectedRole) => {
    var _a, _b;
    return (_b = (_a = roles === null || roles === void 0 ? void 0 : roles.find(({ id }) => id === selectedRole)) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.map(({ code }) => code);
};
export const hasDelphieorSuperPermissions = (roles, selectedRole) => {
    var _a, _b;
    const selectedRolePermissions = (_b = (_a = roles === null || roles === void 0 ? void 0 : roles.find(({ id }) => id === selectedRole)) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.map(({ code }) => code);
    const hasPermissions = selectedRolePermissions === null || selectedRolePermissions === void 0 ? void 0 : selectedRolePermissions.includes(DELPHI_ADMINISTRATOR);
    return hasPermissions;
};
