import { connect } from "react-redux";
import React, { useEffect } from "react";
import SingleSelectNew from "./SingleSelectNew";
const GroupDropdownContainer = ({ groupOptions, onChange, selectedValue, defaultValue, className, error = null, isDisable = false, allowClear = false, allowNAItem = false, placeHolder, labelInValue, }) => {
    useEffect(() => {
        if (allowNAItem) {
            for (const group of groupOptions) {
                let companies = group.listSimpleDataDTO;
                if (!companies.some((company) => company.id === -1)) {
                    companies.push({ id: -1, name: 'N/A', listSimpleDataDTO: [] });
                }
                for (const company of companies) {
                    let sites = company.listSimpleDataDTO;
                    if (!sites.some((site) => site.id === -1)) {
                        sites.push({ id: -1, name: 'N/A' });
                    }
                }
            }
        }
    }, [allowNAItem, groupOptions]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { id: "group-filter-dropdown", options: groupOptions, selectedValue: selectedValue, onChange: onChange, defaultValue: defaultValue, disabled: groupOptions.length === 1 || isDisable, placeholder: placeHolder !== null && placeHolder !== void 0 ? placeHolder : "Select a Group", key: "groupSelect", valueProperty: "id", textProperty: "name", error: error, className: className, labelInValue: labelInValue, allowClear: allowClear })));
};
function mapStateTopProps(state, ownProps) {
    return {
        groupOptions: state.groupOptions,
        onChange: ownProps.onChange,
        selectedValue: ownProps.selectedValue,
    };
}
export default connect(mapStateTopProps, null)(GroupDropdownContainer);
