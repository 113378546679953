export var UserStatus;
(function (UserStatus) {
    UserStatus[UserStatus["INACTIVE"] = 0] = "INACTIVE";
    UserStatus[UserStatus["ACTIVE"] = 1] = "ACTIVE";
    UserStatus[UserStatus["LOCK"] = 2] = "LOCK";
})(UserStatus || (UserStatus = {}));
export var UserType;
(function (UserType) {
    UserType[UserType["Internal"] = 1] = "Internal";
    UserType[UserType["External"] = 2] = "External";
})(UserType || (UserType = {}));
