import { Card } from "antd";
import React from "react";
import { formatValueByConf } from "../../../../../../../../core/helpers/chartYAxisLabel";
import isEmpty from "../../../../../../../../core/helpers/isEmpty";
import "./styles.scss";
const WatchListRatingsCard = ({ headerTitle, wrapperClass, status = "X", statusRgbColor = "lightgray", statusMA, valueType, footerText = undefined, decimalPlaces, }) => {
    return (React.createElement("div", { className: `watchListCardContainerClass ${wrapperClass}` },
        React.createElement("h6", null, headerTitle),
        React.createElement(Card, { className: "ratingCard watchlistCard", style: {
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
            } },
            React.createElement("p", { className: "watchlistStatus" }, status),
            React.createElement("p", { className: "watchlistRating", style: {
                    backgroundColor: statusRgbColor,
                } }, statusMA
                ? `${formatValueByConf(statusMA, valueType, decimalPlaces)}`
                : "-")),
        React.createElement("p", { className: "watchlistDays align-center", hidden: isEmpty(footerText) }, footerText)));
};
export default WatchListRatingsCard;
