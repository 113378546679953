import React from "react";
import isEmpty from "../../../../../core/helpers/isEmpty";
export function ReportsListingColumns(responseColumns, isSummary = false, actions = null, reportType = null, isPrintMode = false, titleKey = null, timePeriod = null, getDataSheet) {
    var _a;
    const renderColumnValue = (index, value, record) => {
        var _a;
        if (index === 0) {
            let formattedValue = value;
            // if (record?.flag) formattedValue = `${value} (in 000s)`;
            if ((record === null || record === void 0 ? void 0 : record.chartUnit) === 1000) {
                formattedValue = `${value} (in K)`;
            }
            else if ((record === null || record === void 0 ? void 0 : record.chartUnit) === 1000000) {
                formattedValue = `${value} (in M)`;
            }
            return formattedValue;
        }
        return (React.createElement("span", { style: Object.assign({}, (!isEmpty(value) && value[0] === "-" && { color: "red" })) }, !isEmpty(value) && value[0] === "-"
            ? `(${(_a = String(value)) === null || _a === void 0 ? void 0 : _a.slice(1)})`
            : value));
    };
    const setWidth = (index) => {
        return !isPrintMode ? (index === 0 ? 360 : 110) : index === 0 ? 260 : 75;
    };
    return ((_a = responseColumns === null || responseColumns === void 0 ? void 0 : responseColumns.map((colName, index) => {
        const fieldName = Object.keys(colName)[0] === "monthNumber"
            ? Object.keys(colName)[1]
            : Object.keys(colName)[0];
        return {
            fixed: index === 0 ? true : false,
            width: setWidth(index),
            columnWidth: setWidth(index),
            minWidth: setWidth(index),
            maxWidth: setWidth(index),
            fieldName,
            title: index > 0 && (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { display: "block", fontSize: "11px" } },
                    "Month ",
                    colName["monthNumber"]),
                React.createElement("span", null, colName[fieldName]),
                React.createElement("span", { hidden: titleKey === "summarySheetRatio" ? true : false, style: { display: "block", fontWeight: "lighter" } }, "'000'"))),
            render: (value, record) => (React.createElement(React.Fragment, null,
                React.createElement("span", { className: `reportColumnValue ${index !== 0 &&
                        (record === null || record === void 0 ? void 0 : record.underline) &&
                        !isEmpty(value) &&
                        `netValue`} ${(record === null || record === void 0 ? void 0 : record.bold) && "boldRow"}` }, renderColumnValue(index, value, record)),
                React.createElement("span", { className: `reports-chart-icon-${record === null || record === void 0 ? void 0 : record.type}`, id: !(record === null || record === void 0 ? void 0 : record.category) &&
                        // isSummary &&
                        (record === null || record === void 0 ? void 0 : record.type) === reportType &&
                        "selectedRowId" }, isSummary &&
                    actions &&
                    index === 0 &&
                    !(record === null || record === void 0 ? void 0 : record.category) &&
                    actions(record, value)))),
        };
    })) !== null && _a !== void 0 ? _a : []);
}
