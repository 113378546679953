import { StarOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState, useContext } from "react";
import { DASHBOARD_LISTINGS_DESCRIPTION } from "../../../config/constants";
import { dashboardRoute, watchlistRoute, } from "../../../core/constants/routesPath";
import { clearDashboardSession, getButtonTextKey, getBreadCrumbSession, } from "../../../core/helpers/localStorage/breadCrumbSession";
import localize from "../../../core/utils/translation-files";
import BreadcrumbNavigation from "../breadCrumbNavigation";
import { browserHistoryMemoryContext } from "../../../core/contexts/browserHistoryMemoryContext";
import PageDescription from "../common/pageDescription";
import TableActions from "../customTable/tableActions";
import RenderSvg from "../icons/RenderSvg";
import HistoryColumnDropdown from "../select/HIstoryColumnDropdown";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../core/constants/SessionVariables";
// import useDashboardHierarchy from "../../../core/hooks/useDashboardHeirarchy";
import CurrentFinancialRatingBox from "../../../views/internal/shared/dashboard/DashboardRoadmap/components/currentFinancialRatingBox";
const CustomPageHeader = ({ setScaleModelVisibility = null, frequencyList = null, selectedFrequency = null, setSelectedFrequency = null, handlePrint = null, descriptionText = null, pageHeading = null, itemName = null, currentRatingData = null, data = null, isHoldingCompany = false,
// setSelectedCategory = null,
 }) => {
    var _a;
    // const history = useHistory();
    const [buttonText, setButtonText] = useState(getButtonTextKey);
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    // const {
    //   isSinglSite,
    //   isSingleCompany,
    //   // isSingleCompanyAndSite,
    //   // CompanyByGroup,
    //   // SiteByCompany,
    // } = useDashboardHierarchy();
    const updateButtonText = () => {
        var _a;
        const ids = (_a = getBreadCrumbSession()) === null || _a === void 0 ? void 0 : _a.map((item) => parseInt(item.key));
        const level = SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_LEVEL, sessionStorage);
        if (getButtonTextKey() === "Watchlist" &&
            ids[ids.length - 1] !==
                SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage) &&
            level === null) {
            SessionStorage.setKey(SessionVariables.BUTTON_TEXT, "Overview", sessionStorage);
            SessionStorage.removeKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage);
            setButtonText("Overview");
            SessionStorage.setKey(SessionVariables.EXPANDED_KEYS, ids === null || ids === void 0 ? void 0 : ids.slice(0, -1), sessionStorage);
        }
        if (level !== "Company" && level !== "Group") {
            SessionStorage.removeKey(SessionVariables.WATCHLIST_ITEM_LEVEL, sessionStorage);
        }
    };
    const headerButtons = () => {
        const btnList = [
            {
                svgIcon: "Print",
                onClick: handlePrint,
                priority: 1,
                title: ["print"],
                renderSvgButton: true,
                // iconStyle: { color: "#6e6b7b" },
            },
            {
                btnType: "primary",
                onClick: (x) => setScaleModelVisibility(true),
                priority: 2,
                title: ["Ratings"],
                iconComponent: React.createElement(StarOutlined, { style: { fontSize: "16px" } }),
            },
        ];
        return (React.createElement(TableActions, { actionArray: btnList, wrapperClass: "dottedActionsButton watchlistActions", dropdownClassName: "actionsDropdown dropdown-action-container", ShowActionText: false, menuItems: true }));
    };
    const { title, description } = DASHBOARD_LISTINGS_DESCRIPTION !== null && DASHBOARD_LISTINGS_DESCRIPTION !== void 0 ? DASHBOARD_LISTINGS_DESCRIPTION : {};
    return (React.createElement("header", { className: "customHeader dashboardHeader" },
        React.createElement("span", { className: "dashboardTitleContainer", style: { display: "inline-flex", alignItems: "center" } },
            React.createElement(RenderSvg, { icon: "HomeAlt", style: { marginLeft: "0px", marginRight: "7px", marginTop: "-2px" } }),
            buttonText && (React.createElement(Button, { style: { right: "0px", marginTop: "-2px", position: "absolute" }, type: "primary", onClick: () => {
                    clearDashboardSession();
                    if (buttonText === "Overview") {
                        browserHistory.push(dashboardRoute);
                    }
                    else if (buttonText === "Watchlist") {
                        browserHistory.push(watchlistRoute);
                    }
                } },
                buttonText === "Watchlist" && updateButtonText(),
                buttonText === "Overview"
                    ? "Back To Overview"
                    : "Back To Watchlist")),
            React.createElement("h5", null, (_a = localize(pageHeading)) !== null && _a !== void 0 ? _a : "")),
        React.createElement(BreadcrumbNavigation, { description: descriptionText }),
        React.createElement(PageDescription, { title: title, description: descriptionText !== null && descriptionText !== void 0 ? descriptionText : description }),
        React.createElement("div", { className: "header-action-btns" },
            React.createElement("h5", null, itemName),
            currentRatingData && isHoldingCompany && (React.createElement(CurrentFinancialRatingBox, { record: data, financialData: currentRatingData })),
            setScaleModelVisibility && (React.createElement("div", { className: "ml-auto d-flex" },
                React.createElement("div", { className: "dashboardDropdown" },
                    React.createElement("span", null, "Historical Performance Period"),
                    React.createElement(HistoryColumnDropdown, { frequencyList: frequencyList, selectedFrequency: selectedFrequency, setSelectedFrequency: setSelectedFrequency })),
                React.createElement("div", { className: "d-print-none" }, headerButtons()))))));
};
export default CustomPageHeader;
