import { httpGet, httpPost } from "./request/requestApi";
import isEmpty from "../helpers/isEmpty";
const controllerEndpoint = "watchlist";
export class WatchlistApi {
    static createWatchList(name) {
        return httpPost(`${controllerEndpoint}/createWatchlist/${name}`);
    }
    static deleteWatchList(watchlistId) {
        return httpPost(`${controllerEndpoint}/deleteWatchlist/${watchlistId}`);
    }
    static getWatchlist(watchlistId, sort, pageNo = 1, pageSize = 6, area = null, abortSignal = undefined, body) {
        return httpPost(`${controllerEndpoint}/getWatchlist/${pageNo}/${pageSize}/${sort !== null && sort !== void 0 ? sort : 4}/${watchlistId}`, {
            silent: !isEmpty(area),
            area,
            abortSignal,
            body
        });
    }
    static getWatchlistExpandedData(trackedItemId, frequency, area = null) {
        return httpGet(`${controllerEndpoint}/getWatchlistDataExpanded/${trackedItemId}/${frequency}`, {
            silent: !isEmpty(area),
            area
        });
    }
    static getUserWatchList(silent = true) {
        return httpGet(`${controllerEndpoint}/getUserWatchlists`, {
            silent: silent,
        });
    }
    static getWatchListByTrackItemId(trackItemId) {
        return httpGet(`${controllerEndpoint}/getWatchlistByReportId/${trackItemId}`);
    }
    static addToWatchlist(reportId, watchlistId) {
        // reportId: number,
        // companyId: number = -1,
        // onClick: boolean = true,
        // userName: string = null,
        // watchlistName: string = null
        return httpPost(`${controllerEndpoint}/addToWatchlist/${reportId}/${-1}/${true}/${null}/${watchlistId}`);
    }
    static renameWatchlist(watchlistName, watchlistId) {
        return httpPost(`${controllerEndpoint}/updateWatchlistName/${watchlistId}/${watchlistName}`);
    }
    static removeFromWatchlist(reportId, watchlistId) {
        return httpPost(`${controllerEndpoint}/removeFromWatchlist/${reportId}/${watchlistId}`);
    }
}
