import { GET_GROUP_HIERARCHY } from "./types";
import { DashboardApi } from "../../core/api/dashboardApi";
export const getGroupHierarchySuccess = (data) => ({
    type: GET_GROUP_HIERARCHY,
    payload: data,
});
export function getGroupHierarchy() {
    return function (dispatch) {
        return DashboardApi.getGroupsHierarchy()
            .then((response) => {
            dispatch(getGroupHierarchySuccess(response));
            return response;
        })
            .catch((error) => {
            return error;
        });
    };
}
