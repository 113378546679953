import React from "react";
import LookupForm from "./lookupForm";
import CustomModal from "../../../../../layout/components/customModal";
function AddLookup({ visible, onClose, onSubmit, valuesRef, lookup }) {
    return (React.createElement("div", { "data-test": "addLookupComponent" },
        React.createElement(CustomModal, { visible: visible, maskClosable: false, onCancel: onClose, destroyOnClose: true, footer: null, title: ["add", "Lookup.lookupValue"] },
            React.createElement("div", { "data-test": "lookupFormComponent" },
                React.createElement(LookupForm, { onSubmit: onSubmit, onCancel: onClose, lookup: lookup, isEdit: false, valuesRef: valuesRef })))));
}
export default AddLookup;
