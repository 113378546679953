import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DashboardApi } from "../../../../../core/api/dashboardApi";
import { TrackedItemApi } from "../../../../../core/api/trackedItemApi";
import { defaultHistoryFrequencyFilter } from "../../../../../core/constants/historyFrequencyFilter";
import { LookupNames } from "../../../../../core/constants/lookup";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { browserHistoryMemoryContext } from "../../../../../core/contexts/browserHistoryMemoryContext";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import useDidMount from "../../../../../core/hooks/useDidMount";
import UsePersistCustomFilters from "../../../../../core/hooks/usePersistCustomFilters";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import CustomPageHeader from "../../../../../layout/components/customPageHeader";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import { getLookups } from "../../../../../redux/lookup/actions";
import TrackedItemBandScaleContainer from "../../charts/line-chart/movingAverageAndProjection/trackedItemBandScale/trackedItemBandScaleContainer";
import OverallStatusPanels from "../components/OverallStatusPanels";
import RatingCount from "../components/RatingCount";
import RelatedNotificationsPopup from "../components/relatedNotificationsPopup";
import AddRemoveWatchListModal from "../DashboardRoadmap/components/addRemoveWatchList";
import UniversalRating from "./UniversalRating/index";
import { getBreadCrumbSession, handleSetRoadMapItemWatchListStatus, } from "../../../../../core/helpers/localStorage/breadCrumbSession";
const { setKey, getKey, removeKey } = SessionStorage;
const { QUERY_PARAMS, SELECTED_TRACKED_ITEM_DASHBOARD_RATING } = SessionVariables;
function TrackedItemsDashboard({ getLookups, historyFrequency, printInstance, }) {
    var _a, _b, _c, _d, _e, _f, _g;
    // const { TabPane } = Tabs;
    const [bandScales, setBandScales] = useState([]);
    const [data, setData] = useState(undefined);
    // const [selectedTab, setSelectedTab] = useState(
    //   getKey(SELECTED_TRACKED_ITEM_DASHBOARD_RATING) ?? "1"
    // );
    const [scaleModelVisibility, setScaleModelVisibility] = useState(false);
    const shouldUpdate = useRef(true);
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedWatchlistModalItem, setWatchListModalItem] = useState({});
    const [notificationModelVisibility, setNotificationModelVisibility] = useState(false);
    const overallNotificationPopup = useRef(false);
    const handleToggleWatchListModal = (param) => {
        setWatchListModalItem((prev) => ((param === null || param === void 0 ? void 0 : param.itemId) ? param : {}));
    };
    const openNotificationModal = (item, isOverall = false) => {
        if (isOverall) {
            overallNotificationPopup.current = true;
        }
        setNotificationModelVisibility(true);
        setSelectedItem(item);
    };
    const closeNotificationModal = () => {
        if (overallNotificationPopup.current) {
            overallNotificationPopup.current = false;
        }
        setNotificationModelVisibility(false);
        setSelectedItem({});
    };
    const history = useHistory();
    const params = useParams();
    const userContext = getKey(SessionVariables.USER_CONTEXT);
    const userHistoryFrequencyFilter = isEmpty(userContext === null || userContext === void 0 ? void 0 : userContext.lastVisitedContext)
        ? defaultHistoryFrequencyFilter
        : (_b = JSON.parse((_a = JSON.parse(userContext === null || userContext === void 0 ? void 0 : userContext.lastVisitedContext)) === null || _a === void 0 ? void 0 : _a.text)) === null || _b === void 0 ? void 0 : _b.selectedFilter;
    const { filters: selectedFrequency, setFilters: setSelectedFrequency } = UsePersistCustomFilters({
        unmountContextRemoval: false,
        defaultValue: userHistoryFrequencyFilter,
    });
    const [trackedItemsOverallRating, setTrackedItemsOverallRating] = useState(undefined);
    const [ratingCount, setRatingCount] = useState(undefined);
    // const handleChange = (activeKey) => {
    //   ////Reset search params on tab change///////
    //   !isEmpty(history.location.search) && history.push({ search: "" });
    //   // SessionStorage.removeKey(SessionVariables.TrackedItem_DASHBOARD_ALL_RECORD);
    //   removeKey(QUERY_PARAMS, sessionStorage);
    //   setSelectedTab(activeKey);
    //   setKey(SELECTED_TRACKED_ITEM_DASHBOARD_RATING, activeKey);
    // };
    const closeScaleModal = () => setScaleModelVisibility(false);
    useDidMount(() => {
        TrackedItemApi.getTrackedItemScale().then((res) => {
            setBandScales(res);
        });
        getLookupByListing({
            LookupNames: [LookupNames.LKReportHistoryFrequency],
            getLookups,
        });
    });
    useEffect(() => {
        window.addEventListener("beforeunload", (event) => {
            setKey(QUERY_PARAMS, history.location.search, sessionStorage);
        });
    });
    useEffect(() => {
        if (shouldUpdate.current && userHistoryFrequencyFilter) {
            setSelectedFrequency(userHistoryFrequencyFilter);
            shouldUpdate.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldUpdate, userHistoryFrequencyFilter]);
    useEffect(() => {
        var _a, _b, _c, _d;
        if (!selectedFrequency && historyFrequency.length > 0) {
            setSelectedFrequency({
                code: (_a = historyFrequency[0]) === null || _a === void 0 ? void 0 : _a.code,
                text: (_b = historyFrequency[0]) === null || _b === void 0 ? void 0 : _b.text,
            });
        }
        if (selectedFrequency && params.category_id) {
            DashboardApi.getTrackedItemsOverallRating(+(params === null || params === void 0 ? void 0 : params.site_id) === 0
                ? (_d = (_c = getBreadCrumbSession()) === null || _c === void 0 ? void 0 : _c.at(-1)) === null || _d === void 0 ? void 0 : _d.companyId
                : params === null || params === void 0 ? void 0 : params.site_id, params === null || params === void 0 ? void 0 : params.category_id, params === null || params === void 0 ? void 0 : params.sub_cat_id, +(selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code)).then((res) => {
                var _a;
                setTrackedItemsOverallRating(res);
                if (!(ratingCount === null || ratingCount === void 0 ? void 0 : ratingCount.length)) {
                    DashboardApi.getRatingCount((_a = res === null || res === void 0 ? void 0 : res.ratingData) === null || _a === void 0 ? void 0 : _a.itemId, params === null || params === void 0 ? void 0 : params.group_id, (params === null || params === void 0 ? void 0 : params.comp_id) !== "0" && (params === null || params === void 0 ? void 0 : params.comp_id) !== "null"
                        ? (params === null || params === void 0 ? void 0 : params.comp_id) || -1
                        : -1, (params === null || params === void 0 ? void 0 : params.site_id) !== "0" && (params === null || params === void 0 ? void 0 : params.site_id) !== "null"
                        ? (params === null || params === void 0 ? void 0 : params.site_id) || -1
                        : -1, params === null || params === void 0 ? void 0 : params.category_id, params === null || params === void 0 ? void 0 : params.sub_cat_id).then((res) => {
                        setRatingCount(res);
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFrequency, historyFrequency]);
    const paginationProps = UseCustomPagination({
        totalRecords: (_d = (_c = data === null || data === void 0 ? void 0 : data.universalTabList) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0,
        clientPagination: true,
    });
    useEffect(() => {
        return () => {
            removeKey(QUERY_PARAMS, sessionStorage);
            removeKey(SELECTED_TRACKED_ITEM_DASHBOARD_RATING);
        };
    }, []);
    const { nodeRef, handlePrint } = UsePrintPage();
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(CustomPageHeader, { setScaleModelVisibility: setScaleModelVisibility, frequencyList: historyFrequency, selectedFrequency: selectedFrequency, setSelectedFrequency: setSelectedFrequency, handlePrint: handlePrint, pageHeading: ["Dashboard.subCategoryDashboard"], itemName: (_f = (_e = trackedItemsOverallRating === null || trackedItemsOverallRating === void 0 ? void 0 : trackedItemsOverallRating.ratingData) === null || _e === void 0 ? void 0 : _e.itemName) !== null && _f !== void 0 ? _f : "" }),
        React.createElement(TrackedItemBandScaleContainer, { subCategory: params === null || params === void 0 ? void 0 : params.category_id, visible: scaleModelVisibility, onClose: closeScaleModal, dashboardBandScale: bandScales, isDashboard: true }),
        React.createElement(OverallStatusPanels, { data: (_g = trackedItemsOverallRating === null || trackedItemsOverallRating === void 0 ? void 0 : trackedItemsOverallRating.ratingData) !== null && _g !== void 0 ? _g : [], selectedFrequencyLabel: selectedFrequency, openNotificationModal: openNotificationModal, showAllCards: false }),
        React.createElement(RatingCount, { grades: bandScales, data: ratingCount !== null && ratingCount !== void 0 ? ratingCount : {}, level: "Sub Category" }),
        React.createElement(UniversalRating, { bandScales: bandScales, selectedFrequency: selectedFrequency, pageSize: paginationProps.pageSize, printInstance: printInstance, userId: userContext === null || userContext === void 0 ? void 0 : userContext.userId, openNotificationModal: openNotificationModal, data: data, setData: setData, handleToggleModalVisibility: handleToggleWatchListModal }),
        React.createElement(RelatedNotificationsPopup, { itemId: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.itemId, visible: notificationModelVisibility, onClose: closeNotificationModal, selectedItem: selectedItem, setSelectedItem: setSelectedItem, browserHistory: browserHistory, setOverallData: overallNotificationPopup.current && setTrackedItemsOverallRating, setDashboardListData: !overallNotificationPopup.current && setData, listKey: overallNotificationPopup.current ? "ratingData" : "universalTabList" }),
        React.createElement(AddRemoveWatchListModal, { handleToggleModalVisibility: handleToggleWatchListModal, selectedTrackItemId: selectedWatchlistModalItem === null || selectedWatchlistModalItem === void 0 ? void 0 : selectedWatchlistModalItem.itemId, onAddRemoveWatchList: (itemId, status) => {
                setData((prev) => (Object.assign(Object.assign({}, prev), { universalTabList: prev.universalTabList.map((record) => {
                        if (record.itemId === itemId) {
                            record.watchlistStatus = status;
                        }
                        return record;
                    }) })));
                handleSetRoadMapItemWatchListStatus(itemId, status);
            } })));
}
function mapStateTopProps(state) {
    var _a;
    return {
        historyFrequency: (_a = state.lookups[LookupNames.LKReportHistoryFrequency]) !== null && _a !== void 0 ? _a : [],
        printInstance: state.togglePrint,
    };
}
const mapDispatchToProps = {
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(TrackedItemsDashboard);
