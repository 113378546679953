var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import SiteForm from "../components/siteForm";
import { defaultSiteDetail } from "../../../../../core/contracts/site/siteContracts";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addSite } from "../../../../../redux/site/actions";
import { getCompanies } from "../../../../../redux/company/actions";
import { notifications } from "../../../../../layout/components/notifications";
import { CompanyType } from "../../../../../core/enums/CompanyType";
import { TelephoneType } from "../../../../../core/enums/TelephoneType";
import { AddressType } from "../../../../../core/enums/AddressTypes";
import { useHistory } from "react-router-dom";
import { sitesRoute } from "../../../../../core/constants/routesPath";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import { useTranslation } from "react-i18next";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import { LookupNames } from "../../../../../core/constants/lookup";
import { getLookups } from "../../../../../redux/lookup/actions";
import useDidMount from "../../../../../core/hooks/useDidMount";
function AddSite({ addSite, context, sites, companies, getCompanies, getLookups, }) {
    var _a;
    const history = useHistory();
    const [t] = useTranslation();
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [
                LookupNames.LKRegisteredActivities,
                LookupNames.LKReportWeight,
            ],
            getLookups,
        });
        if (companies.length === 0) {
            loadCompanies();
        }
    });
    const loadCompanies = () => __awaiter(this, void 0, void 0, function* () {
        yield getCompanies().catch((error) => {
            throw error;
        });
    });
    const initialSite = {
        id: 0,
        parentId: (_a = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _a === void 0 ? void 0 : _a.id,
        name: "",
        type: CompanyType.Site,
        registrationNumber: "",
        cqcRegistrationNumber: "",
        contactName: "",
        primaryTelephoneType: TelephoneType.Mobile,
        primaryTelephoneNumber: "",
        secondaryTelephoneType: TelephoneType.Mobile,
        secondaryTelephoneNumber: "",
        externalReference: null,
        emailAddress: "",
        siteDetail: defaultSiteDetail,
        addresses: [
            {
                name: "",
                line1: "",
                line2: "",
                town: "",
                county: "",
                postCode: "",
                type: AddressType.RegisteredAddress,
                id: null,
            },
            {
                name: "",
                line1: "",
                line2: "",
                town: "",
                county: "",
                postCode: "",
                type: AddressType.ContactAddress,
                id: null,
            },
        ],
        active: true,
        holdingCompany: false,
        weight: 0,
    };
    const addSiteHandler = (site, resetForm, setErrors) => {
        addSite(site).then((response) => {
            if (response &&
                (response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION ||
                    response.code === ResponseStatusCodes.VALIDATION_ERROR)) {
                setErrors({
                    [response.errorField]: response.message,
                });
            }
            else {
                resetForm();
                notifications.success(t("Site added successfully"));
                history.push(sitesRoute);
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SiteForm, { onSubmit: addSiteHandler, submitText: "Save", site: initialSite, sites: sites, companies: companies })));
}
function mapStateTopProps(state) {
    return {
        context: state.context,
        sites: state.sites,
        companies: state.companies,
    };
}
const mapDispatchToProps = {
    addSite,
    getCompanies,
    getLookups,
};
AddSite.propTypes = {
    addSite: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(AddSite);
