import { GET_N_NOTIFICATION_SUCCESS, FLAG_NOTIFICATION_SUCCESS, UNFLAG_NOTIFICATION_SUCCESS, READ_NOTIFICATION_SUCCESS, UNREAD_NOTIFICATION_SUCCESS, } from "./types";
const initialState = [];
export const inAppNotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_N_NOTIFICATION_SUCCESS: {
            return [...action.payload];
        }
        case FLAG_NOTIFICATION_SUCCESS: {
            const notifications = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { flagged: true }));
            return [...notifications];
        }
        case UNFLAG_NOTIFICATION_SUCCESS: {
            const notifications = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { flagged: false }));
            return [...notifications];
        }
        case READ_NOTIFICATION_SUCCESS: {
            const notifications = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { inAppStatus: 1 }));
            return [...notifications];
        }
        case UNREAD_NOTIFICATION_SUCCESS: {
            const notifications = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { inAppStatus: 0 }));
            return [...notifications];
        }
        default: {
            return state;
        }
    }
};
