import { GET_USERS_SUCCESS, ADD_USER_SUCCESS, UPDATE_USER_SUCCESS, INACTIVATE_USER_SUCCESS, ACTIVATE_USER_SUCCESS, DELETE_USER_SUCCESS, UNLOCK_USER_SUCCESS, LOCK_USER_SUCCESS, CLEAR_USERS_LISTING_SUCCESS, } from "./types";
import { UserApi } from "../../core/api/userApi";
import { SessionStorage } from "../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../core/constants/SessionVariables";
import isEmpty from "../../core/helpers/isEmpty";
export const getUsersSuccess = (data) => ({
    type: GET_USERS_SUCCESS,
    payload: data,
});
export const addUserSuccess = (data) => ({
    type: ADD_USER_SUCCESS,
    payload: data,
});
export const updateUserSuccess = (data) => ({
    type: UPDATE_USER_SUCCESS,
    payload: data,
});
export const inActivateUserSuccess = (data) => ({
    type: INACTIVATE_USER_SUCCESS,
    payload: data,
});
export const activateUserSuccess = (data) => ({
    type: ACTIVATE_USER_SUCCESS,
    payload: data,
});
export const unlockUserSuccess = (data) => ({
    type: UNLOCK_USER_SUCCESS,
    payload: data,
});
export const lockUserSuccess = (data) => ({
    type: LOCK_USER_SUCCESS,
    payload: data,
});
export const deleteUserSuccess = (data) => ({
    type: DELETE_USER_SUCCESS,
    payload: data,
});
export const clearUserListingSuccess = () => ({
    type: CLEAR_USERS_LISTING_SUCCESS,
});
export function getUsers(companyId, isCompanyChanged = false) {
    return function (dispatch) {
        return UserApi.getUsers(companyId)
            .then((users) => {
            if (Array.isArray(users) || isCompanyChanged === true) {
                const mapUsers = users.map((value) => {
                    var _a, _b;
                    return (Object.assign(Object.assign({}, value), { fullName: `${(_a = value.firstName) !== null && _a !== void 0 ? _a : ``} ${(_b = value.lastName) !== null && _b !== void 0 ? _b : ``}` }));
                });
                dispatch(getUsersSuccess(mapUsers));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getActiveUsers(companyId, isCompanyChanged = false) {
    return function (dispatch) {
        return UserApi.getActiveUsers(companyId)
            .then((users) => {
            if (Array.isArray(users) || isCompanyChanged === true) {
                dispatch(getUsersSuccess(users));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function addUser(user) {
    return function (dispatch) {
        return UserApi.createUser(user)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(clearUserListingSuccess());
            //dispatch(addUserSuccess(response));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function editUser(user) {
    return function (dispatch) {
        return UserApi.updateUser(user)
            .then((response) => {
            var _a, _b, _c, _d, _e;
            if (response.code) {
                return response;
            }
            const updateResponse = Object.assign(Object.assign({}, response), { fullName: `${(_a = response.firstName) !== null && _a !== void 0 ? _a : ``} ${(_b = response.lastName) !== null && _b !== void 0 ? _b : ``}` });
            const { groupId: sessionGroupId, companyId: sessionCompanyId, siteId: sessionSiteId } = (_e = (_d = (_c = SessionStorage.getKey(SessionVariables.LISTING_FILTERS, sessionStorage)) === null || _c === void 0 ? void 0 : _c["/vertical/users/"]) === null || _d === void 0 ? void 0 : _d["1"]) !== null && _e !== void 0 ? _e : {};
            updateResponse.userCompanyRoleDTOs = updateResponse.userCompanyRoleDTOs.filter(({ groupId, companyId, siteId }) => {
                const isValid = (sessionGroupId === groupId && isEmpty(sessionCompanyId) && isEmpty(sessionSiteId)) ||
                    (sessionGroupId === groupId && (sessionCompanyId) === companyId && isEmpty(sessionSiteId)) ||
                    (sessionGroupId === groupId && (sessionCompanyId) === companyId && (sessionSiteId) === siteId);
                return isValid;
            });
            dispatch(updateUserSuccess(updateResponse));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function inActivateUser(data, callBack) {
    return function (dispatch) {
        return UserApi.inActivateUser(data)
            .then((response) => {
            if (typeof response === "boolean") {
                callBack === null || callBack === void 0 ? void 0 : callBack();
                dispatch(inActivateUserSuccess(data));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function activateUser(data, callBack) {
    return function (dispatch) {
        return UserApi.activateUser(data)
            .then((response) => {
            if (typeof response === "boolean") {
                callBack === null || callBack === void 0 ? void 0 : callBack();
                dispatch(activateUserSuccess(data));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function unlockUser(data) {
    return function (dispatch) {
        return UserApi.unlockUser(data)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(unlockUserSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function lockUser(data) {
    return function (dispatch) {
        return UserApi.lockUser(data)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(lockUserSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
