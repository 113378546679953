import { SET_CONTEXT, CLEAR_CONTEXT, SET_CONTEXT_COMPANY, SET_CONTEXT_APPLICATION, SET_SITE_STATUSES, SET_CONTEXT_TASK, SET_CONTEXT_SUB_CATEGORY, } from "./types";
import { initialState as initialStoreState } from "../initialState";
const initialState = initialStoreState.context;
const defaults = {
    userId: undefined,
    userFullName: undefined,
    isAuthenticated: false,
    permissions: ["annonymous"],
    companyPermissionDTOs: [],
    selectedCompany: {},
    allowedApps: [],
    selectedApp: undefined,
    siteStatuses: [],
    selectedFinancialTask: {},
    selectedSubCategory: {},
};
export const contextReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTEXT:
            return Object.assign(Object.assign({}, action.payload), { isAuthenticated: true });
        case SET_CONTEXT_COMPANY: {
            return Object.assign(Object.assign({}, state), { selectedCompany: action.payload });
        }
        case SET_SITE_STATUSES: {
            return Object.assign(Object.assign({}, state), { siteStatuses: action.payload });
        }
        case SET_CONTEXT_APPLICATION: {
            return Object.assign(Object.assign({}, state), { selectedApp: action.payload });
        }
        case SET_CONTEXT_TASK: {
            return Object.assign(Object.assign({}, state), { selectedFinancialTask: action.payload });
        }
        case SET_CONTEXT_SUB_CATEGORY: {
            return Object.assign(Object.assign({}, state), { selectedSubCategory: action.payload });
        }
        case CLEAR_CONTEXT:
            return Object.assign({}, defaults);
        default:
            return Object.assign({}, state);
    }
};
