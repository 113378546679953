import React, { useContext, useEffect } from "react";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import { USER_REPORTS, USER_REPORTS_FINANCIAL, } from "../../../../../core/constants/permissionConstants";
import { reportsRoute, unAuthorizedRoute, } from "../../../../../core/constants/routesPath";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { browserHistoryMemoryContext } from "../../../../../core/contexts/browserHistoryMemoryContext";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import TableActions from "../../../../../layout/components/customTable/tableActions";
const DashboardActionButtons = (item, handleToggleWatchListModal, userId, listKey) => {
    const isReportViewAllowed = isActionAllowed([USER_REPORTS]);
    const isFinancialDataViewAllowed = isActionAllowed([USER_REPORTS_FINANCIAL]);
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    // const addToWatchList = (item) => {
    //   if (item?.itemId) {
    //     setData(
    //       (origin) => ({
    //         ...origin,
    //         [listKey]: origin[listKey]?.map((el) =>
    //           el.itemId !== item.itemId ? el : { ...el, watchlistStatus: true }
    //         ),
    //       })
    //       // origin.map((el) =>
    //       //   el.itemId !== item.itemId ? item : { ...item, watchlistStatus: true }
    //       // )
    //     );
    //     WatchlistApi.addToWatchlist(item?.itemId).then(
    //       (res) =>
    //         !res.error &&
    //         notifications.success(
    //           localize([
    //             "Dashboard.addedto",
    //             "Dashboard.watchList",
    //             "successfully",
    //           ])
    //         )
    //     );
    //   }
    // };
    // const removeFromWatchlist = (item) => {
    //   if (item && item?.itemId) {
    //     setData((origin) => ({
    //       ...origin,
    //       [listKey]: origin[listKey]?.map((el) =>
    //         el.itemId !== item.itemId ? el : { ...el, watchlistStatus: false }
    //       ),
    //     }));
    //     WatchlistApi.removeFromWatchlist(item.itemId).then((res) => {
    //       !res.error &&
    //         notifications.success(
    //           localize([
    //             "Dashboard.removedFrom",
    //             "Dashboard.watchList",
    //             "successfully",
    //           ])
    //         );
    //       //   setDataLength(data.length);
    //     });
    //   }
    // };
    const viewFinancialModule = (item) => {
        var _a;
        if (!isEmpty(item && (item === null || item === void 0 ? void 0 : item.trackedItemCompanyId))) {
            let companyFilter, siteFilter = undefined;
            if (item.reportType === "Company") {
                companyFilter = item.trackedItemCompanyId;
            }
            else if (item.reportType !== "Company" && item.reportType !== "Group") {
                companyFilter = item === null || item === void 0 ? void 0 : item.parentCompanyId;
                siteFilter = item.trackedItemCompanyId;
            }
            SessionStorage.setKey(SessionVariables.FINANCIAL_MODULE, {
                filterValues: {
                    groupId: item.reportType === "Group"
                        ? item.trackedItemCompanyId
                        : item === null || item === void 0 ? void 0 : item.parentGroupId,
                    companyId: companyFilter,
                    siteId: siteFilter,
                },
                tab: "4",
                // item.type === 3
                //   ? "1"
                //   : item.type === 1
                //   ? "4"
                //   : item.type === 4
                //   ? "3"
                //   : item.type?.toString(),
                type: item.reportType,
                parentKey: item.parentKey && ((_a = item.parentKey) !== null && _a !== void 0 ? _a : undefined),
            });
            isReportViewAllowed && isFinancialDataViewAllowed
                ? browserHistory.push(reportsRoute)
                : browserHistory.push(unAuthorizedRoute, {
                    subText: "permission",
                });
        }
    };
    // const btnList: ITableActions[] = [
    //   {
    //     // svgIcon: item.watchlistStatus ? "AddedToWatchlist" : "WatchlistButton",
    //     onClick: handleToggleWatchListModal.bind({}, item),
    //     priority: 1,
    //     title: ["Add / Remove Watchlist"],
    //     renderSvgButton: true,
    //   },
    //   {
    //     priority: 2,
    //     title: ["Dashboard.viewFinancialSheet"],
    //     svgIcon: "FinancialSheet",
    //     onClick: (e) => viewFinancialModule(item),
    //     renderSvgButton: true,
    //     isAllowed: !isEmpty(item.isSummaryItem) && item.isSummaryItem === true,
    //   },
    // ];
    const btnList = [
        {
            onClick: handleToggleWatchListModal.bind({}, item),
            priority: 1,
            title: ["Add / Remove Watchlist"],
            svgIcon: item.watchlistStatus ? "AddedToWatchlist" : "WatchlistButton",
        },
        {
            priority: 2,
            title: ["Dashboard.viewFinancialSheet"],
            svgIcon: "FinancialSheet",
            onClick: (e) => viewFinancialModule(item),
            isAllowed: !isEmpty(item.isSummaryItem) && item.isSummaryItem === true,
        },
    ];
    const removeTableAction = () => {
        document
            .querySelectorAll(".hideOnScroll")
            .forEach((item) => { var _a, _b; return (_b = (_a = item === null || item === void 0 ? void 0 : item.parentElement) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.add("d-none"); });
    };
    useEffect(() => {
        window.addEventListener("wheel", removeTableAction);
        return () => {
            window.removeEventListener("wheel", removeTableAction, false);
        };
    }, []);
    return (
    // <TableActions
    //   actionArray={btnList}
    //   wrapperClass="dottedActionsButton watchlistActions"
    //   dropdownClassName="actionsDropdown hideOnScroll"
    //   ShowActionText={false}
    //   menuItems
    // />
    React.createElement(TableActions, { actionArray: btnList, wrapperClass: "watchlistActionButtons" }));
};
export default DashboardActionButtons;
