import { useEffect, useState, useRef } from "react";
import React from "react";
import SingleSelectNew from "./SingleSelectNew";
import { TrackedItemApi } from "../../../core/api/trackedItemApi";
const TrackedItemDropdownContainer = ({ onChange, selectedValue, defaultValue, className, siteId, categoryId, subcategoryId, disabled, allowClear = false, labelInValue, haveDefaultSiteHandler = false, placeHolder, error = null, }) => {
    const isAllowClear = useRef(false);
    const [trackedItems, setTrackedItems] = useState([]);
    useEffect(() => {
        if (categoryId && subcategoryId && siteId) {
            TrackedItemApi.getTrackedItemsByCompanyIdAndCategorySubCategory(siteId, subcategoryId, categoryId).then((response) => {
                if (response === null || response === void 0 ? void 0 : response.length) {
                    setTrackedItems(response);
                }
            });
        }
        else {
            isAllowClear.current = true;
            setTrackedItems([]);
        }
    }, [subcategoryId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { id: "tracked-item-filter-dropdown", options: trackedItems, selectedValue: selectedValue, defaultValue: defaultValue, onChange: onChange, placeholder: placeHolder !== null && placeHolder !== void 0 ? placeHolder : "Select a Tracked Item", key: "trackedItemSelect", valueProperty: "id", textProperty: "name", className: className, disabled: disabled, labelInValue: labelInValue, allowClear: isAllowClear.current, error: error })));
};
export default TrackedItemDropdownContainer;
