import { Button, Popover, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import RenderSvg from "../../../../../../../layout/components/icons/RenderSvg";
import RatingColumnComponent from "../../ratingColumnComponent";
import "./styles.scss";
import { useHistory, useLocation } from "react-router-dom";
import { getBreadCrumbSession } from "../../../../../../../core/helpers/localStorage/breadCrumbSession";
import { SessionStorage } from "../../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../../../core/constants/SessionVariables";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import AddRemoveWatchListModal from "../addRemoveWatchList";
import localize from "../../../../../../../core/utils/translation-files";
import isActionAllowed from "../../../../../../../core/authorization/userPermissions";
import { REPORT_CATEGORY_CARE_DELIVERY, REPORT_CATEGORY_CARE_FINANCIALS, REPORT_CATEGORY_CARE_RISK } from "../../../../../../../core/constants/permissionConstants";
const { getKey, setKey } = SessionStorage;
const { BREADCRUMB } = SessionVariables;
const CurrentFinancialRatingBox = ({ record, financialData, navigateCallBack = undefined, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17;
    const [showFinancialRating, setShowFinancialRating] = useState(false);
    const [filteredFinancialData, setFilteredFinancialData] = useState([]);
    const [watchListModalData, setWatchListModalData] = useState(undefined);
    const handleToggleModalVisibility = (event, trackedItemId = undefined) => {
        event.preventDefault();
        event.stopPropagation();
        setWatchListModalData(trackedItemId);
    };
    const history = useHistory();
    useEffect(() => {
        // if (record) {
        setFilteredFinancialData(financialData === null || financialData === void 0 ? void 0 : financialData.filter((item) => { var _a; return item.companyId === ((_a = record === null || record === void 0 ? void 0 : record.companyId) !== null && _a !== void 0 ? _a : record === null || record === void 0 ? void 0 : record.trackedItemCompanyId); }));
        // }
    }, [financialData, record]);
    const { pathname } = useLocation();
    const breadCrumbList = getBreadCrumbSession();
    const getBreadCrumbData = (ratingRecord) => {
        var _a, _b, _c;
        const { itemId, categoryId, currentBand } = ratingRecord;
        const name = (ratingRecord === null || ratingRecord === void 0 ? void 0 : ratingRecord.type.includes("Financials"))
            ? "Financials"
            : "Care";
        const parentPath = (_b = (_a = breadCrumbList === null || breadCrumbList === void 0 ? void 0 : breadCrumbList.at(-1)) === null || _a === void 0 ? void 0 : _a.parentPath) !== null && _b !== void 0 ? _b : ``;
        const isGroupLevel = (_c = ratingRecord === null || ratingRecord === void 0 ? void 0 : ratingRecord.type) === null || _c === void 0 ? void 0 : _c.includes("Group");
        //placeholder path for category page on dashboard
        const path = isGroupLevel ? `/0/0/${+categoryId}` : `/0/${+categoryId}`;
        return {
            path,
            name,
            key: ratingRecord === null || ratingRecord === void 0 ? void 0 : ratingRecord.categoryId,
            category: `Category Dashboard`,
            bandName: (record === null || record === void 0 ? void 0 : record.weight) !== 0 && !(record === null || record === void 0 ? void 0 : record.weightFlag)
                ? currentBand === null || currentBand === void 0 ? void 0 : currentBand.currentStatus
                : "E",
            bandColor: currentBand === null || currentBand === void 0 ? void 0 : currentBand.currentStatusRgb,
            parentPath: `${parentPath}${path}`,
            subCategoryId: ratingRecord === null || ratingRecord === void 0 ? void 0 : ratingRecord.subCategoryId,
        };
    };
    const handleNavigateDashboard = (ratingRecord) => {
        const recordBreadCrumb = getBreadCrumbData(ratingRecord);
        if (!isEmpty(navigateCallBack)) {
            navigateCallBack(record, recordBreadCrumb);
            return;
        }
        setKey(BREADCRUMB, [...breadCrumbList, recordBreadCrumb], sessionStorage);
        if (SessionStorage.getKey(SessionVariables.EXPANDED_KEYS, sessionStorage) !==
            null) {
            const ids = getKey(BREADCRUMB, sessionStorage).map((item) => parseInt(item.key));
            setKey(SessionVariables.EXPANDED_KEYS, ids === null || ids === void 0 ? void 0 : ids.slice(0, -1), sessionStorage);
        }
        // console.log({ pathname, rcc: getBreadCrumbData(ratingRecord) });
        history.push(`${pathname}${recordBreadCrumb === null || recordBreadCrumb === void 0 ? void 0 : recordBreadCrumb.path}`);
    };
    const iconStyles = {
        color: "#17A99A",
        marginRight: "10px",
    };
    const isFinancialViewAllowed = isActionAllowed([REPORT_CATEGORY_CARE_FINANCIALS]);
    const isCareViewAllowed = isActionAllowed([
        REPORT_CATEGORY_CARE_DELIVERY,
        REPORT_CATEGORY_CARE_RISK
    ]);
    const contextJSX = (React.createElement("div", { className: "currentFinancialRatingBox " },
        React.createElement("div", null, "Current Rating"),
        isFinancialViewAllowed && !isEmpty((_b = (_a = filteredFinancialData[0]) === null || _a === void 0 ? void 0 : _a.currentBand) === null || _b === void 0 ? void 0 : _b.currentBandPercentage) ? (React.createElement("div", { about: "financial", className: "ratingRow rr-1 cursor-pointer", onClick: handleNavigateDashboard.bind({}, filteredFinancialData[0]) },
            React.createElement("div", { className: "rowTitle" },
                React.createElement(RenderSvg, { icon: "Location", style: iconStyles }),
                React.createElement("span", null, (_c = filteredFinancialData[0]) === null || _c === void 0 ? void 0 : _c.title),
                React.createElement(RenderSvg, { icon: ((_d = filteredFinancialData === null || filteredFinancialData === void 0 ? void 0 : filteredFinancialData[0]) === null || _d === void 0 ? void 0 : _d.hasWatchList)
                        ? "AddedToWatchlist"
                        : "WatchlistButton", tooltipTitle: localize(["Dashboard.addRemoveWatchlist"]), style: {
                        cursor: "pointer",
                        color: "#1B998F",
                        marginLeft: "1rem",
                    }, onClick: (event) => {
                        var _a;
                        return handleToggleModalVisibility(event, (_a = filteredFinancialData === null || filteredFinancialData === void 0 ? void 0 : filteredFinancialData[0]) === null || _a === void 0 ? void 0 : _a.itemId);
                    } })),
            filteredFinancialData === undefined ? (React.createElement(Skeleton.Button, { active: true, size: "default" })) : (React.createElement(RatingColumnComponent, { key: (_e = filteredFinancialData[0]) === null || _e === void 0 ? void 0 : _e.itemId, column: "current", decimals: (_f = filteredFinancialData[0]) === null || _f === void 0 ? void 0 : _f.decimalPlaces, displayValue: (_g = filteredFinancialData[0]) === null || _g === void 0 ? void 0 : _g.displayValue, valueType: (_h = filteredFinancialData[0]) === null || _h === void 0 ? void 0 : _h.valueType, movingAverage: (_k = (_j = filteredFinancialData[0]) === null || _j === void 0 ? void 0 : _j.currentBand) === null || _k === void 0 ? void 0 : _k.currentBandPercentage, statusRgb: (_m = (_l = filteredFinancialData[0]) === null || _l === void 0 ? void 0 : _l.currentBand) === null || _m === void 0 ? void 0 : _m.currentStatusRgb, status: (_p = (_o = filteredFinancialData[0]) === null || _o === void 0 ? void 0 : _o.currentBand) === null || _p === void 0 ? void 0 : _p.currentStatus, date: (_r = (_q = filteredFinancialData[0]) === null || _q === void 0 ? void 0 : _q.currentBand) === null || _r === void 0 ? void 0 : _r.bandDateTime, weight: record === null || record === void 0 ? void 0 : record.weight, weightFlag: record === null || record === void 0 ? void 0 : record.weightFlag, statusMA: (_t = (_s = filteredFinancialData[0]) === null || _s === void 0 ? void 0 : _s.currentBand) === null || _t === void 0 ? void 0 : _t.currentStatusMovingAverage, actualValue: (_v = (_u = filteredFinancialData[0]) === null || _u === void 0 ? void 0 : _u.currentBand) === null || _v === void 0 ? void 0 : _v.currentActualValue, systemCreated: (_w = !(record === null || record === void 0 ? void 0 : record.isLeaf)) !== null && _w !== void 0 ? _w : record.systemCreated, showFlag: false })))) : null,
        isCareViewAllowed && !isEmpty((_y = (_x = filteredFinancialData[1]) === null || _x === void 0 ? void 0 : _x.currentBand) === null || _y === void 0 ? void 0 : _y.currentBandPercentage) ? (React.createElement("div", { about: "care", className: "ratingRow cursor-pointer", onClick: handleNavigateDashboard.bind({}, filteredFinancialData[1]) },
            React.createElement("div", { className: "rowTitle" },
                React.createElement(RenderSvg, { icon: "Location", style: iconStyles }),
                React.createElement("span", null, (_z = filteredFinancialData[1]) === null || _z === void 0 ? void 0 : _z.title),
                React.createElement(RenderSvg, { icon: ((_0 = filteredFinancialData === null || filteredFinancialData === void 0 ? void 0 : filteredFinancialData[1]) === null || _0 === void 0 ? void 0 : _0.hasWatchList)
                        ? "AddedToWatchlist"
                        : "WatchlistButton", tooltipTitle: localize(["Dashboard.addRemoveWatchlist"]), style: {
                        cursor: "pointer",
                        color: "#1B998F",
                        marginLeft: "1rem",
                    }, onClick: (event) => {
                        var _a;
                        return handleToggleModalVisibility(event, (_a = filteredFinancialData === null || filteredFinancialData === void 0 ? void 0 : filteredFinancialData[1]) === null || _a === void 0 ? void 0 : _a.itemId);
                    } })),
            filteredFinancialData === undefined ? (React.createElement(Skeleton.Button, { active: true, size: "default" })) : (React.createElement(RatingColumnComponent, { key: (_1 = filteredFinancialData[1]) === null || _1 === void 0 ? void 0 : _1.itemId, column: "current", decimals: (_2 = filteredFinancialData[1]) === null || _2 === void 0 ? void 0 : _2.decimalPlaces, displayValue: (_3 = filteredFinancialData[1]) === null || _3 === void 0 ? void 0 : _3.displayValue, record: record, valueType: (_4 = filteredFinancialData[1]) === null || _4 === void 0 ? void 0 : _4.valueType, movingAverage: (_6 = (_5 = filteredFinancialData[1]) === null || _5 === void 0 ? void 0 : _5.currentBand) === null || _6 === void 0 ? void 0 : _6.currentBandPercentage, statusRgb: (_8 = (_7 = filteredFinancialData[1]) === null || _7 === void 0 ? void 0 : _7.currentBand) === null || _8 === void 0 ? void 0 : _8.currentStatusRgb, status: (_10 = (_9 = filteredFinancialData[1]) === null || _9 === void 0 ? void 0 : _9.currentBand) === null || _10 === void 0 ? void 0 : _10.currentStatus, date: (_12 = (_11 = filteredFinancialData[1]) === null || _11 === void 0 ? void 0 : _11.currentBand) === null || _12 === void 0 ? void 0 : _12.bandDateTime, weight: record === null || record === void 0 ? void 0 : record.weight, weightFlag: record === null || record === void 0 ? void 0 : record.weightFlag, statusMA: (_14 = (_13 = filteredFinancialData[1]) === null || _13 === void 0 ? void 0 : _13.currentBand) === null || _14 === void 0 ? void 0 : _14.currentStatusMovingAverage, actualValue: (_16 = (_15 = filteredFinancialData[1]) === null || _15 === void 0 ? void 0 : _15.currentBand) === null || _16 === void 0 ? void 0 : _16.currentActualValue, systemCreated: (_17 = !(record === null || record === void 0 ? void 0 : record.isLeaf)) !== null && _17 !== void 0 ? _17 : record.systemCreated, showFlag: false })))) : null));
    if (!isFinancialViewAllowed && !isCareViewAllowed) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: "dottedActionsButton" },
        React.createElement(Button, { type: "primary", className: "dashed-action-btn" },
            React.createElement(Popover, { content: contextJSX, title: null, placement: "right", trigger: "click", visible: showFinancialRating, onVisibleChange: (isVisble) => {
                    if (!document.querySelector(".ant-modal-mask")) {
                        setShowFinancialRating(isVisble);
                    }
                }, overlayClassName: "category-popover-container" },
                React.createElement("div", { className: "category-container" },
                    React.createElement(RenderSvg, { icon: "MoreActions", style: {
                            marginTop: "-2.4px",
                        } })))),
        React.createElement(AddRemoveWatchListModal, { onAddRemoveWatchList: (itemId, status) => {
                // console.log({ itemId, status });
                setFilteredFinancialData((prev) => [...prev].map((record) => {
                    if ((record === null || record === void 0 ? void 0 : record.itemId) === itemId) {
                        record.hasWatchList = status;
                    }
                    return record;
                }));
            }, handleToggleModalVisibility: handleToggleModalVisibility, selectedTrackItemId: watchListModalData })));
};
export default CurrentFinancialRatingBox;
