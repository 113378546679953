import { Tabs } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getTasks } from "../../../../../redux/task/actions";
import { TaskListingTable } from "./components/taskListingTable";
import { TaskType } from "../../../../../core/enums/TaskType";
import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { setContextTask } from "../../../../../redux/context/actions";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import localize from "../../../../../core/utils/translation-files";
import TableActions from "../../../../../layout/components/customTable/tableActions";
const TaskListingContainer = ({ getTasks, tasks, context, setContextTask }) => {
    var _a;
    const { TabPane } = Tabs;
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();
    useEffect(() => {
        var _a, _b, _c;
        if (((_a = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _a === void 0 ? void 0 : _a.id) !== undefined) {
            if (tasks.completedTask.length === 0) {
                getTasks((_b = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _b === void 0 ? void 0 : _b.id, TaskType.Completed);
            }
            if (tasks.dueTask.length === 0) {
                getTasks((_c = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _c === void 0 ? void 0 : _c.id, TaskType.Due);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_a = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _a === void 0 ? void 0 : _a.id]);
    const onTaskCompleteClick = (task) => {
        setContextTask(task);
        SessionStorage.setKey(SessionVariables.SELECTED_TASK, task);
        history.push(task.taskDataMap.pageURL);
    };
    const taskActions = (task) => {
        const actionArray = [
            {
                priority: 1,
                title: ["Task.complete", "Task.task"],
                btnType: "default",
                onClick: onTaskCompleteClick.bind(this, task),
                children: React.createElement(EyeOutlined, null),
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    return (React.createElement("div", null,
        React.createElement(Tabs, { defaultActiveKey: query.get("tab") === "pending" ? "2" : "1" },
            React.createElement(TabPane, { tab: `${localize(["Task.pending", "Task.task"])}`, key: "1", id: "users", style: { background: "green" } },
                React.createElement(TaskListingTable, { key: "id1", data: tasks.dueTask, actions: taskActions, type: TaskType.Due })),
            React.createElement(TabPane, { tab: `${localize(["Task.completed", "Task.task"])}`, key: "2", id: "pendingUsers", active: true },
                React.createElement(TaskListingTable, { key: "id2", data: tasks.completedTask, actions: undefined, type: TaskType.Completed })))));
};
function mapStateTopProps(state) {
    return {
        tasks: state.tasks,
        context: state.context,
    };
}
const mapDispatchToProps = {
    getTasks,
    setContextTask,
};
TaskListingContainer.propTypes = {};
export default connect(mapStateTopProps, mapDispatchToProps)(TaskListingContainer);
