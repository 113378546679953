import { BaseEntity } from "../baseEntity";
export class LookupResponse extends BaseEntity {
}
export class LookupRequest extends BaseEntity {
    constructor() {
        super(...arguments);
        this.systemCreated = false;
    }
}
export class LookupTypesResponse extends BaseEntity {
}
