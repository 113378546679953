var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { DashboardApi } from "../../../../../core/api/dashboardApi";
import { LookupNames } from "../../../../../core/constants/lookup";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import CustomPageHeader from "../../../../../layout/components/customPageHeader";
import { getLookups } from "../../../../../redux/lookup/actions";
import SubCategoriesListing from "./components/subCategoriesListing";
import { setContextSubCategory } from "../../../../../redux/context/actions";
import TrackedItemBandScaleContainer from "../../charts/line-chart/movingAverageAndProjection/trackedItemBandScale/trackedItemBandScaleContainer";
import OverallStatusPanels from "../components/OverallStatusPanels";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import { TrackedItemApi } from "../../../../../core/api/trackedItemApi";
import useDidMount from "../../../../../core/hooks/useDidMount";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import UsePersistCustomFilters from "../../../../../core/hooks/usePersistCustomFilters";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { browserHistoryMemoryContext } from "../../../../../core/contexts/browserHistoryMemoryContext";
import RelatedNotificationsPopup from "../components/relatedNotificationsPopup";
import RatingCount from "../components/RatingCount";
import DashboardFooter from "../components/DashboardFooter";
import UseInfiniteScroll from "../../../../../core/hooks/useInfiniteScroll";
import queryString from "query-string";
import { defaultHistoryFrequencyFilter } from "../../../../../core/constants/historyFrequencyFilter";
import AddRemoveWatchListModal from "../DashboardRoadmap/components/addRemoveWatchList";
import { getBreadCrumbSession, handleSetRoadMapItemWatchListStatus, } from "../../../../../core/helpers/localStorage/breadCrumbSession";
function SubCategoriesDashboard({ historyFrequency, getLookups, setContextSubCategory, isPrintMode, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const userContext = SessionStorage.getKey(SessionVariables.USER_CONTEXT);
    const userHistoryFrequencyFilter = isEmpty(userContext === null || userContext === void 0 ? void 0 : userContext.lastVisitedContext)
        ? defaultHistoryFrequencyFilter
        : (_b = JSON.parse((_a = JSON.parse(userContext === null || userContext === void 0 ? void 0 : userContext.lastVisitedContext)) === null || _a === void 0 ? void 0 : _a.text)) === null || _b === void 0 ? void 0 : _b.selectedFilter;
    const { filters: selectedFrequency, setFilters: setSelectedFrequency } = UsePersistCustomFilters({
        unmountContextRemoval: false,
        defaultValue: userHistoryFrequencyFilter,
    });
    const params = useParams();
    const [data, setData] = useState(undefined);
    const [lastRefreshTime, setLastRefreshTime] = useState("");
    const [scaleModelVisibility, setScaleModelVisibility] = useState(false);
    const _k = UseCustomPagination({
        // customRowHeight: 160,
        totalRecords: (_d = (_c = data === null || data === void 0 ? void 0 : data.subCategoryRatingDTOs) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange, pageSize } = _k, restPaginationProps = __rest(_k, ["handleCustomTablePropChange", "pageSize"]);
    const shouldUpdate = useRef(true);
    const [bandScales, setBandScales] = useState([]);
    const [subCategoryOverallRating, setSubCategoryOverallRating] = useState(undefined);
    const [ratingCount, setRatingCount] = useState(undefined);
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedWatchlistModalItem, setWatchListModalItem] = useState({});
    const [notificationModelVisibility, setNotificationModelVisibility] = useState(false);
    const overallNotificationPopup = useRef(false);
    const handleToggleWatchListModal = (param) => {
        setWatchListModalItem((prev) => ((param === null || param === void 0 ? void 0 : param.itemId) ? param : {}));
    };
    const openNotificationModal = (item, isOverall = false) => {
        if (isOverall) {
            overallNotificationPopup.current = true;
        }
        setNotificationModelVisibility(true);
        setSelectedItem(item);
    };
    const closeNotificationModal = () => {
        if (overallNotificationPopup.current) {
            overallNotificationPopup.current = false;
        }
        setNotificationModelVisibility(false);
        setSelectedItem({});
    };
    useDidMount(() => {
        TrackedItemApi.getTrackedItemScale().then((res) => {
            setBandScales(res);
        });
        getLookupByListing({
            LookupNames: [LookupNames.LKReportHistoryFrequency],
            getLookups,
        });
    });
    // const [currentPage, setCurrentPage] = useState(1);
    // const [shouldFetch, setShouldFetch] = useState(false);
    useEffect(() => {
        if (shouldUpdate.current && userHistoryFrequencyFilter) {
            setSelectedFrequency(userHistoryFrequencyFilter);
            shouldUpdate.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldUpdate, userHistoryFrequencyFilter]);
    const { search } = useLocation();
    const query = queryString.parse(search ||
        SessionStorage.getKey(SessionVariables.QUERY_PARAMS, sessionStorage));
    const paramsId = +(params === null || params === void 0 ? void 0 : params.site_id) || +(params === null || params === void 0 ? void 0 : params.comp_id) || +(params === null || params === void 0 ? void 0 : params.group_id);
    const handleGetApiCall = (isLoadMore = false, current = null) => {
        var _a, _b;
        const page = current !== null && current !== void 0 ? current : currentPage + 1;
        const { itemName = null, sort = null, order = null } = query !== null && query !== void 0 ? query : {};
        const body = {
            overallBands: null,
            benchmarkCodes: null,
            maxOverall: null,
            minOverall: null,
            sortColumnName: null,
            sortOrder: null,
            trackedItemName: itemName,
            predictedBands: null,
        };
        body.sortColumnName = (sort === null || sort === void 0 ? void 0 : sort.includes("itemName")) ? "name" : "band";
        body.sortOrder = order === "ascend" ? "asc" : "desc";
        const updatedPageSize = pageSize ? pageSize : 4;
        if (updatedPageSize) {
            DashboardApi.getSubCategoriesRatingList(paramsId, params === null || params === void 0 ? void 0 : params.category_id, ((_b = (_a = getBreadCrumbSession()) === null || _a === void 0 ? void 0 : _a.at(-1)) === null || _b === void 0 ? void 0 : _b.subCategoryId) || -1, +(selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code), page, 4, isLoadMore ? "LoadMore" : null, body).then((res) => {
                setLastRefreshTime(res.lastRefreshTime);
                if (isLoadMore) {
                    setCurrentPage((prev) => prev + 1);
                    setData((prev) => (Object.assign(Object.assign({}, prev), { subCategoryRatingDTOs: [
                            ...prev.subCategoryRatingDTOs,
                            ...res.subCategoryRatingDTOs,
                        ] })));
                    setShouldFetch(false);
                    return;
                }
                setData(res);
            });
        }
    };
    const { currentPage, setCurrentPage, setShouldFetch, handleScrollToTop } = UseInfiniteScroll({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleGetApiCall,
        isScrollMore: ((_e = data === null || data === void 0 ? void 0 : data.subCategoryRatingDTOs) === null || _e === void 0 ? void 0 : _e.length) < (data === null || data === void 0 ? void 0 : data.totalRecords),
    });
    useEffect(() => {
        var _a, _b;
        if (selectedFrequency && params.category_id) {
            // document
            //   .querySelector(".ant-table-body")
            //   .scrollTo({ behavior: "smooth", top: 0 });
            handleScrollToTop();
            setCurrentPage(1);
            handleGetApiCall(false, 1);
            DashboardApi.getSubCategoriesOverallRating(paramsId, params === null || params === void 0 ? void 0 : params.category_id, ((_b = (_a = getBreadCrumbSession()) === null || _a === void 0 ? void 0 : _a.at(-1)) === null || _b === void 0 ? void 0 : _b.subCategoryId) || -1, +(selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code)).then((res) => {
                var _a, _b, _c;
                if (!isEmpty(res === null || res === void 0 ? void 0 : res.ratingData)) {
                    setSubCategoryOverallRating(res);
                    if (!(ratingCount === null || ratingCount === void 0 ? void 0 : ratingCount.length)) {
                        DashboardApi.getRatingCount((_a = res === null || res === void 0 ? void 0 : res.ratingData) === null || _a === void 0 ? void 0 : _a.itemId, +(params === null || params === void 0 ? void 0 : params.group_id) || -1, (params === null || params === void 0 ? void 0 : params.comp_id) !== "0" && (params === null || params === void 0 ? void 0 : params.comp_id) !== "null"
                            ? (params === null || params === void 0 ? void 0 : params.comp_id) || -1
                            : -1, (params === null || params === void 0 ? void 0 : params.site_id) !== "0" && (params === null || params === void 0 ? void 0 : params.site_id) !== "null"
                            ? (params === null || params === void 0 ? void 0 : params.site_id) || -1
                            : -1, params === null || params === void 0 ? void 0 : params.category_id, !(params === null || params === void 0 ? void 0 : params.site_id) ||
                            (params === null || params === void 0 ? void 0 : params.site_id) === "0" ||
                            (params === null || params === void 0 ? void 0 : params.site_id) === "null"
                            ? ((_c = (_b = getBreadCrumbSession()) === null || _b === void 0 ? void 0 : _b.at(-1)) === null || _c === void 0 ? void 0 : _c.subCategoryId) || 1
                            : -1).then((res) => {
                            setRatingCount(res);
                        });
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        params === null || params === void 0 ? void 0 : params.category_id,
        paramsId,
        selectedFrequency,
        query === null || query === void 0 ? void 0 : query.sort,
        query === null || query === void 0 ? void 0 : query.order,
        query === null || query === void 0 ? void 0 : query.itemName,
    ]);
    const closeScaleModal = () => setScaleModelVisibility(false);
    const { nodeRef, handlePrint } = UsePrintPage();
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(CustomPageHeader, { setScaleModelVisibility: setScaleModelVisibility, frequencyList: historyFrequency, selectedFrequency: selectedFrequency, setSelectedFrequency: setSelectedFrequency, handlePrint: handlePrint, pageHeading: ["Dashboard.categoryDashboard"], itemName: (_g = (_f = subCategoryOverallRating === null || subCategoryOverallRating === void 0 ? void 0 : subCategoryOverallRating.ratingData) === null || _f === void 0 ? void 0 : _f.itemName) !== null && _g !== void 0 ? _g : "" }),
        React.createElement(TrackedItemBandScaleContainer, { visible: scaleModelVisibility, onClose: closeScaleModal, subCategory: params === null || params === void 0 ? void 0 : params.category_id, dashboardBandScale: bandScales, isDashboard: true }),
        React.createElement(OverallStatusPanels, { data: (_h = subCategoryOverallRating === null || subCategoryOverallRating === void 0 ? void 0 : subCategoryOverallRating.ratingData) !== null && _h !== void 0 ? _h : [], selectedFrequencyLabel: selectedFrequency, openNotificationModal: openNotificationModal, showAllCards: false }),
        React.createElement(RatingCount, { grades: bandScales, data: ratingCount !== null && ratingCount !== void 0 ? ratingCount : {}, level: "Category" }),
        React.createElement(SubCategoriesListing, { subcategories: (_j = data === null || data === void 0 ? void 0 : data.subCategoryRatingDTOs) !== null && _j !== void 0 ? _j : [], setContextSubCategory: setContextSubCategory, scaleBandArray: bandScales, handleCustomTablePropChange: handleCustomTablePropChange, currentPage: restPaginationProps.currentPage, pageSize: pageSize, selectedFrequency: selectedFrequency, isPrintMode: isPrintMode, openNotificationModal: openNotificationModal, handleToggleWatchListModal: handleToggleWatchListModal, params: params, userId: userContext === null || userContext === void 0 ? void 0 : userContext.userId }),
        React.createElement(RelatedNotificationsPopup, { itemId: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.itemId, visible: notificationModelVisibility, onClose: closeNotificationModal, selectedItem: selectedItem, setSelectedItem: setSelectedItem, browserHistory: browserHistory, setOverallData: overallNotificationPopup.current && setSubCategoryOverallRating, setDashboardListData: !overallNotificationPopup.current && setData, listKey: overallNotificationPopup.current
                ? "ratingData"
                : "subCategoryRatingDTOs" }),
        React.createElement(AddRemoveWatchListModal, { handleToggleModalVisibility: handleToggleWatchListModal, selectedTrackItemId: selectedWatchlistModalItem === null || selectedWatchlistModalItem === void 0 ? void 0 : selectedWatchlistModalItem.itemId, onAddRemoveWatchList: (itemId, status) => {
                setData((prev) => (Object.assign(Object.assign({}, prev), { subCategoryRatingDTOs: prev.subCategoryRatingDTOs.map((record) => {
                        if (record.itemId === itemId) {
                            record.watchlistStatus = status;
                        }
                        return record;
                    }) })));
                handleSetRoadMapItemWatchListStatus(itemId, status);
            } }),
        React.createElement(DashboardFooter, { lastRefreshTime: lastRefreshTime })));
}
function mapStateTopProps(state) {
    var _a, _b;
    return {
        historyFrequency: (_a = state.lookups[LookupNames.LKReportHistoryFrequency]) !== null && _a !== void 0 ? _a : [],
        isPrintMode: (_b = state.togglePrint) === null || _b === void 0 ? void 0 : _b.status,
    };
}
const mapDispatchToProps = {
    getLookups,
    setContextSubCategory,
};
export default connect(mapStateTopProps, mapDispatchToProps)(SubCategoriesDashboard);
