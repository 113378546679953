import React, { useImperativeHandle } from "react";
import * as Yup from "yup";
import { Button, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useFormik } from "formik";
import localize from "../../../../../core/utils/translation-files";
import { textAreaDefaultLength } from "../../../../../core/utils/default-length";
import PromptComponent from "../../../../../layout/components/prompt-message";
const VisionCommentsForm = ({ permission, onCancel, valuesRef, onSubmit, initialNotification, }) => {
    const { handleSubmit, handleChange, setErrors, values, errors, touched, resetForm, dirty, } = useFormik({
        validationSchema: Yup.object({
            visionComments: permission.isDelphieorSuperUser
                ? Yup.string()
                    .trim()
                    .test("len", localize([
                    "mustBe",
                    "lessThan",
                    `${textAreaDefaultLength}`,
                    "characters",
                ]), (val) => (val ? val.length <= textAreaDefaultLength : 0 < 1))
                    .required(localize(["Notification.VisionComments", "isRequired!"]))
                    .nullable()
                : Yup.string().nullable(),
        }),
        initialValues: initialNotification,
        onSubmit: (values) => {
            onSubmit(values, resetForm, setErrors);
        },
    });
    const handleCancel = () => {
        onCancel(resetForm);
    };
    useImperativeHandle(valuesRef, () => {
        return {
            isPrompt: dirty,
        };
    }, [dirty]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: "mb-1 customForm", name: "basic", onSubmit: handleSubmit, "data-test": "overwrite-form" },
            React.createElement(Input, { name: "id", type: "hidden" }),
            React.createElement("div", { className: "col-12 mb-2" },
                React.createElement(TextArea, { name: "visionComments", showCount: true, placeholder: localize(["enter", "Notification.visionComments"]), value: values.visionComments, defaultValue: values.visionComments, autoSize: { minRows: 8 }, onChange: handleChange, className: `${errors.visionComments && touched.visionComments
                        ? "input-error"
                        : null}` }),
                errors.visionComments && touched.visionComments ? (React.createElement("p", { className: "error" }, errors.visionComments)) : null),
            React.createElement("div", { className: "d-flex settings-actions d-print-none col-12 popup-button-list" },
                React.createElement(Button, { type: "default", onClick: handleCancel, className: "ml-auto mr-2" },
                    React.createElement("span", { className: "icofont icofont-close-circled mr-2", style: { fontSize: "1.2em" } }),
                    localize(["Button.cancel"])),
                React.createElement(Button, { type: "primary", htmlType: "submit", hidden: !dirty },
                    React.createElement("span", { className: "icofont icofont-save mr-2", style: { fontSize: "1em" } }),
                    localize(["Button.save"]))))));
};
export default VisionCommentsForm;
