import React, { useEffect, useState } from "react";
import BaseLayout from "../base/BaseLayout";
import Navbar from "../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import Menu from "../components/menu/Menu";
import Actions from "../components/actions/Actions";
import { toggleSidebar, resetSideBar } from "../../redux/settings/actions";
import "./Vertical.scss";
import NavLoader from "./../components/navbar/NavLoader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TopNavbar } from "../components/navbar/TopNavbar";
import "../../views/internal/shared/dashboard/components/dashboard.scss";
import { GROUP_CREATION } from "./../../core/constants/permissionConstants";
// import usePermissions from "../../core/hooks/usePermissions";
// import { UserType } from "../../core/enums/UserStatus";
import localize from "../../core/utils/translation-files";
import { PAGES_HEADER_INFO } from "../../config/constants";
import $ from 'jquery';
const VerticalLayout = ({ children, settings, context, toggleSidebar, resetSideBar, rootView, }) => {
    const permissions = context === null || context === void 0 ? void 0 : context.companyPermissionDTOs;
    const [menus] = useState(rootView === null || rootView === void 0 ? void 0 : rootView.sideBarActions);
    const [isAdmin] = useState(permissions === null || permissions === void 0 ? void 0 : permissions.some((x) => [GROUP_CREATION].includes(x)));
    const location = useLocation();
    const hideTooltip = () => {
        var _a;
        (_a = document
            .querySelector(".ant-tooltip")) === null || _a === void 0 ? void 0 : _a.setAttribute("class", "ant-tooltip-hidden");
        document
            .querySelectorAll(".ant-dropdown")
            .forEach((item) => {
            var _a, _b;
            return !((_a = item === null || item === void 0 ? void 0 : item.classList) === null || _a === void 0 ? void 0 : _a.contains("ant-dropdown-hidden")) &&
                ((_b = item === null || item === void 0 ? void 0 : item.classList) === null || _b === void 0 ? void 0 : _b.add("ant-dropdown-hidden"));
        });
    };
    $(document).on("wheel", "input[type=number]", function (e) {
        $(this).blur();
    });
    useEffect(() => {
        window.addEventListener("wheel", hideTooltip);
        return () => {
            window.removeEventListener("wheel", hideTooltip, false);
        };
    }, []);
    // const { singleSiteValues, singleCompanyValues, singleGroupValues } =
    //   usePermissions() ?? {};
    // const getCompanyContext = () => {
    //   if (context.userType === UserType.External) {
    //     return "Vision By Delphi";
    //   }
    //   return (
    //     singleSiteValues?.name ??
    //     singleCompanyValues?.name ??
    //     singleGroupValues?.name ??
    //     context?.userCompanyName
    //   );
    // };
    const findCurrentPageName = () => {
        var _a, _b;
        const currentParentRouteArr = location.pathname.split("/");
        if ((currentParentRouteArr === null || currentParentRouteArr === void 0 ? void 0 : currentParentRouteArr.includes("unauthorized")) ||
            (currentParentRouteArr === null || currentParentRouteArr === void 0 ? void 0 : currentParentRouteArr.includes("dashboard")) ||
            (currentParentRouteArr === null || currentParentRouteArr === void 0 ? void 0 : currentParentRouteArr.includes("watchlist")))
            return undefined;
        const currentParentRoute = currentParentRouteArr[1] + "/" + currentParentRouteArr[2];
        const { title, description } = (_a = PAGES_HEADER_INFO === null || PAGES_HEADER_INFO === void 0 ? void 0 : PAGES_HEADER_INFO[currentParentRoute]) !== null && _a !== void 0 ? _a : {};
        return (React.createElement("div", { className: "header-info-container" },
            React.createElement("div", { className: "header-title" }, (_b = localize(title).toUpperCase()) !== null && _b !== void 0 ? _b : "",
                " "),
            " ",
            React.createElement("span", { className: "header-desc-border", hidden: !title }),
            " ",
            React.createElement("span", { className: "header-desc-text", title: description }, description),
            " "));
    };
    const nav = (React.createElement(Navbar, { boxed: settings.boxed, color: settings.topbarColor, background: settings.topbarBg, orientation: "horizontal" },
        React.createElement("div", { className: "row align-items-center title-section-container" },
            React.createElement("div", { className: "col-md-12 company-logo align-items-center" },
                React.createElement("p", { className: "company-logo-name" },
                    React.createElement("strong", null, findCurrentPageName()
                    // || getCompanyContext()
                    )))),
        React.createElement(Actions, null),
        React.createElement(NavLoader, { loaded: true, type: "top-bar" })));
    const topNavBar = React.createElement(TopNavbar, null);
    const sideNav = (React.createElement(Navbar, { onClickOutside: toggleSidebar, opened: settings.sidebarOpened, color: settings.sidebarColor, background: settings.sidebarBg, orientation: "vertical" },
        React.createElement(Menu, { opened: settings.sidebarOpened, orientation: "vertical", data: menus, isAdmin: !isAdmin, toggleSidebar: toggleSidebar, resetSideBar: resetSideBar })));
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseLayout, { orientation: "vertical", topNavBar: topNavBar, nav: nav, sideNav: (menus === null || menus === void 0 ? void 0 : menus.length) === 0 ? null : sideNav, isSideNaveHidden: (menus === null || menus === void 0 ? void 0 : menus.length) === 0 ? true : false }, children)));
};
function mapStateTopProps(state, ownProps) {
    return {
        settings: state.settings,
        context: state.context,
        rootView: state.rootView,
    };
}
const mapDispatchToProps = {
    toggleSidebar,
    resetSideBar,
};
VerticalLayout.propTypes = {
    settings: PropTypes.object.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(VerticalLayout);
