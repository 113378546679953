export const defaultScrollPosition = 13;
export const inAppNotificationScrollPosition = 24.3;
export const userPageScrollPosition = 22.5;
export const ClientNotificationPageScrollPosition = 24;
export const defaultExpandableTableScrollPosition = 19.5;
export const defaultNestedTableScrollPosition = 37;
export const defaultTrackedItemTypeScrollPosition = 17.5;
export const financialReportNestedScrollPosition = 38;
export const roleListingScrollPosition = 14.5;
export const relationsListingScrollPosition = 35;
export const defaultDashboardScrollPosition = 46;
