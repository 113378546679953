import React, { useState } from "react";
import { Badge, Button, Dropdown, Menu, Tooltip } from "antd";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import localize from "../../../../../../core/utils/translation-files";
import { InAppStatus } from "../../../../../../core/enums/InAppNotifications";
import { getMessagesDateAndTimeStringGlobal } from "../../../../../../core/helpers/dateFormatter";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import { CheckOutlined } from "@ant-design/icons";
const RecentNotificationList = ({ data, openNotificationModal, handleViewAll, count, flagNotification, unflagNotification, readNotification, unreadNotification, }) => {
    const [visible, setVisible] = useState(false);
    const handleNotificationClick = (item) => {
        setVisible(!visible);
        openNotificationModal(item);
        (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.unread && readNotification(item === null || item === void 0 ? void 0 : item.id);
    };
    const notificationsMenu = (React.createElement(Menu, { className: "action-menu custom-notification-menu", style: { minWidth: "280px" } },
        React.createElement("span", { className: "dropdown-header" },
            React.createElement("h3", { className: "dropdown-title" }, "Notifications")),
        (data === null || data === void 0 ? void 0 : data.length) ? (data.map((item, index) => (React.createElement(Menu.Item, { className: "action-item d-flex w-100", key: index, onClick: handleNotificationClick.bind({}, item) },
            React.createElement("span", { className: "customIcons" },
                React.createElement(Tooltip, { placement: "top", title: localize([
                        `${(item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.read
                            ? "Notification.markAsUnread"
                            : "Notification.markAsRead"}`,
                    ]), key: index },
                    React.createElement(FontAwesomeIcon, { icon: faCircle, size: "sm", className: "unread-icon", onClick: (event) => {
                            event.stopPropagation();
                            (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.read
                                ? unreadNotification(item === null || item === void 0 ? void 0 : item.id)
                                : readNotification(item === null || item === void 0 ? void 0 : item.id);
                        }, style: (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.unread && {
                            color: "#17A99A",
                        } }))),
            React.createElement("span", { className: "text" },
                React.createElement("span", { className: "message", style: (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.unread
                        ? { fontWeight: "bold" }
                        : { fontWeight: "lighter" } }, item.subject),
                React.createElement("span", { className: "sub-text" },
                    React.createElement("span", null, getMessagesDateAndTimeStringGlobal(item === null || item === void 0 ? void 0 : item.createdDate)))),
            React.createElement("span", { className: "customIcons" },
                React.createElement(Tooltip, { placement: "top", title: localize([
                        `${item.flagged ? "Notification.unflag" : "Notification.flag"}`,
                        "Notification.thisNotification",
                    ]), key: index },
                    React.createElement(CheckOutlined, { className: "flag-icon", onClick: (event) => {
                            event.stopPropagation();
                            (item === null || item === void 0 ? void 0 : item.flagged) === true
                                ? unflagNotification(item === null || item === void 0 ? void 0 : item.id)
                                : flagNotification(item === null || item === void 0 ? void 0 : item.id);
                        }, style: (item === null || item === void 0 ? void 0 : item.flagged) ? { color: "#17A99A" } : { color: "#6e6b7b" } }))))))) : (React.createElement("span", { className: "empty-item" }, "No notifications")),
        (data === null || data === void 0 ? void 0 : data.length) ? (React.createElement("div", { className: "dropdown-actions" },
            React.createElement(Button, { type: "primary", className: "w-100", onClick: handleViewAll }, localize(["Notification.viewMoreNotifications"])))) : ("")));
    return (React.createElement("div", { "data-test": "recent-notification-list" },
        React.createElement(Dropdown, { className: "mr-3", overlay: notificationsMenu, trigger: ["click"], visible: visible, onVisibleChange: setVisible, placement: "bottomRight" },
            React.createElement(Badge, { className: "action-badge", count: count },
                React.createElement("span", { className: "notification-icon" },
                    React.createElement(RenderSvg, { icon: `${visible ? "ActiveNotificationBell" : "InActiveNotificationBell"}` }))))));
};
export default RecentNotificationList;
