import React from "react";
import CustomTable from "../../../../../../layout/components/customTable";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { getBreadCrumbSession } from "../../../../../../core/helpers/localStorage/breadCrumbSession";
import ActionsContainer from "../../../../admin/watchlist/components/watchlistTable/components/ActionsContainer";
import WatchlistPredictionChart from "../../../../../../layout/components/rechart/tiny-chart/WatchlistPredictionChart";
import HistoricalChart from "../../../../../../layout/components/rechart/tiny-chart/HistoricalChart";
import WatchlistRatingColumnComponent from "../../../../admin/watchlist/components/watchlistTable/components/WatchlistRatingColumnComponent";
import WatchlistTitleContainer from "../../../../admin/watchlist/components/watchlistTable/components/WatchlistTitleContainer";
import DashboardActionButtons from "../../components/dashboardActions";
import { defaultDashboardScrollPosition } from "../../../../../../core/constants/tableScrollConstants";
import { usePromiseTracker } from "react-promise-tracker";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import { getRouteByCategory } from "../../../../../../core/helpers/dasboardRoute";
import { DashboardApi } from "../../../../../../core/api/dashboardApi";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const SubCategoriesListing = ({ subcategories, setContextSubCategory, scaleBandArray, currentPage = null, pageSize = null, handleCustomTablePropChange = null, selectedFrequency = null, openNotificationModal = null, isPrintMode = false, handleToggleWatchListModal = null, params = null, userId = null, }) => {
    const history = useHistory();
    const { BREADCRUMB } = SessionVariables;
    const { setKey, getKey } = SessionStorage;
    const { pathname } = useLocation();
    const handleRowClick = (record) => {
        const { subCategoryId, trackedItemCompanyId, itemName, hierarchy, currentRatingInfoDTO, } = record;
        let breadCrumbList = getBreadCrumbSession();
        DashboardApi.getParentData([trackedItemCompanyId]).then((res) => {
            const { groupId, companyId } = res[trackedItemCompanyId];
            breadCrumbList = [
                ...breadCrumbList,
                {
                    path: `/${+subCategoryId}`,
                    name: itemName,
                    key: record === null || record === void 0 ? void 0 : record.itemId,
                    category: `${hierarchy} Dashboard`,
                    bandName: (record === null || record === void 0 ? void 0 : record.weight) !== 0 && !(record === null || record === void 0 ? void 0 : record.weightFlag)
                        ? currentRatingInfoDTO === null || currentRatingInfoDTO === void 0 ? void 0 : currentRatingInfoDTO.currentStatus
                        : "E",
                    bandColor: currentRatingInfoDTO === null || currentRatingInfoDTO === void 0 ? void 0 : currentRatingInfoDTO.currentStatusRgb,
                    parentPath: getRouteByCategory(record, groupId, companyId, params),
                    companyId: trackedItemCompanyId,
                },
            ];
            setKey(BREADCRUMB, breadCrumbList, sessionStorage);
            if (SessionStorage.getKey(SessionVariables.EXPANDED_KEYS, sessionStorage) !== null) {
                const ids = getKey(BREADCRUMB, sessionStorage).map((item) => parseInt(item.key));
                setKey(SessionVariables.EXPANDED_KEYS, ids === null || ids === void 0 ? void 0 : ids.slice(0, -1), sessionStorage);
            }
            history.push(`${pathname}/${+subCategoryId}`);
        });
    };
    const columns = [
        {
            fieldName: "itemName",
            title: ["Dashboard.subcategory"],
            className: "w-10 watchlistTitleColum ",
            width: 20,
            searchable: true,
            sortable: true,
            render: (row, data) => (React.createElement(React.Fragment, null,
                React.createElement(WatchlistTitleContainer, { row: row, data: data }),
                React.createElement(WatchlistRatingColumnComponent, { row: row, data: data, cardText: "SubCategory Value" }))),
        },
        {
            fieldName: "historyChartDTOList",
            title: ["Dashboard.historicalPerformance"],
            className: "w-30 watchlistChartColum",
            width: 27,
            align: "center",
            render: (row, data) => (React.createElement(React.Fragment, null,
                React.createElement(HistoricalChart, { data: data, row: row, frequency: +(selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code), isDashboard: true, weight: data === null || data === void 0 ? void 0 : data.weight, weightFlag: data === null || data === void 0 ? void 0 : data.weightFlag }))),
        },
        {
            fieldName: "predictedBandInfoDTO",
            title: (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "bold-content" }, "Vyzion"),
                "Prediction")),
            className: "w-30 watchlistPredictionColum",
            width: 26,
            align: "center",
            render: (data, row) => (React.createElement(WatchlistPredictionChart, { data: data, row: row, weight: row === null || row === void 0 ? void 0 : row.weight, weightFlag: row === null || row === void 0 ? void 0 : row.weightFlag })),
        },
        {
            fieldName: "notificationCount",
            title: ["Dashboard.criticalNotifications"],
            className: "w-15 watchlistActionColum",
            width: 12,
            align: "center",
            render: (row, data) => (React.createElement(ActionsContainer, { row: row, data: data, openMessageModal: openNotificationModal })),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        className: "w-5",
        width: 7,
        align: "center",
        render: (actions, row) => (React.createElement(React.Fragment, null, DashboardActionButtons(row, handleToggleWatchListModal, userId, "subCategoryRatingDTOs"))),
    };
    const displayedColumns = [...columns, actionColumn];
    const { promiseInProgress } = usePromiseTracker({ area: "LoadMore" });
    return (React.createElement("div", { className: "customListing watchlistTable", "data-test": "subCategories-Listing-Table" },
        React.createElement("div", { className: "watchlistLoading align-content-center justify-content-center row", hidden: !promiseInProgress },
            React.createElement(RenderSvg, { icon: "Loader" })),
        React.createElement(CustomTable, { data: subcategories, columns: displayedColumns, dataType: "any", scroll: true, scrollPosition: defaultDashboardScrollPosition, clientPagination: false, serverSearching: true, serverSorting: true, customTablePropChange: handleCustomTablePropChange, searchAllColumn: false, rowClickHandler: handleRowClick })));
};
export default SubCategoriesListing;
