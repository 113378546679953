import React from "react";
import CustomTable from "../../../../../../layout/components/customTable";
import { GroupResponse, } from "../../../../../../core/contracts/group/groupContracts";
import { useHistory } from "react-router-dom";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { companiesRoute } from "../../../../../../core/constants/routesPath";
import { actionColumnFilterOptions } from "../../../../../../core/constants/actionColumnFilterOptions";
import CustomTextHighlighter from "../../../../../../layout/components/customTextHighlighter";
const GroupTable = ({ data, actions, groupChanged, setContextCompany, currentPage, pageSize, handleCustomTablePropChange = null, isPrintMode = null, paginationSection = null, }) => {
    const history = useHistory();
    const handleRowClick = (record) => {
        setContextCompany(record);
        SessionStorage.setKey(SessionVariables.COMPANY, record);
        groupChanged();
        history.push(companiesRoute, { groupId: record === null || record === void 0 ? void 0 : record.id });
    };
    const columns = [
        {
            fieldName: "name",
            title: ["Group.group", "name"],
            width: 150,
            sortable: true,
            defaultSortOrder: "ascend",
            className: "bold-text",
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "emailAddress",
            title: ["Group.group", "email", "address"],
            width: 150,
            sortable: true,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "primaryTelephoneNumber",
            title: ["primary", "contact", "number"],
            width: 150,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "contactName",
            sortable: true,
            // searchable: true,
            title: ["primary", "contact", "name"],
            width: 150,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        width: 150,
        enableFilter: true,
        filterInfo: {
            listing: actionColumnFilterOptions,
            nestedKey: "active",
        },
        render: actions,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "companyTableComponent" },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: GroupResponse, rowClickHandler: handleRowClick, currentPage: currentPage, pageSize: pageSize, itemEnableKey: "active", totalCount: paginationSection === null || paginationSection === void 0 ? void 0 : paginationSection.totalRecords, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: paginationSection })));
};
export default GroupTable;
