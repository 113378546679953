var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Tooltip } from "antd";
import React from "react";
import { actionColumnFilterOptions } from "../../../../../../core/constants/actionColumnFilterOptions";
import { UserResponse } from "../../../../../../core/contracts/user/userContracts";
import localize from "../../../../../../core/utils/translation-files";
import CustomTable from "../../../../../../layout/components/customTable";
import CustomTextHighlighter from "../../../../../../layout/components/customTextHighlighter";
import UseCustomPagination from "../../../../../../layout/components/hooks/useCustomPagination";
export const UserListingTable = ({ data, actions, openRoleModal, isPrintMode = false, }) => {
    var _a;
    const _b = UseCustomPagination({
        totalRecords: (_a = data === null || data === void 0 ? void 0 : data.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange"]);
    const columns = [
        {
            width: 150,
            fieldName: "fullName",
            title: ["name"],
            sortable: true,
            defaultSortOrder: "ascend",
            className: "bold-text",
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            width: 150,
            fieldName: "emailAddress",
            title: ["User.notificationEmailAddress"],
            sortable: true,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            width: 150,
            fieldName: "username",
            title: ["User.userName"],
            sortable: true,
            className: "bold-text",
            render: (name) => (React.createElement(Tooltip, { title: name },
                React.createElement("strong", null,
                    React.createElement(CustomTextHighlighter, { text: name })))),
        },
        {
            fieldName: "professionName",
            title: ["User.profession"],
            sortable: true,
            width: 100,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "userTypeText",
            title: ["User.userType"],
            enableFilter: true,
            width: 100,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "userCompanyRoleDTOs",
            title: ["UserRole.roles"],
            width: 70,
            render: (userCompanyRoleDTOs, row) => (React.createElement(Tooltip, { placement: "top", title: localize(["User.user", "UserRole.roles"]) },
                React.createElement("strong", null,
                    React.createElement(Button, { className: "roleCountButton", shape: "circle", type: "default", onClick: openRoleModal.bind([], userCompanyRoleDTOs, row.firstName + " " + row.lastName) }, userCompanyRoleDTOs.length)))),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        enableFilter: true,
        filterInfo: {
            listing: actionColumnFilterOptions,
            nestedKey: "status",
        },
        render: actions,
        width: 140,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "users-listing-table" },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: UserResponse, currentPage: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.currentPage, pageSize: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.pageSize, totalCount: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.totalRecords, itemEnableKey: "status", scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps })));
};
