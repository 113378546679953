import React from "react";
import CustomTable from "../../../../../layout/components/customTable";
import CustomTextHighlighter from "../../../../../layout/components/customTextHighlighter";
const RoleTable = ({ roles, actions, renderRolePermissions, currentPage, pageSize, handleCustomTablePropChange = null, isPrintMode = false, paginationSection = null, setRoles = null, overAllData = null, }) => {
    const columns = [
        {
            fieldName: "name",
            title: ["Role.role", "name"],
            width: 250,
            sortable: true,
            defaultSortOrder: "ascend",
            className: "bold-text",
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "permissionsName",
            title: ["Role.permission", "name"],
            className: "role_permission",
            render: renderRolePermissions,
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        width: 100,
        className: "d-print-none",
        render: actions,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "roleTableComponent" },
        React.createElement(CustomTable, { data: roles, dataType: "any", columns: displayedColumns, 
            // scrollPosition={roleListingScrollPosition}
            currentPage: currentPage, pageSize: pageSize, totalCount: paginationSection === null || paginationSection === void 0 ? void 0 : paginationSection.totalRecords, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: paginationSection, handleSetData: setRoles, parentHandlerAllData: overAllData })));
};
export default RoleTable;
