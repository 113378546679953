import React from "react";
import { Button, Popconfirm, Table } from "antd";
import EditableRow from "./EditableRow";
import EditableCell from "./EditableCell";
import localize from "../../../../../../core/utils/translation-files";
function CompleteTaskTable(props) {
    const { dataSource, count, handleSave, handleDelete, financialActivity, financialActivityHead, } = props;
    const columns = [
        {
            title: `${localize(["Task.financialHead"])}`,
            dataIndex: "financialActivityHeadId",
            width: "30%",
            editable: true,
        },
        {
            title: `${localize(["Task.financialActivity"])}`,
            dataIndex: "financialActivityId",
            width: "30%",
            editable: true,
        },
        {
            title: `${localize(["Task.value"])}`,
            dataIndex: "value",
            width: "30%",
            editable: true,
        },
        {
            title: `${localize(["Task.actions"])}`,
            dataIndex: "operation",
            render: (_, record) => parseInt(record.id) != count - 1 ? (React.createElement(Popconfirm, { title: `${localize(["Task.deleteMessage"])}`, onConfirm: () => handleDelete(record.id) },
                React.createElement(Button, { danger: true, shape: "circle", type: "primary" },
                    React.createElement("span", { className: "icofont icofont-ui-delete" })))) : null,
        },
    ];
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const newColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return Object.assign(Object.assign({}, col), { onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
                financialActivity: financialActivity,
                financialActivityHead: financialActivityHead,
            }) });
    });
    return (React.createElement(Table, { components: components, className: "complete-task-table", rowClassName: () => "editable-row", bordered: true, dataSource: dataSource, columns: newColumns, pagination: false }));
}
export default CompleteTaskTable;
