import { APP_URL } from "../../config/constants";
export const indexRoute = "/";
export const usersRoute = "/vertical/users";
export const taskRoute = "/vertical/tasks";
export const registerUserRoute = `${APP_URL}/public/users/register-user`;
export const sitesRoute = "/vertical/sites";
export const addSiteRoute = "sites/add";
export const companiesRoute = "/vertical/companies";
export const addCompanyRoute = "companies/add";
export const groupsRoute = "/vertical/groups";
export const addGroupRoute = "groups/add";
export const editGroupRoute = "groups/edit/";
export const rolesRoute = "/vertical/roles";
export const trackedItemsListingRoute = "/vertical/manage-client-tracked-items";
export const addTrackedItemsRoute = "/vertical/manage-client-tracked-items/add";
export const editTrackedItemsRoute = "/vertical/manage-client-tracked-items/edit/";
export const trackedItemTypesListingRoute = "/vertical/manage-tracked-item-defaults";
export const addTrackedItemTypeRoute = "/vertical/manage-tracked-item-defaults/add";
export const editTrackedItemTypeRoute = "/vertical/manage-tracked-item-defaults/edit/";
export const viewTrackedItemChartRoute = "/vertical/tracked-item-chart/";
export const viewDashboardChartRoute = "/vertical/dashboard/";
export const historicalTasksRoute = "/vertical/tasks/historical-task";
export const loginRoute = "/public/sign-in";
export const resetPasswordRoute = "/public/reset-password";
export const forgetPasswordRoute = "/public/forget-password";
export const notFoundRoute = "/public/page-404";
export const unAuthorizedRoute = "/public/unauthorized";
export const trackedItemsDashboardRoute = "/tracked-items/dashboard";
export const watchlistRoute = "/vertical/watchlist";
export const dashboardRoute = "/vertical/dashboard";
export const homeRoute = "/vertical/home";
export const selectApplicationRoute = "/tracked-items/select-application";
export const notificationEventsRoute = "/vertical/notifications-events";
export const reportsRoute = "/vertical/reports";
