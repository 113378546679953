import { InAppNotificationApi } from "../../../core/api/inAppNotificationApi";
import { GET_UNREAD_NOTIFICATION_COUNT_SUCCESS, UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS, } from "./types";
export const getUnreadNotificationCountSuccess = (data) => ({
    type: GET_UNREAD_NOTIFICATION_COUNT_SUCCESS,
    payload: data,
});
export const updateUnreadNotificationCount = (data) => ({
    type: UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS,
    payload: data,
});
export function getUnreadNotificationsCount() {
    return function (dispatch) {
        return InAppNotificationApi.getNotificationsUnreadCount(true)
            .then((response) => {
            if (response) {
                dispatch(getUnreadNotificationCountSuccess(response));
                return response;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
