import { DashboardApi } from "../api/dashboardApi";
import { unAuthorizedRoute } from "../constants/routesPath";
import { SessionVariables } from "../constants/SessionVariables";
import isEmpty from "./isEmpty";
import { SessionStorage } from "./localStorage/sessionStorage";
export const dashboardNavigation = [
    { category: "Group Dashboard" },
    { category: "Company Dashboard" },
    { category: "Site Dashboard" },
    { category: "Category Dashboard" },
    { category: "Subcategory Dashboard" },
    { category: "Tracked Item" },
];
export const getRouteByCategory = (v, groupId, companyId, params = null) => {
    return v.hierarchy === "Group"
        ? `/${v.companyId}`
        : v.hierarchy === "Company"
            ? `/${groupId}/${v.trackedItemCompanyId}`
            : v.hierarchy === "Site"
                ? `/${groupId}/${companyId}/${v.trackedItemCompanyId}`
                : v.hierarchy === "Category"
                    ? `/${groupId}/${companyId}/${v.trackedItemCompanyId}/${v.categoryId}`
                    : v.hierarchy === "Subcategory"
                        ? `/${groupId}/${companyId}/${v.trackedItemCompanyId}/${params === null || params === void 0 ? void 0 : params.category_id}/${v.subCategoryId}`
                        : v.hierarchy === "Tracked Item" &&
                            `/${groupId}/${companyId}/${v.trackedItemCompanyId}/${params === null || params === void 0 ? void 0 : params.category_id}/${params === null || params === void 0 ? void 0 : params.sub_cat_id}/${v.itemId}`;
};
const getParentsRoute = (v, groupId, companyId) => {
    return v.category === "Tracked Item"
        ? `/${groupId}/${companyId}/${v.companyId}/${v.categoryId}/${v.subCategoryId}/${v.id}`
        : v.category === "Subcategory"
            ? `/${groupId}/${companyId}/${v.companyId}/${v.categoryId}/${v.subCategoryId}`
            : v.category === "Category"
                ? `/${groupId}/${companyId}/${v.companyId}/${v.categoryId}`
                : v.category === "Site"
                    ? `/${groupId}/${companyId}/${v.companyId}`
                    : v.category === "Company"
                        ? `/${groupId}/${companyId}`
                        : v.category === "Group" && `/${v.companyId}`;
};
const addBreadcrumb = (node, groupId, companyId) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        path: node.category === "Category"
            ? `/${node.categoryId}`
            : node.category === "Subcategory"
                ? `/${node.subCategoryId}`
                : node.category === "Tracked Item"
                    ? `/${node.id}`
                    : `/${node.companyId}`,
        name: (_a = node.name) !== null && _a !== void 0 ? _a : node.title,
        category: `${node.category} ${!node.category.includes("Tracked") ? "Dashboard" : ""}`,
        bandName: node.weight !== 0 && !node.weightFlag
            ? (_c = (_b = node.bandName) !== null && _b !== void 0 ? _b : node.band) !== null && _c !== void 0 ? _c : "X"
            : "E",
        bandColor: (_e = (_d = node.rgbColor) !== null && _d !== void 0 ? _d : node.bandColor) !== null && _e !== void 0 ? _e : "darkgray",
        key: (_g = (_f = node.id) !== null && _f !== void 0 ? _f : node.itemId) !== null && _g !== void 0 ? _g : node.key,
        parentPath: getParentsRoute(node, groupId, companyId),
    };
};
const getBreadcrumbsPath = (breadcrumbsData, groupId, companyId) => {
    let breadcrumbs = [];
    for (let i = 0; i < breadcrumbsData.length; i++) {
        const node = breadcrumbsData[i];
        breadcrumbs = [...breadcrumbs, addBreadcrumb(node, groupId, companyId)];
    }
    const dashboardCategories = dashboardNavigation.map((c) => c.category.trim());
    breadcrumbs = [...breadcrumbs].sort((a, b) => {
        return (dashboardCategories.indexOf(a.category.trim()) -
            dashboardCategories.indexOf(b.category.trim()));
    });
    return breadcrumbs;
};
export const viewTrackedItemChart = (trackedItemId, filters, siteId, history, CompanyByGroup, SiteByCompany, isSingleCompanyAndSite, isSinglSite, isSingleCompany, isDashboardViewAllowed, isFinancialViewAllowed, isSummary = false) => {
    let updatedBreadcrumb;
    isDashboardViewAllowed && isFinancialViewAllowed
        ? // TrackedItemApi.getTrackedItemId(trackedItem?.type, siteId).then((res) => {
            DashboardApi.getParentData([siteId]).then((item) => {
                const { groupId, companyId } = item[siteId];
                if (!isEmpty(groupId)) {
                    DashboardApi.getTrackedItemBreadCrumb(trackedItemId).then((breadcrumbs) => {
                        updatedBreadcrumb = getBreadcrumbsPath(breadcrumbs, groupId, companyId);
                        SessionStorage.setKey(SessionVariables.BREADCRUMB, updatedBreadcrumb, sessionStorage);
                        if (SessionStorage.getKey(SessionVariables.EXPANDED_KEYS, sessionStorage) !== null) {
                            const ids = SessionStorage.getKey(SessionVariables.BREADCRUMB, sessionStorage).map((item) => parseInt(item.key));
                            SessionStorage.setKey(SessionVariables.EXPANDED_KEYS, ids === null || ids === void 0 ? void 0 : ids.slice(0, -1), sessionStorage);
                        }
                        return history.push("/vertical/dashboard", {
                            filters: filters,
                            trackedItemId: trackedItemId,
                            isSummary: isSummary,
                        });
                    });
                }
                // });
            })
        : history.push(unAuthorizedRoute, {
            subText: "permission",
        });
};
