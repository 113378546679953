import { Button, Radio, Tabs } from "antd";
import { useFormik } from "formik";
import React, { useImperativeHandle, useState } from "react";
import * as Yup from "yup";
import localize from "../../../../core/utils/translation-files";
import Asterik from "../../../internal/shared/helper-components/Asterik";
import { notificationOpt, NOTIFICATION_TYPE_COLLECTIVE, timeOpt, NOTIFICATION_TYPE_INDIVIDUAL, } from "../../../../core/constants/notificationSettingsConstants";
import SingleSelectNew from "../../../../layout/components/select/SingleSelectNew";
import LookupDropDown from "../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../core/constants/lookup";
import { arePropertiesEmpty } from "../../../../core/helpers/isEmpty";
import PromptComponent from "../../../../layout/components/prompt-message";
import isActionAllowed from "../../../../core/authorization/userPermissions";
import { REPORT_CATEGORY_CARE_DELIVERY, REPORT_CATEGORY_CARE_FINANCIALS, } from "../../../../core/constants/permissionConstants";
const NotificationSettingsForm = ({ initialNotificationSetting, onSubmit, modalDescription, modalTitle, closeModal, tabOptions, valuesRef, }) => {
    var _a, _b;
    const { TabPane } = Tabs;
    const [selectedTab, setSelectedTab] = useState((_a = tabOptions[0]) === null || _a === void 0 ? void 0 : _a.code);
    const [showError, setShowError] = useState(false);
    let SettingsSchema = Yup.array().of(Yup.object({
        notificationType: Yup.number().required(localize(["Field", "isRequired!"])),
        notificationFrequency: Yup.number().when("notificationType", {
            is: NOTIFICATION_TYPE_COLLECTIVE,
            then: Yup.number()
                .required(localize(["Notification.frequency", "isRequired!"]))
                .nullable(),
            otherwise: Yup.number().nullable(),
        }),
        timeInterval: Yup.string().when("notificationType", {
            is: NOTIFICATION_TYPE_COLLECTIVE,
            then: Yup.string()
                .required(localize(["Notification.time", "isRequired!"]))
                .nullable(),
            otherwise: Yup.string().nullable(),
        }),
        sortId: Yup.string().when("notificationType", {
            is: NOTIFICATION_TYPE_COLLECTIVE,
            then: Yup.string()
                .required(localize(["Notification.sort", "isRequired!"]))
                .nullable(),
            otherwise: Yup.string().nullable(),
        }),
    }));
    const { handleSubmit, values, errors, dirty, setFieldValue, resetForm } = useFormik({
        initialValues: initialNotificationSetting,
        validationSchema: SettingsSchema,
        onSubmit: (values) => {
            onSubmit(values, resetForm);
        },
    });
    const handleCancel = () => {
        closeModal(resetForm);
    };
    const setValues = (val, field) => {
        setShowError(false);
        setFieldValue(field, val);
    };
    useImperativeHandle(valuesRef, () => {
        return {
            isPrompt: dirty,
        };
    }, [dirty]);
    return (React.createElement("div", { className: "reset-password", "data-test": "resetPasswordForm" },
        React.createElement("h4", { className: "mt-0 mb-2" }, modalTitle),
        React.createElement("p", { className: "text-color-200" }, modalDescription),
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: `mb-1 customForm`, name: "basic", onSubmit: handleSubmit },
            React.createElement("div", null,
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        " ",
                        localize(["Notification.notificationType"]),
                        React.createElement(Asterik, null),
                        " ")),
                React.createElement("div", { className: "form-group" },
                    React.createElement(Radio.Group, { onChange: (e) => {
                            tabOptions && tabOptions.length
                                ? tabOptions.map((value, index) => {
                                    setValues(e.target.value, `${index}.notificationType`);
                                    if (e.target.value === 0) {
                                        setValues(null, `${index}.notificationFrequency`);
                                        setValues(null, `${index}.timeInterval`);
                                        setValues(null, `${index}.sortId`);
                                    }
                                    return 0;
                                })
                                : setValues(e.target.value, `${0}.notificationType`);
                        }, defaultValue: (_b = values[0]) === null || _b === void 0 ? void 0 : _b.notificationType, buttonStyle: "solid", name: "notificationType" },
                        " ",
                        notificationOpt.map(({ value, text }) => {
                            var _a;
                            return ((value === 0 ||
                                (value === 1 &&
                                    (isActionAllowed(REPORT_CATEGORY_CARE_FINANCIALS) ||
                                        isActionAllowed(REPORT_CATEGORY_CARE_DELIVERY)))) && (React.createElement(Radio, { value: value, key: value, disabled: !(tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) &&
                                    ((_a = values[0]) === null || _a === void 0 ? void 0 : _a.notificationType) ===
                                        NOTIFICATION_TYPE_INDIVIDUAL },
                                text,
                                " Notification")));
                        })))),
            tabOptions && tabOptions.length ? (React.createElement("div", null,
                React.createElement(Tabs, { defaultActiveKey: selectedTab, onChange: setSelectedTab }, tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.map(({ code, text }, index) => {
                    var _a, _b, _c, _d, _e, _f, _g;
                    return (React.createElement(TabPane, { tab: text, key: code },
                        React.createElement("div", { className: " notificationSettingsFrequency" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["Notification.frequency"]),
                                            React.createElement(Asterik, null))),
                                    React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: (_a = values[index]) === null || _a === void 0 ? void 0 : _a.notificationFrequency, onChange: (val, obj) => setValues(obj, `${index}.notificationFrequency`), inputName: "notificationFrequency", localizationKey: ["Notification.frequency"], disabled: ((_b = values[index]) === null || _b === void 0 ? void 0 : _b.notificationType) !==
                                            NOTIFICATION_TYPE_COLLECTIVE, lookUpType: LookupNames.LKCollectiveNotificationFrequency, error: errors[index] &&
                                            errors[index]["notificationFrequency"] &&
                                            showError
                                            ? errors[index]["notificationFrequency"]
                                            : null }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["Notification.time"]),
                                            React.createElement(Asterik, null))),
                                    React.createElement(SingleSelectNew, { key: "timeInterval", allowClear: false, selectedValue: (_d = (_c = values[index]) === null || _c === void 0 ? void 0 : _c.timeInterval) === null || _d === void 0 ? void 0 : _d.slice(0, 5), className: errors.timeInterval && showError
                                            ? "require-ant-select"
                                            : "", placeholder: "Select a Time", onChange: (val, obj) => {
                                            setValues(obj.text, `${index}.timeInterval`);
                                            setValues(4, `${index}.sortId`);
                                        }, disabled: ((_e = values[index]) === null || _e === void 0 ? void 0 : _e.notificationType) !==
                                            NOTIFICATION_TYPE_COLLECTIVE, options: timeOpt, valueProperty: "value", textProperty: "text", error: errors[index] &&
                                            errors[index]["timeInterval"] &&
                                            showError &&
                                            errors[index]["timeInterval"] }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["Notification.sortBy"]),
                                            React.createElement(Asterik, null))),
                                    React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: (_f = values[index]) === null || _f === void 0 ? void 0 : _f.sortId, onChange: (val, obj) => setValues(obj, `${index}.sortId`), inputName: "sortId", localizationKey: ["Notification.sort"], disabled: ((_g = values[index]) === null || _g === void 0 ? void 0 : _g.notificationType) !==
                                            NOTIFICATION_TYPE_COLLECTIVE, lookUpType: LookupNames.LKCollectiveNotificationSort, error: errors[index] &&
                                            errors[index]["sortId"] &&
                                            showError
                                            ? errors[index]["sortId"]
                                            : null }))),
                            !arePropertiesEmpty(errors) && showError ? (React.createElement("p", { className: "error", style: { position: "inherit" } }, localize([
                                "Notification.allCategories",
                                "areRequired!",
                            ]))) : null)));
                })))) : null,
            React.createElement("div", { className: "form-group row justify-content-between align-items-center" },
                React.createElement(Button, { type: "default", onClick: handleCancel }, localize(["Cancel"])),
                React.createElement(Button, { type: "primary", htmlType: "submit", onClick: () => setShowError(true), disabled: !dirty ? true : false }, localize(["Save"]))))));
};
export default NotificationSettingsForm;
