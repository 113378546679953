import React from "react";
import RenderSvg from "../../../../../../../layout/components/icons/RenderSvg";
const ActionsContainer = ({ row = null, data, openMessageModal = null }) => {
    return (React.createElement("div", { className: `watchlistActionContainer row align-center justify-content-center ${!(data === null || data === void 0 ? void 0 : data.notificationCount) && "zeroNotification"}` },
        React.createElement("div", { className: "criticalMessages", onClickCapture: (e) => {
                e.stopPropagation();
                openMessageModal(data);
            } },
            React.createElement("div", { className: "row align-items-center justify-content-center " },
                React.createElement(RenderSvg, { icon: "WatchlistMessage" }),
                React.createElement("p", { className: "bold-text" },
                    (data === null || data === void 0 ? void 0 : data.notificationCount) !== 0 && (data === null || data === void 0 ? void 0 : data.notificationCount),
                    " ",
                    (data === null || data === void 0 ? void 0 : data.notificationCount) === 1
                        ? "Notification"
                        : !(data === null || data === void 0 ? void 0 : data.notificationCount)
                            ? "No Notifications"
                            : "Notifications")),
            (data === null || data === void 0 ? void 0 : data.actionRequired) && React.createElement("p", { className: "bold-text" }, "Urgent & Critical"))));
};
export default ActionsContainer;
