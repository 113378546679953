import { TaskApi } from "../../core/api/taskApi";
import { ON_TASK_SUBMITED_SUCCESS, GET_TASKS_SUCCESS } from "./types";
export const getTasksSuccess = (data, taskType) => ({
    type: GET_TASKS_SUCCESS,
    payload: data,
    taskType: taskType,
});
export const onTaskSubmit = () => ({
    type: ON_TASK_SUBMITED_SUCCESS,
    payload: [],
    taskType: 0,
});
export function getTasks(siteId, type) {
    return function (dispatch) {
        return TaskApi.getTasks(siteId, type)
            .then((tasks) => {
            if (tasks.length > 0) {
                dispatch(getTasksSuccess(tasks, type));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
