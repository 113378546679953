import React, { useState } from "react";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../../core/utils/translation-files";
import CustomModal from "../../../../../../../layout/components/customModal";
import TableActions from "../../../../../../../layout/components/customTable/tableActions";
import { notifications } from "../../../../../../../layout/components/notifications";
import NotificationBandsForm from "./notificationBandsForm";
import NotificationRulesTable from "./NotificationRulesTable";
function NotificationRules({ notificationsList, setNotificationsList, setOverWrittenNotificationList, overWrittenNotificationList, filterNotificationsListHandler, userBandOptions, resetDefaultClientNotifications, isEdit, }) {
    const [bandModelVisibility, setBandModelVisibility] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(undefined);
    const openBandModal = (notification) => {
        setSelectedNotification(() => {
            var _a, _b, _c;
            const selected = (_a = overWrittenNotificationList === null || overWrittenNotificationList === void 0 ? void 0 : overWrittenNotificationList.find(({ id }) => id === (notification === null || notification === void 0 ? void 0 : notification.id))) !== null && _a !== void 0 ? _a : [];
            let notificationReceipent = (_b = selected === null || selected === void 0 ? void 0 : selected.recipient) !== null && _b !== void 0 ? _b : (_c = notification === null || notification === void 0 ? void 0 : notification.recipient) === null || _c === void 0 ? void 0 : _c.split(",");
            return Object.assign(Object.assign({}, notification), { recipient: notificationReceipent !== null && notificationReceipent !== void 0 ? notificationReceipent : [] });
        });
        setBandModelVisibility(true);
    };
    const handleShowOverWriteAction = (notification) => {
        return ((overWrittenNotificationList === null || overWrittenNotificationList === void 0 ? void 0 : overWrittenNotificationList.some(({ id }) => id === (notification === null || notification === void 0 ? void 0 : notification.id))) ||
            (isEdit &&
                !isEmpty(notification.companyId) &&
                !isEmpty(notification.referenceId)));
    };
    const closeBandModel = () => setBandModelVisibility(false);
    const resetClientNotificationHandler = (data) => {
        if (!isEmpty(data.companyId) && !isEmpty(data.referenceId)) {
            resetDefaultClientNotifications(data === null || data === void 0 ? void 0 : data.id).then((response) => {
                if (response) {
                    filterNotificationsListHandler(data === null || data === void 0 ? void 0 : data.id, true);
                    notifications.success(localize([
                        "Notification.notification",
                        "Notification.resetToDefault",
                        "successfully",
                    ]));
                }
            });
        }
        else {
            filterNotificationsListHandler(data === null || data === void 0 ? void 0 : data.id);
        }
    };
    const notificationsActions = (rule) => {
        const actionArray = [
            {
                priority: 2,
                title: ["manage", "Notification.notification"],
                btnType: "primary",
                onClick: openBandModal.bind({}, rule),
                svgIcon: "Edit",
            },
            {
                priority: 1,
                title: ["Notification.resetToDefault", "Notification.notification"],
                btnType: "primary",
                onClick: resetClientNotificationHandler.bind({}, rule),
                svgIcon: "Reset",
                iconStyle: {
                    color: !handleShowOverWriteAction(rule) ? "#B3B2B7" : "#6e6b7b",
                },
                disbaled: !handleShowOverWriteAction(rule),
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "box-main-section" },
            React.createElement("section", { className: "box-section" },
                React.createElement("div", { className: "box-title" },
                    React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.notificationRules"]))),
                React.createElement("div", { className: "box-description" },
                    React.createElement(NotificationRulesTable, { data: notificationsList, actions: notificationsActions })))),
        React.createElement(CustomModal, { visible: bandModelVisibility, onCancel: closeBandModel, maskClosable: false, destroyOnClose: true, footer: null, title: ["manage", "Notification.notification"] },
            React.createElement(NotificationBandsForm, { onSubmit: closeBandModel, onCancel: closeBandModel, userBandOptions: userBandOptions, setOverWrittenNotificationList: setOverWrittenNotificationList, overWrittenNotificationList: overWrittenNotificationList, selectedNotification: selectedNotification, setSelectedNotification: setSelectedNotification, notificationsList: notificationsList, setNotificationsList: setNotificationsList }))));
}
export default NotificationRules;
