var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { actionColumnFilterOptions } from "../../../../../core/constants/actionColumnFilterOptions";
import { printContext } from "../../../../../core/contexts/printContext";
import usePermissions from "../../../../../core/hooks/usePermissions";
import CustomTable from "../../../../../layout/components/customTable";
import CustomTextHighlighter from "../../../../../layout/components/customTextHighlighter";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import VisionCommentsPopup from "./VisionCommentsPopup";
export const GlobalNotificationsTable = ({ data, actions, isPrintMode = null, setFormChange = null, formChange = null, }) => {
    var _a, _b;
    const { isDelphieorSuperUser, } = usePermissions();
    const [newData, setNewData] = useState(data);
    const disabledNotificationNames = [
        "Notification Disable",
        "Notification Enable",
        "Notification Update",
    ];
    useEffect(() => {
        let tempData = [];
        data === null || data === void 0 ? void 0 : data.forEach((item) => {
            !(disabledNotificationNames === null || disabledNotificationNames === void 0 ? void 0 : disabledNotificationNames.includes(item === null || item === void 0 ? void 0 : item.name)) && tempData.push(item);
        });
        setNewData(tempData);
    }, [data]);
    const _c = UseCustomPagination({
        totalRecords: isDelphieorSuperUser ? ((_a = data === null || data === void 0 ? void 0 : data.length) !== null && _a !== void 0 ? _a : 0) : ((_b = newData === null || newData === void 0 ? void 0 : newData.length) !== null && _b !== void 0 ? _b : 0),
        clientPagination: true,
    }), { handleCustomTablePropChange } = _c, restPaginationProps = __rest(_c, ["handleCustomTablePropChange"]);
    const { nodeRef } = useContext(printContext);
    const columns = [
        {
            fieldName: "name",
            title: ["Notification.notification", "Notification.event"],
            sortable: true,
            defaultSortOrder: "ascend",
            width: 225,
            className: "bold-text",
            render: (text) => (React.createElement("div", null,
                React.createElement(Tooltip, { title: text, placement: "top", overlayClassName: "visionCommentsTooltip" }, text))),
        },
        {
            fieldName: "referenceTypeText",
            title: ["Notification.notification", "Notification.category"],
            sortable: true,
            enableFilter: true,
            width: 225,
            render: (text) => (React.createElement("div", null,
                React.createElement(Tooltip, { title: text, placement: "top", overlayClassName: "visionCommentsTooltip" }, text))),
        },
        {
            fieldName: "description",
            title: ["Description"],
            sortable: true,
            enableFilter: false,
            width: 225,
            render: (text) => (React.createElement("div", null,
                React.createElement(Tooltip, { title: text, placement: "topLeft", overlayClassName: "visionCommentsTooltip" }, text))),
        },
        {
            fieldName: "visionComments",
            title: ["Notification.visionComments"],
            sortable: true,
            enableFilter: false,
            width: 225,
            render: (text, row) => {
                return (React.createElement("div", { className: "visionCommentsContainer d-flex" },
                    React.createElement(Tooltip, { title: text, placement: "top", overlayClassName: "visionCommentsTooltip" }, text),
                    React.createElement(VisionCommentsPopup, { data: row, formChange: formChange, setFormChange: setFormChange })));
            },
        },
        {
            fieldName: "sampleEmailMessage",
            title: ["Sample Email Message"],
            sortable: true,
            enableFilter: false,
            width: 225,
            render: (text) => (React.createElement("div", null,
                React.createElement(Tooltip, { title: text, placement: "topLeft", overlayClassName: "visionCommentsTooltip" }, text))),
        },
        {
            fieldName: "recipient",
            title: ["Bands"],
            sortable: true,
            enableFilter: true,
            width: 110,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        enableFilter: true,
        filterInfo: {
            listing: actionColumnFilterOptions,
            nestedKey: "enabled",
        },
        render: actions,
        width: 110,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "global-notifications-listing-table", ref: nodeRef },
        React.createElement(CustomTable, { data: isDelphieorSuperUser ? data : newData, columns: displayedColumns, dataType: "any", itemEnableKey: "enabled", currentPage: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.currentPage, pageSize: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.pageSize, totalCount: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.totalRecords, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps })));
};
