import React, { useEffect } from "react";
import SitesDashboard from "./SitesDashboard";
import CategoriesDashboard from "./CategoriesDashboard";
import SubCategoriesDashboard from "./SubcategoriesDashboard";
import TrackedItemsDashboard from "./TrackedItemsDashboard";
import CustomMemoryRouter from "../../../../layout/components/memoryRouter";
import { clearDashboardSession } from "../../../../core/helpers/localStorage/breadCrumbSession";
import MovingAverageAndProjectionChartContainer from "../charts/line-chart/movingAverageAndProjection";
import RoadMapContainer from "./DashboardRoadmap";
import CompanyDashboard from "./CompanyDashboard";
const Dashboard = () => {
    useEffect(() => {
        return () => {
            clearDashboardSession();
        };
    }, []);
    const routes = [
        { path: "", component: RoadMapContainer },
        // { path: "", component: GroupDashboard },
        { path: ":group_id", component: CompanyDashboard },
        // { path: ":id/:groupNode", component: CompanyDashboardContainer },
        { path: ":group_id/:comp_id", component: SitesDashboard },
        // { path: ":id/:companyNode", component: SitesDashboard },
        { path: ":group_id/:comp_id/:site_id", component: CategoriesDashboard },
        // { path: ":id/:siteNode", component: CategoriesDashboard },
        {
            path: ":group_id/:comp_id/:site_id/:category_id",
            component: SubCategoriesDashboard,
        },
        // {
        //   path: ":id/:categoryNode",
        //   component: SubCategoriesDashboard,
        // },
        {
            path: ":group_id/:comp_id/:site_id/:category_id/:sub_cat_id",
            component: TrackedItemsDashboard,
        },
        // {
        //   path: ":id/:subcategoryNode",
        //   component: TrackedItemsDashboard,
        // },
        {
            path: ":group_id/:comp_id/:site_id/:category_id/:sub_cat_id/:id",
            component: MovingAverageAndProjectionChartContainer,
        },
        // {
        //   path: ":id/trackedItemNode",
        //   component: MovingAverageAndProjectionChartContainer,
        // },
    ];
    return React.createElement(CustomMemoryRouter, { routes: routes });
};
export default Dashboard;
