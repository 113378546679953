import { GET_PERMISSIONS_SUCCESS } from './types';
import { initialState as initialStoreState } from '../initialState';
const initialState = initialStoreState.permissions;
export const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PERMISSIONS_SUCCESS: {
            return [...action.payload];
        }
        default: {
            return state;
        }
    }
};
