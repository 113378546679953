import React from "react";
import RoleForm from "./roleForm";
import PropTypes from "prop-types";
import CustomModal from "../../../../../layout/components/customModal";
const EditRole = ({ existingRole, visible, onClose, onEdited, permissions, lookupPermissionCategories, roles, valuesRef, }) => {
    return (React.createElement("div", { "data-test": "EditRoleComponent" },
        React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["update", "Role.role"], overflowY: false, width: "80%" },
            React.createElement("div", { "data-test": "EditRoleFormComponent" },
                React.createElement(RoleForm, { onCancel: onClose, onSubmit: onEdited, role: existingRole, submitText: "Save", permissions: permissions, lookupPermissionCategories: lookupPermissionCategories, roles: roles, valuesRef: valuesRef })))));
};
EditRole.propTypes = {
    existingRole: PropTypes.shape({
        companyId: PropTypes.number,
        id: PropTypes.number,
        level: PropTypes.number,
        name: PropTypes.string,
        permissions: PropTypes.array,
    }),
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onEdited: PropTypes.func,
    permissions: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.number,
    })),
    companies: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        parentId: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.number,
        registrationNumber: PropTypes.string,
        cqcRegistrationNumber: PropTypes.string,
        contactName: PropTypes.string,
        primaryTelephoneType: PropTypes.number,
        primaryTelephoneNumber: PropTypes.string,
        secondaryTelephoneType: PropTypes.number,
        secondaryTelephoneNumber: PropTypes.string,
        emailAddress: PropTypes.string,
        addresses: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            line1: PropTypes.string,
            line2: PropTypes.string,
            town: PropTypes.string,
            county: PropTypes.string,
            postCode: PropTypes.string,
            country: PropTypes.string,
            type: PropTypes.number,
            id: PropTypes.number,
        })),
        active: PropTypes.bool,
    })),
    roles: PropTypes.arrayOf(PropTypes.shape({
        companyId: PropTypes.number,
        id: PropTypes.number,
        level: PropTypes.number,
        name: PropTypes.string,
        permissions: PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.string,
            description: PropTypes.string,
            id: PropTypes.number,
            name: PropTypes.string,
            type: PropTypes.number,
        })),
    })),
};
export default EditRole;
