import { GET_COMPANIES_SUCCESS, ADD_COMPANY_SUCCESS, UPDATE_COMPANY_SUCCESS, ACTIVATE_COMPANY_SUCCESS, IN_ACTIVATE_COMPANY_SUCCESS, } from "./types";
const initialState = [];
export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANIES_SUCCESS: {
            return [...action.payload];
        }
        case UPDATE_COMPANY_SUCCESS: {
            const editedCompany = state.map((el) => el.id !== action.payload.id ? el : action.payload);
            return [...editedCompany];
        }
        case ADD_COMPANY_SUCCESS: {
            return [Object.assign({}, action.payload), ...state];
        }
        case ACTIVATE_COMPANY_SUCCESS: {
            const tempCompanies = [...state].map((company) => (Object.assign(Object.assign({}, company), { active: company.id === action.payload ? true : company.active })));
            return [...tempCompanies];
        }
        case IN_ACTIVATE_COMPANY_SUCCESS: {
            const tempCompanies = [...state].map((company) => (Object.assign(Object.assign({}, company), { active: company.id === action.payload ? false : company.active })));
            return [...tempCompanies];
        }
        default: {
            return state;
        }
    }
};
