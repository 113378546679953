import { useEffect, useState } from "react";
import ResizableColumn from "../../layout/components/resizableColumn/resizableColumn";
export const useResizableColumn = (nonResizableColumns, data) => {
    const [columns, setColumns] = useState(nonResizableColumns);
    const components = {
        header: {
            cell: ResizableColumn,
        },
    };
    useEffect(() => {
        setColumns(nonResizableColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, nonResizableColumns === null || nonResizableColumns === void 0 ? void 0 : nonResizableColumns.length]);
    const handleResize = (index) => (e, { size }) => {
        const nextColumns = [...columns];
        nextColumns[index] = Object.assign(Object.assign({}, nextColumns[index]), { width: size.width });
        setColumns(nextColumns);
    };
    const resizableColumns = columns.map((col, index) => (Object.assign(Object.assign({}, col), { onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index),
        }) })));
    return {
        components: components,
        resizableColumns: resizableColumns,
    };
};
