import { GET_BAND_SUCCESS } from "./types";
import { LookupApi } from "../../core/api/lookupApi";
export const getBandSuccess = (data) => ({
    type: GET_BAND_SUCCESS,
    payload: data,
});
export function getBand() {
    return function (dispatch) {
        LookupApi.getBandLookup()
            .then((response) => {
            if (Array.isArray(response)) {
                dispatch(getBandSuccess(response));
                return response;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
