import { httpGet, httpPost, httpPut } from "./request/requestApi";
const endPointPath = "notification";
export class NotificationApi {
    static getLocalNotifications() {
        return httpGet(`${endPointPath}/list/definitions/report`);
    }
    static getClientNotifications(groupId, companyId, siteId, categoryType) {
        return httpGet(`${endPointPath}/list/ClientNotifications/${groupId}/${companyId}/${siteId}/${categoryType}`);
    }
    static getGlobalNotifications(categoryType) {
        return httpGet(`${endPointPath}/list/GlobalNotifications/${categoryType}`);
    }
    static updateGlobalNotificationComments(data) {
        return httpPost(`${endPointPath}/updateComments`, { body: data });
    }
    static saveClientNotification(data) {
        return httpPost(`${endPointPath}/save/clientNotification`, { body: data });
    }
    static editClientNotification(data) {
        return httpPut(`${endPointPath}/save/clientNotification`, { body: data });
    }
    static getSingleCLientNotification(id) {
        return httpGet(`${endPointPath}/getNotificationInstance/${id}`);
    }
    static activateGlobalNotification(id) {
        return httpGet(`${endPointPath}/enable/globalNotification/${id}`);
    }
    static inActivateGlobalNotification(id) {
        return httpGet(`${endPointPath}/disable/globalNotification/${id}`);
    }
    static activateClientNotification(id) {
        return httpGet(`${endPointPath}/enable/clientNotification/${id}`);
    }
    static inActivateClientNotification(id) {
        return httpGet(`${endPointPath}/disable/clientNotification/${id}`);
    }
    static resetDefaultClientNotifications(notificationInstanceId) {
        return httpGet(`${endPointPath}/setToDefault/${notificationInstanceId}`);
    }
    static getNotificationsByRoadMapListId(roadMapId, area) {
        return httpPost(`${endPointPath}/getNotificationsCountFlag`, {
            body: roadMapId,
            area,
        });
    }
    static getAllNotificationCount(itemId, silent = false) {
        return httpPost(`notification/getAllNotificationsCount`, {
            silent: silent,
            body: itemId,
        });
    }
    static getAllNotifications(body, silent = false) {
        return httpPost(`notification/getAllNotifications`, {
            silent: silent,
            body: body,
        });
    }
}
