import React from "react";
import { Button, Input } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoginOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import localize from "../../../../core/utils/translation-files";
import { forgetPasswordRoute } from "../../../../core/constants/routesPath";
import { inputDefaultLength } from "../../../../core/utils/default-length";
const intialState = {
    username: "",
    password: "",
};
const LoginForm = (props) => {
    const LoginSchema = Yup.object({
        username: Yup.string()
            .min(2, localize(["minimum", "Login.2", "characters"]))
            // .max(35, localize(["maximum", "Login.35", "characters"]))
            .required(localize(["Login.username", "isRequired!"])),
        password: Yup.string()
            .required(localize(["password", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
    });
    const login = useFormik({
        initialValues: intialState,
        validationSchema: LoginSchema,
        onSubmit: (values, { setErrors, resetForm }) => {
            props.LoginHandler(values, setErrors);
        },
    });
    return (React.createElement("div", null,
        React.createElement("h4", { className: "mt-0 mb-1" }, localize(["Login.loginForm"])),
        React.createElement("p", { className: "text-color-200" }, localize(["Login.loginToAccessYourAccount"])),
        React.createElement("form", { className: "mb-1 loginForm", name: "basic", onSubmit: login.handleSubmit },
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["Login.username"]), type: "text", name: "username", value: login.values.username, onChange: login.handleChange, className: `${login.errors.username && login.touched.username
                        ? "input-error"
                        : null}` }),
                login.errors.username && login.touched.username && (React.createElement("p", { className: "error" }, login.errors.username))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["password"]), type: "password", name: "password", value: login.values.password, onChange: login.handleChange, className: `${login.errors.password && login.touched.password
                        ? "input-error"
                        : null}` }),
                login.errors.password && login.touched.password && (React.createElement("p", { className: "error" }, login.errors.password))),
            login.errors["invalid"] && (React.createElement("p", { className: "error" }, login.errors["invalid"])),
            React.createElement("div", { className: "form-group" },
                React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LoginOutlined, { style: { fontSize: "1.3rem" } }) }, localize(["Button.submit"])))),
        React.createElement("br", null),
        React.createElement(Link, { to: forgetPasswordRoute }, localize(["Login.forgotYourPassword"]))));
};
export default LoginForm;
