var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { httpGet, httpPost } from "./request/requestApi";
const endPointPath = "financial";
export class FinancialApi {
    static getFinancialTrackedItemData(siteId, year, month, type) {
        return __awaiter(this, void 0, void 0, function* () {
            return httpGet(`${endPointPath}/financialData/${siteId}/${year}${month}/${type}`);
        });
    }
    static saveFinancialData(data) {
        return httpPost(`${endPointPath}/saveFinancialData `, { body: data });
    }
    static getFinancialTrackedItemDataById(taskId) {
        return __awaiter(this, void 0, void 0, function* () {
            return httpGet(`${endPointPath}/financialDataByTask/${taskId}`);
        });
    }
    static submitFinancialData(data) {
        return httpPost(`${endPointPath}/saveSubmitFinancialData `, { body: data });
    }
    static getProfitAndLossTrackedItem(siteId) {
        return __awaiter(this, void 0, void 0, function* () {
            return httpGet(`${endPointPath}/pnlReportData/${siteId}`);
        });
    }
    static performInjestion(siteId, ingestionMonth, ingestionYear) {
        return __awaiter(this, void 0, void 0, function* () {
            return httpGet(`job/performIngestion/${siteId}/${ingestionMonth}/${ingestionYear}`);
        });
    }
    static getAccountMonthYear(externalReference) {
        return __awaiter(this, void 0, void 0, function* () {
            return httpGet(`accounting/getMonthYear/${externalReference}`);
        });
    }
    static getAccountNextMonthYear(externalReference) {
        return __awaiter(this, void 0, void 0, function* () {
            return httpGet(`accounting/getNextMonth/${externalReference}`);
        });
    }
}
