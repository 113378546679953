import React from "react";
import RoleForm from "./lookupForm";
import CustomModal from "../../../../../layout/components/customModal";
const EditLookup = ({ existingLookup, visible, onClose, onEdited, valuesRef, }) => {
    return (React.createElement("div", { "data-test": "EditLookupComponent" },
        React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["edit", "Lookup.lookupValue"] },
            React.createElement("div", { "data-test": "EditLookupFormComponent" },
                React.createElement(RoleForm, { onCancel: onClose, onSubmit: onEdited, lookup: existingLookup, submitText: "Edit Lookup", isEdit: true, valuesRef: valuesRef })))));
};
export default EditLookup;
