import { SessionStorage } from "./../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../core/constants/SessionVariables";
const { USER_CONTEXT, COMPANY, SITE_STATUSES, SELECTED_TASK, SELECTED_SUB_CATEGORY, GROUP, USER_FILTER, } = SessionVariables;
var userObj = SessionStorage.getKey(USER_CONTEXT);
var company = SessionStorage.getKey(COMPANY);
var siteStatusObj = SessionStorage.getKey(SITE_STATUSES);
var selectedTask = SessionStorage.getKey(SELECTED_TASK);
var selectedSubCategory = SessionStorage.getKey(SELECTED_SUB_CATEGORY, sessionStorage);
var userGroup = SessionStorage.getKey(GROUP);
var userFilter = SessionStorage.getKey(USER_FILTER);
let user = {
    userId: undefined,
    userFullName: undefined,
    isAuthenticated: false,
    permissions: ["annonymous"],
    selectedCompany: {},
    selectedApp: undefined,
    siteStatuses: [],
    selectedFinancialTask: {},
    selectedSubCategory: {},
};
if (userObj) {
    user = Object.assign(Object.assign({}, userObj), { isAuthenticated: true });
}
if (company) {
    user.selectedCompany = company;
}
if (selectedTask) {
    user.selectedFinancialTask = selectedTask;
}
if (selectedSubCategory) {
    user.selectedSubCategory = selectedSubCategory;
}
if (siteStatusObj) {
    user.siteStatuses = siteStatusObj;
}
export const DEFAULT_CONTEXT = Object.assign(Object.assign({}, user), { userId: user.userId, userFullName: user.userFullName, isAuthenticated: user.isAuthenticated, permissions: user.permissions, companyPermissionDTOs: user.companyPermissionDTOs, selectedCompany: user.selectedCompany, allowedApps: user.allowedApps, selectedApp: user.selectedApp, siteStatuses: user.siteStatuses, selectedFinancialTask: user.selectedFinancialTask, selectedSubCategory: user.selectedSubCategory });
export const DEFAULT_GROUP = userGroup !== null && userGroup !== void 0 ? userGroup : [];
export const DEFAULT_FILTER = userFilter !== null && userFilter !== void 0 ? userFilter : [];
