export const NOTIFICATION_TYPE_INDIVIDUAL = 0;
export const NOTIFICATION_TYPE_COLLECTIVE = 1;
export const NOTIFICATION_FREQUENCY_DAILY = 0;
export const NOTIFICATION_FREQUENCY_WEEKLY = 1;
export const NOTIFICATION_DAY_MONDAY = 1;
export const NOTIFICATION_DAY_TUESDAY = 2;
export const NOTIFICATION_DAY_WEDNESDAY = 3;
export const NOTIFICATION_DAY_THURSDAY = 4;
export const NOTIFICATION_DAY_FRIDAY = 5;
export const NOTIFICATION_DAY_SATURDAY = 6;
export const NOTIFICATION_DAY_SUNDAY = 7;
export const notificationOpt = [
    { value: 0, text: "Individual" },
    { value: 1, text: "Collective" },
];
export const frequencyOpt = [
    { value: 0, text: "Daily" },
    { value: 1, text: "Weekly" },
];
export const dayOpt = [
    { value: 1, text: "Monday" },
    { value: 2, text: "Tuesday" },
    { value: 3, text: "Wednesday" },
    { value: 4, text: "Thursday" },
    { value: 5, text: "Friday" },
    { value: 6, text: "Saturday" },
    { value: 7, text: "Sunday" },
];
export const timeOpt = [
    { value: 0, text: "00:00" },
    { value: 1, text: "01:00" },
    { value: 2, text: "02:00" },
    { value: 3, text: "03:00" },
    { value: 4, text: "04:00" },
    { value: 5, text: "05:00" },
    { value: 6, text: "06:00" },
    { value: 7, text: "07:00" },
    { value: 8, text: "08:00" },
    { value: 9, text: "09:00" },
    { value: 10, text: "10:00" },
    { value: 11, text: "11:00" },
    { value: 12, text: "12:00" },
    { value: 13, text: "13:00" },
    { value: 14, text: "14:00" },
    { value: 15, text: "15:00" },
    { value: 16, text: "16:00" },
    { value: 17, text: "17:00" },
    { value: 18, text: "18:00" },
    { value: 19, text: "19:00" },
    { value: 20, text: "20:00" },
    { value: 21, text: "21:00" },
    { value: 22, text: "22:00" },
    { value: 23, text: "23:00" },
];
