import { GET_PARENT_GROUP_SUCCESS, TOGGLE_PARENT_GROUP_SUCCESS, } from "./types";
const initialState = [];
export const trackedItemMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PARENT_GROUP_SUCCESS: {
            let newLookup = {};
            newLookup[action.lookupType] = action.payload;
            return Object.assign({}, state, newLookup);
        }
        case TOGGLE_PARENT_GROUP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { [action.lookupType]: state[action.lookupType].map((group, i) => i === action.payload.index
                    ? Object.assign(Object.assign({}, group), { active: action.payload.value }) : group) });
        }
        default: {
            return state;
        }
    }
};
