var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import LoginForm from "./components/loginForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signIn, loadContext } from "./../../../redux/context/actions";
import { useHistory } from "react-router-dom";
import { UserDashboardRoute } from "../../../core/utils/userDashboardRoute";
import { setContextCompany } from "../../../redux/context/actions";
import { SelectContextAndNavigation } from "../../../core/utils/UserContextSelection";
import { DASHBOARD_VIEWER, EXTERNAL_USER, } from "../../../core/constants/permissionConstants";
import { ResponseCodeWithMessages, ResponseStatusCodes, } from "../../../core/constants/responseStatusCodes";
import { loginRoute, resetPasswordRoute, } from "../../../core/constants/routesPath";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../core/constants/SessionVariables";
import { notifications } from "../../../layout/components/notifications";
import { useState } from "react";
import { getGroupOptions } from "../../../redux/groupOption/actions";
import isEmpty from "../../../core/helpers/isEmpty";
const LoginContainer = (_a) => {
    var { context, signIn, loadContext, getGroupOptions, groupOptions, setContextCompany } = _a, props = __rest(_a, ["context", "signIn", "loadContext", "getGroupOptions", "groupOptions", "setContextCompany"]);
    const history = useHistory();
    const [prevPath] = useState(history.location.state);
    const [isLoginClickAction, setIsLoginClickAction] = useState(false);
    const LoginHandler = (objLogin, setErrors) => {
        setIsLoginClickAction(true);
        signIn(objLogin).then((loginResponse) => __awaiter(void 0, void 0, void 0, function* () {
            if (loginResponse.code === ResponseStatusCodes.OK ||
                loginResponse.code ===
                    ResponseCodeWithMessages.PASSWORD_EXPIRY_WARNING.key) {
                if (loginResponse.code ===
                    ResponseCodeWithMessages.PASSWORD_EXPIRY_WARNING.key) {
                    SessionStorage.setKey(SessionVariables.PASSWORD_EXPIRES_IN, loginResponse.message);
                }
                loadContext().then((response) => {
                    var _a;
                    if (response) {
                        if (response.roleExpiryNotification.length > 0) {
                            response.roleExpiryNotification.forEach((x) => notifications.warning(x, { autoClose: 20000, delay: 2500 }));
                        }
                        const userPermissions = (_a = response === null || response === void 0 ? void 0 : response.companyPermissionDTOs) === null || _a === void 0 ? void 0 : _a.map(({ permission }) => permission);
                        isEmpty(prevPath) &&
                            SelectContextAndNavigation(response, history, groupOptions, getGroupOptions, setContextCompany);
                        if (!isEmpty(prevPath)) {
                            history.push(prevPath, { RedirectedPath: true });
                        }
                        else {
                            if (!userPermissions.includes(DASHBOARD_VIEWER)) {
                                const path = UserDashboardRoute.getRouteByPermissions(response);
                                if (path) {
                                    history.push(path);
                                }
                            }
                        }
                    }
                });
            }
            else if (loginResponse.code === ResponseStatusCodes.PASSWORD_EXPIRED) {
                SessionStorage.setKey(SessionVariables.OLD_PASSWORD, objLogin.password);
                SessionStorage.setKey(SessionVariables.USERNAME, objLogin.username);
                history.push(resetPasswordRoute);
            }
            else if (loginResponse.code === ResponseStatusCodes.PASSWORD_FORCE_CHANGE_WARNING) {
                SessionStorage.setKey(SessionVariables.OLD_PASSWORD, objLogin.password);
                SessionStorage.setKey(SessionVariables.USERNAME, objLogin.username);
                history.push(resetPasswordRoute);
            }
            else {
                setErrors({ invalid: loginResponse.message });
            }
        }));
    };
    if (context.isAuthenticated &&
        (!isLoginClickAction ||
            !context.companyPermissionDTOs.length ||
            (context.companyPermissionDTOs.length === 1 &&
                context.companyPermissionDTOs[0].permission === EXTERNAL_USER))) {
        if (window.location.pathname !== loginRoute) {
            history.push(window.location.pathname);
        }
        else {
            if (!(prevPath === null || prevPath === void 0 ? void 0 : prevPath.action) &&
                SessionStorage.getKey(SessionVariables.USER_CONTEXT)) {
                history.push(UserDashboardRoute.getRouteByPermissions(context));
            }
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(LoginForm, { LoginHandler: LoginHandler })));
};
// to map state to components props
function mapStateTopProps(state, ownProps) {
    return {
        context: state.context,
        groupOptions: state.groupOptions,
    };
}
// to map components actions to props with dispatcher
const mapDispatchToProps = {
    signIn,
    loadContext,
    getGroupOptions,
    setContextCompany,
};
LoginContainer.propTypes = {
    signIn: PropTypes.func.isRequired,
    getGroupOptions: PropTypes.func.isRequired,
    setContextCompany: PropTypes.func.isRequired,
    loadContext: PropTypes.func.isRequired,
    context: PropTypes.object.isRequired,
    groupOptions: PropTypes.array.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(LoginContainer);
