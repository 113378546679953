import React, { useCallback, useState } from "react";
import { DatePicker, Input } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import RoleDropdownContainer from "../../../../../layout/components/select/RoleDropdownContainer";
import moment from "moment";
import LookupDropDown from "../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../core/constants/lookup";
import localize from "../../../../../core/utils/translation-files";
import { inputDefaultLength } from "../../../../../core/utils/default-length";
import { defaultDateFormat, defaultDateTimeFormat, } from "../../../../../core/constants/DateTime";
import { disabledDateTime } from "../../../../../core/validations/dateTimeValidations";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import BandDropdown from "../../../../../layout/components/select/BandDropdown";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import { EXTERNAL_USER_INVITATION_WITH_ROLE } from "../../../../../core/constants/permissionConstants";
import { hasDelphieorSuperPermissions } from "../../../../../core/helpers/permissionByRoleId";
import FormPermissionDropdown from "../../../../../layout/components/select/FormPermissionDropdown";
import PromptComponent from "../../../../../layout/components/prompt-message";
import FormActionButtons from "../../../../../layout/components/common/FormsActionButtons";
import { useHistory } from "react-router-dom";
import { usersRoute } from "../../../../../core/constants/routesPath";
import Asterik from "../../../shared/helper-components/Asterik";
import isEmpty from "../../../../../core/helpers/isEmpty";
const defaultSubmitText = "";
const InviteUserForm = ({ submitText = defaultSubmitText, userInfo, onSubmit, onCancel, users, roles, permission, }) => {
    const [key, setKey] = useState(0);
    const { handlePrint, nodeRef } = UsePrintPage(false);
    const isRoleAssignmentAllowed = isActionAllowed([
        EXTERNAL_USER_INVITATION_WITH_ROLE,
    ]);
    const validationShape = {
        emailAddress: Yup.string()
            .email(localize(["enterAValid", "email"]))
            .required(localize(["email", "address", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        firstName: Yup.string()
            .required(localize(["First Name is Required!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        middleName: Yup.string().max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        lastName: Yup.string()
            .required(localize(["Last Name is Required!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        groupId: Yup.number().required(localize(["Group.group", "isRequired!"])),
        companyId: Yup.number().nullable(),
        siteId: Yup.number().nullable(),
        professionId: Yup.number().required(localize(["Profession", "isRequired!"])),
        title: Yup.number().required(localize(["Title", "isRequired!"])),
        roleId: Yup.number().nullable(),
        band: Yup.number().nullable(),
        startDate: Yup.date().nullable(),
        endDate: Yup.date().nullable(),
    };
    if (permission.isCompanyAdmin) {
        validationShape.companyId = Yup.number().required(localize(["Company.company", "isRequired!"]));
    }
    if (permission.isSiteAdmin) {
        validationShape.siteId = Yup.number().required(localize(["Site.site", "isRequired!"]));
    }
    if (isRoleAssignmentAllowed) {
        validationShape.roleId = Yup.number().required(localize(["UserRole.role", "isRequired!"]));
        validationShape.startDate = Yup.date()
            .required(localize(["UserRole.startDateAndTime", "isRequired!"]))
            .test("start-date-min-value", localize([
            "UserRole.startDateAndTime",
            "mustBeGreaterThan",
            "orEqualTo",
            "currentDateTime",
        ]), function (value) {
            const [selectedValue, currentDateTime] = [
                moment(value).format(defaultDateTimeFormat),
                moment().format(defaultDateTimeFormat),
            ];
            return moment(selectedValue, defaultDateTimeFormat).isSameOrAfter(moment(currentDateTime, defaultDateTimeFormat));
        })
            .test(localize(["check"]), localize([
            "UserRole.startDateAndTime",
            "mustBeLessThan",
            "UserRole.endDateAndTime",
        ]), function (value) {
            const [startDate, endDate] = [
                moment(value).format(defaultDateTimeFormat),
                moment(values.endDate).format(defaultDateTimeFormat),
            ];
            const isSameOrBefore = values.roleId && value && values.endDate
                ? moment(startDate, defaultDateTimeFormat).isBefore(moment(endDate, defaultDateTimeFormat))
                : true;
            return isSameOrBefore;
        });
        validationShape.endDate = Yup.string().test("end-date-min-value", localize([
            "UserRole.endDateAndTime",
            "mustBeGreaterThan",
            "orEqualTo",
            "currentDateTime",
        ]), function (value) {
            if (isEmpty(value))
                return true;
            const [selectedValue, currentDateTime] = [
                moment(value).format(defaultDateTimeFormat),
                moment().format(defaultDateTimeFormat),
            ];
            return moment(selectedValue, defaultDateTimeFormat).isSameOrAfter(moment(currentDateTime, defaultDateTimeFormat));
        });
        validationShape.band = Yup.number().required(localize(["User.user", "User.band", "isRequired!"]));
        // .when("roleId", {
        //   is: (x) => x > 0 && !hasDelphieorSuperPermissions(roles, x),
        //   then: Yup.number().required(
        //     localize(["User.user", "User.band", "isRequired!"])
        //   ),
        // });
    }
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, resetForm, setErrors, setFieldValue, dirty, } = useFormik({
        validationSchema: Yup.object().shape(validationShape),
        initialValues: userInfo,
        onSubmit: (values) => {
            onSubmit(values, setErrors, resetForm);
        },
    });
    const history = useHistory();
    const todayDate = moment().format(defaultDateFormat);
    const [startCurrentDate, setStartCurrentDate] = useState(todayDate);
    const [endCurrentDate, setEndCurrentDate] = useState(todayDate);
    function disabledDate(current) {
        return current && current < moment(todayDate, defaultDateFormat); //.add(-1, "days");
    }
    const handleChangeGroup = useCallback((value) => {
        setFieldValue("groupId", value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeCompany = useCallback((value) => {
        setFieldValue("companyId", value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeSite = useCallback((value) => {
        setFieldValue("siteId", value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: "mb-1 customForm", name: "basic", onSubmit: handleSubmit, key: key, "data-test": "inviteUserFormComponent", id: "my-form" },
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["User Information"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement(Input, { name: "companyId", type: "hidden" }),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", { "data-test": "title-input" },
                                            localize(["Title"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.title, onChange: setFieldValue, inputName: "title", localizationKey: ["Title"], lookUpType: LookupNames.LKTitle, error: errors.title && touched.title ? errors.title : null }))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", { "data-test": "firstName-input" },
                                            localize(["First Name"]),
                                            React.createElement(Asterik, null))),
                                    React.createElement(Input, { name: "firstName", placeholder: localize(["First Name"]), onBlur: handleBlur, onChange: handleChange, value: values.firstName, className: `${errors.firstName && touched.firstName
                                            ? "input-error"
                                            : null}` }),
                                    " ",
                                    errors.firstName && touched.firstName && (React.createElement("p", { className: "error" }, errors.firstName)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", { "data-test": "middleName-input" }, localize(["Middle Name"]))),
                                    React.createElement(Input, { name: "middleName", placeholder: localize(["Middle Name"]), onBlur: handleBlur, onChange: handleChange, value: values.middleName, className: `${errors.middleName && touched.middleName
                                            ? "input-error"
                                            : null}` }),
                                    " ",
                                    errors.middleName && touched.middleName && (React.createElement("p", { className: "error" }, errors.middleName)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", { "data-test": "lastName-input" },
                                            localize(["Last Name"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(Input, { name: "lastName", placeholder: localize(["Last Name"]), onBlur: handleBlur, onChange: handleChange, value: values.lastName, className: `${errors.lastName && touched.lastName
                                            ? "input-error"
                                            : null}` }),
                                    " ",
                                    errors.lastName && touched.lastName && (React.createElement("p", { className: "error" }, errors.lastName)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["User.notificationEmailAddress"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(Input, { name: "emailAddress", placeholder: localize(["User.notificationEmailAddress"]), onBlur: handleBlur, onChange: handleChange, value: values.emailAddress, className: `${errors.emailAddress && touched.emailAddress
                                            ? "input-error"
                                            : null}` }),
                                    " ",
                                    errors.emailAddress && touched.emailAddress && (React.createElement("p", { className: "error" }, errors.emailAddress)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["Profession"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.professionId, onChange: setFieldValue, inputName: "professionId", localizationKey: ["Profession"], lookUpType: LookupNames.LKProfession, error: errors.professionId && touched.professionId
                                            ? errors.professionId
                                            : null }))),
                            React.createElement(FormPermissionDropdown, { groupValues: {
                                    onChangeGroup: handleChangeGroup,
                                    selectedvalue: values.groupId,
                                    errorMessage: errors.groupId && touched.groupId ? errors.groupId : null,
                                }, companyValues: {
                                    onChangeCompany: handleChangeCompany,
                                    selectedvalue: values.companyId,
                                    errorMessage: errors.companyId && touched.companyId
                                        ? errors.companyId
                                        : null,
                                    allowClear: true,
                                }, siteValues: {
                                    onChangeSite: handleChangeSite,
                                    selectedvalue: values.siteId,
                                    errorMessage: errors.siteId && touched.siteId ? errors.siteId : null,
                                    allowClear: true,
                                }, dirty: true }),
                            isRoleAssignmentAllowed && (React.createElement(React.Fragment, null,
                                " ",
                                React.createElement("div", { className: "col-3", "data-test": "site-dropdown" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["UserRole.role"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(RoleDropdownContainer, { className: "mb-2 mb-md-0", companyId: values.companyId, selectedValue: values.roleId, onChange: (value) => setFieldValue("roleId", value), isExternal: true, error: errors.roleId && touched.roleId
                                                ? errors === null || errors === void 0 ? void 0 : errors.roleId
                                                : null }))),
                                !hasDelphieorSuperPermissions(roles, values.roleId) && (React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["User.user", "User.band"]),
                                                React.createElement(Asterik
                                                // hidden={isEmpty(values.roleId)}
                                                , null))),
                                        React.createElement(BandDropdown, { selectedBand: values.band, setSelectedBand: (value) => setFieldValue("band", value), error: errors.band && touched.band ? errors === null || errors === void 0 ? void 0 : errors.band : null })))),
                                React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["Start Date"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(DatePicker, { value: values.startDate, disabledDate: disabledDate, format: defaultDateTimeFormat, showTime: true, className: `mb-2 mb-md-0 d-block ant-input ${errors.startDate && touched.startDate
                                                ? `input-error`
                                                : ``}`, placeholder: localize(["Start Date"]), onChange: (date) => {
                                                setFieldValue("startDate", date ? moment(date._d) : "");
                                            }, onSelect: (selectedDate) => {
                                                setStartCurrentDate(moment(selectedDate).format(defaultDateTimeFormat));
                                            }, disabledTime: disabledDateTime.bind({}, startCurrentDate, "startDate") }),
                                        errors.startDate && touched.startDate && (React.createElement("p", { className: "error" }, errors.startDate)))),
                                React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                " ",
                                                localize(["End Date"]),
                                                " ")),
                                        React.createElement(DatePicker, { value: values.endDate, disabledDate: disabledDate, format: defaultDateTimeFormat, showTime: true, className: `mb-2 mb-md-0 d-block ant-input ${errors.endDate && touched.endDate
                                                ? `input-error`
                                                : ``}`, placeholder: localize(["End Date"]), onChange: (date) => setFieldValue("endDate", date ? moment(date._d) : ""), onSelect: (selectedDate) => {
                                                setEndCurrentDate(moment(selectedDate).format(defaultDateTimeFormat));
                                            }, disabledTime: disabledDateTime.bind({}, endCurrentDate, "endDate") }),
                                        errors.endDate && touched.endDate && (React.createElement("p", { className: "error" }, errors.endDate))))))))))),
        React.createElement(FormActionButtons, { onCancel: () => history.push(usersRoute), fromId: "my-form", isEdit: !dirty || userInfo.id > 0, isDirty: !dirty, submitText: "Send", resetForm: resetForm, setKey: setKey, printOnClick: handlePrint, dataTest: "siteFormComponent" })));
};
export default InviteUserForm;
