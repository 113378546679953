import React, { useCallback, useImperativeHandle } from "react";
import { Button, Checkbox, Input } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import localize from "../../../../../core/utils/translation-files";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import FormPermissionDropdown from "../../../../../layout/components/select/FormPermissionDropdown";
import { TrackedItemsVariables } from "../../../../../core/constants/TrackedItemsConstants";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import PromptComponent from "../../../../../layout/components/prompt-message";
const OverWriteForm = ({ onSubmit, onCancel, notification, permission, userBandOptions, valuesRef, }) => {
    const { handleSubmit, setFieldValue, handleChange, setErrors, values, errors, touched, resetForm, dirty, } = useFormik({
        validationSchema: Yup.object({
            groupId: Yup.number().required(localize(["Group.group", "isRequired!"])),
            companyId: permission.isCompanyAdmin
                ? Yup.number().required(localize(["Company.company", "isRequired!"]))
                : Yup.number().nullable(),
            siteId: permission.isSiteAdmin
                ? Yup.number().required(localize(["Site.site", "isRequired!"]))
                : Yup.number().nullable(),
        }),
        initialValues: notification,
        onSubmit: (values) => {
            onSubmit(values, resetForm, setErrors);
        },
    });
    const handleCancel = () => {
        onCancel(resetForm);
    };
    const { handlePrint, nodeRef } = UsePrintPage(false);
    const handleChangeGroup = useCallback((value) => {
        setFieldValue("groupId", value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeCompany = useCallback((value) => {
        setFieldValue("companyId", value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeSite = useCallback((value) => {
        setFieldValue("siteId", value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { USER_CONTEXT } = SessionVariables;
    const { getKey } = SessionStorage;
    const isDisableBand = (value) => {
        var _a;
        const userHighestBand = Math.max(...(_a = getKey(USER_CONTEXT)) === null || _a === void 0 ? void 0 : _a.allowedBands);
        return userHighestBand < value;
    };
    const handleChangeBandsCheckBox = (event) => {
        const { checked, id } = event.target;
        let updateRecipient = [...values === null || values === void 0 ? void 0 : values.recipient];
        updateRecipient = checked
            ? [...updateRecipient, id]
            : updateRecipient.filter((recipient) => recipient !== id);
        setFieldValue("recipient", updateRecipient);
    };
    useImperativeHandle(valuesRef, () => {
        return {
            isPrompt: dirty,
        };
    }, [dirty]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: "mb-1 customForm", name: "basic", ref: nodeRef, onSubmit: handleSubmit, "data-test": "overwrite-form" },
            React.createElement(Input, { name: "id", type: "hidden" }),
            React.createElement(FormPermissionDropdown, { "data-test": "dropdowns", groupValues: {
                    onChangeGroup: handleChangeGroup,
                    selectedvalue: values.groupId,
                    wrapperClass: "col-12",
                    disabled: values.id > 0,
                    errorMessage: errors.groupId && touched.groupId ? errors.groupId : null,
                }, companyValues: {
                    onChangeCompany: handleChangeCompany,
                    selectedvalue: values.companyId,
                    wrapperClass: "col-12",
                    disabled: values.id > 0,
                    errorMessage: errors.companyId && touched.companyId ? errors.companyId : null,
                    allowClear: true,
                }, siteValues: {
                    onChangeSite: handleChangeSite,
                    selectedvalue: values.siteId,
                    wrapperClass: "col-12",
                    disabled: values.id > 0,
                    errorMessage: errors.siteId && touched.siteId ? errors.siteId : null,
                    allowClear: true,
                } }),
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "form-group", "data-test": "band-dropdown" },
                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                        React.createElement("label", null, localize(["User.user", "User.band"]))),
                    React.createElement("div", { className: "row" }, userBandOptions === null || userBandOptions === void 0 ? void 0 : userBandOptions.map(({ code }, bandIndex) => {
                        var _a;
                        return code !== TrackedItemsVariables.NOTIFICATIONS_DEFAULT_BAND && (React.createElement("div", { className: "col-2 col-half-offset", "data-test": "checkbox", "data-testid": "checkbox" },
                            React.createElement(Checkbox, { key: bandIndex, onChange: handleChangeBandsCheckBox, id: code, disabled: isDisableBand(+code), checked: (_a = values.recipient) === null || _a === void 0 ? void 0 : _a.some((value) => value === code) }, code)));
                    })),
                    errors.recipient && React.createElement("p", { className: "error" }, errors.recipient))),
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "form-group", "data-test": "checkbox" },
                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                        React.createElement("label", null, localize(["enable"]))),
                    React.createElement(Checkbox, { name: "enabled", checked: values.enabled, onChange: handleChange }))),
            React.createElement("div", { className: "d-flex settings-actions d-print-none col-12 popup-button-list" },
                React.createElement(Button, { type: "default", onClick: handlePrint },
                    React.createElement("span", { className: "icofont icofont-printer mr-2", style: { fontSize: "1.3em" } }),
                    localize(["print"])),
                React.createElement(Button, { type: "default", onClick: handleCancel, className: "ml-auto mr-2" },
                    React.createElement("span", { className: "icofont icofont-close-circled mr-2", style: { fontSize: "1.2em" } }),
                    localize(["Button.cancel"])),
                React.createElement(Button, { type: "primary", htmlType: "submit", hidden: !dirty },
                    React.createElement("span", { className: "icofont icofont-save mr-2", style: { fontSize: "1em" } }),
                    localize(["Button.save"]))))));
};
export default OverWriteForm;
