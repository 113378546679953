var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { matchPath, Redirect, Route, Switch } from "react-router-dom";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../core/constants/SessionVariables";
import { defaultRoutes, getRoutesByPermission } from "../../../routing/routes";
import { loginRoute, unAuthorizedRoute, } from "../../../core/constants/routesPath";
const UserRoutes = () => {
    var _a;
    const userContext = SessionStorage.getKey(SessionVariables.USER_CONTEXT);
    const allowedRoutes = getRoutesByPermission(userContext === null || userContext === void 0 ? void 0 : userContext.companyPermissionDTOs);
    const splitRoute = window.location.pathname.slice(1);
    const currRoute = splitRoute.substring(splitRoute.indexOf("/") + 1);
    const renderSingleRoute = (route) => (React.createElement(AppRoute, { key: route.path, exact: true, path: route.path.includes("404")
            ? "*"
            : route.layout.length > 0
                ? `/${route.layout}/${route.path}`
                : `/${route.path}\``, layoutName: route.layoutName, layout: route.layout, component: route.component, requiredPermissions: route.requiredPermissions }));
    const renderSingleAndChildRouteHandler = (route) => {
        renderChildRoutes(route.routes);
        renderSingleRoute(route);
    };
    const renderChildRoutes = (routes) => routes
        .filter((x) => (userContext === null || userContext === void 0 ? void 0 : userContext.companyPermissionDTOs.map((x) => x.permission).some((elm) => x.requiredPermissions.includes(elm))) ||
        x.requiredPermissions === [])
        .map((route) => (React.createElement(AppRoute, { key: route.path, exact: true, path: route.layout.length > 0
            ? `/${route.layout}/${route.path}`
            : `/${route.path}\``, layoutName: route.layoutName, layout: route.layout, component: route.component, requiredPermissions: route.requiredPermissions })));
    const AppRoute = (_a) => {
        var { component: Component, layoutName: LayoutName, layout: Layout, requiredPermissions: RequiredPermissions } = _a, rest = __rest(_a, ["component", "layoutName", "layout", "requiredPermissions"]);
        return (React.createElement(Route, Object.assign({}, rest, { render: (props) => (React.createElement(LayoutName, null,
                React.createElement(Component, Object.assign({}, props)))) })));
    };
    return (React.createElement(Switch, null,
        !userContext &&
            window.location.pathname !== loginRoute &&
            !window.location.pathname.includes("public") &&
            (defaultRoutes === null || defaultRoutes === void 0 ? void 0 : defaultRoutes.some((routes) => matchPath(currRoute, { path: routes.path, exact: true, strict: true }))) ? (React.createElement(Redirect, { to: {
                pathname: loginRoute,
                state: window.location.pathname + window.location.search,
            } })) : ((_a = window.location.href) === null || _a === void 0 ? void 0 : _a.includes("?")) &&
            !window.location.pathname.includes("public") ? (React.createElement(Redirect, { to: {
                pathname: window.location.pathname,
                state: {
                    params: window.location.search,
                    url: window.location.href,
                },
            } })) : (userContext &&
            (defaultRoutes === null || defaultRoutes === void 0 ? void 0 : defaultRoutes.some((routes) => matchPath(currRoute, { path: routes.path, exact: true, strict: true }))) &&
            !(allowedRoutes === null || allowedRoutes === void 0 ? void 0 : allowedRoutes.some((routes) => matchPath(currRoute, { path: routes.path, exact: true, strict: true }))) && (React.createElement(Redirect, { to: {
                pathname: unAuthorizedRoute,
                state: { subText: "permission" },
            } }))), allowedRoutes === null || allowedRoutes === void 0 ? void 0 :
        allowedRoutes.map((route) => {
            if (route.routes && route.routes.length > 0) {
                return renderSingleAndChildRouteHandler(route);
            }
            return renderSingleRoute(route);
        })));
};
export default UserRoutes;
