var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { DashboardApi } from "../../../../../core/api/dashboardApi";
import { LookupNames } from "../../../../../core/constants/lookup";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import CustomPageHeader from "../../../../../layout/components/customPageHeader";
import { getLookups } from "../../../../../redux/lookup/actions";
import CategoriesListing from "./components/CategoriesListing";
import TrackedItemBandScaleContainer from "../../charts/line-chart/movingAverageAndProjection/trackedItemBandScale/trackedItemBandScaleContainer";
import OverallStatusPanels from "../components/OverallStatusPanels";
import useDidMount from "../../../../../core/hooks/useDidMount";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import { TrackedItemApi } from "../../../../../core/api/trackedItemApi";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import UsePersistCustomFilters from "../../../../../core/hooks/usePersistCustomFilters";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { browserHistoryMemoryContext } from "../../../../../core/contexts/browserHistoryMemoryContext";
import RelatedNotificationsPopup from "../components/relatedNotificationsPopup";
import RatingCount from "../components/RatingCount";
import DashboardFooter from "../components/DashboardFooter";
import UseInfiniteScroll from "../../../../../core/hooks/useInfiniteScroll";
import queryString from "query-string";
import { defaultHistoryFrequencyFilter } from "../../../../../core/constants/historyFrequencyFilter";
import AddRemoveWatchListModal from "../DashboardRoadmap/components/addRemoveWatchList";
import { handleSetRoadMapItemWatchListStatus } from "../../../../../core/helpers/localStorage/breadCrumbSession";
function CategoriesDashboard({ historyFrequency, getLookups, isPrintMode,
//setContextSubCategory,
 }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const userContext = SessionStorage.getKey(SessionVariables.USER_CONTEXT);
    const userHistoryFrequencyFilter = isEmpty(userContext === null || userContext === void 0 ? void 0 : userContext.lastVisitedContext)
        ? defaultHistoryFrequencyFilter
        : (_b = JSON.parse((_a = JSON.parse(userContext === null || userContext === void 0 ? void 0 : userContext.lastVisitedContext)) === null || _a === void 0 ? void 0 : _a.text)) === null || _b === void 0 ? void 0 : _b.selectedFilter;
    const { filters: selectedFrequency, setFilters: setSelectedFrequency } = UsePersistCustomFilters({
        unmountContextRemoval: false,
        defaultValue: userHistoryFrequencyFilter,
    });
    const params = useParams();
    const [overAllRating, setOverallRating] = useState(undefined);
    const [ratingCount, setRatingCount] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [lastRefreshTime, setLastRefreshTime] = useState("");
    const [bandScales, setBandScales] = useState([]);
    const [scaleModelVisibility, setScaleModelVisibility] = useState(false);
    const shouldUpdate = useRef(true);
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedWatchlistItem, setSelectedWatchlistItem] = useState({});
    const [notificationModelVisibility, setNotificationModelVisibility] = useState(false);
    const overallNotificationPopup = useRef(false);
    const handleToggleWatchListModal = (param) => {
        setSelectedWatchlistItem((prev) => ((param === null || param === void 0 ? void 0 : param.itemId) ? param : {}));
    };
    const openNotificationModal = (item, isOverall = false) => {
        if (isOverall) {
            overallNotificationPopup.current = true;
        }
        setNotificationModelVisibility(true);
        setSelectedItem(item);
    };
    const closeNotificationModal = () => {
        if (overallNotificationPopup.current) {
            overallNotificationPopup.current = false;
        }
        setNotificationModelVisibility(false);
        setSelectedItem({});
    };
    const _j = UseCustomPagination({
        totalRecords: (_c = data === null || data === void 0 ? void 0 : data.categoryRatingTabDTOList.length) !== null && _c !== void 0 ? _c : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange, pageSize } = _j, restPaginationProps = __rest(_j, ["handleCustomTablePropChange", "pageSize"]);
    useDidMount(() => {
        TrackedItemApi.getTrackedItemScale().then((res) => {
            setBandScales(res);
        });
        getLookupByListing({
            LookupNames: [LookupNames.LKReportHistoryFrequency],
            getLookups,
        });
    });
    const { search } = useLocation();
    const query = queryString.parse(search ||
        SessionStorage.getKey(SessionVariables.QUERY_PARAMS, sessionStorage));
    const handleGetApiCall = (isLoadMore = false, current = null) => {
        const page = current !== null && current !== void 0 ? current : currentPage + 1;
        const { itemName = null, sort = null, order = null } = query !== null && query !== void 0 ? query : {};
        const body = {
            overallBands: null,
            benchmarkCodes: null,
            maxOverall: null,
            minOverall: null,
            sortColumnName: null,
            sortOrder: null,
            trackedItemName: itemName,
            predictedBands: null,
        };
        body.sortColumnName = (sort === null || sort === void 0 ? void 0 : sort.includes("itemName")) ? "name" : "band";
        body.sortOrder = order === "ascend" ? "asc" : "desc";
        const updatedPageSize = pageSize ? pageSize : 4;
        if (updatedPageSize) {
            DashboardApi.getCategoriesRatingList(params === null || params === void 0 ? void 0 : params.site_id, +(selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code), page, 4, isLoadMore ? "LoadMore" : null, body).then((res) => {
                setLastRefreshTime(res.lastRefreshTime);
                if (isLoadMore) {
                    setCurrentPage((prev) => prev + 1);
                    setData((prev) => (Object.assign(Object.assign({}, prev), { categoryRatingTabDTOList: [
                            ...prev.categoryRatingTabDTOList,
                            ...res.categoryRatingTabDTOList,
                        ] })));
                    setShouldFetch(false);
                    return;
                }
                setData(res);
            });
        }
    };
    const { currentPage, setCurrentPage, setShouldFetch, handleScrollToTop } = UseInfiniteScroll({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleGetApiCall,
        isScrollMore: ((_d = data === null || data === void 0 ? void 0 : data.categoryRatingTabDTOList) === null || _d === void 0 ? void 0 : _d.length) < (data === null || data === void 0 ? void 0 : data.totalRecords),
    });
    useEffect(() => {
        if (shouldUpdate.current && userHistoryFrequencyFilter) {
            setSelectedFrequency(userHistoryFrequencyFilter);
            shouldUpdate.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldUpdate, userHistoryFrequencyFilter]);
    useEffect(() => {
        if (selectedFrequency && (params === null || params === void 0 ? void 0 : params.site_id)) {
            // DashboardApi.getCategoriesRatingList(
            //   params?.site_id,
            //   +selectedFrequency?.code
            // ).then((res) => {
            //   setData(res);
            //   setLastRefreshTime(res.lastRefreshTime);
            // });
            handleScrollToTop();
            setCurrentPage(1);
            handleGetApiCall(false, 1);
            DashboardApi.getCategoriesOverallRating(params === null || params === void 0 ? void 0 : params.site_id, +(selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code)).then((res) => {
                var _a;
                setOverallRating(res);
                if (!(ratingCount === null || ratingCount === void 0 ? void 0 : ratingCount.length)) {
                    DashboardApi.getRatingCount((_a = res === null || res === void 0 ? void 0 : res.ratingData) === null || _a === void 0 ? void 0 : _a.itemId, params === null || params === void 0 ? void 0 : params.group_id, params === null || params === void 0 ? void 0 : params.comp_id, params === null || params === void 0 ? void 0 : params.site_id).then((res) => {
                        setRatingCount(res);
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        params === null || params === void 0 ? void 0 : params.site_id,
        selectedFrequency,
        query === null || query === void 0 ? void 0 : query.sort,
        query === null || query === void 0 ? void 0 : query.order,
        query === null || query === void 0 ? void 0 : query.itemName,
    ]);
    const closeScaleModal = () => setScaleModelVisibility(false);
    const { nodeRef, handlePrint } = UsePrintPage();
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(CustomPageHeader, { setScaleModelVisibility: setScaleModelVisibility, frequencyList: historyFrequency, selectedFrequency: selectedFrequency, setSelectedFrequency: setSelectedFrequency, handlePrint: handlePrint, pageHeading: ["Dashboard.siteDashboard"], itemName: (_f = (_e = overAllRating === null || overAllRating === void 0 ? void 0 : overAllRating.ratingData) === null || _e === void 0 ? void 0 : _e.itemName) !== null && _f !== void 0 ? _f : "" }),
        React.createElement(TrackedItemBandScaleContainer, { visible: scaleModelVisibility, onClose: closeScaleModal, 
            // subCategory={params?.site_id}
            dashboardBandScale: bandScales, isDashboard: true }),
        React.createElement(OverallStatusPanels, { data: (_g = overAllRating === null || overAllRating === void 0 ? void 0 : overAllRating.ratingData) !== null && _g !== void 0 ? _g : [], selectedFrequencyLabel: selectedFrequency, openNotificationModal: openNotificationModal, showAllCards: false }),
        React.createElement(RatingCount, { grades: bandScales, data: ratingCount !== null && ratingCount !== void 0 ? ratingCount : {}, level: "Site" }),
        React.createElement(CategoriesListing, { categories: (_h = data === null || data === void 0 ? void 0 : data.categoryRatingTabDTOList) !== null && _h !== void 0 ? _h : [], scaleBandArray: bandScales, currentPage: restPaginationProps.currentPage, pageSize: pageSize, handleCustomTablePropChange: handleCustomTablePropChange, selectedFrequency: selectedFrequency, isPrintMode: isPrintMode, openNotificationModal: openNotificationModal, handleToggleWatchListModal: handleToggleWatchListModal, userId: userContext === null || userContext === void 0 ? void 0 : userContext.userId }),
        React.createElement(RelatedNotificationsPopup, { itemId: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.itemId, visible: notificationModelVisibility, onClose: closeNotificationModal, selectedItem: selectedItem, setSelectedItem: setSelectedItem, browserHistory: browserHistory, setOverallData: overallNotificationPopup.current && setOverallRating, setDashboardListData: !overallNotificationPopup.current && setData, listKey: overallNotificationPopup.current
                ? "ratingData"
                : "categoryRatingTabDTOList" }),
        React.createElement(AddRemoveWatchListModal, { handleToggleModalVisibility: handleToggleWatchListModal, selectedTrackItemId: selectedWatchlistItem === null || selectedWatchlistItem === void 0 ? void 0 : selectedWatchlistItem.itemId, onAddRemoveWatchList: (itemId, status) => {
                setData(prev => (Object.assign(Object.assign({}, prev), { categoryRatingTabDTOList: prev.categoryRatingTabDTOList.map((record) => {
                        if (record.itemId === itemId) {
                            record.watchlistStatus = status;
                        }
                        return record;
                    }) })));
                handleSetRoadMapItemWatchListStatus(itemId, status);
            } }),
        React.createElement(DashboardFooter, { lastRefreshTime: lastRefreshTime })));
}
function mapStateTopProps(state) {
    var _a, _b;
    return {
        historyFrequency: (_a = state.lookups[LookupNames.LKReportHistoryFrequency]) !== null && _a !== void 0 ? _a : [],
        isPrintMode: (_b = state.togglePrint) === null || _b === void 0 ? void 0 : _b.status,
    };
}
const mapDispatchToProps = {
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(CategoriesDashboard);
