import { initialState } from "../initialState";
import { GET_BAND_SUCCESS } from "./types";
export const bandReducer = (state = initialState.bands, action) => {
    switch (action.type) {
        case GET_BAND_SUCCESS: {
            return [...action.payload];
        }
        default: {
            return state;
        }
    }
};
