var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Tabs } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import SitesAndRoles from "./components/sitesAndRoles";
import UserInformationForm from "./components/userInformationForm";
import { addUser, getUsers, editUser } from "../../../../../redux/user/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RoleCompanyType } from "../../../../../core/enums/RoleTypes";
import { UserStatus, UserType } from "../../../../../core/enums/UserStatus";
import moment from "moment";
import { removeWhiteSpace } from "../../../../../core/utils/stringUtils";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import { usersRoute } from "../../../../../core/constants/routesPath";
import { TelephoneType } from "../../../../../core/enums/TelephoneType";
import { notifications } from "../../../../../layout/components/notifications";
import localize from "../../../../../core/utils/translation-files";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import { LookupNames } from "../../../../../core/constants/lookup";
import { getLookups } from "../../../../../redux/lookup/actions";
import ExpiredRolesContainer from "./expiredUserRolesContainer";
import { RolesListingTable } from "../userListing/components/rolesListingTable";
import { UserApi } from "../../../../../core/api/userApi";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { getCompanyIdBySelectedCompany } from "../../../../../core/utils/UserContextSelection";
import { defaultDateTimeFormat } from "../../../../../core/constants/DateTime";
import { getLocalDate, getLocalDateString, } from "../../../../../core/helpers/dateFormatter";
import useDidMount from "../../../../../core/hooks/useDidMount";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import usePermissions from "../../../../../core/hooks/usePermissions";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import { EXTERNAL_USER_UPDATE, USER_EDIT, } from "../../../../../core/constants/permissionConstants";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import FormActionButtons from "../../../../../layout/components/common/FormsActionButtons";
import { useSelector } from "react-redux";
const { TabPane } = Tabs;
const UserFormContainer = ({ addUser, users, editUser, context, getLookups, getUsers, roles, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const { isDelphieorSuperUser, defaultCompanyValues, isCompanyAdmin, isSiteAdmin, defaultGroupValues, //internal user other than delphie will belong to single group,
    defaultSiteValues, groupOptions, } = usePermissions();
    const initialUser = {
        id: 0,
        firstName: "",
        middleName: "",
        lastName: "",
        username: "",
        emailAddress: "",
        companyId: defaultGroupValues === null || defaultGroupValues === void 0 ? void 0 : defaultGroupValues.id,
        userType: UserType.Internal,
        status: UserStatus.ACTIVE,
        userCompanyRoleDTOs: [],
        telephoneType: TelephoneType.Mobile,
        professionId: undefined,
        title: undefined,
        telephoneNumber: "",
        currentContextCompanySiteId: (_a = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _a === void 0 ? void 0 : _a.id,
    };
    const initialUserRole = {
        groupId: defaultGroupValues === null || defaultGroupValues === void 0 ? void 0 : defaultGroupValues.id,
        groupName: defaultGroupValues === null || defaultGroupValues === void 0 ? void 0 : defaultGroupValues.name,
        companyId: (_b = defaultCompanyValues === null || defaultCompanyValues === void 0 ? void 0 : defaultCompanyValues.id) !== null && _b !== void 0 ? _b : null,
        companyName: (_c = defaultCompanyValues === null || defaultCompanyValues === void 0 ? void 0 : defaultCompanyValues.name) !== null && _c !== void 0 ? _c : null,
        siteName: (_d = defaultSiteValues === null || defaultSiteValues === void 0 ? void 0 : defaultSiteValues.name) !== null && _d !== void 0 ? _d : null,
        siteId: (_e = defaultSiteValues === null || defaultSiteValues === void 0 ? void 0 : defaultSiteValues.id) !== null && _e !== void 0 ? _e : null,
        roleId: undefined,
        roleName: "",
        startDate: "",
        endDate: "",
        type: "",
        band: undefined,
        isSite: false,
        id: 0,
    };
    const initialGroup = {
        groupId: defaultGroupValues === null || defaultGroupValues === void 0 ? void 0 : defaultGroupValues.id,
        groupName: defaultGroupValues === null || defaultGroupValues === void 0 ? void 0 : defaultGroupValues.name,
    };
    const { handlePrint, nodeRef } = UsePrintPage(true);
    const [isUserHaveOnlyViewPermission, setIsViewOnlyPermission] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [userState, setUserState] = useState(initialUser);
    const [userRole, setUserRole] = useState(initialUserRole);
    const [key, setKey] = useState(0);
    const [roleKey, setRoleKey] = useState(1);
    const [roleListKey, setRoleListKey] = useState(2);
    const [userRoleError, setUserRoleError] = useState("");
    const [userRoleErrorKey, setUserRoleErrorKey] = useState(3);
    const [userFormDirty, setUserFormDirty] = useState(false);
    const [roleFormDirty, setRoleFormDirty] = useState(false);
    const [isUserRolesUpdated, setIsUserRolesUpdated] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(initialGroup);
    const fetchAPI = useCallback((userContext) => __awaiter(void 0, void 0, void 0, function* () {
        var _h, _j;
        const userResponse = yield UserApi.getUser(userContext === null || userContext === void 0 ? void 0 : userContext.userGroupId, userContext === null || userContext === void 0 ? void 0 : userContext.userId);
        if (userResponse) {
            if (userResponse.userType === UserType.Internal) {
                setSelectedGroup({
                    groupId: (_h = userResponse.userCompanyRoleDTOs[0]) === null || _h === void 0 ? void 0 : _h.groupId,
                    groupName: (_j = userResponse.userCompanyRoleDTOs[0]) === null || _j === void 0 ? void 0 : _j.groupName,
                });
                setIsViewOnlyPermission(!isActionAllowed([USER_EDIT]));
            }
            else {
                setIsViewOnlyPermission(!isActionAllowed([EXTERNAL_USER_UPDATE]));
            }
            setUserState(userResponse);
            setKey(Math.random());
            setUserRoles(JSON.parse(JSON.stringify(userResponse.userCompanyRoleDTOs)));
        }
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id]);
    useDidMount(() => {
        var _a, _b, _c;
        getLookupByListing({
            LookupNames: [LookupNames.LKProfession, LookupNames.LKTitle],
            getLookups,
        });
        if (!isEmpty((_a = location.state) === null || _a === void 0 ? void 0 : _a.userGroupId) && !users.length) {
            getUsers((_b = location.state) === null || _b === void 0 ? void 0 : _b.userGroupId);
        }
        if ((params === null || params === void 0 ? void 0 : params.id) && ((_c = location.state) === null || _c === void 0 ? void 0 : _c.userId)) {
            fetchAPI(location.state);
        }
    });
    useEffect(() => {
        var _a;
        if (params.id &&
            groupOptions.length &&
            userState.id > 0 &&
            isEmpty(selectedGroup.groupId)) {
            const { id, name } = (_a = groupOptions.find(({ id }) => id === userState.companyId)) !== null && _a !== void 0 ? _a : {};
            setSelectedGroup({ groupId: id, groupName: name });
        }
    }, [params.id, groupOptions, selectedGroup.groupId, userState]);
    useEffect(() => {
        if (!isEmpty(selectedGroup.groupId)) {
            setUserRole(Object.assign(Object.assign({}, initialUserRole), selectedGroup));
            if (userState.id > 0 ? userState.userType !== UserType.External : true) {
                setUserRoles([]);
            }
            setRoleKey(Math.random());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroup.groupId]);
    const assignRoleHandler = (data, setErrors) => {
        setUserRoleError("");
        setUserRoleErrorKey(Math.random());
        setErrors({ existing: "" });
        setIsUserRolesUpdated(true);
        const [startDate, endDate] = [
            moment(data.startDate).format(defaultDateTimeFormat),
            moment(data.endDate).format(defaultDateTimeFormat),
        ];
        if (data.index > -1 && data.id > 0) {
            const editedRole = userRoles[data.index];
            const [previousStartDate, previousEndDate] = [
                moment(getLocalDate(editedRole.startDate)).format(defaultDateTimeFormat),
                moment(getLocalDate(editedRole.endDate)).format(defaultDateTimeFormat),
            ];
            if (moment(previousStartDate, defaultDateTimeFormat).isSameOrBefore(moment()) &&
                !moment(previousStartDate, defaultDateTimeFormat).isSame(moment(startDate, defaultDateTimeFormat))) {
                setErrors({
                    existing: "Role is already started!",
                });
                return;
            }
            if (moment(previousEndDate, defaultDateTimeFormat).isSameOrBefore(moment()) &&
                !moment(previousEndDate, defaultDateTimeFormat).isSame(moment(endDate, defaultDateTimeFormat))) {
                setErrors({
                    existing: "Role is already expired!",
                });
                return;
            }
        }
        //End date must be greater than start date
        if (data.endDate &&
            moment(endDate, defaultDateTimeFormat).isSameOrBefore(moment(startDate, defaultDateTimeFormat))) {
            setErrors({
                existing: localize([
                    "UserRole.startDateAndTime",
                    "mustBeLessThan",
                    "UserRole.endDateAndTime",
                ]),
            });
            return;
        }
        let filterUserRole = [...userRoles];
        if (data.index > -1) {
            filterUserRole = filterUserRole.filter((_, index) => index !== data.index);
        }
        let existingCompanyRole = filterUserRole.filter((x) => x.groupId === data.groupId &&
            ((isEmpty(data.companyId) && isEmpty(x.companyId)) ||
                x.companyId === data.companyId) &&
            ((isEmpty(data.siteId) && isEmpty(x.siteId)) ||
                x.siteId === data.siteId));
        //role on same level can start after end date of existing
        existingCompanyRole = existingCompanyRole.filter(({ endDate }) => {
            const endDateFormat = typeof endDate === "string"
                ? moment(getLocalDateString(endDate), defaultDateTimeFormat)
                : endDate;
            return (isEmpty(endDate) ||
                moment(endDateFormat, defaultDateTimeFormat).isSameOrAfter(moment(data.startDate, defaultDateTimeFormat)));
        });
        if (existingCompanyRole && existingCompanyRole.length) {
            // const existingRoleshasDashboardViewer = existingCompanyRole.some(
            //   ({ roleId }) => hasDashboardViewerPermission(roles, roleId)
            // );
            // //user can have internal permission group/company/site admin and dashboard viewer but not two internal permission same time
            // const isExistAlready = hasDashboardViewerPermission(roles, data.roleId)
            //   ? existingRoleshasDashboardViewer
            //   : existingRoleshasDashboardViewer
            //   ? existingCompanyRole.length !== 1
            //   : true;
            // if (isExistAlready) {
            setErrors({
                existing: localize(["UserRole.roleAlreadyExist"]),
            });
            setIsUserRolesUpdated(false);
            return;
            //  }
        }
        var isSite = isEmpty(data.siteId) ? false : true;
        var isCompany = isEmpty(data.companyId) ? false : true;
        data = Object.assign(Object.assign({}, data), { companyName: data.companyName, groupName: data.groupName, type: isSite === true
                ? RoleCompanyType.Site
                : isCompany === true
                    ? RoleCompanyType.Company
                    : RoleCompanyType.Group, startDate: getLocalDate(data.startDate, false), endDate: getLocalDate(data.endDate, false) });
        //if edited role
        if (data.index > -1) {
            const updated = userRoles.map((x, i) => (i === data.index ? data : x));
            setUserRoles(updated);
        }
        else {
            setUserRoles([data, ...userRoles]);
        }
        setRoleListKey(Math.random());
        setUserRole(Object.assign(Object.assign({}, initialUserRole), selectedGroup));
        setRoleKey(Math.random());
        return;
    };
    //resetAll flag for whole form reset
    const resetSiteAndRole = (resetAll = false) => {
        setUserRole(Object.assign(Object.assign({}, initialUserRole), (resetAll ? initialGroup : selectedGroup)));
        if (resetAll) {
            setSelectedGroup(initialGroup);
            setUserRoles([]);
        }
        setRoleKey(Math.random());
    };
    const resetHandler = (event) => {
        setUserState(initialUser);
        setKey(Math.random());
        resetSiteAndRole(true);
        setIsUserRolesUpdated(false);
    };
    const addUserHandler = (user, setErrors, resetForm) => {
        var _a;
        if (userRoles.length === 0) {
            setUserRoleError(localize(["UserRole.userRolesAreRequired"]));
            return;
        }
        userRoles.forEach((x) => {
            if (x.type === RoleCompanyType.Site) {
                x.companyId = x.siteId;
            }
            if (x.type === RoleCompanyType.Group) {
                x.companyId = x.groupId;
            }
        });
        const userRequest = Object.assign(Object.assign({}, user), { userCompanyRoleDTOs: userRoles });
        if (params.id && params.id > 0) {
            const userRequestWithCompanyId = Object.assign(Object.assign({}, userRequest), { 
                // currentContextCompanySiteId: context?.selectedCompany?.id,
                currentContextCompanySiteId: (_a = userRequest === null || userRequest === void 0 ? void 0 : userRequest.userCompanyRoleDTOs[0]) === null || _a === void 0 ? void 0 : _a.groupId });
            editUser(userRequestWithCompanyId).then((response) => {
                if (response &&
                    response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                    setErrors({
                        username: localize(["User.userName", "alreadyExist!"]),
                    });
                    return;
                }
                notifications.success(localize(["User.user", "updated", "successfully"]));
                resetForm();
                resetSiteAndRole(true);
                setIsUserRolesUpdated(false);
                history.push(usersRoute);
            });
        }
        else {
            addUser(userRequest).then((response) => {
                if (response &&
                    response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                    setErrors({ username: localize(["User.user", "alreadyExist!"]) });
                    return;
                }
                notifications.success(localize(["User.user", "added", "successfully"]));
                resetForm();
                resetSiteAndRole(true);
                setIsUserRolesUpdated(false);
                history.push(usersRoute);
            });
        }
    };
    const hide = useSelector((state) => state.togglePrint);
    const gnerateUserId = (values, setFieldValue, selectedGroupName = selectedGroup.groupName) => {
        if (values.firstName !== undefined &&
            values.lastName !== undefined &&
            values.id === 0) {
            const userId = values.firstName +
                values.lastName +
                `${selectedGroupName
                    ? "-" + (selectedGroupName !== null && selectedGroupName !== void 0 ? selectedGroupName : context.selectedCompany.name)
                    : ""}`;
            const trimmedUserId = removeWhiteSpace(userId);
            setFieldValue("username", trimmedUserId);
        }
    };
    const editUserRoleHandler = (userRole) => {
        const clonedUserRole = JSON.parse(JSON.stringify(userRole));
        if (clonedUserRole.startDate) {
            clonedUserRole.startDate = getLocalDate(clonedUserRole.startDate, true);
        }
        if (clonedUserRole.endDate) {
            clonedUserRole.endDate = getLocalDate(clonedUserRole.endDate, true);
        }
        clonedUserRole.band = (clonedUserRole === null || clonedUserRole === void 0 ? void 0 : clonedUserRole.band)
            ? String(clonedUserRole === null || clonedUserRole === void 0 ? void 0 : clonedUserRole.band)
            : undefined;
        clonedUserRole.index = userRoles.indexOf(userRole);
        setUserRole(clonedUserRole);
        setRoleKey(Math.random());
    };
    const usersActions = (role) => (React.createElement("div", { className: "buttons-list nowrap align-items-center" },
        React.createElement(Button, { onClick: editUserRoleHandler.bind({}, role), className: "listingIcon" },
            React.createElement(RenderSvg, { icon: "Edit", tooltipTitle: ["edit"] }))));
    if (params.id && !((_f = location.state) === null || _f === void 0 ? void 0 : _f.userId)) {
        history.push(usersRoute);
    }
    // const isFormDirty = userFormDirty || roleFormDirty || isUserRolesUpdated;
    const isFormDirty = userFormDirty || isUserRolesUpdated;
    return (React.createElement("div", { ref: nodeRef, className: "customForm" },
        React.createElement(UserInformationForm, { key: key, setSelectedGroup: setSelectedGroup, onSubmit: addUserHandler, userInfo: params.id && parseInt(params.id) > 0 ? userState : initialUser, gnerateUserId: gnerateUserId, users: users, permissions: { isDelphieorSuperUser, isCompanyAdmin, isSiteAdmin }, isUserHaveOnlyViewPermission: isUserHaveOnlyViewPermission, setUserFormDirty: setUserFormDirty }),
        React.createElement("div", { key: userRoleErrorKey }, userRoleError !== "" && React.createElement("p", { className: "error" }, userRoleError)),
        React.createElement(SitesAndRoles, { key: roleKey, onSubmit: assignRoleHandler, companyId: getCompanyIdBySelectedCompany(context), userRoles: userRole, roles: roles, isGroupEnable: (params === null || params === void 0 ? void 0 : params.id) && userState.userType === UserType.External, resetSiteAndRole: resetSiteAndRole, permission: {
                isCompanyAdmin,
                isSiteAdmin,
            }, isUserHaveOnlyViewPermission: isUserHaveOnlyViewPermission &&
                userState.userType === UserType.Internal, isUserRolesUpdated: isUserRolesUpdated, setRoleFormDirty: setRoleFormDirty }),
        React.createElement("div", { className: "row box-main-section" },
            React.createElement("div", { className: "col-12" },
                React.createElement(Tabs, { defaultActiveKey: "1", className: hide.status ? "printMode" : "" },
                    React.createElement(TabPane, { tab: "Active Roles", key: "1", id: "report-definitions" },
                        React.createElement("div", { className: hide.status ? "box-heading small mb-2" : "hidden" }, localize(["Active", "Roles"])),
                        React.createElement(RolesListingTable, { key: roleListKey, userRoles: userRoles, actions: usersActions, showUser: false })),
                    params.id && params.id > 0 && (React.createElement(TabPane, { tab: "Historical Roles", key: hide.status ? "1" : "2", id: "report-type-defaults" },
                        React.createElement("div", { className: hide.status
                                ? "box-heading small  mb-2 historicalRoleTab "
                                : "historicalRoleTab  hidden" }, localize(["Historical", "Roles"])),
                        React.createElement(ExpiredRolesContainer, { companyId: (_g = location.state) === null || _g === void 0 ? void 0 : _g.userGroupId, userId: params.id })))))),
        React.createElement(FormActionButtons, { onCancel: () => history.push(usersRoute), resetForm: resetHandler, fromId: "my-form", setKey: setKey, isDirty: (isUserHaveOnlyViewPermission &&
                (params.id && parseInt(params.id) > 0
                    ? userState.userType === UserType.Internal
                    : initialUser.userType === UserType.Internal)) ||
                !isFormDirty, isEdit: (params.id && parseInt(params.id) > 0
                ? userState.id > 0
                : initialUser.id > 0) || !isFormDirty, printOnClick: handlePrint })));
};
UserFormContainer.whyDidYouRender = true;
function mapStateTopProps(state) {
    return {
        users: state.users,
        context: state.context,
        roles: state.roles,
    };
}
const mapDispatchToProps = {
    addUser,
    getUsers,
    editUser,
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(UserFormContainer);
