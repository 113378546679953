var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from "react";
import CompanyForm from "../components/companyForm";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addCompany, getCompanies, getCompaniesByGroup, } from "../../../../../redux/company/actions";
import { notifications } from "../../../../../layout/components/notifications";
import { CompanyType } from "../../../../../core/enums/CompanyType";
import { TelephoneType } from "../../../../../core/enums/TelephoneType";
import { AddressType } from "../../../../../core/enums/AddressTypes";
import { useHistory } from "react-router-dom";
import { companiesRoute } from "../../../../../core/constants/routesPath";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import localize from "../../../../../core/utils/translation-files";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { defaultSiteDetail } from "../../../../../core/contracts/site/siteContracts";
const AddCompany = ({ addCompany, companies, getCompanies }) => {
    const history = useHistory();
    const selectedContextCompany = SessionStorage.getKey(SessionVariables.COMPANY);
    useEffect(() => {
        if (companies.length === 0) {
            loadCompanies();
        }
    });
    const loadCompanies = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getCompanies().catch((error) => {
            throw error;
        });
    });
    const initialCompany = {
        id: 0,
        parentId: selectedContextCompany === null || selectedContextCompany === void 0 ? void 0 : selectedContextCompany.id,
        name: "",
        type: CompanyType.Company,
        registrationNumber: "",
        contactName: "",
        primaryTelephoneType: TelephoneType.Mobile,
        primaryTelephoneNumber: "",
        secondaryTelephoneType: TelephoneType.Mobile,
        secondaryTelephoneNumber: "",
        emailAddress: "",
        siteDetail: defaultSiteDetail,
        addresses: [
            {
                name: "",
                line1: "",
                line2: "",
                town: "",
                county: "",
                postCode: "",
                type: AddressType.RegisteredAddress,
                id: 0,
            },
            {
                name: "",
                line1: "",
                line2: "",
                town: "",
                county: "",
                postCode: "",
                type: AddressType.ContactAddress,
                id: 0,
            },
        ],
        active: true,
        holdingCompany: true,
        weight: 0,
        externalReference: "",
    };
    const addCompanyHandler = (company, resetForm, setErrors) => {
        addCompany(company).then((response) => {
            if (response &&
                (response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION ||
                    response.code === ResponseStatusCodes.VALIDATION_ERROR)) {
                setErrors({
                    [response.errorField]: response.message,
                });
            }
            else {
                resetForm();
                notifications.success(localize(["Company.company", "added", "successfully"]));
                history.push(companiesRoute);
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CompanyForm, { onCancel: () => history.push(companiesRoute), onSubmit: addCompanyHandler, submitText: "Save", company: initialCompany, companies: companies })));
};
function mapStateTopProps(state) {
    return {
        companies: state.companies,
    };
}
const mapDispatchToProps = {
    addCompany,
    getCompanies,
    getCompaniesByGroup,
};
AddCompany.propTypes = {
    addCompany: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getCompaniesByGroup: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(AddCompany);
