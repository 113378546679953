import { CLEAR_TRACKED_ITEMS_PER_SITE_SUCCESS, GET_TRACKED_ITEMS_PER_SITE_SUCCESS, } from "./types";
import { TrackedItemApi } from "../../core/api/trackedItemApi";
export const getTrackedItemsPerSiteSuccess = (data) => ({
    type: GET_TRACKED_ITEMS_PER_SITE_SUCCESS,
    payload: data,
});
export const clearAllTrackedItemsPerSite = () => ({
    type: CLEAR_TRACKED_ITEMS_PER_SITE_SUCCESS,
});
export function getTrackedItemsPerSite() {
    return function (dispatch) {
        return TrackedItemApi.getTrackedItemsPerSite()
            .then((trackedItems) => {
            if (trackedItems.length > 0) {
                dispatch(getTrackedItemsPerSiteSuccess(trackedItems));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
