import React from "react";
import { Input } from "antd";
import { addressFields } from "../../../../core/utils/address-fields";
import { useTranslation } from "react-i18next";
import ConditionalViewFormField from "../../conditionalViewFormField";
import Asterik from "../../../../views/internal/shared/helper-components/Asterik";
const ContactAddress = ({ handleBlur, handleChange, setFieldValue, values, errors, touched, isReadOnly, }) => {
    var _a, _b;
    const [t] = useTranslation();
    return (React.createElement("div", { hidden: ((_a = values.addresses[0]) === null || _a === void 0 ? void 0 : _a.type) === 1, className: `row ${isReadOnly && ((_b = values.addresses[0]) === null || _b === void 0 ? void 0 : _b.type) !== 1 && `mt-4`}` }, addressFields.map((item, index) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        return (React.createElement(React.Fragment, { key: index },
            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                React.createElement("div", { className: "form-group" },
                    React.createElement("div", { className: "ant-col bold-text ant-form-item-label" },
                        React.createElement("label", null,
                            t(`${item.placeholder}`),
                            " ",
                            item.required && React.createElement(Asterik, { hidden: isReadOnly }))),
                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: item.name === "town" || item.name === "postCode"
                            ? (_c = (_b = (_a = values === null || values === void 0 ? void 0 : values.addresses) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b[item.name]) === null || _c === void 0 ? void 0 : _c.toUpperCase()
                            : (_e = (_d = values === null || values === void 0 ? void 0 : values.addresses) === null || _d === void 0 ? void 0 : _d[1]) === null || _e === void 0 ? void 0 : _e[item.name] },
                        React.createElement(Input, { type: "text", name: `addresses.${1}.${item.name}`, placeholder: t(`${item.placeholder}`), onBlur: handleBlur, 
                            // onChange={handleChange}
                            onChange: (e) => {
                                var _a;
                                setFieldValue(`addresses.${1}.${item.name}`, item.name === "town" || item.name === "postCode"
                                    ? (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.toUpperCase()
                                    : e.target.value);
                            }, value: item.name === "town" || item.name === "postCode"
                                ? (_h = (_g = (_f = values === null || values === void 0 ? void 0 : values.addresses) === null || _f === void 0 ? void 0 : _f[1]) === null || _g === void 0 ? void 0 : _g[item.name]) === null || _h === void 0 ? void 0 : _h.toUpperCase()
                                : (_k = (_j = values === null || values === void 0 ? void 0 : values.addresses) === null || _j === void 0 ? void 0 : _j[1]) === null || _k === void 0 ? void 0 : _k[item.name], className: `${((_m = (_l = errors === null || errors === void 0 ? void 0 : errors.addresses) === null || _l === void 0 ? void 0 : _l[1]) === null || _m === void 0 ? void 0 : _m[item.name]) &&
                                ((_p = (_o = touched === null || touched === void 0 ? void 0 : touched.addresses) === null || _o === void 0 ? void 0 : _o[1]) === null || _p === void 0 ? void 0 : _p[item.name])
                                ? "input-error"
                                : null}` }),
                        ((_r = (_q = errors === null || errors === void 0 ? void 0 : errors.addresses) === null || _q === void 0 ? void 0 : _q[1]) === null || _r === void 0 ? void 0 : _r[item.name]) &&
                            ((_t = (_s = touched === null || touched === void 0 ? void 0 : touched.addresses) === null || _s === void 0 ? void 0 : _s[1]) === null || _t === void 0 ? void 0 : _t[item.name]) ? (React.createElement("p", { className: "error" }, (_v = (_u = errors.addresses) === null || _u === void 0 ? void 0 : _u[1]) === null || _v === void 0 ? void 0 : _v[item.name])) : null)))));
    })));
};
export default ContactAddress;
