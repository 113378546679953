import { Button } from "antd";
import React from "react";
import UsePrintPage from "../../../../../../../core/hooks/usePrintPage";
import localize from "../../../../../../../core/utils/translation-files";
import CustomModal from "../../../../../../../layout/components/customModal";
import TrackedItemBandScaleTable from "./trackedItemsBandScaleTable";
export default function TrackedItemBandScale({ bandScales, visible, onClose, subCategory, }) {
    const { handlePrint, nodeRef } = UsePrintPage(false);
    return (React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["TrackedItemsScale.scaleTableHeading"] },
        React.createElement(Button, { type: "primary", className: "report-scale-modal-actions", onClick: handlePrint },
            React.createElement("span", { className: "icofont icofont-printer mr-2", style: { fontSize: "1.3em" } }),
            localize(["print"])),
        React.createElement(TrackedItemBandScaleTable, { subCategory: subCategory, data: bandScales, tableRef: nodeRef })));
}
