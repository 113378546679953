import React, { useCallback, useEffect, useState } from "react";
import { Input, Tooltip } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserType } from "../../../../../../core/enums/UserStatus";
import localize from "../../../../../../core/utils/translation-files";
import { isUnique } from "../../../../../../core/validations/uniqueFieldValidations";
import { inputDefaultLength, telephoneNumberDefaultLength, } from "../../../../../../core/utils/default-length";
import LookupDropDown from "../../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../../core/constants/lookup";
import GroupDropdownContainer from "../../../../../../layout/components/select/GroupDropdownContainer";
import PromptComponent from "../../../../../../layout/components/prompt-message";
import Asterik from "../../../../shared/helper-components/Asterik";
const UserInformationForm = ({ userInfo, onSubmit, gnerateUserId, users, isSiteOrCompanyAdmin, permissions, setSelectedGroup, isUserHaveOnlyViewPermission = false, setUserFormDirty, }) => {
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, resetForm, setErrors, setFieldValue, dirty, } = useFormik({
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required(localize(["User.firstName", "isRequired!"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            middleName: Yup.string()
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ]))
                .nullable(),
            lastName: Yup.string()
                .required(localize(["User.lastName", "isRequired!"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            title: Yup.number().required(localize(["User.title", "isRequired!"])),
            username: Yup.string().when("userType", {
                is: UserType.Internal,
                then: Yup.string()
                    .required(localize(["User.userName", "isRequired!"]))
                    .max(35, localize(["maximum", "Login.35", "characters"]))
                    .test("check", localize(["User.userName", "alreadyExist!"]), function (value) {
                    return isUnique(value, users, userInfo, "username");
                })
                    .matches(/^[a-z\d\-_\s_@]+$/i, localize(["special", "characters", "notAllowed!"])),
                otherwise: Yup.string()
                    .required(localize(["User.userName", "isRequired!"]))
                    .max(35, localize(["maximum", "Login.35", "characters"]))
                    .test("check", localize(["User.userName", "alreadyExist!"]), function (value) {
                    return isUnique(value, users, userInfo, "username");
                }),
            }),
            emailAddress: Yup.string().when("userType", {
                is: UserType.Internal,
                then: Yup.string()
                    .email(localize(["enterAValid", "email"]))
                    .required(localize(["User.emailAddress", "isRequired!"]))
                    .max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
                otherwise: Yup.string()
                    .email(localize(["enterAValid", "email"]))
                    .required(localize(["User.emailAddress", "isRequired!"]))
                    .max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ]))
                    .test("check", localize(["User.emailAddress", "isRequired!"]), function (value) {
                    return isUnique(value, users, userInfo, "emailAddress");
                }),
            }),
            telephoneNumber: Yup.string()
                .trim()
                .matches(/^[0-9]*$/, localize(["Lookup.onlyNumbers", "areAllowed!"]))
                .max(telephoneNumberDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${telephoneNumberDefaultLength}`,
                "characters",
            ]))
                .nullable(),
            companyId: Yup.string().required(localize(["Group.group", "isRequired!"])),
        }),
        initialValues: userInfo,
        onSubmit: (values) => {
            onSubmit(values, setErrors, resetForm);
        },
    });
    const [isUsernameUpdated, setIsUsernameUpdated] = useState(false);
    useEffect(() => {
        setUserFormDirty(dirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);
    const handleChangeGroup = useCallback((value, option) => {
        const { name } = option;
        setFieldValue("companyId", value);
        setSelectedGroup({
            groupId: value,
            groupName: name,
        });
        !isUsernameUpdated && gnerateUserId(values, setFieldValue, name);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]);
    const handleChangeUsername = (e) => {
        setFieldValue("username", e.target.value);
        setIsUsernameUpdated(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { "data-test": "UserInformationFormComponent", className: "mb-1", name: "basic", onSubmit: handleSubmit, id: "my-form" },
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["User.userInformation"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement(Input, { name: "id", type: "hidden" }),
                        React.createElement(Input, { name: "userType", type: "hidden" }),
                        React.createElement(Input, { name: "status", type: "hidden" }),
                        React.createElement(Input, { name: "currentContextCompanySiteId", type: "hidden" }),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            " ",
                                            localize(["User.firstName"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(Input, { disabled: isUserHaveOnlyViewPermission, name: "firstName", placeholder: localize(["User.firstName"]), onBlur: handleBlur, onChange: handleChange, value: values.firstName, className: `${errors.firstName && touched.firstName
                                            ? "input-error"
                                            : null}` }),
                                    errors.firstName && touched.firstName && (React.createElement("p", { className: "error" }, errors.firstName)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null, localize(["User.middleName"]))),
                                    React.createElement(Input, { disabled: (values.userType === UserType.External &&
                                            isSiteOrCompanyAdmin) ||
                                            isUserHaveOnlyViewPermission, name: "middleName", placeholder: localize(["User.middleName"]), onBlur: handleBlur, onChange: handleChange, value: values.middleName, className: `${errors.middleName && touched.middleName
                                            ? "input-error"
                                            : null}` }),
                                    errors.middleName && touched.middleName && (React.createElement("p", { className: "error" }, errors.middleName)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["User.lastName"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(Input, { disabled: isUserHaveOnlyViewPermission, name: "lastName", placeholder: localize(["User.lastName"]), onBlur: handleBlur, onChange: handleChange, value: values.lastName, className: `${errors.lastName && touched.lastName
                                            ? "input-error"
                                            : null}` }),
                                    errors.lastName && touched.lastName && (React.createElement("p", { className: "error" }, errors.lastName)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["User.title"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(LookupDropDown, { disabled: isUserHaveOnlyViewPermission, className: "mb-2 mb-md-0", selectedValue: values.title, onChange: setFieldValue, inputName: "title", localizationKey: ["User.title"], lookUpType: LookupNames.LKTitle, error: errors.title && touched.title ? errors.title : null })))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["User.userName"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(Input, { disabled: isUserHaveOnlyViewPermission, name: "username", placeholder: localize(["User.userName"]), onBlur: handleBlur, onChange: handleChangeUsername, value: values.username, onFocus: !isUsernameUpdated &&
                                            gnerateUserId.bind({}, values, setFieldValue, undefined), onClick: !isUsernameUpdated &&
                                            gnerateUserId.bind({}, values, setFieldValue, undefined), className: `${errors.username && touched.username
                                            ? "input-error"
                                            : null}` }),
                                    errors.username && touched.username && (React.createElement("p", { className: "error" }, errors.username)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["User.notificationEmailAddress"]),
                                            " ",
                                            React.createElement(Asterik, null))),
                                    React.createElement(Tooltip, { placement: "bottom", title: localize(["User.adminEmailTooltip"]) },
                                        React.createElement(Input, { disabled: isUserHaveOnlyViewPermission, name: "emailAddress", placeholder: localize([
                                                "User.notificationEmailAddress",
                                            ]), onBlur: handleBlur, onChange: handleChange, value: values.emailAddress, className: `${errors.emailAddress && touched.emailAddress
                                                ? "input-error"
                                                : null}` }),
                                        " "),
                                    errors.emailAddress && touched.emailAddress && (React.createElement("p", { className: "error" }, errors.emailAddress)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null, localize(["User.telephoneNumber"]))),
                                    React.createElement(Input, { disabled: isUserHaveOnlyViewPermission, placeholder: localize(["User.telephoneNumber"]), type: "text", name: "telephoneNumber", value: values.telephoneNumber, onChange: handleChange, className: `${errors.telephoneNumber && touched.telephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.telephoneNumber && touched.telephoneNumber && (React.createElement("p", { className: "error" }, errors.telephoneNumber)))),
                            React.createElement("div", { className: "col-3" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null, localize(["User.profession"]))),
                                    React.createElement(LookupDropDown, { disabled: isUserHaveOnlyViewPermission, className: "mb-2 mb-md-0", selectedValue: values.professionId, onChange: setFieldValue, inputName: "professionId", lookUpType: LookupNames.LKProfession, localizationKey: ["User.profession"], error: errors.professionId && touched.professionId
                                            ? errors.professionId
                                            : null }))),
                            React.createElement("div", { className: "col-3", hidden: !(permissions === null || permissions === void 0 ? void 0 : permissions.isDelphieorSuperUser) },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            " ",
                                            localize(["Group.group"]),
                                            " ",
                                            React.createElement(Asterik, null),
                                            " ")),
                                    React.createElement(GroupDropdownContainer, { className: "mb-2 mb-md-0", onChange: handleChangeGroup, selectedValue: values.companyId, isDisable: !!values.id, error: errors.companyId && touched.companyId
                                            ? errors.companyId
                                            : null }))))))))));
};
export default UserInformationForm;
