var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { LookupNames } from "../../../../../../core/constants/lookup";
import { TrackedItemTypebenchmark } from "../../../../../../core/constants/TrackedItemsConstants";
import { trackedItemTypesListingRoute } from "../../../../../../core/constants/routesPath";
import { notifications } from "../../../../../../layout/components/notifications";
import { getLookup, getLookups } from "../../../../../../redux/lookup/actions";
import { getTrackedItemTypes, getSystemCreatedTrackedItemTypes, editTrackedItemType, addTrackedItemType, } from "../../../../../../redux/tracked-item-type/actions";
import TrackedItemTypeForm from "./components/trackedItemTypeForm";
import localize from "../../../../../../core/utils/translation-files";
import { getTrackedItems } from "../../../../../../redux/trackedItem/actions";
import { getLookupByListing } from "../../../../../../core/helpers/lookupHanlder";
import { initialTrackedItemType } from "../../defaultValues";
import "../trackedItemType.scss";
import "../../../../../../layout/components/numericInput/numericInput.scss";
import "../../../../../../layout/components/numericColoredRanges/numericColoredRanges.scss";
import { getRatingValues } from "../../../../../../layout/components/numericColoredRanges/utils";
const TrackedItemTypeFormContainer = ({ trackedItemTypes, getTrackedItemTypes, getSystemCreatedTrackedItemTypes, addTrackedItemType, editTrackedItemType, benchmarkListOptions, getLookup, getTrackedItems, getLookups, }) => {
    var _a, _b;
    const [trackedItemType, setTrackedItemType] = useState(initialTrackedItemType);
    const params = useParams();
    const [key, setKey] = useState(0);
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        var _a, _b, _c, _d;
        getLookupByListing({
            LookupNames: [
                LookupNames.LKReportType,
                LookupNames.LKReportValueType,
                LookupNames.LKReportTypeDataFrequency,
                LookupNames.LKReportTypeProjectionAverage,
                LookupNames.LKReportTypeBenchmarkDirection,
                LookupNames.LKReportTypeBenchmark,
                LookupNames.LKItemCategory,
                LookupNames.LKItemRiskLevel,
                LookupNames.LKChartDisplayUnit,
                LookupNames.LKReportWeight,
                LookupNames.LKDisplayValue,
            ],
            getLookups,
        });
        if (trackedItemTypes.length === 0) {
            ((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.systemCreated)
                ? getSystemCreatedTrackedItemTypes().catch((error) => {
                    throw error;
                })
                : getTrackedItemTypes().catch((error) => {
                    throw error;
                });
        }
        if (params.id && params.id > 0 && ((_b = location.state) === null || _b === void 0 ? void 0 : _b.reportTypeId)) {
            const trackedItemType = trackedItemTypes === null || trackedItemTypes === void 0 ? void 0 : trackedItemTypes.filter((x) => x.id === parseInt(params.id))[0];
            if (trackedItemType) {
                const newValues = JSON.parse(JSON.stringify(trackedItemType));
                if ((_c = trackedItemType.benchmarkList) === null || _c === void 0 ? void 0 : _c.length) {
                    newValues.benchmarkList = (_d = newValues.benchmarkList) === null || _d === void 0 ? void 0 : _d.map((benchmark) => {
                        return Object.assign(Object.assign({}, benchmark), {
                            gradeRanges: getRatingValues(newValues.requiredBenchmarkDirection, benchmark),
                        });
                    });
                }
                const { associatedTrackedItems } = newValues, rest = __rest(newValues, ["associatedTrackedItems"]);
                let sortedAssociatedTrackedItems = associatedTrackedItems === null || associatedTrackedItems === void 0 ? void 0 : associatedTrackedItems.sort((a, b) => (a === null || a === void 0 ? void 0 : a.priority) - (b === null || b === void 0 ? void 0 : b.priority));
                setTrackedItemType(Object.assign(Object.assign({}, rest), { associatedTrackedItems: sortedAssociatedTrackedItems }));
                setKey(Math.random());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // benchmarkListOptions.length,
        getTrackedItemTypes,
        getSystemCreatedTrackedItemTypes,
        params.id,
        trackedItemTypes,
    ]);
    const saveTrackedItemTypeHandler = (trackedItemType, setErrors, resetForm) => {
        var _a, _b;
        resetForm();
        const payloadAssociations = [...trackedItemType === null || trackedItemType === void 0 ? void 0 : trackedItemType.associatedTrackedItems];
        const formatedAssociations = (_b = (_a = payloadAssociations === null || payloadAssociations === void 0 ? void 0 : payloadAssociations.filter((item) => item.name)) === null || _a === void 0 ? void 0 : _a.map((value, index) => {
            return Object.assign(Object.assign({}, value), { priority: index + 1 });
        })) === null || _b === void 0 ? void 0 : _b.sort((a, b) => (a === null || a === void 0 ? void 0 : a.id) - (b === null || b === void 0 ? void 0 : b.id));
        let updatedAssocition = formatedAssociations.filter((value) => value === null || value === void 0 ? void 0 : value.id);
        const newAssociatedItem = formatedAssociations.filter((value) => !(value === null || value === void 0 ? void 0 : value.id));
        if (newAssociatedItem === null || newAssociatedItem === void 0 ? void 0 : newAssociatedItem.length) {
            updatedAssocition = [...updatedAssocition, ...newAssociatedItem];
        }
        trackedItemType.associatedTrackedItems = updatedAssocition;
        if (trackedItemType.id > 0) {
            editTrackedItemType(trackedItemType).then((response) => {
                if (!response || !response.code) {
                    notifications.success(localize(["TrackedItem.default", "updated", "successfully"]));
                    history.push(trackedItemTypesListingRoute);
                }
            });
            return;
        }
        addTrackedItemType(trackedItemType).then((response) => {
            if (!response || !response.code) {
                // resetForm();
                notifications.success(localize(["TrackedItem.default", "created", "successfully"]));
                history.push(trackedItemTypesListingRoute);
            }
        });
    };
    if ((params === null || params === void 0 ? void 0 : params.id) && !((_a = location.state) === null || _a === void 0 ? void 0 : _a.reportTypeId)) {
        history.push(trackedItemTypesListingRoute);
    }
    return (React.createElement(TrackedItemTypeForm, { intialTrackedItemType: trackedItemType, key: key, onSubmit: saveTrackedItemTypeHandler, trackedItemTypes: trackedItemTypes, benchmarkListOptions: benchmarkListOptions, isSystemCreated: (_b = history.location.state) === null || _b === void 0 ? void 0 : _b.systemCreated }));
};
function mapStateTopProps(state, ownProps) {
    var _a, _b, _c;
    const trackedItemReducer = state.trackedItemTypes;
    return {
        trackedItemTypes: ((_a = ownProps === null || ownProps === void 0 ? void 0 : ownProps.location.state) === null || _a === void 0 ? void 0 : _a.systemCreated)
            ? trackedItemReducer === null || trackedItemReducer === void 0 ? void 0 : trackedItemReducer.systemCreatedTrackedItems
            : trackedItemReducer === null || trackedItemReducer === void 0 ? void 0 : trackedItemReducer.userCreatedTrackedItem,
        benchmarkListOptions: (_c = (_b = state.lookups[LookupNames.LKReportTypeBenchmark]) === null || _b === void 0 ? void 0 : _b.filter((x) => parseInt(x.code) !==
            TrackedItemTypebenchmark.TRACKED_ITEM_TYPE_BENCHMARK_CUSTOM //for custom
        )) !== null && _c !== void 0 ? _c : [],
    };
}
const mapDispatchToProps = {
    getTrackedItemTypes,
    getSystemCreatedTrackedItemTypes,
    addTrackedItemType,
    editTrackedItemType,
    getLookups,
    getLookup,
    getTrackedItems,
};
export default connect(mapStateTopProps, mapDispatchToProps)(TrackedItemTypeFormContainer);
