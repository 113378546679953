export const SUPER_PERMISSION = "SUPER_PERMISSION";
export const DELPHI_ADMINISTRATOR = "DELPHI_ADMINISTRATOR";
// export const EXTERNAL_AUDITOR = "EXTERNAL_AUDITOR";
export const COMPANY_ADMINISTRATOR = "COMPANY_ADMINISTRATOR";
export const SITE_ADMINISTRATOR = "SITE_ADMINISTRATOR";
export const GROUP_ADMINISTRATOR = "GROUP_ADMINISTRATOR";
export const ACCESS_ATOM = "ACCESS_ATOM";
export const EXTERNAL_USER = "EXTERNAL_USER";
export const DASHBOARD_VIEWER = "DASHBOARD_VIEWER";
export const SITE_VIEW = "SITE_VIEW";
export const SITE_EDIT = "SITE_EDIT";
export const SITE_CREATION = "SITE_CREATION";
export const COMPANY_VIEW = "COMPANY_VIEW";
export const COMPANY_EDIT = "COMPANY_EDIT";
export const COMPANY_CREATION = "COMPANY_CREATION";
export const GROUP_VIEW = "GROUP_VIEW";
export const GROUP_CREATION = "GROUP_CREATION";
export const GROUP_EDIT = "GROUP_EDIT";
export const USER_VIEW = "USER_VIEW";
export const USER_CREATION = "USER_CREATION";
export const USER_EDIT = "USER_EDIT";
export const EXTERNAL_USER_CREATION = "EXTERNAL_USER_CREATION";
export const EXTERNAL_USER_INVITATION_WITH_ROLE = "EXTERNAL_USER_INVITATION_WITH_ROLE";
export const EXTERNAL_USER_UPDATE = "EXTERNAL_USER_UPDATE";
export const EXTERNAL_USER_ARCHIVE = "EXTERNAL_USER_ARCHIVE";
export const ROLE_VIEW = "ROLE_VIEW";
export const ROLE_EDIT = "ROLE_EDIT";
export const ROLE_CREATION = "ROLE_CREATION";
export const LOOKUP_VIEW = "LOOKUP_VIEW";
export const LOOKUP_EDIT = "LOOKUP_EDIT";
export const LOOKUP_CREATION = "LOOKUP_CREATION";
export const REPORT_VIEW = "REPORT_VIEW";
export const REPORT_EDIT = "REPORT_EDIT";
export const REPORT_CREATION = "REPORT_CREATION";
export const REPORT_DEFAULTS_VIEW = "REPORT_DEFAULTS_VIEW";
export const REPORT_DEFAULTS_EDIT = "REPORT_DEFAULTS_EDIT";
export const REPORT_DEFAULTS_CREATION = "REPORT_DEFAULTS_CREATION";
export const REPORT_DEFAULTS_DELPHI_CONFIGURATION = "REPORT_DEFAULTS_DELPHI_CONFIGURATION";
export const MANAGE_CLIENT_NOTIFICATIONS = "MANAGE_CLIENT_NOTIFICATIONS";
export const MANAGE_GLOBAL_NOTIFICATIONS = "MANAGE_GLOBAL_NOTIFICATIONS";
export const USER_REPORTS = "USER_REPORTS";
export const USER_REPORTS_FINANCIAL = "USER_REPORTS_FINANCIAL";
export const REPORT_CATEGORY_CARE_FINANCIALS = "REPORT_CATEGORY_CARE_FINANCIALS";
export const REPORT_CATEGORY_CARE_DELIVERY = "REPORT_CATEGORY_CARE_DELIVERY";
export const REPORT_CATEGORY_CARE_RISK = "REPORT_CATEGORY_CARE_RISK";
