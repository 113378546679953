import { httpGet, httpPost } from "./request/requestApi";
const controllerEndpoint = "report";
export class ReportsApi {
    static getFinancialReport(data) {
        return httpPost(`${controllerEndpoint}/getFinancialReportsData`, {
            body: data,
        });
    }
    static getFiscalYearRange(id) {
        return httpGet(`admin/site/fiscalYear/${id}`);
    }
}
