import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import "../../customPrint.scss";
import { printHideAction, resetShowAction, } from "../../redux/togglePrint/actions";
const UsePrintPage = (shouldModifyContent = true, shouldTriggerResolveHandler = true) => {
    const nodeRef = useRef();
    const context = useSelector((state) => {
        return state.context;
    });
    const dispatch = useDispatch();
    const handlePrint = useReactToPrint({
        content: () => nodeRef.current,
        documentTitle: context.userFullName,
        pageStyle: `
    @media print {
      @page {
        size: A4 landscape;

      }
    }
    `,
        onBeforeGetContent: () => {
            if (shouldModifyContent) {
                return new Promise((resolve, reject) => {
                    dispatch(printHideAction(resolve));
                    setTimeout(() => shouldTriggerResolveHandler && resolve(true), 1000);
                });
            }
        },
        onAfterPrint: () => {
            dispatch(resetShowAction());
        },
    });
    return { handlePrint, nodeRef };
};
export default UsePrintPage;
