var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { DashboardApi } from "../../../../../core/api/dashboardApi";
import "./RoadMap.scss";
import "./TableTree.scss";
import Header from "../../../../../layout/components/common/header";
import PageDescription from "../../../../../layout/components/common/pageDescription";
import localize from "../../../../../core/utils/translation-files";
import RoadmapTree from "./roadmapTree";
import { DASHBOARD_INDEX_DESCRIPTION } from "../../../../../config/constants";
import PrintButton from "../../../../../layout/components/common/PrintButton";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import { NotificationApi } from "../../../../../core/api/notificationApi";
import { Button } from "antd";
import html2canvas from "html2canvas";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfContent } from "../../../../../layout/components/customPdfContent";
import $ from "jquery";
import DownloadDataLoader from "../../../../../layout/components/customPdfContent/DownloadDataLoader";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import { USER_REPORTS, USER_REPORTS_FINANCIAL } from "../../../../../core/constants/permissionConstants";
import isEmpty from "../../../../../core/helpers/isEmpty";
const { getKey } = SessionStorage;
const RoadMapContainer = () => {
    const [apiCount, setApiCount] = useState(SessionStorage.getKey(SessionVariables.OVERVIEW_API_COUNT) !== undefined
        ? SessionStorage.getKey(SessionVariables.OVERVIEW_API_COUNT)
        : 0);
    const [treeData, setTreeData] = useState([]);
    const [bandData, setBandData] = useState({});
    const [count, setCount] = useState({});
    const { handlePrint, nodeRef } = UsePrintPage(true);
    const [financialData, setFinancialData] = useState([]);
    const [companyIdForFinancialData, setCompanyIdForFinancialData] = useState([]);
    const getNotificationAPICall = (list) => {
        const allCompanyIds = [
            ...new Set([
                ...list
                    .filter((item) => ["Group", "Company"].includes(item.category))
                    .map(({ companyId }) => companyId),
                ...companyIdForFinancialData,
            ]),
        ];
        setCompanyIdForFinancialData(allCompanyIds);
        if (allCompanyIds.length) {
            DashboardApi.getBandsInfoForCompanyFinancials(allCompanyIds, true)
                .then((response) => {
                setFinancialData(prev => [...prev, ...response]);
            })
                .catch((error) => console.log("error: ", error));
        }
        const mapId = list
            .map(({ key }) => key)
            .filter((id) => !Object.keys(notificationCountListId).includes(String(id)))
            .filter(Boolean);
        if (mapId.length) {
            setApiCount((prev) => {
                return (prev = prev + 1);
            });
            DashboardApi.getbandInfo(mapId, true)
                .then((response) => {
                setApiCount((prev) => {
                    return (prev = prev - 1);
                });
                let resp = {};
                response === null || response === void 0 ? void 0 : response.forEach((item) => {
                    Object.assign(resp, { [item === null || item === void 0 ? void 0 : item.itemId]: item });
                });
                setBandData((prev) => (Object.assign(Object.assign({}, prev), resp)));
                setApiCount((prev) => {
                    return (prev = prev + 1);
                });
                NotificationApi.getNotificationsByRoadMapListId(mapId, list[0].category).then((response) => {
                    setApiCount((prev) => {
                        return (prev = prev - 1);
                    });
                    setNotificationCountListId((prev) => (Object.assign(Object.assign({}, prev), response)));
                });
            })
                .catch((error) => {
                setApiCount((prev) => {
                    return (prev = prev - 1);
                });
                let resp = {};
                mapId === null || mapId === void 0 ? void 0 : mapId.forEach((item) => {
                    Object.assign(resp, { [item]: "empty" });
                });
                setBandData((prev) => (Object.assign(Object.assign({}, prev), resp)));
                setApiCount((prev) => {
                    return (prev = prev + 1);
                });
                NotificationApi.getNotificationsByRoadMapListId(mapId, list[0].category).then((response) => {
                    setApiCount((prev) => {
                        return (prev = prev - 1);
                    });
                    setNotificationCountListId((prev) => (Object.assign(Object.assign({}, prev), response)));
                });
            });
            setApiCount((prev) => {
                return (prev = prev + 1);
            });
            NotificationApi.getAllNotificationCount(mapId, true).then((response) => {
                setApiCount((prev) => {
                    return (prev = prev - 1);
                });
                setCount((prev) => {
                    return Object.assign(Object.assign({}, prev), response);
                });
            });
        }
    };
    const getNotificationCountBIdList = (list, isExpandAll) => {
        getNotificationAPICall(list);
        if (isExpandAll) {
            list.forEach((iterateNode, index, currentArr) => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                if ((_a = iterateNode === null || iterateNode === void 0 ? void 0 : iterateNode.children) === null || _a === void 0 ? void 0 : _a.length) {
                    return getNotificationCountBIdList(iterateNode.children, isExpandAll);
                }
            }));
        }
    };
    const [notificationCountListId, setNotificationCountListId] = useState({});
    useEffect(() => {
        const data = getKey(SessionVariables.TREE_EXPANDED_STATE, sessionStorage);
        if (!(treeData === null || treeData === void 0 ? void 0 : treeData.length) && !data) {
            setApiCount((prev) => {
                return (prev = prev + 1);
            });
            DashboardApi.getRoadMapData().then((res) => {
                setApiCount((prev) => {
                    return (prev = prev - 1);
                });
                setTreeData(res);
                getNotificationCountBIdList(res);
                getSummaryFlagByTrackItemListAPI(res);
            });
        }
        else if (!isEmpty(data)) {
            getNotificationCountBIdList(data);
            getSummaryFlagByTrackItemListAPI(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { title, description } = DASHBOARD_INDEX_DESCRIPTION !== null && DASHBOARD_INDEX_DESCRIPTION !== void 0 ? DASHBOARD_INDEX_DESCRIPTION : {};
    const [pdfContent, setPdfContent] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const loadAllData = () => {
        $("#expandAll").click();
        setLoadingData(true);
        setLoadingProgress(0);
        const timer = setInterval(() => {
            var _a;
            setLoadingProgress((loadingProgress) => loadingProgress >= 70 ? loadingProgress : loadingProgress + 5);
            if (SessionStorage.getKey(SessionVariables.OVERVIEW_API_COUNT) === 0) {
                clearInterval(timer);
                html2canvas(document.querySelector(".tableTree"), {
                    scale: ((_a = document.querySelector(".tableTree")) === null || _a === void 0 ? void 0 : _a.scrollHeight) > 4500
                        ? 1.25
                        : 2,
                }).then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    setPdfContent(imgData);
                    setLoadingProgress(100);
                });
            }
        }, 4000);
    };
    useEffect(() => {
        if (pdfContent !== null) {
            setTimeout(() => {
                $(".downloadChat button").click();
                setLoadingData(false);
                setPdfContent(null);
            }, 2000);
        }
    }, [pdfContent]);
    useEffect(() => {
        // console.log("remove")
        SessionStorage.setKey(SessionVariables.OVERVIEW_API_COUNT, apiCount);
    }, [apiCount]);
    const [summarySheetResponse, setSummarySheetResonse] = useState({});
    const isReportViewAllowed = isActionAllowed([USER_REPORTS]);
    const isFinancialDataViewAllowed = isActionAllowed([USER_REPORTS_FINANCIAL]);
    const getSummaryFlagByTrackItemListAPI = (list) => {
        if (isReportViewAllowed && isFinancialDataViewAllowed) {
            const mapId = list.map(({ key }) => key);
            if (mapId.length) {
                DashboardApi.getSummaryFlagByTrackItemList(mapId, `${list[0].category}-financial`).then((response) => {
                    setSummarySheetResonse((prev) => (Object.assign(Object.assign({}, prev), response)));
                });
            }
        }
    };
    return (React.createElement("div", null,
        React.createElement(Header, { leftComponent: React.createElement("h4", null, localize(["DashboardMenus.overview"])), rightComponent: React.createElement("div", { className: "row" },
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Button, { type: "primary", onClick: loadAllData, id: "downloadBtn", key: `${loadingData}`, disabled: loadingData === true ? true : false }, loadingData === true ? "Downloading..." : "Download"),
                React.createElement(PDFDownloadLink, { fileName: "Overview Data", className: "downloadChat d-none", document: React.createElement(PdfContent, { image: pdfContent }) },
                    React.createElement(Button, { onClick: null }))) }),
        React.createElement("div", null),
        React.createElement(PageDescription, { title: title, description: description }),
        React.createElement(DownloadDataLoader, { percent: loadingProgress, loadingData: loadingData, screen: "overview", callBack: () => setApiCount(0) }),
        React.createElement("div", { ref: nodeRef },
            React.createElement(RoadmapTree, { treeData: treeData, setTreeData: setTreeData, 
                // watchListId={watchListId}
                // setWatchListId={setWatchListId}
                // ids={ids}
                bandData: bandData, setBandData: setBandData, count: count, setCount: setCount, getNotificationCountBIdList: getNotificationCountBIdList, notificationCountListId: notificationCountListId, setNotificationCountListId: setNotificationCountListId, financialData: financialData, summarySheetResponse: summarySheetResponse, getSummaryFlagByTrackItemListAPI: getSummaryFlagByTrackItemListAPI }))));
};
export default RoadMapContainer;
