import React from "react";
export const largeGraphIcon = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
    React.createElement("path", { d: "M3 13C3 12.448 3.448 12 4 12", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M3 16V17", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M4 21C3.448 21 3 20.552 3 20", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M7 21H8", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M7 12H8", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M12 20C12 20.552 11.552 21 11 21", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M12 17V16", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M11 12C11.552 12 12 12.448 12 13", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M7 12V5C7 3.895 7.895 3 9 3H19C20.105 3 21 3.895 21 5V15C21 16.105 20.105 17 19 17H12", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M14 10L18 6", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M15 6H18V9", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" })));
export const smallHistoricalScale = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "120", viewBox: "0 0 20 120", fill: "none" },
    React.createElement("rect", { width: "20", height: "30", transform: "matrix(-1 0 0 1 20 0)", fill: "#00B050" }),
    React.createElement("path", { d: "M10.2305 11.9277L7.91016 19H6.04102L9.21094 10.4688H10.4004L10.2305 11.9277ZM12.1582 19L9.83203 11.9277L9.64453 10.4688H10.8457L14.0332 19H12.1582ZM12.0527 15.8242V17.2012H7.54688V15.8242H12.0527Z", fill: "black" }),
    React.createElement("rect", { width: "20", height: "30", transform: "matrix(-1 0 0 1 20 30)", fill: "#92D050" }),
    React.createElement("path", { d: "M10.1016 45.2559H7.91016L7.89844 44.0488H9.73828C10.0625 44.0488 10.3281 44.0078 10.5352 43.9258C10.7422 43.8398 10.8965 43.7168 10.998 43.5566C11.1035 43.3926 11.1562 43.1934 11.1562 42.959C11.1562 42.6934 11.1055 42.4785 11.0039 42.3145C10.9062 42.1504 10.752 42.0312 10.541 41.957C10.334 41.8828 10.0664 41.8457 9.73828 41.8457H8.51953V49H6.76172V40.4688H9.73828C10.2344 40.4688 10.6777 40.5156 11.0684 40.6094C11.4629 40.7031 11.7969 40.8457 12.0703 41.0371C12.3438 41.2285 12.5527 41.4707 12.6973 41.7637C12.8418 42.0527 12.9141 42.3965 12.9141 42.7949C12.9141 43.1465 12.834 43.4707 12.6738 43.7676C12.5176 44.0645 12.2695 44.3066 11.9297 44.4941C11.5938 44.6816 11.1543 44.7852 10.6113 44.8047L10.1016 45.2559ZM10.0254 49H7.42969L8.11523 47.6289H10.0254C10.334 47.6289 10.5859 47.5801 10.7812 47.4824C10.9766 47.3809 11.1211 47.2441 11.2148 47.0723C11.3086 46.9004 11.3555 46.7031 11.3555 46.4805C11.3555 46.2305 11.3125 46.0137 11.2266 45.8301C11.1445 45.6465 11.0117 45.5059 10.8281 45.4082C10.6445 45.3066 10.4023 45.2559 10.1016 45.2559H8.4082L8.41992 44.0488H10.5293L10.9336 44.5234C11.4531 44.5156 11.8711 44.6074 12.1875 44.7988C12.5078 44.9863 12.7402 45.2305 12.8848 45.5312C13.0332 45.832 13.1074 46.1543 13.1074 46.498C13.1074 47.0449 12.9883 47.5059 12.75 47.8809C12.5117 48.252 12.1621 48.5312 11.7012 48.7188C11.2441 48.9062 10.6855 49 10.0254 49Z", fill: "black" }),
    React.createElement("rect", { width: "20", height: "30", transform: "matrix(-1 0 0 1 20 60)", fill: "#FFC000" }),
    React.createElement("path", { d: "M11.707 76.1699H13.459C13.4238 76.7441 13.2656 77.2539 12.9844 77.6992C12.707 78.1445 12.3184 78.4922 11.8184 78.7422C11.3223 78.9922 10.7246 79.1172 10.0254 79.1172C9.47852 79.1172 8.98828 79.0234 8.55469 78.8359C8.12109 78.6445 7.75 78.3711 7.44141 78.0156C7.13672 77.6602 6.9043 77.2305 6.74414 76.7266C6.58398 76.2227 6.50391 75.6582 6.50391 75.0332V74.4414C6.50391 73.8164 6.58594 73.252 6.75 72.748C6.91797 72.2402 7.15625 71.8086 7.46484 71.4531C7.77734 71.0977 8.15039 70.8242 8.58398 70.6328C9.01758 70.4414 9.50195 70.3457 10.0371 70.3457C10.748 70.3457 11.3477 70.4746 11.8359 70.7324C12.3281 70.9902 12.709 71.3457 12.9785 71.7988C13.252 72.252 13.416 72.7676 13.4707 73.3457H11.7129C11.6934 73.002 11.625 72.7109 11.5078 72.4727C11.3906 72.2305 11.2129 72.0488 10.9746 71.9277C10.7402 71.8027 10.4277 71.7402 10.0371 71.7402C9.74414 71.7402 9.48828 71.7949 9.26953 71.9043C9.05078 72.0137 8.86719 72.1797 8.71875 72.4023C8.57031 72.625 8.45898 72.9062 8.38477 73.2461C8.31445 73.582 8.2793 73.9766 8.2793 74.4297V75.0332C8.2793 75.4746 8.3125 75.8633 8.37891 76.1992C8.44531 76.5312 8.54688 76.8125 8.68359 77.043C8.82422 77.2695 9.00391 77.4414 9.22266 77.5586C9.44531 77.6719 9.71289 77.7285 10.0254 77.7285C10.3926 77.7285 10.6953 77.6699 10.9336 77.5527C11.1719 77.4355 11.3535 77.2617 11.4785 77.0312C11.6074 76.8008 11.6836 76.5137 11.707 76.1699Z", fill: "black" }),
    React.createElement("rect", { width: "20", height: "30", transform: "matrix(-1 0 0 1 20 90)", fill: "#FF0000" }),
    React.createElement("path", { d: "M9.38086 109H7.52344L7.53516 107.629H9.38086C9.8418 107.629 10.2305 107.525 10.5469 107.318C10.8633 107.107 11.1016 106.801 11.2617 106.398C11.4258 105.996 11.5078 105.51 11.5078 104.939V104.523C11.5078 104.086 11.4609 103.701 11.3672 103.369C11.2773 103.037 11.1426 102.758 10.9629 102.531C10.7832 102.305 10.5625 102.135 10.3008 102.021C10.0391 101.904 9.73828 101.846 9.39844 101.846H7.48828V100.469H9.39844C9.96875 100.469 10.4902 100.566 10.9629 100.762C11.4395 100.953 11.8516 101.229 12.1992 101.588C12.5469 101.947 12.8145 102.377 13.002 102.877C13.1934 103.373 13.2891 103.926 13.2891 104.535V104.939C13.2891 105.545 13.1934 106.098 13.002 106.598C12.8145 107.098 12.5469 107.527 12.1992 107.887C11.8555 108.242 11.4434 108.518 10.9629 108.713C10.4863 108.904 9.95898 109 9.38086 109ZM8.51953 100.469V109H6.76172V100.469H8.51953Z", fill: "black" })));
export const largeHistoricalScale = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "40", height: "400", viewBox: "0 0 40 400", fill: "none" },
    React.createElement("rect", { width: "40", height: "100", transform: "matrix(-1 0 0 1 40 0)", fill: "#00B050" }),
    React.createElement("rect", { width: "40", height: "100", transform: "matrix(-1 0 0 1 40 100)", fill: "#92D050" }),
    React.createElement("rect", { width: "40", height: "100", transform: "matrix(-1 0 0 1 40 200)", fill: "#FFC000" }),
    React.createElement("rect", { width: "40", height: "100", transform: "matrix(-1 0 0 1 40 300)", fill: "#FF0000" }),
    React.createElement("path", { d: "M19.9355 46.749L17.2285 55H15.0479L18.7461 45.0469H20.1338L19.9355 46.749ZM22.1846 55L19.4707 46.749L19.252 45.0469H20.6533L24.3721 55H22.1846ZM22.0615 51.2949V52.9014H16.8047V51.2949H22.0615Z", fill: "black" }),
    React.createElement("path", { d: "M20.7852 150.632H18.2285L18.2148 149.224H20.3613C20.7396 149.224 21.0495 149.176 21.291 149.08C21.5326 148.98 21.7126 148.836 21.8311 148.649C21.9541 148.458 22.0156 148.226 22.0156 147.952C22.0156 147.642 21.9564 147.392 21.8379 147.2C21.724 147.009 21.5439 146.87 21.2979 146.783C21.0563 146.697 20.7441 146.653 20.3613 146.653H18.9395V155H16.8887V145.047H20.3613C20.9401 145.047 21.4574 145.102 21.9131 145.211C22.3734 145.32 22.763 145.487 23.082 145.71C23.401 145.933 23.6449 146.216 23.8135 146.558C23.9821 146.895 24.0664 147.296 24.0664 147.761C24.0664 148.171 23.973 148.549 23.7861 148.896C23.6038 149.242 23.3145 149.524 22.918 149.743C22.526 149.962 22.0133 150.083 21.3799 150.105L20.7852 150.632ZM20.6963 155H17.668L18.4678 153.4H20.6963C21.0563 153.4 21.3503 153.343 21.5781 153.229C21.806 153.111 21.9746 152.951 22.084 152.751C22.1934 152.55 22.248 152.32 22.248 152.061C22.248 151.769 22.1979 151.516 22.0977 151.302C22.002 151.088 21.847 150.924 21.6328 150.81C21.4186 150.691 21.1361 150.632 20.7852 150.632H18.8096L18.8232 149.224H21.2842L21.7559 149.777C22.362 149.768 22.8496 149.875 23.2188 150.099C23.5924 150.317 23.8636 150.602 24.0322 150.953C24.2054 151.304 24.292 151.68 24.292 152.081C24.292 152.719 24.153 153.257 23.875 153.694C23.597 154.127 23.1891 154.453 22.6514 154.672C22.1182 154.891 21.4665 155 20.6963 155Z", fill: "black" }),
    React.createElement("path", { d: "M21.6582 251.698H23.7021C23.6611 252.368 23.4766 252.963 23.1484 253.482C22.8249 254.002 22.3714 254.408 21.7881 254.699C21.2093 254.991 20.512 255.137 19.6963 255.137C19.0583 255.137 18.4863 255.027 17.9805 254.809C17.4746 254.585 17.0417 254.266 16.6816 253.852C16.3262 253.437 16.055 252.936 15.8682 252.348C15.6813 251.76 15.5879 251.101 15.5879 250.372V249.682C15.5879 248.952 15.6836 248.294 15.875 247.706C16.071 247.114 16.349 246.61 16.709 246.195C17.0736 245.781 17.5088 245.462 18.0146 245.238C18.5205 245.015 19.0856 244.903 19.71 244.903C20.5394 244.903 21.2389 245.054 21.8086 245.354C22.3828 245.655 22.8271 246.07 23.1416 246.599C23.4606 247.127 23.652 247.729 23.7158 248.403H21.665C21.6423 248.002 21.5625 247.663 21.4258 247.385C21.2891 247.102 21.0817 246.89 20.8037 246.749C20.5303 246.603 20.1657 246.53 19.71 246.53C19.3682 246.53 19.0697 246.594 18.8145 246.722C18.5592 246.849 18.3451 247.043 18.1719 247.303C17.9987 247.562 17.8688 247.891 17.7822 248.287C17.7002 248.679 17.6592 249.139 17.6592 249.668V250.372C17.6592 250.887 17.6979 251.34 17.7754 251.732C17.8529 252.12 17.9714 252.448 18.1309 252.717C18.2949 252.981 18.5046 253.182 18.7598 253.318C19.0195 253.451 19.3317 253.517 19.6963 253.517C20.1247 253.517 20.4779 253.448 20.7559 253.312C21.0339 253.175 21.2458 252.972 21.3916 252.703C21.542 252.434 21.6309 252.099 21.6582 251.698Z", fill: "black" }),
    React.createElement("path", { d: "M18.9443 355H16.7773L16.791 353.4H18.9443C19.4821 353.4 19.9355 353.28 20.3047 353.038C20.6738 352.792 20.9518 352.434 21.1387 351.965C21.3301 351.495 21.4258 350.928 21.4258 350.263V349.777C21.4258 349.267 21.3711 348.818 21.2617 348.431C21.1569 348.043 20.9997 347.717 20.79 347.453C20.5804 347.189 20.3229 346.991 20.0176 346.858C19.7122 346.722 19.3613 346.653 18.9648 346.653H16.7363V345.047H18.9648C19.6302 345.047 20.2386 345.161 20.79 345.389C21.346 345.612 21.8268 345.933 22.2324 346.353C22.638 346.772 22.9502 347.273 23.1689 347.856C23.3923 348.435 23.5039 349.08 23.5039 349.791V350.263C23.5039 350.969 23.3923 351.614 23.1689 352.197C22.9502 352.781 22.638 353.282 22.2324 353.701C21.8314 354.116 21.3506 354.437 20.79 354.665C20.234 354.888 19.6188 355 18.9443 355ZM17.9395 345.047V355H15.8887V345.047H17.9395Z", fill: "black" })));
export const AtoB = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "265", height: "121", viewBox: "0 0 265 121", fill: "none" },
        React.createElement("rect", { width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265 16H0V15H265V16ZM265 31H0V30H265V31ZM0 46H265V45H0V46ZM265 61H0V60H265V61ZM0 76H265V75H0V76ZM265 91H0V90H265V91ZM0 106H265V105H0V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M4.82715 120.5L4.82715 0.5H3.82715L3.82715 120.5H4.82715ZM41.8271 120.5L41.8271 0.5H40.8271L40.8271 120.5H41.8271ZM78.8271 0.5L78.8271 120.5H77.8271L77.8271 0.5H78.8271ZM115.827 120L115.827 0H114.827L114.827 120H115.827ZM152.827 0V120H151.827V0H152.827ZM189.827 120.5V0.5H188.827V120.5H189.827ZM226.827 0.5V120.5H225.827V0.5H226.827ZM263.827 120.5V0.5H262.827V120.5H263.827Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M27.8779 14.2129L24.0107 26H20.8955L26.1787 11.7812H28.1611L27.8779 14.2129ZM31.0908 26L27.2139 14.2129L26.9014 11.7812H28.9033L34.2158 26H31.0908ZM30.915 20.707V23.002H23.4053V20.707H30.915Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M238.663 79.7598H235.011L234.991 77.748H238.058C238.598 77.748 239.041 77.6797 239.386 77.543C239.731 77.3997 239.988 77.1947 240.157 76.9277C240.333 76.6543 240.421 76.3223 240.421 75.9316C240.421 75.4889 240.336 75.1309 240.167 74.8574C240.004 74.584 239.747 74.3854 239.396 74.2617C239.05 74.138 238.604 74.0762 238.058 74.0762H236.026V86H233.097V71.7812H238.058C238.884 71.7812 239.623 71.8594 240.274 72.0156C240.932 72.1719 241.489 72.4095 241.944 72.7285C242.4 73.0475 242.748 73.4512 242.989 73.9395C243.23 74.4212 243.351 74.9941 243.351 75.6582C243.351 76.2441 243.217 76.7845 242.95 77.2793C242.69 77.7741 242.276 78.1777 241.71 78.4902C241.15 78.8027 240.418 78.9753 239.513 79.0078L238.663 79.7598ZM238.536 86H234.21L235.353 83.7148H238.536C239.05 83.7148 239.47 83.6335 239.796 83.4707C240.121 83.3014 240.362 83.0736 240.519 82.7871C240.675 82.5007 240.753 82.1719 240.753 81.8008C240.753 81.3841 240.681 81.0228 240.538 80.7168C240.401 80.4108 240.18 80.1764 239.874 80.0137C239.568 79.8444 239.164 79.7598 238.663 79.7598H235.841L235.86 77.748H239.376L240.05 78.5391C240.916 78.526 241.612 78.679 242.14 78.998C242.674 79.3105 243.061 79.7174 243.302 80.2188C243.549 80.7201 243.673 81.2572 243.673 81.8301C243.673 82.7415 243.474 83.5098 243.077 84.1348C242.68 84.7533 242.097 85.2188 241.329 85.5312C240.567 85.8438 239.636 86 238.536 86Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2257_42196)" },
            React.createElement("path", { d: "M12.8271 32H42.8271V107H12.8271V32Z", fill: `url(#paint0_linear_2257_42196_${renderKey})` }),
            React.createElement("path", { d: "M93.8271 49H63.8271V107H93.8271V49Z", fill: `url(#paint1_linear_2257_42196_${renderKey})` }),
            React.createElement("path", { d: "M116.827 60H146.827V107H116.827V60Z", fill: `url(#paint2_linear_2257_42196_${renderKey})` }),
            React.createElement("path", { d: "M199.827 80H169.827V107H199.827V80Z", fill: `url(#paint3_linear_2257_42196_${renderKey})` }),
            React.createElement("path", { d: "M252.827 93H222.827V107H252.827V93Z", fill: `url(#paint4_linear_2257_42196_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2257_42196)" },
            React.createElement("path", { d: "M212.621 96.9092L212.999 104.154L227.581 90.3984L210.643 80.0686L211.373 86.8624C147.806 97.8021 78.5071 73.1315 23.0156 39.2378C73.7184 81.1355 162.38 108.486 212.621 96.9092Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2257_42196", x: "12.8271", y: "32", width: "248", height: "75", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.0875 0 0 0 0 0.35 0 0 0 0 0.206818 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2257_42196" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2257_42196", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2257_42196", x: "23.0156", y: "39.2378", width: "207.565", height: "66.916", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2257_42196" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2257_42196", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2257_42196_${renderKey}`, x1: "119.327", y1: "23", x2: "119.827", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint1_linear_2257_42196_${renderKey}`, x1: "119.327", y1: "23", x2: "119.827", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint2_linear_2257_42196_${renderKey}`, x1: "119.327", y1: "23", x2: "119.827", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint3_linear_2257_42196_${renderKey}`, x1: "119.327", y1: "23", x2: "119.827", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint4_linear_2257_42196_${renderKey}`, x1: "119.327", y1: "23", x2: "119.827", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })))));
};
export const BtoC = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "265", height: "121", viewBox: "0 0 265 121", fill: "none" },
        React.createElement("path", { d: "M0 0H265V120H0V0Z", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265 16H0V15H265V16ZM265 31H0V30H265V31ZM0 46H265V45H0V46ZM265 61H0V60H265V61ZM0 76H265V75H0V76ZM265 91H0V90H265V91ZM0 106H265V105H0V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M3.82617 120.5L3.82617 0.5H2.82617L2.82617 120.5H3.82617ZM40.8262 120.5L40.8262 0.5H39.8262L39.8262 120.5H40.8262ZM77.8262 0.5L77.8262 120.5H76.8262L76.8262 0.5H77.8262ZM114.826 120L114.826 0H113.826L113.826 120H114.826ZM151.826 0V120H150.826V0H151.826ZM188.826 120.5V0.5H187.826V120.5H188.826ZM225.826 0.5V120.5H224.826V0.5H225.826ZM262.826 120.5V0.5H261.826V120.5H262.826Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M27.3369 19.7598H23.6846L23.665 17.748H26.7314C27.2718 17.748 27.7145 17.6797 28.0596 17.543C28.4046 17.3997 28.6618 17.1947 28.8311 16.9277C29.0068 16.6543 29.0947 16.3223 29.0947 15.9316C29.0947 15.4889 29.0101 15.1309 28.8408 14.8574C28.6781 14.584 28.4209 14.3854 28.0693 14.2617C27.7243 14.138 27.2783 14.0762 26.7314 14.0762H24.7002V26H21.7705V11.7812H26.7314C27.5583 11.7812 28.2972 11.8594 28.9482 12.0156C29.6058 12.1719 30.1624 12.4095 30.6182 12.7285C31.0739 13.0475 31.4222 13.4512 31.6631 13.9395C31.904 14.4212 32.0244 14.9941 32.0244 15.6582C32.0244 16.2441 31.891 16.7845 31.624 17.2793C31.3636 17.7741 30.9502 18.1777 30.3838 18.4902C29.8239 18.8027 29.0915 18.9753 28.1865 19.0078L27.3369 19.7598ZM27.21 26H22.8838L24.0264 23.7148H27.21C27.7243 23.7148 28.1442 23.6335 28.4697 23.4707C28.7952 23.3014 29.0361 23.0736 29.1924 22.7871C29.3486 22.5007 29.4268 22.1719 29.4268 21.8008C29.4268 21.3841 29.3551 21.0228 29.2119 20.7168C29.0752 20.4108 28.8538 20.1764 28.5479 20.0137C28.2419 19.8444 27.8382 19.7598 27.3369 19.7598H24.5146L24.5342 17.748H28.0498L28.7236 18.5391C29.5895 18.526 30.2861 18.679 30.8135 18.998C31.3473 19.3105 31.7347 19.7174 31.9756 20.2188C32.223 20.7201 32.3467 21.2572 32.3467 21.8301C32.3467 22.7415 32.1481 23.5098 31.751 24.1348C31.3538 24.7533 30.7712 25.2188 30.0029 25.5312C29.2412 25.8438 28.3102 26 27.21 26Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M241.013 81.2832H243.933C243.874 82.2402 243.61 83.0898 243.142 83.832C242.679 84.5742 242.032 85.1536 241.198 85.5703C240.371 85.987 239.375 86.1953 238.21 86.1953C237.299 86.1953 236.481 86.0391 235.759 85.7266C235.036 85.4076 234.418 84.9518 233.903 84.3594C233.396 83.7669 233.008 83.0508 232.741 82.2109C232.474 81.3711 232.341 80.4303 232.341 79.3887V78.4023C232.341 77.3607 232.478 76.4199 232.751 75.5801C233.031 74.7337 233.428 74.0143 233.942 73.4219C234.463 72.8294 235.085 72.3737 235.808 72.0547C236.53 71.7357 237.338 71.5762 238.229 71.5762C239.414 71.5762 240.414 71.791 241.228 72.2207C242.048 72.6504 242.683 73.2428 243.132 73.998C243.588 74.7533 243.861 75.6126 243.952 76.5762H241.022C240.99 76.0033 240.876 75.5182 240.681 75.1211C240.485 74.7174 240.189 74.4147 239.792 74.2129C239.401 74.0046 238.881 73.9004 238.229 73.9004C237.741 73.9004 237.315 73.9915 236.95 74.1738C236.586 74.3561 236.28 74.6328 236.032 75.0039C235.785 75.375 235.599 75.8438 235.476 76.4102C235.358 76.9701 235.3 77.6276 235.3 78.3828V79.3887C235.3 80.1243 235.355 80.7721 235.466 81.332C235.576 81.8854 235.746 82.3542 235.974 82.7383C236.208 83.1159 236.507 83.4023 236.872 83.5977C237.243 83.7865 237.689 83.8809 238.21 83.8809C238.822 83.8809 239.326 83.7832 239.724 83.5879C240.121 83.3926 240.424 83.1029 240.632 82.7188C240.847 82.3346 240.974 81.8561 241.013 81.2832Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_48389)" },
            React.createElement("path", { d: "M12.501 32H42.501V107H12.501V32Z", fill: `url(#paint0_linear_2401_48389_${renderKey})` }),
            React.createElement("path", { d: "M93.501 49H63.501V107H93.501V49Z", fill: `url(#paint1_linear_2401_48389_${renderKey})` }),
            React.createElement("path", { d: "M116.501 60H146.501V107H116.501V60Z", fill: `url(#paint2_linear_2401_48389_${renderKey})` }),
            React.createElement("path", { d: "M199.501 80H169.501V107H199.501V80Z", fill: `url(#paint3_linear_2401_48389_${renderKey})` }),
            React.createElement("path", { d: "M252.501 93H222.501V107H252.501V93Z", fill: `url(#paint4_linear_2401_48389_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_48389)" },
            React.createElement("path", { d: "M212.295 96.9092L212.673 104.154L227.255 90.3984L210.317 80.0686L211.047 86.8624C147.48 97.8021 78.1809 73.1315 22.6895 39.2378C73.3923 81.1355 162.054 108.486 212.295 96.9092Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_48389", x: "12.501", y: "32", width: "248", height: "75", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.495833 0 0 0 0 0.419875 0 0 0 0 0.188003 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_48389" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_48389", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_48389", x: "22.6895", y: "39.2378", width: "207.565", height: "66.916", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_48389" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_48389", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_48389_${renderKey}`, x1: "119.001", y1: "23", x2: "119.501", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_48389_${renderKey}`, x1: "119.001", y1: "23", x2: "119.501", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_48389_${renderKey}`, x1: "119.001", y1: "23", x2: "119.501", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_48389_${renderKey}`, x1: "119.001", y1: "23", x2: "119.501", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_48389_${renderKey}`, x1: "119.001", y1: "23", x2: "119.501", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })))));
};
export const CtoD = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "266", height: "121", viewBox: "0 0 266 121", fill: "none" },
        React.createElement("rect", { x: "0.173828", y: "1", width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265.174 16H0.173828V15H265.174V16ZM265.174 31H0.173828V30H265.174V31ZM0.173828 46H265.174V45H0.173828V46ZM265.174 61H0.173828V60H265.174V61ZM0.173828 76H265.174V75H0.173828V76ZM265.174 91H0.173828V90H265.174V91ZM0.173828 106H265.174V105H0.173828V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M3.67383 121L3.67383 1H2.67383L2.67383 121H3.67383ZM40.6738 121L40.6738 1H39.6738L39.6738 121H40.6738ZM77.6738 1L77.6738 121H76.6738L76.6738 1H77.6738ZM114.674 120.5L114.674 0.5H113.674L113.674 120.5H114.674ZM151.674 0.5V120.5H150.674V0.5H151.674ZM188.674 121V1H187.674V121H188.674ZM225.674 1V121H224.674V1H225.674ZM262.674 121V1H261.674V121H262.674Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M29.2354 22.4785H31.1104C31.0127 23.377 30.7555 24.181 30.3389 24.8906C29.9222 25.6003 29.333 26.1634 28.5713 26.5801C27.8096 26.9902 26.859 27.1953 25.7197 27.1953C24.8864 27.1953 24.1279 27.0391 23.4443 26.7266C22.7673 26.4141 22.1846 25.9714 21.6963 25.3984C21.208 24.819 20.8304 24.1257 20.5635 23.3184C20.3031 22.5046 20.1729 21.5996 20.1729 20.6035V19.1875C20.1729 18.1914 20.3031 17.2897 20.5635 16.4824C20.8304 15.6686 21.2113 14.972 21.7061 14.3926C22.2074 13.8132 22.8096 13.3672 23.5127 13.0547C24.2158 12.7422 25.0068 12.5859 25.8857 12.5859C26.96 12.5859 27.8682 12.7878 28.6104 13.1914C29.3525 13.5951 29.9287 14.1549 30.3389 14.8711C30.7555 15.5807 31.0127 16.4043 31.1104 17.3418H29.2354C29.1442 16.6777 28.9749 16.1081 28.7275 15.6328C28.4801 15.151 28.1286 14.7799 27.6729 14.5195C27.2171 14.2591 26.6214 14.1289 25.8857 14.1289C25.2542 14.1289 24.6976 14.2493 24.2158 14.4902C23.7406 14.7311 23.3402 15.0729 23.0146 15.5156C22.6956 15.9583 22.4548 16.4889 22.292 17.1074C22.1292 17.7259 22.0479 18.4128 22.0479 19.168V20.6035C22.0479 21.3001 22.1195 21.9544 22.2627 22.5664C22.4124 23.1784 22.637 23.7155 22.9365 24.1777C23.236 24.64 23.6169 25.0046 24.0791 25.2715C24.5413 25.5319 25.0882 25.6621 25.7197 25.6621C26.5205 25.6621 27.1585 25.5352 27.6338 25.2812C28.109 25.0273 28.4671 24.6628 28.708 24.1875C28.9554 23.7122 29.1312 23.1426 29.2354 22.4785Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M235.498 87H232.529L232.549 85.4668H235.498C236.514 85.4668 237.36 85.2552 238.037 84.832C238.714 84.4023 239.222 83.8034 239.561 83.0352C239.906 82.2604 240.078 81.3555 240.078 80.3203V79.4512C240.078 78.6374 239.98 77.9147 239.785 77.2832C239.59 76.6452 239.303 76.1081 238.926 75.6719C238.548 75.2292 238.086 74.8939 237.539 74.666C236.999 74.4382 236.377 74.3242 235.674 74.3242H232.471V72.7812H235.674C236.605 72.7812 237.454 72.9375 238.223 73.25C238.991 73.556 239.652 74.002 240.205 74.5879C240.765 75.1673 241.195 75.8704 241.494 76.6973C241.794 77.5176 241.943 78.4421 241.943 79.4707V80.3203C241.943 81.349 241.794 82.2767 241.494 83.1035C241.195 83.9238 240.762 84.6237 240.195 85.2031C239.635 85.7826 238.958 86.2285 238.164 86.541C237.376 86.847 236.488 87 235.498 87ZM233.535 72.7812V87H231.65V72.7812H233.535Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_49590)" },
            React.createElement("path", { d: "M11.001 33H41.001V108H11.001V33Z", fill: `url(#paint0_linear_2401_49590_${renderKey})` }),
            React.createElement("path", { d: "M92.001 50H62.001V108H92.001V50Z", fill: `url(#paint1_linear_2401_49590_${renderKey})` }),
            React.createElement("path", { d: "M115.001 61H145.001V108H115.001V61Z", fill: `url(#paint2_linear_2401_49590_${renderKey})` }),
            React.createElement("path", { d: "M198.001 81H168.001V108H198.001V81Z", fill: `url(#paint3_linear_2401_49590_${renderKey})` }),
            React.createElement("path", { d: "M251.001 94H221.001V108H251.001V94Z", fill: `url(#paint4_linear_2401_49590_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_49590)" },
            React.createElement("path", { d: "M212.794 97.9092L213.172 105.154L227.754 91.3984L210.816 81.0686L211.546 87.8624C147.979 98.8021 78.68 74.1315 23.1885 40.2378C73.8913 82.1355 162.553 109.486 212.794 97.9092Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_49590", x: "11.001", y: "33", width: "248", height: "75", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.329167 0 0 0 0 0.137153 0 0 0 0 0.137153 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49590" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49590", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_49590", x: "23.1885", y: "40.2378", width: "207.565", height: "66.916", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49590" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49590", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_49590_${renderKey}`, x1: "117.501", y1: "24", x2: "118.001", y2: "108", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_49590_${renderKey}`, x1: "117.501", y1: "24", x2: "118.001", y2: "108", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_49590_${renderKey}`, x1: "117.501", y1: "24", x2: "118.001", y2: "108", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_49590_${renderKey}`, x1: "117.501", y1: "24", x2: "118.001", y2: "108", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_49590_${renderKey}`, x1: "117.501", y1: "24", x2: "118.001", y2: "108", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })))));
};
export const DtoC = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "266", height: "121", viewBox: "0 0 266 121", fill: "none" },
        React.createElement("rect", { x: "0.924805", width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265.675 16H0.674805V15H265.675V16ZM265.675 31H0.674805V30H265.675V31ZM0.674805 46H265.675V45H0.674805V46ZM265.675 61H0.674805V60H265.675V61ZM0.674805 76H265.675V75H0.674805V76ZM265.675 91H0.674805V90H265.675V91ZM0.674805 106H265.675V105H0.674805V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M4.4248 120.5L4.4248 0.5H3.4248L3.4248 120.5H4.4248ZM41.4248 120.5L41.4248 0.5H40.4248L40.4248 120.5H41.4248ZM78.4248 0.5L78.4248 120.5H77.4248L77.4248 0.5H78.4248ZM115.425 120L115.425 0H114.425L114.425 120H115.425ZM152.425 0V120H151.425V0H152.425ZM189.425 120.5V0.5H188.425V120.5H189.425ZM226.425 0.5V120.5H225.425V0.5H226.425ZM263.425 120.5V0.5H262.425V120.5H263.425Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M26.8096 84H23.7139L23.7334 81.7148H26.8096C27.5778 81.7148 28.2256 81.5423 28.7529 81.1973C29.2803 80.8457 29.6774 80.3346 29.9443 79.6641C30.2178 78.9935 30.3545 78.1829 30.3545 77.2324V76.5391C30.3545 75.8099 30.2764 75.1686 30.1201 74.6152C29.9704 74.0618 29.7458 73.5964 29.4463 73.2188C29.1468 72.8411 28.779 72.5579 28.3428 72.3691C27.9066 72.1738 27.4053 72.0762 26.8389 72.0762H23.6553V69.7812H26.8389C27.7894 69.7812 28.6585 69.944 29.4463 70.2695C30.2406 70.5885 30.9274 71.0475 31.5068 71.6465C32.0863 72.2454 32.5322 72.9616 32.8447 73.7949C33.1637 74.6217 33.3232 75.543 33.3232 76.5586V77.2324C33.3232 78.2415 33.1637 79.1628 32.8447 79.9961C32.5322 80.8294 32.0863 81.5456 31.5068 82.1445C30.9339 82.737 30.2471 83.196 29.4463 83.5215C28.652 83.8405 27.7731 84 26.8096 84ZM25.374 69.7812V84H22.4443V69.7812H25.374Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M241.687 21.2832H244.606C244.548 22.2402 244.284 23.0898 243.815 23.832C243.353 24.5742 242.705 25.1536 241.872 25.5703C241.045 25.987 240.049 26.1953 238.884 26.1953C237.972 26.1953 237.155 26.0391 236.433 25.7266C235.71 25.4076 235.091 24.9518 234.577 24.3594C234.069 23.7669 233.682 23.0508 233.415 22.2109C233.148 21.3711 233.015 20.4303 233.015 19.3887V18.4023C233.015 17.3607 233.151 16.4199 233.425 15.5801C233.705 14.7337 234.102 14.0143 234.616 13.4219C235.137 12.8294 235.759 12.3737 236.481 12.0547C237.204 11.7357 238.011 11.5762 238.903 11.5762C240.088 11.5762 241.088 11.791 241.901 12.2207C242.722 12.6504 243.356 13.2428 243.806 13.998C244.261 14.7533 244.535 15.6126 244.626 16.5762H241.696C241.664 16.0033 241.55 15.5182 241.354 15.1211C241.159 14.7174 240.863 14.4147 240.466 14.2129C240.075 14.0046 239.554 13.9004 238.903 13.9004C238.415 13.9004 237.989 13.9915 237.624 14.1738C237.259 14.3561 236.953 14.6328 236.706 15.0039C236.459 15.375 236.273 15.8438 236.149 16.4102C236.032 16.9701 235.974 17.6276 235.974 18.3828V19.3887C235.974 20.1243 236.029 20.7721 236.14 21.332C236.25 21.8854 236.42 22.3542 236.647 22.7383C236.882 23.1159 237.181 23.4023 237.546 23.5977C237.917 23.7865 238.363 23.8809 238.884 23.8809C239.496 23.8809 240 23.7832 240.397 23.5879C240.795 23.3926 241.097 23.1029 241.306 22.7188C241.521 22.3346 241.647 21.8561 241.687 21.2832Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_49592)" },
            React.createElement("path", { d: "M253.175 32H223.175V107H253.175V32Z", fill: `url(#paint0_linear_2401_49592_${renderKey})` }),
            React.createElement("path", { d: "M172.175 49H202.175V107H172.175V49Z", fill: `url(#paint1_linear_2401_49592_${renderKey})` }),
            React.createElement("path", { d: "M149.175 60H119.175V107H149.175V60Z", fill: `url(#paint2_linear_2401_49592_${renderKey})` }),
            React.createElement("path", { d: "M66.1748 80H96.1748V107H66.1748V80Z", fill: `url(#paint3_linear_2401_49592_${renderKey})` }),
            React.createElement("path", { d: "M13.1748 93H43.1748V107H13.1748V93Z", fill: `url(#paint4_linear_2401_49592_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_49592)" },
            React.createElement("path", { d: "M226.45 52.5704L230.403 58.9113L236.155 39.4425L216.244 39L220.34 44.5461C160.049 94.3091 79.6432 97.206 32.9942 97.2839C126.422 108.911 199.872 77.4951 226.45 52.5704Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_49592", x: "13.1748", y: "32", width: "248", height: "75", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.495833 0 0 0 0 0.419875 0 0 0 0 0.188003 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49592" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49592", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_49592", x: "32.9941", y: "39", width: "206.161", height: "62.7783", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49592" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49592", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_49592_${renderKey}`, x1: "146.675", y1: "-27.0426", x2: "145.402", y2: "106.993", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_49592_${renderKey}`, x1: "146.675", y1: "-27.0426", x2: "145.402", y2: "106.993", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_49592_${renderKey}`, x1: "146.675", y1: "-27.0426", x2: "145.402", y2: "106.993", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_49592_${renderKey}`, x1: "146.675", y1: "-27.0426", x2: "145.402", y2: "106.993", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_49592_${renderKey}`, x1: "146.675", y1: "-27.0426", x2: "145.402", y2: "106.993", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })))));
};
export const CtoB = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "266", height: "121", viewBox: "0 0 266 121", fill: "none" },
        React.createElement("rect", { x: "0.674805", width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265.425 16H0.674805V15H265.425V16ZM265.425 31H0.674805V30H265.425V31ZM0.674805 46H265.425V45H0.674805V46ZM265.425 61H0.674805V60H265.425V61ZM0.674805 76H265.425V75H0.674805V76ZM265.425 91H0.674805V90H265.425V91ZM0.674805 106H265.425V105H0.674805V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M4.1748 120.5L4.1748 0.5H3.1748L3.1748 120.5H4.1748ZM41.1748 120.5L41.1748 0.5H40.1748L40.1748 120.5H41.1748ZM78.1748 0.5L78.1748 120.5H77.1748L77.1748 0.5H78.1748ZM115.175 120L115.175 0H114.175L114.175 120H115.175ZM152.175 0V120H151.175V0H152.175ZM189.175 120.5V0.5H188.175V120.5H189.175ZM226.175 0.5V120.5H225.175V0.5H226.175ZM263.175 120.5V0.5H262.175V120.5H263.175Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M28.9932 79.2832H31.9131C31.8545 80.2402 31.5908 81.0898 31.1221 81.832C30.6598 82.5742 30.012 83.1536 29.1787 83.5703C28.3519 83.987 27.3558 84.1953 26.1904 84.1953C25.279 84.1953 24.4619 84.0391 23.7393 83.7266C23.0166 83.4076 22.3981 82.9518 21.8838 82.3594C21.376 81.7669 20.9886 81.0508 20.7217 80.2109C20.4548 79.3711 20.3213 78.4303 20.3213 77.3887V76.4023C20.3213 75.3607 20.458 74.4199 20.7314 73.5801C21.0114 72.7337 21.4085 72.0143 21.9229 71.4219C22.4437 70.8294 23.0654 70.3737 23.7881 70.0547C24.5107 69.7357 25.318 69.5762 26.21 69.5762C27.3949 69.5762 28.3942 69.791 29.208 70.2207C30.0283 70.6504 30.6631 71.2428 31.1123 71.998C31.568 72.7533 31.8415 73.6126 31.9326 74.5762H29.0029C28.9704 74.0033 28.8564 73.5182 28.6611 73.1211C28.4658 72.7174 28.1696 72.4147 27.7725 72.2129C27.3818 72.0046 26.861 71.9004 26.21 71.9004C25.7217 71.9004 25.2952 71.9915 24.9307 72.1738C24.5661 72.3561 24.2601 72.6328 24.0127 73.0039C23.7653 73.375 23.5798 73.8438 23.4561 74.4102C23.3389 74.9701 23.2803 75.6276 23.2803 76.3828V77.3887C23.2803 78.1243 23.3356 78.7721 23.4463 79.332C23.557 79.8854 23.7262 80.3542 23.9541 80.7383C24.1885 81.1159 24.488 81.4023 24.8525 81.5977C25.2236 81.7865 25.6696 81.8809 26.1904 81.8809C26.8024 81.8809 27.307 81.7832 27.7041 81.5879C28.1012 81.3926 28.404 81.1029 28.6123 80.7188C28.8271 80.3346 28.9541 79.8561 28.9932 79.2832Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M237.317 19.7598H233.665L233.646 17.748H236.712C237.252 17.748 237.695 17.6797 238.04 17.543C238.385 17.3997 238.642 17.1947 238.812 16.9277C238.987 16.6543 239.075 16.3223 239.075 15.9316C239.075 15.4889 238.991 15.1309 238.821 14.8574C238.659 14.584 238.401 14.3854 238.05 14.2617C237.705 14.138 237.259 14.0762 236.712 14.0762H234.681V26H231.751V11.7812H236.712C237.539 11.7812 238.278 11.8594 238.929 12.0156C239.586 12.1719 240.143 12.4095 240.599 12.7285C241.054 13.0475 241.403 13.4512 241.644 13.9395C241.884 14.4212 242.005 14.9941 242.005 15.6582C242.005 16.2441 241.871 16.7845 241.604 17.2793C241.344 17.7741 240.931 18.1777 240.364 18.4902C239.804 18.8027 239.072 18.9753 238.167 19.0078L237.317 19.7598ZM237.19 26H232.864L234.007 23.7148H237.19C237.705 23.7148 238.125 23.6335 238.45 23.4707C238.776 23.3014 239.017 23.0736 239.173 22.7871C239.329 22.5007 239.407 22.1719 239.407 21.8008C239.407 21.3841 239.336 21.0228 239.192 20.7168C239.056 20.4108 238.834 20.1764 238.528 20.0137C238.222 19.8444 237.819 19.7598 237.317 19.7598H234.495L234.515 17.748H238.03L238.704 18.5391C239.57 18.526 240.267 18.679 240.794 18.998C241.328 19.3105 241.715 19.7174 241.956 20.2188C242.203 20.7201 242.327 21.2572 242.327 21.8301C242.327 22.7415 242.129 23.5098 241.731 24.1348C241.334 24.7533 240.752 25.2188 239.983 25.5312C239.222 25.8438 238.291 26 237.19 26Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_49591)" },
            React.createElement("path", { d: "M251.481 32H221.481V107H251.481V32Z", fill: `url(#paint0_linear_2401_49591_${renderKey})` }),
            React.createElement("path", { d: "M170.481 49H200.481V107H170.481V49Z", fill: `url(#paint1_linear_2401_49591_${renderKey})` }),
            React.createElement("path", { d: "M147.481 60H117.481V107H147.481V60Z", fill: `url(#paint2_linear_2401_49591_${renderKey})` }),
            React.createElement("path", { d: "M64.4814 80H94.4814V107H64.4814V80Z", fill: `url(#paint3_linear_2401_49591_${renderKey})` }),
            React.createElement("path", { d: "M11.4814 93H41.4814V107H11.4814V93Z", fill: `url(#paint4_linear_2401_49591_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_49591)" },
            React.createElement("path", { d: "M224.757 52.5704L228.709 58.9113L234.461 39.4425L214.55 39L218.647 44.5461C158.356 94.3091 77.9498 97.206 31.3009 97.2839C124.728 108.911 198.179 77.4951 224.757 52.5704Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_49591", x: "11.4814", y: "32", width: "248", height: "75", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.0875 0 0 0 0 0.35 0 0 0 0 0.206818 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49591" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49591", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_49591", x: "31.3008", y: "39", width: "206.161", height: "62.7783", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49591" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49591", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_49591_${renderKey}`, x1: "144.981", y1: "23", x2: "144.481", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_49591_${renderKey}`, x1: "144.981", y1: "23", x2: "144.481", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_49591_${renderKey}`, x1: "144.981", y1: "23", x2: "144.481", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_49591_${renderKey}`, x1: "144.981", y1: "23", x2: "144.481", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_49591_${renderKey}`, x1: "144.981", y1: "23", x2: "144.481", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })))));
};
export const BtoA = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "266", height: "121", viewBox: "0 0 266 121", fill: "none" },
        React.createElement("rect", { x: "0.25", width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265 16H0V15H265V16ZM265 31H0V30H265V31ZM0 46H265V45H0V46ZM265 61H0V60H265V61ZM0 76H265V75H0V76ZM265 91H0V90H265V91ZM0 106H265V105H0V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M3.75 120.5L3.75 0.5H2.75L2.75 120.5H3.75ZM40.75 120.5L40.75 0.5H39.75L39.75 120.5H40.75ZM77.75 0.5L77.75 120.5H76.75L76.75 0.5H77.75ZM114.75 120L114.75 0H113.75L113.75 120H114.75ZM151.75 0V120H150.75V0H151.75ZM188.75 120.5V0.5H187.75V120.5H188.75ZM225.75 0.5V120.5H224.75V0.5H225.75ZM262.75 120.5V0.5H261.75V120.5H262.75Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M27.3359 77.7598H23.6836L23.6641 75.748H26.7305C27.2708 75.748 27.7135 75.6797 28.0586 75.543C28.4036 75.3997 28.6608 75.1947 28.8301 74.9277C29.0059 74.6543 29.0938 74.3223 29.0938 73.9316C29.0938 73.4889 29.0091 73.1309 28.8398 72.8574C28.6771 72.584 28.4199 72.3854 28.0684 72.2617C27.7233 72.138 27.2773 72.0762 26.7305 72.0762H24.6992V84H21.7695V69.7812H26.7305C27.5573 69.7812 28.2962 69.8594 28.9473 70.0156C29.6048 70.1719 30.1615 70.4095 30.6172 70.7285C31.0729 71.0475 31.4212 71.4512 31.6621 71.9395C31.903 72.4212 32.0234 72.9941 32.0234 73.6582C32.0234 74.2441 31.89 74.7845 31.623 75.2793C31.3626 75.7741 30.9492 76.1777 30.3828 76.4902C29.8229 76.8027 29.0905 76.9753 28.1855 77.0078L27.3359 77.7598ZM27.209 84H22.8828L24.0254 81.7148H27.209C27.7233 81.7148 28.1432 81.6335 28.4688 81.4707C28.7943 81.3014 29.0352 81.0736 29.1914 80.7871C29.3477 80.5007 29.4258 80.1719 29.4258 79.8008C29.4258 79.3841 29.3542 79.0228 29.2109 78.7168C29.0742 78.4108 28.8529 78.1764 28.5469 78.0137C28.2409 77.8444 27.8372 77.7598 27.3359 77.7598H24.5137L24.5332 75.748H28.0488L28.7227 76.5391C29.5885 76.526 30.2852 76.679 30.8125 76.998C31.3464 77.3105 31.7337 77.7174 31.9746 78.2188C32.222 78.7201 32.3457 79.2572 32.3457 79.8301C32.3457 80.7415 32.1471 81.5098 31.75 82.1348C31.3529 82.7533 30.7702 83.2188 30.002 83.5312C29.2402 83.8438 28.3092 84 27.209 84Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M238.551 14.2129L234.684 26H231.568L236.852 11.7812H238.834L238.551 14.2129ZM241.764 26L237.887 14.2129L237.574 11.7812H239.576L244.889 26H241.764ZM241.588 20.707V23.002H234.078V20.707H241.588Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_47844)" },
            React.createElement("path", { d: "M252.5 32H222.5V107H252.5V32Z", fill: `url(#paint0_linear_2401_47844_${renderKey})` }),
            React.createElement("path", { d: "M171.5 49H201.5V107H171.5V49Z", fill: `url(#paint1_linear_2401_47844_${renderKey})` }),
            React.createElement("path", { d: "M148.5 60H118.5V107H148.5V60Z", fill: `url(#paint2_linear_2401_47844_${renderKey})` }),
            React.createElement("path", { d: "M65.5 80H95.5V107H65.5V80Z", fill: `url(#paint3_linear_2401_47844_${renderKey})` }),
            React.createElement("path", { d: "M12.5 93H42.5V107H12.5V93Z", fill: `url(#paint4_linear_2401_47844_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_47844)" },
            React.createElement("path", { d: "M225.775 52.5704L229.728 58.9113L235.48 39.4425L215.569 39L219.666 44.5461C159.375 94.3091 78.9684 97.206 32.3194 97.2839C125.747 108.911 199.197 77.4951 225.775 52.5704Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_47844", x: "12.5", y: "32", width: "248", height: "75", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.0875 0 0 0 0 0.35 0 0 0 0 0.206818 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_47844" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_47844", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_47844", x: "31.5", y: "39", width: "206.98", height: "62.7783", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_47844" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_47844", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_47844_${renderKey}`, x1: "146", y1: "23", x2: "145.5", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_47844_${renderKey}`, x1: "146", y1: "23", x2: "145.5", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_47844_${renderKey}`, x1: "146", y1: "23", x2: "145.5", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_47844_${renderKey}`, x1: "146", y1: "23", x2: "145.5", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_47844_${renderKey}`, x1: "146", y1: "23", x2: "145.5", y2: "107", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })))));
};
export const AtoA = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "267", height: "120", viewBox: "0 0 267 120", fill: "none" },
        React.createElement("rect", { x: "1.32227", width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M266.322 16H0.908203V15H266.322V16ZM266.322 31H0.908203V30H266.322V31ZM0.908203 46H266.322V45H0.908203V46ZM266.322 61H0.908203V60H266.322V61ZM0.908203 76H266.322V75H0.908203V76ZM266.322 91H0.908203V90H266.322V91ZM0.908203 106H266.322V105H0.908203V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M4.49316 120L4.49316 0.497696H3.49316L3.49316 120H4.49316ZM41.5871 120L41.5871 0.497696H40.5871L40.5871 120H41.5871ZM78.6807 0.497925L78.6807 120H77.6807L77.6807 0.497925H78.6807ZM115.774 119.502L115.774 -0.000350952H114.774L114.774 119.502H115.774ZM152.869 -0.000350952V119.502H151.869V-0.000350952H152.869ZM189.963 120V0.497696H188.963V120H189.963ZM227.057 0.497696V120H226.057V0.497696H227.057ZM264.151 120V0.497696H263.151V120H264.151Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M28.4521 20.041L23.7451 33H21.8213L27.2412 18.7812H28.4814L28.4521 20.041ZM32.3975 33L27.6807 20.041L27.6514 18.7812H28.8916L34.3311 33H32.3975ZM32.1533 27.7363V29.2793H24.165V27.7363H32.1533Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M243.998 20.041L239.291 33H237.367L242.787 18.7812H244.027L243.998 20.041ZM247.943 33L243.227 20.041L243.197 18.7812H244.438L249.877 33H247.943ZM247.699 27.7363V29.2793H239.711V27.7363H247.699Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_49593)" },
            React.createElement("path", { d: "M254.127 45H224.051V107H254.127V45Z", fill: `url(#paint0_linear_2401_49593_${renderKey})` }),
            React.createElement("path", { d: "M172.921 45H202.997V107H172.921V45Z", fill: `url(#paint1_linear_2401_49593_${renderKey})` }),
            React.createElement("path", { d: "M149.863 45H119.787V107H149.863V45Z", fill: `url(#paint2_linear_2401_49593_${renderKey})` }),
            React.createElement("path", { d: "M66.6521 45H96.7282V107H66.6521V45Z", fill: `url(#paint3_linear_2401_49593_${renderKey})` }),
            React.createElement("path", { d: "M13.5176 45H43.5937V107H13.5176V45Z", fill: `url(#paint4_linear_2401_49593_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_49593)" },
            React.createElement("path", { d: "M233.388 62.7705V69.3281L22.3271 76.5597L233.388 79.0334V86.6404L248.209 74.8365L233.388 62.7705Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_49593", x: "13.5176", y: "45", width: "248.609", height: "62", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.0875 0 0 0 0 0.35 0 0 0 0 0.206818 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49593" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49593", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_49593", x: "22.3271", y: "62.7705", width: "228.882", height: "25.8696", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49593" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49593", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_49593_${renderKey}`, x1: "147.356", y1: "37.56", x2: "147.016", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_49593_${renderKey}`, x1: "147.356", y1: "37.56", x2: "147.016", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_49593_${renderKey}`, x1: "147.356", y1: "37.56", x2: "147.016", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_49593_${renderKey}`, x1: "147.356", y1: "37.56", x2: "147.016", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_49593_${renderKey}`, x1: "147.356", y1: "37.56", x2: "147.016", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#1BE075" }),
                React.createElement("stop", { offset: "1", "stop-color": "#00B050" })))));
};
export const BtoB = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "266", height: "121", viewBox: "0 0 266 121", fill: "none" },
        React.createElement("rect", { x: "0.191406", width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265.186 16H0.162109V15H265.186V16ZM265.186 31H0.162109V30H265.186V31ZM0.162109 46H265.186V45H0.162109V46ZM265.186 61H0.162109V60H265.186V61ZM0.162109 76H265.186V75H0.162109V76ZM265.186 91H0.162109V90H265.186V91ZM0.162109 106H265.186V105H0.162109V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M3.3623 120.5L3.3623 0.5H2.3623L2.3623 120.5H3.3623ZM40.4562 120.5L40.4562 0.5H39.4562L39.4562 120.5H40.4562ZM77.5501 0.5L77.5501 120.5H76.5501L76.5501 0.5H77.5501ZM114.644 120L114.644 0H113.644L113.644 120H114.644ZM151.738 0V120H150.738V0H151.738ZM188.832 120.5V0.5H187.832V120.5H188.832ZM225.926 0.5V120.5H224.926V0.5H225.926ZM263.02 120.5V0.5H262.02V120.5H263.02Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M24.2432 25.7598H20.5908L20.5713 23.748H23.6377C24.1781 23.748 24.6208 23.6797 24.9658 23.543C25.3109 23.3997 25.568 23.1947 25.7373 22.9277C25.9131 22.6543 26.001 22.3223 26.001 21.9316C26.001 21.4889 25.9163 21.1309 25.7471 20.8574C25.5843 20.584 25.3271 20.3854 24.9756 20.2617C24.6305 20.138 24.1846 20.0762 23.6377 20.0762H21.6064V32H18.6768V17.7812H23.6377C24.4645 17.7812 25.2035 17.8594 25.8545 18.0156C26.512 18.1719 27.0687 18.4095 27.5244 18.7285C27.9801 19.0475 28.3285 19.4512 28.5693 19.9395C28.8102 20.4212 28.9307 20.9941 28.9307 21.6582C28.9307 22.2441 28.7972 22.7845 28.5303 23.2793C28.2699 23.7741 27.8564 24.1777 27.29 24.4902C26.7301 24.8027 25.9977 24.9753 25.0928 25.0078L24.2432 25.7598ZM24.1162 32H19.79L20.9326 29.7148H24.1162C24.6305 29.7148 25.0505 29.6335 25.376 29.4707C25.7015 29.3014 25.9424 29.0736 26.0986 28.7871C26.2549 28.5007 26.333 28.1719 26.333 27.8008C26.333 27.3841 26.2614 27.0228 26.1182 26.7168C25.9814 26.4108 25.7601 26.1764 25.4541 26.0137C25.1481 25.8444 24.7445 25.7598 24.2432 25.7598H21.4209L21.4404 23.748H24.9561L25.6299 24.5391C26.4958 24.526 27.1924 24.679 27.7197 24.998C28.2536 25.3105 28.641 25.7174 28.8818 26.2188C29.1292 26.7201 29.2529 27.2572 29.2529 27.8301C29.2529 28.7415 29.0544 29.5098 28.6572 30.1348C28.2601 30.7533 27.6774 31.2188 26.9092 31.5312C26.1475 31.8438 25.2165 32 24.1162 32Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M239.789 25.7598H236.137L236.117 23.748H239.184C239.724 23.748 240.167 23.6797 240.512 23.543C240.857 23.3997 241.114 23.1947 241.283 22.9277C241.459 22.6543 241.547 22.3223 241.547 21.9316C241.547 21.4889 241.462 21.1309 241.293 20.8574C241.13 20.584 240.873 20.3854 240.521 20.2617C240.176 20.138 239.73 20.0762 239.184 20.0762H237.152V32H234.223V17.7812H239.184C240.01 17.7812 240.749 17.8594 241.4 18.0156C242.058 18.1719 242.615 18.4095 243.07 18.7285C243.526 19.0475 243.874 19.4512 244.115 19.9395C244.356 20.4212 244.477 20.9941 244.477 21.6582C244.477 22.2441 244.343 22.7845 244.076 23.2793C243.816 23.7741 243.402 24.1777 242.836 24.4902C242.276 24.8027 241.544 24.9753 240.639 25.0078L239.789 25.7598ZM239.662 32H235.336L236.479 29.7148H239.662C240.176 29.7148 240.596 29.6335 240.922 29.4707C241.247 29.3014 241.488 29.0736 241.645 28.7871C241.801 28.5007 241.879 28.1719 241.879 27.8008C241.879 27.3841 241.807 27.0228 241.664 26.7168C241.527 26.4108 241.306 26.1764 241 26.0137C240.694 25.8444 240.29 25.7598 239.789 25.7598H236.967L236.986 23.748H240.502L241.176 24.5391C242.042 24.526 242.738 24.679 243.266 24.998C243.799 25.3105 244.187 25.7174 244.428 26.2188C244.675 26.7201 244.799 27.2572 244.799 27.8301C244.799 28.7415 244.6 29.5098 244.203 30.1348C243.806 30.7533 243.223 31.2188 242.455 31.5312C241.693 31.8438 240.762 32 239.662 32Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_49960)" },
            React.createElement("path", { d: "M249.996 44H219.92V106H249.996V44Z", fill: `url(#paint0_linear_2401_49960_${renderKey})` }),
            React.createElement("path", { d: "M168.79 44H198.866V106H168.79V44Z", fill: `url(#paint1_linear_2401_49960_${renderKey})` }),
            React.createElement("path", { d: "M145.732 44H115.656V106H145.732V44Z", fill: `url(#paint2_linear_2401_49960_${renderKey})` }),
            React.createElement("path", { d: "M62.5212 44H92.5974V106H62.5212V44Z", fill: `url(#paint3_linear_2401_49960_${renderKey})` }),
            React.createElement("path", { d: "M9.38672 44H39.4629V106H9.38672V44Z", fill: `url(#paint4_linear_2401_49960_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_49960)" },
            React.createElement("path", { d: "M229.258 61.7705V68.3281L18.1963 75.5597L229.258 78.0334V85.6404L244.078 73.8365L229.258 61.7705Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_49960", x: "9.38672", y: "44", width: "248.609", height: "62", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.0875 0 0 0 0 0.35 0 0 0 0 0.206818 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49960" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49960", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_49960", x: "18.1963", y: "61.7705", width: "228.882", height: "25.8696", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49960" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49960", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_49960_${renderKey}`, x1: "143.226", y1: "36.56", x2: "142.885", y2: "106.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_49960_${renderKey}`, x1: "143.226", y1: "36.56", x2: "142.885", y2: "106.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_49960_${renderKey}`, x1: "143.226", y1: "36.56", x2: "142.885", y2: "106.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_49960_${renderKey}`, x1: "143.226", y1: "36.56", x2: "142.885", y2: "106.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_49960_${renderKey}`, x1: "143.226", y1: "36.56", x2: "142.885", y2: "106.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#BCF77E" }),
                React.createElement("stop", { offset: "1", "stop-color": "#92D050" })))));
};
export const CtoC = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "266", height: "121", viewBox: "0 0 266 121", fill: "none" },
        React.createElement("rect", { x: "0.587891", width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265.562 16H0.720703V15H265.562V16ZM265.562 31H0.720703V30H265.562V31ZM0.720703 46H265.562V45H0.720703V46ZM265.562 61H0.720703V60H265.562V61ZM0.720703 76H265.562V75H0.720703V76ZM265.562 91H0.720703V90H265.562V91ZM0.720703 106H265.562V105H0.720703V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M3.75879 120.5L3.75879 0.5H2.75879L2.75879 120.5H3.75879ZM40.8527 120.5L40.8527 0.5H39.8527L39.8527 120.5H40.8527ZM77.9466 0.5L77.9466 120.5H76.9466L76.9466 0.5H77.9466ZM115.041 120L115.041 0H114.041L114.041 120H115.041ZM152.134 0V120H151.134V0H152.134ZM189.228 120.5V0.5H188.228V120.5H189.228ZM226.322 0.5V120.5H225.322V0.5H226.322ZM263.416 120.5V0.5H262.416V120.5H263.416Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M28.1846 28.2832H31.1045C31.0459 29.2402 30.7822 30.0898 30.3135 30.832C29.8512 31.5742 29.2035 32.1536 28.3701 32.5703C27.5433 32.987 26.5472 33.1953 25.3818 33.1953C24.4704 33.1953 23.6533 33.0391 22.9307 32.7266C22.208 32.4076 21.5895 31.9518 21.0752 31.3594C20.5674 30.7669 20.18 30.0508 19.9131 29.2109C19.6462 28.3711 19.5127 27.4303 19.5127 26.3887V25.4023C19.5127 24.3607 19.6494 23.4199 19.9229 22.5801C20.2028 21.7337 20.5999 21.0143 21.1143 20.4219C21.6351 19.8294 22.2568 19.3737 22.9795 19.0547C23.7021 18.7357 24.5094 18.5762 25.4014 18.5762C26.5863 18.5762 27.5856 18.791 28.3994 19.2207C29.2197 19.6504 29.8545 20.2428 30.3037 20.998C30.7594 21.7533 31.0329 22.6126 31.124 23.5762H28.1943C28.1618 23.0033 28.0479 22.5182 27.8525 22.1211C27.6572 21.7174 27.361 21.4147 26.9639 21.2129C26.5732 21.0046 26.0524 20.9004 25.4014 20.9004C24.9131 20.9004 24.4867 20.9915 24.1221 21.1738C23.7575 21.3561 23.4515 21.6328 23.2041 22.0039C22.9567 22.375 22.7712 22.8438 22.6475 23.4102C22.5303 23.9701 22.4717 24.6276 22.4717 25.3828V26.3887C22.4717 27.1243 22.527 27.7721 22.6377 28.332C22.7484 28.8854 22.9176 29.3542 23.1455 29.7383C23.3799 30.1159 23.6794 30.4023 24.0439 30.5977C24.415 30.7865 24.861 30.8809 25.3818 30.8809C25.9938 30.8809 26.4984 30.7832 26.8955 30.5879C27.2926 30.3926 27.5954 30.1029 27.8037 29.7188C28.0186 29.3346 28.1455 28.8561 28.1846 28.2832Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M243.73 28.2832H246.65C246.592 29.2402 246.328 30.0898 245.859 30.832C245.397 31.5742 244.749 32.1536 243.916 32.5703C243.089 32.987 242.093 33.1953 240.928 33.1953C240.016 33.1953 239.199 33.0391 238.477 32.7266C237.754 32.4076 237.135 31.9518 236.621 31.3594C236.113 30.7669 235.726 30.0508 235.459 29.2109C235.192 28.3711 235.059 27.4303 235.059 26.3887V25.4023C235.059 24.3607 235.195 23.4199 235.469 22.5801C235.749 21.7337 236.146 21.0143 236.66 20.4219C237.181 19.8294 237.803 19.3737 238.525 19.0547C239.248 18.7357 240.055 18.5762 240.947 18.5762C242.132 18.5762 243.132 18.791 243.945 19.2207C244.766 19.6504 245.4 20.2428 245.85 20.998C246.305 21.7533 246.579 22.6126 246.67 23.5762H243.74C243.708 23.0033 243.594 22.5182 243.398 22.1211C243.203 21.7174 242.907 21.4147 242.51 21.2129C242.119 21.0046 241.598 20.9004 240.947 20.9004C240.459 20.9004 240.033 20.9915 239.668 21.1738C239.303 21.3561 238.997 21.6328 238.75 22.0039C238.503 22.375 238.317 22.8438 238.193 23.4102C238.076 23.9701 238.018 24.6276 238.018 25.3828V26.3887C238.018 27.1243 238.073 27.7721 238.184 28.332C238.294 28.8854 238.464 29.3542 238.691 29.7383C238.926 30.1159 239.225 30.4023 239.59 30.5977C239.961 30.7865 240.407 30.8809 240.928 30.8809C241.54 30.8809 242.044 30.7832 242.441 30.5879C242.839 30.3926 243.141 30.1029 243.35 29.7188C243.564 29.3346 243.691 28.8561 243.73 28.2832Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_49963)" },
            React.createElement("path", { d: "M251.261 45H221.185V107H251.261V45Z", fill: `url(#paint0_linear_2401_49963_${renderKey})` }),
            React.createElement("path", { d: "M170.056 45H200.132V107H170.056V45Z", fill: `url(#paint1_linear_2401_49963_${renderKey})` }),
            React.createElement("path", { d: "M146.998 45H116.921V107H146.998V45Z", fill: `url(#paint2_linear_2401_49963_${renderKey})` }),
            React.createElement("path", { d: "M63.7869 45H93.863V107H63.7869V45Z", fill: `url(#paint3_linear_2401_49963_${renderKey})` }),
            React.createElement("path", { d: "M10.6523 45H40.7285V107H10.6523V45Z", fill: `url(#paint4_linear_2401_49963_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_49963)" },
            React.createElement("path", { d: "M230.523 62.7705V69.3281L19.4619 76.5597L230.523 79.0334V86.6404L245.344 74.8365L230.523 62.7705Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_49963", x: "10.6523", y: "45", width: "248.609", height: "62", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.495833 0 0 0 0 0.419875 0 0 0 0 0.188003 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49963" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49963", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_49963", x: "19.4619", y: "62.7705", width: "228.882", height: "25.8696", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49963" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49963", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_49963_${renderKey}`, x1: "144.491", y1: "-3.80851", x2: "143.623", y2: "106.997", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_49963_${renderKey}`, x1: "144.491", y1: "-3.80851", x2: "143.623", y2: "106.997", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_49963_${renderKey}`, x1: "144.491", y1: "-3.80851", x2: "143.623", y2: "106.997", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_49963_${renderKey}`, x1: "144.491", y1: "-3.80851", x2: "143.623", y2: "106.997", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_49963_${renderKey}`, x1: "144.491", y1: "-3.80851", x2: "143.623", y2: "106.997", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FFE595" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FFC000" })))));
};
export const DtoD = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "266", height: "121", viewBox: "0 0 266 121", fill: "none" },
        React.createElement("rect", { x: "0.173828", width: "265", height: "120", fill: "#2B3B4A" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M265.174 16H0.173828V15H265.174V16ZM265.174 31H0.173828V30H265.174V31ZM0.173828 46H265.174V45H0.173828V46ZM265.174 61H0.173828V60H265.174V61ZM0.173828 76H265.174V75H0.173828V76ZM265.174 91H0.173828V90H265.174V91ZM0.173828 106H265.174V105H0.173828V106Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M3.34473 120.5L3.34473 0.5H2.34473L2.34473 120.5H3.34473ZM40.4386 120.5L40.4386 0.5H39.4386L39.4386 120.5H40.4386ZM77.5325 0.5L77.5325 120.5H76.5325L76.5325 0.5H77.5325ZM114.626 120L114.626 0H113.626L113.626 120H114.626ZM151.72 0V120H150.72V0H151.72ZM188.814 120.5V0.5H187.814V120.5H188.814ZM225.908 0.5V120.5H224.908V0.5H225.908ZM263.002 120.5V0.5H262.002V120.5H263.002Z", fill: "white", "fill-opacity": "0.1" }),
        React.createElement("path", { id: "text", d: "M23.0146 33H19.9189L19.9385 30.7148H23.0146C23.7829 30.7148 24.4307 30.5423 24.958 30.1973C25.4854 29.8457 25.8825 29.3346 26.1494 28.6641C26.4229 27.9935 26.5596 27.1829 26.5596 26.2324V25.5391C26.5596 24.8099 26.4814 24.1686 26.3252 23.6152C26.1755 23.0618 25.9508 22.5964 25.6514 22.2188C25.3519 21.8411 24.984 21.5579 24.5479 21.3691C24.1117 21.1738 23.6104 21.0762 23.0439 21.0762H19.8604V18.7812H23.0439C23.9945 18.7812 24.8636 18.944 25.6514 19.2695C26.4456 19.5885 27.1325 20.0475 27.7119 20.6465C28.2913 21.2454 28.7373 21.9616 29.0498 22.7949C29.3688 23.6217 29.5283 24.543 29.5283 25.5586V26.2324C29.5283 27.2415 29.3688 28.1628 29.0498 28.9961C28.7373 29.8294 28.2913 30.5456 27.7119 31.1445C27.139 31.737 26.4521 32.196 25.6514 32.5215C24.8571 32.8405 23.9782 33 23.0146 33ZM21.5791 18.7812V33H18.6494V18.7812H21.5791Z", fill: "white" }),
        React.createElement("path", { id: "text", d: "M238.561 33H235.465L235.484 30.7148H238.561C239.329 30.7148 239.977 30.5423 240.504 30.1973C241.031 29.8457 241.428 29.3346 241.695 28.6641C241.969 27.9935 242.105 27.1829 242.105 26.2324V25.5391C242.105 24.8099 242.027 24.1686 241.871 23.6152C241.721 23.0618 241.497 22.5964 241.197 22.2188C240.898 21.8411 240.53 21.5579 240.094 21.3691C239.658 21.1738 239.156 21.0762 238.59 21.0762H235.406V18.7812H238.59C239.54 18.7812 240.41 18.944 241.197 19.2695C241.992 19.5885 242.678 20.0475 243.258 20.6465C243.837 21.2454 244.283 21.9616 244.596 22.7949C244.915 23.6217 245.074 24.543 245.074 25.5586V26.2324C245.074 27.2415 244.915 28.1628 244.596 28.9961C244.283 29.8294 243.837 30.5456 243.258 31.1445C242.685 31.737 241.998 32.196 241.197 32.5215C240.403 32.8405 239.524 33 238.561 33ZM237.125 18.7812V33H234.195V18.7812H237.125Z", fill: "white" }),
        React.createElement("g", { filter: "url(#filter0_d_2401_49962)" },
            React.createElement("path", { d: "M249.969 45H219.892V107H249.969V45Z", fill: `url(#paint0_linear_2401_49962_${renderKey})` }),
            React.createElement("path", { d: "M168.763 45H198.839V107H168.763V45Z", fill: `url(#paint1_linear_2401_49962_${renderKey})` }),
            React.createElement("path", { d: "M145.705 45H115.628V107H145.705V45Z", fill: `url(#paint2_linear_2401_49962_${renderKey})` }),
            React.createElement("path", { d: "M62.4939 45H92.57V107H62.4939V45Z", fill: `url(#paint3_linear_2401_49962_${renderKey})` }),
            React.createElement("path", { d: "M9.35938 45H39.4355V107H9.35938V45Z", fill: `url(#paint4_linear_2401_49962_${renderKey})` })),
        React.createElement("g", { filter: "url(#filter1_d_2401_49962)" },
            React.createElement("path", { d: "M229.23 62.7705V69.3281L18.1689 76.5597L229.23 79.0334V86.6404L244.051 74.8365L229.23 62.7705Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_2401_49962", x: "9.35938", y: "45", width: "248.609", height: "62", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "8" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.329167 0 0 0 0 0.137153 0 0 0 0 0.137153 0 0 0 1 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49962" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49962", result: "shape" })),
            React.createElement("filter", { id: "filter1_d_2401_49962", x: "18.1689", y: "62.7705", width: "228.882", height: "25.8696", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dx: "3", dy: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2401_49962" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_2401_49962", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_2401_49962_${renderKey}`, x1: "143.198", y1: "37.56", x2: "142.857", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })),
            React.createElement("linearGradient", { id: `paint1_linear_2401_49962_${renderKey}`, x1: "143.198", y1: "37.56", x2: "142.857", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })),
            React.createElement("linearGradient", { id: `paint2_linear_2401_49962_${renderKey}`, x1: "143.198", y1: "37.56", x2: "142.857", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })),
            React.createElement("linearGradient", { id: `paint3_linear_2401_49962_${renderKey}`, x1: "143.198", y1: "37.56", x2: "142.857", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })),
            React.createElement("linearGradient", { id: `paint4_linear_2401_49962_${renderKey}`, x1: "143.198", y1: "37.56", x2: "142.857", y2: "107.001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#FF7474" }),
                React.createElement("stop", { offset: "1", "stop-color": "#FF0000" })))));
};
