var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { UserResponse } from "../../../../../../core/contracts/user/userContracts";
import CustomTable from "../../../../../../layout/components/customTable";
import CustomTextHighlighter from "../../../../../../layout/components/customTextHighlighter";
import UseCustomPagination from "../../../../../../layout/components/hooks/useCustomPagination";
export const PendingUserListingTable = ({ data, actions, isPrintMode = false, }) => {
    var _a;
    const _b = UseCustomPagination({
        totalRecords: (_a = data === null || data === void 0 ? void 0 : data.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange"]);
    const columns = [
        {
            fieldName: "fullName",
            title: ["name"],
            sortable: true,
            // searchable: true,
            defaultSortOrder: "ascend",
            width: 160,
            className: "bold-text",
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "emailAddress",
            title: ["User.notificationEmailAddress"],
            sortable: true,
            width: 170,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "professionName",
            title: ["User.profession"],
            sortable: true,
            width: 150,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "statusText",
            title: ["User.status"],
            sortable: true,
            width: 150,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "companyName",
            title: ["User.groupName"],
            sortable: true,
            width: 90,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "siteName",
            title: ["UserRole.site", "name"],
            // render: (externalUserSiteResponseDTOs) =>
            //   externalUserSiteResponseDTOs?.length > 0
            //     ? externalUserSiteResponseDTOs[0].companyName
            //     : "",
            width: 90,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "userRole",
            title: ["UserRole.role", "name"],
            // render: (externalUserSiteResponseDTOs) =>
            //   externalUserSiteResponseDTOs?.length > 0
            //     ? externalUserSiteResponseDTOs[0].roleName
            //     : "",
            width: 90,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "startDateTime",
            title: ["UserRole.startDateAndTime"],
            // render: (externalUserSiteResponseDTOs) =>
            //   getLocalDateString(externalUserSiteResponseDTOs[0]?.startDate),
            width: 90,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "endDateTime",
            title: ["UserRole.endDateAndTime"],
            // render: (externalUserSiteResponseDTOs) =>
            //   getLocalDateString(externalUserSiteResponseDTOs[0]?.endDate),
            width: 90,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "expiryDate",
            title: ["UserRole.expiryDate"],
            sortable: true,
            // render: (expiryDate) => {
            //   return expiryDate === null || expiryDate === undefined
            //     ? ""
            //     : new Date(expiryDate).toLocaleDateString();
            // },
            width: 90,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        render: actions,
        width: 90,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "pendingUsers-listing-table" },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: UserResponse, itemEnableKey: "status", currentPage: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.currentPage, pageSize: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.pageSize, totalCount: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.totalRecords, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps })));
};
