import React from "react";
import { AccountApi } from "../../../core/api/accountApi";
import ResetPasswordForm from "./components/resetPasswordForm";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadContext } from "./../../../redux/context/actions";
import { UserDashboardRoute } from "../../../core/utils/userDashboardRoute";
import { setContextCompany } from "../../../redux/context/actions";
import { SelectContextAndNavigation, } from "../../../core/utils/UserContextSelection";
import { ResponseStatusCodes } from "../../../core/constants/responseStatusCodes";
import localize from "../../../core/utils/translation-files";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../core/constants/SessionVariables";
import { getGroupOptions } from "../../../redux/groupOption/actions";
import { DASHBOARD_VIEWER } from "../../../core/constants/permissionConstants";
const ResetPasswordAfterFPassword = ({ loadContext, setContextCompany, getGroupOptions, groupOptions }) => {
    const history = useHistory();
    const clearLocalStorage = () => {
        SessionStorage.removeKey(SessionVariables.OLD_PASSWORD);
        SessionStorage.removeKey(SessionVariables.USERNAME);
    };
    const handleChangePassword = (data, setErrors) => {
        data.oldpassword = SessionStorage.getKey(SessionVariables.OLD_PASSWORD);
        AccountApi.changePassword(data).then((response) => {
            if (response.code === ResponseStatusCodes.OK) {
                clearLocalStorage();
                loadContext().then((response) => {
                    var _a;
                    const userPermissions = (_a = response === null || response === void 0 ? void 0 : response.companyPermissionDTOs) === null || _a === void 0 ? void 0 : _a.map(({ permission }) => permission);
                    SelectContextAndNavigation(response, history, groupOptions, getGroupOptions, setContextCompany);
                    if (!userPermissions.includes(DASHBOARD_VIEWER)) {
                        const path = UserDashboardRoute.getRouteByPermissions(response);
                        if (path) {
                            history.push(path);
                        }
                    }
                });
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ResetPasswordForm, { modalTitle: localize(["Reset.reset", "password"]), modalDescription: localize([
                "Reset.currentPasswordOnlyValidForOneTimeLogin",
            ]), handleChangePassword: handleChangePassword, hideOldPassword: true })));
};
// to map state to components props
function mapStateTopProps(state, ownProps) {
    return {
        groupOptions: state.groupOptions,
    };
}
// to map components actions to props with dispatcher
const mapDispatchToProps = {
    loadContext,
    getGroupOptions,
    setContextCompany,
};
ResetPasswordAfterFPassword.propTypes = {
    getGroupOptions: PropTypes.func.isRequired,
    setContextCompany: PropTypes.func.isRequired,
    loadContext: PropTypes.func.isRequired,
    groupOptions: PropTypes.array.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(ResetPasswordAfterFPassword);
