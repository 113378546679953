import { BaseEntity } from "../baseEntity";
export class TrackedItemRequest {
}
export class TrackedItemResponse {
}
export class NotificationInstances {
}
export class BenchmarkListRequest extends BaseEntity {
}
export class RelationsInstancesRequest {
}
export class TrackedItemTypeRequest extends BaseEntity {
}
export class TrackedItemTypeResponse extends TrackedItemTypeRequest {
}
export class TrackedItemScale {
}
