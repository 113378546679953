export function isUniqueTrackedItemName(value, TrackedItems, parentGroupId, editedTrackedItem) {
    if (!value || value === "") {
        return true;
    }
    if (!TrackedItems || TrackedItems.length === 0) {
        return true;
    }
    if (editedTrackedItem && editedTrackedItem.name !== "") {
        if (TrackedItems.filter((item) => item.name.toLocaleLowerCase() === value.toLocaleLowerCase() &&
            item.parentGroupId === parentGroupId &&
            editedTrackedItem.id !== item.id).length > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        // In Case of Adding the User
        if (TrackedItems.filter((item) => item.name.toLocaleLowerCase() === value.toLocaleLowerCase() &&
            item.parentGroupId === parentGroupId).length > 0) {
            return false;
        }
        else {
            return true;
        }
    }
}
