import React from "react";
import { Pagination } from "antd";
const CustomPagination = ({ currentPage, pageSize, totalRecords, handleChangePage, }) => {
    if (!totalRecords)
        return React.createElement(React.Fragment, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(Pagination, { total: totalRecords, current: currentPage, pageSize: pageSize, onChange: handleChangePage }),
        React.createElement("div", { className: "pagination_info" },
            React.createElement("span", null, `Record Count - ${totalRecords}`))));
};
export default CustomPagination;
