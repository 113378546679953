export const defaultDate = new Date(1999, 12, 1);
export const defaultDateFormat = "DD-MM-YYYY";
export const defaultTimeFormat = "HH:mm";
export const defaultDateTimeFormat = "DD-MM-YYYY HH:mm";
export const defaultDateTimeFormatForFile = "DD-MM-YYYY HH-mm";
export const defaultFullDateTimeFormat = "DD MMM YYYY hh:mm A";
export const defaultDateFormatForTinyChart = "DD-MMM-YYYY";
export const defaultDateFormatFinancialData = "MMM-YY";
export const defaultDateFormatForMessages = "MMMM DD, YYYY hh:mm A";
export const defaultFullDateTimeFormatForNotification = "DD MMM YYYY hh:mm";
