import { useDispatch, useSelector } from "react-redux";
import { getGroupHierarchy } from "../../redux/groupHierarchy/actions";
import useDidMount from "./useDidMount";
const useDashboardHierarchy = () => {
    //   const { isDelphieorSuperUser, isGroupAdmin } = usePermissions(true);
    const groupDataHierarchy = useSelector((state) => state.groupDataHierarchy);
    const dispatch = useDispatch();
    useDidMount(() => {
        // if (!groupDataHierarchy.length) {
        dispatch(getGroupHierarchy());
        // }
    });
    const isSingleCompanyAndSite = (groupId) => {
        let isSingleCompSite = false;
        groupDataHierarchy.map((node) => {
            var _a, _b, _c;
            if (node.companyId === groupId) {
                if (((_a = node.children) === null || _a === void 0 ? void 0 : _a.length) === 1 &&
                    ((_c = (_b = node.children[0]) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c.length) === 1)
                    isSingleCompSite = true;
            }
            return isSingleCompSite;
        });
        return isSingleCompSite;
    };
    const isSingleCompany = (groupId) => {
        let isSingleCompany = false;
        groupDataHierarchy.map((node) => {
            var _a;
            if (node.companyId === groupId) {
                if (((_a = node.children) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                    isSingleCompany = true;
                }
            }
            return isSingleCompany;
        });
        return isSingleCompany;
    };
    const isSinglSite = (groupId, companyId) => {
        let isSingleSite = false;
        groupDataHierarchy === null || groupDataHierarchy === void 0 ? void 0 : groupDataHierarchy.map((node) => {
            var _a;
            if (node.companyId === groupId) {
                (_a = node.children) === null || _a === void 0 ? void 0 : _a.map((item) => {
                    var _a;
                    if (item.companyId === companyId) {
                        if (((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) === 1)
                            isSingleSite = true;
                    }
                    return isSingleSite;
                });
            }
            return isSingleSite;
        });
        return isSingleSite;
    };
    //   const GroupChildrenById = (groupId) => {
    //     let children;
    //     groupDataHierarchy.map((node) => {
    //       if (node.companyId === groupId) {
    //         children = node.children;
    //       }
    //       return children;
    //     });
    //     return children;
    //   };
    const CompanyByGroup = (groupId) => {
        let companyDetail;
        groupDataHierarchy === null || groupDataHierarchy === void 0 ? void 0 : groupDataHierarchy.map((node) => {
            if (node.companyId === groupId)
                companyDetail = node.children[0];
            return companyDetail;
        });
        return isSingleCompany(groupId) && companyDetail;
    };
    const SiteByCompany = (groupId, companyId) => {
        let siteDetail;
        groupDataHierarchy === null || groupDataHierarchy === void 0 ? void 0 : groupDataHierarchy.map((node) => {
            var _a;
            if (node.companyId === groupId) {
                (_a = node.children) === null || _a === void 0 ? void 0 : _a.map((item) => {
                    if (item.companyId === companyId) {
                        siteDetail = item.children[0];
                    }
                    return siteDetail;
                });
            }
            return siteDetail;
        });
        return isSinglSite(groupId, companyId) && siteDetail;
    };
    return {
        groupDataHierarchy,
        isSingleCompany,
        isSinglSite,
        isSingleCompanyAndSite,
        CompanyByGroup,
        SiteByCompany,
    };
};
export default useDashboardHierarchy;
