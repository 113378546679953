import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import { loginRoute } from "./core/constants/routesPath";
import { ApiErrorHandler } from "./layout/components/errors/apiErrorHandler";
import Loader from "./layout/components/loading/loader";
import { NotificationContainer } from "./layout/components/notifications";
import UserRoutes from "./layout/components/routes/UserRoutes";
function App() {
    return (React.createElement(ApiErrorHandler, null,
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/", exact: true },
                React.createElement(Redirect, { to: loginRoute })),
            React.createElement(UserRoutes, null)),
        React.createElement(NotificationContainer, null),
        React.createElement(Loader, null)));
}
export default App;
