import { UserStatus } from "./../../core/enums/UserStatus";
import { GET_USERS_SUCCESS, ADD_USER_SUCCESS, UPDATE_USER_SUCCESS, DELETE_USER_SUCCESS, INACTIVATE_USER_SUCCESS, ACTIVATE_USER_SUCCESS, UNLOCK_USER_SUCCESS, LOCK_USER_SUCCESS, CLEAR_USERS_LISTING_SUCCESS, } from "./types";
const initialState = [];
export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_SUCCESS: {
            return [...action.payload];
        }
        case UPDATE_USER_SUCCESS: {
            const editedUser = state.map((el) => el.id !== action.payload.id ? el : action.payload);
            return [...editedUser];
        }
        case ADD_USER_SUCCESS: {
            //
            return [Object.assign({}, action.payload), ...state];
        }
        case DELETE_USER_SUCCESS: {
            const users = state.filter((el) => el.id !== action.payload);
            return [...users];
        }
        case INACTIVATE_USER_SUCCESS: {
            const users = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { status: UserStatus.INACTIVE }));
            return [...users];
        }
        case ACTIVATE_USER_SUCCESS: {
            const users = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { status: UserStatus.ACTIVE }));
            return [...users];
        }
        case UNLOCK_USER_SUCCESS: {
            const users = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { status: UserStatus.ACTIVE }));
            return [...users];
        }
        case LOCK_USER_SUCCESS: {
            const users = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { status: UserStatus.LOCK }));
            return [...users];
        }
        case CLEAR_USERS_LISTING_SUCCESS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
