import React, { useEffect, useState } from "react";
import { TrackedItemApi } from "../../../../../../../core/api/trackedItemApi";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../../core/utils/translation-files";
import DefaultRelationsTable from "./defaultRelationsTable";
function DefaultRelations({ reportTypeId, siteId }) {
    const [dataSource, setDataSource] = useState([]);
    useEffect(() => {
        if (!isEmpty(reportTypeId) && !isEmpty(siteId)) {
            TrackedItemApi.getAssociatedTrackItemByDefaults(reportTypeId, siteId)
                .then((response) => {
                if (Array === null || Array === void 0 ? void 0 : Array.isArray(response)) {
                    setDataSource(response);
                }
            })
                .catch((error) => {
                throw error;
            });
        }
        else if (isEmpty(reportTypeId) || isEmpty(siteId)) {
            setDataSource([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportTypeId, siteId]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "box-main-section" },
            React.createElement("section", { className: "box-section" },
                React.createElement("div", { className: "box-title" },
                    React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.relationship"]))),
                React.createElement("div", { className: "box-description" },
                    React.createElement(DefaultRelationsTable, { dataSource: dataSource }))))));
}
export default DefaultRelations;
