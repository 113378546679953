import React from "react";
import ConditionalViewFormField from "../conditionalViewFormField";
import Asterik from "../../../views/internal/shared/helper-components/Asterik";
import { Input } from "antd";
const CustomInputField = ({ onChange, onBlur, type = "text", name, dataTest, key, placeholder, defaultValue, className, isViewMode, isMandatory = false, labelText, label, errorMessage, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "form-group", key: key },
            React.createElement("div", { className: "ant-col bold-text" },
                React.createElement("label", { "data-test": dataTest ? dataTest : "" },
                    label,
                    React.createElement(Asterik, { hidden: isViewMode || !isMandatory }))),
            React.createElement(ConditionalViewFormField, { isViewMode: isViewMode, labelText: labelText !== null && labelText !== void 0 ? labelText : defaultValue },
                React.createElement(Input, { type: type, name: name, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, onBlur: onBlur, onChange: onChange, defaultValue: defaultValue, className: `${className} ${errorMessage ? "input-error" : null}` }),
                errorMessage && React.createElement("p", { className: "error" }, errorMessage)))));
};
export default CustomInputField;
