var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Switch } from "antd";
import React, { useEffect, useState } from "react";
import Header from "../../../../../layout/components/common/header";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { addSite, editSite, getSites, activateSite, inActivateSite, } from "../../../../../redux/site/actions";
import { notifications } from "../../../../../layout/components/notifications";
import { getCompanies } from "../../../../../redux/company/actions";
import SiteTable from "./components/siteTable";
import { setContextCompany } from "../../../../../redux/context/actions";
import { SITE_EDIT, SITE_CREATION, } from "../../../../../core/constants/permissionConstants";
import { addSiteRoute } from "../../../../../core/constants/routesPath";
import localize from "../../../../../core/utils/translation-files";
import { getCompanyIdBySelectedCompany } from "../../../../../core/utils/UserContextSelection";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import TableActions from "../../../../../layout/components/customTable/tableActions";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import usePermissions from "../../../../../core/hooks/usePermissions";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import PrintButton from "../../../../../layout/components/common/PrintButton";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import ListingSearch from "../../../../../layout/components/listingSearch";
import ReingestDetailModal from "./components/reingestDetailModal";
import { FinancialApi } from "../../../../../core/api/financialApi";
const SiteListingContainer = ({ sites, getSites, activateSite, inActivateSite, companies, context, isPrintMode, }) => {
    var _a, _b;
    const [reingestModelVisibilty, setReingestModelVisibilty] = useState(false);
    const [selectedSite, setSelectedSite] = useState();
    const [monthYearList, setMonthYearList] = useState();
    const history = useHistory();
    const { handlePrint, nodeRef } = UsePrintPage();
    const { singleCompanyValues } = usePermissions();
    const [companyId] = useState((_a = getCompanyIdBySelectedCompany(context)) !== null && _a !== void 0 ? _a : singleCompanyValues === null || singleCompanyValues === void 0 ? void 0 : singleCompanyValues.id);
    const _c = UseCustomPagination({
        totalRecords: (_b = sites === null || sites === void 0 ? void 0 : sites.length) !== null && _b !== void 0 ? _b : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _c, restPaginationProps = __rest(_c, ["handleCustomTablePropChange"]);
    useEffect(() => {
        //
        if (sites.length === 0) {
            if (companyId) {
                getSites(companyId).catch((error) => {
                    throw error;
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sites.length,
        getSites,
        companies,
        sites,
        companies.length,
        context.selectedCompany,
    ]);
    const performInjestion = (siteId, payload = null, resetForm = null) => {
        const { ingestionMonth, ingestionYear } = payload;
        if (siteId) {
            FinancialApi.performInjestion(siteId, ingestionMonth !== null && ingestionMonth !== void 0 ? ingestionMonth : -1, ingestionYear !== null && ingestionYear !== void 0 ? ingestionYear : -1)
                .then((res) => {
                if (!res.code) {
                    if (payload) {
                        if (!ingestionMonth) {
                            notifications.success(localize(["Ingestion", "resumed", "successfully"]));
                        }
                        else {
                            notifications.success(localize(["Reingestion", "started", "successfully"]));
                        }
                        resetForm();
                        setSelectedSite(undefined);
                    }
                    setReingestModelVisibilty(false);
                }
            })
                .catch((error) => {
                throw error;
            });
        }
    };
    const performMonthlyInjestion = (site) => {
        FinancialApi.getAccountNextMonthYear(site.externalReference)
            .then((res) => {
            if (res.month) {
                if (site.id) {
                    FinancialApi.performInjestion(site.id, res.month, res.year)
                        .then((res) => {
                        if (!res.code) {
                            notifications.success(localize([
                                "Ingestion",
                                "of",
                                "Next",
                                "Month",
                                "Started",
                                "successfully",
                            ]));
                            setSelectedSite(undefined);
                            setReingestModelVisibilty(false);
                        }
                    })
                        .catch((error) => {
                        throw error;
                    });
                }
            }
            else {
                if (!res.code) {
                    notifications.success(localize(["Data", "of", "Latest", "Month", "Already", "Ingested"]));
                }
            }
        })
            .catch((error) => {
            throw error;
        });
    };
    const openReingestModel = (site) => {
        FinancialApi.getAccountMonthYear(site.externalReference).then((res) => setMonthYearList(res));
        setReingestModelVisibilty(true);
        setSelectedSite(site.id);
    };
    const closeReingestModel = () => {
        setReingestModelVisibilty(false);
    };
    const openEditModal = (sites, isReadOnly = null) => {
        history.push(`sites/edit/${sites.id}`, {
            siteId: sites.id,
            isReadOnly: isReadOnly,
        });
    };
    const InActivateSiteHandler = (data, status) => {
        inActivateSite(data, !status);
        notifications.success(localize(["Site.site", "disabled", "successfully"]));
    };
    const activeSiteHandler = (data, status) => {
        activateSite(data, !status);
        notifications.success(localize(["Site.site", "enabled", "successfully"]));
    };
    const sitesActions = (site) => {
        const actionArray = [
            {
                priority: 2,
                title: ["edit", "Site.site"],
                onClick: openEditModal.bind({}, site, false),
                svgIcon: "Edit",
                isAllowed: isActionAllowed([SITE_EDIT], site),
            },
            {
                priority: 2,
                title: ["view", "Site.site"],
                onClick: openEditModal.bind({}, site, true),
                svgIcon: "View",
                isAllowed: !isActionAllowed([SITE_EDIT], site),
            },
            {
                priority: 1,
                title: site.active
                    ? ["archive", "Site.site"]
                    : ["Site.unarchive", "Site.site"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${site.active ? "Archive" : "unarchive"}`,
                    "Site.thisSite",
                ],
                onConfirm: site.active
                    ? InActivateSiteHandler.bind({}, site.id)
                    : activeSiteHandler.bind({}, site.id),
                isAllowed: isActionAllowed([SITE_EDIT], site),
                children: (React.createElement(Switch, { className: "ml-1", checked: site.active, style: site.name === "Delphi Care"
                        ? {
                            margin: "auto",
                            pointerEvents: "none",
                            background: "gray",
                            borderColor: "gray",
                        }
                        : { margin: "auto" } })),
            },
        ];
        const xeroActions = [
            {
                priority: 5,
                title: ["Site.resumeIngestion"],
                onClick: performInjestion.bind({}, site.id),
                disbaled: site.siteDetail.financialSystem === 0,
                renderSvgButton: true,
                // isAllowed: isActionAllowed([SITE_EDIT], site),
            },
            {
                priority: 4,
                title: ["Site.reingestData"],
                onClick: openReingestModel.bind({}, site),
                disbaled: site.siteDetail.financialSystem === 0,
                renderSvgButton: true,
                // svgIcon: "InActiveFinancialReportsMenu",
                // isAllowed: !isActionAllowed([SITE_EDIT], site),
            },
            {
                priority: 3,
                title: ["Site.ingestDataByMonth"],
                onClick: performMonthlyInjestion.bind({}, site),
                disbaled: site.siteDetail.financialSystem === 0,
                renderSvgButton: true,
                // svgIcon: "InActiveFinancialReportsMenu",
                // isAllowed: !isActionAllowed([SITE_EDIT], site),
            },
        ];
        return (React.createElement("div", { style: { display: "flex", alignItems: "center" } },
            React.createElement("div", null,
                React.createElement(TableActions, { actionArray: actionArray })),
            React.createElement("div", null,
                React.createElement(TableActions, { actionArray: xeroActions, wrapperClass: "dottedActionsButton", ShowActionText: false, menuItems: true }))));
    };
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                " ",
                React.createElement(ListingSearch, null),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Button, { type: "primary", onClick: (e) => history.push(addSiteRoute), hidden: !isActionAllowed([SITE_CREATION]) },
                    React.createElement(RenderSvg, { icon: "AddSite" }),
                    localize(["add", "Site.site"]))) }),
        React.createElement(SiteTable, { data: sites, actions: sitesActions, currentPage: restPaginationProps.currentPage, pageSize: restPaginationProps.pageSize, handleCustomTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps, isPrintMode: isPrintMode }),
        React.createElement(ReingestDetailModal, { reingestModelVisibilty: reingestModelVisibilty, closeReingestModel: closeReingestModel, selectedSite: selectedSite, monthYearList: monthYearList, onSubmit: performInjestion })));
};
function mapStateTopProps(state, ownProps) {
    var _a;
    return {
        sites: state.sites,
        companies: state.companies,
        context: state.context,
        isPrintMode: (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status,
    };
}
const mapDispatchToProps = {
    getSites,
    addSite,
    editSite,
    activateSite,
    inActivateSite,
    getCompanies,
    setContextCompany,
};
SiteListingContainer.propTypes = {
    getCompanies: PropTypes.func.isRequired,
    sites: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    getSites: PropTypes.func.isRequired,
    addSite: PropTypes.func.isRequired,
    editSite: PropTypes.func.isRequired,
    deleteSite: PropTypes.func.isRequired,
    setContextCompany: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(SiteListingContainer);
