import { NumericInput } from "../numericInput/numericInput";
import React, { useState } from "react";
import { uniqueId } from "lodash-es";
const useUniqueId = (prefix) => {
    const [persistedId] = useState(uniqueId(prefix));
    return persistedId;
};
export const NumericColoredRangeValueControl = ({ value, onChange, colorClassName, renderTooltip, disabled, }) => {
    const id = useUniqueId("numeric-colored-control-");
    return (React.createElement("div", { className: `range ${colorClassName}` },
        React.createElement(NumericInput, { id: id, className: `range-value ${colorClassName}`, value: value, onChange: onChange, placeholder: disabled ? "" : "Enter", disabled: disabled })));
};
