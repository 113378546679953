import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutActions } from "../../../redux/context/actions";
import BrowserActivityDetector from "../BrowserActivityDetector";
const Logout = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(logoutActions(history));
    };
    return React.createElement(BrowserActivityDetector, { onIdle: logoutHandler });
};
export default Logout;
