const isEmpty = (value) => value === undefined ||
    value === null ||
    (typeof value === "string" && !value.trim().length) ||
    (typeof value === "object" && !Object.keys(value).length);
export default isEmpty;
export function arePropertiesEmpty(obj) {
    for (var key in obj) {
        if (obj[key] !== null && obj[key] !== "")
            return false;
    }
    return true;
}
