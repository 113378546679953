import { TaskType } from "./../enums/TaskType";
import { httpGet } from "./request/requestApi";
const controllerEndpoint = "task";
export class TaskApi {
    static getTasks(siteId, type) {
        if (type === TaskType.Due)
            return httpGet(`${controllerEndpoint}/list/due/${siteId}`);
        if (type === TaskType.Completed)
            return httpGet(`${controllerEndpoint}/list/completed/${siteId}`);
    }
    static getTaskCount(siteId) {
        return httpGet(`${controllerEndpoint}/count/${siteId}`);
    }
}
