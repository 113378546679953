var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DragOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement, SortableHandle, } from "react-sortable-hoc";
import usePermissions from "../../../../../../../core/hooks/usePermissions";
import { TrackedItemTypeApi } from "../../../../../../../core/api/trackedItemTypeApi";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import { array_move } from "../../../../../../../core/utils/arrayUtils";
import { scrollIntoView } from "../../../../../../../core/utils/function";
import localize from "../../../../../../../core/utils/translation-files";
import TableActions from "../../../../../../../layout/components/customTable/tableActions";
import RelationsTable from "./relationsTable";
function Relations({ relationsList, setFieldValue, frequency, type, setFormDirty, }) {
    const { isDelphieorSuperUser } = usePermissions();
    const [count, setCount] = useState(relationsList.length + 1);
    const initial = {
        id: undefined,
        associatedId: undefined,
        name: undefined,
        disabled: false,
        summaryItem: false,
        emailFlag: false,
        priority: count,
        isNew: true,
    };
    const [updatedInstance, setUpdatedInstance] = useState(initial);
    const [DEFAULT_RELATIONS] = useState([...relationsList]);
    const [dataSource, setDataSource] = useState([...relationsList, initial]);
    const DragHandle = SortableHandle(() => (React.createElement(DragOutlined, { style: { cursor: "grab", color: "#636363", fontSize: "1.3rem" } })));
    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.priority !== key);
        setDataSource(newData);
        handleSetRelationsValue(newData);
    };
    const [isAddAction, setIsAddAction] = useState(false);
    const handleAdd = () => {
        const isNewRow = dataSource.find((row) => !row.name);
        if (!isNewRow) {
            const newData = initial;
            setDataSource([...dataSource, newData]);
            setIsAddAction(true);
        }
    };
    useEffect(() => {
        if (dataSource.length > 5 && isAddAction) {
            scrollIntoView("associatedIdField");
        }
    }, [dataSource === null || dataSource === void 0 ? void 0 : dataSource.length, isAddAction]);
    const [errors, setErrors] = useState({});
    const validate = () => {
        const existingRelation = dataSource === null || dataSource === void 0 ? void 0 : dataSource.find(({ associatedId }) => associatedId === updatedInstance.associatedId);
        const errorMessages = {};
        if ((existingRelation === null || existingRelation === void 0 ? void 0 : existingRelation.associatedId) === updatedInstance.associatedId)
            errorMessages["associatedId"] = "Item Name must be unique";
        setErrors(errorMessages);
        return isEmpty(errorMessages);
    };
    const handleSave = () => {
        setFormDirty(false);
        const newData = [...dataSource];
        const isValid = validate();
        if (isValid) {
            isAddAction && setIsAddAction(false);
            const index = newData.findIndex((item) => updatedInstance.priority === item.priority);
            delete updatedInstance["isNew"];
            newData[index] = updatedInstance;
            setUpdatedInstance(Object.assign(Object.assign({}, initial), { priority: count + 1 }));
            setCount(count + 1);
            handleSetRelationsValue(newData);
        }
    };
    const handleSetRelationsValue = (data, reset = false) => {
        if (reset) {
            setDataSource([...data, initial]);
        }
        else {
            setDataSource(data);
        }
        setFieldValue("associatedTrackedItems", data);
    };
    const handlSetFieldValue = (value, inputField) => {
        var _a;
        setFormDirty(true);
        const updateInstanceValue = Object.assign(Object.assign({}, updatedInstance), { [inputField]: value });
        const { priority, isNew } = updateInstanceValue, rest = __rest(updateInstanceValue, ["priority", "isNew"]);
        const isDirty = (_a = Object.values(rest).filter(Boolean)) === null || _a === void 0 ? void 0 : _a.length;
        setFormDirty(Boolean(isDirty));
        setUpdatedInstance(updateInstanceValue);
    };
    const handleDefaultChange = (value, option) => {
        setFormDirty(true);
        setUpdatedInstance(Object.assign(Object.assign({}, updatedInstance), { associatedId: value, name: option.text }));
    };
    const [defaultFrequencyOptions, setDefaultFrequencyOptions] = useState([]);
    useEffect(() => {
        if (!isEmpty(frequency)) {
            handleSetRelationsValue([...DEFAULT_RELATIONS], true);
            setUpdatedInstance(initial);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frequency]);
    useEffect(() => {
        if (!isEmpty(frequency) && !isEmpty(type)) {
            TrackedItemTypeApi.getDefaultsByFrequency(frequency, type)
                .then((response) => {
                var _a;
                const defaultOptions = (_a = response === null || response === void 0 ? void 0 : response.map(({ id, trackedItemName }) => {
                    return { value: id, text: trackedItemName };
                })) !== null && _a !== void 0 ? _a : [];
                setDefaultFrequencyOptions(defaultOptions);
            })
                .catch((error) => {
                throw error;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frequency, type]);
    const SortableItem = SortableElement((props) => React.createElement("tr", Object.assign({}, props)));
    const SortableBody = SortableContainer((props) => React.createElement("tbody", Object.assign({}, props)));
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = array_move([].concat(dataSource), oldIndex, newIndex).filter((el) => !!el);
            handleSetRelationsValue(newData);
        }
    };
    const DraggableContainer = (props) => (React.createElement(SortableBody, Object.assign({ useDragHandle: true, disableAutoscroll: true, helperClass: "row-dragging", onSortEnd: onSortEnd }, props)));
    const DraggableBodyRow = (_a) => {
        var { className, style } = _a, restProps = __rest(_a, ["className", "style"]);
        const index = dataSource.findIndex((x) => x.priority === restProps["data-row-key"]);
        return React.createElement(SortableItem, Object.assign({ index: index }, restProps));
    };
    const relationsActions = (relation) => {
        const actionArray = [
            {
                priority: 1,
                title: ["TrackedItem.sort", "TrackedItem.relation"],
                // btnType: "default",
                // btnClass: "roleCountButton sortButton",
                isAllowed: isDelphieorSuperUser,
                iconComponent: React.createElement(DragHandle, null),
            },
            {
                priority: 2,
                title: ["delete", "TrackedItem.relation"],
                isModal: true,
                modalTitle: ["areYouSureTo", "TrackedItem.deleteThisRelation", "?"],
                onConfirm: handleDelete.bind({}, relation === null || relation === void 0 ? void 0 : relation.priority),
                isAllowed: isDelphieorSuperUser,
                children: (React.createElement("span", { className: "icofont icofont-ui-delete", style: { color: "red", fontSize: "16px", cursor: "pointer" } })),
            },
        ];
        return (React.createElement(TableActions, { actionArray: actionArray, wrapperClass: "buttons-list-center" }));
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "box-main-section" },
            React.createElement("section", { className: "box-section" },
                React.createElement("div", { className: "box-title" },
                    React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.relationship"]))),
                React.createElement("div", { className: "box-description" },
                    React.createElement(RelationsTable, { dataSource: dataSource, setDataSource: handleSetRelationsValue, updatedInstance: updatedInstance, setUpdatedInstance: setUpdatedInstance, actions: relationsActions, DraggableBodyRow: DraggableBodyRow, DraggableContainer: DraggableContainer, handleSave: handleSave, handleAdd: handleAdd, handlSetFieldValue: handlSetFieldValue, handleDefaultChange: handleDefaultChange, frequency: frequency, isDelphieorSuperUser: isDelphieorSuperUser, defaultFrequencyOptions: defaultFrequencyOptions, errors: errors }))))));
}
export default Relations;
