import React, { useEffect, useState } from "react";
import CustomModal from "../../../../../../layout/components/customModal";
import localize from "../../../../../../core/utils/translation-files";
import ReingestDataForm from "./reingestDataForm";
const ReingestDetailModal = ({ reingestModelVisibilty, closeReingestModel, selectedSite, onSubmit, monthYearList, }) => {
    const initialData = {
        ingestionMonth: undefined,
        ingestionYear: undefined,
    };
    const [monthOpt, setMonthOpt] = useState([]);
    const [yearOpt, setYearOpt] = useState([]);
    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString("en-US", { month: "long" });
    };
    useEffect(() => {
        var _a, _b, _c, _d;
        if (monthYearList) {
            setMonthOpt(monthYearList.month
                ? (_b = (_a = monthYearList.month) === null || _a === void 0 ? void 0 : _a.map((v) => ({
                    value: v,
                    text: getMonthName(v),
                }))) === null || _b === void 0 ? void 0 : _b.reverse()
                : []);
            setYearOpt(monthYearList.year
                ? (_d = (_c = monthYearList.year) === null || _c === void 0 ? void 0 : _c.map((v) => ({
                    value: v,
                    text: v.toString(),
                }))) === null || _d === void 0 ? void 0 : _d.reverse()
                : []);
        }
    }, [monthYearList]);
    return (React.createElement(CustomModal, { visible: reingestModelVisibilty, onCancel: closeReingestModel, maskClosable: false, destroyOnClose: true, footer: null, title: [localize(["Site.reingestData"])], width: "450px" },
        React.createElement(ReingestDataForm, { onCancel: closeReingestModel, onSubmit: onSubmit, selectedSite: selectedSite, initialData: initialData, monthOpt: monthOpt, yearOpt: yearOpt })));
};
export default ReingestDetailModal;
