import { Button, List } from "antd";
import React, { useEffect, useState } from "react";
import { WatchlistApi } from "../../../../../../../core/api/watchlistApi";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../../core/utils/translation-files";
import RenderSvg from "../../../../../../../layout/components/icons/RenderSvg";
import { notifications } from "../../../../../../../layout/components/notifications";
import NewWatchListModalComponent from "../../../../../admin/watchlist/components/newWatchListModalComponent";
const AddRemoveWatchListComponent = ({ selectedTrackItemId, onAddRemoveWatchList }) => {
    const [watchlistInputVisible, setWatchlistInputVisible] = useState(false);
    const handleWatchListInputToggleVisiblity = () => {
        setWatchlistInputVisible((prev) => !prev);
    };
    const [tabsArray, setTabsArray] = useState([]);
    useEffect(() => {
        if (!isEmpty(selectedTrackItemId)) {
            WatchlistApi.getWatchListByTrackItemId(selectedTrackItemId).then((response) => {
                if (!isEmpty(response) && Array.isArray(response)) {
                    setTabsArray(response);
                }
            });
        }
    }, [selectedTrackItemId]);
    const handeAddRemoveFromWatchList = (watchListId, reportIdPresent) => {
        const addRemoveAPIAction = reportIdPresent
            ? WatchlistApi.removeFromWatchlist
            : WatchlistApi.addToWatchlist;
        const successMessage = reportIdPresent
            ? ["Dashboard.removedFrom", "Dashboard.watchList", "successfully"]
            : ["Dashboard.addedto", "Dashboard.watchList", "successfully"];
        addRemoveAPIAction(selectedTrackItemId, watchListId).then((res) => {
            if (!(res === null || res === void 0 ? void 0 : res.code)) {
                setTabsArray((prev) => prev.map(({ watchlistDTO, reportIdPresent }) => watchlistDTO.id === watchListId
                    ? { watchlistDTO, reportIdPresent: !reportIdPresent }
                    : { watchlistDTO, reportIdPresent }));
                onAddRemoveWatchList === null || onAddRemoveWatchList === void 0 ? void 0 : onAddRemoveWatchList(selectedTrackItemId, !reportIdPresent);
                notifications.success(localize(successMessage));
            }
        });
    };
    const handleAddNewWatchList = (watchlistDTO) => {
        handleWatchListInputToggleVisiblity();
        setTabsArray((prev) => [{ watchlistDTO, reportIdPresent: false }, ...prev]);
    };
    return (React.createElement(React.Fragment, null,
        watchlistInputVisible ? (React.createElement(NewWatchListModalComponent, { handleAddNewWatchList: handleAddNewWatchList, tabsArray: tabsArray.map(({ watchlistDTO }) => watchlistDTO) })) : (React.createElement(Button, { type: "primary", onClick: handleWatchListInputToggleVisiblity }, "Create New Watchlist")),
        React.createElement("div", { className: "watchlistArrayContainer" },
            React.createElement(List, { itemLayout: "horizontal", dataSource: tabsArray, renderItem: (watchlistTrackItem) => {
                    const { reportIdPresent, watchlistDTO: { id, watchListName, itemsCanBeAddorRemoved }, } = watchlistTrackItem;
                    return (React.createElement(List.Item, { title: watchListName, className: `cursor-${itemsCanBeAddorRemoved ? `pointer` : `not-allowed`}`, tabIndex: id, onClick: itemsCanBeAddorRemoved
                            ? handeAddRemoveFromWatchList.bind({}, id, reportIdPresent)
                            : undefined },
                        React.createElement(RenderSvg, { icon: reportIdPresent ? "AddedToWatchlist" : "WatchlistButton", style: { marginRight: "10px" } }),
                        reportIdPresent ? " Remove from " : " Add to ",
                        watchListName));
                } }))));
};
export default AddRemoveWatchListComponent;
