import React from "react";
import { Modal, Tooltip } from "antd";
import localize from "../../../core/utils/translation-files";
const CustomModal = ({ visible, maskClosable, onCancel, destroyOnClose, footer, title, subTitle = "", children, width, overflowY, inlineStyle, modelClass, }) => {
    // const settings = useSelector((state: RootStateOrAny) => state.settings);
    const headerStyle = {
    // background: settings.modalHeaderBg,
    // color: settings.modalHeaderClr,
    };
    const headerTitle = title && visible ? `${subTitle} ${localize(title)}` : ``;
    return (React.createElement(Modal, { className: modelClass && modelClass, visible: visible, centered: true, onCancel: onCancel, maskClosable: maskClosable, destroyOnClose: destroyOnClose, footer: footer, bodyStyle: overflowY
            ? {
                overflowY: "hidden",
                maxHeight: "70vh",
            }
            : {}, title: title ? (React.createElement(Tooltip, { title: headerTitle, placement: "topLeft" },
            React.createElement("div", { style: headerStyle },
                React.createElement("h5", { className: "title" }, headerTitle)))) : (""), width: width && width, style: inlineStyle }, children));
};
export default CustomModal;
