import { Button, Tabs } from "antd";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { addTrackedItemsRoute, viewTrackedItemChartRoute, } from "../../../../../../core/constants/routesPath";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import UsePrintPage from "../../../../../../core/hooks/usePrintPage";
import localize from "../../../../../../core/utils/translation-files";
import UserCreatedTrackedItemsListingContainer from "./user-created-tracked-items";
import SystemCreatedTrackedItemsListingContainer from "./system-created-tracked-items";
import isActionAllowed from "../../../../../../core/authorization/userPermissions";
import { REPORT_CREATION } from "../../../../../../core/constants/permissionConstants";
import UsePersistCustomFilters from "../../../../../../core/hooks/usePersistCustomFilters";
import FiltersDropdownMenu from "../../../../../../layout/components/select/FiltersDropdownMenu";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import SelectedListingFilters from "../../../../../../layout/components/selectedListingFilters";
import PrintButton from "../../../../../../layout/components/common/PrintButton";
import Header from "../../../../../../layout/components/common/header";
import ListingSearch from "../../../../../../layout/components/listingSearch";
import { useDispatch } from "react-redux";
import { clearSearchInputSuccess } from "../../../../../../redux/listingSearchInput/actions";
const ManageTrackedItemContainer = (props) => {
    var _a;
    const { TabPane } = Tabs;
    const { setFilters, filters } = UsePersistCustomFilters({
        additionalPersistRoutes: [viewTrackedItemChartRoute],
        defaultValue: {},
    });
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState((_a = SessionStorage.getKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB)) !== null && _a !== void 0 ? _a : "1");
    const [isSubmitAction, setIsSubmitAction] = useState(false);
    const handleChange = (activeKey) => {
        dispatch(clearSearchInputSuccess());
        setSelectedTab(activeKey);
        SessionStorage.setKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB, activeKey);
    };
    const handleChangeFilters = (filterValues) => {
        setFilters(Object.assign(Object.assign({}, filters), { [selectedTab]: filterValues }));
    };
    const tabFilterValue = useCallback((tabKey) => { var _a; return (_a = filters === null || filters === void 0 ? void 0 : filters[tabKey]) !== null && _a !== void 0 ? _a : {}; }, [filters]);
    const handleApplyFilter = () => {
        setIsSubmitAction(true);
    };
    const { handlePrint, nodeRef } = UsePrintPage();
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { leftComponent: React.createElement(SelectedListingFilters, { filters: tabFilterValue(selectedTab) }), rightComponent: React.createElement("div", { className: "row" },
                React.createElement(ListingSearch, null),
                React.createElement(FiltersDropdownMenu, { groupOptionValues: tabFilterValue(selectedTab), onChangeGroupOptions: handleChangeFilters, allowClearCompany: true, allowClearSite: true, onApply: handleApplyFilter }),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Link, { to: {
                        pathname: addTrackedItemsRoute,
                    }, hidden: !isActionAllowed([REPORT_CREATION]) },
                    React.createElement(Button, { htmlType: "button", type: "primary" },
                        React.createElement(RenderSvg, { icon: "AddUserTrackItem" }),
                        localize(["add", "TrackedItem.trackedItem"])))) }),
        React.createElement(Tabs, { defaultActiveKey: selectedTab, onChange: handleChange },
            React.createElement(TabPane, { tab: "Tracked Items", key: "1", id: "tracked-item-definitions" }, selectedTab === "1" && (React.createElement(UserCreatedTrackedItemsListingContainer, { filters: tabFilterValue("1"), isSubmitAction: isSubmitAction && selectedTab === "1", setIsSubmitAction: setIsSubmitAction }))),
            React.createElement(TabPane, { tab: "Indices/Categories", key: "2", id: "tracked-item-types" }, selectedTab === "2" && (React.createElement(SystemCreatedTrackedItemsListingContainer, { filters: tabFilterValue("2"), isSubmitAction: isSubmitAction && selectedTab === "2", setIsSubmitAction: setIsSubmitAction }))))));
};
export default ManageTrackedItemContainer;
