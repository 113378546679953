var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useState, useEffect, useRef } from "react";
import { Input, Form, Select } from "antd";
export const EditableContext = React.createContext(null);
const EditableCell = (_a) => {
    var { getLookup, financialActivity, financialActivityHead, title, editable, children, dataIndex, record, handleSave } = _a, restProps = __rest(_a, ["getLookup", "financialActivity", "financialActivityHead", "title", "editable", "children", "dataIndex", "record", "handleSave"]);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };
    const save = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const values = yield form.validateFields();
            toggleEdit();
            handleSave(Object.assign(Object.assign({}, record), values));
        }
        catch (errInfo) {
            handleSave(Object.assign(Object.assign({}, record), { [errInfo.errorFields[0].name[0]]: "" }));
        }
    });
    let childNode = children;
    if (editable) {
        childNode = editing ? (React.createElement(React.Fragment, null,
            dataIndex === "value" && (React.createElement(Form.Item, { style: { margin: 0 }, name: dataIndex, rules: [
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ] },
                React.createElement(Input, { ref: inputRef, type: financialActivityHead.length > 0 &&
                        record &&
                        record.financialActivityHeadId &&
                        financialActivityHead.filter((item) => item.text === record.financialActivityHeadId).length > 0
                        ? financialActivityHead.filter((item) => item.text === record.financialActivityHeadId)[0].dataKey
                        : "string", onPressEnter: save, onBlur: save, placeholder: "Enter a value" }))),
            dataIndex === "financialActivityHeadId" && (React.createElement(Form.Item, { style: { margin: 0 }, name: dataIndex, rules: [
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ] },
                React.createElement(Select, { ref: inputRef, onBlur: save, placeholder: "Select an option", showSearch: true }, financialActivityHead.map((item, index) => (React.createElement(Select.Option, { key: index, value: item.text }, item.text)))))),
            dataIndex === "financialActivityId" && (React.createElement(Form.Item, { style: { margin: 0 }, name: dataIndex, rules: [
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ] },
                React.createElement(Select, { ref: inputRef, onBlur: save, placeholder: "Select an option", showSearch: true }, financialActivity.map((item, index) => (React.createElement(Select.Option, { key: index, value: item.text }, item.text)))))))) : (React.createElement("div", { className: "editable-cell-value-wrap", style: { paddingRight: 24 }, onClick: toggleEdit }, children));
    }
    return React.createElement("td", Object.assign({}, restProps), childNode);
};
export default EditableCell;
