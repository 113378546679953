export const registerationNoRegex = {
    regex: /\b[A-Z]{2}[0-9]{6}\b|^[0-9]{8}$/g,
    message: "Format should be ‘NNNNNNNN’ or ‘AANNNNNN’ (e.g. 12345678 or AB123456)",
};
export const cqcRegistrationNumberRegex = {
    regex: /\b([0-9]{1})+-[0-9]{9}\b$/,
    message: "Format should be ‘N-NNNNNNNNN’ (e.g. 1-234567890). Length is 11 characters",
};
export const onlyAlpabeticCharactersRegex = {
    regex: /^[a-zA-Z\-_\s]+$/,
    message: "Must only have Alphabetic characters",
};
export const mobileNumberRegex = {
    regex: /\b07[0-9]{9}\b/,
    message: "Format should be Standard UK Mobile number’ (e.g. 07234567890)",
};
export const telephoneNumberRegex = {
    regex: /^0([1-3|8][0-9]{8,9})$/,
    message: "Format should be Standard UK Landline Number’ (e.g. 01422123456)",
};
export const ukPostCodeRegex = {
    regex: /^([A-Za-z]{1,2}[0-9]{1,2}|[A-Za-z]{3}|[A-Za-z]{1,2}[0-9][A-Za-z])( |-)[0-9][A-Za-z]{2}$/,
    message: "Format should be Standard UK Post Code’ (e.g. A1A 1AA)",
};
export const urlRegex = {
    regex: /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    message: "Invalid URL",
};
export const passwordRegex = {
    regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    message: "Minimum 8 characters, at least one letter, one number and one special character",
};
export const trackedItemNameRegex = {
    regex: /^[a-zA-Z0-9_ ]*$/,
    message: "Special Characters are not allowed!",
};
export const decimalNumberRegex = {
    regex: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
    message: "Must be a number",
};
export const numberRegex = {
    regex: /^[0-9]*$/,
    message: "Enter a valid Number",
};
