import React from "react";
import { CompanyResponse, } from "../../../../../../core/contracts/company/companyContracts";
import CustomTable from "../../../../../../layout/components/customTable";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { sitesRoute } from "../../../../../../core/constants/routesPath";
import { useHistory } from "react-router-dom";
import { actionColumnFilterOptions } from "../../../../../../core/constants/actionColumnFilterOptions";
import CustomTextHighlighter from "../../../../../../layout/components/customTextHighlighter";
const CompanyTable = ({ data, actions, companyChanged, setContextCompany, currentPage, pageSize, handleCustomTablePropChange = null, isPrintMode = false, paginationSection = null, }) => {
    const history = useHistory();
    const handleRowClick = (record) => {
        setContextCompany(record);
        SessionStorage.setKey(SessionVariables.COMPANY, record);
        companyChanged();
        history.push(sitesRoute);
    };
    const columns = [
        {
            fieldName: "name",
            title: ["Company.company", "name"],
            width: 150,
            sortable: true,
            defaultSortOrder: "ascend",
            className: "bold-text",
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "emailAddress",
            title: ["Company.company", "email", "address"],
            width: 150,
            sortable: true,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "primaryTelephoneNumber",
            title: ["primary", "contact", "number"],
            width: 150,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "contactName",
            title: ["primary", "contact", "name"],
            width: 150,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        width: 150,
        enableFilter: true,
        filterInfo: {
            listing: actionColumnFilterOptions,
            nestedKey: "active",
        },
        render: actions,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "companyTableComponent" },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: CompanyResponse, rowClickHandler: handleRowClick, currentPage: currentPage, pageSize: pageSize, itemEnableKey: "active", totalCount: paginationSection === null || paginationSection === void 0 ? void 0 : paginationSection.totalRecords, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: paginationSection })));
};
export default CompanyTable;
