import { httpDelete, httpGet, httpPost, httpPut } from "./request/requestApi";
const controllerEndpoint = "staticdata/lookup";
const lookupTypecontrollerEndpoint = "support/lookups";
const dependentLookupTypecontrollerEndpoint = "staticdata/lookupData";
export class LookupApi {
    static getLookupData(lookupName) {
        return httpGet(controllerEndpoint + "/" + lookupName);
    }
    static getLookupList(lookupNames) {
        return httpPost(`${controllerEndpoint}/list`, {
            body: lookupNames,
        });
    }
    static createLookup(data) {
        return httpPost(`${lookupTypecontrollerEndpoint}/entry`, {
            body: data,
        });
    }
    static updateLookup(data) {
        return httpPut(`${lookupTypecontrollerEndpoint}/entry`, {
            body: data,
        });
    }
    static deleteLookup(id) {
        return httpDelete(`${lookupTypecontrollerEndpoint}/entry/${id}`);
    }
    static getLookupTypes() {
        return httpGet(lookupTypecontrollerEndpoint + "/list");
    }
    static getChildLookupByParentId(id) {
        return httpGet(`${dependentLookupTypecontrollerEndpoint}/list/${id}`);
    }
    static getLookupByPermissions(name) {
        return httpGet(`${lookupTypecontrollerEndpoint}/${name}`);
    }
    static getBandLookup() {
        return httpGet(`${lookupTypecontrollerEndpoint}/getAllowedBandToUser`);
    }
}
