var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import isEmpty from "../../../core/helpers/isEmpty";
import usePermissions from "../../../core/hooks/usePermissions";
import localize from "../../../core/utils/translation-files";
import Asterik from "../../../views/internal/shared/helper-components/Asterik";
import CompanyDropdownContainer from "../select/CompanyDropdownContainer";
import GroupDropdownContainer from "../select/GroupDropdownContainer";
import SiteDropdownContainer from "../select/SiteDropdownContainer";
const FormPermissionDropdown = (_a) => {
    var _b, _c, _d;
    var { isReport = false } = _a, _e = _a.groupValues, { onChangeGroup } = _e, groupValues = __rest(_e, ["onChangeGroup"]), _f = _a.companyValues, { onChangeCompany } = _f, companyValues = __rest(_f, ["onChangeCompany"]), _g = _a.siteValues, { onChangeSite } = _g, siteValues = __rest(_g, ["onChangeSite"]), { dirty = false } = _a;
    const { isDelphieorSuperUser, isSingleCompanyOrSiteAdmin, isDelphiSuperOrGroupUser, isSingleSiteAdmin, isCompanyAdmin, isSiteAdmin, isSingleGroup, isExternalUser, } = usePermissions();
    const handleGroupChange = (value, e) => {
        onChangeGroup(value, e);
        onChangeCompany(null);
        onChangeSite(null);
    };
    const handleChangeCompany = (value, e) => {
        onChangeCompany(value, e);
        onChangeSite(null);
    };
    const handleChangeSite = (value, e) => {
        onChangeSite(value, e);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `col-3 ${(_b = groupValues.wrapperClass) !== null && _b !== void 0 ? _b : ``}`, hidden: (isSingleGroup && !isDelphieorSuperUser) || !!groupValues.hidden, "data-test": "group-dropdown" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        localize(["Group.group"]),
                        " ",
                        dirty && React.createElement(Asterik, null))),
                React.createElement(GroupDropdownContainer, { className: "mb-2 mb-md-0", onChange: handleGroupChange, selectedValue: groupValues.selectedvalue, isDisable: groupValues.disabled, allowClear: groupValues.allowClear, error: groupValues.errorMessage }))),
        React.createElement("div", { className: `col-3 ${(_c = companyValues.wrapperClass) !== null && _c !== void 0 ? _c : ``}`, hidden: isSingleCompanyOrSiteAdmin || !!companyValues.hidden, "data-test": "company-dropdown" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null, localize(["Company.company"]))),
                React.createElement(CompanyDropdownContainer, { className: "mb-2 mb-md-0", selectedValue: companyValues.selectedvalue, groupId: groupValues.selectedvalue, onChange: handleChangeCompany, allowClear: (isDelphiSuperOrGroupUser ||
                        (isExternalUser && !isSingleGroup)) &&
                        companyValues.allowClear, siteAdminHandler: handleChangeSite, disabled: companyValues.disabled, haveDefaultCompanyHandler: isDelphieorSuperUser && companyValues.haveDefaultCompanyHandler, placeHolder: isReport
                        ? "Select a Company"
                        : !isEmpty(groupValues.selectedvalue) && !isCompanyAdmin
                            ? `All Companies`
                            : "Select a Company", error: companyValues.errorMessage }))),
        React.createElement("div", { className: `col-3 ${(_d = siteValues.wrapperClass) !== null && _d !== void 0 ? _d : ``}`, hidden: isSingleSiteAdmin || !!siteValues.hidden, "data-test": "site-dropdown" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null, localize(["Site.site"]))),
                React.createElement(SiteDropdownContainer, { className: "mb-2 mb-md-0", groupId: groupValues.selectedvalue, companyId: companyValues.selectedvalue, selectedValue: siteValues.selectedvalue, onChange: handleChangeSite, allowClear: siteValues.allowClear && !isSiteAdmin, disabled: siteValues.disabled, placeHolder: isReport
                        ? "Select a Site"
                        : !isSiteAdmin &&
                            (isCompanyAdmin
                                ? !isEmpty(companyValues.selectedvalue)
                                : !isEmpty(groupValues.selectedvalue))
                            ? `All Sites`
                            : "Select a Site", error: siteValues.errorMessage })))));
};
export default React.memo(FormPermissionDropdown);
