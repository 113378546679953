import React from "react";
import { Select, Tooltip } from "antd";
import UseToggleStateOnScroll from "../../../core/hooks/useToggleStateOnScroll";
const Option = Select.Option;
const SingleSelectNew = ({ selectedValue, options, valueProperty, textProperty, onChange, placeholder, defaultValue, style, className = "mb-lg-0 mb-4", disabled, labelInValue = false, allowClear = true, error = null, optionDivId = true, id = null, }) => {
    const optionFilter = (input, option) => {
        var _a, _b;
        return ((_b = (_a = option === null || option === void 0 ? void 0 : option.children) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.title.toString().toLowerCase().indexOf(input === null || input === void 0 ? void 0 : input.toLowerCase())) >= 0;
    };
    const handleChange = (value, e) => {
        onChange(value, e === null || e === void 0 ? void 0 : e.optiondata);
    };
    const { visible, setVisible } = UseToggleStateOnScroll();
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { "data-test": "single-select-new", labelInValue: labelInValue, allowClear: allowClear, disabled: disabled, showSearch: true, open: visible, onDropdownVisibleChange: setVisible, style: style, className: `${className} ${error ? `require-ant-select` : ``} `, defaultValue: defaultValue !== null && defaultValue !== void 0 ? defaultValue : undefined, value: selectedValue !== null && selectedValue !== void 0 ? selectedValue : undefined, placeholder: placeholder, optionFilterProp: "children", filterOption: optionFilter, onChange: handleChange, key: placeholder, id: id && id }, options === null || options === void 0 ? void 0 : options.map((option, key) => (React.createElement(Option, { key: option[valueProperty], value: option[valueProperty], disabled: !!(option === null || option === void 0 ? void 0 : option.optionDisabled), optiondata: option, id: optionDivId && `option-div-container-${option[textProperty].toLowerCase().replaceAll(" ", "-")}` },
            React.createElement(Tooltip, { placement: "top", 
                // color={!!option?.optionDisabled && "#2db7f5"}
                title: !!(option === null || option === void 0 ? void 0 : option.optionDisabled)
                    ? option.optionDisableMessage
                    : option[textProperty], overlayClassName: "dropdownTooltip" },
                React.createElement("div", { style: {
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }, id: optionDivId && "option-div" }, option[textProperty])))))),
        error && React.createElement("p", { className: "error" }, error)));
};
export default SingleSelectNew;
