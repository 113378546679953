import { httpDelete, httpGet, httpPost, httpPut } from "./request/requestApi";
const adminControllerEndpoint = "admin";
const companyEndpoint = "company";
const controllerEndpoint = "externalUser";
export class PendingUserApi {
    static getPendingUsers(companyId) {
        return httpGet(`${adminControllerEndpoint}/${companyEndpoint}/${companyId}/${controllerEndpoint}s`);
    }
    static inviteUser(data) {
        return httpPost(`${adminControllerEndpoint}/${controllerEndpoint}`, {
            body: data,
        });
    }
    static updatePendingUser(data) {
        return httpPut(`${adminControllerEndpoint}/${controllerEndpoint}`, {
            body: data,
        });
    }
    static deletePendingUser(id) {
        return httpDelete(`${adminControllerEndpoint}/${controllerEndpoint}/${id}`);
    }
    static resendInvitationLink(data) {
        return httpPost(`${adminControllerEndpoint}/${controllerEndpoint}/generateRegistrationLink`, {
            body: data,
        });
    }
}
