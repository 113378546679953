var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from "antd";
import React from "react";
import Header from "../../../../../layout/components/common/header";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { groupChanged, setContextCompany, } from "../../../../../redux/context/actions";
import { useHistory } from "react-router-dom";
import { Switch } from "antd";
import { GROUP_CREATION, GROUP_EDIT, } from "../../../../../core/constants/permissionConstants";
import { addGroupRoute, editGroupRoute, } from "../../../../../core/constants/routesPath";
import { notifications } from "../../../../../layout/components/notifications";
import localize from "../../../../../core/utils/translation-files";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import TableActions from "../../../../../layout/components/customTable/tableActions";
import GroupTable from "./components/groupTable";
import { activateGroup, inActivateGroup, getGroups, } from "../../../../../redux/group/actions";
import { DelphiGroupId } from "../../../../../core/constants/companyConstants";
import useDidMount from "../../../../../core/hooks/useDidMount";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import PrintButton from "../../../../../layout/components/common/PrintButton";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import ListingSearch from "../../../../../layout/components/listingSearch";
const GroupListingContainer = ({ groups, getGroups, activateGroup, inActivateGroup, groupChanged, setContextCompany, isPrintMode, }) => {
    var _a;
    const history = useHistory();
    const { handlePrint, nodeRef } = UsePrintPage();
    const _b = UseCustomPagination({
        totalRecords: (_a = groups === null || groups === void 0 ? void 0 : groups.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange"]);
    useDidMount(() => {
        if (groups.length === 0) {
            getGroups().catch((error) => {
                throw error;
            });
        }
    });
    const openEditModal = (groups, isReadOnly) => {
        history.push(`${editGroupRoute}${groups.id}`, {
            groupId: groups.id,
            isReadOnly,
        });
    };
    const activateGroupHandler = (data) => {
        activateGroup(data);
        notifications.success(localize(["Group.group", "enabled", "successfully"]));
    };
    const inActivateGroupHandler = (data) => {
        inActivateGroup(data);
        notifications.success(localize(["Group.group", "disabled", "successfully"]));
    };
    const groupsActions = (group) => {
        const actionArray = [
            {
                priority: 2,
                title: ["edit", "Group.group"],
                onClick: (event) => {
                    event.stopPropagation();
                    openEditModal(group, false);
                },
                svgIcon: "Edit",
                isAllowed: isActionAllowed([GROUP_EDIT], group),
            },
            {
                priority: 2,
                title: ["view", "Group.group"],
                onClick: (event) => {
                    event.stopPropagation();
                    openEditModal(group, true);
                },
                svgIcon: "View",
                isAllowed: !isActionAllowed([GROUP_EDIT], group),
            },
            {
                priority: 1,
                title: group.active
                    ? ["archive", "Group.group"]
                    : ["unArchive", "Group.group"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${group.active ? "Archive" : "unarchive"}`,
                    "Group.thisGroup?",
                ],
                onConfirm: (event) => {
                    event.stopPropagation();
                    group.active
                        ? inActivateGroupHandler(group.id)
                        : activateGroupHandler(group.id);
                },
                onCancel: (event) => {
                    event.stopPropagation();
                },
                isAllowed: isActionAllowed([GROUP_EDIT], group),
                children: (React.createElement(Switch, { className: `ml-1 ${group.id === DelphiGroupId ? `disableSwitch` : ``} `, checked: group.active, onChange: (checked, event) => event.stopPropagation() })),
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                React.createElement(ListingSearch, null),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Button, { type: "primary", onClick: () => history.push(addGroupRoute), hidden: !isActionAllowed([GROUP_CREATION]) },
                    React.createElement(RenderSvg, { icon: "AddGroup" }),
                    localize(["add", "Group.group"]))) }),
        React.createElement(GroupTable, { data: groups, actions: groupsActions, groupChanged: groupChanged, setContextCompany: setContextCompany, currentPage: restPaginationProps.currentPage, pageSize: restPaginationProps.pageSize, handleCustomTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps, isPrintMode: isPrintMode })));
};
function mapStateTopProps(state) {
    var _a;
    return {
        groups: state.groups,
        context: state.context,
        isPrintMode: (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status,
    };
}
const mapDispatchToProps = {
    getGroups,
    activateGroup,
    inActivateGroup,
    setContextCompany,
    groupChanged,
};
GroupListingContainer.propTypes = {
    groups: PropTypes.array.isRequired,
    getGroups: PropTypes.func.isRequired,
    activateGroup: PropTypes.func.isRequired,
    inActivateGroup: PropTypes.func.isRequired,
    setContextCompany: PropTypes.func.isRequired,
    groupChanged: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(GroupListingContainer);
