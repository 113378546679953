import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { getSearchInputSuccess, clearSearchInputSuccess, } from "../../../redux/listingSearchInput/actions";
import { debounce } from "../../../core/utils/function";
import { useHistory } from "react-router-dom";
const ListingSearch = ({ clientSide = true }) => {
    var _a;
    const dispatch = useDispatch();
    const history = useHistory();
    const queryParams = Object.assign({}, (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.queryParams);
    const searchValue = useSelector((state) => state === null || state === void 0 ? void 0 : state.searchInput);
    const [searchInput, setSearchInput] = useState((queryParams === null || queryParams === void 0 ? void 0 : queryParams.subject) || null);
    const handleSearch = (event) => {
        if (clientSide) {
            dispatch(getSearchInputSuccess(event.target.value));
        }
        else {
            setSearchInput((prev) => event.target.value);
            handleDelayedSearch(event.target.value, queryParams);
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDelayedSearch = useCallback(debounce((searchInput, queryParams) => {
        queryParams.subject = searchInput;
        queryParams.searchInput = searchInput;
        queryParams.page = 1;
        history.push(history.location.pathname, {
            queryParams,
        });
    }, 1000), [dispatch]);
    const searchInputValue = useMemo(() => (clientSide ? searchValue : searchInput), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue, searchInput]);
    useEffect(() => {
        return () => {
            dispatch(clearSearchInputSuccess());
        };
    }, [dispatch]);
    return (React.createElement("div", { className: "searchContainer" },
        React.createElement(Input, { id: "generic-search", placeholder: "Search", value: searchInputValue, onChange: handleSearch, allowClear: true, prefix: React.createElement(SearchOutlined, { style: { color: "#6E6B7B" } }) })));
};
export default ListingSearch;
