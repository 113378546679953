import React from "react";
import { BlockType, Side, } from "./numericColoredRanges";
import { Tooltip } from "antd";
export const NumericColoredRangeValueTooltip = ({ id, color, side, value, }) => {
    const to = getToValue(value, side, color);
    const from = getFromValue(value, side, color);
    if (from && to) {
        if (from.value !== undefined && to.value !== undefined) {
            return from.value < to.value
                ? getBothSidedTooltip(id, from.value, to.value, side, color)
                : null;
        }
        if (color === BlockType.DarkGreen) {
            if (to.value !== undefined && side === Side.Right) {
                return getRightDarkGreenTooltip(id, to.value);
            }
            if (from.value !== undefined && side === Side.Left) {
                return getLeftDarkGreenTooltip(id, from.value);
            }
        }
    }
    return null;
};
const getBothSidedTooltip = (id, from, to, side, color) => {
    return React.createElement(Tooltip, { id: id, overlay: "" });
};
const getLeftDarkGreenTooltip = (id, from) => {
    return React.createElement(Tooltip, { title: id });
};
const getRightDarkGreenTooltip = (id, to) => {
    return React.createElement(Tooltip, { title: id });
};
const getFromValue = (values, side, color) => {
    switch (color) {
        case BlockType.DarkGreen:
            return side === Side.Right
                ? getRightDarkGreenFromValue(values)
                : values.darkGreen.leftEdge;
        case BlockType.Green:
            return side === Side.Right
                ? values.darkGreen.rightEdge
                : values.green.leftEdge;
        case BlockType.Amber:
            return side === Side.Right
                ? getRightAmberFromValue(values)
                : values.orange.leftEdge;
        case BlockType.Red:
            return side === Side.Right
                ? getRightRedFromValue(values)
                : values.red.leftEdge;
    }
};
const getToValue = (values, side, color) => {
    switch (color) {
        case BlockType.DarkGreen:
            return side === Side.Left
                ? getLeftDarkGreenToValue(values)
                : values.darkGreen.rightEdge;
        case BlockType.Green:
            return side === Side.Left
                ? values.darkGreen.leftEdge
                : values.green.rightEdge;
        case BlockType.Amber:
            return side === Side.Left
                ? getLeftAmberToValue(values)
                : values.orange.rightEdge;
        case BlockType.Red:
            return side === Side.Left
                ? getLeftRedToValue(values)
                : values.red.rightEdge;
    }
};
const getLeftRedToValue = (value) => {
    return value.orange.leftEdge.doesExist
        ? value.orange.leftEdge
        : value.green.leftEdge.doesExist
            ? value.green.leftEdge
            : value.darkGreen.leftEdge;
};
const getLeftAmberToValue = (value) => {
    return value.green.leftEdge.doesExist
        ? value.green.leftEdge
        : value.darkGreen.leftEdge;
};
const getLeftDarkGreenToValue = (value) => {
    return value.darkGreen.rightEdge.value === undefined &&
        (value.green.rightEdge.doesExist ||
            value.orange.rightEdge.doesExist ||
            value.red.rightEdge.doesExist)
        ? undefined
        : value.darkGreen.rightEdge;
};
const getRightDarkGreenFromValue = (value) => {
    return value.darkGreen.leftEdge.value === undefined &&
        (value.green.leftEdge.doesExist ||
            value.orange.leftEdge.doesExist ||
            value.red.leftEdge.doesExist)
        ? undefined
        : value.darkGreen.leftEdge;
};
const getRightAmberFromValue = (value) => {
    return value.green.rightEdge.doesExist
        ? value.green.rightEdge
        : value.darkGreen.rightEdge;
};
const getRightRedFromValue = (value) => {
    return value.orange.rightEdge.doesExist
        ? value.orange.rightEdge
        : value.green.rightEdge.doesExist
            ? value.green.rightEdge
            : value.darkGreen.rightEdge;
};
