import React from "react";
import RoleForm from "./roleForm";
import CustomModal from "../../../../../layout/components/customModal";
function AddRole({ visible, onClose, onSubmit, 
// companies,
permissions, role, roles, lookupPermissionCategories, valuesRef, isPrintMode, }) {
    return (React.createElement("div", { "data-test": "addRoleComponent" },
        React.createElement(CustomModal, { visible: visible, maskClosable: false, onCancel: onClose, destroyOnClose: true, footer: null, title: ["create", "Role.role"], overflowY: false, width: "80%" },
            React.createElement("div", { "data-test": "roleFormComponent" },
                React.createElement(RoleForm, { onSubmit: onSubmit, onCancel: onClose, lookupPermissionCategories: lookupPermissionCategories, role: role, permissions: permissions, roles: roles, isPrintMode: isPrintMode, valuesRef: valuesRef })))));
}
export default AddRole;
