import React from "react";
import { Checkbox, Input } from "antd";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import getFrequencyData from "../../../../../../../core/utils/getDataFrequency";
import localize from "../../../../../../../core/utils/translation-files";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import Asterik from "../../../../../shared/helper-components/Asterik";
export const MovingAveragesAndProjections = ({ setFieldValue, values, handleBlur, handleChange, errors, touched, isSystemCreated, handlSetFieldValue, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "box-main-section" },
            React.createElement("section", { className: "box-section" },
                React.createElement("div", { className: "box-title" },
                    React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.movingAverage"]))),
                React.createElement("div", { className: "box-description" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.dataCollectionFrequency"]),
                                        " ",
                                        React.createElement(Asterik, null))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.dataFrequency, inputName: "dataFrequency", localizationKey: ["TrackedItem.dataCollectionFrequency"], lookUpType: LookupNames.LKReportTypeDataFrequency, onChange: setFieldValue, disabled: values.id > 0, error: errors.dataFrequency && touched.dataFrequency
                                        ? errors.dataFrequency
                                        : null }))),
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.shortTerm"]),
                                        " ",
                                        React.createElement(Asterik, null))),
                                React.createElement(Input, { type: "number", name: "shortTermMovingAveragePeriod", placeholder: localize(["TrackedItem.shortTerm"]), onBlur: handleBlur, onChange: handlSetFieldValue, value: values.shortTermMovingAveragePeriod, className: `${errors.shortTermMovingAveragePeriod &&
                                        touched.shortTermMovingAveragePeriod
                                        ? "input-error"
                                        : null}`, suffix: getFrequencyData(values.dataFrequency) }),
                                errors.shortTermMovingAveragePeriod &&
                                    touched.shortTermMovingAveragePeriod && (React.createElement("p", { className: "error" }, errors.shortTermMovingAveragePeriod)))),
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.mediumTerm"]),
                                        " ",
                                        React.createElement(Asterik, null))),
                                React.createElement(Input, { type: "number", name: "midTermMovingAverage", placeholder: localize(["TrackedItem.mediumTerm"]), onBlur: handleBlur, onChange: handlSetFieldValue, value: values.midTermMovingAverage, className: `${errors.midTermMovingAverage &&
                                        touched.midTermMovingAverage
                                        ? "input-error"
                                        : null}`, suffix: getFrequencyData(values.dataFrequency) }),
                                errors.midTermMovingAverage &&
                                    touched.midTermMovingAverage && (React.createElement("p", { className: "error" }, errors.midTermMovingAverage)))),
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.longTerm"]),
                                        " ",
                                        React.createElement(Asterik, null))),
                                React.createElement(Input, { type: "number", name: "longTermMovingAverage", placeholder: localize(["TrackedItem.longTerm"]), onBlur: handleBlur, onChange: handlSetFieldValue, value: values.longTermMovingAverage, className: `${errors.longTermMovingAverage &&
                                        touched.longTermMovingAverage
                                        ? "input-error"
                                        : null}`, suffix: getFrequencyData(values.dataFrequency) }),
                                errors.longTermMovingAverage &&
                                    touched.longTermMovingAverage && (React.createElement("p", { className: "error" }, errors.longTermMovingAverage)))))))),
        React.createElement("div", { className: "box-main-section" },
            React.createElement("section", { className: "box-section", hidden: values.id > 0 && !values.projectionRequired },
                React.createElement("div", { className: "box-title" },
                    React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.projections"]))),
                React.createElement("div", { className: "box-description" },
                    React.createElement("div", { className: "row", hidden: isSystemCreated },
                        React.createElement("div", { className: "col-3" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null, localize([
                                        "TrackedItem.projection",
                                        "TrackedItem.required",
                                    ]))),
                                React.createElement(Checkbox, { name: "projectionRequired", checked: values.projectionRequired, onChange: handleChange, disabled: values.id > 0 })))),
                    React.createElement("div", { className: "row" }, values.projectionRequired && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize([
                                            "TrackedItem.projection",
                                            "TrackedItem.movingAverage",
                                        ]),
                                        React.createElement(Asterik, null))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.projectionAverageUsed, inputName: "projectionAverageUsed", onChange: setFieldValue, localizationKey: [
                                        "TrackedItem.projection",
                                        "TrackedItem.movingAverage",
                                    ], lookUpType: LookupNames.LKReportTypeProjectionAverage, error: errors.projectionAverageUsed &&
                                        touched.projectionAverageUsed
                                        ? errors.projectionAverageUsed
                                        : null }))),
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize([
                                            "TrackedItem.projection",
                                            "TrackedItem.point",
                                        ]),
                                        React.createElement(Asterik, null))),
                                React.createElement(Input, { type: "number", name: "projectionPoint", placeholder: localize([
                                        "TrackedItem.projection",
                                        "TrackedItem.point",
                                    ]), onBlur: handleBlur, onChange: handlSetFieldValue, value: values.projectionPoint, className: `${errors.projectionPoint && touched.projectionPoint
                                        ? "input-error"
                                        : null}`, suffix: getFrequencyData(values.dataFrequency) }),
                                errors.projectionPoint && touched.projectionPoint && (React.createElement("p", { className: "error" }, errors.projectionPoint)))),
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.reactionTime"]),
                                        " ",
                                        React.createElement(Asterik, null))),
                                React.createElement(Input, { type: "number", name: "reactionTime", placeholder: localize(["TrackedItem.reactionTime"]), onBlur: handleBlur, onChange: handlSetFieldValue, value: values.reactionTime, className: `${errors.reactionTime && touched.reactionTime
                                        ? "input-error"
                                        : null}`, suffix: "Days" }),
                                errors.reactionTime && touched.reactionTime && (React.createElement("p", { className: "error" }, errors.reactionTime))))))))))));
};
export default MovingAveragesAndProjections;
