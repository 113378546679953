import React from "react";
import "./logo.scss";
const Logo = ({ alt = "", height = "auto", width = "auto", src, style = {}, logoText, className = "", }) => {
    return (React.createElement("div", { className: "logo", style: style },
        React.createElement("img", { src: src, alt: alt, width: width, height: height, className: `${className} logo-img` }),
        React.createElement("span", { className: "ml-2" },
            " ",
            logoText)));
};
export default Logo;
