import { DASHBOARD_VIEWER, GROUP_CREATION, SITE_VIEW, } from "./../constants/permissionConstants";
import { groupsRoute, homeRoute, loginRoute, unAuthorizedRoute, usersRoute, watchlistRoute, } from "./../constants/routesPath";
import { EXTERNAL_USER } from "../constants/permissionConstants";
export class UserDashboardRoute {
    static getRouteByPermissions(context) {
        var _a;
        if (!(context === null || context === void 0 ? void 0 : context.isAuthenticated))
            return loginRoute;
        const userPermissions = (_a = context === null || context === void 0 ? void 0 : context.companyPermissionDTOs) === null || _a === void 0 ? void 0 : _a.map(({ permission }) => permission);
        if (userPermissions) {
            if ((userPermissions.length === 0 ||
                (userPermissions.length === 1 &&
                    userPermissions[0] === EXTERNAL_USER)) &&
                context.isAuthenticated) {
                return unAuthorizedRoute;
            }
            if (userPermissions.some((x) => [DASHBOARD_VIEWER].includes(x))) {
                return watchlistRoute;
            }
            if (userPermissions.some((x) => [GROUP_CREATION].includes(x))) {
                return groupsRoute;
            }
            if (userPermissions.some((x) => [SITE_VIEW].includes(x))) {
                return usersRoute;
            }
            if (userPermissions.length > 1) {
                return homeRoute;
            }
        }
        return unAuthorizedRoute;
    }
}
