import { httpGet, httpPost, httpPut } from "./request/requestApi";
const controllerEndpoint = "admin/group";
export class GroupApi {
    static getGroups() {
        return httpGet(controllerEndpoint);
    }
    static createGroup(data) {
        return httpPost(controllerEndpoint, { body: data });
    }
    static updateGroup(data) {
        return httpPut(`${controllerEndpoint}`, { body: data });
    }
    static getSingleGroup(id) {
        return httpGet(`${controllerEndpoint}/${id}`);
    }
    static getGroupDropdownOptions(isSuperorDelphie, isExternal) {
        return httpGet(isSuperorDelphie
            ? `admin/groupUserCreation`
            : isExternal
                ? "admin/allowedBaseCompanies"
                : `admin/allowedCompaniesWithSites`);
    }
    static inActivateGroup(id) {
        return httpPost(`${controllerEndpoint}/inactive/${id}`, {});
    }
    static activateGroup(id) {
        return httpPost(`${controllerEndpoint}/active/${id}`, {});
    }
}
