import { LookupApi } from "./../../core/api/lookupApi";
import { ADD_LOOKUP_SUCCESS, DELETE_LOOKUP_SUCCESS, GET_LOOKUPS_SUCCESS, GET_LOOKUP_SUCCESS, UPDATE_LOOKUP_SUCCESS, } from "./types";
import { LookupTypes } from "../../core/constants/lookup";
import isEmpty from "../../core/helpers/isEmpty";
export const getLookupSuccess = (data, lookupType) => ({
    type: GET_LOOKUP_SUCCESS,
    payload: data,
    lookupType: lookupType,
});
export const getLookupsSuccess = (data) => ({
    type: GET_LOOKUPS_SUCCESS,
    payload: data,
});
export const addLookupSuccess = (data, lookupType) => ({
    type: ADD_LOOKUP_SUCCESS,
    payload: data,
    lookupType: lookupType,
});
export const updateLookupSuccess = (data, lookupType) => ({
    type: UPDATE_LOOKUP_SUCCESS,
    payload: data,
    lookupType: lookupType,
});
export const deleteLookupSuccess = (data, lookupType) => ({
    type: DELETE_LOOKUP_SUCCESS,
    payload: data,
    lookupType: lookupType,
});
export function getLookup(lookupType) {
    return function (dispatch) {
        return LookupApi.getLookupData(lookupType)
            .then((lookupdata) => {
            if ((lookupdata === null || lookupdata === void 0 ? void 0 : lookupdata.length) > 0) {
                dispatch(getLookupSuccess(lookupdata, lookupType));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getLookups(lookupsNames) {
    return function (dispatch) {
        return LookupApi.getLookupList(lookupsNames)
            .then((lookupdata) => {
            !isEmpty(lookupdata) && dispatch(getLookupsSuccess(lookupdata));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getLookupTypes() {
    return function (dispatch) {
        return LookupApi.getLookupTypes()
            .then((lookupTypes) => {
            if ((lookupTypes === null || lookupTypes === void 0 ? void 0 : lookupTypes.length) > 0) {
                dispatch(getLookupSuccess(lookupTypes, LookupTypes));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function addLookup(data, lookupType) {
    return function (dispatch) {
        return LookupApi.createLookup(data)
            .then((lookupdata) => {
            if (!lookupdata.message) {
                dispatch(addLookupSuccess(lookupdata, lookupType));
            }
            return lookupdata;
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function editLookup(data, lookupType) {
    return function (dispatch) {
        return LookupApi.updateLookup(data)
            .then((lookupdata) => {
            if (!lookupdata.message) {
                dispatch(updateLookupSuccess(lookupdata, lookupType));
            }
            return lookupdata;
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function deleteLookup(id, lookupType) {
    return function (dispatch) {
        return LookupApi.deleteLookup(id)
            .then((lookupdata) => {
            dispatch(deleteLookupSuccess(id, lookupType));
            return lookupdata;
        })
            .catch((error) => {
            throw error;
        });
    };
}
