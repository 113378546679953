import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";
const PromptComponent = ({ isPrompt = false }) => {
    useEffect(() => {
        if (isPrompt) {
            window.addEventListener("beforeunload", alertUser);
        }
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, [isPrompt]);
    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Prompt, { when: isPrompt, message: () => "Changes you made may not be saved." })));
};
export default PromptComponent;
