import React from "react";
import SingleSelectNew from "./SingleSelectNew";
import { connect } from "react-redux";
import { LookupTypes } from "../../../core/constants/lookup";
import { getLookupTypes } from "../../../redux/lookup/actions";
const LookupTypesDropdownContainer = ({ onChange, selectedValue, className, disabled, lookupTypes, getLookupTypes, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { allowClear: false, "data-test": "lookup-type-dropdown", options: lookupTypes, selectedValue: selectedValue, onChange: onChange, valueProperty: "id", textProperty: "name", placeholder: "Select a Lookup Type", key: "lookupSelect", className: className, disabled: disabled, labelInValue: true })));
};
function mapStateTopProps(state, ownProps) {
    var _a;
    return {
        lookupTypes: (_a = state.lookups[LookupTypes]) !== null && _a !== void 0 ? _a : [],
        onChange: ownProps.onChange,
        selectedValue: ownProps.selectedValue,
    };
}
const mapDispatchToProps = {
    getLookupTypes,
};
LookupTypesDropdownContainer.propTypes = {};
export default connect(mapStateTopProps, mapDispatchToProps)(LookupTypesDropdownContainer);
