import React, { useState } from "react";
import { MemoryRouter as Router, Route, Switch, useHistory, useLocation, } from "react-router-dom";
import { filterRoute, getBreadCrumbSession, } from "../../../core/helpers/localStorage/breadCrumbSession";
import { BrowserHistoryMemoryContextProvider } from "../../../core/contexts/browserHistoryMemoryContext";
import { dashboardRoute } from "../../../core/constants/routesPath";
const CustomMemoryRouter = ({ routes = [] }) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const [preservePaths] = useState(() => {
        var _a;
        return [...Array((_a = getBreadCrumbSession(pathname)) === null || _a === void 0 ? void 0 : _a.length)].map((_, index) => `${filterRoute(index) !== dashboardRoute ? dashboardRoute : ""}${filterRoute(index)}`);
    });
    return (React.createElement(BrowserHistoryMemoryContextProvider, { browserHistory: history },
        React.createElement(Router, { initialIndex: preservePaths.length - 1, initialEntries: preservePaths },
            React.createElement(Switch, null, routes.map(({ path, component }) => (React.createElement(Route, { exact: true, path: `${pathname}/${path}`, component: component })))))));
};
export default CustomMemoryRouter;
