var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import CustomTable from "../../../../../layout/components/customTable";
import { actionColumnFilterOptions } from "../../../../../core/constants/actionColumnFilterOptions";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import CustomTextHighlighter from "../../../../../layout/components/customTextHighlighter";
const ClientNotificationsTable = ({ data, actions, isPrintMode = null }) => {
    var _a;
    const _b = UseCustomPagination({
        totalRecords: (_a = data === null || data === void 0 ? void 0 : data.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange"]);
    const columns = [
        {
            fieldName: "name",
            title: ["Notification.notification", "Notification.event"],
            sortable: true,
            defaultSortOrder: "ascend",
            width: 160,
            className: "bold-text",
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "groupName",
            title: ["Group.group"],
            width: 170,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "companyName",
            title: ["Company.company"],
            width: 170,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "siteName",
            title: ["Site.site"],
            width: 170,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "referenceTypeText",
            title: ["Notification.category"],
            sortable: true,
            enableFilter: true,
            width: 170,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        enableFilter: true,
        filterInfo: {
            listing: actionColumnFilterOptions,
            nestedKey: "enabled",
        },
        render: actions,
        width: 140,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { "data-test": "local-notifications-listing-table", className: "customListing" },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: "any", currentPage: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.currentPage, pageSize: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.pageSize, totalCount: restPaginationProps === null || restPaginationProps === void 0 ? void 0 : restPaginationProps.totalRecords, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps })));
};
export default ClientNotificationsTable;
