import React, { useImperativeHandle } from "react";
import { Button, Input } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import localize from "../../../../../core/utils/translation-files";
import { inputDefaultLength } from "../../../../../core/utils/default-length";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import { LOOKUP_CREATION, LOOKUP_EDIT, } from "../../../../../core/constants/permissionConstants";
import PromptComponent from "../../../../../layout/components/prompt-message";
import Asterik from "../../../shared/helper-components/Asterik";
const defaultSubmitText = "Save";
const LookupForm = ({ submitText = defaultSubmitText, onSubmit, onCancel, isEdit, lookup, valuesRef, }) => {
    const { handleSubmit, handleChange, handleBlur, setErrors, values, errors, touched, dirty, resetForm, } = useFormik({
        validationSchema: Yup.object({
            text: Yup.string()
                .trim()
                .matches(/^[a-zA-Z0-9 ]*$/, localize(["special", "characters", "notAllowed!"]))
                .required(localize(["Lookup.lookup", "Lookup.text", "isRequired!"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            code: Yup.string().when("codeType", {
                is: "NUMBER",
                then: Yup.string()
                    .trim()
                    .matches(/^[0-9]*$/, localize(["Lookup.onlyNumbers", "areAllowed!"]))
                    .required(localize(["code", "isRequired!"]))
                    .max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
                otherwise: Yup.string()
                    .trim()
                    .matches(
                // /^[a-zA-Z0-9]*$/
                /^[a-z\d\-_\s]+$/i, localize(["special", "characters", "notAllowed!"]))
                    .required(localize(["code", "isRequired!"]))
                    .max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
            }),
        }),
        initialValues: lookup,
        onSubmit: (values) => {
            onSubmit(values, resetForm, setErrors);
        },
    });
    useImperativeHandle(valuesRef, () => {
        return {
            isPrompt: dirty,
        };
    }, [dirty]);
    const handleCancel = () => {
        onCancel(resetForm);
    };
    const { handlePrint, nodeRef } = UsePrintPage(false);
    const isUserHaveOnlyViewPermission = !isActionAllowed([
        LOOKUP_CREATION,
        LOOKUP_EDIT,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: "mb-1 col-12 customForm", "data-test": "lookupFormComponent", name: "basic", onSubmit: handleSubmit, ref: nodeRef },
            React.createElement("h3", { className: "d-print page-title", hidden: isUserHaveOnlyViewPermission }, localize([isEdit ? "edit" : "add", "Lookup.lookupValue"])),
            React.createElement(Input, { name: "lookupid", type: "hidden" }),
            React.createElement(Input, { name: "systemCreated", type: "hidden" }),
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        localize(["code"]),
                        " ",
                        React.createElement(Asterik, null))),
                React.createElement(Input, { disabled: isEdit, type: "text", name: "code", placeholder: localize(["code"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.code, className: `${errors.code && touched.code ? "input-error" : null}` }),
                errors.code && touched.code && (React.createElement("p", { className: "error" }, errors.code))),
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        localize(["Lookup.text"]),
                        " ",
                        React.createElement(Asterik, null))),
                React.createElement(Input, { type: "text", name: "text", placeholder: localize(["Lookup.text"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.text, className: `${errors.text && touched.text ? "input-error" : null}`, disabled: isUserHaveOnlyViewPermission }),
                errors.text && touched.text && (React.createElement("p", { className: "error" }, errors.text))),
            React.createElement("div", { className: "d-flex  settings-actions d-print-none" },
                React.createElement(Button, { type: "default", onClick: handlePrint },
                    React.createElement("span", { className: "icofont icofont-printer mr-2", style: { fontSize: "1.3em" } }),
                    localize(["print"])),
                React.createElement(Button, { type: "default", onClick: handleCancel, className: "ml-auto mr-2" },
                    React.createElement("span", { className: "icofont icofont-close-circled mr-2", style: { fontSize: "1.2em" } }),
                    localize(["Button.cancel"])),
                React.createElement(Button, { type: "primary", htmlType: "submit", hidden: isUserHaveOnlyViewPermission || !dirty },
                    React.createElement("span", { className: "icofont icofont-save mr-2", style: { fontSize: "1em" } }),
                    localize(["Button.save"]))))));
};
export default LookupForm;
