import { useEffect } from "react";
import { ApiError, isExpected } from "../../../core/api/request/apiErrors";
import React from "react";
import { ErrorCode } from "../../../typings/apiErrorCodes";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../redux/context/actions";
import { notifications } from "../notifications";
export const ApiErrorHandler = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        window.addEventListener("unhandledrejection", onUnhandledRejection);
        return () => window.removeEventListener("unhandledrejection", onUnhandledRejection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onUnhandledRejection = (event) => {
        const error = event.reason;
        if (error instanceof ApiError) {
            error.code = error.response.status.toString();
            handleApiError(error);
        }
        else if (error.message === "Failed to fetch") {
            notifications.error("Oops! Something went wrong!");
        }
    };
    const handleApiError = (error) => {
        if (error.response.status === 401) {
            handleUnauthorized();
        }
        if (isExpected(error, [ErrorCode.ConcurrentModification])) {
            handleOptimisticLockException();
            return;
        }
        if (isExpected(error, [ErrorCode.UnhandledException])) {
            handleUnhandledException(error);
            return;
        }
        return;
    };
    const handleUnauthorized = () => {
        dispatch(logoutActions(history));
        return;
    };
    const handleOptimisticLockException = () => {
        // notifications.errorCode(ErrorCode.ConcurrentModification);
    };
    const handleUnhandledException = (error) => {
        // notifications.error(ErrorCode.UnhandledException);
    };
    return React.createElement(React.Fragment, null, children);
};
