var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import CompanyForm from "../components/companyForm";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { editCompany, getCompanies, getCompaniesByGroup, } from "../../../../../redux/company/actions";
import { notifications } from "../../../../../layout/components/notifications";
import { useHistory, useParams } from "react-router-dom";
import { companiesRoute } from "../../../../../core/constants/routesPath";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import localize from "../../../../../core/utils/translation-files";
import { CompanyApi } from "../../../../../core/api/companyApi";
const EditCompany = ({ companies, getCompanies, editCompany, getCompaniesByGroup, }) => {
    var _a, _b, _c;
    const history = useHistory();
    const params = useParams();
    const [selectedCompany, setSelectedCompany] = useState(null);
    useEffect(() => {
        var _a;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if ((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.companyId) {
            CompanyApi.getSingleCompany(params.id).then((res) => {
                var _a;
                setSelectedCompany(Object.assign(Object.assign({}, res), { externalReference: (_a = res === null || res === void 0 ? void 0 : res.externalReference) === null || _a === void 0 ? void 0 : _a.toString() }));
            });
            if (companies.length === 0) {
                loadCompanies();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadCompanies = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getCompanies().catch((error) => {
            throw error;
        });
    });
    const editCompanyHandler = (company, resetForm, setErrors) => {
        editCompany(company).then((response) => {
            if (response &&
                (response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION ||
                    response.code === ResponseStatusCodes.VALIDATION_ERROR)) {
                setErrors({
                    [response.errorField]: response.message,
                });
            }
            else {
                resetForm();
                notifications.success(localize(["Company.company", "updated", "successfully"]));
                history.push(companiesRoute);
            }
        });
    };
    if (!((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.companyId)) {
        history.push(companiesRoute);
    }
    return (React.createElement(React.Fragment, null, selectedCompany !== null && (React.createElement(CompanyForm, { onCancel: () => history.push(companiesRoute), onSubmit: editCompanyHandler, company: selectedCompany, submitText: "Save", isEdit: true, companies: companies, isReadOnly: (_c = (_b = history.location) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.isReadOnly }))));
};
function mapStateTopProps(state) {
    return {
        companies: state.companies,
    };
}
const mapDispatchToProps = {
    editCompany,
    getCompanies,
    getCompaniesByGroup,
};
EditCompany.propTypes = {
    companies: PropTypes.array.isRequired,
    editCompany: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getCompaniesByGroup: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(EditCompany);
