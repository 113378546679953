import React from "react";
import { LookupResponse } from "../../../../../core/contracts/lookup/lookupContracts";
import CustomTable from "../../../../../layout/components/customTable";
import CustomTextHighlighter from "../../../../../layout/components/customTextHighlighter";
export const LookupTable = ({ data, actions, currentPage, pageSize, handleCustomTablePropChange = null, isPrintMode = false, paginationSection = null, }) => {
    const columns = [
        {
            fieldName: "code",
            title: ["code"],
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "text",
            title: ["Lookup.text"],
            sortable: true,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        render: actions,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "lookup-listing-table" },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: LookupResponse, 
            // scrollPosition={defaultTrackedItemTypeScrollPosition}
            currentPage: currentPage, pageSize: pageSize, totalCount: data === null || data === void 0 ? void 0 : data.length, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: paginationSection })));
};
