import { NotificationApi } from "../../../core/api/notificationApi";
import isEmpty from "../../../core/helpers/isEmpty";
import { ADD_CLIENT_NOTIFICATION_SUCCESS, DISABLE_CLIENT_NOTIFICATION_SUCCESS, ENABLE_CLIENT_NOTIFICATION_SUCCESS, GET_CLIENT_NOTIFICATION_SUCCESS, RESET_CLIENT_NOTIFICATION_SUCCESS, UPDATE_CLIENT_NOTIFICATION_SUCCESS, } from "./types";
export const enableClientNotificationSuccess = (data) => ({
    type: ENABLE_CLIENT_NOTIFICATION_SUCCESS,
    payload: data,
});
export const disableClientNotificationSuccess = (data) => ({
    type: DISABLE_CLIENT_NOTIFICATION_SUCCESS,
    payload: data,
});
export const getClientNotificationSuccess = (data) => ({
    type: GET_CLIENT_NOTIFICATION_SUCCESS,
    payload: data,
});
export const addClientNotificationSuccess = (data) => ({
    type: ADD_CLIENT_NOTIFICATION_SUCCESS,
    payload: data,
});
export const resetClientNotificationSuccess = (data) => ({
    type: RESET_CLIENT_NOTIFICATION_SUCCESS,
    payload: data,
});
export const updateClientNotificationSuccess = (data) => ({
    type: UPDATE_CLIENT_NOTIFICATION_SUCCESS,
    payload: data,
});
export function getClientNotification(groupId, companyId, siteId, categoryType) {
    return function (dispatch) {
        return NotificationApi.getClientNotifications(groupId, companyId, siteId, categoryType)
            .then((response) => {
            if (Array.isArray(response)) {
                dispatch(getClientNotificationSuccess(response));
                return response;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
export function addClientNotification(notification, shouldConcatClientState) {
    return function (dispatch) {
        return NotificationApi.saveClientNotification(notification)
            .then((response) => {
            if (!isEmpty(response) && !(response === null || response === void 0 ? void 0 : response.code)) {
                if (shouldConcatClientState) {
                    const dispatchAction = notification.id
                        ? updateClientNotificationSuccess
                        : addClientNotificationSuccess;
                    dispatch(dispatchAction(response.data));
                }
                return response;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
export function editClientNotification(notification) {
    return function (dispatch) {
        return NotificationApi.editClientNotification(notification)
            .then((response) => {
            if (!isEmpty(response) && !(response === null || response === void 0 ? void 0 : response.code)) {
                dispatch(updateClientNotificationSuccess(response));
                return response;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
export function disableClientNotification(data) {
    return function (dispatch) {
        return NotificationApi.inActivateClientNotification(data)
            .then((response) => {
            if (!isEmpty(response) && !(response === null || response === void 0 ? void 0 : response.code)) {
                dispatch(disableClientNotificationSuccess(data));
                return response;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function enableClientNotification(data) {
    return function (dispatch) {
        return NotificationApi.activateClientNotification(data)
            .then((response) => {
            if (!isEmpty(response) && !(response === null || response === void 0 ? void 0 : response.code)) {
                dispatch(enableClientNotificationSuccess(data));
                return response;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function resetDefaultClientNotifications(data) {
    return function (dispatch) {
        return NotificationApi.resetDefaultClientNotifications(data)
            .then((response) => {
            if (!(response === null || response === void 0 ? void 0 : response.code)) {
                dispatch(resetClientNotificationSuccess(data));
                return true;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
