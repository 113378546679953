import React, { useEffect, useState } from "react";
import { TrackedItemApi } from "../../../../../../../core/api/trackedItemApi";
import ExportIndividualItemModal from "./exportIndividualItemModal";
import ExportItemsModal from "./exportItemsModal";
export default function ExportItemsContainer({ exportItemsModelVisibility, handleToggleExportModalVisibilty, exportItemToggle = null, handleToggleIndividualModalVisibilty, exportIndividualModelVisibility, actualDataExportHandle, chartVariables, trackedItemId, selectedFilterValue, selectedRange, }) {
    const [itemRelations, setItemRelations] = useState(undefined);
    useEffect(() => {
        if ((chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeId) && (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.companyId)) {
            TrackedItemApi.getAssociatedTrackItemByDefaults(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeId, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.companyId).then((res) => {
                setItemRelations(res);
            });
        }
    }, [chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeId, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.companyId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ExportItemsModal, { visible: exportItemsModelVisibility, onClose: handleToggleExportModalVisibilty, exportItemToggle: exportItemToggle, relatedItems: itemRelations, actualDataExportHandle: actualDataExportHandle, chartVariables: chartVariables, trackedItemId: trackedItemId, selectedFilterValue: selectedFilterValue, selectedRange: selectedRange }),
        React.createElement(ExportIndividualItemModal, { relatedItems: itemRelations, visible: exportIndividualModelVisibility, onClose: handleToggleIndividualModalVisibilty, actualDataExportHandle: actualDataExportHandle, chartVariables: chartVariables, trackedItemId: trackedItemId, selectedFilterValue: selectedFilterValue, selectedRange: selectedRange })));
}
