import React from "react";
import className from "../../core/utils/class-names";
import "./BaseLayout.scss";
const BaseLayout = ({ nav, topNav, sideNav, topNavBar, orientation, children, isSideNaveHidden = false, }) => {
    const mainContentClasses = className({
        "main-content": true,
        loaded: true,
        fulfilled: true,
    });
    const mainContentWrapClasses = className({
        "main-content-wrap": true,
    });
    const appContainerClasses = className({
        "app-container": true,
        "custom-padding": isSideNaveHidden,
    });
    return (React.createElement("div", { className: `layout ${orientation}` },
        React.createElement("div", { className: appContainerClasses },
            nav,
            topNav,
            React.createElement("main", { className: mainContentClasses },
                React.createElement("div", { className: mainContentWrapClasses }, children)),
            sideNav)));
};
export default BaseLayout;
