export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USERUPDATE_SUCCESS";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const INACTIVATE_USER_SUCCESS = "INACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const LOCK_USER_SUCCESS = "LOCK_USER_SUCCESS";
export const UNLOCK_USER_SUCCESS = "UNLOCK_USER_SUCCESS";
export const CLEAR_USERS_LISTING_SUCCESS = "CLEAR_USERS_LISTING_SUCCESS";
// | clearUserListingSuccessAction;
