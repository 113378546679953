import React, { useContext } from "react";
import { actionColumnFilterOptions } from "../../../../../../../../core/constants/actionColumnFilterOptions";
import { TrackedItemTypeResponse } from "../../../../../../../../core/contracts/trackedItem/trackedItemContracts";
import { printContext } from "../../../../../../../../core/contexts/printContext";
import CustomTable from "../../../../../../../../layout/components/customTable";
import CustomTextHighlighter from "../../../../../../../../layout/components/customTextHighlighter";
export const UserCreatedtrackedItemsTypeTable = ({ data, actions, benchmarkListOptions, currentPage, pageSize, handleCustomTablePropChange = null, isPrintMode = false, paginationSection = null, }) => {
    const { nodeRef } = useContext(printContext);
    const columns = [
        {
            fieldName: "name",
            title: ["name"],
            className: "bold-text",
            sortable: true,
            // searchable: true,
            defaultSortOrder: "ascend",
            width: 300,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "weightText",
            title: ["TrackedItem.weight"],
            filter: true,
            width: 100,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "itemCategoryText",
            title: ["TrackedItem.category"],
            sortable: true,
            enableFilter: true,
            width: 130,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "itemSubCategoryText",
            title: ["TrackedItem.subCategory"],
            sortable: true,
            enableFilter: true,
            width: 200,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "benchmarkList",
            title: ["TrackedItem.benchmarks"],
            sortable: true,
            enableFilter: true,
            filterInfo: {
                nestedKey: "benchmarkName",
                listing: benchmarkListOptions,
            },
            width: 130,
            render: (benchmarkList) => (React.createElement(CustomTextHighlighter, { text: benchmarkList === null || benchmarkList === void 0 ? void 0 : benchmarkList.map((x) => x.benchmarkName).toString() })),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        enableFilter: true,
        filterInfo: {
            //disabled is true in current data source DTO
            listing: actionColumnFilterOptions.map(({ text, value }) => ({
                text,
                value: !value,
            })),
            nestedKey: "disabled",
        },
        render: actions,
        width: 100,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", ref: nodeRef },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: TrackedItemTypeResponse, 
            // scrollPosition={defaultTrackedItemTypeScrollPosition}
            itemEnableKey: "disabled", currentPage: currentPage, pageSize: pageSize, totalCount: paginationSection === null || paginationSection === void 0 ? void 0 : paginationSection.totalRecords, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: paginationSection })));
};
