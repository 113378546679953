import { defaultDateFormat, defaultDateFormatFinancialData, defaultDateFormatForMessages, defaultDateFormatForTinyChart, defaultDateTimeFormat, defaultDateTimeFormatForFile, defaultFullDateTimeFormat, } from "./../constants/DateTime";
import moment from "moment";
import isEmpty from "./isEmpty";
export const formatDateTime = (date) => {
    if (isEmpty(date))
        return "";
    return moment(new Date(date.toString())).format(defaultDateTimeFormat);
};
export const formatDateTimeForFile = (date) => {
    if (isEmpty(date))
        return "";
    return moment(new Date(date.toString())).format(defaultDateTimeFormatForFile);
};
export const formatDate = (date) => {
    if (isEmpty(date))
        return "";
    return moment(new Date(date.toString())).format(defaultDateFormat);
};
export const formatDateUK = (date, isSummary) => {
    if (isEmpty(date))
        return "";
    return moment(date, isSummary ? defaultDateFormatForTinyChart : "DD-MM-YYYY").format(defaultDateFormatFinancialData);
};
export const formatChartDate = (value, dateFormat = "DD-MMM") => {
    let format = moment(value).format(dateFormat);
    return format;
};
export const getMessagesDateAndTimeString = (date) => {
    if (isEmpty(date)) {
        return null;
    }
    return moment.utc(date).local().format(defaultDateFormatForMessages);
};
export const getMessagesDateAndTimeStringGlobal = (date) => {
    if (isEmpty(date)) {
        return null;
    }
    return moment.utc(date).format(defaultDateTimeFormat);
};
export const getLocalDateString = (date) => {
    if (isEmpty(date)) {
        return null;
    }
    return moment.utc(date).local().format(defaultDateTimeFormat);
};
export const getLocalDateAndTimeString = (date) => {
    if (isEmpty(date)) {
        return null;
    }
    return moment.utc(date).local().format(defaultFullDateTimeFormat);
};
export const getLocalDate = (date, convertToLocal = true) => {
    if (isEmpty(date)) {
        return null;
    }
    if (convertToLocal) {
        if (date.toString().toLowerCase().indexOf("z") > 0)
            return moment.utc(date).local();
        return moment.utc(new Date(date.toString() + "Z")).local();
    }
    return moment.utc(date._d);
};
export const validateStartAndEndDate = (siteId, startDate, endDate) => {
    const isSameOrBefore = siteId &&
        startDate &&
        moment(endDate, defaultDateTimeFormat).isSameOrBefore(moment(startDate, defaultDateTimeFormat))
        ? false
        : true;
    return isSameOrBefore;
};
export const convertDateToLocal = (date) => {
    if (isEmpty(date)) {
        return new Date();
    }
    if (date.toString().toLowerCase().indexOf("z") > 0)
        return moment.utc(date).local().toDate();
    return moment
        .utc(new Date(date.toString() + "Z"))
        .local()
        .toDate();
};
export const convertDateToUnix = (date, format = defaultDateFormat) => moment(date, format).valueOf();
export const getFullDate = (d) => {
    const date = new Date(d);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return moment(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + lastDay).format("YYYY-MM-DD");
};
