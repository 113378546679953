export const addressFields = [
    {
        name: "name",
        type: "text",
        placeholder: "Address Name",
        required: false,
    },
    {
        name: "line1",
        type: "text",
        placeholder: "Address Line 1",
        required: true,
    },
    {
        name: "line2",
        type: "text",
        placeholder: "Address Line 2",
        required: false,
    },
    {
        name: "town",
        type: "text",
        placeholder: "Town",
        required: true,
    },
    {
        name: "county",
        type: "text",
        placeholder: "County",
        required: false,
    },
    {
        name: "postCode",
        type: "text",
        placeholder: "PostCode",
        required: true,
    },
];
