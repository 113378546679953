import { GET_ROLES_SUCCESS, UPDATE_ROLE_PERMISSION_SUCCESS, ADD_ROLE_SUCCESS, DELETE_ROLE_SUCCESS, DELETE_ROLE_PERMISSION_SUCCESS, } from "./types";
import { initialState as initialStoreState } from "../initialState";
const initialState = initialStoreState.roles;
export const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROLES_SUCCESS: {
            return [...action.payload];
        }
        case UPDATE_ROLE_PERMISSION_SUCCESS: {
            // const selectedCompanyRoles = state.filter(
            //   (role) => role.companyId === action.payload.companyId
            // );
            // if (selectedCompanyRoles.length === 0 && state.length > 0) {
            //   const editedRole = state.filter(
            //     (role) => role.id !== action.payload.id
            //   );
            //   return [...editedRole];
            // }
            const editedRole = state.map((el) => el.id !== action.payload.id ? el : action.payload);
            return [...editedRole];
        }
        case ADD_ROLE_SUCCESS: {
            // const selectedCompanyRoles = state.filter(
            //   (role) => role.companyId === action.payload.companyId
            // );
            // if (selectedCompanyRoles.length === 0 && state.length > 0) {
            //   return [...state];
            // }
            return [...state, Object.assign({}, action.payload)];
        }
        case DELETE_ROLE_SUCCESS: {
            const roles = state.filter((el) => el.id !== action.payload);
            return [...roles];
        }
        case DELETE_ROLE_PERMISSION_SUCCESS: {
            state.map((el) => {
                if (el.id === action.payload.id) {
                    el = action.payload;
                }
            });
            return [...state];
        }
        default: {
            return state;
        }
    }
};
