import { Popover } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import watchListGraphIcon from "../../../../../../assets/img/watchListGraphIcon.png";
import { defaultDateFormat } from "../../../../../../core/constants/DateTime";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import WatchListRatingsCard from "../watchlistTable/components/watchListRatingCard";
import "./styles.scss";
const CategoryColumnComponent = ({ ratingCardProps, companyName, valueType, }) => {
    const contextJSX = (React.createElement(React.Fragment, null,
        React.createElement("div", null, "Current Rating"),
        React.createElement(WatchListRatingsCard, Object.assign({}, ratingCardProps, { valueType: valueType, headerTitle: moment(ratingCardProps.bandDateTime).format(defaultDateFormat) }))));
    const removeTableAction = () => {
        visible && setVisible(false);
    };
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        window.addEventListener("wheel", removeTableAction);
        return () => {
            window.removeEventListener("wheel", removeTableAction, false);
        };
    }, [visible]);
    return (React.createElement(Popover, { content: contextJSX, title: null, placement: "right", trigger: "click", visible: visible, onVisibleChange: (isVisble) => {
            setVisible(isVisble);
        }, overlayClassName: "category-popover-container" },
        React.createElement("div", { className: "category-container" },
            React.createElement("div", { className: "float-left" }, companyName !== null && companyName !== void 0 ? companyName : `N/A`),
            !isEmpty(companyName) && (React.createElement("img", { src: watchListGraphIcon, alt: "watchListGraphIcon" })),
            " ")));
};
export default CategoryColumnComponent;
