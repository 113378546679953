import React from "react";
import * as Yup from "yup";
import { Button, Input } from "antd";
import { useFormik } from "formik";
import localize from "../../../../../../core/utils/translation-files";
import Asterik from "../../../../shared/helper-components/Asterik";
import SingleSelectNew from "../../../../../../layout/components/select/SingleSelectNew";
const ReingestDataForm = ({ onCancel, onSubmit, selectedSite, initialData, monthOpt, yearOpt, }) => {
    const { handleSubmit, setFieldValue, setErrors, values, errors, resetForm, dirty, } = useFormik({
        validationSchema: Yup.object({
            ingestionYear: Yup.string()
                .required(localize(["Site.ingestionYear", "isRequired!"]))
                .nullable(),
            ingestionMonth: Yup.string()
                .required(localize(["Site.ingestionMonth", "isRequired!"]))
                .nullable(),
        }),
        initialValues: initialData,
        onSubmit: (values) => {
            onSubmit(selectedSite, values, resetForm, setErrors);
        },
    });
    const handleCancel = () => {
        onCancel(resetForm);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { className: "mb-1 customForm", name: "basic", onSubmit: handleSubmit },
            React.createElement(Input, { name: "id", type: "hidden" }),
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "form-group" },
                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                        React.createElement("label", null,
                            localize(["Site.ingestionMonth"]),
                            React.createElement(Asterik, null))),
                    React.createElement(SingleSelectNew, { key: "ingestionMonth", allowClear: false, selectedValue: values.ingestionMonth, className: errors.ingestionMonth ? "require-ant-select" : "", placeholder: "Select a Month", onChange: (val, obj) => {
                            setFieldValue(`ingestionMonth`, obj.value);
                        }, options: monthOpt, valueProperty: "value", textProperty: "text", error: errors &&
                            errors.ingestionMonth &&
                            errors.ingestionMonth }))),
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "form-group" },
                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                        React.createElement("label", null,
                            localize(["Site.ingestionYear"]),
                            React.createElement(Asterik, null))),
                    React.createElement(SingleSelectNew, { key: "ingestionYear", allowClear: false, selectedValue: values.ingestionYear, className: errors.ingestionYear ? "require-ant-select" : "", placeholder: "Select a Year", onChange: (val, obj) => {
                            setFieldValue(`ingestionYear`, obj.value);
                        }, options: yearOpt, valueProperty: "value", textProperty: "text", error: errors &&
                            errors.ingestionYear &&
                            errors.ingestionYear }))),
            React.createElement("div", { className: "d-flex settings-actions d-print-none col-12 popup-button-list" },
                React.createElement(Button, { type: "default", onClick: handleCancel, className: "ml-auto mr-2" },
                    React.createElement("span", { className: "icofont icofont-close-circled mr-2", style: { fontSize: "1.2em" } }),
                    localize(["Button.cancel"])),
                React.createElement(Button, { type: "primary", htmlType: "submit", hidden: !dirty },
                    React.createElement("span", { className: "icofont icofont-save mr-2", style: { fontSize: "1em" } }),
                    localize(["Button.save"]))))));
};
export default ReingestDataForm;
