export class ResponseStatusCodes {
}
ResponseStatusCodes.OK = "OK";
ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION = "DATA_CONSTRAINT_VIOLATION";
// public static PASSWORD_EXPIRY_WARNING: string = "PASSWORD_EXPIRY_WARNING";
ResponseStatusCodes.PASSWORD_FORCE_CHANGE_WARNING = "PASSWORD_FORCE_CHANGE_WARNING";
ResponseStatusCodes.PASSWORD_EXPIRED = "PASSWORD_EXPIRED";
ResponseStatusCodes.EXISTING_USER_GRANTED_ROLE = "EXISTING_USER_GRANTED_ROLE";
ResponseStatusCodes.EXTERNAL_USER_INVITATION_LIMIT_REACHED = "EXTERNAL_USER_INVITATION_LIMIT_REACHED";
ResponseStatusCodes.EXTERNAL_USER_REGISTRATION_LINK_ALREADY_SENT = "EXTERNAL_USER_REGISTRATION_LINK_ALREADY_SENT";
ResponseStatusCodes.USER_ALREADY_EXIST = "USER_ALREADY_EXIST";
ResponseStatusCodes.PI_USER_NOT_FOUND = "PI_USER_NOT_FOUND";
ResponseStatusCodes.VALIDATION_ERROR = "-1";
export class ResponseCodeWithMessages {
}
ResponseCodeWithMessages.NEW_PASSWORD_ALREADY_EXISTS = {
    key: "NEW_PASSWORD_ALREADY_EXISTS",
    value: "This password already used in last 3 passwords",
};
ResponseCodeWithMessages.INVALID_USER_PASSWORD = {
    key: "INVALID_USER_PASSWORD",
    value: "Please enter correct old password",
};
ResponseCodeWithMessages.PASSWORD_EXPIRY_WARNING = {
    key: "PASSWORD_EXPIRY_WARNING",
    value: "Please reset your password before",
};
// Register External User URL Validate Error Codes
ResponseCodeWithMessages.EXTERNAL_USER_REGISTRATION_LINK_DATA_MISSING = {
    key: "EXTERNAL_USER_REGISTRATION_LINK_DATA_MISSING",
    value: "Mandatory data missing in identifier to register an External User in our system",
};
ResponseCodeWithMessages.EXTERNAL_USER_ALREADY_REGISTERED = {
    key: "EXTERNAL_USER_ALREADY_REGISTERED",
    value: "User may have already been registered",
};
ResponseCodeWithMessages.EXTERNAL_USER_DECLINED_REGISTRATION = {
    key: "EXTERNAL_USER_DECLINED_REGISTRATION",
    value: "User may already have declined the registration",
};
ResponseCodeWithMessages.EXTERNAL_USER_REGISTRATION_LINK_EXPIRED = {
    key: "EXTERNAL_USER_REGISTRATION_LINK_EXPIRED",
    value: "Registration Link is expired",
};
ResponseCodeWithMessages.EXTERNAL_USER_NOT_FOUND = {
    key: "EXTERNAL_USER_NOT_FOUND",
    value: "External User not found",
};
// Register External User
ResponseCodeWithMessages.USER_ALREADY_EXIST = {
    key: "USER_ALREADY_EXIST",
    value: "User already exists in our system",
};
ResponseCodeWithMessages.EXTERNAL_USER_INVITATION_LIMIT_REACHED = {
    key: "EXTERNAL_USER_INVITATION_LIMIT_REACHED",
    value: "User already received allowed number of invitations",
};
ResponseCodeWithMessages.ACCOUNT_LOCKED = {
    key: "ACCOUNT_LOCKED",
    value: "Your account is locked",
};
