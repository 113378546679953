import React from "react";
import useDidMount from "../../../core/hooks/useDidMount";
import { useDispatch, useSelector } from "react-redux";
import { getBand } from "../../../redux/band/actions";
import SingleSelectNew from "./SingleSelectNew";
const BandDropdown = ({ selectedBand, setSelectedBand, disabled = false, mode = null, error = null, }) => {
    const dispatch = useDispatch();
    const bands = useSelector((state) => state.bands);
    useDidMount(() => {
        !bands.length && dispatch(getBand());
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { defaultValue: selectedBand, 
            // mode={mode}
            selectedValue: selectedBand, className: error ? "require-ant-select" : "", placeholder: "Select Band", onChange: setSelectedBand, disabled: disabled, options: bands, key: "bandDropdown", valueProperty: "text", textProperty: "code", error: error })));
};
export default BandDropdown;
