import { initialState as initialStoreState } from "../initialState";
import { GET_LOOKUP_SUCCESS, ADD_LOOKUP_SUCCESS, DELETE_LOOKUP_SUCCESS, UPDATE_LOOKUP_SUCCESS, GET_LOOKUPS_SUCCESS, } from "./types";
const initialState = initialStoreState.lookups;
export const lookupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOOKUP_SUCCESS: {
            let newLookup = {};
            newLookup[action.lookupType] = action.payload;
            return Object.assign({}, state, newLookup);
        }
        case GET_LOOKUPS_SUCCESS: {
            const newState = Object.assign(Object.assign({}, state), action.payload);
            return newState;
        }
        case ADD_LOOKUP_SUCCESS: {
            let newLookup = Object.assign(Object.assign({}, state), { [action.lookupType]: [...state[action.lookupType], action.payload] });
            return newLookup;
        }
        case UPDATE_LOOKUP_SUCCESS: {
            const edited = state[action.lookupType].map((el) => el.id !== action.payload.id ? el : action.payload);
            let newLookup = Object.assign(Object.assign({}, state), { [action.lookupType]: edited });
            return newLookup;
        }
        case DELETE_LOOKUP_SUCCESS: {
            const filtered = state[action.lookupType].filter((el) => el.id !== action.payload);
            let newLookup = Object.assign(Object.assign({}, state), { [action.lookupType]: filtered });
            return newLookup;
        }
        default: {
            return state;
        }
    }
};
