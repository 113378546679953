import React from "react";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../../core/utils/translation-files";
export default function ChartVariables({ chartVariables }) {
    return (React.createElement("div", { className: "row  customInformation", "data-test": "chartVariables" },
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.requiredBenchmarkDirection"])),
            React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.requiredBenchmarkDirectionText)),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.shortTermAverage"])),
            React.createElement("p", { className: "color-black" }, !isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.shortTermMovingAveragePeriod)
                ? (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.shortTermMovingAveragePeriod) +
                    " " +
                    (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.dataFrequencyText)
                : "")),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.midTermAverage"])),
            React.createElement("p", { className: "color-black" }, !isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.midTermMovingAveragePeriod)
                ? (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.midTermMovingAveragePeriod) +
                    " " +
                    (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.dataFrequencyText)
                : "")),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.longTermAverage"])),
            React.createElement("p", { className: "color-black" }, !isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.longTermMovingAveragePeriod)
                ? (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.longTermMovingAveragePeriod) +
                    " " +
                    (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.dataFrequencyText)
                : "")),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.benchmark"])),
            React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.benchmarkName)),
        (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.requiredBenchmarkDirection) ===
            TrackedItemsVariables.BENCHMARK_MIDDLE_DIRECTION ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "col-6 chart_variable_item" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.upperConcern"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.upperConcernLevel)),
            React.createElement("div", { className: "col-6 chart_variable_item" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.lowerConcern"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.lowerConcernLevel)),
            React.createElement("div", { className: "col-6 chart_variable_item" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.upperCritical"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.upperCriticalLevel)),
            React.createElement("div", { className: "col-6 chart_variable_item" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.lowerCritical"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.lowerCriticalLevel)))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "col-6 chart_variable_item" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.concernLevel"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.concernLevel)),
            React.createElement("div", { className: "col-6 chart_variable_item" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.criticalLevel"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.criticalLevel)))),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.projectionMovingAverage"])),
            React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.projectionAverageUsedText)),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.projectionPoint"])),
            React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.projectionPoint)),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.requiredBenchmarkDirectionText) === "Upwards"
                ? localize(["ChartVariables.dateofHighestPeak"])
                : localize(["ChartVariables.dateofLowestPeak"])),
            React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.dateOfPeak)),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.optimalValue"])),
            React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.optimalValue)),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.weight"])),
            React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.weight)),
        React.createElement("div", { className: "col-6 chart_variable_item" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.levelOfRisk"])),
            React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.itemRiskLevelText)),
        React.createElement("div", { className: "col-12" },
            React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.description"])),
            (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.description) ? (React.createElement("p", { className: "color-black" }, chartVariables.description)) : (React.createElement("p", { className: "color-black" }, "No Description")))));
}
