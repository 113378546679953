import { GET_PERMISSIONS_SUCCESS } from "./types";
import { PermissionApi } from "../../core/api/permissionApi";
export const getPermissionsSuccess = (data) => ({
    type: GET_PERMISSIONS_SUCCESS,
    payload: data,
});
export function getPermissions() {
    return function (dispatch) {
        return PermissionApi.getPermissions()
            .then((permissions) => {
            if (permissions.length > 0) {
                dispatch(getPermissionsSuccess(permissions));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
