import React from "react";
import { Input } from "antd";
import getFrequencyData from "../../../../../../../core/utils/getDataFrequency";
import localize from "../../../../../../../core/utils/translation-files";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import Asterik from "../../../../../shared/helper-components/Asterik";
const MovingAverages = ({ handleChange, handleBlur, errors, touched, setFieldValue, values, }) => {
    return (React.createElement("div", { className: "box-main-section" },
        React.createElement("section", { className: "box-section", "data-test": "moving-average-benchmark-component" },
            React.createElement("div", { className: "box-title" },
                React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.movingAverage"]))),
            React.createElement("div", { className: "box-description" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6", "data-test": "data-frequency-dropdown" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.dataFrequency"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.dataFrequency, onChange: setFieldValue, inputName: "dataFrequency", lookUpType: LookupNames.LKReportTypeDataFrequency, localizationKey: ["TrackedItem.dataFrequency"], disabled: true, error: errors.dataFrequency && touched.dataFrequency
                                    ? errors.dataFrequency
                                    : null }))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.shortTerm"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(Input, { type: "number", name: "shortTermMovingAveragePeriod", placeholder: localize(["TrackedItem.shortTerm"]), onBlur: handleBlur, onChange: handleChange, value: values.shortTermMovingAveragePeriod, className: `${errors.shortTermMovingAveragePeriod &&
                                    touched.shortTermMovingAveragePeriod
                                    ? "input-error"
                                    : null}`, suffix: getFrequencyData(values.dataFrequency), disabled: values.reportTypeId === undefined }),
                            errors.shortTermMovingAveragePeriod &&
                                touched.shortTermMovingAveragePeriod && (React.createElement("p", { className: "error" }, errors.shortTermMovingAveragePeriod)))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.mediumTerm"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(Input, { type: "number", name: "midTermMovingAverage", placeholder: localize(["TrackedItem.mediumTerm"]), onBlur: handleBlur, onChange: handleChange, value: values.midTermMovingAverage, className: `${errors.midTermMovingAverage && touched.midTermMovingAverage
                                    ? "input-error"
                                    : null}`, suffix: getFrequencyData(values.dataFrequency), disabled: values.reportTypeId === undefined }),
                            errors.midTermMovingAverage &&
                                touched.midTermMovingAverage && (React.createElement("p", { className: "error" }, errors.midTermMovingAverage)))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.longTerm"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(Input, { type: "number", name: "longTermMovingAverage", placeholder: localize(["TrackedItem.longTerm"]), onBlur: handleBlur, onChange: handleChange, value: values.longTermMovingAverage, className: `${errors.longTermMovingAverage &&
                                    touched.longTermMovingAverage
                                    ? "input-error"
                                    : null}`, suffix: getFrequencyData(values.dataFrequency), disabled: values.reportTypeId === undefined }),
                            errors.longTermMovingAverage &&
                                touched.longTermMovingAverage && (React.createElement("p", { className: "error" }, errors.longTermMovingAverage)))))))));
};
export default MovingAverages;
