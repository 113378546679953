import { Button } from "antd";
import React, { useRef, useState } from "react";
import useOutsideClick from "../../../../../../../core/hooks/useClickOutside";
import localize from "../../../../../../../core/utils/translation-files";
import CustomModal from "../../../../../../../layout/components/customModal";
import RenderSvg from "../../../../../../../layout/components/icons/RenderSvg";
export default function ExportItemsModal({ visible, onClose, relatedItems, exportItemToggle = null, chartVariables, trackedItemId, selectedFilterValue, actualDataExportHandle, selectedRange, }) {
    const containerRef = useRef(null);
    const [selectedStyle, setSelectedStyle] = useState("exportItems");
    const handleClickOutside = () => selectedStyle === "selectedItem" ? setSelectedStyle("exportItems") : null;
    useOutsideClick(containerRef, handleClickOutside);
    return (React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["TrackedItem.exportItems"], width: "max-content" },
        React.createElement("div", { className: "exportItemsBox" },
            React.createElement("div", { ref: containerRef, className: selectedStyle, onClick: () => setSelectedStyle("selectedItem") },
                React.createElement("p", { className: "content-center-aligned", style: { flexDirection: "column" } },
                    React.createElement(RenderSvg, { icon: "AllRelatedItems" }),
                    React.createElement("span", { className: "bold-content" }, localize(["TrackedItem.allRelatedItems"])))),
            React.createElement("div", { className: "exportItems", onClick: () => {
                    exportItemToggle();
                    onClose();
                } },
                React.createElement("p", { className: "content-center-aligned", style: { flexDirection: "column" } },
                    React.createElement(RenderSvg, { icon: "IndividualItem" }),
                    React.createElement("span", { className: "bold-content" }, localize(["TrackedItem.individualItem"]))))),
        React.createElement("div", { className: "exportItemsBox", style: { marginBottom: "unset" } },
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(Button, { type: "default", onClick: onClose },
                    React.createElement(RenderSvg, { icon: "Cancel" }),
                    localize(["Button.cancel"])),
                React.createElement(Button, { ref: containerRef, type: "primary", onClick: () => {
                        actualDataExportHandle(selectedFilterValue, trackedItemId, selectedRange, chartVariables, relatedItems);
                        onClose();
                        setSelectedStyle("exportItems");
                    }, disabled: selectedStyle !== "selectedItem" },
                    React.createElement(RenderSvg, { icon: "Export" }),
                    localize(["download"]))))));
}
