import { InAppNotificationApi } from "../../../core/api/inAppNotificationApi";
import { GET_N_NOTIFICATION_SUCCESS, FLAG_NOTIFICATION_SUCCESS, UNFLAG_NOTIFICATION_SUCCESS, READ_NOTIFICATION_SUCCESS, UNREAD_NOTIFICATION_SUCCESS, } from "./types";
export const flagNotificationSuccess = (data) => ({
    type: FLAG_NOTIFICATION_SUCCESS,
    payload: data,
});
export const unflagNotificationSuccess = (data) => ({
    type: UNFLAG_NOTIFICATION_SUCCESS,
    payload: data,
});
export const readNotificationSuccess = (data) => ({
    type: READ_NOTIFICATION_SUCCESS,
    payload: data,
});
export const unreadNotificationSuccess = (data) => ({
    type: UNREAD_NOTIFICATION_SUCCESS,
    payload: data,
});
export const getNNotificationSuccess = (data) => ({
    type: GET_N_NOTIFICATION_SUCCESS,
    payload: data,
});
export function getLastNNotifications(n) {
    return function (dispatch) {
        return InAppNotificationApi.getLastNNotifications(n, true)
            .then((response) => {
            if (Array.isArray(response)) {
                dispatch(getNNotificationSuccess(response));
                return response;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
export function flagNotification(data) {
    return function (dispatch) {
        return InAppNotificationApi.changeNotificationFlag(data, true)
            .then((response) => {
            dispatch(flagNotificationSuccess(data));
            return response;
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function unflagNotification(data) {
    return function (dispatch) {
        return InAppNotificationApi.changeNotificationFlag(data, false)
            .then((response) => {
            dispatch(unflagNotificationSuccess(data));
            return response;
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function readNotification(data) {
    return function (dispatch) {
        return InAppNotificationApi.updateNotificationInAppStatus(data, 1)
            .then((response) => {
            dispatch(readNotificationSuccess(data));
            return response;
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function unreadNotification(data) {
    return function (dispatch) {
        return InAppNotificationApi.updateNotificationInAppStatus(data, 0)
            .then((response) => {
            dispatch(unreadNotificationSuccess(data));
            return response;
        })
            .catch((error) => {
            throw error;
        });
    };
}
