import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupOptions } from "../../redux/groupOption/actions";
import { COMPANY_EDIT, SITE_EDIT, SITE_VIEW, SUPER_PERMISSION, DELPHI_ADMINISTRATOR, } from "../constants/permissionConstants";
import useDidMount from "./useDidMount";
import { DASHBOARD_VIEWER } from "./../constants/permissionConstants";
import { UserType } from "../enums/UserStatus";
const usePermissions = (shouldFetch = false, forceRender = false) => {
    const context = useSelector((state) => state.context);
    const { companyPermissionDTOs, userType } = context !== null && context !== void 0 ? context : {};
    const permissions = companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.map(({ permission }) => permission);
    const groupOptions = useSelector((state) => state.groupOptions);
    const dispatch = useDispatch();
    useDidMount(() => {
        if (
        // userType === UserType.Internal &&
        (!groupOptions.length && shouldFetch) ||
            forceRender) {
            dispatch(getGroupOptions());
        }
    });
    const isExternalUser = useMemo(() => {
        return userType === UserType.External;
    }, [userType]);
    const isHaveDashboardViewerPermission = useMemo(() => {
        return permissions === null || permissions === void 0 ? void 0 : permissions.some((x) => [DASHBOARD_VIEWER].includes(x));
    }, [permissions]);
    const isDelphieorSuperUser = useMemo(() => {
        return permissions === null || permissions === void 0 ? void 0 : permissions.some((x) => [DELPHI_ADMINISTRATOR, SUPER_PERMISSION].includes(x));
    }, [permissions]);
    const isDelphiSuperOrGroupUser = useMemo(() => {
        return permissions === null || permissions === void 0 ? void 0 : permissions.some((x) => [DELPHI_ADMINISTRATOR, SUPER_PERMISSION, COMPANY_EDIT].includes(x));
    }, [permissions]);
    const isGroupAdmin = useMemo(() => {
        if (permissions === null || permissions === void 0 ? void 0 : permissions.some((x) => [DELPHI_ADMINISTRATOR, SUPER_PERMISSION].includes(x))) {
            return false;
        }
        return permissions === null || permissions === void 0 ? void 0 : permissions.includes(COMPANY_EDIT);
    }, [permissions]);
    const isCompanyAdmin = useMemo(() => {
        if (permissions === null || permissions === void 0 ? void 0 : permissions.some((x) => [DELPHI_ADMINISTRATOR, SUPER_PERMISSION, COMPANY_EDIT].includes(x))) {
            return false;
        }
        return permissions === null || permissions === void 0 ? void 0 : permissions.includes(SITE_EDIT);
    }, [permissions]);
    const isSiteAdmin = useMemo(() => {
        if (permissions === null || permissions === void 0 ? void 0 : permissions.some((x) => [
            DELPHI_ADMINISTRATOR,
            SUPER_PERMISSION,
            COMPANY_EDIT,
            SITE_EDIT,
        ].includes(x))) {
            return false;
        }
        return permissions === null || permissions === void 0 ? void 0 : permissions.includes(SITE_VIEW);
    }, [permissions]);
    const isCompanyOrSiteAdmin = useMemo(() => {
        if (permissions === null || permissions === void 0 ? void 0 : permissions.some((x) => [DELPHI_ADMINISTRATOR, SUPER_PERMISSION, COMPANY_EDIT].includes(x))) {
            return false;
        }
        return (permissions === null || permissions === void 0 ? void 0 : permissions.includes(SITE_VIEW)) || (permissions === null || permissions === void 0 ? void 0 : permissions.includes(SITE_EDIT));
    }, [permissions]);
    const isSingleCompanyOrSiteAdmin = useMemo(() => {
        var _a, _b;
        return (isSiteAdmin ||
            (isCompanyAdmin &&
                groupOptions.length === 1 &&
                ((_b = (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO) === null || _b === void 0 ? void 0 : _b.length) === 1));
    }, [groupOptions, isSiteAdmin, isCompanyAdmin]);
    const isSingleSiteAdmin = useMemo(() => {
        var _a, _b, _c, _d, _e;
        return (isSiteAdmin &&
            (groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.length) === 1 &&
            ((_b = (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO) === null || _b === void 0 ? void 0 : _b.length) === 1 &&
            ((_e = (_d = (_c = groupOptions[0]) === null || _c === void 0 ? void 0 : _c.listSimpleDataDTO[0]) === null || _d === void 0 ? void 0 : _d.listSimpleDataDTO) === null || _e === void 0 ? void 0 : _e.length) === 1);
    }, [groupOptions, isSiteAdmin]);
    const isMultiCompanyAdmin = useMemo(() => {
        var _a, _b;
        return (isCompanyAdmin &&
            (groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.length) === 1 &&
            ((_b = (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO) === null || _b === void 0 ? void 0 : _b.length) > 1);
    }, [groupOptions, isCompanyAdmin]);
    //for setting default value of group for user
    const defaultGroupValues = useMemo(() => {
        return isDelphieorSuperUser ? undefined : groupOptions[0];
    }, [isDelphieorSuperUser, groupOptions]);
    const defaultCompanyValues = useMemo(() => {
        var _a;
        return isSingleCompanyOrSiteAdmin
            ? (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO[0]
            : undefined;
    }, [isSingleCompanyOrSiteAdmin, groupOptions]);
    const defaultSiteValues = useMemo(() => {
        var _a, _b;
        return isSingleSiteAdmin
            ? (_b = (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO[0]) === null || _b === void 0 ? void 0 : _b.listSimpleDataDTO[0]
            : undefined;
    }, [isSingleSiteAdmin, groupOptions]);
    const isSingleGroup = useMemo(() => {
        return (groupOptions === null || groupOptions === void 0 ? void 0 : groupOptions.length) === 1;
    }, [groupOptions]);
    const isSingleCompany = useMemo(() => {
        var _a, _b;
        return isSingleGroup && ((_b = (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO) === null || _b === void 0 ? void 0 : _b.length) === 1;
    }, [isSingleGroup, groupOptions]);
    const isSingleSite = useMemo(() => {
        var _a, _b, _c;
        return (isSingleCompany &&
            ((_c = (_b = (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO[0]) === null || _b === void 0 ? void 0 : _b.listSimpleDataDTO) === null || _c === void 0 ? void 0 : _c.length) === 1);
    }, [isSingleCompany, groupOptions]);
    const singleGroupValues = useMemo(() => {
        return isSingleGroup ? groupOptions[0] : undefined;
    }, [isSingleGroup, groupOptions]);
    const singleCompanyValues = useMemo(() => {
        var _a;
        return isSingleCompany ? (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO[0] : undefined;
    }, [isSingleCompany, groupOptions]);
    const singleSiteValues = useMemo(() => {
        var _a, _b;
        return isSingleSite
            ? (_b = (_a = groupOptions[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO[0]) === null || _b === void 0 ? void 0 : _b.listSimpleDataDTO[0]
            : undefined;
    }, [isSingleSite, groupOptions]);
    return {
        isDelphieorSuperUser,
        isDelphiSuperOrGroupUser,
        isGroupAdmin,
        isCompanyAdmin,
        isSiteAdmin,
        isSingleCompanyOrSiteAdmin,
        isSingleSiteAdmin,
        defaultGroupValues,
        defaultCompanyValues,
        defaultSiteValues,
        groupOptions,
        isSingleGroup,
        isSingleCompany,
        isSingleSite,
        singleGroupValues,
        singleCompanyValues,
        singleSiteValues,
        isHaveDashboardViewerPermission,
        isCompanyOrSiteAdmin,
        context,
        isExternalUser,
        isMultiCompanyAdmin,
    };
};
export default usePermissions;
