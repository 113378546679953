import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllowedRoles } from "../../../redux/role/actions";
import React from "react";
import localize from "../../../core/utils/translation-files";
import SingleSelectNew from "./SingleSelectNew";
const RoleDropdownContainer = ({ roles, getAllowedRoles, onChange, selectedValue, className, companyId, disabled, labelInValue, isExternal = false, error = null, }) => {
    const [roleOptions, setRoleOptions] = useState([]);
    useEffect(() => {
        if (roles.length === 0) {
            getAllowedRoles().catch((error) => {
                throw error;
            });
        }
    }, [roles === null || roles === void 0 ? void 0 : roles.length, getAllowedRoles, companyId, roles]);
    useEffect(() => {
        setRoleOptions([...roles]
            .sort(function (a, b) {
            return a === null || a === void 0 ? void 0 : a.name.localeCompare(b === null || b === void 0 ? void 0 : b.name);
        })
            .filter((r) => (r === null || r === void 0 ? void 0 : r.id) !== 1)
            .map((role) => {
            var _a;
            let isAdmin = isExternal &&
                ((_a = role.permissions) === null || _a === void 0 ? void 0 : _a.some((per) => (per === null || per === void 0 ? void 0 : per.administrativeType) === true));
            return Object.assign(Object.assign({}, role), { optionDisabled: isAdmin, optionDisableMessage: localize([
                    "Role.administrativeRoleCannotBeSelected",
                ]) });
        }));
    }, [roles, isExternal]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { options: roleOptions, selectedValue: selectedValue, onChange: onChange, placeholder: "Select a Role", key: "roleSelect", valueProperty: "id", textProperty: "name", className: className, disabled: disabled, labelInValue: labelInValue, allowClear: false, error: error })));
};
function mapStateTopProps(state, ownProps) {
    return {
        roles: state.roles,
        onChange: ownProps.onChange,
        selectedValue: ownProps.selectedValue,
    };
}
const mapDispatchToProps = {
    getAllowedRoles,
};
RoleDropdownContainer.propTypes = {
    getAllowedRoles: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(RoleDropdownContainer);
