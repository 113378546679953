import React from "react";
import { Button, Input } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginRoute } from "../../../../core/constants/routesPath";
import { useHistory } from "react-router-dom";
import localize from "../../../../core/utils/translation-files";
import { inputDefaultLength } from "../../../../core/utils/default-length";
const intialState = {
    username: "",
};
export default function ForgotPasswordForm({ emailSent, handleForgetPassword, }) {
    const history = useHistory();
    const ForgetPasswordSchema = Yup.object({
        username: Yup.string()
            .required(localize(["Forgot.username", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
    });
    const forgotPassword = useFormik({
        initialValues: intialState,
        validationSchema: ForgetPasswordSchema,
        onSubmit: (values, { setErrors, resetForm }) => {
            handleForgetPassword(values, setErrors);
        },
    });
    return (React.createElement("div", { className: "reset-password" },
        React.createElement("h4", { className: "mt-0 mb-1" }, localize(["Forgot.forgot", "password"])),
        React.createElement("p", { className: "text-color-200" }, localize(["Forgot.sendYourUserNameToAccessYourAccount"])),
        emailSent !== "" ? (React.createElement("div", { className: "success-box" }, emailSent)) : (React.createElement("form", { className: "mb-1 loginForm", name: "basic", onSubmit: forgotPassword.handleSubmit },
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["Forgot.your", "Forgot.username"]), type: "text", name: "username", value: forgotPassword.values.username, onChange: forgotPassword.handleChange, className: `${forgotPassword.errors.username &&
                        forgotPassword.touched.username
                        ? "input-error"
                        : null}` }),
                forgotPassword.errors.username &&
                    forgotPassword.touched.username && (React.createElement("p", { className: "error" }, forgotPassword.errors.username))),
            forgotPassword.errors["invalid"] && (React.createElement("p", { className: "error" }, forgotPassword.errors["invalid"])),
            React.createElement("div", { className: "form-group" },
                React.createElement(Button, { "data-test": "new-password-button", className: "submitButton", type: "primary", htmlType: "submit" }, localize(["Button.getNewPassword"]))))),
        React.createElement("br", null),
        React.createElement(Button, { htmlType: "button", className: "mb-1", type: "default", onClick: () => history.push(loginRoute) }, localize(["Button.backToLogin"]))));
}
