import { httpGet, httpPost } from "./request/requestApi";
const controllerEndpoint = "report";
const watchListControllerEndPoint = "watchlist";
export class DashboardApi {
    static getUniversalRatingData(companyId, subCategoryId, historyFrequency, pageNo = 1, pageSize = 2, area, body) {
        return httpPost(`${controllerEndpoint}/universalRatingTabList/${companyId}/${subCategoryId}/${historyFrequency}/${pageNo}/${pageSize}`, { area, body });
    }
    static getCategoriesRatingList(siteId, historyFrequency, pageNo = 1, pageSize = 2, area, body) {
        return httpPost(`${controllerEndpoint}/categoriesRatingList/${siteId}/${historyFrequency}/${pageNo}/${pageSize}`, { area, body });
    }
    static getSubCategoriesRatingList(companyId, categoryId, subCategoryId, historyFrequency, pageNo = 1, pageSize = 2, area, body) {
        return httpPost(`${controllerEndpoint}/subCategoriesRatingList/${companyId}/${categoryId}/${subCategoryId}/${historyFrequency}/${pageNo}/${pageSize}`, { area, body });
    }
    static getBenchMarkRatingData(companyId, subCategoryId, historyFrequency, pageNo, pageSize, body) {
        return httpPost(`${controllerEndpoint}/benchmarkRatingTabList/${companyId}/${subCategoryId}/${historyFrequency}/${pageNo}/${pageSize}`, { body });
    }
    static getSubCategoriesOverallRating(companyId, categoryId, subCategoryId, historyFrequency) {
        return httpGet(`${controllerEndpoint}/subCategoriesOverallRating/${companyId}/${categoryId}/${subCategoryId}/${historyFrequency}`);
    }
    static getTrackedItemsOverallRating(companyId, categoryId, subCategoryId, historyFrequency) {
        return httpGet(`${controllerEndpoint}/reportsOverallRating/${companyId}/${categoryId}/${subCategoryId}/${historyFrequency}`);
    }
    static getCategoriesOverallRating(siteId, historyFrequency) {
        return httpGet(`${controllerEndpoint}/categoriesOverallRating/${siteId}/${historyFrequency}`);
    }
    static getSitesOverallRating(companyId, historyFrequency) {
        return httpGet(`${controllerEndpoint}/siteOverallRating/${companyId}/${historyFrequency}`);
    }
    static getSitesRatingList(companyId, historyFrequency = 7, pageNo = 1, pageSize = 2, area, body) {
        return httpPost(`${controllerEndpoint}/siteRatingList/${companyId}/${historyFrequency}/${pageNo}/${pageSize}`, { area, body });
    }
    static getCompaniesOverallRating(groupId, historyFrequency) {
        return httpGet(`${controllerEndpoint}/companyOverallRating/${groupId}/${historyFrequency}`);
    }
    static getCompaniesRatingList(groupId = 150288, historyFrequency = 7, pageNo = 1, pageSize = 2, area, body) {
        return httpPost(`${controllerEndpoint}/companyRatingList/${groupId}/${historyFrequency}/${pageNo}/${pageSize}`, { area, body });
    }
    static getGroupsRatingList(historyFrequency = 7, pageNo = 1, pageSize = 2) {
        return httpGet(`${controllerEndpoint}/groupRatingList/${historyFrequency}/${pageNo}/${pageSize}`);
    }
    static getRatingCount(itemId, groupId = -1, companyId = -1, siteId = -1, categoryId = -1, subcategoryId = -1) {
        return httpGet(`${controllerEndpoint}/getAggregateRatings/${groupId}/${companyId}/${siteId}/${categoryId}/${subcategoryId}/${itemId}`);
    }
    static getRoadMapData(reportId = -1, silent = false) {
        return httpGet(`${watchListControllerEndPoint}/getRoadMap/${reportId}`, {
            silent: silent,
        });
    }
    static getRoadMapItemInfo(userId, reportId = -1, silent = false) {
        return httpGet(`${controllerEndpoint}/getRoadMapItemInfo/${reportId}/${userId}`, {
            silent: silent,
            area: reportId,
        });
    }
    static getRoadMapTrackeditems(companyId, subcategoryId, silent = false) {
        return httpGet(`${watchListControllerEndPoint}/getTrackedItems/${companyId}/${subcategoryId}`, {
            silent: silent,
        });
    }
    static getSummaryFlagByTrackItemList(reportIdList, area, silent = false) {
        return httpPost(`${controllerEndpoint}/getSummaryItemFlag`, {
            body: reportIdList,
            area,
            silent: silent,
        });
    }
    static getParentData(body) {
        const id = body[0];
        return httpPost(`admin/parentNameAndIds/${id}`, { body });
    }
    static getGroupsHierarchy() {
        return httpGet(`${watchListControllerEndPoint}/getGroupHierarchy`);
    }
    static getHistoricalChartData(reportId, frequency, silent = false) {
        return httpGet(`${controllerEndpoint}/getHistoricalChartData/${reportId}/${frequency}`, {
            silent: silent,
        });
    }
    static getPredictedBandInfo(itemIdList, silent = false) {
        return httpPost(`${controllerEndpoint}/getPredictedBands`, {
            silent: silent,
            body: itemIdList,
        });
    }
    static getbandInfo(itemIdList, silent = false) {
        return httpPost(`${controllerEndpoint}/getBandsInfo/`, {
            silent: silent,
            body: itemIdList,
        });
    }
    static getBandsInfoForCompanyFinancials(itemIdList, silent = false) {
        return httpPost(`${controllerEndpoint}/getBandsInfoForCompanyFinancials/`, {
            silent: silent,
            body: itemIdList,
        });
    }
    static getTrackedItemBreadCrumb(reportId) {
        return httpGet(`${controllerEndpoint}/getTrackedItemBreadCrumb/${reportId}`);
    }
    static getParentIdsByReportId(reportId, silent = false) {
        return httpGet(`${controllerEndpoint}/getParentIdsByReportId/${reportId}`);
    }
}
