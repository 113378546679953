import { httpGet, httpPost, httpPut } from "./request/requestApi";
const controllerGroupComapnyEndpoint = "admin/group/company";
const controllerEndpoint = "admin/company";
export class CompanyApi {
    // public static getCompanies(): Promise<CompanyResponse[]> {
    //   return httpGet(controllerEndpoint);
    // }
    static getCompanies(id = undefined) {
        if (![undefined, null].includes(id)) {
            return httpGet(`${controllerGroupComapnyEndpoint}/${id}`);
        }
        return httpGet(`${controllerGroupComapnyEndpoint}`);
    }
    static getAllCompanies() {
        return httpGet(controllerEndpoint);
    }
    static getCompaniesByGroup(id) {
        return httpGet(`${controllerGroupComapnyEndpoint}/${id}`);
    }
    static createCompany(data) {
        return httpPost(controllerEndpoint, { body: data });
    }
    static updateCompany(data) {
        return httpPut(`${controllerEndpoint}`, { body: data });
    }
    static getSingleCompany(id) {
        return httpGet(`${controllerEndpoint}/${id}`);
    }
    static activateCompany(id) {
        return httpPost(`${controllerEndpoint}/active/${id}`, {});
    }
    static inActivateCompany(id) {
        return httpPost(`${controllerEndpoint}/inactive/${id}`, {});
    }
    static companyExist(id) {
        return httpGet(`${controllerEndpoint}/externalReference/${id}`);
    }
}
