var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { GET_PENDINGUSERS_SUCCESS, ADD_PENDINGUSER_SUCCESS, UPDATE_PENDINGUSER_SUCCESS, DELETE_PENDINGUSER_SUCCESS, CLEAR_PENDINGUSER_LISTING_SUCCESS, } from "./types";
import { PendingUserApi } from "../../core/api/pendingUserApi";
import { getLocalDateString } from "../../core/helpers/dateFormatter";
export const getPendingUsersSuccess = (data) => ({
    type: GET_PENDINGUSERS_SUCCESS,
    payload: data,
});
export const addPendingUserSuccess = (data) => ({
    type: ADD_PENDINGUSER_SUCCESS,
    payload: data,
});
export const updatePendingUserSuccess = (data) => ({
    type: UPDATE_PENDINGUSER_SUCCESS,
    payload: data,
});
export const deletePendingUserSuccess = (data) => ({
    type: DELETE_PENDINGUSER_SUCCESS,
    payload: data,
});
export const clearPendingUserListingSuccess = () => ({
    type: CLEAR_PENDINGUSER_LISTING_SUCCESS,
});
export function getPendingUsers(companyId, isCompanyChanged = false) {
    return function (dispatch) {
        return PendingUserApi.getPendingUsers(companyId)
            .then((users) => {
            if (Array.isArray(users) || isCompanyChanged === true) {
                const mapResponse = users.map((pendingUser) => {
                    var _a, _b, _c;
                    const { expiryDate, externalUserSiteResponseDTOs, firstName, lastName } = pendingUser, rest = __rest(pendingUser, ["expiryDate", "externalUserSiteResponseDTOs", "firstName", "lastName"]);
                    return Object.assign(Object.assign({}, rest), { fullName: `${firstName !== null && firstName !== void 0 ? firstName : ``} ${lastName !== null && lastName !== void 0 ? lastName : ``}`, siteName: (externalUserSiteResponseDTOs === null || externalUserSiteResponseDTOs === void 0 ? void 0 : externalUserSiteResponseDTOs.length) > 0
                            ? externalUserSiteResponseDTOs[0].companyName
                            : "", userRole: (externalUserSiteResponseDTOs === null || externalUserSiteResponseDTOs === void 0 ? void 0 : externalUserSiteResponseDTOs.length) > 0
                            ? externalUserSiteResponseDTOs[0].roleName
                            : "", startDateTime: getLocalDateString((_a = externalUserSiteResponseDTOs[0]) === null || _a === void 0 ? void 0 : _a.startDate), endDateTime: getLocalDateString((_b = externalUserSiteResponseDTOs[0]) === null || _b === void 0 ? void 0 : _b.endDate), expiryDate: (_c = getLocalDateString(expiryDate)) !== null && _c !== void 0 ? _c : new Date(expiryDate).toLocaleDateString() });
                });
                dispatch(getPendingUsersSuccess(mapResponse));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function addPendingUser(user) {
    return function (dispatch) {
        return PendingUserApi.inviteUser(user)
            .then((response) => {
            var _a, _b, _c;
            if (response.code) {
                return response;
            }
            const _d = Object.assign({}, response), { expiryDate, externalUserSiteResponseDTOs, firstName, lastName } = _d, rest = __rest(_d, ["expiryDate", "externalUserSiteResponseDTOs", "firstName", "lastName"]);
            dispatch(addPendingUserSuccess(Object.assign(Object.assign({}, rest), { fullName: `${firstName !== null && firstName !== void 0 ? firstName : ``} ${lastName !== null && lastName !== void 0 ? lastName : ``}`, siteName: (externalUserSiteResponseDTOs === null || externalUserSiteResponseDTOs === void 0 ? void 0 : externalUserSiteResponseDTOs.length) > 0
                    ? externalUserSiteResponseDTOs[0].companyName
                    : "", userRole: (externalUserSiteResponseDTOs === null || externalUserSiteResponseDTOs === void 0 ? void 0 : externalUserSiteResponseDTOs.length) > 0
                    ? externalUserSiteResponseDTOs[0].roleName
                    : "", startDateTime: getLocalDateString((_a = externalUserSiteResponseDTOs[0]) === null || _a === void 0 ? void 0 : _a.startDate), endDateTime: getLocalDateString((_b = externalUserSiteResponseDTOs[0]) === null || _b === void 0 ? void 0 : _b.endDate), expiryDate: (_c = getLocalDateString(expiryDate)) !== null && _c !== void 0 ? _c : new Date(expiryDate).toLocaleDateString() })));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function editPendingUser(user) {
    return function (dispatch) {
        return PendingUserApi.updatePendingUser(user)
            .then((response) => {
            var _a, _b, _c;
            if (response.code) {
                return response;
            }
            const _d = Object.assign({}, response), { expiryDate, externalUserSiteResponseDTOs, firstName, lastName } = _d, rest = __rest(_d, ["expiryDate", "externalUserSiteResponseDTOs", "firstName", "lastName"]);
            dispatch(updatePendingUserSuccess(Object.assign(Object.assign({}, rest), { fullName: `${firstName !== null && firstName !== void 0 ? firstName : ``} ${lastName !== null && lastName !== void 0 ? lastName : ``}`, siteName: (externalUserSiteResponseDTOs === null || externalUserSiteResponseDTOs === void 0 ? void 0 : externalUserSiteResponseDTOs.length) > 0
                    ? externalUserSiteResponseDTOs[0].companyName
                    : "", userRole: (externalUserSiteResponseDTOs === null || externalUserSiteResponseDTOs === void 0 ? void 0 : externalUserSiteResponseDTOs.length) > 0
                    ? externalUserSiteResponseDTOs[0].roleName
                    : "", startDateTime: getLocalDateString((_a = externalUserSiteResponseDTOs[0]) === null || _a === void 0 ? void 0 : _a.startDate), endDateTime: getLocalDateString((_b = externalUserSiteResponseDTOs[0]) === null || _b === void 0 ? void 0 : _b.endDate), expiryDate: (_c = getLocalDateString(expiryDate)) !== null && _c !== void 0 ? _c : new Date(expiryDate).toLocaleDateString() })));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function deletePendingUser(data) {
    return function (dispatch) {
        return PendingUserApi.deletePendingUser(data)
            .then((id) => {
            dispatch(deletePendingUserSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
