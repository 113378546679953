import { GET_COMPANIES_SUCCESS, ADD_COMPANY_SUCCESS, UPDATE_COMPANY_SUCCESS, ACTIVATE_COMPANY_SUCCESS, IN_ACTIVATE_COMPANY_SUCCESS, } from "./types";
import { CompanyApi } from "../../core/api/companyApi";
import { CompanyType } from "../../core/enums/CompanyType";
import { clearGroupOptionSuccess } from "../groupOption/actions";
export const getCompaniesSuccess = (data) => ({
    type: GET_COMPANIES_SUCCESS,
    payload: data,
});
export const addCompanySuccess = (data) => ({
    type: ADD_COMPANY_SUCCESS,
    payload: data,
});
export const updateCompanySuccess = (data) => ({
    type: UPDATE_COMPANY_SUCCESS,
    payload: data,
});
export const activateCompanySuccess = (data) => ({
    type: ACTIVATE_COMPANY_SUCCESS,
    payload: data,
});
export const inActivateCompanySuccess = (data) => ({
    type: IN_ACTIVATE_COMPANY_SUCCESS,
    payload: data,
});
export function getCompaniesByGroup(groupId) {
    return function (dispatch) {
        return CompanyApi.getCompaniesByGroup(groupId)
            .then((companies) => {
            if ((companies === null || companies === void 0 ? void 0 : companies.length) > 0) {
                dispatch(getCompaniesSuccess(companies));
                return companies;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
export function getCompanies(groupId = null) {
    return function (dispatch, getState) {
        const { selectedCompany } = getState().context;
        // TODO needs improvement : extract function for groupId
        let companyGroupId = groupId !== null && groupId !== void 0 ? groupId : ((selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.type) === CompanyType.Group
            ? selectedCompany.id
            : (selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.type) === CompanyType.Company
                ? selectedCompany.parentId
                : -1);
        return CompanyApi.getCompanies(companyGroupId)
            .then((companies) => {
            if ((companies === null || companies === void 0 ? void 0 : companies.length) > 0) {
                dispatch(getCompaniesSuccess(companies));
                return companies;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
export function addCompany(company) {
    return function (dispatch) {
        return CompanyApi.createCompany(company)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(addCompanySuccess(response));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            return error;
        });
    };
}
export function editCompany(company) {
    return function (dispatch) {
        return CompanyApi.updateCompany(company)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(updateCompanySuccess(response));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            return error;
        });
    };
}
export function activateCompany(data, status) {
    return function (dispatch) {
        return CompanyApi.activateCompany(data)
            .then((id) => {
            dispatch(activateCompanySuccess(data));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            return error;
        });
    };
}
export function inActivateCompany(data) {
    return function (dispatch) {
        return CompanyApi.inActivateCompany(data)
            .then((id) => {
            dispatch(inActivateCompanySuccess(data));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            return error;
        });
    };
}
