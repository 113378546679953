import React from "react";
import { Input } from "antd";
import getFrequencyData from "../../../../../../../core/utils/getDataFrequency";
import localize from "../../../../../../../core/utils/translation-files";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import Asterik from "../../../../../shared/helper-components/Asterik";
const Projections = ({ handleChange, handleBlur, errors, touched, setFieldValue, values, setDirection, isClientAdmin, projectionRequired, isSystemCreated = false, }) => {
    return projectionRequired === true ? (React.createElement("div", { className: "box-main-section" },
        React.createElement("section", { className: "box-section", "data-test": "projections-component" },
            React.createElement("div", { className: "box-title" },
                React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.projections"]))),
            React.createElement("div", { className: "box-description" },
                React.createElement("div", { className: "row" },
                    React.createElement(React.Fragment, null,
                        " ",
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize([
                                            "TrackedItem.projection",
                                            "TrackedItem.movingAverage",
                                        ]),
                                        React.createElement(Asterik, null))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.projectionAverageUsed, onChange: setFieldValue, inputName: "projectionAverageUsed", lookUpType: LookupNames.LKReportTypeProjectionAverage, localizationKey: [
                                        "TrackedItem.projection",
                                        "TrackedItem.movingAverage",
                                    ], disabled: values.reportTypeId === undefined, error: errors.projectionAverageUsed &&
                                        touched.projectionAverageUsed
                                        ? errors.projectionAverageUsed
                                        : null }))),
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize([
                                            "TrackedItem.projection",
                                            "TrackedItem.point",
                                        ]),
                                        React.createElement(Asterik, null))),
                                React.createElement(Input, { type: "number", name: "projectionPoint", placeholder: localize([
                                        "TrackedItem.projection",
                                        "TrackedItem.point",
                                    ]), onBlur: handleBlur, onChange: handleChange, value: values.projectionPoint, className: `${errors.projectionPoint && touched.projectionPoint
                                        ? "input-error"
                                        : null}`, suffix: getFrequencyData(values.dataFrequency), disabled: values.reportTypeId === undefined }),
                                errors.projectionPoint && touched.projectionPoint && (React.createElement("p", { className: "error" }, errors.projectionPoint)))),
                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.reactionTime"]),
                                        React.createElement(Asterik, null))),
                                React.createElement(Input, { type: "number", name: "reactionTime", placeholder: localize(["TrackedItem.reactionTime"]), onBlur: handleBlur, onChange: handleChange, value: values.reactionTime, className: `${errors.reactionTime && touched.reactionTime
                                        ? "input-error"
                                        : null}`, suffix: "Days", disabled: values.reportTypeId === undefined }),
                                errors.reactionTime && touched.reactionTime && (React.createElement("p", { className: "error" }, errors.reactionTime)))))))))) : null;
};
export default Projections;
