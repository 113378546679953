const removeHideClass = () => {
    document.querySelectorAll(".hideOnScroll").forEach((item) => {
        var _a, _b;
        ((_b = (_a = item === null || item === void 0 ? void 0 : item.parentElement) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.contains("d-none")) &&
            setTimeout(() => {
                var _a, _b;
                (_b = (_a = item === null || item === void 0 ? void 0 : item.parentElement) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.remove("d-none");
            }, 50);
    });
};
export default removeHideClass;
