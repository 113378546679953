import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getLocalDateString } from "../../../../../../core/helpers/dateFormatter";
import localize from "../../../../../../core/utils/translation-files";
export const RolesListingTable = ({ userRoles, actions = null, tableRef = null, userName = null, showUser = true, }) => {
    const columns = [
        {
            title: localize(["Group.group"]),
            dataIndex: "groupName",
            key: "groupName",
        },
        {
            title: localize(["UserRole.company"]),
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: localize(["UserRole.site"]),
            dataIndex: "siteName",
            key: "siteName",
        },
        {
            title: localize(["UserRole.role"]),
            dataIndex: "roleName",
            key: "roleName",
        },
        {
            title: localize(["User.band"]),
            dataIndex: "band",
            key: "band",
            render: (band) => (band === 5000 ? "N/A" : band),
        },
        {
            key: "startDate",
            title: localize(["UserRole.startDateAndTime"]),
            dataIndex: "startDate",
            render: (startDate) => getLocalDateString(startDate),
        },
        {
            key: "endDate",
            title: localize(["UserRole.endDateAndTime"]),
            dataIndex: "endDate",
            render: (endDate) => getLocalDateString(endDate),
        },
    ];
    const actionColumn = {
        key: "actions",
        title: localize(["actions"]),
        render: actions,
    };
    const hide = useSelector((state) => state.togglePrint);
    const displayedColumns = actions && !hide.status ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { ref: tableRef },
        showUser && (React.createElement("h3", { className: "d-print page-title" }, `${userName} Roles`)),
        React.createElement(Table, { rowKey: (record) => record.companyId + record.siteId, dataSource: [...userRoles], columns: displayedColumns, pagination: false })));
};
