import React from "react";
import { useLocation } from "react-router-dom";
import { PAGES_HEADER_INFO } from "../../../config/constants";
import PageDescription from "./pageDescription";
const Header = ({ dataTest, leftComponent = null, rightComponent = null, leftComponentClass = null, rightComponentClass = null, }) => {
    const location = useLocation();
    const findCurrentPageName = () => {
        var _a;
        const currentParentRouteArr = location.pathname.split("/");
        if ((currentParentRouteArr === null || currentParentRouteArr === void 0 ? void 0 : currentParentRouteArr.includes("unauthorized")) ||
            !(currentParentRouteArr === null || currentParentRouteArr === void 0 ? void 0 : currentParentRouteArr.includes("watchlist")))
            return undefined;
        const currentParentRoute = currentParentRouteArr[1] + "/" + currentParentRouteArr[2];
        const { title, description } = (_a = PAGES_HEADER_INFO === null || PAGES_HEADER_INFO === void 0 ? void 0 : PAGES_HEADER_INFO[currentParentRoute]) !== null && _a !== void 0 ? _a : {};
        return React.createElement(PageDescription, { title: title, description: description });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: "page-header customHeader", "data-test": dataTest, id: "page-header" },
            React.createElement("div", { className: leftComponentClass }, leftComponent),
            React.createElement("div", { className: `d-print-none ${rightComponentClass}` }, rightComponent)),
        findCurrentPageName()));
};
export default Header;
