import React, { useMemo } from "react";
import { Button, Popconfirm, Menu, Dropdown } from "antd";
import localize from "../../../core/utils/translation-files";
import isEmpty from "./../../../core/helpers/isEmpty";
import RenderSvg from "../icons/RenderSvg";
import CustomTooltip from "../customTooltip";
import removeHideClass from "../../vertical/RemoveHideClass";
const TableActions = ({ actionArray, wrapperClass, menuItems = false, ShowActionText = true, dropdownClassName, }) => {
    //TODO: Need to remove isEmpty
    const btnElement = (btnProp) => {
        if (btnProp.isModal) {
            return !btnProp.disbaled ? (React.createElement(Popconfirm, { placement: "top", title: localize(btnProp.modalTitle), onConfirm: btnProp.onConfirm, onCancel: btnProp.onCancel, okText: localize(["yes"]), cancelText: localize(["no"]) }, btnProp === null || btnProp === void 0 ? void 0 : btnProp.children)) : (React.createElement(React.Fragment, null, btnProp === null || btnProp === void 0 ? void 0 : btnProp.children));
        }
        else if ((btnProp === null || btnProp === void 0 ? void 0 : btnProp.renderSvgButton) || btnProp.iconComponent) {
            if (btnProp === null || btnProp === void 0 ? void 0 : btnProp.onClick) {
                return (React.createElement("div", { className: "d-flex align-items-center", onClick: !(btnProp === null || btnProp === void 0 ? void 0 : btnProp.disbaled) && (btnProp === null || btnProp === void 0 ? void 0 : btnProp.onClick), style: { cursor: (btnProp === null || btnProp === void 0 ? void 0 : btnProp.disbaled) ? "not-allowed" : "pointer" } },
                    (btnProp === null || btnProp === void 0 ? void 0 : btnProp.renderSvgButton) && (React.createElement(RenderSvg, { className: btnProp.btnClass, icon: btnProp === null || btnProp === void 0 ? void 0 : btnProp.svgIcon })), btnProp === null || btnProp === void 0 ? void 0 :
                    btnProp.iconComponent,
                    React.createElement("span", { hidden: isEmpty(btnProp.title), className: "ml-2 " }, localize(btnProp.title))));
            }
            return btnProp === null || btnProp === void 0 ? void 0 : btnProp.iconComponent;
        }
        else {
            return (React.createElement(RenderSvg, { className: btnProp.btnClass, icon: btnProp.svgIcon, onClick: btnProp.onClick, tooltipTitle: localize(btnProp.title), style: Object.assign(Object.assign({ cursor: (btnProp === null || btnProp === void 0 ? void 0 : btnProp.disbaled) ? "not-allowed" : "pointer" }, (!isEmpty(btnProp === null || btnProp === void 0 ? void 0 : btnProp.iconStyle) && (btnProp === null || btnProp === void 0 ? void 0 : btnProp.iconStyle))), { opacity: (btnProp === null || btnProp === void 0 ? void 0 : btnProp.disbaled) ? "50%" : "100%" }), disabled: btnProp === null || btnProp === void 0 ? void 0 : btnProp.disbaled }));
        }
    };
    const formatedActions = useMemo(() => actionArray === null || actionArray === void 0 ? void 0 : actionArray.filter((data) => data.isAllowed || isEmpty(data.isAllowed)).sort((a, b) => (a.priority > b.priority ? 1 : -1)), [actionArray]);
    const actions = formatedActions === null || formatedActions === void 0 ? void 0 : formatedActions.map((e, index) => !e === false && (React.createElement(CustomTooltip, { placement: "top", title: localize(e.title), key: index, renderTooltip: isEmpty(menuItems) }, btnElement(e))));
    const menu = (React.createElement(Menu, { className: "actionsButtonsDropdown" }, actions === null || actions === void 0 ? void 0 : actions.map((item, index, array) => {
        var _a;
        return (React.createElement(Menu.Item, { key: index, className: ((_a = formatedActions[index]) === null || _a === void 0 ? void 0 : _a.disbaled) && `disable_menu_item`, style: {
                borderRadius: index === (array === null || array === void 0 ? void 0 : array.length) - 1
                    ? "0 0 6px 6px"
                    : index === 0
                        ? "6px 6px 0 0"
                        : 0,
            } }, item));
    })));
    return (React.createElement("div", { className: `buttons-list nowrap ${wrapperClass !== null && wrapperClass !== void 0 ? wrapperClass : ""}`, onClick: (event) => event.stopPropagation() }, menuItems ? (React.createElement(Dropdown, { overlay: menu, onVisibleChange: removeHideClass, overlayClassName: dropdownClassName ? dropdownClassName : "dropdown-action-container", placement: "bottomRight" },
        React.createElement(Button, { type: "primary", className: "dashed-action-btn " },
            React.createElement(RenderSvg, { icon: "MoreActions" }),
            ShowActionText && localize(["actions"])))) : (actions)));
};
export default TableActions;
