import React from "react";
import { Button, Checkbox } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import localize from "../../../../../../../core/utils/translation-files";
import { SessionVariables } from "../../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../../core/helpers/localStorage/sessionStorage";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
const { USER_CONTEXT } = SessionVariables;
const { getKey } = SessionStorage;
const defaultSubmitText = "Save";
const NotificationBandsForm = ({ submitText = defaultSubmitText, onSubmit, onCancel, userBandOptions, setOverWrittenNotificationList, overWrittenNotificationList, selectedNotification, setSelectedNotification, notificationsList, setNotificationsList, }) => {
    const { handleSubmit, errors, resetForm } = useFormik({
        validationSchema: Yup.object({
        // bands: Yup.string().required(
        //   localize(["Role.permissions", "areRequired!"])
        // ),
        }),
        initialValues: "",
        onSubmit: (values) => {
            onSubmit(values);
            resetForm();
        },
    });
    const handleCancel = () => {
        resetForm();
        onCancel();
    };
    const submitButtonHandler = (event) => {
        event.preventDefault();
        handleSubmit();
        setOverWrittenNotificationList((prev) => {
            let updateOverWriteNotification = [...prev];
            updateOverWriteNotification = (updateOverWriteNotification === null || updateOverWriteNotification === void 0 ? void 0 : updateOverWriteNotification.some(({ id }) => id === (selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.id)))
                ? updateOverWriteNotification.map((overWritten) => (overWritten === null || overWritten === void 0 ? void 0 : overWritten.id) === (selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.id)
                    ? selectedNotification
                    : overWritten)
                : [...updateOverWriteNotification, selectedNotification];
            return updateOverWriteNotification;
        });
        setNotificationsList((prev) => [
            ...prev.map((noti) => (noti === null || noti === void 0 ? void 0 : noti.id) === selectedNotification.id ? selectedNotification : noti),
        ]);
        // setSelectedNotification(null);
    };
    const handleChangeBandsCheckBox = (event) => {
        const { checked, id } = event.target;
        let updateRecipient = [...selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.recipient];
        updateRecipient = checked
            ? [...updateRecipient, id]
            : updateRecipient.filter((recipient) => recipient !== id);
        setSelectedNotification((prev) => (Object.assign(Object.assign({}, prev), { recipient: updateRecipient })));
    };
    const isDisableBand = (value) => {
        var _a;
        const userHighestBand = Math.max(...(_a = getKey(USER_CONTEXT)) === null || _a === void 0 ? void 0 : _a.allowedBands);
        return userHighestBand < value;
    };
    return (React.createElement("div", { className: "col-12" },
        React.createElement("form", { className: "mb-1 customForm", "data-test": "addRoleFormComponent", name: "basic", onSubmit: handleSubmit },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null, localize(["User.user", "User.band"]))),
                React.createElement("div", { className: "row" }, userBandOptions === null || userBandOptions === void 0 ? void 0 : userBandOptions.map(({ code }, bandIndex) => {
                    var _a;
                    return code !== TrackedItemsVariables.NOTIFICATIONS_DEFAULT_BAND && (React.createElement("div", { className: "col-6" },
                        React.createElement(Checkbox, { key: bandIndex, onChange: handleChangeBandsCheckBox, id: code, disabled: isDisableBand(+code), checked: (_a = selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.recipient) === null || _a === void 0 ? void 0 : _a.some((value) => value === code) }, code)));
                })),
                errors.bands && React.createElement("p", { className: "error" }, errors.bands))),
        React.createElement("div", { className: "d-flex justify-content-between buttons-list settings-actions" },
            React.createElement(Button, { danger: true, onClick: handleCancel }, localize(["Button.cancel"])),
            React.createElement(Button, { type: "primary", onClick: submitButtonHandler }, submitText))));
};
export default NotificationBandsForm;
