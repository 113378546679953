import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import localize from "../../../../core/utils/translation-files";
import UsePersistCustomFilters from "../../../../core/hooks/usePersistCustomFilters";
import Header from "../../../../layout/components/common/header";
import FiltersDropdownMenu from "../../../../layout/components/select/FiltersDropdownMenu";
import "./styles.scss";
import WatchlistTabs from "./components/watchlistTabs";
import { LookupNames } from "../../../../core/constants/lookup";
import LookupDropDown from "../../../../layout/components/select/LookupDropDown";
import UsePrintPage from "../../../../core/hooks/usePrintPage";
import MultiWatchListExpandedTable from "./components/multiwatchlistExpandedTable";
import { getLookupByListing } from "../../../../core/helpers/lookupHanlder";
import { useDispatch, useSelector } from "react-redux";
import { getLookups } from "../../../../redux/lookup/actions";
import PrintButton from "../../../../layout/components/common/PrintButton";
import UseInfiniteScroll from "../../../../core/hooks/useInfiniteScroll";
import { WatchlistApi } from "../../../../core/api/watchlistApi";
import isEmpty from "../../../../core/helpers/isEmpty";
import usePermissions from "../../../../core/hooks/usePermissions";
import RelatedNotificationsPopup from "../../shared/dashboard/components/relatedNotificationsPopup";
import { useHistory } from "react-router-dom";
import { NotificationApi } from "../../../../core/api/notificationApi";
import isActionAllowed from "../../../../core/authorization/userPermissions";
import { DASHBOARD_VIEWER, REPORT_CATEGORY_CARE_FINANCIALS, } from "../../../../core/constants/permissionConstants";
import useDashboardHierarchy from "../../../../core/hooks/useDashboardHeirarchy";
import { viewTrackedItemChart } from "../../../../core/helpers/dasboardRoute";
import { Button } from "antd";
import html2canvas from "html2canvas";
import $ from "jquery";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfContent } from "../../../../layout/components/customPdfContent";
import DownloadDataLoader from "../../../../layout/components/customPdfContent/DownloadDataLoader";
import { SessionStorage } from "../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../core/constants/SessionVariables";
const MultiWatchListContainer = () => {
    var _a, _b, _c, _d;
    const { isDelphieorSuperUser, isGroupAdmin, isCompanyAdmin, isSingleSiteAdmin, isMultiCompanyAdmin, } = (_a = usePermissions()) !== null && _a !== void 0 ? _a : {};
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);
    const history = useHistory();
    const isPrintMode = useSelector((state) => { var _a; return (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status; });
    // const [isSubmitAction, setIsSubmitAction] = useState(false);
    const isTableSort = useRef(null);
    const controller = useRef(null);
    const [data, setData] = useState(undefined);
    const [notificationCount, setNotificationCount] = useState({});
    const [itemId, setItemId] = useState(SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage));
    const firstPageLoad = useRef(false);
    useEffect(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKWatchlistFilter],
            getLookups: (lookupName) => dispatch(getLookups(lookupName)),
        });
    }, [dispatch]);
    const [selectedSortValue, setSelectedSortValue] = useState(3);
    const [counter, setCounter] = useState(0);
    const apiCompletion = useRef(false);
    const [activeWatchlistTab, setActiveWatchListTab] = useState((_b = SessionStorage.getKey(SessionVariables.WATCHLIST_ACTIVE_TAB)) !== null && _b !== void 0 ? _b : undefined);
    const currentTab = useRef(undefined);
    const [sorting, setSorting] = useState({
        sortColumnName: null,
        sortOrder: null,
    });
    const [selectedItem, setSelectedItem] = useState({});
    const [notificationModelVisibility, setNotificationModelVisibility] = useState(false);
    const [pageSize, setPageSize] = useState(8);
    useLayoutEffect(() => {
        const handlePerPageRecord = () => {
            if (document.getElementsByTagName("tbody")[0]) {
                let tbodyTop = document.getElementsByTagName("tbody")[0];
                const availableHeight = window.innerHeight - tbodyTop.getBoundingClientRect().top;
                const perPage = availableHeight / 54;
                setCurrentPage(1);
                setPageSize((prev) => (isFinite(perPage) ? parseInt(perPage.toString()) : prev) + 1);
            }
        };
        handlePerPageRecord();
        window.addEventListener("resize", (event) => {
            handlePerPageRecord();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeWatchlistTab]);
    const handleGetApiCall = (isLoadMore = false, current = null, tabFilter, tableSort = true) => {
        var _a, _b;
        if (current === null) {
            setItemId(undefined);
        }
        const page = current !== null && current !== void 0 ? current : currentPage + 1;
        if (!isEmpty(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id) || !isEmpty(currentTab)) {
            const watchlistTab = activeWatchlistTab !== undefined
                ? activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id
                : currentTab.current;
            if (watchlistTab !== undefined) {
                if (isLoadMore) {
                    //new instance of abort controller
                    controller.current = new AbortController();
                }
                else if (controller.current) {
                    //if abort controller instance , block request
                    setShouldFetch(false); //preventmulti api call on load more
                    (_a = controller.current) === null || _a === void 0 ? void 0 : _a.abort();
                }
                WatchlistApi.getWatchlist(watchlistTab, tableSort ? -1 : selectedSortValue, page, pageSize, isLoadMore ? "LoadMore" : null, isLoadMore ? (_b = controller.current) === null || _b === void 0 ? void 0 : _b.signal : undefined, tabFilter !== null && tabFilter !== void 0 ? tabFilter : filters).then((res) => {
                    var _a;
                    const itemId = SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage);
                    if (res !== undefined &&
                        itemId !== null &&
                        (res === null || res === void 0 ? void 0 : res.dataList.filter((item) => item.itemId === itemId).length) !== 0) {
                        firstPageLoad.current = false;
                        if (currentPage !== 1) {
                            setCurrentPage((prev) => prev - 1);
                        }
                        SessionStorage.removeKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage);
                    }
                    else if (firstPageLoad.current === true &&
                        currentPage === 1 &&
                        itemId !== null) {
                        setCurrentPage((prev) => prev + 1);
                    }
                    if ((res === null || res === void 0 ? void 0 : res.dataList) && Array.isArray(res === null || res === void 0 ? void 0 : res.dataList)) {
                        const mapId = (_a = res === null || res === void 0 ? void 0 : res.dataList) === null || _a === void 0 ? void 0 : _a.map(({ itemId }) => {
                            return itemId;
                        });
                        NotificationApi.getAllNotificationCount(mapId, true)
                            .then((response) => {
                            setNotificationCount((prev) => {
                                return Object.assign(Object.assign({}, prev), response);
                            });
                        })
                            .catch(() => {
                            // setNotificationCount(response);
                        });
                        if (isLoadMore) {
                            setCurrentPage((prev) => prev + 1);
                            setData((prev) => {
                                if (prev !== undefined) {
                                    return {
                                        dataList: [...prev === null || prev === void 0 ? void 0 : prev.dataList, ...res === null || res === void 0 ? void 0 : res.dataList],
                                        totalRecords: res === null || res === void 0 ? void 0 : res.totalRecords,
                                    };
                                }
                                else {
                                    return prev;
                                }
                            });
                            setShouldFetch(false); //preventmulti api call on load more
                            return;
                        }
                        controller.current = null; //reset abort controller instance
                        setData(res); //on component mount
                    }
                    apiCompletion.current = true;
                });
            }
        }
    };
    const { filters, setFilters } = UsePersistCustomFilters({
        defaultValue: {},
    });
    const { currentPage, setCurrentPage, setShouldFetch, handleScrollToTop } = UseInfiniteScroll({
        handleGetApiCall,
        isScrollMore: ((_c = data === null || data === void 0 ? void 0 : data.dataList) === null || _c === void 0 ? void 0 : _c.length) < (data === null || data === void 0 ? void 0 : data.totalRecords),
        isTableSort,
        filters,
    });
    useEffect(() => {
        if (SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage) !== null) {
            currentTab.current = SessionStorage.getKey(SessionVariables.WATCHLIST_TAB, sessionStorage);
            if (firstPageLoad.current === true) {
                handleGetApiCall(true, currentPage, filters, false);
            }
            else {
                handleGetApiCall(false, 1, filters, false);
                firstPageLoad.current = true;
            }
        }
    }, [currentPage]);
    const scrollDown = () => {
        const targetElement = document.getElementById("expandedRowId");
        targetElement === null || targetElement === void 0 ? void 0 : targetElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
        });
    };
    const { handlePrint, nodeRef } = UsePrintPage();
    const openNotificationModal = (item) => {
        setNotificationModelVisibility(true);
        setSelectedItem(item);
    };
    const closeNotificationModal = () => {
        setNotificationModelVisibility(false);
        setSelectedItem({});
    };
    useEffect(() => {
        if (SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage) === null) {
            handleScrollToTop();
            setCurrentPage(1); //reset current page on changes tab and sort value
            if (Object.keys(filters).length !== 0) {
                isTableSort.current === true
                    ? handleGetApiCall(false, 1, filters, true)
                    : handleGetApiCall(false, 1, filters, false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSortValue, activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id, pageSize, filters]);
    const handleApplyFilter = (filter) => {
        setCounter((prev) => {
            return (prev = prev + 1);
        });
        isTableSort.current = false;
        // setIsSubmitAction(true);
        handleGetApiCall(false, 1, filter !== null && filter !== void 0 ? filter : filters, false);
    };
    useEffect(() => {
        if (isFirstRender.current) {
            if (isDelphieorSuperUser) {
                setFilters(Object.assign(Object.assign({}, filters), { groupId: null }));
            }
            isFirstRender.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isFirstRender,
        isDelphieorSuperUser,
        isSingleSiteAdmin,
        isCompanyAdmin,
        isGroupAdmin,
    ]);
    // console.log({ filters });
    // const printBtn: ITableActions[] = [
    //   {
    //     svgIcon: "Print",
    //     onClick: handlePrint,
    //     priority: 1,
    //     title: ["print"],
    //     renderSvgButton: true,
    //   },
    // ];
    useEffect(() => {
        setFilters(Object.assign(Object.assign({}, filters), { sortingParameters: sorting }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting]);
    const isDashboardViewAllowed = isActionAllowed([DASHBOARD_VIEWER]);
    const isFinancialViewAllowed = isActionAllowed([
        REPORT_CATEGORY_CARE_FINANCIALS,
    ]);
    const { isSinglSite, isSingleCompany, isSingleCompanyAndSite, CompanyByGroup, SiteByCompany, } = useDashboardHierarchy();
    const navigateToDashboard = (id, siteId) => {
        viewTrackedItemChart(id, filters, siteId, history, CompanyByGroup, SiteByCompany, isSingleCompanyAndSite, isSinglSite, isSingleCompany, isDashboardViewAllowed, isFinancialViewAllowed);
    };
    const [pdfContent, setPdfContent] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const loadAllData = () => {
        var _a;
        apiCompletion.current = false;
        setLoadingProgress(0);
        const interval = setInterval(() => {
            setLoadingProgress((loadingProgress) => loadingProgress >= 70 ? loadingProgress : loadingProgress + 20);
        }, 2000);
        document
            .querySelector(".loading-animation-wrapper")
            .classList.add("opacity-zero");
        if (((_a = data === null || data === void 0 ? void 0 : data.dataList) === null || _a === void 0 ? void 0 : _a.length) === (data === null || data === void 0 ? void 0 : data.totalRecords)) {
            apiCompletion.current = true;
        }
        else {
            setPageSize(data === null || data === void 0 ? void 0 : data.totalRecords);
        }
        setLoadingData(true);
        const timer = setInterval(() => {
            if (apiCompletion.current === true) {
                setTimeout(() => {
                    var _a;
                    clearInterval(timer);
                    html2canvas(document.querySelector(".multi-watchlist-wrapper"), {
                        scale: ((_a = document.querySelector(".multi-watchlist-wrapper")) === null || _a === void 0 ? void 0 : _a.scrollHeight) >
                            4500
                            ? 1.25
                            : 2,
                    }).then((canvas) => {
                        const imgData = canvas.toDataURL("image/png");
                        setPdfContent(imgData);
                    });
                    clearInterval(interval);
                    setLoadingProgress(100);
                }, 2000);
            }
        }, 3000);
    };
    useEffect(() => {
        if (pdfContent !== null) {
            setTimeout(() => {
                $(".downloadChat button").click();
                document
                    .querySelector(".loading-animation-wrapper")
                    .classList.remove("opacity-zero");
                setLoadingData(false);
                $(document).ready(function () {
                    $(window).trigger("resize");
                });
                setPdfContent(null);
            }, 2000);
        }
    }, [pdfContent]);
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { leftComponent: React.createElement("h4", null, localize(["DashboardMenus.my", "DashboardMenus.watchList"])), rightComponent: React.createElement("div", { className: "row align-items-center" },
                React.createElement("div", { style: { margin: "0px 50px" } },
                    React.createElement(FiltersDropdownMenu, { groupOptionValues: filters, onChangeGroupOptions: setFilters, onApply: handleApplyFilter, allowClearGroup: isDelphieorSuperUser, removeCompanyId: isGroupAdmin || isMultiCompanyAdmin, removeSiteId: isCompanyAdmin || !isSingleSiteAdmin, showDropDown: true })),
                React.createElement("div", { className: "filterDropdown watchlistDropdown" },
                    React.createElement("label", null, localize(["Watchlist.sortBy"])),
                    React.createElement(LookupDropDown, { placeHolder: isTableSort.current === true ? "Select Sorting" : null, selectedValue: isTableSort.current === true ? null : selectedSortValue, onChange: (e) => {
                            setCounter((prev) => {
                                return (prev = prev + 1);
                            });
                            isTableSort.current = false;
                            setSelectedSortValue(e);
                        }, localizationKey: ["Watchlist.sort"], lookUpType: LookupNames.LKWatchlistFilter })),
                React.createElement("div", null,
                    React.createElement(Button, { type: "primary", onClick: loadAllData, id: "downloadBtn", key: `${loadingData}`, disabled: loadingData === true ? true : false }, loadingData === true ? "Downloading..." : "Download"),
                    React.createElement(PDFDownloadLink, { fileName: "Watchlist Data", className: "downloadChat d-none", document: React.createElement(PdfContent, { image: pdfContent }) },
                        React.createElement(Button, { onClick: null })),
                    React.createElement(PrintButton, { printOnClick: handlePrint }))) }),
        React.createElement(DownloadDataLoader, { percent: loadingProgress, loadingData: loadingData, screen: "watchlist", callBack: () => {
                document
                    .querySelector(".loading-animation-wrapper")
                    .classList.remove("opacity-zero");
                SessionStorage.setKey(SessionVariables.WATCHLIST_ACTIVE_TAB, activeWatchlistTab);
            } }),
        React.createElement("div", { className: "multi-watchlist-wrapper" },
            React.createElement(WatchlistTabs, { activeWatchlistTab: activeWatchlistTab, setActiveWatchListTab: setActiveWatchListTab, setData: setData }),
            React.createElement(MultiWatchListExpandedTable, { selectedSortValue: selectedSortValue, isPrintMode: isPrintMode || loadingData, activeWatchListTab: activeWatchlistTab, data: (_d = data === null || data === void 0 ? void 0 : data.dataList) !== null && _d !== void 0 ? _d : [], notificationCount: notificationCount, setParentData: setData, setSorting: setSorting, sorting: sorting, itemId: itemId, isTableSort: isTableSort, openNotificationModal: openNotificationModal, navigateToDashboard: navigateToDashboard })),
        React.createElement(RelatedNotificationsPopup, { itemId: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.itemId, visible: notificationModelVisibility, onClose: closeNotificationModal, selectedItem: selectedItem, setSelectedItem: setSelectedItem, browserHistory: history, setListData: setData, showFlag: false }),
        itemId && scrollDown()));
};
export default MultiWatchListContainer;
