var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AccountApi } from "../../api/accountApi";
import { SessionVariables } from "../../constants/SessionVariables";
import { SessionStorage } from "../localStorage/sessionStorage";
export function saveContext(selectedOption = null) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const userContext = SessionStorage.getKey(SessionVariables.USER_CONTEXT);
        const listingFilter = selectedOption !== null && selectedOption !== void 0 ? selectedOption : (_a = SessionStorage.getKey(SessionVariables.LISTING_FILTERS, sessionStorage)) === null || _a === void 0 ? void 0 : _a[`/vertical/dashboard/`];
        let storedContext = {};
        if (Object.keys(listingFilter).length > 0) {
            storedContext = {
                selectedFilter: {
                    code: listingFilter.code,
                    text: listingFilter.text,
                },
            };
        }
        else {
            storedContext = null;
        }
        const savedContext = { text: JSON.stringify(storedContext) };
        userContext.lastVisitedContext = JSON.stringify(savedContext);
        SessionStorage.setKey(SessionVariables.USER_CONTEXT, userContext);
        yield AccountApi.storeContext(savedContext);
    });
}
