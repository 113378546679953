import React from "react";
import Asterik from "../../../shared/helper-components/Asterik";
import { DatePicker, Input } from "antd";
import localize from "../../../../../core/utils/translation-files";
import ConditionalViewFormField from "../../../../../layout/components/conditionalViewFormField";
import LookupDropDown from "../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../core/constants/lookup";
import moment from "moment";
import { disabledDate } from "../../../../../core/validations/dateTimeValidations";
import { useSelector } from "react-redux";
import { CompanyType } from "../../../../../core/enums/CompanyType";
import useDidMount from "../../../../../core/hooks/useDidMount";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import { getLookups } from "../../../../../redux/lookup/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
const SiteDetailsFormFields = ({ values, errors, touched, handleChange, setFieldValue, isReadOnly, getLookups, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKFinancialSystem, LookupNames.LKMonth],
            getLookups,
        });
    });
    const setFinancialMonth = (e) => {
        setFieldValue("siteDetail.financialMonth", e === 12 ? e - 11 : e + 1);
    };
    const months = useSelector((state) => { var _a; return (_a = state.lookups[LookupNames.LKMonth]) !== null && _a !== void 0 ? _a : []; });
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col bold-text" },
                    React.createElement("label", null,
                        localize(["Site.financialYearEnd"]),
                        " ",
                        React.createElement(Asterik, { hidden: isReadOnly }))),
                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: ((_a = values.siteDetail) === null || _a === void 0 ? void 0 : _a.financialMonth)
                        ? months.map((item) => {
                            if (("0" +
                                (parseInt(values.siteDetail.financialMonth) !== 1
                                    ? +values.siteDetail.financialMonth - 1
                                    : parseInt(values.siteDetail.financialMonth) + 11)).slice(-2) === (item === null || item === void 0 ? void 0 : item.code)) {
                                return item === null || item === void 0 ? void 0 : item.text;
                            }
                            else
                                return null;
                        })
                        : null },
                    React.createElement(LookupDropDown, { disabled: values.id > 0 && !!((_b = values === null || values === void 0 ? void 0 : values.siteDetail) === null || _b === void 0 ? void 0 : _b.financialMonth), className: "mb-2 mb-md-0", localizationKey: ["Site.financialYearEnd"], selectedValue: ((_c = values.siteDetail) === null || _c === void 0 ? void 0 : _c.financialMonth)
                            ? parseInt(("0" +
                                (parseInt(values.siteDetail.financialMonth) !== 1
                                    ? +values.siteDetail.financialMonth - 1
                                    : parseInt(values.siteDetail.financialMonth) + 11)).slice(-2))
                            : null, customOnChangeHandler: setFinancialMonth, inputName: "siteDetail.financialMonth", lookUpType: LookupNames.LKMonth, error: ((_d = errors === null || errors === void 0 ? void 0 : errors.siteDetail) === null || _d === void 0 ? void 0 : _d.financialMonth) &&
                            ((_e = touched === null || touched === void 0 ? void 0 : touched.siteDetail) === null || _e === void 0 ? void 0 : _e.financialMonth)
                            ? (_f = errors === null || errors === void 0 ? void 0 : errors.siteDetail) === null || _f === void 0 ? void 0 : _f.financialMonth
                            : null })))),
        values.type === CompanyType.Site && (React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col bold-text" },
                    React.createElement("label", null,
                        localize(["Site.numOfRegisteredBeds"]),
                        " ",
                        React.createElement(Asterik, { hidden: isReadOnly }))),
                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: (_g = values === null || values === void 0 ? void 0 : values.siteDetail) === null || _g === void 0 ? void 0 : _g.noOfRegisteredBeds },
                    React.createElement(Input, { type: "text", name: "siteDetail.noOfRegisteredBeds", placeholder: localize(["Site.numOfRegisteredBeds"]), readOnly: isReadOnly, onChange: handleChange, value: (_h = values === null || values === void 0 ? void 0 : values.siteDetail) === null || _h === void 0 ? void 0 : _h.noOfRegisteredBeds, className: `${errors &&
                            errors.siteDetail &&
                            errors.siteDetail.noOfRegisteredBeds &&
                            touched &&
                            touched.siteDetail &&
                            touched.siteDetail.noOfRegisteredBeds
                            ? "input-error"
                            : null}` }),
                    errors &&
                        errors.siteDetail &&
                        errors.siteDetail.noOfRegisteredBeds &&
                        touched &&
                        touched.siteDetail &&
                        touched.siteDetail.noOfRegisteredBeds && (React.createElement("p", { className: "error" }, errors.siteDetail.noOfRegisteredBeds)))))),
        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col bold-text" },
                    React.createElement("label", null,
                        localize(["Site.financialSystem"]),
                        " ",
                        React.createElement(Asterik, { hidden: isReadOnly }))),
                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: (_j = values.siteDetail) === null || _j === void 0 ? void 0 : _j.financialSystemText },
                    React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: (_k = values.siteDetail) === null || _k === void 0 ? void 0 : _k.financialSystem, onChange: (e, value) => {
                            setFieldValue(`siteDetail.financialSystem`, value);
                            if (value === 1) {
                                setFieldValue("siteDetail.tenantId", "");
                                setFieldValue("siteDetail.refreshToken", "");
                                setFieldValue("siteDetail.accessToken", "");
                                setFieldValue("siteDetail.clientId", "");
                                setFieldValue("siteDetail.clientSecret", "");
                            }
                        }, inputName: "siteDetail.financialSystem", localizationKey: ["Site.financialSystem"], lookUpType: LookupNames.LKFinancialSystem, error: errors.siteDetail &&
                            errors.siteDetail.financialSystem &&
                            touched &&
                            touched.siteDetail &&
                            touched.siteDetail.financialSystem && (React.createElement("p", { className: "error" }, errors.siteDetail.financialSystem)) })))),
        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col bold-text" },
                    React.createElement("label", null,
                        localize(["Site.ingestionStartYear"]),
                        " ",
                        React.createElement(Asterik, { hidden: isReadOnly }))),
                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.ingestionYear },
                    React.createElement(DatePicker, { value: ((_l = values.siteDetail) === null || _l === void 0 ? void 0 : _l.ingestionYear)
                            ? moment(values.siteDetail.ingestionYear.toString())
                            : null, className: `mb-2 mb-md-0 d-block ant-input  ${((_m = errors === null || errors === void 0 ? void 0 : errors.siteDetail) === null || _m === void 0 ? void 0 : _m.ingestionYear) &&
                            ((_o = touched === null || touched === void 0 ? void 0 : touched.siteDetail) === null || _o === void 0 ? void 0 : _o.ingestionYear)
                            ? `input-error`
                            : ``}`, disabledDate: disabledDate, allowClear: false, placeholder: localize(["Site.ingestionStartYear"]), onChange: (date, dateString) => {
                            setFieldValue(`siteDetail.ingestionYear`, +dateString);
                        }, picker: "year" }),
                    errors.siteDetail &&
                        errors.siteDetail.ingestionYear &&
                        touched.siteDetail &&
                        touched.siteDetail.ingestionYear && (React.createElement("p", { className: "error" }, errors.siteDetail.ingestionYear))))),
        values.type === CompanyType.Site && (React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
            React.createElement("div", { className: "form-group" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null, localize(["Site.weight"]))),
                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: (_p = values.siteDetail) === null || _p === void 0 ? void 0 : _p.weight },
                    React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: (_q = values.siteDetail) === null || _q === void 0 ? void 0 : _q.weight, onChange: setFieldValue, inputName: "siteDetail.weight", localizationKey: ["TrackedItem.weight"], lookUpType: LookupNames.LKReportWeight, disabled: true })))))));
};
function mapStateTopProps(state) {
    return {
        lookups: state.lookups,
    };
}
const mapDispatchToProps = {
    getLookups,
};
SiteDetailsFormFields.propTypes = {
    getLookups: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(SiteDetailsFormFields);
