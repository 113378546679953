import React from "react";
import { ComposedChart, ResponsiveContainer, YAxis } from "recharts";
import { Bar, LabelList } from "recharts";
const HistoricalChartScale = ({ data, isPopup, isMainChart = false, showScale, itemData, percentageValuMinMax, weight, weightFlag, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    var colorData = (_a = data === null || data === void 0 ? void 0 : data.colorCodeList) !== null && _a !== void 0 ? _a : [];
    colorData = colorData === null || colorData === void 0 ? void 0 : colorData.map((colorCode, index) => {
        let updateData = Object.assign({}, colorCode);
        if (!index) {
            updateData.startPoint = (data === null || data === void 0 ? void 0 : data.systemCreated) ? 0 : updateData === null || updateData === void 0 ? void 0 : updateData.startPoint;
            // -  percentageValuMinMax?.bottom;
        }
        else if (colorData.length === index + 1) {
            updateData.endPoint = (data === null || data === void 0 ? void 0 : data.systemCreated) ? 1 : updateData === null || updateData === void 0 ? void 0 : updateData.endPoint;
            //  +  percentageValuMinMax?.top;
        }
        return updateData;
    });
    var newData = {};
    colorData === null || colorData === void 0 ? void 0 : colorData.map(({ order, startPoint, endPoint }, index) => {
        var _a, _b;
        if (index === 0 && Math.sign((_a = colorData[index]) === null || _a === void 0 ? void 0 : _a.startPoint) === -1) {
            newData = {
                blank: (_b = colorData[index]) === null || _b === void 0 ? void 0 : _b.startPoint,
            };
        }
        newData = Object.assign(Object.assign({}, newData), {
            [index]: endPoint - startPoint,
            // (index === colorData.length - 1 && data?.systemCreated === true
            //   ? 1
            //   : endPoint) - (index === 0 && data?.systemCreated ? 0 : startPoint),
        });
    });
    const chartData = [newData];
    return (React.createElement("div", { style: { height: "100%" } },
        React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
            React.createElement(ComposedChart, { data: chartData, margin: { bottom: 1 } },
                React.createElement(YAxis, { type: "number", interval: 0, domain: (data === null || data === void 0 ? void 0 : data.systemCreated)
                        ? [0, 1]
                        : [
                            (_b = colorData[0]) === null || _b === void 0 ? void 0 : _b.startPoint,
                            ((_c = colorData[(colorData === null || colorData === void 0 ? void 0 : colorData.length) - 1]) === null || _c === void 0 ? void 0 : _c.endPoint) -
                                (Math.sign((_d = colorData[0]) === null || _d === void 0 ? void 0 : _d.startPoint) === -1
                                    ? 0
                                    : (_e = colorData[0]) === null || _e === void 0 ? void 0 : _e.startPoint),
                        ], padding: {
                        top: isPopup || isMainChart ? 20 : 10,
                        bottom: isPopup || isMainChart ? 20 : 10,
                    }, 
                    // ticks={data?.systemCreated ? null : yAxisTickArray()}
                    // tick={yAxisFormat}
                    tickCount: (data === null || data === void 0 ? void 0 : data.systemCreated) && 5, width: 0 }),
                Math.sign((_f = colorData[0]) === null || _f === void 0 ? void 0 : _f.startPoint) === -1 && (React.createElement(Bar, { dataKey: "blank", stackId: (_g = colorData[0]) === null || _g === void 0 ? void 0 : _g.order, fill: "white", key: "blank" })),
                colorData.map(({ color, bandName }, index) => {
                    var _a;
                    return (React.createElement(Bar, { dataKey: index, stackId: (_a = colorData[0]) === null || _a === void 0 ? void 0 : _a.order, fill: color, key: index, isAnimationActive: false },
                        React.createElement(LabelList, { values: `${index}`, offset: 0, position: "center", stroke: "black", strokeWidth: "1px", fontSize: isPopup ? 12 : 10 }, (weight !== 0 && !weightFlag) ? bandName : "")));
                })))));
};
export default HistoricalChartScale;
