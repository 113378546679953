import isEmpty from "../helpers/isEmpty";
import Fuse from "fuse.js";
export const sortFunction = (current, next, keyProperty) => {
    let [a, b] = [current[keyProperty], next[keyProperty]];
    if ((!isNaN(+a) && !isNaN(parseInt(a))) ||
        (!isNaN(+b) && !isNaN(parseInt(b)))) {
        //number case
        return a > b ? -1 : 1;
    }
    else if (isNaN(+a) &&
        isNaN(+b) &&
        (!isNaN(parseInt(a)) || !isNaN(parseInt(b)))) {
        /// i.e a="205 super" always greate than string (!isNaN(parseInt("205 super")) to 205 which should be in last in order")
        return a > b ? -1 : 1;
    }
    else if (typeof a === "string") {
        a = a === null || a === void 0 ? void 0 : a.toLowerCase().trim();
        b = b === null || b === void 0 ? void 0 : b.toLowerCase().trim();
    }
    return a > b ? 1 : -1;
};
export const sortArray = (list, keyProperty) => {
    if (isEmpty(list))
        return [];
    return [...list].sort((current, next) => sortFunction(current, next, keyProperty));
};
export const sortGroupOptions = (groupOption) => {
    const sortedList = sortArray(groupOption, "name");
    if (Array.isArray(sortedList)) {
        return sortedList.map((value) => (Object.assign(Object.assign({}, value), { listSimpleDataDTO: sortGroupOptions(value === null || value === void 0 ? void 0 : value.listSimpleDataDTO) })));
    }
    return sortedList;
};
export const getOptionsById = (groupOptions, groupId = null, companyId = null, siteId = null) => {
    var _a, _b;
    if (isEmpty(groupId))
        return null;
    const groupValues = groupOptions.find(({ id }) => id === groupId);
    if (isEmpty(companyId))
        return groupValues;
    const companyValues = (_a = groupValues === null || groupValues === void 0 ? void 0 : groupValues.listSimpleDataDTO) === null || _a === void 0 ? void 0 : _a.find(({ id }) => id === companyId);
    if (isEmpty(siteId))
        return companyValues;
    return (_b = companyValues === null || companyValues === void 0 ? void 0 : companyValues.listSimpleDataDTO) === null || _b === void 0 ? void 0 : _b.find(({ id }) => id === siteId);
};
export const genericSearch = (data, columns, searchvalue) => {
    if (isEmpty(searchvalue))
        return data;
    let keysToSearch = columns;
    const isNested = typeof keysToSearch[0] === "string";
    if (!isNested) {
        keysToSearch = keysToSearch
            .map((column) => {
            var _a;
            return ((_a = column === null || column === void 0 ? void 0 : column.filterInfo) === null || _a === void 0 ? void 0 : _a.nestedKey)
                ? `${column.fieldName}.${column.filterInfo.nestedKey}`
                : column.fieldName;
        })
            .filter((key) => !(key === null || key === void 0 ? void 0 : key.includes("actions")));
    }
    const options = {
        includeMatches: true,
        minMatchCharLength: 1,
        ignoreLocation: true,
        includeScore: true,
        keys: keysToSearch,
    };
    const fuseIndex = Fuse.createIndex(keysToSearch, data);
    const fuse = new Fuse(data, options, fuseIndex);
    const searchResult = fuse.search(`${searchvalue}`);
    if (isNested) {
        return searchResult
            .map(({ matches, item }) => {
            const matchFilter = matches.filter(({ value }) => String(value).toLowerCase().includes(searchvalue.toLowerCase()));
            //  if (matchFilter.find(({ key }) => !key.includes("."))) return item;
            const filterItem = item.data.filter((child) => matchFilter.some(({ key, value }) => {
                const [parent, nestedKey] = key === null || key === void 0 ? void 0 : key.split(".");
                return child[nestedKey] === value;
            }));
            return Object.assign(Object.assign({}, item), { data: filterItem });
        })
            .filter((value) => { var _a; return (_a = value.data) === null || _a === void 0 ? void 0 : _a.length; });
    }
    return searchResult
        .filter(({ matches }) => matches.some(({ value }) => String(value).toLowerCase().includes(searchvalue.toLowerCase())))
        .map(({ item }) => item);
};
export const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};
