var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { UserApi } from "../../../../../core/api/userApi";
import { RolesListingTable } from "../userListing/components/rolesListingTable";
const ExpiredRolesContainer = ({ companyId, userId }) => {
    const [expiredRoles, setExpiredRoles] = useState([]);
    const getRoles = () => __awaiter(void 0, void 0, void 0, function* () {
        if (expiredRoles.length === 0) {
            const userRoles = yield UserApi.getUserHistoricalRoles(companyId, userId);
            setExpiredRoles(userRoles);
        }
    });
    useEffect(() => {
        getRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(RolesListingTable, { key: "expiredRoles", userRoles: expiredRoles, showUser: false }));
};
export default ExpiredRolesContainer;
