import React from "react";
import { curry } from "lodash-es";
import { convertToColoredNumericRange, convertToInternalColoredNumericRange, } from "./utils";
import { NumericColoredLine } from "./numericColoredLine";
import { NumericColoredRangeValueControl } from "./numericColoredRangeValueControl";
import { NumericColoredRangeValueTooltip } from "./numericColoredRangeValueTooltip";
import { TrackedItemsVariables } from "../../../core/constants/TrackedItemsConstants";
export var BlockType;
(function (BlockType) {
    BlockType["DarkGreen"] = "DarkGreen";
    BlockType["Green"] = "Green";
    BlockType["Amber"] = "Amber";
    BlockType["Red"] = "Red";
})(BlockType || (BlockType = {}));
export var Side;
(function (Side) {
    Side["Left"] = "Left";
    Side["Right"] = "Right";
})(Side || (Side = {}));
const colorClassName = {
    darkGreen: "color-darkGreen",
    green: "color-green",
    amber: "color-amber",
    red: "color-red",
    grey: "color-grey",
};
export const NumericColoredRanges = ({ onChange, value, disabled: controlDisabled, rightAddDisabled = false, leftAddDisabled = false, removeDisabled = false, category = undefined, weight, weightFlag, bandScales, }) => {
    const getRiskBand = (band) => {
        return bandScales === null || bandScales === void 0 ? void 0 : bandScales.map((bandInfo) => bandInfo.bandName === band &&
            (category < 2
                ? `${band}(${bandInfo.riskBand})`
                : category === +TrackedItemsVariables.FinancialCategoryCode
                    ? `${band}(${bandInfo.financialRiskBand})`
                    : `${band}`));
    };
    const convertedValue = convertToInternalColoredNumericRange(value);
    const { red, orange, green, darkGreen } = convertedValue;
    const canAddOnLeft = !red.leftEdge.doesExist ||
        !orange.leftEdge.doesExist ||
        !green.leftEdge.doesExist;
    const canAddOnRight = !red.rightEdge.doesExist ||
        !orange.rightEdge.doesExist ||
        !green.rightEdge.doesExist;
    const curriedGetUpdatedValue = curry(getUpdatedValue)(convertedValue);
    const disabled = controlDisabled;
    return (React.createElement("div", { className: "numeric-colored-ranges" },
        React.createElement("div", { className: `range-values ${canAddOnLeft ? "" : "left-ended"} ${canAddOnRight ? "" : "right-ended"}` },
            red.leftEdge.doesExist && (React.createElement(NumericColoredRangeValueControl, { colorClassName: colorClassName.red, value: red.leftEdge.value, onChange: (v) => onChange(curriedGetUpdatedValue((current) => (current.red.leftEdge.value = v))), disabled: disabled, renderTooltip: (id) => (React.createElement(NumericColoredRangeValueTooltip, { id: id, color: BlockType.Red, side: Side.Left, value: convertedValue })) })),
            orange.leftEdge.doesExist && (React.createElement(NumericColoredRangeValueControl, { colorClassName: colorClassName.amber, value: orange.leftEdge.value, onChange: (v) => onChange(curriedGetUpdatedValue((current) => (current.orange.leftEdge.value = v))), disabled: disabled, renderTooltip: (id) => (React.createElement(NumericColoredRangeValueTooltip, { id: id, color: BlockType.Amber, side: Side.Left, value: convertedValue })) })),
            green.leftEdge.doesExist && (React.createElement(NumericColoredRangeValueControl, { colorClassName: colorClassName.green, value: green.leftEdge.value, onChange: (v) => onChange(curriedGetUpdatedValue((current) => (current.green.leftEdge.value = v))), disabled: disabled, renderTooltip: (id) => (React.createElement(NumericColoredRangeValueTooltip, { id: id, color: BlockType.Green, side: Side.Left, value: convertedValue })) })),
            React.createElement(NumericColoredRangeValueControl, { colorClassName: colorClassName.darkGreen, value: darkGreen.leftEdge.value, onChange: (v) => onChange(curriedGetUpdatedValue((current) => (current.darkGreen.leftEdge.value = v))), disabled: disabled, renderTooltip: (id) => (React.createElement(NumericColoredRangeValueTooltip, { id: id, color: BlockType.DarkGreen, side: Side.Left, value: convertedValue })) }),
            React.createElement(NumericColoredRangeValueControl, { colorClassName: colorClassName.darkGreen, value: darkGreen.rightEdge.value, onChange: (v) => onChange(curriedGetUpdatedValue((current) => (current.darkGreen.rightEdge.value = v))), disabled: disabled, renderTooltip: (id) => (React.createElement(NumericColoredRangeValueTooltip, { id: id, color: BlockType.DarkGreen, side: Side.Right, value: convertedValue })) }),
            green.rightEdge.doesExist && (React.createElement(NumericColoredRangeValueControl, { colorClassName: colorClassName.green, value: green.rightEdge.value, onChange: (v) => onChange(curriedGetUpdatedValue((current) => (current.green.rightEdge.value = v))), disabled: disabled, renderTooltip: (id) => (React.createElement(NumericColoredRangeValueTooltip, { id: id, color: BlockType.Green, side: Side.Right, value: convertedValue })) })),
            orange.rightEdge.doesExist && (React.createElement(NumericColoredRangeValueControl, { colorClassName: colorClassName.amber, value: orange.rightEdge.value, onChange: (v) => onChange(curriedGetUpdatedValue((current) => (current.orange.rightEdge.value = v))), disabled: disabled, renderTooltip: (id) => (React.createElement(NumericColoredRangeValueTooltip, { id: id, color: BlockType.Amber, side: Side.Right, value: convertedValue })) })),
            red.rightEdge.doesExist && (React.createElement(NumericColoredRangeValueControl, { colorClassName: colorClassName.red, value: red.rightEdge.value, onChange: (v) => onChange(curriedGetUpdatedValue((current) => (current.red.rightEdge.value = v))), disabled: disabled, renderTooltip: (id) => (React.createElement(NumericColoredRangeValueTooltip, { id: id, color: BlockType.Red, side: Side.Right, value: convertedValue })) }))),
        React.createElement("div", { className: "colored-lines" },
            canAddOnLeft && (React.createElement(NumericColoredLine, { colorClassName: colorClassName.grey, onAdd: disabled || leftAddDisabled
                    ? undefined
                    : () => onChange(addOnLeft(convertedValue)), weight: weight, weightFlag: weightFlag })),
            red.leftEdge.doesExist && (React.createElement(NumericColoredLine, { colorClassName: colorClassName.red, getRiskBand: getRiskBand, onClear: disabled || removeDisabled
                    ? undefined
                    : () => onChange(curriedGetUpdatedValue(clearLeftEdge)), weight: weight, weightFlag: weightFlag })),
            orange.leftEdge.doesExist && (React.createElement(NumericColoredLine, { colorClassName: colorClassName.amber, getRiskBand: getRiskBand, onClear: disabled || removeDisabled
                    ? undefined
                    : () => onChange(curriedGetUpdatedValue(clearLeftEdge)), weight: weight, weightFlag: weightFlag })),
            green.leftEdge.doesExist && (React.createElement(NumericColoredLine, { colorClassName: colorClassName.green, getRiskBand: getRiskBand, onClear: disabled || removeDisabled
                    ? undefined
                    : () => onChange(curriedGetUpdatedValue(clearLeftEdge)), weight: weight, weightFlag: weightFlag })),
            React.createElement(NumericColoredLine, { colorClassName: colorClassName.darkGreen, getRiskBand: getRiskBand, weight: weight, weightFlag: weightFlag }),
            green.rightEdge.doesExist && (React.createElement(NumericColoredLine, { colorClassName: colorClassName.green, getRiskBand: getRiskBand, onClear: disabled || removeDisabled
                    ? undefined
                    : () => onChange(curriedGetUpdatedValue(clearRightEdge)), weight: weight, weightFlag: weightFlag })),
            orange.rightEdge.doesExist && (React.createElement(NumericColoredLine, { colorClassName: colorClassName.amber, getRiskBand: getRiskBand, onClear: disabled || removeDisabled
                    ? undefined
                    : () => onChange(curriedGetUpdatedValue(clearRightEdge)), weight: weight, weightFlag: weightFlag })),
            red.rightEdge.doesExist && (React.createElement(NumericColoredLine, { colorClassName: colorClassName.red, getRiskBand: getRiskBand, onClear: disabled || removeDisabled
                    ? undefined
                    : () => onChange(curriedGetUpdatedValue(clearRightEdge)), weight: weight, weightFlag: weightFlag })),
            canAddOnRight && (React.createElement(NumericColoredLine, { colorClassName: colorClassName.grey, onAdd: disabled || rightAddDisabled
                    ? undefined
                    : () => onChange(addOnRight(convertedValue)), weight: weight, weightFlag: weightFlag })))));
};
const clearLeftEdge = (c) => {
    c.red.leftEdge.doesExist = false;
    c.orange.leftEdge.doesExist = false;
    c.green.leftEdge.doesExist = false;
};
const clearRightEdge = (c) => {
    c.red.rightEdge.doesExist = false;
    c.orange.rightEdge.doesExist = false;
    c.green.rightEdge.doesExist = false;
};
const addOnLeft = (current) => {
    if (!current.red.leftEdge.doesExist && !current.orange.leftEdge.doesExist) {
        // const updated=
        return getUpdatedValue(current, (c) => {
            c.red.leftEdge.doesExist = true;
            c.orange.leftEdge.doesExist = true;
            c.green.leftEdge.doesExist = true;
        });
    }
};
const addOnRight = (current) => {
    if (!current.red.rightEdge.doesExist && !current.orange.rightEdge.doesExist) {
        return getUpdatedValue(current, (c) => {
            c.red.rightEdge.doesExist = true;
            c.orange.rightEdge.doesExist = true;
            c.green.rightEdge.doesExist = true;
        });
    }
};
const getUpdatedValue = (current, updateAction) => {
    updateAction(current);
    return convertToColoredNumericRange(current);
};
