import { first, isEmpty, last } from "lodash-es";
import { BenchmarkDirectionType } from "../../../core/enums/benchmarkDirection";
import { BlockType, } from "./numericColoredRanges";
// export const storeSpacingValues = (direction, spacingArray) => {
//   let benchmarkValues =
//     direction === BenchmarkDirectionType.Upwards
//       ? ["spvD", "spvC", "spvB", "spvA"]
//       : direction === BenchmarkDirectionType.Downwards
//       ? ["spvA", "spvB", "spvC", "spvD"]
//       : direction === BenchmarkDirectionType.Middle
//       ? [
//           "spvLowerD",
//           "spvLowerB",
//           "spvLowerC",
//           "spvA",
//           "spvUpperB",
//           "spvUpperC",
//           "spvUpperD",
//         ]
//       : [];
//   let spacingValues = [];
//   for (var i = 0; i < benchmarkValues?.length; i++) {
//     spacingValues = [
//       {
//         ...spacingValues[0],
//         [benchmarkValues[i]]:
//           direction === BenchmarkDirectionType.Upwards
//             ? spacingArray[i]?.rule?.from?.value
//             : direction === BenchmarkDirectionType.Downwards
//             ? spacingArray[i]?.rule?.to?.value
//             : i < 4
//             ? spacingArray[i]?.rule?.from?.value
//             : spacingArray[i]?.rule?.to?.value,
//       },
//     ];
//   }
//   return spacingValues;
// };
// export const getSpacingValues = (direction, benchmark) => {
//   const benchmarkValues =
//     direction === BenchmarkDirectionType.Upwards
//       ? [benchmark?.spvD, benchmark?.spvC, benchmark?.spvB, benchmark?.spvA]
//       : direction === BenchmarkDirectionType.Downwards
//       ? [
//           undefined,
//           benchmark?.spvA,
//           benchmark?.spvB,
//           benchmark?.spvC,
//           benchmark?.spvD,
//         ]
//       : direction === BenchmarkDirectionType.Middle && [
//           benchmark?.spvLowerD,
//           benchmark?.spvLowerC,
//           benchmark?.spvLowerB,
//           benchmark?.spvA,
//           undefined,
//           benchmark?.spvUpperB,
//           benchmark?.spvUpperC,
//           benchmark?.spvUpperD,
//         ];
//   let types = ["Red", "Amber", "Green", "DarkGreen", "Green", "Amber", "Red"];
//   types =
//     direction === BenchmarkDirectionType.Downwards ? types.slice(-4) : types;
//   const loopVariable =
//     direction === BenchmarkDirectionType.Upwards
//       ? benchmarkValues?.length
//       : benchmarkValues?.length - 1;
//   let spacings = [];
//   for (var i = 0; i < loopVariable; i++) {
//     spacings = [
//       ...spacings,
//       {
//         type: types[i],
//         rule: {
//           from:
//             benchmarkValues[i] || benchmarkValues[i] === 0
//               ? {
//                   include: true,
//                   value: benchmarkValues[i],
//                 }
//               : undefined,
//           to:
//             benchmarkValues[i + 1] || benchmarkValues[i + 1] === 0
//               ? {
//                   include: false,
//                   value: benchmarkValues[i + 1],
//                 }
//               : undefined,
//         },
//       },
//     ];
//   }
//   return spacings;
// };
export const getRatingValues = (direction, benchmark) => {
    const benchmarkValues = direction === BenchmarkDirectionType.Upwards
        ? [
            benchmark === null || benchmark === void 0 ? void 0 : benchmark.minRating,
            benchmark === null || benchmark === void 0 ? void 0 : benchmark.criticalLevel,
            benchmark === null || benchmark === void 0 ? void 0 : benchmark.concernLevel,
            benchmark === null || benchmark === void 0 ? void 0 : benchmark.optimalLevel,
            benchmark === null || benchmark === void 0 ? void 0 : benchmark.maxRating,
        ]
        : direction === BenchmarkDirectionType.Downwards
            ? [
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.minRating,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.optimalLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.concernLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.criticalLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.maxRating,
            ]
            : direction === BenchmarkDirectionType.Middle && [
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.minRating,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.lowerCriticalLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.lowerConcernLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.lowerOptimalLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.upperOptimalLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.upperConcernLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.upperCriticalLevel,
                benchmark === null || benchmark === void 0 ? void 0 : benchmark.maxRating,
            ];
    let types = ["Red", "Amber", "Green", "DarkGreen", "Green", "Amber", "Red"];
    types =
        direction === BenchmarkDirectionType.Downwards ? types.slice(-4) : types;
    let ranges = [];
    for (var i = 0; i < (benchmarkValues === null || benchmarkValues === void 0 ? void 0 : benchmarkValues.length) - 1; i++) {
        ranges = [
            ...ranges,
            {
                type: types[i],
                rule: {
                    from: benchmarkValues[i] || benchmarkValues[i] === 0
                        ? {
                            include: true,
                            value: benchmarkValues[i],
                        }
                        : undefined,
                    to: benchmarkValues[i + 1] || benchmarkValues[i + 1] === 0
                        ? {
                            include: false,
                            value: benchmarkValues[i + 1],
                        }
                        : undefined,
                },
            },
        ];
    }
    return ranges;
};
export function convertToInternalColoredNumericRange(ranges) {
    const result = {
        darkGreen: {
            rightEdge: {
                doesExist: true,
            },
            leftEdge: {
                doesExist: true,
            },
        },
        green: {
            rightEdge: {
                doesExist: false,
            },
            leftEdge: {
                doesExist: false,
            },
        },
        orange: {
            rightEdge: {
                doesExist: false,
            },
            leftEdge: {
                doesExist: false,
            },
        },
        red: {
            rightEdge: {
                doesExist: false,
            },
            leftEdge: {
                doesExist: false,
            },
        },
    };
    if (!ranges || isEmpty(ranges)) {
        return result;
    }
    let afterDarkGreen = false;
    for (const r of ranges) {
        const action = afterDarkGreen
            ? setRangeValueAfterDarkGreen
            : setRangeValueBeforeDarkGreen;
        switch (r.type) {
            case BlockType.Red:
                action(result.red, r);
                break;
            case BlockType.Amber:
                action(result.orange, r);
                break;
            case BlockType.Green:
                action(result.green, r);
                break;
            case BlockType.DarkGreen:
                setRangeValueForDarkGreen(result.darkGreen, r);
                afterDarkGreen = true;
                break;
            default:
                throw new Error(`Invalid type: ${r.type}`);
        }
    }
    return result;
}
function setRangeValueBeforeDarkGreen({ leftEdge }, current) {
    leftEdge.doesExist = true;
    leftEdge.value = current.rule.from && current.rule.from.value;
}
function setRangeValueAfterDarkGreen({ rightEdge }, current) {
    rightEdge.doesExist = true;
    rightEdge.value = current.rule.to && current.rule.to.value;
}
function setRangeValueForDarkGreen(range, current) {
    range.leftEdge.value = current.rule.from && current.rule.from.value;
    range.rightEdge.value = current.rule.to && current.rule.to.value;
}
export function convertToColoredNumericRange({ red, orange, green, darkGreen, }) {
    const darkGreenRangeElement = getDarkGreenRangeElement(darkGreen);
    const leftRanges = [];
    if (green.leftEdge.doesExist) {
        leftRanges.unshift(getLeftRangeElement(leftRanges, BlockType.Green, green, darkGreen));
    }
    if (orange.leftEdge.doesExist) {
        leftRanges.unshift(getLeftRangeElement(leftRanges, BlockType.Amber, orange, darkGreen));
    }
    if (red.leftEdge.doesExist) {
        leftRanges.unshift(getLeftRangeElement(leftRanges, BlockType.Red, red, darkGreen));
    }
    const rightRanges = [];
    if (green.rightEdge.doesExist) {
        rightRanges.push(getRightRangeElement(rightRanges, BlockType.Green, green, darkGreen));
    }
    if (orange.rightEdge.doesExist) {
        rightRanges.push(getRightRangeElement(rightRanges, BlockType.Amber, orange, darkGreen));
    }
    if (red.rightEdge.doesExist) {
        rightRanges.push(getRightRangeElement(rightRanges, BlockType.Red, red, darkGreen));
    }
    return [...leftRanges, darkGreenRangeElement, ...rightRanges];
}
function getDarkGreenRangeElement(darkGreen) {
    return {
        type: BlockType.DarkGreen,
        rule: {
            from: darkGreen.leftEdge.value !== undefined
                ? {
                    include: true,
                    value: darkGreen.leftEdge.value,
                }
                : undefined,
            to: darkGreen.rightEdge.value !== undefined
                ? {
                    include: true,
                    value: darkGreen.rightEdge.value,
                }
                : undefined,
        },
    };
}
function getLeftRangeElement(all, type, { leftEdge }, darkGreen) {
    const f = first(all);
    return {
        type: type,
        rule: {
            from: leftEdge.value !== undefined
                ? {
                    include: true,
                    value: leftEdge.value,
                }
                : undefined,
            to: {
                include: false,
                value: f ? f.rule.from && f.rule.from.value : darkGreen.leftEdge.value,
            },
        },
    };
}
function getRightRangeElement(all, type, { rightEdge }, darkGreen) {
    const l = last(all);
    return {
        type: type,
        rule: {
            to: rightEdge.value !== undefined
                ? {
                    include: true,
                    value: rightEdge.value,
                }
                : undefined,
            from: {
                include: false,
                value: l ? l.rule.to && l.rule.to.value : darkGreen.rightEdge.value,
            },
        },
    };
}
