import { CLEAR_TRACKED_ITEMS_PER_SITE_SUCCESS, GET_TRACKED_ITEMS_PER_SITE_SUCCESS, } from "./types";
const initialState = [];
export const trackedItemsPerSiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRACKED_ITEMS_PER_SITE_SUCCESS: {
            return [...action.payload];
        }
        case CLEAR_TRACKED_ITEMS_PER_SITE_SUCCESS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
