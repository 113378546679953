import React from "react";
import { actionColumnFilterOptions } from "../../../../../../core/constants/actionColumnFilterOptions";
import { SiteResponse, } from "../../../../../../core/contracts/site/siteContracts";
import CustomTable from "../../../../../../layout/components/customTable";
import CustomTextHighlighter from "../../../../../../layout/components/customTextHighlighter";
const SiteTable = ({ data, actions, currentPage, pageSize, handleCustomTablePropChange = null, isPrintMode = false, paginationSection = null, }) => {
    const columns = [
        {
            fieldName: "name",
            title: ["name"],
            width: 150,
            sortable: true,
            defaultSortOrder: "ascend",
            className: "bold-text",
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "emailAddress",
            title: ["Site.emailAddress"],
            width: 150,
            sortable: true,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "primaryTelephoneNumber",
            title: ["Site.contactNumber"],
            width: 150,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "addresses",
            title: ["address"],
            width: 150,
            render: (addresses) => (React.createElement("div", { className: "overflow-text-ellipsis", title: addresses },
                React.createElement(CustomTextHighlighter, { text: addresses }))),
        },
        {
            fieldName: "siteDetail",
            title: ["Site.financialSystem"],
            width: 150,
            filterInfo: {
                nestedKey: "financialSystem",
            },
            render: (siteDetail) => (React.createElement("div", { className: "overflow-text-ellipsis", title: siteDetail },
                React.createElement(CustomTextHighlighter, { text: siteDetail.financialSystemText }))),
        },
        {
            fieldName: "siteDetail",
            title: ["Company.website"],
            width: 150,
            filterInfo: {
                nestedKey: "website",
            },
            render: (siteDetail) => {
                let url = "";
                if (siteDetail && siteDetail.website) {
                    if (siteDetail.website.substring(0, 8) !== "https://") {
                        url = "https://" + siteDetail.website;
                    }
                    else {
                        url = siteDetail.website;
                    }
                }
                return siteDetail && siteDetail.website ? (
                // eslint-disable-next-line react/jsx-no-target-blank
                React.createElement("a", { href: siteDetail && siteDetail.website && url ? url : "-", target: "_blank" },
                    React.createElement(CustomTextHighlighter, { text: siteDetail && siteDetail.website ? siteDetail.website : "-" }))) : ("-");
            },
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        width: 150,
        enableFilter: true,
        filterInfo: {
            listing: actionColumnFilterOptions,
            nestedKey: "active",
        },
        render: actions,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { className: "customListing", "data-test": "siteTableComponent" },
        React.createElement(CustomTable, { data: data, itemEnableKey: "active", columns: displayedColumns, dataType: SiteResponse, currentPage: currentPage, pageSize: pageSize, totalCount: paginationSection === null || paginationSection === void 0 ? void 0 : paginationSection.totalRecords, scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: paginationSection })));
};
export default SiteTable;
