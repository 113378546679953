import { NotificationApi } from "../../../core/api/notificationApi";
import isEmpty from "../../../core/helpers/isEmpty";
import { DISABLE_GLOBAL_NOTIFICATION_SUCCESS, ENABLE_GLOBAL_NOTIFICATION_SUCCESS, GET_GLOBAL_NOTIFICATIONS_SUCCESS, } from "./types";
export const getGlobalNotificationsSuccess = (data) => ({
    type: GET_GLOBAL_NOTIFICATIONS_SUCCESS,
    payload: data,
});
export const enableGlobalNotificationSuccess = (data) => ({
    type: ENABLE_GLOBAL_NOTIFICATION_SUCCESS,
    payload: data,
});
export const disableGlobalNotificationSuccess = (data) => ({
    type: DISABLE_GLOBAL_NOTIFICATION_SUCCESS,
    payload: data,
});
export function getGlobalNotifications(categoryType) {
    return function (dispatch) {
        return NotificationApi.getGlobalNotifications(categoryType)
            .then((notifications) => {
            if (Array.isArray(notifications)) {
                dispatch(getGlobalNotificationsSuccess(notifications));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function enableGlobalNotification(data) {
    return function (dispatch) {
        return NotificationApi.activateGlobalNotification(data)
            .then((response) => {
            if (!isEmpty(response) && !(response === null || response === void 0 ? void 0 : response.code)) {
                dispatch(enableGlobalNotificationSuccess(data));
                return response;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function disableGlobalNotification(data) {
    return function (dispatch) {
        return NotificationApi.inActivateGlobalNotification(data)
            .then((response) => {
            if (!isEmpty(response) && !(response === null || response === void 0 ? void 0 : response.code)) {
                dispatch(disableGlobalNotificationSuccess(data));
                return response;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
