var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CompanyApi } from "../api/companyApi";
export function isUniqueSiteCQCNumber(value, companies, editedSite) {
    //
    if (!value) {
        return true;
    }
    if (editedSite && editedSite.cqcRegistrationNumber !== "") {
        if ((companies &&
            companies.some((el) => el.sites &&
                el.sites.some((ele) => ele.cqcRegistrationNumber === value && ele.id !== editedSite.id))) ||
            (companies && companies.some((el) => el.cqcRegistrationNumber === value))) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        // In Case of Adding the Company
        if ((companies &&
            companies.some((el) => el.sites &&
                el.sites.some((ele) => ele.cqcRegistrationNumber === value))) ||
            (companies && companies.some((el) => el.cqcRegistrationNumber === value))) {
            return false;
        }
        else {
            return true;
        }
    }
}
export function isUniqueCompanyCQCNumber(value, companies, editCompany) {
    if (!value || value === "") {
        return true;
    }
    if (!companies || companies.length === 0) {
        return true;
    }
    if (editCompany && editCompany.cqcRegistrationNumber !== "") {
        if ((companies &&
            companies.some((el) => el.sites &&
                el.sites.some((ele) => ele.cqcRegistrationNumber === value))) ||
            (companies &&
                companies.some((el) => el.cqcRegistrationNumber === value && el.id !== editCompany.id))) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        // In Case of Adding the Company
        if ((companies &&
            companies.some((el) => el.sites &&
                el.sites.some((ele) => ele.cqcRegistrationNumber === value))) ||
            (companies && companies.some((el) => el.cqcRegistrationNumber === value))) {
            return false;
        }
        else {
            return true;
        }
    }
}
export const isUniqueExternalReference = (value, edited) => __awaiter(void 0, void 0, void 0, function* () {
    if (!value)
        return true;
    if (edited) {
        if (value === edited)
            return true;
    }
    const res = yield CompanyApi.companyExist(+value).then((res) => res);
    return !res;
});
