import { Button } from "antd";
import React, { useState } from "react";
import { WatchlistApi } from "../../../../../../core/api/watchlistApi";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../core/utils/translation-files";
import CustomInputField from "../../../../../../layout/components/customInputField";
import CustomModal from "../../../../../../layout/components/customModal";
const WatchlistRenameComponent = ({ handleToggleNameModalVisibility, activeWatchlistTab, tabsArray, nameModalVisible, setActiveWatchListTab, setTabsArray, }) => {
    const [name, setName] = useState(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.watchListName);
    const [error, setError] = useState(false);
    const handleChange = (event) => {
        setError(true);
        setName(event.target.value.trim());
    };
    const handleCancel = () => {
        if (name !== (activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.watchListName)) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("Changes you have made will be lost")) {
                setError(false);
                setName(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.watchListName);
                handleToggleNameModalVisibility();
            }
            else {
            }
        }
        else
            handleToggleNameModalVisibility();
    };
    const renameWatchlist = () => {
        WatchlistApi.renameWatchlist(name, activeWatchlistTab.id).then((res) => {
            setActiveWatchListTab(res);
            const newTabsArray = tabsArray === null || tabsArray === void 0 ? void 0 : tabsArray.map((item) => {
                return (item === null || item === void 0 ? void 0 : item.id) === (res === null || res === void 0 ? void 0 : res.id) ? res : item;
            });
            setTabsArray(newTabsArray);
            setError(false);
            handleToggleNameModalVisibility();
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomModal, { visible: nameModalVisible, onCancel: handleCancel, maskClosable: true, destroyOnClose: true, footer: null, title: ["rename", "DashboardMenus.watchList"], modelClass: "CreateWatchlistPopup" },
            React.createElement("div", { className: "newWatchListFormContainer" },
                React.createElement(CustomInputField, { label: localize(["name"]), type: "text", defaultValue: activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.watchListName, onChange: handleChange, errorMessage: error &&
                        (tabsArray === null || tabsArray === void 0 ? void 0 : tabsArray.some(({ watchListName }) => watchListName.toLowerCase() === (name === null || name === void 0 ? void 0 : name.toLowerCase())))
                        ? `Watchlist with same name already exist!`
                        : undefined }),
                React.createElement(Button, { type: "primary", onClick: renameWatchlist, disabled: isEmpty(name) ||
                        (tabsArray === null || tabsArray === void 0 ? void 0 : tabsArray.some(({ watchListName }) => watchListName.toLowerCase() === (name === null || name === void 0 ? void 0 : name.toLowerCase()))) }, localize(["rename"]))))));
};
export default WatchlistRenameComponent;
