import React from "react";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../../core/utils/translation-files";
import CustomModal from "../../../../../../../layout/components/customModal";
import { NumericColoredRanges } from "../../../../../../../layout/components/numericColoredRanges/numericColoredRanges";
import { getRatingValues } from "../../../../../../../layout/components/numericColoredRanges/utils";
export default function ChartVariablesModal({ chartVariables, visible, onClose, hideDescription = false, }) {
    const isMiddle = (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.requiredBenchmarkDirection) ===
        TrackedItemsVariables.BENCHMARK_MIDDLE_DIRECTION;
    return (React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["ChartVariables.chartVariables"], width: "min-content", inlineStyle: { minWidth: "68%" } },
        React.createElement("div", { className: "row col-12 customInformationModal", "data-test": "chartVariables" },
            React.createElement("div", { className: "col-3" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.shortTermAverage"])),
                React.createElement("p", { className: "color-black" }, !isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.shortTermMovingAveragePeriod)
                    ? (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.shortTermMovingAveragePeriod) +
                        " " +
                        (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.dataFrequencyText)
                    : "")),
            React.createElement("div", { className: "col-3" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.midTermAverage"])),
                React.createElement("p", { className: "color-black" }, !isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.midTermMovingAveragePeriod)
                    ? (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.midTermMovingAveragePeriod) +
                        " " +
                        (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.dataFrequencyText)
                    : "")),
            React.createElement("div", { className: "col-3" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.longTermAverage"])),
                React.createElement("p", { className: "color-black" }, !isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.longTermMovingAveragePeriod)
                    ? (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.longTermMovingAveragePeriod) +
                        " " +
                        (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.dataFrequencyText)
                    : "")),
            React.createElement("div", { className: "col-3" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.projectionMovingAverage"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.projectionAverageUsedText)),
            React.createElement("div", { className: "col-3" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.projectionPoint"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.projectionPoint)),
            React.createElement("div", { className: "col-3" },
                React.createElement("h6", { className: "mt-0 mb-0" }, (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.requiredBenchmarkDirectionText) === "Upwards"
                    ? localize(["ChartVariables.dateofHighestPeak"])
                    : localize(["ChartVariables.dateofLowestPeak"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.dateOfPeak)),
            React.createElement("div", { className: "col-3" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.weight"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.weightText)),
            React.createElement("div", { className: "col-3" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.levelOfRisk"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.itemRiskLevelText)),
            React.createElement("div", { className: "col-3 chart_variable_item" },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["ChartVariables.benchmark"])),
                React.createElement("p", { className: "color-black" }, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.benchmarkName)),
            React.createElement("div", { className: `${isMiddle ? `col-lg-12 customRating` : `col-9`} chart_variable_item`, style: { marginBottom: "10px" } },
                React.createElement("h6", { className: "mt-0 mb-0" }, localize(["TrackedItem.rating"])),
                React.createElement(NumericColoredRanges, { value: getRatingValues(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.requiredBenchmarkDirection, chartVariables), disabled: true, category: chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.itemCategoryId, weight: chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.weight })))));
}
