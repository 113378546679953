import { TaskType } from "./../../core/enums/TaskType";
import { GET_TASKS_SUCCESS, ON_TASK_SUBMITED_SUCCESS } from "./types";
const initialState = {
    dueTask: [],
    completedTask: [],
};
export const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TASKS_SUCCESS: {
            if (action.taskType === TaskType.Completed) {
                return Object.assign(Object.assign({}, state), { completedTask: action.payload });
            }
            return Object.assign(Object.assign({}, state), { dueTask: action.payload });
        }
        case ON_TASK_SUBMITED_SUCCESS:
            return Object.assign({}, initialState);
        default: {
            return state;
        }
    }
};
