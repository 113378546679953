import React, { useEffect, useState } from "react";
import { Select } from "antd";
import localize from "../../../core/utils/translation-files";
import isEmpty from "../../../core/helpers/isEmpty";
import { LookupApi } from "../../../core/api/lookupApi";
import { useSelector } from "react-redux";
import UseToggleStateOnScroll from "../../../core/hooks/useToggleStateOnScroll";
const Option = Select.Option;
const LookupChildDropdown = ({ selectedValue, onChange, defaultValue, className = "mb-lg-0 mb-4", disabled = false, labelInValue = false, allowClear = false, textProperty = "text", valueProperty = "code", parentLookUpType, inputName, error, valueType = "number", showSearch = true, mode, customOnChangeHandler = false, localizationKey, parentId, }) => {
    const [data, setData] = useState([]);
    const optionFilter = (input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
    const parentLookupData = useSelector((state) => { var _a; return (_a = state.lookups[parentLookUpType]) !== null && _a !== void 0 ? _a : []; });
    useEffect(() => {
        var _a;
        if (!isEmpty(parentId) && parentLookupData.length) {
            const parentPrimaryId = (_a = parentLookupData === null || parentLookupData === void 0 ? void 0 : parentLookupData.find((x) => x.code === parentId.toString())) === null || _a === void 0 ? void 0 : _a.id;
            LookupApi.getChildLookupByParentId(parentPrimaryId).then((response) => {
                setData(response);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentId, parentLookupData]);
    const handleChange = (value) => {
        let updatedValue = mode === "multiple" ? value : value !== null && value !== void 0 ? value : "";
        if (customOnChangeHandler) {
            customOnChangeHandler(value);
        }
        else if (inputName) {
            onChange(inputName, updatedValue);
            return;
        }
        else {
            onChange(updatedValue);
        }
    };
    const { visible, setVisible } = UseToggleStateOnScroll();
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { "data-test": "single-select", labelInValue: labelInValue, allowClear: allowClear, disabled: disabled, showSearch: showSearch, className: `${className} ${error ? `require-ant-select` : ``}`, mode: mode, defaultValue: mode === "multiple" && selectedValue === ""
                ? []
                : isEmpty(selectedValue)
                    ? undefined
                    : selectedValue, value: mode === "multiple" && selectedValue === ""
                ? []
                : isEmpty(selectedValue)
                    ? undefined
                    : selectedValue, placeholder: localize(["selectA", ...localizationKey]), optionFilterProp: "children", filterOption: optionFilter, onChange: handleChange, open: visible, onDropdownVisibleChange: setVisible, key: `select-${localizationKey[0]}` }, data === null || data === void 0 ? void 0 : data.map((option, key) => (React.createElement(Option, { key: option[valueProperty], value: valueType === "number"
                ? parseInt(option[valueProperty])
                : option[valueProperty], id: "option-div-container" }, option[textProperty])))),
        React.createElement("p", { className: "error" }, error)));
};
const Memoize = React.memo(LookupChildDropdown);
export default Memoize;
