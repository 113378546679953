import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { SessionVariables } from "../constants/SessionVariables";
import { browserHistoryMemoryContext } from "../contexts/browserHistoryMemoryContext";
import { SessionStorage } from "../helpers/localStorage/sessionStorage";
const { LISTING_FILTERS } = SessionVariables;
const { getKey, setKey, removeKey } = SessionStorage;
const UsePersistCustomFilters = ({ additionalPersistRoutes = [], clearContextCallBack, defaultValue = null, unmountContextRemoval = true, shouldSetSession = true, }) => {
    var _a, _b;
    const history = useHistory();
    //browser history context for memory router
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const browserHistoryProp = useMemo(() => {
        return browserHistory !== null && browserHistory !== void 0 ? browserHistory : history;
    }, [browserHistory, history]);
    const listingPath = useRef(`${(_a = browserHistoryProp === null || browserHistoryProp === void 0 ? void 0 : browserHistoryProp.location) === null || _a === void 0 ? void 0 : _a.pathname}/`);
    const sessionFilterValues = (_b = getKey(LISTING_FILTERS, sessionStorage)) === null || _b === void 0 ? void 0 : _b[listingPath.current];
    const [filters, setFilters] = useState(sessionFilterValues !== null && sessionFilterValues !== void 0 ? sessionFilterValues : defaultValue);
    const handleSetFilterSession = (value = null) => {
        setKey(LISTING_FILTERS, {
            [listingPath.current]: value !== null && value !== void 0 ? value : filters,
        }, sessionStorage);
    };
    useEffect(() => {
        if (shouldSetSession) {
            handleSetFilterSession();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);
    useEffect(() => {
        return () => persistFilterSameContext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [browserHistoryProp]);
    //navigatedRoute => next path on unmount parent listing container and pathname is listing route
    const persistFilterSameContext = () => {
        var _a;
        //list /vertical/users and relative i.e /vertical/users/add
        if (!unmountContextRemoval)
            return;
        const navigatedRoute = (_a = browserHistoryProp === null || browserHistoryProp === void 0 ? void 0 : browserHistoryProp.location) === null || _a === void 0 ? void 0 : _a.pathname;
        const isNotlistRelativePath = !navigatedRoute.includes(listingPath.current);
        const isNotPersisRoute = !additionalPersistRoutes.some((persisRoute) => navigatedRoute.includes(persisRoute));
        if (additionalPersistRoutes.length
            ? isNotlistRelativePath && isNotPersisRoute
            : isNotlistRelativePath) {
            clearContextCallBack === null || clearContextCallBack === void 0 ? void 0 : clearContextCallBack();
            removeKey(LISTING_FILTERS, sessionStorage);
        }
    };
    return {
        setFilters,
        filters,
        handleSetFilterSession,
    };
};
export default UsePersistCustomFilters;
