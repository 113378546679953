import store from "../../redux/configureStore";
const getFrequencyData = (code) => {
    var _a;
    if (code === 0) {
        return "Days";
    }
    if (!code) {
        return "?";
    }
    const state = store.getState();
    const dataFrequency = (_a = state === null || state === void 0 ? void 0 : state.lookups) === null || _a === void 0 ? void 0 : _a.LKReportTypeDataFrequency;
    if ((dataFrequency === null || dataFrequency === void 0 ? void 0 : dataFrequency.length) > 0) {
        const filtered = dataFrequency === null || dataFrequency === void 0 ? void 0 : dataFrequency.filter((item) => item.code === code + "");
        if (filtered && filtered.length > 0) {
            return filtered[0].text;
        }
        else {
            return "?";
        }
    }
};
export default getFrequencyData;
