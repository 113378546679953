import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { printContext } from "../../../../../../../../core/contexts/printContext";
import { actionColumnFilterOptions } from "../../../../../../../../core/constants/actionColumnFilterOptions";
import CustomTextHighlighter from "../../../../../../../../layout/components/customTextHighlighter";
import CustomTable from "../../../../../../../../layout/components/customTable";
const UserCreatedtrackedItemTable = ({ data, actions, paginationProps = null, currentPage, pageSize, filters, handleCustomTablePropChange = null, isPrintMode = false, paginationSection = null, }) => {
    const { nodeRef } = useContext(printContext);
    const expandedColumns = [
        {
            fieldName: "name",
            title: ["name"],
            sortable: true,
            className: "bold-text",
            defaultSortOrder: "ascend",
            width: 230,
            render: (text) => React.createElement(CustomTextHighlighter, { text: text }),
        },
        {
            fieldName: "weightText",
            title: ["TrackedItem.weight"],
            width: 100,
            enableFilter: true,
            render: (_, trackedItem) => {
                let weight = trackedItem.weight;
                let weightText = weight === 0 ? "unrateable" : weight;
                return (React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement(CustomTextHighlighter, { text: weightText }))));
            },
        },
        {
            fieldName: "bandColor",
            title: ["TrackedItem.rating"],
            width: 100,
            render: (bandColor) => (React.createElement("div", null,
                React.createElement(FontAwesomeIcon, { icon: faCircle, style: { color: bandColor ? bandColor : "grey" }, size: "lg" }))),
        },
        {
            fieldName: "benchmarkName",
            title: ["TrackedItem.benchmark", "name"],
            enableFilter: true,
            width: 150,
            render: (benchmarkName) => (React.createElement("div", null,
                React.createElement("p", null,
                    React.createElement(CustomTextHighlighter, { text: benchmarkName })))),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        enableFilter: true,
        filterInfo: {
            //disabled is true in current data source DTO
            listing: actionColumnFilterOptions.map(({ text, value }) => ({
                text,
                value: !value,
            })),
            nestedKey: "disabled",
        },
        render: actions,
        width: 100,
    };
    const expandedDisplayedColumns = actions
        ? [...expandedColumns, actionColumn]
        : expandedColumns;
    // const columns: IColumnType[] = [
    //   {
    //     fieldName: "companyName",
    //     width: 230,
    //     title: ["TrackedItem.site"],
    //     defaultSortOrder: "ascend",
    //     sortable: true,
    //     className: "bold-text",
    //     render: (text) => <CustomTextHighlighter text={text} />,
    //   },
    //   {
    //     fieldName: "darkGreen",
    //     width: 70,
    //     title: ["A"],
    //     align: "center",
    //     render: (darkGreen) => (
    //       <div>
    //         <span className="indicator online darkGreen"></span> &nbsp; &nbsp;{" "}
    //         <span style={{ verticalAlign: "text-bottom" }}>{darkGreen}</span>
    //       </div>
    //     ),
    //   },
    //   {
    //     fieldName: "green",
    //     width: 70,
    //     title: ["B"],
    //     align: "center",
    //     render: (green) => (
    //       <div>
    //         <span className="indicator online green"></span> &nbsp; &nbsp;{" "}
    //         <span style={{ verticalAlign: "text-bottom" }}>{green}</span>
    //       </div>
    //     ),
    //   },
    //   {
    //     fieldName: "amber",
    //     width: 70,
    //     dataIndex: "amber",
    //     title: ["C"],
    //     align: "center",
    //     render: (amber) => (
    //       <div>
    //         <span className="indicator online amber"></span> &nbsp; &nbsp;{" "}
    //         <span style={{ verticalAlign: "text-bottom" }}>{amber}</span>
    //       </div>
    //     ),
    //   },
    //   {
    //     fieldName: "red",
    //     width: 70,
    //     title: ["D"],
    //     align: "center",
    //     render: (red) => (
    //       <>
    //         <div>
    //           <span className="indicator online red"></span> &nbsp; &nbsp;{" "}
    //           <span style={{ verticalAlign: "text-bottom" }}>{red}</span>
    //         </div>
    //       </>
    //     ),
    //   },
    //   {
    //     fieldName: "grey",
    //     width: 70,
    //     title: ["TrackedItem.grey"],
    //     align: "center",
    //     render: (grey) => (
    //       <div>
    //         <span className="indicator online grey"></span> &nbsp; &nbsp;
    //         <span style={{ verticalAlign: "text-bottom" }}>{grey}</span>
    //       </div>
    //     ),
    //   },
    //   {
    //     width: 200,
    //     fieldName: "totalEnabledReports",
    //     title: ["TrackedItem.totalEnabled", "TrackedItem.trackedItems"],
    //     render: (totalEnabledReports) => {
    //       const title = `Total Enabled Tracked Items: ${totalEnabledReports}`;
    //       return (
    //         <Tooltip title={title}>
    //           <div
    //             style={{
    //               whiteSpace: "nowrap",
    //               textAlign: "center",
    //               textOverflow: "ellipsis",
    //               overflow: "hidden",
    //             }}
    //           >
    //             <p>
    //               <CustomTextHighlighter text={title} />
    //             </p>
    //           </div>
    //         </Tooltip>
    //       );
    //     },
    //   },
    // ];
    return (React.createElement("div", { className: "trackedItemListing", "data-test": "tracked-item-listing-table", ref: nodeRef },
        React.createElement(CustomTable, { data: data, columns: expandedDisplayedColumns, dataType: "any", currentPage: currentPage, pageSize: pageSize, totalCount: paginationSection === null || paginationSection === void 0 ? void 0 : paginationSection.totalRecords, itemEnableKey: "disabled", scroll: false, clientPagination: !isPrintMode, customTablePropChange: handleCustomTablePropChange, paginationSection: paginationSection })));
};
export default UserCreatedtrackedItemTable;
