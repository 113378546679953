import { initialState as initialStoreState } from "../initialState";
import { ADD_PENDINGUSER_SUCCESS, DELETE_PENDINGUSER_SUCCESS, GET_PENDINGUSERS_SUCCESS, CLEAR_PENDINGUSER_LISTING_SUCCESS, } from "./types";
const initialState = initialStoreState.pendingUsers;
export const pendingUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PENDINGUSERS_SUCCESS: {
            return [...action.payload];
        }
        case ADD_PENDINGUSER_SUCCESS: {
            return [Object.assign({}, action.payload), ...state];
        }
        case DELETE_PENDINGUSER_SUCCESS: {
            const users = state.filter((el) => el.id !== action.payload);
            return [...users];
        }
        case CLEAR_PENDINGUSER_LISTING_SUCCESS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
