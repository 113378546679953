import React from "react";
import { relationsListingScrollPosition } from "../../../../../../../core/constants/tableScrollConstants";
import localize from "../../../../../../../core/utils/translation-files";
import CustomTable from "../../../../../../../layout/components/customTable";
function DefaultRelationsTable({ dataSource }) {
    const columns = [
        {
            title: `${localize(["TrackedItem.itemName"])}`,
            fieldName: "name",
            width: 400,
        },
        {
            title: `${localize(["TrackedItem.required"])}`,
            fieldName: "disabled",
            width: 80,
            align: "center",
            render: (value, row) => (React.createElement("span", null, value === false ? "Yes" : "No")
            // <Switch className="ml-1" checked={value === true} disabled={true} />
            ),
        },
        {
            title: `${localize(["TrackedItem.summaryItem"])}`,
            fieldName: "summaryItem",
            width: 100,
            align: "center",
            render: (value, row) => (React.createElement("span", null, value === true ? "Yes" : "No")
            // <Checkbox name="summaryItem" checked={value === true} disabled={true} />
            ),
        },
        {
            title: `${localize(["email"])}`,
            fieldName: "emailFlag",
            width: 100,
            align: "center",
            render: (value, row) => React.createElement("span", null, value === true ? "Yes" : "No"),
        },
    ];
    return (React.createElement("div", { className: "box-main-section" },
        React.createElement("div", { className: "relationsListing" },
            React.createElement(CustomTable, { data: dataSource, dataType: "any", columns: columns, scrollPosition: relationsListingScrollPosition }))));
}
export default DefaultRelationsTable;
