import React from "react";
import isEmpty from "../../../core/helpers/isEmpty";
import localize from "../../../core/utils/translation-files";
import RenderSvg from "../icons/RenderSvg";
const PageDescription = ({ title, description }) => {
    var _a;
    return (React.createElement("div", { className: "pageDescription" },
        React.createElement(RenderSvg, { icon: "Info", style: {
                padding: "10px 15px",
                background: "#efcefe",
                borderRight: "1px solid #d08bc9",
            } }),
        React.createElement("span", { className: "descriptionArea" },
            React.createElement("strong", null, (_a = localize(title)) !== null && _a !== void 0 ? _a : "",
                " "),
            isEmpty(description) ? "No description" : description)));
};
export default PageDescription;
