import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.scss";
import store from "./redux/configureStore";
import { Provider } from "react-redux";
import "./i18n";
render(React.createElement(Provider, { store: store },
    React.createElement(Router, null,
        React.createElement(App, null))), document.getElementById("app"));
