import { DEFAULT_GROUP } from "../context/context";
import { GET_GROUP_HIERARCHY, } from "./types";
export const groupHierarchyReducer = (state = DEFAULT_GROUP, action) => {
    switch (action.type) {
        case GET_GROUP_HIERARCHY: {
            return [...action.payload];
        }
        default: {
            return state;
        }
    }
};
