var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { LookupNames } from "../../../../../core/constants/lookup";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import useDidMount from "../../../../../core/hooks/useDidMount";
import usePermissions from "../../../../../core/hooks/usePermissions";
import localize from "../../../../../core/utils/translation-files";
import CustomModal from "../../../../../layout/components/customModal";
import { notifications } from "../../../../../layout/components/notifications";
import { getLookups } from "../../../../../redux/lookup/actions";
import { addClientNotification } from "../../../../../redux/notification/client/actions";
import OverWriteForm from "./overwriteForm";
const OverwriteGlobalModel = ({ visible, onClose, selectedNotification, addClientNotification, selectedFilters, getLookups, valueRef, }) => {
    var _a, _b, _c;
    const { defaultGroupValues, defaultCompanyValues, defaultSiteValues, isCompanyAdmin, isSiteAdmin, isDelphieorSuperUser, } = usePermissions();
    const initialNotification = {
        id: 0,
        name: selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.name,
        notificationDefinitionId: (_a = selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.notificationDefinitionId) !== null && _a !== void 0 ? _a : selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.id,
        referenceId: undefined,
        recipient: (_c = (_b = selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.recipient) === null || _b === void 0 ? void 0 : _b.split(",")) !== null && _c !== void 0 ? _c : [],
        enabled: true,
        //permission dropdown handling
        groupId: defaultGroupValues === null || defaultGroupValues === void 0 ? void 0 : defaultGroupValues.id,
        companyId: defaultCompanyValues === null || defaultCompanyValues === void 0 ? void 0 : defaultCompanyValues.id,
        siteId: defaultSiteValues === null || defaultSiteValues === void 0 ? void 0 : defaultSiteValues.id,
    };
    const userBandOptions = useSelector((state) => { var _a; return (_a = state.lookups[LookupNames.LKUserBand]) !== null && _a !== void 0 ? _a : []; });
    const [clientNotification, setClientNotification] = useState(initialNotification);
    ///check if group/company/site in overwrite form are same as in dropdown options
    // and concat it in client notification listing state
    const shouldConcatWithState = (notification) => {
        const { groupId, companyId, siteId } = notification;
        const { groupId: filterGroupId, companyId: filterCompanyId, siteId: filterSiteId, } = selectedFilters;
        let isSameFilterValue = !isEmpty(selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.groupId);
        if (!isSameFilterValue) {
            if (groupId === filterGroupId &&
                isEmpty(filterCompanyId) &&
                isEmpty(filterSiteId)) {
                isSameFilterValue = true;
            }
            else if (groupId === filterGroupId &&
                companyId === filterCompanyId &&
                isEmpty(filterSiteId)) {
                isSameFilterValue = true;
            }
            else if (groupId === filterGroupId &&
                companyId === filterCompanyId &&
                siteId === filterSiteId) {
                isSameFilterValue = true;
            }
        }
        return isSameFilterValue;
    };
    const overwriteNotificationHandler = (notification, resetForm) => {
        var _a;
        const { groupId, companyId, siteId, recipient } = notification, rest = __rest(notification, ["groupId", "companyId", "siteId", "recipient"]);
        const request = Object.assign(Object.assign({}, rest), { companyId: (_a = siteId !== null && siteId !== void 0 ? siteId : companyId) !== null && _a !== void 0 ? _a : groupId, recipient: recipient.toString() });
        const shouldConcat = shouldConcatWithState(notification);
        addClientNotification(request, shouldConcat).then((response) => {
            if (response) {
                resetForm();
                onClose();
                if (response) {
                    if (!isEmpty(response.Message)) {
                        notifications.success(response.Message);
                    }
                    else {
                        notifications.success(localize([
                            "Notification.notification",
                            "Notification.overwritten",
                            "successfully",
                        ]));
                    }
                }
            }
        });
    };
    const loadEditClientNotification = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        if (!isEmpty(selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.groupId)) {
            const { categoryText, categoryType, groupName, referenceType, referenceTypeText, resetFlag, siteName, companyName, key, keyType, message } = selectedNotification, rest = __rest(selectedNotification, ["categoryText", "categoryType", "groupName", "referenceType", "referenceTypeText", "resetFlag", "siteName", "companyName", "key", "keyType", "message"]);
            const clientNotification = Object.assign({}, rest);
            clientNotification.recipient =
                (_e = (_d = selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.recipient) === null || _d === void 0 ? void 0 : _d.toString().split(",")) !== null && _e !== void 0 ? _e : [];
            setClientNotification(clientNotification);
        }
        else {
            setClientNotification(initialNotification);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [selectedNotification]);
    useEffect(() => {
        loadEditClientNotification();
    }, [loadEditClientNotification]);
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKUserBand],
            getLookups,
        });
    });
    return (React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["Notification.notification"], subTitle: selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.name },
        React.createElement(OverWriteForm, { onSubmit: overwriteNotificationHandler, onCancel: onClose, notification: clientNotification, permission: { isCompanyAdmin, isSiteAdmin, isDelphieorSuperUser }, userBandOptions: userBandOptions, valuesRef: valueRef })));
};
// const mapStateTopProps = (state) => {
//   return {
//     lookups
//   };
// }
const mapDispatchToProps = {
    addClientNotification,
    getLookups,
};
export default connect(null, mapDispatchToProps)(OverwriteGlobalModel);
