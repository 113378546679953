import { useEffect, useState } from "react";
const UseInfiniteScroll = ({ handleGetApiCall, isScrollMore, isTableSort = null, filters = null, querySelectorClassName = ".ant-table-body", }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [shouldFetch, setShouldFetch] = useState(false);
    const handleScrollToTop = () => document
        .querySelector(querySelectorClassName)
        .scrollTo({ behavior: "smooth", top: 0 });
    useEffect(() => {
        const doc = document.querySelector(querySelectorClassName);
        const handleLoadMore = (node) => {
            if (isScrollMore) {
                if (node.target.scrollTop > 0 && //not for horizontal scroll
                    node.target.offsetHeight + node.target.scrollTop + 3 >=
                        node.target.scrollHeight) {
                    // console.log("fetch more", {
                    //   offsetHeight: node.target.offsetHeight,
                    //   scrollTop: node.target.scrollTop,
                    //   scrollHeight: node.target.scrollHeight,
                    // });
                    setShouldFetch(true);
                }
            }
        };
        doc.addEventListener("scroll", handleLoadMore);
        return () => doc.removeEventListener("scroll", handleLoadMore);
    }, [isScrollMore]);
    useEffect(() => {
        if (shouldFetch) {
            (isTableSort === null || isTableSort === void 0 ? void 0 : isTableSort.current) === true
                ? handleGetApiCall(true)
                : handleGetApiCall(true, null, filters, false);
        }
    }, [shouldFetch]);
    return {
        handleScrollToTop,
        setCurrentPage,
        currentPage,
        setShouldFetch,
    };
};
export default UseInfiniteScroll;
