import { GET_CLIENT_NOTIFICATION_SUCCESS, ADD_CLIENT_NOTIFICATION_SUCCESS, UPDATE_CLIENT_NOTIFICATION_SUCCESS, ENABLE_CLIENT_NOTIFICATION_SUCCESS, DISABLE_CLIENT_NOTIFICATION_SUCCESS, RESET_CLIENT_NOTIFICATION_SUCCESS, } from "./types";
const initialState = [];
export const clientNotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENT_NOTIFICATION_SUCCESS: {
            return [...action.payload];
        }
        case UPDATE_CLIENT_NOTIFICATION_SUCCESS: {
            const editedNotification = state.map((el) => el.id !== action.payload.id ? el : action.payload);
            return [...editedNotification];
        }
        case ADD_CLIENT_NOTIFICATION_SUCCESS: {
            return [Object.assign({}, action.payload), ...state];
        }
        case ENABLE_CLIENT_NOTIFICATION_SUCCESS: {
            const notifications = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { enabled: true }));
            return [...notifications];
        }
        case DISABLE_CLIENT_NOTIFICATION_SUCCESS: {
            const notifications = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { enabled: false }));
            return [...notifications];
        }
        case RESET_CLIENT_NOTIFICATION_SUCCESS: {
            const notifications = state.filter((el) => el.id !== action.payload);
            return [...notifications];
        }
        default: {
            return state;
        }
    }
};
