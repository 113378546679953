import { httpGet, httpPost } from "./request/requestApi";
const controllerEndpoint = "report";
export default class ChartApi {
    static getChartVariables(trackedItemId) {
        return httpGet(`${controllerEndpoint}/getReportInfo/${trackedItemId}`);
    }
    static getChartData(data) {
        return httpPost(`${controllerEndpoint}/getDataForChart`, { body: data });
    }
    static exportChartData(data) {
        return httpPost(`${controllerEndpoint}/exportDataForChart`, { body: data });
    }
    static exportActualDataForChart(data) {
        return httpPost(`${controllerEndpoint}/exportActualDataForChart
    `, { body: data });
    }
    static exportFinancialDataForChart(data) {
        return httpPost(`${controllerEndpoint}/exportSpreadsheet
    `, { body: data });
    }
    static getConfigurationParameters(data) {
        return httpPost(`${controllerEndpoint}/getConfigurationParameters`, {
            body: data,
        });
    }
    static getChartPrediction(data) {
        return httpPost(`${controllerEndpoint}/listProjectedDays`, { body: data });
    }
    static getChartTopCardsData(reportId) {
        return httpGet(`${controllerEndpoint}/getTrackedItemTopCard/${reportId}`);
    }
}
