import { Button } from "antd";
import React from "react";
import localize from "../../../core/utils/translation-files";
import RenderSvg from "../icons/RenderSvg";
import PrintButton from "./PrintButton";
const FormActionButtons = ({ onCancel, resetForm, fromId = null, setKey, submitText = null, isDirty, isEdit, children, printOnClick, printBtnType = "default", dataTest, checkUnique, }) => {
    return (React.createElement("footer", { className: "formsFooter d-print-none", "data-test": dataTest },
        printOnClick && (React.createElement(PrintButton, { printBtnType: printBtnType, printOnClick: printOnClick })),
        React.createElement(Button, { type: "default", hidden: isEdit, onClick: () => resetForm(), htmlType: "reset" },
            React.createElement(RenderSvg, { icon: "Reset", style: { color: "#6e6b7b" } }),
            localize(["Button.reset"])),
        React.createElement(Button, { type: "default", onClick: onCancel, danger: true },
            React.createElement("span", { className: "icofont icofont-close-circled mr-2", style: { fontSize: "1.2em" } }),
            localize(["Button.cancel"])),
        React.createElement(Button, { hidden: isDirty, type: "primary", htmlType: "submit", form: fromId, onClick: checkUnique && checkUnique },
            React.createElement("span", { className: "icofont icofont-save mr-2", style: { fontSize: "1em" } }),
            submitText ? submitText : localize(["Button.save"])),
        children && children));
};
export default FormActionButtons;
