import React from "react";
import { Button } from "antd";
import LookupDropDown from "../../../../../../layout/components/select/LookupDropDown";
import CompleteTaskTable from "./CompleteTaskTable";
import localize from "../../../../../../core/utils/translation-files";
import { LookupNames } from "../../../../../../core/constants/lookup";
function CompleteTask(props) {
    const { context, handleCancel, month, setMonth, dataSource, count, isValid, isDataChanged, handleSaveTask, handleSave, handleDelete, financialActivity, financialActivityHead, } = props;
    const selectedMonthValue = context.selectedFinancialTask.taskDataMap.taskMonth
        ? parseInt(("0" + context.selectedFinancialTask.taskDataMap.taskMonth).slice(-2))
        : month
            ? parseInt(("0" + month).slice(-2))
            : null;
    return (React.createElement("div", { "data-test": "complete-task-component" },
        React.createElement("header", { className: "page-header", style: { paddingTop: "0.6rem" } },
            React.createElement("span", { className: "page-title" },
                context.selectedFinancialTask.name + " ",
                React.createElement("span", { className: "report-type", "data-testid": "complete-task-type" }, context.selectedFinancialTask.taskDataMap.taskType
                    .toLowerCase()
                    .charAt(0)
                    .toUpperCase() +
                    context.selectedFinancialTask.taskDataMap.taskType
                        .toLowerCase()
                        .slice(1))),
            React.createElement("div", { className: "action-btn-right" },
                React.createElement(Button, { danger: true, onClick: handleCancel }, localize(["Button.cancel"])))),
        React.createElement("br", null),
        React.createElement("div", { style: { maxWidth: "13%", marginBottom: "10px" } },
            React.createElement(LookupDropDown, { disabled: true, localizationKey: ["Task.month"], className: "mb-2 mb-md-0", selectedValue: selectedMonthValue, onChange: setMonth, lookUpType: LookupNames.LKMonth })),
        React.createElement(CompleteTaskTable, { dataSource: dataSource, count: count, handleSave: handleSave, handleDelete: handleDelete, financialActivity: financialActivity, financialActivityHead: financialActivityHead }),
        React.createElement("div", { style: { float: "right", color: "red", marginTop: "5px" } }, !isValid && localize(["Validation.failed"])),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("header", { className: "page-header", "data-test": "companyFormComponent" },
            React.createElement("p", null),
            React.createElement("div", { className: "action-btn-right" },
                React.createElement(Button, { disabled: !isDataChanged, type: "default", onClick: handleSaveTask.bind(this, "Save") }, localize(["Button.save"])),
                React.createElement(Button, { disabled: !isDataChanged, type: "primary", onClick: handleSaveTask.bind(this, "Submit") }, localize(["Button.saveAndSubmit"]))))));
}
export default CompleteTask;
