import { PRINT_HIDE_ACTIONS, RESET_SHOW_ACTIONS, } from "./types";
import { initialState as initialStoreState } from "../initialState";
const initialState = initialStoreState.togglePrint;
export const togglePrintReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRINT_HIDE_ACTIONS: {
            return Object.assign(Object.assign({}, action), { status: true });
        }
        case RESET_SHOW_ACTIONS: {
            return Object.assign(Object.assign({}, action), { status: false });
        }
        default: {
            return state;
        }
    }
};
