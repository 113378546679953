import React from "react";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
const RatingAccuracy = ({ data }) => {
    return (React.createElement("div", null,
        React.createElement(RenderSvg, { icon: "Alert", tooltipTitle: [
                "TrackedItem.unreliableRatingAccuracy",
                `${Math.trunc(+(data === null || data === void 0 ? void 0 : data.ratingAccuracy))}%`,
                "TrackedItem.complete",
            ], hidden: +(data === null || data === void 0 ? void 0 : data.ratingAccuracy) >= +(data === null || data === void 0 ? void 0 : data.accuracyThreshold), style: { color: data === null || data === void 0 ? void 0 : data.accuracyScale, marginRight: "5px" } }),
        React.createElement(RenderSvg, { icon: "RatingFlag", tooltipTitle: [
                `${+(data === null || data === void 0 ? void 0 : data.ratingAccuracy) < +(data === null || data === void 0 ? void 0 : data.accuracyThreshold)
                    ? "TrackedItem.unreliableRatingAccuracy"
                    : "TrackedItem.ratingAccuracy"}`,
                `${Math.trunc(+(data === null || data === void 0 ? void 0 : data.ratingAccuracy))}%`,
                "TrackedItem.complete",
            ], style: { color: data === null || data === void 0 ? void 0 : data.accuracyScale } })));
};
export default RatingAccuracy;
