import { DISABLE_GLOBAL_NOTIFICATION_SUCCESS, ENABLE_GLOBAL_NOTIFICATION_SUCCESS, GET_GLOBAL_NOTIFICATIONS_SUCCESS } from './types';
const initialState = [];
export const globalNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GLOBAL_NOTIFICATIONS_SUCCESS: {
            return [...action.payload];
        }
        case ENABLE_GLOBAL_NOTIFICATION_SUCCESS: {
            const notifications = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { enabled: true }));
            return [...notifications];
        }
        case DISABLE_GLOBAL_NOTIFICATION_SUCCESS: {
            const notifications = state.map((el) => el.id !== action.payload ? el : Object.assign(Object.assign({}, el), { enabled: false }));
            return [...notifications];
        }
        default: {
            return state;
        }
    }
};
