import React, { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { WatchlistApi } from "../../../../../../core/api/watchlistApi";
import CustomModal from "../../../../../../layout/components/customModal";
import TableActions from "../../../../../../layout/components/customTable/tableActions";
import NewWatchListModalComponent from "../newWatchListModalComponent";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import { notifications } from "../../../../../../layout/components/notifications";
import localize from "../../../../../../core/utils/translation-files";
import "./styles.scss";
import WatchlistRenameComponent from "../watchlistRenameComponent/WatchlistRenameComponent";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
const WatchlistTabs = ({ activeWatchlistTab, setActiveWatchListTab, setData, }) => {
    var _a;
    const [modalVisible, setModalVisible] = useState(false);
    const [tabsArray, setTabsArray] = useState([]);
    const { location } = useHistory();
    const parseParams = queryString.parse((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.params);
    const trackItemId = parseParams === null || parseParams === void 0 ? void 0 : parseParams.trackedItemId;
    const trackedItemName = parseParams === null || parseParams === void 0 ? void 0 : parseParams.trackedItemName;
    useEffect(() => {
        WatchlistApi.getUserWatchList(false).then((response) => {
            var _a;
            if (Array.isArray(response)) {
                setTabsArray(response);
                if (!isEmpty(trackItemId)) {
                    handleAddTrackItemToMiscWatchlist(response);
                }
                else {
                    setActiveWatchListTab((_a = SessionStorage.getKey(SessionVariables.WATCHLIST_ACTIVE_TAB)) !== null && _a !== void 0 ? _a : response[0]);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackItemId]);
    const handleAddTrackItemToMiscWatchlist = (watchlistData) => {
        const miscWatchlist = watchlistData.find(({ watchListName, itemsCanBeAddorRemoved, canBeDeleted }) => watchListName === "Misc." && itemsCanBeAddorRemoved && !canBeDeleted);
        if (!isEmpty(miscWatchlist)) {
            WatchlistApi.addToWatchlist(trackItemId, miscWatchlist.id).then((res) => {
                setActiveWatchListTab(miscWatchlist);
                SessionStorage.setKey(SessionVariables.WATCHLIST_ACTIVE_TAB, miscWatchlist);
                if (!(res === null || res === void 0 ? void 0 : res.code)) {
                    notifications.success(`${trackedItemName} ${localize([
                        "Dashboard.addedto",
                    ])} Misc. ${localize(["Dashboard.watchList", "successfully"])}`);
                }
            });
        }
    };
    const handleAddNewWatchList = (watchListName) => {
        handleToggleModalVisibility(undefined);
        setTabsArray((prev) => [...prev, watchListName]);
    };
    const handleDeleteWatchList = (event) => {
        const { id: activeWatchListId } = activeWatchlistTab;
        WatchlistApi.deleteWatchList(activeWatchListId).then((response) => {
            if (response === true) {
                const updateTabs = [...tabsArray].filter(({ id }) => id !== activeWatchListId);
                setTabsArray(updateTabs);
                setData(undefined);
                if ((updateTabs === null || updateTabs === void 0 ? void 0 : updateTabs.length) === 0) {
                    //clear data if last watchlist removed
                    setActiveWatchListTab(undefined);
                    SessionStorage.setKey(SessionVariables.WATCHLIST_ACTIVE_TAB, undefined);
                }
                else {
                    //on delete set previous as selected;
                    setActiveWatchListTab(updateTabs[updateTabs.length - 1]);
                    SessionStorage.setKey(SessionVariables.WATCHLIST_ACTIVE_TAB, updateTabs[updateTabs.length - 1]);
                }
            }
        });
    };
    const handleSetActiveTab = (activeKey) => {
        if (Number(activeKey) !== activeWatchlistTab.id) {
            setActiveWatchListTab(tabsArray.find(({ id }) => id === +activeKey));
            SessionStorage.setKey(SessionVariables.WATCHLIST_ACTIVE_TAB, tabsArray.find(({ id }) => id === +activeKey));
            setData(undefined); //clear listing data
        }
    };
    const handleToggleModalVisibility = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setModalVisible((prev) => !prev);
    };
    const [nameModalVisible, setNameModalVisible] = useState(false);
    const handleToggleNameModalVisibility = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setNameModalVisible((prev) => !prev);
    };
    const deleteBtn = [
        {
            priority: 1,
            onClick: handleToggleNameModalVisibility,
            title: ["rename"],
            svgIcon: "Edit",
            btnClass: "watchlistRenameBtn",
            iconStyle: { color: "#000000" },
            renderSvgButton: true,
            disbaled: !(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.canBeDeleted),
        },
        {
            priority: 2,
            onClick: handleDeleteWatchList,
            title: ["delete"],
            svgIcon: "Delete",
            iconStyle: { color: "#FF0000" },
            renderSvgButton: true,
            disbaled: !(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.canBeDeleted),
        },
    ];
    return (React.createElement("div", { className: "row watchlist-tabs-container" },
        React.createElement(Tabs, { defaultActiveKey: String(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id), activeKey: String(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id), onChange: handleSetActiveTab, key: "true" }, tabsArray.map(({ watchListName, id }) => {
            return React.createElement(Tabs.TabPane, { tab: watchListName, key: id });
        })),
        React.createElement(Button, { type: "primary", onClick: handleToggleModalVisibility }, "Create New Watchlist"),
        React.createElement(TableActions, { actionArray: deleteBtn, wrapperClass: "dottedActionsButton watchlistActions watchlistDeleteBtn", dropdownClassName: "actionsDropdown wathclistDeleteDropdown", ShowActionText: false, menuItems: true }),
        React.createElement(WatchlistRenameComponent, { handleToggleNameModalVisibility: handleToggleNameModalVisibility, activeWatchlistTab: activeWatchlistTab, tabsArray: tabsArray, nameModalVisible: nameModalVisible, setActiveWatchListTab: setActiveWatchListTab, setTabsArray: setTabsArray }),
        React.createElement(CustomModal, { visible: modalVisible, onCancel: handleToggleModalVisibility, maskClosable: true, destroyOnClose: true, footer: null, title: ["Create Watchlist"], modelClass: "CreateWatchlistPopup" },
            React.createElement(NewWatchListModalComponent, { handleAddNewWatchList: handleAddNewWatchList, tabsArray: tabsArray }))));
};
export default WatchlistTabs;
