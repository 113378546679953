import { Document, Page, StyleSheet, Image } from "@react-pdf/renderer";
import React from "react";
const styles = StyleSheet.create({
    page: {
        margin: 0,
        padding: 20,
    },
    section: {
        margin: 0,
        padding: 20,
        flexGrow: 1,
    },
    text: {
        lineHeight: 1.5,
        fontSize: 14,
        paddingBottom: 5,
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "black",
    },
});
export const PdfContent = ({ text = null, image = null, node = null }) => {
    return (React.createElement(Document, { key: text === null || text === void 0 ? void 0 : text.length },
        React.createElement(Page, { size: "A4", orientation: "portrait", style: styles.page, wrap: false, dpi: 800, fixed: true }, image && React.createElement(Image, { src: image, fixed: false }))));
};
