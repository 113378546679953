var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useCallback, useMemo } from "react";
import { Input } from "antd";
const types = {
    count: {
        max: 1000000000,
        min: 0,
        step: 1,
    },
    percents: {
        max: 100,
        min: 0,
        prefix: "%",
    },
    year: {
        max: 9999,
        min: 0,
    },
    decimal: {
        max: 1000000000,
        min: 0,
    },
    negativeDecimal: {
        max: 1000000000,
        min: -1000000000,
    },
};
export const NumericInput = (_a) => {
    var { config, id, type, onChange, value } = _a, rest = __rest(_a, ["config", "id", "type", "onChange", "value"]);
    const inputValue = value == null ? "" : value.toString();
    const cfg = useMemo(() => getConfig(config, type), [config, type]);
    const onChangeHandler = useCallback((e) => {
        const regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        const number = e.target.value === "" ? undefined : e.target.value;
        if (!number ||
            (number[0] && (number === null || number === void 0 ? void 0 : number.length) === 1 && number[0] === "-") ||
            regex.test(number)) {
            onChange(number);
        }
    }, [onChange]);
    return (React.createElement("div", { className: "numeric-input-group" },
        React.createElement(Input, Object.assign({ id: id, placeholder: `Enter`, onChange: onChangeHandler, value: inputValue }, rest)),
        cfg.prefix && React.createElement("i", { className: "material-icons" }, cfg.prefix)));
};
function getConfig(config, type) {
    return config || (type ? types[type] : {});
}
