import { Button, Checkbox } from "antd";
import React, { useState } from "react";
import localize from "../../../../../../../core/utils/translation-files";
import CustomModal from "../../../../../../../layout/components/customModal";
import RenderSvg from "../../../../../../../layout/components/icons/RenderSvg";
export default function ExportIndividualItemModal({ relatedItems, visible, onClose, trackedItemId, actualDataExportHandle, chartVariables, selectedFilterValue, selectedRange, }) {
    const [selectedRelations, setSelectedRelations] = useState([]);
    const handleChangeRelationsCheckBox = (event) => {
        const { checked, id } = event.target;
        let updateRelation = relatedItems === null || relatedItems === void 0 ? void 0 : relatedItems.find((relation) => relation.id === +id);
        updateRelation.disabled = false;
        setSelectedRelations(checked
            ? [...selectedRelations, updateRelation]
            : selectedRelations === null || selectedRelations === void 0 ? void 0 : selectedRelations.filter((relation) => relation.id !== +id));
    };
    const handleCancel = () => {
        onClose();
        setSelectedRelations([]);
    };
    return (React.createElement(CustomModal, { visible: visible, onCancel: handleCancel, maskClosable: false, destroyOnClose: true, footer: null, title: ["TrackedItem.exportItems"], overflowY: true },
        React.createElement("div", { className: "col-12" },
            React.createElement("div", { className: "form-group", "data-test": "band-dropdown" },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null, localize(["TrackedItem.selectedItems"]))),
                React.createElement("div", { className: "row scrollDiv" }, (relatedItems === null || relatedItems === void 0 ? void 0 : relatedItems.length) ? (relatedItems === null || relatedItems === void 0 ? void 0 : relatedItems.map(({ id, name }, relationIndex) => (React.createElement("div", { className: "col-12 selectedRelationItem", "data-test": "checkbox", "data-testid": "checkbox" },
                    React.createElement(Checkbox, { key: relationIndex, onChange: handleChangeRelationsCheckBox, id: id.toString() }, name))))) : (React.createElement("span", { className: "col-12 selectedRelationItem" }, "No Related Items"))))),
        React.createElement("div", { className: "exportItemsBox", style: { marginBottom: "unset" } },
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(Button, { type: "default", onClick: handleCancel },
                    React.createElement(RenderSvg, { icon: "Cancel" }),
                    localize(["Button.cancel"])),
                React.createElement(Button, { type: "primary", onClick: () => {
                        actualDataExportHandle(selectedFilterValue, trackedItemId, selectedRange, chartVariables, selectedRelations);
                        handleCancel();
                    }, disabled: !(selectedRelations === null || selectedRelations === void 0 ? void 0 : selectedRelations.length) },
                    React.createElement(RenderSvg, { icon: "Export" }),
                    localize(["download"]))))));
}
