import { Button, Checkbox, Switch, Table } from "antd";
import React from "react";
import { relationsListingScrollPosition } from "../../../../../../../core/constants/tableScrollConstants";
import localize from "../../../../../../../core/utils/translation-files";
import DefaultsDropdownContainer from "../../../../../../../layout/components/select/DefaultsByFrequecyDropdown";
function RelationsTableT({ dataSource, updatedInstance, setUpdatedInstance, setDataSource, actions, DraggableBodyRow, DraggableContainer, handleSave, handleAdd, handlSetFieldValue, handleDefaultChange, frequency, isDelphieorSuperUser, defaultFrequencyOptions, errors, }) {
    const columns = [
        {
            title: `${localize(["TrackedItem.itemName"])}`,
            dataIndex: "name",
            width: 400,
            render: (value, row) => row.isNew ? (React.createElement(React.Fragment, null,
                React.createElement(DefaultsDropdownContainer, { className: `mb-2 mb-md-0 ${(errors === null || errors === void 0 ? void 0 : errors.associatedId) ? `require-ant-select` : ``}`, selectedValue: updatedInstance.associatedId, onChange: (value, e) => handleDefaultChange(value, e), frequency: frequency, defaultOptions: defaultFrequencyOptions }),
                React.createElement("p", { id: "associatedIdField", className: "rowError" }, (errors === null || errors === void 0 ? void 0 : errors.associatedId) && (errors === null || errors === void 0 ? void 0 : errors.associatedId)))) : (value),
        },
        {
            title: `${localize(["TrackedItem.required"])}`,
            dataIndex: "disabled",
            width: 60,
            align: "center",
            render: (value, row) => (React.createElement(Switch, { className: "ml-1", checked: value === false, onChange: () => {
                    (row === null || row === void 0 ? void 0 : row.isNew) &&
                        setUpdatedInstance(Object.assign(Object.assign({}, updatedInstance), { disabled: !value }));
                    let current = dataSource === null || dataSource === void 0 ? void 0 : dataSource.find((rec) => rec.priority === row.priority);
                    current.disabled = !value;
                    setDataSource([...dataSource]);
                } })),
        },
        {
            title: `${localize(["TrackedItem.summaryItem"])}`,
            dataIndex: "summaryItem",
            width: 90,
            align: "center",
            render: (value, row) => (React.createElement(Checkbox, { name: "summaryItem", checked: value === true, onChange: () => {
                    (row === null || row === void 0 ? void 0 : row.isNew) &&
                        setUpdatedInstance(Object.assign(Object.assign({}, updatedInstance), { summaryItem: !value }));
                    let current = dataSource === null || dataSource === void 0 ? void 0 : dataSource.find((rec) => rec.priority === row.priority);
                    current.summaryItem = !value;
                    setDataSource([...dataSource]);
                }, disabled: row === null || row === void 0 ? void 0 : row.disabled })),
        },
        {
            title: `${localize(["email"])}`,
            dataIndex: "emailFlag",
            width: 60,
            align: "center",
            render: (value, row) => (React.createElement(Checkbox, { name: "emailFlag", checked: value === true, onChange: () => {
                    (row === null || row === void 0 ? void 0 : row.isNew) &&
                        setUpdatedInstance(Object.assign(Object.assign({}, updatedInstance), { emailFlag: !value }));
                    let current = dataSource === null || dataSource === void 0 ? void 0 : dataSource.find((rec) => rec.priority === row.priority);
                    current.emailFlag = !value;
                    setDataSource([...dataSource]);
                }, disabled: row === null || row === void 0 ? void 0 : row.disabled })),
        },
    ];
    const actionColumn = {
        title: (React.createElement(Button, { type: "default", onClick: handleAdd, className: "roleCountButton sortButton", style: { color: "#17a99a" }, hidden: !isDelphieorSuperUser },
            React.createElement("span", { className: "icofont-plus" }),
            localize(["TrackedItem.addItems"]))),
        dataIndex: "actions",
        width: 100,
        align: "center",
        render: (value, row) => {
            return row.isNew ? (React.createElement(Button, { type: "primary", onClick: handleSave, disabled: !updatedInstance.name, style: { marginRight: 0 } }, localize(["Button.save"]))) : (actions(row));
        },
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { "data-test": "notificationTableComponent", className: "relationsListing" },
        React.createElement(Table
        // rowClassName={(rec) => rec.isNew && "edit-relation-row"}
        , { 
            // rowClassName={(rec) => rec.isNew && "edit-relation-row"}
            dataSource: dataSource, columns: displayedColumns, rowKey: "priority", pagination: false, scroll: { y: `calc(100vh - ${relationsListingScrollPosition}em)` }, components: {
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            } })));
}
export default RelationsTableT;
