import isEmpty from "../helpers/isEmpty";
import { decimalNumberRegex } from "../utils/regex";
const isCurrentFieldGreater = (currentValue, otherValue) => {
    if (isPositiveValue(currentValue) && isPositiveValue(otherValue)) {
        return Number(currentValue) > Number(otherValue);
    }
    return true;
};
const isCurrentFieldGreaterOrEquall = (currentValue, otherValue) => {
    if (isPositiveValue(currentValue) && isPositiveValue(otherValue)) {
        return Number(currentValue) >= Number(otherValue);
    }
    return true;
};
const isCurrentFieldLess = (currentValue, otherValue) => {
    if (isPositiveValue(currentValue) && isPositiveValue(otherValue)) {
        return Number(currentValue) < Number(otherValue);
    }
    return true;
};
const isPositiveValue = (value) => {
    const regex = decimalNumberRegex.regex;
    return regex.test(String(value));
};
const divideMultipleAction = (value, action) => {
    if (action === "load")
        return Number(value) * 100;
    return Number(value) / 100;
};
const formatValue = (value, action) => {
    var _a;
    if (isEmpty(value))
        return undefined;
    let stringValue = String(value);
    if (stringValue.includes(".")) {
        let decimalCount = (_a = stringValue.split(".")[1]) === null || _a === void 0 ? void 0 : _a.length;
        const resulantValue = divideMultipleAction(value, action);
        if (action === "submit")
            decimalCount += 2;
        return Number(resulantValue.toPrecision(stringValue.length));
    }
    return divideMultipleAction(value, action);
};
export { isCurrentFieldGreater, isCurrentFieldGreaterOrEquall, isCurrentFieldLess, isPositiveValue, formatValue, };
