var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Switch } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import isActionAllowed from "../../../../../../../core/authorization/userPermissions";
import { REPORT_CREATION, REPORT_EDIT, } from "../../../../../../../core/constants/permissionConstants";
import { ResponseStatusCodes } from "../../../../../../../core/constants/responseStatusCodes";
import { editTrackedItemsRoute } from "../../../../../../../core/constants/routesPath";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../../core/utils/translation-files";
import TableActions from "../../../../../../../layout/components/customTable/tableActions";
import UseCustomPagination from "../../../../../../../layout/components/hooks/useCustomPagination";
import { notifications } from "../../../../../../../layout/components/notifications";
import { inActivateTrackedItem, activateTrackedItem, getSystemCreatedTrackedItems, } from "../../../../../../../redux/trackedItem/actions";
import SystemCreatedtrackedItemTable from "./components/systemCreatedtrackedItemTable";
const SystemCreatedTrackedItemsListingContainer = ({ filters, inActivateTrackedItem, activateTrackedItem, getSystemCreatedTrackedItems, isSubmitAction, setIsSubmitAction, isPrintMode, }) => {
    var _a;
    const history = useHistory();
    const [systemCreatedItems, setSystemCreatedItems] = useState([]);
    const isFirstRender = useRef(true);
    const _b = UseCustomPagination({
        totalRecords: (_a = systemCreatedItems === null || systemCreatedItems === void 0 ? void 0 : systemCreatedItems.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange"]);
    const handleGetSystemTrackItem = useCallback(() => {
        const { groupId, companyId, siteId } = filters;
        if (!isEmpty(groupId)) {
            // setSystemCreatedItems([]);
            getSystemCreatedTrackedItems(groupId, companyId !== null && companyId !== void 0 ? companyId : -1, siteId !== null && siteId !== void 0 ? siteId : -1).then((response) => {
                setSystemCreatedItems(response);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);
    useEffect(() => {
        if ((isFirstRender.current || isSubmitAction) && !isEmpty(filters)) {
            handleGetSystemTrackItem();
            isFirstRender.current = false;
            setIsSubmitAction(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleGetSystemTrackItem, filters, isSubmitAction]);
    const inActivateTrackedItemHandler = (data) => {
        inActivateTrackedItem(data).then((response) => {
            if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return;
            }
            else {
                handleGetSystemTrackItem();
                notifications.success(localize(["TrackedItem.trackeditem", "disabled", "successfully"]));
            }
        });
    };
    const activateTrackedItemHandler = (data) => {
        activateTrackedItem(data).then((response) => {
            if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return;
            }
            else {
                handleGetSystemTrackItem();
                notifications.success(localize(["TrackedItem.trackeditem", "enabled", "successfully"]));
            }
        });
    };
    const editTrackedItemRedirect = (trackedItem) => {
        history.push(editTrackedItemsRoute + trackedItem.id, {
            trackedItemId: trackedItem.id,
            systemCreated: true,
        });
    };
    const trackedItemActions = (trackedItem) => {
        const actionArray = [
            {
                priority: 2,
                title: ["edit", "TrackedItem.trackedItem"],
                onClick: editTrackedItemRedirect.bind({}, trackedItem),
                svgIcon: "Edit",
                isAllowed: isActionAllowed([REPORT_EDIT]),
            },
            {
                priority: 1,
                title: trackedItem.disabled === true
                    ? ["unArchive", "TrackedItem.trackedItem"]
                    : ["archive", "TrackedItem.trackedItem"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${trackedItem.disabled === true ? ["unarchive"] : ["Archive"]}`,
                    "TrackedItem.thisTrackedItem?",
                ],
                isAllowed: isActionAllowed([REPORT_CREATION]),
                onConfirm: trackedItem.disabled === true
                    ? activateTrackedItemHandler.bind({}, trackedItem.id)
                    : inActivateTrackedItemHandler.bind({}, trackedItem.id),
                disbaled: !trackedItem.belongsToHoldingCompany,
                children: (React.createElement(Switch, { disabled: !trackedItem.belongsToHoldingCompany, checked: trackedItem.disabled === false, className: `ml-1 ${!trackedItem.belongsToHoldingCompany ? `disableSwitch` : ``}` })),
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    return (React.createElement("div", null,
        React.createElement(SystemCreatedtrackedItemTable, { data: systemCreatedItems, actions: trackedItemActions, currentPage: restPaginationProps.currentPage, pageSize: restPaginationProps.pageSize, handleCustomTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps, isPrintMode: isPrintMode })));
};
function mapStateTopProps(state) {
    var _a;
    return {
        isPrintMode: (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status,
    };
}
const mapDispatchToProps = {
    inActivateTrackedItem,
    activateTrackedItem,
    getSystemCreatedTrackedItems,
};
SystemCreatedTrackedItemsListingContainer.propTypes = {};
export default connect(mapStateTopProps, mapDispatchToProps)(SystemCreatedTrackedItemsListingContainer);
