import { Button, DatePicker } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { InfoCircleOutlined, StarOutlined } from "@ant-design/icons";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import moment from "moment";
import { ChartFilterOptions } from "../../../../../../../core/enums/chart";
import { disabledDate } from "../../../../../../../core/validations/dateTimeValidations";
import TableActions from "../../../../../../../layout/components/customTable/tableActions";
import { dashboardRoute, watchlistRoute, } from "../../../../../../../core/constants/routesPath";
import { clearDashboardSession } from "../../../../../../../core/helpers/localStorage/breadCrumbSession";
function ChartActionButtons({ printHandle, chartVariables, openScaleModal, setSelectedFilterValue, selectedFilterValue, setSelectedRange, setChartData, selectedRange, chartData, trackedItemId, exportHandle, exportFinancialSheet = null, browserHistory = null, isDashboard = false, hideBackButton = false, buttonText = null, updateButtonText = null, isSummary = false, viewFinancialModule, watchlistStatus = false, isFinancial, handleToggleChartModalVisibilty = null, handleToggleExportModalVisibilty = null, isNotification = false, handleToggleWatchListModal = null, }) {
    const { RangePicker } = DatePicker;
    const history = useHistory();
    const onFilterChange = (value) => {
        if (value !== ChartFilterOptions.Custom) {
            setChartData && setChartData();
        }
        setSelectedRange([]);
        setSelectedFilterValue(value);
    };
    const onRangeChange = (date, dateString) => {
        setChartData && setChartData();
        setSelectedRange(dateString);
    };
    const menuActionButton = () => {
        var _a, _b;
        const btnList = [
            {
                svgIcon: "Print",
                onClick: printHandle,
                priority: 1,
                title: ["print"],
                renderSvgButton: true,
            },
            {
                svgIcon: watchlistStatus ? "AddedToWatchlist" : "WatchlistButton",
                onClick: handleToggleWatchListModal,
                //isWatchListStatus ? removeFromWatchlist(item) : addToWatchList(item),
                priority: 1,
                title: ["Add / Remove Watchlist"],
                renderSvgButton: true,
            },
            {
                disbaled: (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.weight) === 0,
                onClick: openScaleModal,
                priority: 2,
                title: (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.weight) !== 0 ? ["Ratings"] : ["Rating - Unratable"],
                iconComponent: React.createElement(StarOutlined, { style: { fontSize: "16px" } }),
            },
            {
                onClick: handleToggleChartModalVisibilty,
                priority: 3,
                title: ["TrackedItem.chartVariables"],
                iconComponent: (React.createElement(InfoCircleOutlined, { style: {
                        fontSize: "16px",
                    } })),
            },
            {
                onClick: () => exportHandle(selectedFilterValue, chartData, trackedItemId, selectedRange, chartVariables),
                priority: 4,
                title: ["Button.graphDataInTable"],
                svgIcon: "TableFormat",
                renderSvgButton: true,
                disbaled: !((_a = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _a === void 0 ? void 0 : _a.length),
            },
            {
                onClick: () => isFinancial
                    ? exportFinancialSheet(selectedFilterValue, trackedItemId, selectedRange, chartVariables)
                    : handleToggleExportModalVisibilty(),
                priority: 5,
                title: ["Button.relatedItemData"],
                svgIcon: "Export",
                renderSvgButton: true,
                disbaled: !((_b = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _b === void 0 ? void 0 : _b.length),
            },
            {
                onClick: viewFinancialModule,
                priority: 6,
                title: ["Dashboard.viewFinancialSheet"],
                svgIcon: "FinancialSheet",
                btnClass: "viewFinancialSheet",
                renderSvgButton: true,
                isAllowed: isDashboard && isFinancial && !isNotification,
            },
        ];
        return (React.createElement(TableActions, { actionArray: btnList, menuItems: true, dropdownClassName: "dropdown-action-container trackedItemActions" }));
    };
    const actionButtons = (React.createElement("div", { style: { display: "inline-flex", alignItems: "center" } },
        React.createElement("div", { className: "filterDropdown" },
            React.createElement(LookupDropDown, { selectedValue: selectedFilterValue, onChange: (e) => onFilterChange(e), localizationKey: ["TrackedItem.filter"], lookUpType: LookupNames.LKChartDataFilter })),
        React.createElement("div", { hidden: selectedFilterValue !== ChartFilterOptions.Custom, className: "customRangePicker" },
            React.createElement(RangePicker, { allowClear: false, disabledDate: disabledDate, value: selectedRange.length > 0
                    ? [moment(selectedRange[0]), moment(selectedRange[1])]
                    : [null, null], onChange: (date, dateString) => {
                    onRangeChange(date, dateString);
                } })),
        buttonText && (React.createElement(Button, { type: "primary", onClick: () => {
                clearDashboardSession();
                if (buttonText === "Overview") {
                    browserHistory.push(dashboardRoute);
                }
                else if (buttonText === "Watchlist") {
                    browserHistory.push(watchlistRoute);
                }
            } },
            buttonText === "Watchlist" && updateButtonText(),
            buttonText === "Overview" ? "Back To Overview" : "Back To Watchlist")),
        React.createElement(Button, { type: "default", onClick: () => (isSummary ? viewFinancialModule() : history.go(-1)), hidden: isNotification || hideBackButton }, "Back"),
        menuActionButton()));
    return (React.createElement("div", { "data-test": "action-button-section" }, actionButtons));
}
export default ChartActionButtons;
