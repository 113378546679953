var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { GET_SITES_SUCCESS, ADD_SITE_SUCCESS, UPDATE_SITE_SUCCESS, ACTIVATE_SITE_SUCCESS, IN_ACTIVATE_SITE_SUCCESS, } from "./types";
import { SiteApi } from "../../core/api/siteApi";
import { clearGroupOptionSuccess } from "../groupOption/actions";
export const getSitesSuccess = (data) => ({
    type: GET_SITES_SUCCESS,
    payload: data,
});
export const addSiteSuccess = (data) => ({
    type: ADD_SITE_SUCCESS,
    payload: data,
});
export const updateSiteSuccess = (data) => ({
    type: UPDATE_SITE_SUCCESS,
    payload: data,
});
export const inActivateSiteSuccess = (data) => ({
    type: IN_ACTIVATE_SITE_SUCCESS,
    payload: data,
});
export const activateSiteSuccess = (data) => ({
    type: ACTIVATE_SITE_SUCCESS,
    payload: data,
});
export function getSites(companyId, isCompanyChange = false) {
    return function (dispatch) {
        return SiteApi.getSites(companyId)
            .then((sites) => {
            if ((sites === null || sites === void 0 ? void 0 : sites.length) > 0 || isCompanyChange === true) {
                const mapSites = sites.map((_a) => {
                    var { addresses } = _a, site = __rest(_a, ["addresses"]);
                    return (Object.assign(Object.assign({}, site), { addresses: addresses.length > 0 &&
                            addresses[0].line1 +
                                ", " +
                                addresses[0].town +
                                ", " +
                                addresses[0].postCode }));
                });
                dispatch(getSitesSuccess(mapSites));
                return sites;
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function addSite(site) {
    return function (dispatch) {
        return SiteApi.createSite(site)
            .then((response) => {
            if (response.code) {
                return response;
            }
            const updateResponse = Object.assign({}, response);
            updateResponse.addresses =
                (updateResponse === null || updateResponse === void 0 ? void 0 : updateResponse.addresses.length) > 0 &&
                    updateResponse.addresses[0].line1 +
                        ", " +
                        updateResponse.addresses[0].town +
                        ", " +
                        updateResponse.addresses[0].postCode;
            dispatch(addSiteSuccess(updateResponse));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function editSite(site) {
    return function (dispatch) {
        return SiteApi.updateSite(site)
            .then((response) => {
            if (response.code) {
                return response;
            }
            const updateResponse = Object.assign({}, response);
            updateResponse.addresses =
                (updateResponse === null || updateResponse === void 0 ? void 0 : updateResponse.addresses.length) > 0 &&
                    updateResponse.addresses[0].line1 +
                        ", " +
                        updateResponse.addresses[0].town +
                        ", " +
                        updateResponse.addresses[0].postCode;
            dispatch(updateSiteSuccess(updateResponse));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function activateSite(data) {
    return function (dispatch) {
        return SiteApi.activateSite(data)
            .then((id) => {
            dispatch(activateSiteSuccess(data));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function inActivateSite(data) {
    return function (dispatch) {
        return SiteApi.inActivateeSite(data)
            .then((id) => {
            dispatch(inActivateSiteSuccess(data));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            throw error;
        });
    };
}
