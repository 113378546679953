import React from "react";
import localize from "../../../../../../../core/utils/translation-files";
import { BandScaleTableResponse } from "../../../../../../../core/contracts/bandScale/bandScaleContract";
import CustomTable from "../../../../../../../layout/components/customTable";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
const TrackedItemBandScaleTable = ({ data, tableRef, subCategory }) => {
    const bandRanges = {
        A: "90% - 100%",
        B: "80% - 90%",
        C: "70% - 80%",
        D: "0% - 70%",
    };
    const columns = [
        {
            fieldName: "bandName",
            title: ["TrackedItemsScale.bandName"],
            width: 100,
            render(bandName, record) {
                return {
                    props: {
                        style: { background: record.rgbColor + "80" },
                    },
                    children: React.createElement("div", null, bandName),
                };
            },
        },
        {
            fieldName: "bandName",
            title: ["TrackedItemsScale.scaleRangeHeading"],
            width: 100,
            render(bandName, record) {
                // console.log(bandName,record)
                return {
                    props: {
                        style: { background: record.rgbColor + "80" },
                    },
                    children: React.createElement("div", null, bandRanges[bandName]),
                };
            },
        },
        {
            fieldName: "financialRiskBand",
            title: ["TrackedItemsScale.financialRiskBand"],
            width: 210,
            hidden: +subCategory !== +TrackedItemsVariables.FinancialCategoryCode,
            render(financialRiskBand, record) {
                return {
                    props: {
                        style: { background: record.rgbColor + "80" },
                    },
                    children: React.createElement("div", null, financialRiskBand),
                };
            },
        },
        {
            fieldName: "riskBand",
            hidden: +subCategory === +TrackedItemsVariables.FinancialCategoryCode,
            title: ["TrackedItemsScale.riskBand"],
            width: 210,
            render(riskBand, record) {
                return {
                    props: {
                        style: { background: record.rgbColor + "80" },
                    },
                    children: React.createElement("div", null, riskBand),
                };
            },
        },
    ];
    return (React.createElement("div", { "data-test": "scaleTableComponent", ref: tableRef },
        React.createElement("h3", { className: "d-print page-title" }, localize(["TrackedItemsScale.scaleTableHeading"])),
        React.createElement(CustomTable, { data: data, columns: columns, dataType: BandScaleTableResponse, scroll: false })));
};
export default TrackedItemBandScaleTable;
