import { GET_GROUP_OPTIONS, CLEAR_GROUP_OPTIONS, } from "./types";
import { GroupApi } from "../../core/api/groupApi";
import { SessionStorage } from "../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../core/constants/SessionVariables";
import isEmpty from "../../core/helpers/isEmpty";
import { GROUP_CREATION } from "../../core/constants/permissionConstants";
import { UserType } from "../../core/enums/UserStatus";
import { sortGroupOptions } from "../../core/utils/arrayUtils";
export const getGroupOptionSuccess = (data) => ({
    type: GET_GROUP_OPTIONS,
    payload: data,
});
export const clearGroupOptionSuccess = () => ({
    type: CLEAR_GROUP_OPTIONS,
});
export function getGroupOptions() {
    return function (dispatch, getState) {
        const { companyPermissionDTOs, userType } = getState().context;
        const userPermissions = companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.map(({ permission }) => permission);
        const isSuperorDelphie = userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.includes(GROUP_CREATION);
        const isExternal = userType === UserType.External;
        return GroupApi.getGroupDropdownOptions(isSuperorDelphie, isExternal)
            .then((response) => {
            const mapResponse = sortGroupOptions(Array.isArray(response) ? response : [response]);
            if (!isEmpty(response) &&
                !isSuperorDelphie &&
                (mapResponse === null || mapResponse === void 0 ? void 0 : mapResponse.length) === 1) {
                SessionStorage.setKey(SessionVariables.GROUP, mapResponse);
            }
            dispatch(getGroupOptionSuccess(mapResponse));
            return mapResponse;
        })
            .catch((error) => {
            return error;
        });
    };
}
