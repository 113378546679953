import React from "react";
import CustomModal from "../../customModal";
import RenderSvg from "../../icons/RenderSvg";
import HistoricalChart from "./HistoricalChart";
const HistoricalChartPopup = ({ data, row, initialData, visible, popupChartData, graphTitle = "Graph Detail", chartPopupCancel, openDetailGraph, showGraphLink = true, frequency, isDashboardViewAllowed, isFinancialViewAllowed, weight, weightFlag, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomModal, { visible: visible, onCancel: chartPopupCancel, maskClosable: false, destroyOnClose: true, footer: null, 
            // title={[graphTitle]}
            // width="fit-content"
            modelClass: "historicalChartModal" },
            React.createElement("div", { className: "row align-items-center" },
                React.createElement("h5", null, graphTitle),
                showGraphLink && (React.createElement("h6", { className: "detailGraphBtn row align-items-center cursor-pointer", onClick: () => openDetailGraph(data) },
                    React.createElement(RenderSvg, { icon: "OpenChart", style: { marginRight: "5px" } }),
                    "Open Detail Graph"))),
            React.createElement("div", null,
                React.createElement(HistoricalChart, { row: row, initialData: initialData, popupChartData: popupChartData, data: data, isPopup: true, popupChartClass: "historicalChartPopup customChartPopup", frequency: frequency, isDashboardViewAllowed: isDashboardViewAllowed, isFinancialViewAllowed: isFinancialViewAllowed, weight: weight, weightFlag: weightFlag })))));
};
export default HistoricalChartPopup;
