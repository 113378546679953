import React from "react";
import { NavLink } from "react-router-dom";
import posed from "react-pose";
import className from "../../../core/utils/class-names";
import { treeMenuType } from "../../../core/enums/treeMenuType";
import { isMenuAllowed } from "../../../core/utils/allowedMenus";
import CustomTooltip from "../customTooltip";
import RenderSvg from "../icons/RenderSvg";
const Sub = posed.div({
    closed: { height: 0, overflow: "hidden" },
    open: { height: "auto", overflow: "auto" },
    transition: { ease: "ease-in-out", duration: 200 },
});
const ItemWithSub = ({ location, title, layout, sub, active, onClick, icon, parentRoute, openTitle, status, requiredPermissions, isParent, expanded, svgIcon, }) => {
    const renderSvgIcon = (svgIcon, routing) => {
        if (typeof svgIcon === "object") {
            return (React.createElement(RenderSvg, { icon: location.pathname.split("/")[2] === routing
                    ? svgIcon.active
                    : svgIcon.inActive }));
        }
        return React.createElement(RenderSvg, { icon: svgIcon });
    };
    const subItemClass = (routing) => className({
        "menu-item": true,
        active: routing === location.pathname.split("/")[2],
    });
    const itemSub = (isParent = null) => sub.map((item, i) => item.sub ? (React.createElement(ItemWithSub, { key: i, layout: layout, sub: item.sub, title: item.title, location: location, active: item.active, icon: item.icon, onClick: onClick, parentRoute: item.routing, openTitle: openTitle, status: item === null || item === void 0 ? void 0 : item.status, requiredPermissions: item === null || item === void 0 ? void 0 : item.requiredPermissions, isParent: isParent, expanded: expanded, svgIcon: item.svgIcon })) : (isMenuAllowed(item.requiredPermissions) === true && (React.createElement("li", { className: subItemClass(item.routing), key: i, onClick: (event) => onClick(item.title, layout, item.routing) },
        React.createElement(NavLink, { id: item.title.toLowerCase().replaceAll(" ", "-"), to: `/${item.layout || layout}/${item.routing}`, className: `item-link ml-2`, activeClassName: "active", onClick: (event) => event.preventDefault(), style: {
                justifyContent: expanded ? "space-between" : "center",
            } },
            item.svgIcon && renderSvgIcon(item.svgIcon, item.routing),
            expanded && React.createElement("span", { className: "link-text" },
                " ",
                item.title))))));
    return isMenuAllowed(requiredPermissions) === true ? (expanded === true ? (React.createElement("li", { className: `menu-item has-sub ${active ? "active" : ""} `, style: Object.assign({}, (isParent ? {} : { marginLeft: "7px" })), onClick: (event) => {
            onClick(title, layout, parentRoute, status);
        } },
        React.createElement("span", { className: `item-link`, id: title.toLowerCase().replaceAll(" ", "-"), style: {
                justifyContent: expanded ? "space-between" : "center",
            } },
            React.createElement("span", null, svgIcon && renderSvgIcon(svgIcon, parentRoute)),
            React.createElement("span", { hidden: !expanded, className: `tree-item-text` }, title)),
        React.createElement(Sub, { onClick: (e) => e.stopPropagation(), pose: status === treeMenuType.expanded ? "opened" : "closed", hidden: !expanded }, status === treeMenuType.expanded ? (React.createElement("ul", { className: "sub" }, itemSub())) : null))) : (React.createElement(CustomTooltip, { title: title, placement: "right", renderTooltip: !expanded },
        React.createElement("li", { className: `menu-item has-sub ${active ? "active" : ""} `, style: Object.assign({}, (isParent ? {} : { marginLeft: "7px" })), onClick: (event) => {
                onClick(title, layout, parentRoute, status);
            } },
            React.createElement("span", { className: `item-link`, id: title.toLowerCase().replaceAll(" ", "-"), style: {
                    justifyContent: expanded ? "space-between" : "center",
                } },
                React.createElement("span", null, svgIcon && renderSvgIcon(svgIcon, parentRoute)),
                React.createElement("span", { hidden: !expanded, className: `tree-item-text` }, title)),
            React.createElement(Sub, { onClick: (e) => e.stopPropagation(), pose: status === treeMenuType.expanded ? "opened" : "closed", hidden: !expanded }, status === treeMenuType.expanded ? (React.createElement("ul", { className: "sub" }, itemSub())) : null))))) : (React.createElement(React.Fragment, null, itemSub(true)));
};
export default ItemWithSub;
