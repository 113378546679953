import { COMPANY_EDIT, DASHBOARD_VIEWER, GROUP_CREATION, SITE_EDIT, SITE_VIEW, } from "./../constants/permissionConstants";
import { unAuthorizedRoute, watchlistRoute } from "../constants/routesPath";
import { trackedItemsDashboardRoute, selectApplicationRoute, } from "../constants/routesPath";
import { SessionStorage } from "../helpers/localStorage/sessionStorage";
import { ATOM } from "../constants/lookup";
import { SessionVariables } from "../constants/SessionVariables";
import { RoleCompanyType } from "../enums/RoleTypes";
import isEmpty from "../helpers/isEmpty";
import { DashboardApi } from "../api/dashboardApi";
export const selectContextCompany = (setContextCompany, companies) => {
    const company = companies[0];
    SessionStorage.setKey(SessionVariables.COMPANY, company);
    setContextCompany(company);
};
export const selectContextSite = (setContextCompany, sites) => {
    const site = sites[0];
    SessionStorage.setKey(SessionVariables.COMPANY, site);
    setContextCompany(site);
};
export const selectCompanyByCompanies = (companies, companyId, setSelectedCompany) => {
    const tempCompanies = companies.filter((item) => item.id === parseInt(companyId))[0];
    if (tempCompanies) {
        setSelectedCompany(tempCompanies);
    }
};
/**
 * For first time login of external auditor
 * Return url for external auditor after setting the context site
 * @param {setContextCompany} -redux action to set company
 * @param {companies} -List of companies
 * @returns {string}
 * @author {Fiaz Ahmad}
 */
export const SelectSiteForFirstLogin = (setContextCompany, companies, context) => {
    if (companies.length === 0 ||
        (companies.length === 1 && companies[0].sites.length === 0) ||
        context.allowedApps.length === 0) {
        return unAuthorizedRoute;
    }
    if (context.allowedApps.length === 1 &&
        context.allowedApps[0].code === ATOM &&
        companies.length === 1 &&
        companies[0].sites.length === 1) {
        setContextCompany(companies[0].sites[0]);
        SessionStorage.setKey(SessionVariables.COMPANY, companies[0].sites[0]);
        return trackedItemsDashboardRoute;
    }
    else {
        return selectApplicationRoute;
    }
};
/**
 * For second time login of external auditor
 * Return url for external auditor after setting the context site
 * @param {setContextCompany} -wrapper redux action to set company
 * @param {companies} -val List of companies
 * @param {lastSavedContext} -last Saved context of user
 * @returns {string}
 * @author {Fiaz Ahmad}
 */
export const selectSiteFromSavedContext = (setContextCompany, lastSavedContext, companies, context) => {
    if (companies.length === 0 ||
        (companies.length === 1 && companies[0].sites.length === 0) ||
        context.allowedApps.length === 0) {
        return unAuthorizedRoute;
    }
    if (lastSavedContext) {
        var siteObj = undefined;
        // eslint-disable-next-line array-callback-return
        // eslint-disable-next-line array-callback-return
        companies.map((x) => {
            const sites = x.sites.filter((x) => x.id === lastSavedContext.selectedCompOrSite.id);
            if (sites.length > 0) {
                siteObj = sites[0];
            }
        });
        if (!siteObj)
            return selectApplicationRoute;
        setContextCompany(siteObj);
        SessionStorage.setKey(SessionVariables.COMPANY, siteObj);
        return;
    }
    return selectApplicationRoute;
};
export const getCompanyNameBySelectedCompany = (selectedCompany, companies) => {
    if ((selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.type) === RoleCompanyType.Company) {
        return selectedCompany.name;
    }
    return companies.find((x) => x.id === selectedCompany.parentId).name;
};
export const getCompanyIdBySelectedCompany = (context) => {
    var _a, _b, _c;
    if (((_a = context.selectedCompany) === null || _a === void 0 ? void 0 : _a.type) === RoleCompanyType.Company) {
        return (_b = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _b === void 0 ? void 0 : _b.id;
    }
    return (_c = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _c === void 0 ? void 0 : _c.parentId;
};
export const SelectContextAndNavigation = (response, history, groupOptions, getGroupOptions, setContextCompany) => {
    var _a;
    const userPermissions = (_a = response === null || response === void 0 ? void 0 : response.companyPermissionDTOs) === null || _a === void 0 ? void 0 : _a.map(({ permission }) => permission);
    // const userHistoryFrequency = isEmpty(response?.lastVisitedContext)
    //   ? null
    //   : JSON.parse(JSON.parse(response?.lastVisitedContext)?.text)?.selectedFilter
    //       ?.code;
    if (userPermissions.includes(DASHBOARD_VIEWER)) {
        DashboardApi.getGroupsHierarchy();
        const path = watchlistRoute;
        if (path) {
            history.push(path);
        }
        // DashboardApi.getGroupsRatingList(userHistoryFrequency ?? 7).then((res) => {
        //   if (res) {
        //     if (
        //       res?.companyDashboardRatingTabDTOList?.length === 1 ||
        //       (res?.companyDashboardRatingTabDTOList?.length === 0 &&
        //         userPermissions?.permissions?.includes(COMPANY_EDIT))
        //     ) {
        //       const {
        //         companyId: groupId,
        //         companyName: groupName = response?.userCompanyName,
        //       } = res?.companyDashboardRatingTabDTOList?.[0] ?? {};
        //       const path = `${dashboardRoute}/${groupId}`;
        //       const groupBreadCrumbList = [{ path, name: groupName }];
        //       DashboardApi.getCompaniesRatingList(
        //         groupId,
        //         userHistoryFrequency ?? 7
        //       ).then((companyResponse) => {
        //         if (
        //           companyResponse?.companyDashboardRatingTabDTOList?.length === 1
        //         ) {
        //           const { companyId, companyName } =
        //             companyResponse?.companyDashboardRatingTabDTOList[0];
        //           const path = `/${companyId}`;
        //           const breadCrumbList = groupBreadCrumbList.concat([
        //             { path, name: companyName },
        //           ]);
        //           DashboardApi.getSitesRatingListByCategory(
        //             companyId,
        //             userHistoryFrequency ?? 7
        //           ).then((sitesResponse) => {
        //             if (sitesResponse?.companyRatingListDTOList?.length === 1) {
        //               const { siteId, siteName } =
        //                 sitesResponse?.companyRatingListDTOList[0];
        //               SessionStorage.setKey(
        //                 SessionVariables.BREADCRUMB,
        //                 [...breadCrumbList, { path: `/${siteId}`, name: siteName }],
        //                 sessionStorage
        //               );
        //             } else {
        //               SessionStorage.setKey(
        //                 SessionVariables.BREADCRUMB,
        //                 breadCrumbList,
        //                 sessionStorage
        //               );
        //             }
        //             const path = UserDashboardRoute.getRouteByPermissions(response);
        //             if (path) {
        //               history.push(path);
        //             }
        //           });
        //         } else {
        //           SessionStorage.setKey(
        //             SessionVariables.BREADCRUMB,
        //             groupBreadCrumbList,
        //             sessionStorage
        //           );
        //           const path = UserDashboardRoute.getRouteByPermissions(response);
        //           if (path) {
        //             history.push(path);
        //           }
        //         }
        //       });
        //     } else {
        //       // when length is not one
        //       const path = UserDashboardRoute.getRouteByPermissions(response);
        //       if (path) {
        //         history.push(path);
        //       }
        //     }
        //   }
        // });
    }
    if (userPermissions.some((x) => [SITE_VIEW].includes(x))) {
        if (!groupOptions.length) {
            getGroupOptions().then((response) => {
                var _a, _b, _c;
                if (!isEmpty(response)) {
                    if (userPermissions.some((x) => [GROUP_CREATION, COMPANY_EDIT].includes(x))) {
                        selectContextCompany(setContextCompany, response);
                    }
                    else if (userPermissions.some((x) => [SITE_EDIT].includes(x))) {
                        selectContextCompany(setContextCompany, (_a = response[0]) === null || _a === void 0 ? void 0 : _a.listSimpleDataDTO);
                    }
                    else if (userPermissions.some((x) => [SITE_VIEW].includes(x))) {
                        selectContextSite(setContextCompany, (_c = (_b = response[0]) === null || _b === void 0 ? void 0 : _b.listSimpleDataDTO[0]) === null || _c === void 0 ? void 0 : _c.listSimpleDataDTO[0]);
                    }
                }
            });
        }
    }
};
