import React, { useState } from "react";
import SingleSelectNew from "./SingleSelectNew";
const DecimalPlacesDropdown = ({ selectedValue, setSelectedValue, disabled = false, }) => {
    var _a;
    const [decimalPlacesOption] = useState((_a = Array.from(Array(8).keys())) === null || _a === void 0 ? void 0 : _a.map((value, index) => {
        return { id: index, text: value };
    }));
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { defaultValue: selectedValue, placeholder: "Select Decimal Places", onChange: setSelectedValue, disabled: disabled, selectedValue: selectedValue, options: decimalPlacesOption, optionDivId: false, valueProperty: "id", textProperty: "text" })));
};
export default DecimalPlacesDropdown;
