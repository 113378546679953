import React from 'react';
import './NavLoader.scss';
const NavLoader = ({ type, loaded }) => {
    const verticalLoader = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'top-part' },
            React.createElement("div", { className: 'sk-logo bg animated-bg' }),
            React.createElement("div", { className: 'sk-menu' },
                React.createElement("span", { className: 'sk-menu-item menu-header bg-1 animated-bg' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-75' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-80' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-50' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-75' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-50' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-60' })),
            React.createElement("div", { className: 'sk-menu' },
                React.createElement("span", { className: 'sk-menu-item menu-header bg-1 animated-bg' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-60' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-40' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-60' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-40' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-40' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-40' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-40' })),
            React.createElement("div", { className: 'sk-menu' },
                React.createElement("span", { className: 'sk-menu-item menu-header bg-1 animated-bg' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-60' }),
                React.createElement("span", { className: 'sk-menu-item bg animated-bg w-50' })),
            React.createElement("div", { className: 'sk-button animated-bg w-90' })),
        React.createElement("div", { className: 'bottom-part' },
            React.createElement("div", { className: 'sk-menu' },
                React.createElement("span", { className: 'sk-menu-item bg-1 animated-bg w-60' }),
                React.createElement("span", { className: 'sk-menu-item bg-1 animated-bg w-80' }))),
        React.createElement("div", { className: 'horizontal-menu' },
            React.createElement("span", { className: 'sk-menu-item bg animated-bg' }),
            React.createElement("span", { className: 'sk-menu-item bg animated-bg' }),
            React.createElement("span", { className: 'sk-menu-item bg animated-bg' }),
            React.createElement("span", { className: 'sk-menu-item bg animated-bg' }),
            React.createElement("span", { className: 'sk-menu-item bg animated-bg' }),
            React.createElement("span", { className: 'sk-menu-item bg animated-bg' }))));
    const horizontalLoader = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'left-part d-flex align-items-center' },
            React.createElement("span", { className: 'navbar-button bg animated-bg d-lg-none' }),
            React.createElement("span", { className: 'sk-logo bg animated-bg d-none d-lg-block' }),
            React.createElement("span", { className: 'search d-none d-md-block bg animated-bg' })),
        React.createElement("div", { className: 'right-part d-flex align-items-center' },
            React.createElement("div", { className: 'icon-box' },
                React.createElement("span", { className: 'icon bg animated-bg' }),
                React.createElement("span", { className: 'badge' })),
            React.createElement("span", { className: 'avatar bg animated-bg' }))));
    return (React.createElement("div", { className: `navbar-skeleton ${type} ${loaded ? 'invisible' : ''}` }, type === 'top-bar' ? horizontalLoader : verticalLoader));
};
export default NavLoader;
