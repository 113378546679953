import { httpGet, httpPost, httpPut } from "./request/requestApi";
const controllerEndpoint = "admin/site";
const getCompaniesControllerEndpoint = "admin/company";
export class SiteApi {
    static getSites(companyId) {
        return httpGet(`${getCompaniesControllerEndpoint}/site/${companyId}`);
    }
    static createSite(data) {
        return httpPost(controllerEndpoint, { body: data });
    }
    static updateSite(data) {
        return httpPut(`${controllerEndpoint}`, { body: data });
    }
    static inActivateeSite(id) {
        return httpPost(`${controllerEndpoint}/inactive/${id}`, {});
    }
    static activateSite(id) {
        return httpPost(`${controllerEndpoint}/active/${id}`, {});
    }
    static getCQCWidget(data) {
        return httpGet(`${getCompaniesControllerEndpoint}/cqcWidget/${data}`);
    }
    static checkCQCExist(siteId, cqcnumber) {
        return httpGet(`${getCompaniesControllerEndpoint}/cqc/${cqcnumber}`);
    }
    static getSingleSite(id) {
        return httpGet(`${controllerEndpoint}/${id}`);
    }
}
