import React from "react";
import dayjs from "dayjs";
import { convertDateToLocal } from "../../../../../core/helpers/dateFormatter";
const LastRefreshTime = ({ lastRefreshTime }) => {
    return (React.createElement("div", { className: "refresh-time-sub-categories" },
        React.createElement("span", null,
            "Last updated on",
            " ",
            dayjs(convertDateToLocal(lastRefreshTime)).format("hh:mm A")),
        React.createElement("span", { className: "mx-2" }, "|"),
        React.createElement("span", null, dayjs(convertDateToLocal(lastRefreshTime)).format("LL"))));
};
export default LastRefreshTime;
