import React, { useState } from "react";
import { Tooltip } from "antd";
import { uniqueId } from "lodash-es";
const useUniqueId = (prefix) => {
    const [persistedId] = useState(uniqueId(prefix));
    return persistedId;
};
export const NumericColoredLine = ({ colorClassName, onAdd, onClear, getRiskBand, weight, weightFlag }) => {
    const id = useUniqueId("colored-line");
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { className: "colored-line-tooltip", id: id, placement: "bottom", title: onClear ? "Remove" : onAdd ? "Add" : "" },
            React.createElement("div", { id: id, onClick: onAdd || onClear, className: `colored-line ${onClear || onAdd ? "clickable" : ""} ${colorClassName}`, style: { cursor: onAdd || onClear ? "pointer" : "not-allowed" } }),
            React.createElement("span", { className: "grades" }, (weight !== 0 && !weightFlag) && (colorClassName === "color-darkGreen"
                ? getRiskBand("A")
                : colorClassName === "color-green"
                    ? getRiskBand("B")
                    : colorClassName === "color-amber"
                        ? getRiskBand("C")
                        : colorClassName === "color-red"
                            ? getRiskBand("D")
                            : "")))));
};
