var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import InviteUserForm from "./components/inviteUserForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPendingUser } from "../../../../redux/pending-user/actions";
import { ResponseStatusCodes } from "../../../../core/constants/responseStatusCodes";
import { useHistory } from "react-router-dom";
import { registerUserRoute, usersRoute, } from "../../../../core/constants/routesPath";
import { notifications } from "../../../../layout/components/notifications";
import { useTranslation } from "react-i18next";
import { getUsers } from "../../../../redux/user/actions";
import { getLookupByListing } from "../../../../core/helpers/lookupHanlder";
import { getLookups } from "../../../../redux/lookup/actions";
import { LookupNames } from "../../../../core/constants/lookup";
import useDidMount from "../../../../core/hooks/useDidMount";
import { DelphiGroupId } from "../../../../core/constants/groupConstants";
import usePermissions from "../../../../core/hooks/usePermissions";
const InviteUserContainer = ({ users, context, addPendingUser, getUsers, getLookups, roles, }) => {
    const [t] = useTranslation();
    const { defaultCompanyValues, isCompanyAdmin, isSiteAdmin, defaultGroupValues, //internal user other than delphie will belong to single group,
    defaultSiteValues, } = usePermissions();
    const initialUser = {
        title: undefined,
        emailAddress: "",
        groupId: defaultGroupValues === null || defaultGroupValues === void 0 ? void 0 : defaultGroupValues.id,
        companyId: defaultCompanyValues === null || defaultCompanyValues === void 0 ? void 0 : defaultCompanyValues.id,
        siteId: defaultSiteValues === null || defaultSiteValues === void 0 ? void 0 : defaultSiteValues.id,
        professionId: undefined,
        firstName: "",
        middleName: "",
        lastName: "",
        roleId: undefined,
        startDate: "",
        endDate: "",
        band: undefined,
    };
    const history = useHistory();
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKProfession, LookupNames.LKTitle],
            getLookups,
        });
    });
    const addUserHandler = (user, setErrors, resetForm) => {
        var _a;
        const { groupId, companyId, siteId, roleId, band, startDate, endDate } = user, invitePayload = __rest(user, ["groupId", "companyId", "siteId", "roleId", "band", "startDate", "endDate"]);
        invitePayload["companyId"] = (_a = defaultGroupValues === null || defaultGroupValues === void 0 ? void 0 : defaultGroupValues.id) !== null && _a !== void 0 ? _a : DelphiGroupId;
        invitePayload["registrationUrl"] = registerUserRoute;
        invitePayload["externalUserSiteRequestDTOs"] = [
            {
                companyId: siteId || companyId || groupId,
                roleId: roleId,
                startDate: startDate,
                endDate: endDate,
                band: band,
            },
        ];
        addPendingUser(invitePayload).then((response) => {
            var _a;
            if (response &&
                response.code ===
                    ResponseStatusCodes.EXTERNAL_USER_INVITATION_LIMIT_REACHED) {
                resetForm();
            }
            else if (response &&
                response.code ===
                    ResponseStatusCodes.EXTERNAL_USER_REGISTRATION_LINK_ALREADY_SENT) {
                resetForm();
            }
            else if (response &&
                response.code === ResponseStatusCodes.USER_ALREADY_EXIST) {
                resetForm();
            }
            else if (response &&
                response.code === ResponseStatusCodes.EXISTING_USER_GRANTED_ROLE) {
                resetForm();
                history.push(usersRoute);
                getUsers((_a = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _a === void 0 ? void 0 : _a.id).catch((error) => {
                    throw error;
                });
            }
            else if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return false;
            }
            else {
                resetForm();
                notifications.success(t("An invitation has been sent to User on ") + user.emailAddress);
                history.push(usersRoute);
            }
        });
    };
    return (React.createElement("div", null,
        React.createElement(InviteUserForm, { onCancel: () => { }, onSubmit: addUserHandler, submitText: "Save", userInfo: initialUser, users: users, roles: roles, permission: {
                isCompanyAdmin,
                isSiteAdmin,
            } })));
};
InviteUserContainer.propTypes = {
    users: PropTypes.array.isRequired,
    context: PropTypes.object.isRequired,
    addPendingUser: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
    return {
        users: state.users,
        context: state.context,
        groupOptions: state.groupOptions,
        roles: state.roles,
    };
}
const mapDispatchToProps = {
    addPendingUser,
    getUsers,
    getLookups,
};
export default connect(mapStateToProps, mapDispatchToProps)(InviteUserContainer);
