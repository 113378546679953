import { GET_ROLES_SUCCESS, UPDATE_ROLE_PERMISSION_SUCCESS, ADD_ROLE_SUCCESS, DELETE_ROLE_SUCCESS, DELETE_ROLE_PERMISSION_SUCCESS, } from "./types";
import { RoleApi } from "../../core/api/roleApi";
export const getRolesSuccess = (data) => ({
    type: GET_ROLES_SUCCESS,
    payload: data,
});
export const addRoleSuccess = (data) => ({
    type: ADD_ROLE_SUCCESS,
    payload: data,
});
export const updateRolePermissionSuccess = (data) => ({
    type: UPDATE_ROLE_PERMISSION_SUCCESS,
    payload: data,
});
export const deleteRoleSuccess = (data) => ({
    type: DELETE_ROLE_SUCCESS,
    payload: data,
});
export const deleteRolePermissionSuccess = (data) => ({
    type: DELETE_ROLE_PERMISSION_SUCCESS,
    payload: data,
});
export function getRoles(companyId, isCompanyChange = false) {
    return function (dispatch) {
        return RoleApi.getRoles(companyId)
            .then((roles) => {
            if (roles.length > 0 || isCompanyChange) {
                dispatch(getRolesSuccess(roles));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function getAllowedRoles() {
    return function (dispatch) {
        return RoleApi.getUserAllowedRoles()
            .then((roles) => {
            if ((roles === null || roles === void 0 ? void 0 : roles.length) > 0) {
                const mapRoles = roles.map((role) => {
                    var _a, _b;
                    return (Object.assign(Object.assign({}, role), { permissionsName: (_b = (_a = role === null || role === void 0 ? void 0 : role.permissions) === null || _a === void 0 ? void 0 : _a.map(({ name }) => name)) === null || _b === void 0 ? void 0 : _b.join(" | ") }));
                });
                dispatch(getRolesSuccess(mapRoles));
            }
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function addRole(role) {
    return function (dispatch) {
        return RoleApi.createRole(role)
            .then((response) => {
            var _a, _b;
            if (response.code) {
                return response;
            }
            const updateResponse = Object.assign({}, response);
            updateResponse.permissionsName = (_b = (_a = updateResponse === null || updateResponse === void 0 ? void 0 : updateResponse.permissions) === null || _a === void 0 ? void 0 : _a.map(({ name }) => name)) === null || _b === void 0 ? void 0 : _b.join(" | ");
            dispatch(addRoleSuccess(updateResponse));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function editRole(role) {
    return function (dispatch) {
        return RoleApi.updateRolePermissions(role)
            .then((response) => {
            var _a, _b;
            if (response.code) {
                return response;
            }
            const updateResponse = Object.assign({}, response);
            updateResponse.permissionsName = (_b = (_a = updateResponse === null || updateResponse === void 0 ? void 0 : updateResponse.permissions) === null || _a === void 0 ? void 0 : _a.map(({ name }) => name)) === null || _b === void 0 ? void 0 : _b.join(" | ");
            dispatch(updateRolePermissionSuccess(updateResponse));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function deleteRole(data) {
    return function (dispatch) {
        return RoleApi.deleteRole(data)
            .then((response) => {
            dispatch(deleteRoleSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function deleteRolePermission(data) {
    return function (dispatch) {
        return RoleApi.deleteRolePermission(data)
            .then((id) => {
            dispatch(deleteRolePermissionSuccess(data));
        })
            .catch((error) => {
            throw error;
        });
    };
}
