import { Button } from "antd";
import React from "react";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
const AssociatedTrackItemListing = ({ associatedList, handleClick, isModal = false, selectedTrackItem, handleResetChartData = null, }) => {
    const renderAssociationList = (associatedList === null || associatedList === void 0 ? void 0 : associatedList.length) ? (associatedList === null || associatedList === void 0 ? void 0 : associatedList.map(({ name, id, bandName, rgbColor }) => (React.createElement(Button, { type: Number(selectedTrackItem) === id ? "primary" : "default", className: Number(selectedTrackItem) === id ? "" : "roleCountButton", size: "small", onClick: (e) => {
            handleClick(e);
            handleResetChartData && handleResetChartData();
        }, id: id },
        React.createElement("span", null, name),
        React.createElement("span", { hidden: isEmpty(bandName), style: {
                color: Number(selectedTrackItem) === id ? "white" : rgbColor,
                margin: "unset",
            } }, `(${bandName})`))))) : (React.createElement("span", null, "No Relations"));
    return (React.createElement("div", { className: "relations_container", style: isModal ? { borderTop: "none" } : { paddingLeft: "93px" } }, isModal ? (React.createElement("div", { className: "horizontalList", style: { width: "95%" } },
        React.createElement("span", { className: "header_text" }, "Relations"),
        React.createElement("span", { className: "ml-2" }, renderAssociationList))) : (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "header_text" }, "Relations"),
        React.createElement("div", { className: "mt-2" }, renderAssociationList)))));
};
export default AssociatedTrackItemListing;
