import { httpGet, httpPost, httpPut } from "./request/requestApi";
const endPointPath = "notification";
export class InAppNotificationApi {
    static getLastNNotifications(n, silent = false) {
        return httpGet(`${endPointPath}/getLastNNotifications/${n}`, {
            silent: silent,
        });
    }
    static getNotificationsUnreadCount(silent = false) {
        return httpGet(`${endPointPath}/getUnreadCount`, {
            silent: silent,
        });
    }
    static getRelatedNotifications(itemId, sort, data) {
        return httpPost(`${endPointPath}/getRecentNotifications/${itemId}/${sort}`, {
            body: data,
        });
    }
    static getRelatedNotificationsByFrequency(itemId, notificationDefId, frequency, pageNo, pageSize, sort) {
        return httpGet(`${endPointPath}/getRelatedNotifications/${itemId}/${notificationDefId}/${frequency}/${pageNo}/${pageSize}/${sort}`);
    }
    static getSingleNotification(id) {
        return httpGet(`${endPointPath}/getOutboxItem/${id}`);
    }
    static updateNotificationInAppStatus(id, inAppStatus) {
        return httpPut(`${endPointPath}/updateInAppStatus/${id}/${inAppStatus}`);
    }
    static changeNotificationFlag(id, flagged) {
        return httpPut(`${endPointPath}/updateFlagged/${id}/${flagged}`);
    }
    static getNotificationList(body) {
        return httpPost(`${endPointPath}/getAllNotifications`, { body });
    }
}
