import React, { useEffect, useRef, useState } from "react";
import { useSelector, connect } from "react-redux";
import ChartApi from "../../../../../../../core/api/chartApi";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import UsePrintPage from "../../../../../../../core/hooks/usePrintPage";
import ChartVariablesContainer from "../chartVariables/chartVariablesContainer";
import AssociatedTrackItemListing from "./associatedTrackItemListing";
import MovingAverageAndProjectionChart from "./movingAverageAndProjectionChart";
const ChartPopupContainer = ({ trackItemId, associatedList, selectedFilterValue, selectedRange, }) => {
    const [chartData, setChartData] = useState({
        totalPages: 1,
        currentPage: 0,
        data: [],
        loadedProjectedData: false,
    });
    const handleResetChartData = () => {
        setChartData({
            totalPages: 1,
            currentPage: 0,
            data: [],
            loadedProjectedData: false,
        });
    };
    const firstRender = useRef(true);
    const [trackedItemId, setTrackedItemId] = useState(trackItemId);
    const [trackedItemInfo, setTrackedItemInfo] = useState(undefined);
    const { nodeRef } = UsePrintPage();
    const hide = useSelector((state) => state.togglePrint);
    const trackedItemValueTypeLookups = useSelector((state) => { var _a; return (_a = state.lookups[LookupNames.LKReportValueType]) !== null && _a !== void 0 ? _a : []; });
    const [chartVariables, setChartVariables] = useState(undefined);
    useEffect(() => {
        if (trackedItemId) {
            ChartApi.getChartVariables(trackedItemId).then((res) => {
                setTrackedItemInfo(res);
            });
        }
    }, [trackedItemId]);
    const handleClickAssociated = (event) => {
        if (firstRender === null || firstRender === void 0 ? void 0 : firstRender.current)
            firstRender.current = false;
        setTrackedItemId(event.currentTarget.id);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: nodeRef },
            React.createElement(AssociatedTrackItemListing, { associatedList: associatedList, handleClick: handleClickAssociated, isModal: true, selectedTrackItem: (firstRender === null || firstRender === void 0 ? void 0 : firstRender.current) && trackedItemId, handleResetChartData: handleResetChartData }),
            React.createElement(MovingAverageAndProjectionChart, { trackedItemId: trackedItemId, chartVariables: chartVariables, setChartVariables: setChartVariables, isPrintDialogOpen: hide.status, trackedItemValueTypeLookups: trackedItemValueTypeLookups, selectedFilterValue: selectedFilterValue, selectedRange: selectedRange, chartData: chartData, setChartData: setChartData, trackedItemInfo: trackedItemInfo, chartInfoContentElement: React.createElement(ChartVariablesContainer, { trackedItemInfo: trackedItemInfo }) }))));
};
export default connect(null, null)(ChartPopupContainer);
