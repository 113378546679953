import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import React from "react";
import SingleSelectNew from "./SingleSelectNew";
import { LookupNames } from "../../../core/constants/lookup";
import { LookupApi } from "../../../core/api/lookupApi";
const SubCategoryDropdownContainer = ({ onChange, selectedValue, defaultValue, className, categoryId, companyId, siteId, disabled, allowClear = false, labelInValue, haveDefaultSiteHandler = false, placeHolder, error = null, }) => {
    const isAllowClear = useRef(false);
    const parentLookupData = useSelector((state) => { var _a; return (_a = state.lookups[LookupNames.LKItemCategory]) !== null && _a !== void 0 ? _a : []; });
    const [userSubCategoryPermissions, setUserSubCategoryPermissions] = useState();
    useEffect(() => {
        var _a;
        if (categoryId) {
            if (parentLookupData.length) {
                const parentPrimaryId = (_a = parentLookupData === null || parentLookupData === void 0 ? void 0 : parentLookupData.find((x) => x.code === categoryId.toString())) === null || _a === void 0 ? void 0 : _a.id;
                LookupApi.getChildLookupByParentId(parentPrimaryId).then((response) => {
                    setUserSubCategoryPermissions(response);
                });
            }
        }
        else {
            isAllowClear.current = true;
            setUserSubCategoryPermissions(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { id: "subcategory-filter-dropdown", options: userSubCategoryPermissions, selectedValue: selectedValue, defaultValue: defaultValue, onChange: onChange, placeholder: placeHolder !== null && placeHolder !== void 0 ? placeHolder : "Select a SubCategory", key: "subcategorySelect", valueProperty: "code", textProperty: "text", className: className, disabled: disabled, labelInValue: labelInValue, allowClear: isAllowClear.current, error: error })));
};
export default SubCategoryDropdownContainer;
