export const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};
export const scrollIntoView = (elementId) => {
    const targetElement = document.getElementById(elementId);
    targetElement === null || targetElement === void 0 ? void 0 : targetElement.scrollIntoView({ behavior: "smooth" });
};
