var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Alert } from "antd";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Bar, CartesianGrid, ComposedChart, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, } from "recharts";
import ChartApi from "../../../../../../../core/api/chartApi";
import { defaultDateFormat, defaultDateFormatForTinyChart, } from "../../../../../../../core/constants/DateTime";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
import { ChartFilterOptions } from "../../../../../../../core/enums/chart";
import { chartDataMinMaxPercentage, ChartYAxisLabel, } from "../../../../../../../core/helpers/chartYAxisLabel";
import { convertDateToUnix, formatChartDate, } from "../../../../../../../core/helpers/dateFormatter";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import HistoricalChartScale from "../../../../../../../layout/components/rechart/tiny-chart/HistoricalChartScale";
function MovingAverageAndProjectionChart({ trackedItemId, chartVariables, setChartVariables, isPrintDialogOpen, trackedItemValueTypeLookups = [], selectedFilterValue = 0, selectedRange = [], chartData, setChartData, trackedItemInfo = null, chartInfoContentElement = null, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [opacity, setStrockOpacity] = useState({
        shortTermMA: 0,
        midTermMA: 0,
        longTermMA: 0,
        actualValue: 1,
        projectionLine: 1,
    });
    // const [xAxisInterval, setXAxisInterval] = useState(100);
    const [xAxisLabels, setXAxisLabels] = useState({ frequency: 0, labels: [] });
    const [xaxisMaximum, setXaxisMaximum] = useState(null);
    // const [xaxisMinimum, setXaxisMinimum] = useState(null);
    const [movingAverageOptions] = useState([
        { name: "actualValue", color: "#F1C40F", value: "Actual Value" },
        { name: "shortTermMA", color: "#1089F9", value: "Short Term" },
        { name: "midTermMA", color: "#8E44AD", value: "Medium Term" },
        { name: "longTermMA", color: "#EA5455", value: "Long Term" },
    ]);
    //default value is selected moving average use
    const getMAValues = useCallback((code = null) => {
        var _a;
        const index = code !== null && code !== void 0 ? code : (trackedItemInfo === null || trackedItemInfo === void 0 ? void 0 : trackedItemInfo.projectionAverageUsed) + 1;
        return ((_a = movingAverageOptions[index]) !== null && _a !== void 0 ? _a : {
            name: null,
            color: "black",
        });
    }, [trackedItemInfo === null || trackedItemInfo === void 0 ? void 0 : trackedItemInfo.projectionAverageUsed, movingAverageOptions]);
    useEffect(() => {
        var _a;
        const selectedMA = (_a = getMAValues()) === null || _a === void 0 ? void 0 : _a.name;
        if (selectedMA) {
            setStrockOpacity((prev) => (Object.assign(Object.assign({}, prev), { [selectedMA]: 1 })));
        }
    }, [getMAValues]);
    useEffect(() => {
        if (selectedFilterValue === ChartFilterOptions.AllData ||
            selectedFilterValue === ChartFilterOptions.Default ||
            (selectedFilterValue === ChartFilterOptions.Custom &&
                selectedRange.length > 0)) {
            getPaginatedData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterValue, selectedRange, chartData, trackedItemId]);
    const data = (_a = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _a === void 0 ? void 0 : _a.map((value) => (Object.assign(Object.assign({}, value), { dataPointDate: convertDateToUnix(value.dataPointDate), actualDate: value.dataPointDate })));
    const getPaginatedData = () => __awaiter(this, void 0, void 0, function* () {
        var _j;
        if (!isEmpty(selectedFilterValue)) {
            let projectionLoaded = false;
            let projectionMax = null;
            if ((chartData === null || chartData === void 0 ? void 0 : chartData.totalPages) > (chartData === null || chartData === void 0 ? void 0 : chartData.currentPage)) {
                const response = yield ChartApi.getChartData({
                    pageNo: chartData.currentPage,
                    reportId: trackedItemId,
                    chartFilter: selectedFilterValue,
                    startDate: selectedRange[0],
                    endDate: selectedRange[1],
                });
                // setXAxisInterval(response.xAxisInterval);
                setXAxisLabels(response.xAxisLabels);
                setChartData((prev) => {
                    var _a;
                    return {
                        totalPages: response === null || response === void 0 ? void 0 : response.totalPages,
                        currentPage: prev.currentPage + 1,
                        data: ((_a = prev === null || prev === void 0 ? void 0 : prev.data) === null || _a === void 0 ? void 0 : _a.length)
                            ? [...prev.data, ...response === null || response === void 0 ? void 0 : response.data]
                            : response === null || response === void 0 ? void 0 : response.data,
                        loadedProjectedData: false,
                    };
                });
            }
            else if (!(chartData === null || chartData === void 0 ? void 0 : chartData.loadedProjectedData) &&
                ((_j = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _j === void 0 ? void 0 : _j.length) > 0) {
                const predictionData = yield ChartApi.getChartPrediction({
                    pageNo: chartData.currentPage,
                    reportId: trackedItemId,
                    chartFilter: selectedFilterValue,
                    startDate: selectedRange[0],
                    endDate: selectedRange[1],
                });
                if (predictionData === null || predictionData === void 0 ? void 0 : predictionData.ProjectionLineData) {
                    setChartData((prev) => {
                        var _a, _b;
                        ///merge moving average last and prediction line first data and concat oth listing
                        const movingAverageData = [...prev === null || prev === void 0 ? void 0 : prev.data];
                        const lastPointMovingAverage = movingAverageData.pop();
                        const [first, ...predictionLineData] = [
                            ...predictionData === null || predictionData === void 0 ? void 0 : predictionData.ProjectionLineData,
                        ];
                        const updatedData = [
                            ...movingAverageData,
                            Object.assign(Object.assign({}, first), lastPointMovingAverage),
                            ...predictionLineData,
                        ];
                        projectionMax = (_a = updatedData === null || updatedData === void 0 ? void 0 : updatedData.filter(({ projectionLine }) => projectionLine !== null).pop()) === null || _a === void 0 ? void 0 : _a.projectionLine;
                        // setXaxisMinimum(
                        //   updatedData[0] &&
                        //     convertDateToUnix(
                        //       updatedData[0]?.dataPointDate,
                        //       defaultDateFormat
                        //     )
                        // );
                        setXaxisMaximum((updatedData === null || updatedData === void 0 ? void 0 : updatedData.length) &&
                            convertDateToUnix((_b = updatedData[updatedData.length - 1]) === null || _b === void 0 ? void 0 : _b.dataPointDate, defaultDateFormat));
                        //////////////////////////////////////////////////
                        projectionLoaded = true;
                        return Object.assign(Object.assign({}, prev), { data: updatedData, loadedProjectedData: true });
                    });
                }
            }
            if (projectionLoaded) {
                const result = yield ChartApi.getConfigurationParameters({
                    pageNo: null,
                    reportId: trackedItemId,
                    chartFilter: selectedFilterValue,
                    startDate: selectedRange[0],
                    endDate: selectedRange[1],
                    projectionLineMax: projectionMax ? projectionMax : null,
                });
                setChartVariables(Object.assign({}, result));
            }
        }
    });
    const yAxisLabelText = useMemo(() => {
        var _a;
        const unitText = (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.chartUnit) === 1
            ? ``
            : `(in ${(_a = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.chartUnitText) !== null && _a !== void 0 ? _a : ``})`;
        return (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.yaxisLabel)
            ? `${chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.yaxisLabel}  ${unitText}`
            : `Y-axis ${unitText}`;
    }, [chartVariables]);
    const CustomTooltip = memo(({ active, payload, label }) => {
        var _a, _b;
        if (active && payload && payload.length) {
            const { payload: { dataPointDate, actualValue, shortTermMA, midTermMA, longTermMA, projectionLine, noOfDays, }, } = payload[0];
            const showProjectionTooltip = opacity.projectionLine &&
                !isEmpty(projectionLine) &&
                isEmpty(shortTermMA) &&
                isEmpty(midTermMA) &&
                isEmpty(longTermMA);
            return (React.createElement("div", { className: "custom-chart-tooltip" },
                React.createElement("div", { className: "label" },
                    formatChartDate(dataPointDate, "DD-MMM-YYYY"),
                    " "),
                showProjectionTooltip ? (React.createElement("div", { className: "mt-2" },
                    "Days: +",
                    noOfDays)) : null,
                opacity.actualValue && !isEmpty(actualValue) ? (React.createElement("div", { style: { color: (_a = getMAValues(0)) === null || _a === void 0 ? void 0 : _a.color }, className: "mt-2" },
                    "Actual Value :",
                    " ",
                    ChartYAxisLabel(actualValue, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.valueType, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.chartUnit, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.decimalPlaces, (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.valueType) !==
                        TrackedItemsVariables.TRACKED_ITEM_VALUE_NUMBER))) : null,
                movingAverageOptions.map(({ name, color, value }) => {
                    var _a;
                    return ((_a = getMAValues()) === null || _a === void 0 ? void 0 : _a.name) === name &&
                        !isEmpty(eval(name)) &&
                        !showProjectionTooltip ? (React.createElement("div", { style: { color: color }, className: "mt-2" },
                        value,
                        " :",
                        " ",
                        ChartYAxisLabel(eval(name), 
                        // (name = "shortTermMA"
                        //   ? shortTermMA
                        //   : (name = "midTermMA"
                        //       ? midTermMA
                        //       : (name = "longTermMA" && longTermMA))),
                        chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.valueType, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.chartUnit, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.decimalPlaces, (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.valueType) !==
                            TrackedItemsVariables.TRACKED_ITEM_VALUE_NUMBER))) : null;
                }),
                showProjectionTooltip ? (React.createElement("div", { style: { color: (_b = getMAValues()) === null || _b === void 0 ? void 0 : _b.color }, className: "mt-2" },
                    "Projection Line :",
                    " ",
                    ChartYAxisLabel(projectionLine, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.valueType, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.chartUnit, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.decimalPlaces, (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.valueType) !==
                        TrackedItemsVariables.TRACKED_ITEM_VALUE_NUMBER))) : null));
        }
        return null;
    });
    // const handleOpacityEffect = useCallback(
    //   (legendProp: any) => {
    //     const { id: value } = legendProp;
    //     //@ts-ignore
    //     let updatedOpacityValue = opacity[value] === 1 ? 0 : 1;
    //     setStrockOpacity({ ...opacity, [value]: updatedOpacityValue });
    //   },
    //   [opacity]
    // );
    const xAxisTickFormat = (props) => {
        const { x, y, payload } = props;
        return (React.createElement("g", { transform: `translate(${x},${y})`, style: { fontWeight: "bold" }, className: "axis-font" },
            React.createElement("text", { x: 0, y: 0, dy: 5, textAnchor: "end", fill: "#666", transform: "rotate(-30)" }, (xAxisLabels === null || xAxisLabels === void 0 ? void 0 : xAxisLabels.frequency) === 2
                ? formatChartDate(payload.value, "MMM-YY")
                // : xAxisLabels?.frequency === 1
                // ? formatChartDate(payload.value, "MMM")
                : formatChartDate(payload.value, "DD-MMM"))));
    };
    const getValueType = useMemo(() => {
        const result = trackedItemValueTypeLookups.find(({ code }) => code === `${chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.valueType}`);
        return result === null || result === void 0 ? void 0 : result.code;
    }, [trackedItemValueTypeLookups, chartVariables]);
    const yAxisFormat = (props) => {
        const { x, y, payload } = props;
        return (React.createElement("g", { transform: `translate(${x},${y})`, className: "axis-font" }, getValueType && (React.createElement("text", { x: 0, y: 0, dy: 5, textAnchor: "end", fill: "#666" }, ChartYAxisLabel(payload.value, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.valueType, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.chartUnit, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.decimalPlaces)))));
    };
    const yAxisTickArray = () => {
        var _a, _b, _c, _d, _e;
        let array = [];
        let lowEnd = (_b = (_a = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.colorCodeList[0]) === null || _a === void 0 ? void 0 : _a.startPoint) !== null && _b !== void 0 ? _b : 0;
        let highEnd = (_e = (_d = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.colorCodeList[((_c = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.colorCodeList) === null || _c === void 0 ? void 0 : _c.length) - 1]) === null || _d === void 0 ? void 0 : _d.endPoint) !== null && _e !== void 0 ? _e : 1;
        if (lowEnd !== highEnd) {
            let margin = (highEnd - lowEnd) / 10;
            for (var i = lowEnd; i <= highEnd + margin * 0.01;) {
                array.push(i);
                i = i + margin;
            }
        }
        return array;
    };
    const isProjectionLine = useMemo(() => { var _a; return (_a = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _a === void 0 ? void 0 : _a.some(({ projectionLine }) => !isEmpty(projectionLine)); }, [chartData]);
    const chartPadding = useMemo(() => {
        return {
            bottom: chartDataMinMaxPercentage(chartVariables, 10),
            top: chartDataMinMaxPercentage(chartVariables, 10),
        };
    }, [chartVariables]);
    // const [isViewChartInfoOpen, setViewChartInfo] = useState(false);
    // const customLegend = (props) => {
    //   const { payload } = props;
    //   return (
    //     <div className="customLegend">
    //       <div className="d-inline">
    //         {isEmpty(chartVariables?.name) === false ? (
    //           <span>
    //             <span
    //               style={{
    //                 marginLeft: "3px",
    //                 fontSize: "1.3rem",
    //                 fontWeight: "bold",
    //               }}
    //             >
    //               {chartVariables.name}
    //             </span>
    //           </span>
    //         ) : (
    //           <Alert
    //             style={{ padding: "5px" }}
    //             message="Data not available!"
    //             type="error"
    //           />
    //         )}
    //         {chartInfoContentElement && (
    //           <Popover
    //             placement="bottom"
    //             overlayClassName="chart_variable_popover_container"
    //             content={chartInfoContentElement}
    //             visible={isViewChartInfoOpen}
    //             onVisibleChange={setViewChartInfo}
    //           >
    //             <InfoCircleOutlined
    //               style={{
    //                 fontSize: "1.1rem",
    //                 marginLeft: "7px",
    //                 ...(isViewChartInfoOpen && { color: `#17a99a` }),
    //               }}
    //             />
    //           </Popover>
    //         )}
    //       </div>
    //       <ul style={{ alignItems: "baseline", whiteSpace: "nowrap" }}>
    //         <li className="customLegendItems">
    //           <TrackedItemBandCard
    //             bandColor={chartVariables?.bandColor}
    //             status={chartVariables?.bandName}
    //           />
    //         </li>
    //         {payload.map((entry, index) => (
    //           <li
    //             className="customLegendItems"
    //             style={{
    //               background:
    //                 entry.id === "projectionLine" ? "#6E6B7B" : entry.color,
    //             }}
    //             key={`item-${index}`}
    //             onClick={() => handleOpacityEffect(entry)}
    //           >
    //             {entry.value}
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //   );
    // };
    // const getGradientPercentage = (value, maxValue) => {
    //   let percentageValue = (value / (maxValue || 1)) * 100;
    //   return `${percentageValue}%`;
    // };
    // const getMaxValue = useCallback(
    //   (key) => {
    //     const defaultValue = { minValue: 0, maxValue: 0 };
    //     if (isEmpty(chartData)) return defaultValue;
    //     const mapData: number[] = chartData?.data
    //       ?.map((value) => value[key])
    //       ?.filter(Boolean);
    //     if (!mapData?.length) return defaultValue;
    //     return { minValue: Math.min(...mapData), maxValue: Math.max(...mapData) };
    //   },
    //   [chartData]
    // );
    // const getGradientOffsetAndColor = useCallback(
    //   (key = getMAValues()?.name) => {
    //     if (isEmpty(chartVariables) || isEmpty(chartData)) {
    //       return [];
    //     }
    //     //negative min value will set to 0 due to limitaion for area chart mapping -ve values
    //     let minValue =
    //       isEmpty(chartVariables?.minimumValue) ||
    //       chartVariables?.minimumValue < 0
    //         ? 0
    //         : chartVariables?.minimumValue;
    //     if (getMaxValue(key)?.minValue < 0) minValue = getMaxValue(key)?.minValue;
    //     //difference between selected moving average/projection line maximum point and min value of y axis
    //     const maxValue = getMaxValue(key)?.maxValue - minValue;
    //     const colorCodeList = [...chartVariables?.colorCodeList];
    //     return colorCodeList?.map(({ endPoint, color }, index, currArray) => ({
    //       offset:
    //         index + 1 === currArray?.length //last index
    //           ? `100%`
    //           : getGradientPercentage(endPoint - minValue, maxValue),
    //       color,
    //     }));
    //   },
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   [chartVariables, chartData]
    // );
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 0);
    }, []);
    const xaxisTicks = useMemo(() => {
        var _a;
        return (_a = xAxisLabels === null || xAxisLabels === void 0 ? void 0 : xAxisLabels.labels) === null || _a === void 0 ? void 0 : _a.map((rec) => convertDateToUnix(rec, defaultDateFormatForTinyChart));
    }, [xAxisLabels]);
    return (React.createElement("div", { id: "printThis", "data-test": "line-chart-container", className: "chartArea" },
        loaded && (React.createElement(React.Fragment, null,
            !(data === null || data === void 0 ? void 0 : data.length) && (React.createElement(Alert, { className: "mb-2", style: { padding: "5px", width: "fit-content" }, message: "Data not available!", type: "error" })),
            React.createElement("div", { className: "historicalChartPopup customChartPopup", style: { margin: 0, padding: "0 35px 0 10px" } },
                React.createElement("div", { className: "row", style: {
                        alignItems: "stretch",
                        position: "relative",
                        height: "inherit",
                    } },
                    React.createElement("div", { style: {
                            minHeight: "120px",
                            width: "100%",
                        } },
                        React.createElement(ResponsiveContainer, null,
                            React.createElement(ComposedChart, { data: data, margin: { top: 7, right: 10, bottom: 10, left: 12 }, key: "composedChart" },
                                (data === null || data === void 0 ? void 0 : data.length) && (React.createElement(CartesianGrid, { stroke: "rgba(255,255,255,0.08)", fill: "#2b3b4a" })),
                                React.createElement(XAxis, { type: "number", padding: { left: 10, right: 10 }, dataKey: "dataPointDate", domain: ["dataMin", xaxisMaximum], interval: 0, tick: Array.isArray(xaxisTicks) && (xaxisTicks === null || xaxisTicks === void 0 ? void 0 : xaxisTicks.length) > 0
                                        ? xAxisTickFormat
                                        : null, ticks: xaxisTicks, key: "x-axis", height: 40, label: {
                                        value: "Date",
                                        position: "bottom",
                                    } }),
                                React.createElement(YAxis, { type: "number", padding: { top: 20, bottom: 20 }, domain: [
                                        (_c = (_b = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.colorCodeList[0]) === null || _b === void 0 ? void 0 : _b.startPoint) !== null && _c !== void 0 ? _c : 0,
                                        (_f = (_e = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.colorCodeList[((_d = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.colorCodeList) === null || _d === void 0 ? void 0 : _d.length) - 1]) === null || _e === void 0 ? void 0 : _e.endPoint) !== null && _f !== void 0 ? _f : 1,
                                    ], ticks: yAxisTickArray(), width: 75, allowDataOverflow: true, tick: yAxisFormat, label: {
                                        value: yAxisLabelText,
                                        angle: -90,
                                        position: "middleLeft",
                                        dx: -40,
                                    }, key: "y-axis", fontSize: "12px" }),
                                React.createElement(Bar, { dataKey: "actualValue", type: "linear", strokeWidth: 0, stroke: "#fff", fillOpacity: 1, width: 1, fill: "#fff", barSize: 2, minPointSize: 3 }),
                                movingAverageOptions.map(({ name, color }) => {
                                    var _a;
                                    return ((_a = getMAValues()) === null || _a === void 0 ? void 0 : _a.name) === name && (React.createElement(Line, { type: "linear", dataKey: name, strokeWidth: 2, stroke: "#00F0FF", fillOpacity: 1, dot: false, activeDot: true }));
                                }),
                                React.createElement(Line, { type: "linear", dataKey: "projectionLine", strokeWidth: 2, stroke: "#FFC700", fillOpacity: 1, dot: false, activeDot: true, strokeDasharray: "2 2 2 2" }),
                                React.createElement(ReferenceLine
                                // key={index}
                                , { 
                                    // key={index}
                                    stroke: "rgba(255,255,255,0.2)", 
                                    // stroke="gray"
                                    strokeWidth: 3, y: (_g = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.colorCodeList[0]) === null || _g === void 0 ? void 0 : _g.startPoint }),
                                React.createElement(Tooltip, { content: React.createElement(CustomTooltip, null), cursor: true })))),
                    React.createElement("div", { className: " historicalChartScale", style: { width: "40px" } },
                        React.createElement(HistoricalChartScale, { data: chartVariables, itemData: trackedItemInfo, isPopup: true, isMainChart: true, showScale: true, percentageValuMinMax: chartPadding, weight: trackedItemInfo === null || trackedItemInfo === void 0 ? void 0 : trackedItemInfo.weight, weightFlag: trackedItemInfo === null || trackedItemInfo === void 0 ? void 0 : trackedItemInfo.weightFlag })))))),
        React.createElement("div", { className: "row chartValues d-flex justify-content-between", hidden: isEmpty(chartVariables) },
            React.createElement("div", { className: "label-section" },
                React.createElement("span", { className: "chartDescription" }, "Projection"),
                React.createElement("div", { className: "arrow-pointer", style: {
                        background: (_h = chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.predictedBandColor) !== null && _h !== void 0 ? _h : "#01a443",
                    } }, !isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.pointTillPrediction)
                    ? `${chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.pointTillPrediction} ${!isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.predictionEndPoint)
                        ? `(${chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.predictionEndPoint})`
                        : ``}`
                    : `No Prediction`)),
            (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.strength) && isProjectionLine ? (React.createElement("div", { className: "label-section" },
                React.createElement("span", { className: "chartDescription" }, "Strength"),
                React.createElement("div", { className: "arrow-pointer arrow-pointer-strength bold-text" }, parseInt(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.strength) + "%"))) : null)));
}
export default MovingAverageAndProjectionChart;
