import { GET_UNREAD_NOTIFICATION_COUNT_SUCCESS, UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS, } from "./types";
export const unreadNotificationsCountReducer = (state = 0, action) => {
    switch (action.type) {
        case GET_UNREAD_NOTIFICATION_COUNT_SUCCESS: {
            return action.payload;
        }
        case UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};
