export function removeWhiteSpace(string) {
    const finalstr = string.replace(/ /g, "");
    return finalstr;
}
export function insertInsideString(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
}
export function isBoldTitle(activityHead) {
    const checkFrom = [7, 10, 11];
    if (checkFrom.indexOf(activityHead) > -1) {
        return true;
    }
    return false;
}
export function capitalizeFirstLetter(value) {
    return value.slice(0, 1).toUpperCase() + value.slice(1);
}
