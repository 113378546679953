import { useEffect, useState, useRef } from "react";
import React from "react";
import SingleSelectNew from "./SingleSelectNew";
import { LookupApi } from "../../../core/api/lookupApi";
import { LookupNames } from "../../../core/constants/lookup";
const CategoryDropdownContainer = ({ onChange, selectedValue, defaultValue, className, siteId, companyId, disabled, allowClear = false, labelInValue, haveDefaultSiteHandler = false, placeHolder, error = null, }) => {
    const isAllowClear = useRef(false);
    const [userCategoryPermissions, setUserCategoryPermissions] = useState();
    useEffect(() => {
        if (!userCategoryPermissions && siteId) {
            LookupApi.getLookupByPermissions(LookupNames.LKItemCategory).then((response) => {
                if (response === null || response === void 0 ? void 0 : response.length) {
                    setUserCategoryPermissions(response);
                }
            });
        }
        else {
            isAllowClear.current = true;
            setUserCategoryPermissions(undefined);
        }
    }, [siteId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SingleSelectNew, { id: "category-filter-dropdown", options: userCategoryPermissions, selectedValue: selectedValue, defaultValue: defaultValue, onChange: onChange, placeholder: placeHolder !== null && placeHolder !== void 0 ? placeHolder : "Select a Category", key: "categorySelect", valueProperty: "code", textProperty: "text", className: className, disabled: disabled, labelInValue: labelInValue, allowClear: allowClear, error: error })));
};
export default CategoryDropdownContainer;
