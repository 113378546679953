import React, { useEffect, useImperativeHandle } from "react";
import { Button, Checkbox, Input, Collapse } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import localize from "../../../../../core/utils/translation-files";
import { isUnique } from "../../../../../core/validations/uniqueFieldValidations";
import { inputDefaultLength } from "../../../../../core/utils/default-length";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import { ROLE_CREATION, ROLE_EDIT, } from "../../../../../core/constants/permissionConstants";
import PromptComponent from "../../../../../layout/components/prompt-message";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "./roleForm.scss";
import Asterik from "../../../shared/helper-components/Asterik";
const { Panel } = Collapse;
const defaultSubmitText = "Save";
const RoleForm = ({ submitText = defaultSubmitText, onSubmit, onCancel, permissions, role, isPrintMode, roles, lookupPermissionCategories, valuesRef, }) => {
    var _a;
    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched, dirty, resetForm, setValues, } = useFormik({
        validationSchema: Yup.object({
            name: Yup.string()
                .test("check", localize(["Role.role", "name", "alreadyExist!"]), function (value) {
                return isUnique(value, roles, role);
            })
                .trim()
                .matches(/^[a-zA-Z0-9 ]*$/, localize(["special", "characters", "notAllowed!"]))
                .required(localize(["Role.role", "name", "isRequired!"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            permissions: Yup.string().required(localize(["Role.permissions", "areRequired!"])),
            level: Yup.number()
                .required(localize(["Role.level", "isRequired!"]))
                .min(0),
        }),
        initialValues: role,
        onSubmit: (values) => {
            onSubmit(values, resetForm);
        },
    });
    useEffect(() => { }, [role]);
    const { handlePrint, nodeRef } = UsePrintPage(true);
    const handlSetFieldValue = (event) => {
        const { name, value } = event.target;
        setValues((prev) => (Object.assign(Object.assign({}, prev), { [name]: isEmpty(value) ? undefined : value })));
    };
    useImperativeHandle(valuesRef, () => {
        return {
            isPrompt: dirty,
        };
    }, [dirty]);
    const handleCancel = () => {
        onCancel(resetForm);
    };
    const submitButtonHandler = (event) => {
        event.preventDefault();
        handleSubmit();
    };
    const isUserHaveOnlyViewPermission = !isActionAllowed([
        ROLE_CREATION,
        ROLE_EDIT,
    ]);
    const filteredPermissions = permissions === null || permissions === void 0 ? void 0 : permissions.reduce((prev, curr) => {
        var _a, _b;
        let perList = (curr === null || curr === void 0 ? void 0 : curr.administrativeType) === true
            ? Object.assign(Object.assign({}, prev), { admin: (_a = prev === null || prev === void 0 ? void 0 : prev.admin) === null || _a === void 0 ? void 0 : _a.concat(curr) }) : Object.assign(Object.assign({}, prev), { nonAdmin: (_b = prev === null || prev === void 0 ? void 0 : prev.nonAdmin) === null || _b === void 0 ? void 0 : _b.concat(curr) });
        return perList;
    }, { admin: [], nonAdmin: [] });
    const handleChangePermissionCheckBox = (event) => {
        const { checked, id } = event.target;
        let updatePermissions = [...values.permissions];
        updatePermissions = checked
            ? [...updatePermissions, parseInt(id)]
            : updatePermissions.filter((permissionId) => permissionId !== parseInt(id));
        setFieldValue("permissions", updatePermissions.sort());
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: "mb-1 customForm col-12", "data-test": "addRoleFormComponent", name: "basic", ref: nodeRef, onSubmit: handleSubmit },
            React.createElement("h3", { className: "d-print page-title", hidden: isUserHaveOnlyViewPermission }, localize([(role === null || role === void 0 ? void 0 : role.id) ? "edit" : "create", "Role.role"])),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-4" },
                    React.createElement("div", { className: "form-group" },
                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                            React.createElement("label", null,
                                localize(["Role.role", "name"]),
                                " ",
                                React.createElement(Asterik, null))),
                        React.createElement(Input, { type: "text", name: "name", placeholder: localize(["Role.role", "name"]), onBlur: handleBlur, disabled: submitText === localize(["edit", "Role.permissions"]), onChange: handleChange, defaultValue: values.name, className: `${errors.name && touched.name ? "input-error" : null}` }),
                        errors.name && touched.name && (React.createElement("p", { className: "error" }, errors.name)))),
                React.createElement("div", { className: "col-4" },
                    React.createElement("div", { className: "form-group" },
                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                            React.createElement("label", null,
                                localize(["Role.level"]),
                                " ",
                                React.createElement(Asterik, null),
                                " ")),
                        React.createElement(Input, { type: "number", min: "0", max: "50", name: "level", placeholder: localize(["Role.level"]), onBlur: handleBlur, disabled: submitText === localize(["edit", "Role.permissions"]), onChange: handlSetFieldValue, defaultValue: values.level, className: `${errors.level && touched.level ? "input-error" : null}` }),
                        errors.level && touched.level && (React.createElement("p", { className: "error" }, errors.level))))),
            React.createElement("div", { className: isPrintMode ? "printMode form-group" : "form-group", key: isPrintMode },
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        localize(["Role.permissions"]),
                        " ",
                        React.createElement(Asterik, null))),
                React.createElement("div", { className: "collpase-menu-parent" }, (_a = Object.keys(filteredPermissions)) === null || _a === void 0 ? void 0 : _a.map((category, ind) => (React.createElement(Collapse, { defaultActiveKey: isPrintMode ? ["1"] : [], expandIcon: ({ isActive }) => isActive ? React.createElement(UpOutlined, null) : React.createElement(DownOutlined, null), className: "site-collapse-custom-collapse", expandIconPosition: "right" },
                    React.createElement(Panel, { header: localize([
                            ind === 0
                                ? "Role.administrativePermissions"
                                : "Role.nonAdministrativePermissions",
                        ]), key: isPrintMode ? "1" : ind, className: "site-collapse-custom-panel" },
                        React.createElement("div", { className: "row flex-wrap" }, lookupPermissionCategories === null || lookupPermissionCategories === void 0 ? void 0 : lookupPermissionCategories.map((permissionCategory, index) => {
                            var _a;
                            const permissionsList = (_a = filteredPermissions[category]) === null || _a === void 0 ? void 0 : _a.filter(({ categoryId }) => permissionCategory.code === (categoryId === null || categoryId === void 0 ? void 0 : categoryId.toString()));
                            return (permissionsList === null || permissionsList === void 0 ? void 0 : permissionsList.length) ? (React.createElement("div", { className: "col-lg-3 col-md-3 col-sm-4" },
                                React.createElement("h6", null, permissionCategory === null || permissionCategory === void 0 ? void 0 : permissionCategory.text),
                                React.createElement("div", { className: "row flex-column" }, permissionsList.map((permission, permissionIndex) => (React.createElement("div", { className: "col-6" },
                                    React.createElement(Checkbox, { key: permissionIndex, onChange: handleChangePermissionCheckBox, id: permission === null || permission === void 0 ? void 0 : permission.id.toString(), checked: values.permissions.some((permissionId) => permissionId === +(permission === null || permission === void 0 ? void 0 : permission.id)), disabled: isUserHaveOnlyViewPermission }, permission === null || permission === void 0 ? void 0 : permission.name))))))) : (React.createElement(React.Fragment, null));
                        }))))))),
                errors.permissions && (React.createElement("p", { className: "error" }, errors.permissions)))),
        React.createElement("div", { className: "d-flex justify-content-between settings-actions col-12 popup-button-list" },
            React.createElement(Button, { type: "default", onClick: handlePrint },
                React.createElement("span", { className: "icofont icofont-printer mr-2", style: { fontSize: "1.3em" } }),
                localize(["print"])),
            React.createElement(Button, { type: "default", onClick: handleCancel, className: "ml-auto mr-2" },
                React.createElement("span", { className: "icofont icofont-close-circled mr-2", style: { fontSize: "1.2em" } }),
                localize(["Button.cancel"])),
            React.createElement(Button, { type: "primary", htmlType: "submit", onClick: submitButtonHandler, hidden: !dirty || isUserHaveOnlyViewPermission },
                React.createElement("span", { className: "icofont icofont-save mr-2", style: { fontSize: "1em" } }),
                submitText))));
};
export default RoleForm;
