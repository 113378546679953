import { Badge } from "antd";
import Table from "antd/lib/table";
import React from "react";
import { TaskType } from "../../../../../../core/enums/TaskType";
import { isOverDue } from "../../../../../../core/utils/taskColorsUtils";
import localize from "../../../../../../core/utils/translation-files";
export const TaskListingTable = ({ data, actions, type }) => {
    const columns = [
        {
            key: "name",
            dataIndex: "name",
            title: localize(["name"]),
            render: (name, row) => (React.createElement("span", null,
                name + " ",
                isOverDue(row.dueDate) === true && type === TaskType.Due && (React.createElement(Badge, { className: "site-badge-count-109", count: "overdue", style: { backgroundColor: "red" } })))),
            sorter: (a, b) => (a.name > b.name ? 1 : -1),
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: localize(["Task.createdDate"]),
            sorter: (a, b) => (a.createTime > b.createTime ? 1 : -1),
            render: (createTime) => {
                return createTime === null || undefined
                    ? ""
                    : new Date(createTime).toLocaleDateString();
            },
        },
        {
            key: "dueDate",
            dataIndex: "dueDate",
            title: localize(["Task.dueDate"]),
            sorter: (a, b) => (a.dueDate > b.dueDate ? 1 : -1),
            render: (dueDateTime) => {
                return dueDateTime === null || undefined
                    ? ""
                    : new Date(dueDateTime).toLocaleDateString();
            },
        },
        {
            key: "endTime",
            dataIndex: "endTime",
            title: localize(["Task.completedDate"]),
            sorter: (a, b) => (a.endTime > b.endTime ? 1 : -1),
            render: (endTime) => {
                return endTime === null || undefined
                    ? ""
                    : new Date(endTime).toLocaleDateString();
            },
        },
    ];
    const actionColumn = {
        key: "actions",
        title: localize(["actions"]),
        render: actions,
    };
    const displayedColumns = actions
        ? [
            ...(type === TaskType.Completed
                ? actions
                : columns.filter((x) => x.key !== "endTime")),
            actionColumn,
        ]
        : columns;
    return (React.createElement("div", null,
        React.createElement(Table, { rowKey: (record) => record.id, dataSource: data, columns: displayedColumns, pagination: false, "data-test": "task-listing-table" })));
};
