import { CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { defaultFullDateTimeFormatForNotification } from "../../../../../core/constants/DateTime";
import localize from "../../../../../core/utils/translation-files";
import CustomModal from "../../../../../layout/components/customModal";
import "../style.scss";
const NotificationModel = ({ visible, onClose, selectedNotification, flagNotification, unflagNotification, }) => {
    var _a, _b;
    (_a = document.getElementById("flagLink")) === null || _a === void 0 ? void 0 : _a.setAttribute("target", "_self");
    return (React.createElement("div", { "data-test": "notification-model" },
        React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: [""], subTitle: selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.subject, 
            // width="min-content"
            modelClass: "inAppPopup" },
            React.createElement("div", { className: "col-12", style: { display: "flex", justifyContent: "space-between" } },
                React.createElement("span", { style: { fontWeight: "bold" } }, moment
                    .utc((_b = selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.createdDate) === null || _b === void 0 ? void 0 : _b.toString())
                    .format(defaultFullDateTimeFormatForNotification)),
                React.createElement("div", { className: "model-flag" },
                    React.createElement(Tooltip, { placement: "top", title: localize([
                            `${(selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.flagged)
                                ? "Notification.unflag"
                                : "Notification.flag"}`,
                            "Notification.thisNotification",
                        ]) },
                        React.createElement(CheckOutlined, { className: "flag-icon", onClick: (event) => {
                                event.stopPropagation();
                                (selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.flagged) === true
                                    ? unflagNotification(selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.id)
                                    : flagNotification(selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.id);
                            }, style: (selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.flagged)
                                ? { color: "#17A99A" }
                                : { color: "#6e6b7b" } })))),
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "notificationTemplate", dangerouslySetInnerHTML: { __html: selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.body } })))));
};
export default NotificationModel;
