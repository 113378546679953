import { Button, Progress, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { browserHistoryMemoryContext } from "../../../core/contexts/browserHistoryMemoryContext";
import "./pdfContentStyle.scss";
import { useHistory, useLocation } from "react-router-dom";
import CustomModal from "../customModal/index";
const DownloadDataLoader = ({ percent = 0, callBack = null, loadingData = false, screen, }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const location = useLocation();
    const history = useHistory();
    const cancelLoadingData = () => {
        setIsModalOpen(false);
        callBack && callBack();
        if (screen === "overview") {
            browserHistory.push(location.pathname);
        }
        else if (screen === "watchlist") {
            history.push(location.pathname);
        }
    };
    useEffect(() => {
        if (loadingData === true) {
            setIsModalOpen(true);
        }
        if (loadingData === false && percent === 100) {
            setTimeout(() => {
                setIsModalOpen(false);
                callBack();
            }, 1000);
        }
    }, [loadingData, isModalOpen, percent]);
    return (React.createElement(CustomModal, { visible: isModalOpen, onCancel: cancelLoadingData, maskClosable: false, destroyOnClose: true, footer: null, title: ["Progress"], 
        //   width="fit-content"
        modelClass: "progressBarModal" },
        React.createElement("div", { className: "progressBarContainer" },
            React.createElement(Space, null,
                React.createElement(Progress, { showInfo: false, status: "active", type: "line", strokeColor: "#17a99a", percent: percent, key: percent }))),
        React.createElement(Button, { type: "primary", onClick: cancelLoadingData }, "Cancel")));
};
export default DownloadDataLoader;
