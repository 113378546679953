export var TaskType;
(function (TaskType) {
    TaskType[TaskType["Due"] = 0] = "Due";
    TaskType[TaskType["Completed"] = 1] = "Completed";
})(TaskType || (TaskType = {}));
export var CompleteTaskType;
(function (CompleteTaskType) {
    CompleteTaskType[CompleteTaskType["MONTHLY"] = 0] = "MONTHLY";
    CompleteTaskType[CompleteTaskType["ANNUAL"] = 1] = "ANNUAL";
})(CompleteTaskType || (CompleteTaskType = {}));
