import { Badge, Button, Menu, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { InAppNotificationApi } from "../../../../../../core/api/inAppNotificationApi";
import { notificationEventsRoute } from "../../../../../../core/constants/routesPath";
import { getMessagesDateAndTimeStringGlobal } from "../../../../../../core/helpers/dateFormatter";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../core/utils/translation-files";
import CustomModal from "../../../../../../layout/components/customModal";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import "./style.scss";
// import useState from 'react';
const NotificationColumnComponent = ({ value = null, record, browserHistory, count, onLoadDataTable, 
//  isExpandAll,
// isExpandedAll,
expandTrackItem,
// openNotificationModal = null,
 }) => {
    const handleViewAllRecentNotifications = (v) => {
        browserHistory.push(notificationEventsRoute, {
            isAllNotification: true,
            isAllNotificationId: record === null || record === void 0 ? void 0 : record.key,
            isRoadmapItem: false,
            selectedItemId: null,
        });
    };
    useEffect(() => {
        (record === null || record === void 0 ? void 0 : record.category) === "Subcategory" &&
            expandTrackItem.current === true &&
            (record === null || record === void 0 ? void 0 : record.children) === undefined &&
            onLoadDataTable(record);
    }, []);
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const filterOption = {
        timeFilter: null,
        inAppStatus: null,
        flagged: null,
        pageNo: 1,
        pageSize: 3,
        fromDate: null,
        toDate: null,
        category: null,
        sortByCreatedDate: "desc",
        subject: null,
        itemId: record === null || record === void 0 ? void 0 : record.key,
    };
    const openNotificationModal = (item) => {
        if (record === null || record === void 0 ? void 0 : record.key) {
            InAppNotificationApi.getNotificationList(filterOption).then((response) => {
                setData(response === null || response === void 0 ? void 0 : response.notificationsList);
                setVisible(true);
            });
        }
    };
    const onClose = (item) => {
        setVisible(false);
        setData([]);
    };
    return (React.createElement(React.Fragment, null,
        !isEmpty(count) ? (React.createElement("div", { className: "notification-column-container", style: {
                backgroundColor: "transparent",
                borderColor: "transparent",
            }, onClick: (e) => openNotificationModal(record) },
            React.createElement(Badge, { className: "action-badge", count: count, 
                // style={{ color: record?.bandColor }}
                style: {
                    color: "white",
                    background: record === null || record === void 0 ? void 0 : record.bandColor,
                } },
                React.createElement(RenderSvg, { icon: `InActiveNotificationBell`, style: {
                        color: record === null || record === void 0 ? void 0 : record.bandColor,
                        fill: record === null || record === void 0 ? void 0 : record.bandColor,
                        stroke: record === null || record === void 0 ? void 0 : record.bandColor,
                    } })))) : (React.createElement(Skeleton.Button, { active: true, size: "default" })),
        React.createElement("div", null,
            React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["Dashboard.notifications"], width: "50%" },
                React.createElement(Menu, { className: "action-menu related-notifications-list col-12" },
                    (data === null || data === void 0 ? void 0 : data.length) ? (data.map((item, index) => (React.createElement(Menu.Item, { className: "action-item d-flex w-100", key: index, style: {
                            height: "auto",
                            cursor: "text",
                        } },
                        React.createElement("span", { className: "text" },
                            React.createElement("span", { className: "sub-text" },
                                React.createElement("span", null, getMessagesDateAndTimeStringGlobal(item === null || item === void 0 ? void 0 : item.createdDate))),
                            React.createElement("span", { className: "message" }, item.subject)))))) : (React.createElement("span", { className: "empty-item" }, "No Notifications")),
                    (data === null || data === void 0 ? void 0 : data.length) ? (React.createElement("div", { className: "dropdown-actions" },
                        React.createElement(Button, { type: "primary", className: "w-100", onClick: handleViewAllRecentNotifications }, localize(["Notification.viewMoreNotifications"])))) : (""))))));
};
export default NotificationColumnComponent;
