import moment from "moment";
import { defaultDateFormat, defaultDateTimeFormat, } from "../constants/DateTime";
const generateRangeArray = (count) => {
    let numericArray = count ? [...Array(count)].map((data, index) => index) : [];
    return numericArray;
};
export const disabledDateTime = (currentDate) => {
    const isCurrentDate = moment(currentDate, defaultDateFormat).isSame(moment(), "day");
    const isCurrentHour = moment(currentDate, defaultDateTimeFormat).hour() === moment().hour();
    let disableHour = [];
    let disableMinutes = [];
    if (isCurrentDate) {
        disableHour = generateRangeArray(moment().hour());
        if (isCurrentHour) {
            disableMinutes = generateRangeArray(moment().minute());
        }
    }
    return {
        disabledHours: () => disableHour,
        disabledMinutes: () => disableMinutes,
    };
};
export const disabledDate = (current) => {
    // Can not select days before today and today
    return ((current && current > moment().endOf("day")) ||
        current < moment("01-01-1970", "DD-MM-YYYY"));
};
