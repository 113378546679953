import React from "react";
import { Select } from "antd";
import { saveContext } from "../../../core/helpers/saveContext";
const { Option } = Select;
const HistoryColumnDropdown = ({ selectedFrequency, setSelectedFrequency, frequencyList, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { defaultValue: {
                value: selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code,
                label: selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.text,
            }, value: {
                value: selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code,
                label: selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.text,
            }, labelInValue: true, placeholder: "History", style: { width: 105, marginLeft: "10px", marginRight: "10px" }, size: "small", onChange: (selectedOption) => {
                const selectedHistoryFrequency = {
                    code: selectedOption.value,
                    text: selectedOption.label,
                };
                setSelectedFrequency(selectedHistoryFrequency);
                saveContext(selectedHistoryFrequency);
            } }, frequencyList.map(({ text, code }, index) => (React.createElement(Option, { key: index, value: code }, text))))));
};
export default HistoryColumnDropdown;
