var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { httpGet, httpPost, httpPut } from "./request/requestApi";
const endPointPath = "auth";
export class AccountApi {
    static signIn(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return httpPost(`${endPointPath}/authenticate`, { body: data });
        });
    }
    static logout() {
        return __awaiter(this, void 0, void 0, function* () {
            return httpPost(`${endPointPath}/logout`);
        });
    }
    static loadContext() {
        return httpGet(`${endPointPath}/userDetails`);
    }
    static forgetPassword(username) {
        return httpPost(`${endPointPath}/forgetPassword/${username}`);
    }
    static changePassword(data) {
        return httpPost(`${endPointPath}/changePassword`, { body: data });
    }
    static storeContext(data) {
        return httpPut(`${endPointPath}/saveContext`, { body: data });
    }
    static refreshToken() {
        return httpGet(`${endPointPath}/refreshToken`, {
            requestHeader: true,
        });
    }
}
