import { httpDelete, httpGet, httpPost, httpPut } from "./request/requestApi";
const adminControllerEndpoint = "admin";
const companyControllerEndpoint = "company";
const controllerEndpoint = "user";
const externalUserEndpoint = "externalUser";
export class UserApi {
    static getUsers(companyId) {
        return httpGet(`${adminControllerEndpoint}/${companyControllerEndpoint}/${companyId}/${controllerEndpoint}s`);
    }
    static getActiveUsers(companyId) {
        return httpGet(`${adminControllerEndpoint}/${companyControllerEndpoint}/${companyId}/${controllerEndpoint}s/active`);
    }
    static createUser(data) {
        return httpPost(`${adminControllerEndpoint}/${controllerEndpoint}`, {
            body: data,
        });
    }
    static updateUser(data) {
        return httpPut(`${adminControllerEndpoint}/${controllerEndpoint}`, {
            body: data,
        });
    }
    static deleteUser(id) {
        return httpDelete(`${controllerEndpoint}/${id}`);
    }
    static inActivateUser(id) {
        return httpPost(`${adminControllerEndpoint}/${controllerEndpoint}/inactive/${id}`);
    }
    static activateUser(id) {
        return httpPost(`${adminControllerEndpoint}/${controllerEndpoint}/active/${id}`);
    }
    static lockUser(id) {
        return httpPost(`${adminControllerEndpoint}/${controllerEndpoint}/lock/${id}`);
    }
    static unlockUser(id) {
        return httpPost(`${adminControllerEndpoint}/${controllerEndpoint}/unlock/${id}`);
    }
    // Register External User
    static registerExternalUser(data) {
        return httpPost(`${adminControllerEndpoint}/${externalUserEndpoint}/register`, {
            body: data,
        });
    }
    // Validate the URL
    static validateExternalUserURL(identifier) {
        return httpPost(`${adminControllerEndpoint}/${externalUserEndpoint}/validateRegistrationLink/${identifier}`);
    }
    static getUserHistoricalRoles(companyId, userId) {
        return httpGet(`${adminControllerEndpoint}/historicalRoles/${userId}/${companyId}`);
    }
    static getUser(companyId, userId) {
        return httpGet(`${adminControllerEndpoint}/user/${userId}/${companyId}`);
    }
}
