import React from "react";
import BaseErrorPage from "./BaseErrorPage";
import { Button } from "antd";
import { HomeFilled } from "@ant-design/icons";
import { useHistory } from "react-router";
import { UserDashboardRoute } from "../../../core/utils/userDashboardRoute";
import { useDispatch, useSelector } from "react-redux";
import localize from "../../../core/utils/translation-files";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../core/constants/SessionVariables";
import { loginRoute } from "../../../core/constants/routesPath";
import isEmpty from "../../../core/helpers/isEmpty";
import { logoutActions } from "../../../redux/context/actions";
import useDidMount from "../../../core/hooks/useDidMount";
export default function NotFound() {
    const history = useHistory();
    const dispatch = useDispatch();
    const userContext = SessionStorage.getKey(SessionVariables.USER_CONTEXT);
    const context = useSelector((state) => {
        return state.context;
    });
    useDidMount(() => {
        //session storage is clear by other tab action and redux state exist for current tab
        if ((context === null || context === void 0 ? void 0 : context.isAuthenticated) && isEmpty(userContext)) {
            dispatch(logoutActions(history));
        }
    });
    function NavigateHome() {
        if (!isEmpty(userContext)) {
            history.push(UserDashboardRoute.getRouteByPermissions(userContext));
            return;
        }
        history.replace(loginRoute);
    }
    return (React.createElement(BaseErrorPage, { subTitle: React.createElement("h6", { className: "text-md text-center" }, localize(["404Msg"])), bg: `${window.origin}/content/404-page.jpg`, action: React.createElement(Button, { type: "primary", onClick: NavigateHome, style: { width: "auto" }, icon: React.createElement(HomeFilled, { className: "ml-0 mr-2", style: { fontSize: "1em" } }) }, localize(["Button.backToHome"])), title: React.createElement("h1", { style: { fontSize: "6rem" }, className: "text-color-300 text-center mb-2" },
            React.createElement("i", { className: "icofont-radio-active color-error mr-2", style: { opacity: 0.5 } }),
            "404") }));
}
