var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { taskRoute } from "../../../../../core/constants/routesPath";
import { connect } from "react-redux";
import { getLookup } from "../../../../../redux/lookup/actions";
import { LookupNames } from "../../../../../core/constants/lookup";
import { FinancialApi } from "../../../../../core/api/financialApi";
import { CompleteTaskType } from "../../../../../core/enums/TaskType";
import { notifications } from "../../../../../layout/components/notifications";
import { ENTER_A_VALUE, SELECT_AN_OPTION, } from "../../../../../core/constants/TaskTableConstants";
import { onTaskSubmit } from "../../../../../redux/task/actions";
import CompleteTask from "./components/CompleteTask";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import localize from "../../../../../core/utils/translation-files";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import { getLookups } from "../../../../../redux/lookup/actions";
let counter1 = 0;
let counter2 = 0;
function CompleteTaskContainer({ getLookup, financialActivityHead, financialActivity, context, onTaskSubmit, getLookups, }) {
    const history = useHistory();
    const [isValid, setIsValid] = useState(true);
    const [taskData] = useState(null);
    const [month, setMonth] = useState("");
    const [deletedRows, setDeletedRows] = useState([]);
    const [loadedSavedId, setLoadedSavedId] = useState(null);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [count, setCount] = useState(1);
    const [dataSource, setDataSource] = useState([
        {
            id: "0",
            financialActivityHeadId: SELECT_AN_OPTION,
            financialActivityId: SELECT_AN_OPTION,
            value: ENTER_A_VALUE,
            tempRecord: true,
        },
    ]);
    const handleDelete = (key) => {
        const dataaSource = [...dataSource];
        setDataSource(dataaSource.filter((item) => item.id !== key));
        if (!dataaSource.filter((item) => item.id === key)[0].tempRecord) {
            setDeletedRows([...deletedRows, key]);
        }
        setIsDataChanged(true);
    };
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, Object.assign(Object.assign({}, item), row));
        if (!row.tempRecord) {
            setIsDataChanged(true);
        }
        let addNewRow = true;
        newData.map((item) => {
            if (!(item.financialActivityHeadId !== "" &&
                item.financialActivityHeadId !== SELECT_AN_OPTION &&
                item.financialActivityId !== "" &&
                item.financialActivityId !== SELECT_AN_OPTION &&
                item.value !== "" &&
                item.value !== "Enter a value")) {
                addNewRow = false;
            }
        });
        if (addNewRow) {
            setIsDataChanged(true);
            const newRow = {
                id: count + "",
                financialActivityHeadId: SELECT_AN_OPTION,
                financialActivityId: SELECT_AN_OPTION,
                value: ENTER_A_VALUE,
                tempRecord: true,
            };
            setDataSource([...newData, newRow]);
            setCount(count + 1);
        }
        else {
            setDataSource(newData);
        }
    };
    const loadFinancialData = () => {
        if (context.selectedFinancialTask.taskDataMap.hasOwnProperty("taskMonth")) {
            FinancialApi.getFinancialTrackedItemData(context.selectedCompany.id, context.selectedFinancialTask.taskDataMap.taskYear, ("0" + context.selectedFinancialTask.taskDataMap.taskMonth).slice(-2), CompleteTaskType[context.selectedFinancialTask.taskDataMap.taskType]).then((res) => {
                loadFinancialIntoTable(res);
            });
        }
        else {
            FinancialApi.getFinancialTrackedItemDataById(context.selectedFinancialTask.id).then((res) => {
                loadFinancialIntoTable(res);
            });
            setMonth("0" + context.selectedCompany.siteDetail.financialMonth);
        }
    };
    const loadFinancialIntoTable = (res) => {
        if (res.financialDataDetail.length > 0) {
            const tempArray = [];
            res.financialDataDetail.map((item) => {
                item.financialActivityHeadId = item.financialActivityHeadText;
                item.financialActivityId = item.financialActivityText;
                item.tempRecord = false;
                delete item.financialActivityHeadText;
                delete item.financialActivityText;
                tempArray.push(item);
            });
            setMonth(res.yearMonth.toString().slice(4, res.yearMonth.length));
            setLoadedSavedId(res.id);
            setDataSource([
                ...tempArray,
                {
                    id: "0",
                    financialActivityHeadId: SELECT_AN_OPTION,
                    financialActivityId: SELECT_AN_OPTION,
                    value: ENTER_A_VALUE,
                    tempRecord: true,
                },
            ]);
        }
    };
    const loadInitialData = () => {
        if (counter2 === 0) {
            getLookup(LookupNames.LKFinancialActivity).catch((error) => {
                throw error;
            });
            getLookup(LookupNames.LKFinancialActivityHead).catch((error) => {
                throw error;
            });
        }
        ++counter2;
    };
    useEffect(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKProfession],
            getLookups,
        });
        if (financialActivity.length === 0 &&
            financialActivityHead.length === 0 &&
            taskData === null) {
            counter1 = 0;
            counter2 = 0;
            loadInitialData();
        }
        else {
            loadFinancialData();
        }
    }, []);
    const handleCancel = () => {
        history.push(taskRoute);
    };
    const handleSaveTask = (saveOrSubmit) => __awaiter(this, void 0, void 0, function* () {
        const taskDetail = JSON.parse(JSON.stringify([...dataSource]));
        // Validation
        let isTableValid = true;
        taskDetail.map((item) => {
            if (item.financialActivityHeadId === "" ||
                item.financialActivityHeadId === SELECT_AN_OPTION ||
                item.financialActivityId === "" ||
                item.financialActivityId === SELECT_AN_OPTION ||
                item.value === "" ||
                item.value === "Enter a value") {
                isTableValid = false;
                return false;
            }
        });
        if (!isTableValid || taskDetail.length === 0) {
            setIsValid(false);
            return false;
        }
        else {
            setIsValid(true);
        }
        taskDetail.map((item) => {
            //
            item.financialActivityHeadId = parseInt(financialActivityHead.filter((head) => head.text === item.financialActivityHeadId)[0].code);
            item.financialActivityId = parseInt(financialActivity.filter((activity) => activity.text === item.financialActivityId)[0].code);
            item.value = parseFloat(item.value);
            if (item.tempRecord) {
                delete item.id;
            }
            delete item.tempRecord;
        });
        let selectedMonth = "";
        if (context.selectedFinancialTask.taskDataMap.firstFinancialYear === "true") {
            selectedMonth = month;
        }
        else {
            selectedMonth = context.selectedFinancialTask.taskDataMap.taskMonth;
        }
        const payload = {
            id: loadedSavedId ? loadedSavedId : null,
            yearMonth: parseInt(context.selectedFinancialTask.taskDataMap.taskYear +
                "" +
                ("0" + selectedMonth).slice(-2)),
            financialType: CompleteTaskType[context.selectedFinancialTask.taskDataMap.taskType],
            siteId: context.selectedCompany.id,
            taskId: context.selectedFinancialTask.id,
            firstFinancialYear: context.selectedFinancialTask.taskDataMap.firstFinancialYear === "true",
            financialDataDetail: taskDetail,
            deletedFinancialDataDetailID: deletedRows,
        };
        if (saveOrSubmit === "Save") {
            yield FinancialApi.saveFinancialData(payload)
                .then((response) => {
                if (response &&
                    response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                    return;
                }
                else {
                    notifications.success(`${localize(["Task.financialData", "added", "successfully"])}`);
                    history.push(taskRoute);
                }
            })
                .catch(() => { });
        }
        else {
            yield FinancialApi.submitFinancialData(payload)
                .then((response) => {
                if (response &&
                    response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                    return;
                }
                else {
                    notifications.success(`${localize([
                        "Task.financialData",
                        "savedAndSubmited",
                        "successfully",
                    ])}`);
                    onTaskSubmit();
                    history.push(taskRoute);
                }
            })
                .catch(() => { });
        }
    });
    return (React.createElement(CompleteTask, { context: context, handleCancel: handleCancel, month: month, setMonth: setMonth, dataSource: dataSource, isValid: isValid, isDataChanged: isDataChanged, handleSaveTask: handleSaveTask, handleSave: handleSave, handleDelete: handleDelete, financialActivity: financialActivity, financialActivityHead: financialActivityHead }));
}
function mapStateTopProps(state, ownProps) {
    var _a, _b;
    return {
        financialActivityHead: (_a = state.lookups[LookupNames.LKFinancialActivityHead]) !== null && _a !== void 0 ? _a : [],
        financialActivity: (_b = state.lookups[LookupNames.LKFinancialActivity]) !== null && _b !== void 0 ? _b : [],
        context: state.context,
    };
}
const mapDispatchToProps = {
    getLookup,
    onTaskSubmit,
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(CompleteTaskContainer);
