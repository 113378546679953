import { GET_SITES_SUCCESS, ADD_SITE_SUCCESS, UPDATE_SITE_SUCCESS, IN_ACTIVATE_SITE_SUCCESS, ACTIVATE_SITE_SUCCESS, } from "./types";
import { initialState as initialStoreState } from "../initialState";
const initialState = initialStoreState.sites;
export const siteReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SITES_SUCCESS: {
            return [...action.payload];
        }
        case UPDATE_SITE_SUCCESS: {
            const selectedCompanySites = state.filter((site) => site.parentId === action.payload.parentId);
            if (selectedCompanySites.length === 0 && state.length > 0) {
                const editedSite = state.filter((site) => site.id !== action.payload.id);
                return [...editedSite];
            }
            const editedSite = state.map((el) => el.id !== action.payload.id ? el : action.payload);
            return [...editedSite];
        }
        case ADD_SITE_SUCCESS: {
            const selectedCompanySites = state.filter((site) => site.parentId === action.payload.parentId);
            if (selectedCompanySites.length === 0 && state.length > 0) {
                return [...state];
            }
            return [...state, Object.assign({}, action.payload)];
        }
        case ACTIVATE_SITE_SUCCESS: {
            const tempSites = [...state].map((site) => (Object.assign(Object.assign({}, site), { active: site.id === action.payload ? true : site.active })));
            return [...tempSites];
        }
        case IN_ACTIVATE_SITE_SUCCESS: {
            const tempSites = [...state].map((site) => (Object.assign(Object.assign({}, site), { active: site.id === action.payload ? false : site.active })));
            return [...tempSites];
        }
        default: {
            return state;
        }
    }
};
