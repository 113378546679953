import isEmpty from "../helpers/isEmpty";
export function isUnique(value, listing, edited = undefined, valueProperty = "name") {
    if (isEmpty(value) || isEmpty(listing) || !listing.length) {
        return true;
    }
    let updatedListing = !isEmpty(edited) && !isEmpty(edited[valueProperty])
        ? listing === null || listing === void 0 ? void 0 : listing.filter((item) => item.id !== edited.id)
        : listing;
    if (updatedListing === null || updatedListing === void 0 ? void 0 : updatedListing.some((item) => item[valueProperty].toLocaleLowerCase() === value.toLocaleLowerCase())) {
        return false;
    }
    return true;
}
