export class LookupNames {
}
LookupNames.LKProfession = "LKProfession";
LookupNames.LKNucleusModule = "LKNucleusModule";
LookupNames.LKReportParentGroup = "LKReportParentGroup";
LookupNames.LKReportItemSubCategory = "LKReportItemSubCategory";
LookupNames.LKReportType = "LKReportType";
LookupNames.LKTitle = "LKTitle";
LookupNames.LKRegisteredActivities = "LKRegisteredActivities";
LookupNames.LKReportStatusColor = "LKReportStatusColor";
LookupNames.LKMonth = "LKMonth";
LookupNames.LKFinancialSystem = "LKFinancialSystem";
LookupNames.LKFinancialActivityHead = "LKFinancialActivityHead";
LookupNames.LKFinancialActivity = "LKFinancialActivity";
LookupNames.LKReportTypeBenchmark = "LKReportTypeBenchmark";
LookupNames.LKReportTypeProjectionAverage = "LKReportTypeProjectionAverage";
LookupNames.LKReportTypeBenchmarkDirection = "LKReportTypeBenchmarkDirection";
LookupNames.LKReportTypeDataFrequency = "LKReportTypeDataFrequency";
LookupNames.LKReportValueType = "LKReportValueType";
LookupNames.LKItemCategory = "LKItemCategory";
LookupNames.LKReportHistoryFrequency = "LKReportHistoryFrequency";
LookupNames.LKItemRiskLevel = "LKItemRiskLevel";
LookupNames.LKChartDisplayUnit = "LKChartDisplayUnit";
LookupNames.LKCompanyRegistrationType = "LKCompanyRegistrationType";
LookupNames.LKPermissionCategory = "LKPermissionCategory";
LookupNames.LKUserBand = "LKUserBand";
LookupNames.LKChartDataFilter = "LKChartDataFilter";
LookupNames.LKReportDataFilters = "LKReportDataFilters";
LookupNames.LKNotificationTimeFilter = "LKNotificationTimeFilter";
LookupNames.LKNotificationReferenceType = "LKNotificationReferenceType";
LookupNames.LKUserReportType = "LKUserReportType";
LookupNames.LKUserReportName = "LKUserReportName";
LookupNames.LKWatchlistFilter = "LKWatchlistFilter";
LookupNames.LKNotificationCategory = "LKNotificationCategory";
LookupNames.LKCollectiveNotificationSort = "LKCollectiveNotificationSort";
LookupNames.LKCollectiveNotificationFrequency = "LKCollectiveNotificationFrequency";
LookupNames.LKReportWeight = "LKReportWeight";
LookupNames.LKDisplayValue = "LKDisplayValue";
export const LookupTypes = "LookupTypes";
export const OverviewParentGroupId = 3;
export const ATOM = "ATOM";
