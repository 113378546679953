import { Button } from "antd";
import React, { useEffect } from "react";
import { ReportFilterOptions } from "../../../../../core/enums/report";
import { getFullDate } from "../../../../../core/helpers/dateFormatter";
import isEmpty from "../../../../../core/helpers/isEmpty";
import localize from "../../../../../core/utils/translation-files";
import CustomModal from "../../../../../layout/components/customModal";
const DataSheetsModal = ({ visible, onClose, data, formula, setFormula, exportDataSheet, selectedFilters: { reportId, reportName, startDate, endDate, reportFilterOption, }, fiscalYearRange = {}, isChart = false, }) => {
    let boldRows = [];
    const getBoldRows = (f) => {
        if (f) {
            if (!isEmpty(f.indexOf("C")) && Math.sign(f.indexOf("C")) !== -1) {
                boldRows = [
                    ...boldRows,
                    f === null || f === void 0 ? void 0 : f.substring((f === null || f === void 0 ? void 0 : f.indexOf("C")) + 1, (f === null || f === void 0 ? void 0 : f.indexOf("C")) + 3),
                ];
                getBoldRows(
                // f?.substring(f.indexOf(boldRows[boldRows.length - 1]), f?.length)
                f === null || f === void 0 ? void 0 : f.substring(f.indexOf("C") + boldRows[boldRows.length - 1].length, f === null || f === void 0 ? void 0 : f.length));
            }
        }
        return boldRows;
    };
    useEffect(() => {
        if (visible && data) {
            if (formula)
                getBoldRows(formula);
            var table_output = `<table class="financialsPopup">`;
            for (var row = 0; row < data.length; row++) {
                table_output += "<tr>";
                for (var cell = 0; cell < data[row].length; cell++) {
                    isEmpty(data[row][cell])
                        ? row < 7 && row !== 3 && cell > 1
                            ? (table_output += "<td style=display:none;></td>")
                            : (table_output += `<td class=${cell === 0 && "fixedCol"}></td>`)
                        : row === 12 || row === 9
                            ? (table_output += "<td style=display:none;></td>")
                            : (table_output += `<td class=${cell === 0 && "fixedCol"} colspan=${row < 7 &&
                                cell > 0 &&
                                cell < data[row].length &&
                                (data[row].length < 7 ? 6 : data[row].length - 2)} style="font-weight:${(row < 4 && row !== 1) ||
                                (row === 1 && cell < 1) ||
                                (row === 8 && cell > 0) ||
                                row === 7 ||
                                (row !== 9 &&
                                    Math.sign(boldRows.indexOf((row + 1).toString())) !== -1)
                                ? `bold`
                                : `normal`}; color:${row === 7
                                ? `#2596be`
                                : data[row][cell].toString().trim()[0] === "("
                                    ? `red`
                                    : `black`}; text-align:${row > 6 && cell > 0 ? `center` : `left`}; padding:${row === data.length - 1
                                ? cell > 0
                                    ? `0 10px 30px 10px`
                                    : `0 10px 30px 0`
                                : row === 11
                                    ? `10px 10px 20px 10px`
                                    : row === 14
                                        ? cell > 0
                                            ? `10px 10px 0 10px`
                                            : `10px 10px 0 0`
                                        : cell > 0 && `0 10px`}; min-width: ${cell === 0 ? `250px` : `120px`};font-size: ${row === 0 || (row < 3 && cell === 0)
                                ? `16px`
                                : row === 3
                                    ? `14px`
                                    : row === 4
                                        ? `11px`
                                        : `13px`};white-space: ${row === 3 && cell === 2 ? `break-spaces` : `inherit`};">${data[row][cell]} </td>`);
                }
                table_output += "</tr>";
                if (row === 12)
                    table_output += `<tr><td colspan=${data[row].length} style="padding:10px;border-bottom: 0.5px solid black;"></td></tr>`;
            }
            table_output += "</table>";
            document.getElementById("excel_data").innerHTML = table_output;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, data, formula]);
    return (React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, width: "97%", inlineStyle: { overflowY: "hidden", maxHeight: "95vh" } },
        React.createElement(Button, { type: "primary", onClick: () => exportDataSheet(isChart
                ? reportFilterOption
                : reportFilterOption === ReportFilterOptions.Default
                    ? 0
                    : 2, reportId, isChart
                ? [startDate, endDate]
                : startDate
                    ? [getFullDate(startDate), getFullDate(endDate)]
                    : reportFilterOption === ReportFilterOptions.Fiscal
                        ? [fiscalYearRange.startDate, fiscalYearRange.endDate]
                        : [], { name: reportName }) }, localize(["download"])),
        React.createElement("div", { id: "excel_data", className: "mt-5", style: {
                overflow: "auto",
                maxHeight: "80vh",
            } })));
};
export default DataSheetsModal;
