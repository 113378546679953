import React, { useEffect, useRef, useState } from "react";
import BenchmarksPerTrackedItemType from "../../../../../../../layout/components/select/BenchmarksPerTrackedItemType";
import localize from "../../../../../../../core/utils/translation-files";
import Asterik from "../../../../../shared/helper-components/Asterik";
import { BenchmarkDirectionType } from "../../../../../../../core/enums/benchmarkDirection";
import { TrackedItemTypebenchmark } from "../../../../../../../core/constants/TrackedItemsConstants";
import { requiredNumericColoredRanges, validateColoredNumericRanges, } from "../../../../../../../layout/components/numericColoredRanges/numericColoredRangesValidations";
import { NumericColoredRanges } from "../../../../../../../layout/components/numericColoredRanges/numericColoredRanges";
import { TrackedItemApi } from "../../../../../../../core/api/trackedItemApi";
const BenchmarkSelection = ({ errors, touched, setFieldValue, values, benchmarkDropdownOptions, onBenchmarkValueSelection, rangeErrors, setRangeErrors, }) => {
    const [bandScales, setBandScales] = useState(undefined);
    const updateRangeError = useRef(true);
    useEffect(() => {
        if (updateRangeError.current && (values === null || values === void 0 ? void 0 : values.id)) {
            rangeErrorHandler(values === null || values === void 0 ? void 0 : values.gradeRanges);
            updateRangeError.current = false;
        }
        if (bandScales === undefined) {
            TrackedItemApi.getTrackedItemScale().then((res) => {
                setBandScales(res);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateRangeError.current, values === null || values === void 0 ? void 0 : values.gradeRanges]);
    const rangeErrorHandler = (v) => {
        let error = (v === null || v === void 0 ? void 0 : v.length) < 4
            ? localize(["TrackedItem.Grades", "areRequired!"])
            : requiredNumericColoredRanges(v)
                ? localize(["TrackedItem.RatingCannotBeEmpty"])
                : validateColoredNumericRanges(v)
                    ? localize(["TrackedItem.invalidRange"])
                    : undefined;
        setRangeErrors(error);
    };
    const handleGradeRangesChange = (v) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48;
        const updatedBenchmark = (v === null || v === void 0 ? void 0 : v.length) > 4
            ? {
                minRating: (_d = (_c = (_b = (_a = v[0]) === null || _a === void 0 ? void 0 : _a.rule) === null || _b === void 0 ? void 0 : _b.from) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : undefined,
                maxRating: (_h = (_g = (_f = (_e = v[6]) === null || _e === void 0 ? void 0 : _e.rule) === null || _f === void 0 ? void 0 : _f.to) === null || _g === void 0 ? void 0 : _g.value) !== null && _h !== void 0 ? _h : undefined,
                lowerCriticalLevel: ((_j = v[0]) === null || _j === void 0 ? void 0 : _j.type) === "Red" ? (_m = (_l = (_k = v[0]) === null || _k === void 0 ? void 0 : _k.rule) === null || _l === void 0 ? void 0 : _l.to) === null || _m === void 0 ? void 0 : _m.value : undefined,
                upperCriticalLevel: ((_o = v[6]) === null || _o === void 0 ? void 0 : _o.type) === "Red" ? (_r = (_q = (_p = v[6]) === null || _p === void 0 ? void 0 : _p.rule) === null || _q === void 0 ? void 0 : _q.from) === null || _r === void 0 ? void 0 : _r.value : undefined,
                lowerConcernLevel: ((_s = v[1]) === null || _s === void 0 ? void 0 : _s.type) === "Amber" ? (_v = (_u = (_t = v[1]) === null || _t === void 0 ? void 0 : _t.rule) === null || _u === void 0 ? void 0 : _u.to) === null || _v === void 0 ? void 0 : _v.value : undefined,
                upperConcernLevel: ((_w = v[5]) === null || _w === void 0 ? void 0 : _w.type) === "Amber" ? (_z = (_y = (_x = v[5]) === null || _x === void 0 ? void 0 : _x.rule) === null || _y === void 0 ? void 0 : _y.from) === null || _z === void 0 ? void 0 : _z.value : undefined,
                lowerOptimalLevel: ((_0 = v[2]) === null || _0 === void 0 ? void 0 : _0.type) === "Green" ? (_3 = (_2 = (_1 = v[2]) === null || _1 === void 0 ? void 0 : _1.rule) === null || _2 === void 0 ? void 0 : _2.to) === null || _3 === void 0 ? void 0 : _3.value : undefined,
                upperOptimalLevel: ((_4 = v[4]) === null || _4 === void 0 ? void 0 : _4.type) === "Green" ? (_7 = (_6 = (_5 = v[4]) === null || _5 === void 0 ? void 0 : _5.rule) === null || _6 === void 0 ? void 0 : _6.from) === null || _7 === void 0 ? void 0 : _7.value : undefined,
                gradeRanges: (v === null || v === void 0 ? void 0 : v.length) > 1 ? v : [],
            }
            : ((_8 = v[0]) === null || _8 === void 0 ? void 0 : _8.type) === "Red"
                ? {
                    minRating: (_12 = (_11 = (_10 = (_9 = v[0]) === null || _9 === void 0 ? void 0 : _9.rule) === null || _10 === void 0 ? void 0 : _10.from) === null || _11 === void 0 ? void 0 : _11.value) !== null && _12 !== void 0 ? _12 : undefined,
                    maxRating: (_16 = (_15 = (_14 = (_13 = v[3]) === null || _13 === void 0 ? void 0 : _13.rule) === null || _14 === void 0 ? void 0 : _14.to) === null || _15 === void 0 ? void 0 : _15.value) !== null && _16 !== void 0 ? _16 : undefined,
                    criticalLevel: ((_17 = v[0]) === null || _17 === void 0 ? void 0 : _17.type) === "Red" ? (_20 = (_19 = (_18 = v[0]) === null || _18 === void 0 ? void 0 : _18.rule) === null || _19 === void 0 ? void 0 : _19.to) === null || _20 === void 0 ? void 0 : _20.value : undefined,
                    concernLevel: ((_21 = v[1]) === null || _21 === void 0 ? void 0 : _21.type) === "Amber" ? (_24 = (_23 = (_22 = v[1]) === null || _22 === void 0 ? void 0 : _22.rule) === null || _23 === void 0 ? void 0 : _23.to) === null || _24 === void 0 ? void 0 : _24.value : undefined,
                    optimalLevel: ((_25 = v[2]) === null || _25 === void 0 ? void 0 : _25.type) === "Green" ? (_28 = (_27 = (_26 = v[2]) === null || _26 === void 0 ? void 0 : _26.rule) === null || _27 === void 0 ? void 0 : _27.to) === null || _28 === void 0 ? void 0 : _28.value : undefined,
                    gradeRanges: (v === null || v === void 0 ? void 0 : v.length) > 1 ? v : [],
                }
                : {
                    minRating: (_32 = (_31 = (_30 = (_29 = v[0]) === null || _29 === void 0 ? void 0 : _29.rule) === null || _30 === void 0 ? void 0 : _30.from) === null || _31 === void 0 ? void 0 : _31.value) !== null && _32 !== void 0 ? _32 : undefined,
                    maxRating: (_36 = (_35 = (_34 = (_33 = v[3]) === null || _33 === void 0 ? void 0 : _33.rule) === null || _34 === void 0 ? void 0 : _34.to) === null || _35 === void 0 ? void 0 : _35.value) !== null && _36 !== void 0 ? _36 : undefined,
                    criticalLevel: ((_37 = v[3]) === null || _37 === void 0 ? void 0 : _37.type) === "Red" ? (_40 = (_39 = (_38 = v[3]) === null || _38 === void 0 ? void 0 : _38.rule) === null || _39 === void 0 ? void 0 : _39.from) === null || _40 === void 0 ? void 0 : _40.value : undefined,
                    concernLevel: ((_41 = v[2]) === null || _41 === void 0 ? void 0 : _41.type) === "Amber" ? (_44 = (_43 = (_42 = v[2]) === null || _42 === void 0 ? void 0 : _42.rule) === null || _43 === void 0 ? void 0 : _43.from) === null || _44 === void 0 ? void 0 : _44.value : undefined,
                    optimalLevel: ((_45 = v[1]) === null || _45 === void 0 ? void 0 : _45.type) === "Green" ? (_48 = (_47 = (_46 = v[1]) === null || _46 === void 0 ? void 0 : _46.rule) === null || _47 === void 0 ? void 0 : _47.from) === null || _48 === void 0 ? void 0 : _48.value : undefined,
                    gradeRanges: (v === null || v === void 0 ? void 0 : v.length) > 1 ? v : [],
                };
        setFieldValue(`minRating`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.minRating);
        setFieldValue(`maxRating`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.maxRating);
        setFieldValue(`gradeRanges`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.gradeRanges);
        if ((values === null || values === void 0 ? void 0 : values.requiredBenchmarkDirection) === BenchmarkDirectionType.Middle) {
            setFieldValue(`lowerCriticalLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.lowerCriticalLevel);
            setFieldValue(`lowerConcernLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.lowerConcernLevel);
            setFieldValue(`lowerOptimalLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.lowerOptimalLevel);
            setFieldValue(`upperOptimalLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.upperOptimalLevel);
            setFieldValue(`upperConcernLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.upperConcernLevel);
            setFieldValue(`upperCriticalLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.upperCriticalLevel);
        }
        else {
            setFieldValue(`criticalLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.criticalLevel);
            setFieldValue(`concernLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.concernLevel);
            setFieldValue(`optimalLevel`, updatedBenchmark === null || updatedBenchmark === void 0 ? void 0 : updatedBenchmark.optimalLevel);
        }
        rangeErrorHandler(v);
    };
    return (React.createElement("div", { className: "box-main-section" },
        React.createElement("section", { className: "box-section", "data-test": "tracked-item-benchmark-component" },
            React.createElement("div", { className: "box-title" },
                React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.benchmarks"]))),
            React.createElement("div", { className: "box-description" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["TrackedItem.choosen", "TrackedItem.benchmark"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(BenchmarksPerTrackedItemType, { benchmarkDropdownOptions: benchmarkDropdownOptions, className: "mb-2 mb-md-0", selectedValue: values.benchmarkCode, onChange: (value) => {
                                    onBenchmarkValueSelection(value);
                                    updateRangeError.current = true;
                                }, disabled: values.reportTypeId === undefined, error: errors.benchmarkCode && touched.benchmarkCode
                                    ? errors.benchmarkCode
                                    : null }))),
                    React.createElement("div", { className: "col-lg-8 col-md-9" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label" },
                                React.createElement("label", null,
                                    " ",
                                    localize(["TrackedItem.rating"]),
                                    React.createElement(Asterik, null))),
                            React.createElement(NumericColoredRanges, { value: values === null || values === void 0 ? void 0 : values.gradeRanges, onChange: (v) => handleGradeRangesChange(v), disabled: values.weight === undefined ||
                                    values.reportTypeId === undefined ||
                                    values.benchmarkCode !==
                                        TrackedItemTypebenchmark.TRACKED_ITEM_TYPE_BENCHMARK_CUSTOM, leftAddDisabled: (values === null || values === void 0 ? void 0 : values.requiredBenchmarkDirection) ===
                                    BenchmarkDirectionType.Downwards, rightAddDisabled: (values === null || values === void 0 ? void 0 : values.requiredBenchmarkDirection) ===
                                    BenchmarkDirectionType.Upwards, category: values === null || values === void 0 ? void 0 : values.itemCategoryId, bandScales: bandScales, weight: values === null || values === void 0 ? void 0 : values.weight }),
                            rangeErrors && React.createElement("p", { className: "error" }, rangeErrors),
                            !rangeErrors && errors.gradeRanges && touched.gradeRanges && (React.createElement("p", { className: "error" }, errors.gradeRanges)))))))));
};
export default BenchmarkSelection;
