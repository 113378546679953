import { Switch, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import UsePrintPage from "../../../../core/hooks/usePrintPage";
import { SessionStorage } from "../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../core/constants/SessionVariables";
import { GlobalNotificationsTable } from "./components/globalNotificationsTable";
import localize from "../../../../core/utils/translation-files";
import TableActions from "../../../../layout/components/customTable/tableActions";
import { notifications } from "../../../../layout/components/notifications";
import ClientNotificationsTable from "./components/clientNotificationTable";
import OverwriteGlobalModel from "./components/overwriteGlobalModel";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { disableGlobalNotification, enableGlobalNotification, getGlobalNotifications, } from "../../../../redux/notification/global/actions";
import { getClientNotification, disableClientNotification, enableClientNotification, resetDefaultClientNotifications, } from "../../../../redux/notification/client/actions";
import isActionAllowed from "../../../../core/authorization/userPermissions";
import { MANAGE_GLOBAL_NOTIFICATIONS } from "../../../../core/constants/permissionConstants";
import { MANAGE_CLIENT_NOTIFICATIONS } from "./../../../../core/constants/permissionConstants";
import isEmpty from "./../../../../core/helpers/isEmpty";
import UsePersistCustomFilters from "../../../../core/hooks/usePersistCustomFilters";
import FiltersDropdownMenu from "../../../../layout/components/select/FiltersDropdownMenu";
import PrintButton from "../../../../layout/components/common/PrintButton";
import SelectedListingFilters from "../../../../layout/components/selectedListingFilters";
import Header from "../../../../layout/components/common/header";
import ListingSearch from "../../../../layout/components/listingSearch";
import { clearSearchInputSuccess } from "../../../../redux/listingSearchInput/actions";
import LookupDropDown from "../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../core/constants/lookup";
import useDidMount from "../../../../core/hooks/useDidMount";
import { getLookupByListing } from "../../../../core/helpers/lookupHanlder";
import { getLookups } from "../../../../redux/lookup/actions";
const NotificationsListingContainer = ({ globalNotifications, clientNotifications, getGlobalNotifications, disableGlobalNotification, enableGlobalNotification, getClientNotification, enableClientNotification, disableClientNotification, resetDefaultClientNotifications, isPrintMode, clearSearchInputSuccess, getLookups, }) => {
    var _a;
    const [overwriteModelVisibility, setOverwriteModelVisibility] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState();
    const { filters, setFilters } = UsePersistCustomFilters({ defaultValue: {} });
    const { TabPane } = Tabs;
    const { handlePrint, nodeRef } = UsePrintPage();
    const [selectedTab, setSelectedTab] = useState((_a = SessionStorage.getKey(SessionVariables.SELECTED_NOTIFICATIONS_LISTING_TAB)) !== null && _a !== void 0 ? _a : "1");
    const [categoryType, setcategoryType] = useState(0);
    const changeFilterValue = (e) => {
        setcategoryType(e);
    };
    const [formChange, setFormChange] = useState(false);
    useEffect(() => {
        if (selectedTab === "1") {
            getGlobalNotifications(categoryType).catch((error) => {
                throw error;
            });
            setFormChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalNotifications.length, selectedTab, categoryType, formChange]);
    useEffect(() => {
        if (!isEmpty(filters) && selectedTab === "2") {
            applyClientFilters();
            setFormChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, selectedTab, categoryType, formChange]);
    const handleChangeTab = (activeKey) => {
        clearSearchInputSuccess();
        SessionStorage.setKey(SessionVariables.SELECTED_NOTIFICATIONS_LISTING_TAB, activeKey);
        setSelectedTab(activeKey);
    };
    const applyClientFilters = (callBackFilterValues = null) => {
        const { groupId, companyId, siteId } = callBackFilterValues !== null && callBackFilterValues !== void 0 ? callBackFilterValues : filters;
        const shouldFetch = selectedTab === "2" ? true : false;
        if (!isEmpty(groupId) && shouldFetch) {
            getClientNotification(groupId, companyId !== null && companyId !== void 0 ? companyId : -1, siteId !== null && siteId !== void 0 ? siteId : -1, categoryType);
        }
    };
    const valuesRef = useRef(null);
    const openOverwriteModal = (notification) => {
        setSelectedNotification(notification);
        setOverwriteModelVisibility(true);
    };
    const closeOverwriteModel = (resetForm = null) => {
        var _a;
        if (!((_a = valuesRef.current) === null || _a === void 0 ? void 0 : _a.isPrompt) ||
            window.confirm("Changes you made may not be saved.")) {
            if (typeof resetForm === "function") {
                resetForm();
            }
            setOverwriteModelVisibility(false);
        }
    };
    const unarchiveGlobalNotificationHandler = (data) => {
        disableGlobalNotification(data).then((response) => {
            if (response) {
                notifications.success(localize(["Notification.notification", "disabled", "successfully"]));
            }
        });
    };
    const archiveGlobalNotificationHandler = (data) => {
        enableGlobalNotification(data).then((response) => {
            if (response) {
                notifications.success(localize(["Notification.notification", "enabled", "successfully"]));
            }
        });
    };
    const unarchiveClientNotificationHandler = (data) => {
        disableClientNotification(data).then((response) => {
            if (response) {
                if (!isEmpty(response.Message)) {
                    notifications.success(response.Message);
                }
                else {
                    notifications.success(localize(["Notification.notification", "disabled", "successfully"]));
                }
            }
        });
    };
    const archiveClientNotificationHandler = (data) => {
        enableClientNotification(data).then((response) => {
            if (response) {
                if (response) {
                    if (!isEmpty(response.Message)) {
                        notifications.success(response.Message);
                    }
                    else {
                        notifications.success(localize(["Notification.notification", "enabled", "successfully"]));
                    }
                }
            }
        });
    };
    const resetClientNotificationHandler = (data) => {
        resetDefaultClientNotifications(data).then((response) => {
            if (response) {
                notifications.success(localize([
                    "Notification.notification",
                    "Notification.resetToDefault",
                    "successfully",
                ]));
            }
        });
    };
    const globalNotificationActions = (notification) => {
        const actionArray = [
            {
                priority: 1,
                title: notification.enabled === true
                    ? ["disable", "Notification.notification"]
                    : ["enable", "Notification.notification"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${notification.enabled === true ? "disable" : "enable"}`,
                    "Notification.thisNotification?",
                ],
                isAllowed: isActionAllowed([MANAGE_GLOBAL_NOTIFICATIONS]),
                onConfirm: notification.enabled === true
                    ? unarchiveGlobalNotificationHandler.bind({}, notification.id)
                    : archiveGlobalNotificationHandler.bind({}, notification.id),
                children: React.createElement(Switch, { className: "ml-1", checked: notification.enabled }),
            },
            {
                priority: 2,
                title: ["Notification.overwrite", "Notification.notification"],
                btnType: "primary",
                onClick: openOverwriteModal.bind({}, notification),
                svgIcon: "Modify",
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    const clientNotificationActions = (notification) => {
        const actionArray = [
            {
                priority: 1,
                title: notification.enabled === true
                    ? ["disable", "Notification.notification"]
                    : ["enable", "Notification.notification"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${notification.enabled === true ? "disable" : "enable"}`,
                    "Notification.thisNotification?",
                ],
                onConfirm: notification.enabled === true
                    ? unarchiveClientNotificationHandler.bind({}, notification.id)
                    : archiveClientNotificationHandler.bind({}, notification.id),
                children: React.createElement(Switch, { className: "ml-1", checked: notification.enabled }),
            },
            {
                priority: 2,
                title: ["Notification.overwrite", "Notification.notification"],
                onClick: openOverwriteModal.bind({}, notification),
                svgIcon: "Edit",
            },
            {
                priority: 3,
                title: ["Notification.resetToDefault", "Notification.notification"],
                onClick: resetClientNotificationHandler.bind({}, notification.id),
                svgIcon: "Reset",
                iconStyle: { color: "#6e6b7b" },
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKNotificationCategory],
            getLookups,
        });
    });
    return (React.createElement("div", null,
        React.createElement("div", { ref: nodeRef },
            React.createElement(Header, { leftComponent: React.createElement(SelectedListingFilters, { filters: filters, hidden: selectedTab === "1" }), rightComponent: React.createElement("div", { className: "row" },
                    React.createElement(ListingSearch, null),
                    selectedTab === "2" && (React.createElement(FiltersDropdownMenu, { groupOptionValues: filters, onChangeGroupOptions: setFilters, customPlaceHolder: true, onApply: applyClientFilters })),
                    React.createElement(PrintButton, { printOnClick: handlePrint })) }),
            React.createElement("div", { className: "notification-dropdown-container" },
                React.createElement(Tabs, { defaultActiveKey: selectedTab, onChange: handleChangeTab },
                    React.createElement(TabPane, { tab: localize([
                            "Notification.global",
                            "Notification.notifications",
                        ]), key: "1", id: "globalNotifications" }, selectedTab === "1" && (React.createElement(GlobalNotificationsTable, { key: "globalNotificationTable", data: globalNotifications, actions: globalNotificationActions, isPrintMode: isPrintMode, formChange: formChange, setFormChange: setFormChange }))),
                    isActionAllowed([MANAGE_CLIENT_NOTIFICATIONS]) && (React.createElement(TabPane, { tab: localize([
                            "Notification.client",
                            "Notification.notifications",
                        ]), key: "2", id: "clientNotifications", active: true }, selectedTab === "2" && (React.createElement(ClientNotificationsTable, { key: "clientNotificationTable", data: clientNotifications, actions: clientNotificationActions, isPrintMode: isPrintMode }))))),
                React.createElement("div", { className: "notification-dropdown" },
                    React.createElement(LookupDropDown, { selectedValue: categoryType, defaultValue: categoryType, onChange: (e) => changeFilterValue(e), localizationKey: ["TrackedItem.filter"], lookUpType: LookupNames.LKNotificationCategory })))),
        React.createElement(OverwriteGlobalModel, { key: "overwrite-global-notification", visible: overwriteModelVisibility, onClose: closeOverwriteModel, selectedNotification: selectedNotification, selectedFilters: filters, valueRef: valuesRef })));
};
function mapStateTopProps(state) {
    var _a;
    return {
        globalNotifications: state.globalNotifications,
        clientNotifications: state.clientNotifications,
        isPrintMode: (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status,
    };
}
const mapDispatchToProps = {
    getGlobalNotifications,
    disableGlobalNotification,
    enableGlobalNotification,
    getClientNotification,
    disableClientNotification,
    enableClientNotification,
    resetDefaultClientNotifications,
    clearSearchInputSuccess,
    getLookups,
};
NotificationsListingContainer.propTypes = {
    getGlobalNotifications: PropTypes.func.isRequired,
    disableGlobalNotification: PropTypes.func.isRequired,
    enableGlobalNotification: PropTypes.func.isRequired,
    disableClientNotification: PropTypes.func.isRequired,
    enableClientNotification: PropTypes.func.isRequired,
    resetDefaultClientNotifications: PropTypes.func.isRequired,
    globalNotifications: PropTypes.array.isRequired,
    clientNotifications: PropTypes.array.isRequired,
    getLookups: PropTypes.any.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(NotificationsListingContainer);
