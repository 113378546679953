var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from "antd";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../../../../layout/components/common/header";
import RoleTable from "./components/roleTable";
import AddRole from "./components/addRole";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { addRole, editRole, getAllowedRoles, } from "../../../../redux/role/actions";
import { getPermissions } from "../../../../redux/permission/actions";
import { notifications } from "../../../../layout/components/notifications";
import EditRole from "./components/editRole";
import { ResponseStatusCodes } from "../../../../core/constants/responseStatusCodes";
import localize from "../../../../core/utils/translation-files";
import useDidMount from "../../../../core/hooks/useDidMount";
import UsePrintPage from "../../../../core/hooks/usePrintPage";
import TableActions from "../../../../layout/components/customTable/tableActions";
import isActionAllowed from "../../../../core/authorization/userPermissions";
import { ROLE_CREATION, ROLE_EDIT, } from "../../../../core/constants/permissionConstants";
import { LookupNames } from "../../../../core/constants/lookup";
import { getLookups } from "../../../../redux/lookup/actions";
import { getLookupByListing } from "../../../../core/helpers/lookupHanlder";
import RenderSvg from "../../../../layout/components/icons/RenderSvg";
import ReadMoreLess from "../../../../layout/components/ReadMoreLess";
import PrintButton from "../../../../layout/components/common/PrintButton";
import UseCustomPagination from "../../../../layout/components/hooks/useCustomPagination";
import ListingSearch from "../../../../layout/components/listingSearch";
import isEmpty from "../../../../core/helpers/isEmpty";
const RoleContainer = ({ getLookups, permissions, getPermissions, roles, editRole, getAllowedRoles, addRole, lookupPermissionCategories, isPrintMode, }) => {
    var _a;
    const [addingModalVisibility, setAddingModalVisibility] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [viewPortWidth, setViewPortWidth] = useState(0);
    const [rolesData, setRolesData] = useState([]);
    const { handlePrint, nodeRef } = UsePrintPage();
    const _b = UseCustomPagination({
        totalRecords: (_a = roles === null || roles === void 0 ? void 0 : roles.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange"]);
    const searchValue = useSelector((state) => state === null || state === void 0 ? void 0 : state.searchInput);
    useEffect(() => {
        setRolesData(roles);
    }, [roles]);
    useEffect(() => {
        if (isEmpty(searchValue)) {
            setRolesData(roles);
        }
    }, [searchValue, roles]);
    const initialRole = {
        id: 0,
        name: "",
        permissions: [],
        level: undefined,
    };
    const openAddingModal = () => {
        setAddingModalVisibility(true);
    };
    const openEditModal = (role) => {
        const updateRole = JSON.parse(JSON.stringify(role));
        updateRole.level = `${updateRole.level}`;
        updateRole.permissions = updateRole.permissions.map(({ id }) => id).sort();
        setSelectedRole(updateRole);
    };
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKPermissionCategory],
            getLookups,
        });
        if (permissions.length === 0) {
            getPermissions().catch((error) => {
                throw error;
            });
        }
        if (rolesData.length === 0) {
            getAllowedRoles().catch((error) => {
                throw error;
            });
        }
    });
    useEffect(() => {
        return () => {
            setAddingModalVisibility(false);
            setSelectedRole(null);
        };
    }, []);
    useLayoutEffect(() => {
        const handleSetViewPortWidth = () => {
            var _a;
            if ((rolesData === null || rolesData === void 0 ? void 0 : rolesData.length) && document.getElementsByTagName("tbody")[0]) {
                const tableBody = document.getElementsByTagName("tbody")[0];
                const permissionColumn = (_a = tableBody === null || tableBody === void 0 ? void 0 : tableBody.childNodes[0]) === null || _a === void 0 ? void 0 : _a.childNodes[1];
                const viewPortWidth = permissionColumn === null || permissionColumn === void 0 ? void 0 : permissionColumn.getBoundingClientRect().width;
                let fontConstant = 7;
                if (viewPortWidth < 380) {
                    fontConstant = 8.5;
                }
                else if (viewPortWidth < 485) {
                    fontConstant = 8;
                }
                else if (viewPortWidth < 515) {
                    fontConstant = 7.5;
                }
                else if (viewPortWidth < 770) {
                    fontConstant = 7.3;
                }
                setViewPortWidth(viewPortWidth / fontConstant);
            }
        };
        handleSetViewPortWidth();
        window.addEventListener("resize", (event) => handleSetViewPortWidth());
    }, [rolesData]);
    const closeAddingModal = (resetForm = null) => {
        var _a;
        if (!((_a = valuesRef.current) === null || _a === void 0 ? void 0 : _a.isPrompt) ||
            window.confirm("Changes you made may not be saved.")) {
            if (typeof resetForm === "function") {
                resetForm();
            }
            setAddingModalVisibility(false);
        }
    };
    const addRoleHandler = (role, resetForm) => {
        const filteredPermissions = permissions.filter((el) => role.permissions.includes(el.id));
        role.permissions = filteredPermissions;
        addRole(role).then((response) => {
            if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return;
            }
            else {
                notifications.success(localize(["Role.role", "added", "successfully"]));
                resetForm();
                setAddingModalVisibility(false);
            }
        });
    };
    const closeModal = (resetForm = null) => {
        var _a;
        if (!((_a = valuesRef.current) === null || _a === void 0 ? void 0 : _a.isPrompt) ||
            window.confirm("Changes you made may not be saved.")) {
            if (typeof resetForm === "function") {
                resetForm();
            }
            setSelectedRole(null);
        }
    };
    const editRoleHandler = (role, resetForm) => {
        const filteredPermissions = permissions.filter((el) => role.permissions.includes(el.id) ||
            role.permissions.map((x) => x.id).includes(el.id));
        const eidtedRole = Object.assign(Object.assign({}, role), { permissions: filteredPermissions });
        editRole(eidtedRole).then((response) => {
            if (response &&
                response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION) {
                return;
            }
            else {
                notifications.success(localize(["Role.role", "updated", "successfully"]));
                resetForm();
                setAddingModalVisibility(false);
                closeModal();
            }
        });
    };
    const rolesActions = (role) => {
        const actionArray = [
            {
                priority: 2,
                title: ["edit", "Role.role"],
                onClick: openEditModal.bind({}, role),
                svgIcon: "Edit",
                isAllowed: isActionAllowed([ROLE_EDIT]),
            },
        ];
        return (React.createElement(TableActions, { actionArray: actionArray, wrapperClass: "role_actions_buttons" }));
    };
    const renderRolePermissions = (permissions) => {
        return (React.createElement(ReadMoreLess, { text: permissions, characterLimit: isPrintMode ? 1000 : viewPortWidth }));
    };
    const valuesRef = useRef(null);
    return (React.createElement("div", null,
        React.createElement("div", { ref: nodeRef },
            React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                    React.createElement(ListingSearch, null),
                    React.createElement(PrintButton, { printOnClick: handlePrint }),
                    React.createElement(Button, { type: "primary", onClick: openAddingModal, hidden: !isActionAllowed([ROLE_CREATION]) },
                        React.createElement(RenderSvg, { icon: "AddRole" }),
                        localize(["add", "Role.role"]))) }),
            React.createElement(RoleTable, { roles: rolesData, overAllData: roles, actions: isActionAllowed([ROLE_EDIT]) && rolesActions, renderRolePermissions: renderRolePermissions, key: String(viewPortWidth), currentPage: restPaginationProps.currentPage, pageSize: restPaginationProps.pageSize, handleCustomTablePropChange: handleCustomTablePropChange, paginationSection: restPaginationProps, isPrintMode: isPrintMode, setRoles: setRolesData })),
        React.createElement(AddRole, { onClose: closeAddingModal, onSubmit: addRoleHandler, role: initialRole, visible: addingModalVisibility, permissions: permissions, roles: roles, lookupPermissionCategories: lookupPermissionCategories, valuesRef: valuesRef, isPrintMode: isPrintMode }),
        React.createElement(EditRole, { onEdited: editRoleHandler, existingRole: selectedRole, onClose: closeModal, visible: !!selectedRole, permissions: permissions, roles: roles, lookupPermissionCategories: lookupPermissionCategories, valuesRef: valuesRef })));
};
function mapStateTopProps(state) {
    var _a, _b;
    return {
        lookupPermissionCategories: (_a = state.lookups[LookupNames.LKPermissionCategory]) !== null && _a !== void 0 ? _a : [],
        permissions: state.permissions,
        roles: state.roles,
        context: state.context,
        isPrintMode: (_b = state.togglePrint) === null || _b === void 0 ? void 0 : _b.status,
    };
}
const mapDispatchToProps = {
    getPermissions,
    addRole,
    getAllowedRoles,
    editRole,
    getLookups,
};
RoleContainer.propTypes = {
    roles: PropTypes.array.isRequired,
    getAllowedRoles: PropTypes.func.isRequired,
    addRole: PropTypes.func.isRequired,
    editRole: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    getPermissions: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(RoleContainer);
