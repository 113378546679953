import { useEffect, useState } from "react";
import { SessionStorage } from "../helpers/localStorage/sessionStorage";
const loadInitialValue = (key, initialValue) => {
    try {
        return SessionStorage.getKey(key);
    }
    catch (e) {
        return initialValue;
    }
};
export function UseLocalStorage(key, initialValue) {
    const [value, setValue] = useState(() => loadInitialValue(key, initialValue));
    useEffect(() => {
        SessionStorage.setKey(key, value !== null && value !== void 0 ? value : initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    return [value, setValue];
}
export default UseLocalStorage;
