var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { Button, Input, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import localize from "../utils/translation-files";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import isEmpty from "../helpers/isEmpty";
import { SessionStorage } from "../helpers/localStorage/sessionStorage";
import { SessionVariables } from "../constants/SessionVariables";
import { sortFunction } from "../utils/arrayUtils";
const UseRenderFilterTable = ({ serverSorting = false, serverSearching = false, serverFiltering = false, serverPagination = false, pageSize = 10, currentPage = 1, customTablePropChange = null, stateParams = false, combineFilters = null, setCombineFilters = null, overAllData = null, }) => {
    var _a;
    const searchInput = React.useRef(null);
    const location = useLocation();
    const urlQueryParams = stateParams
        ? (_a = location.state) === null || _a === void 0 ? void 0 : _a.queryParams
        : queryString.parse((location === null || location === void 0 ? void 0 : location.search) ||
            SessionStorage.getKey(SessionVariables.QUERY_PARAMS, sessionStorage));
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [paginationConfig] = useState({
        current: currentPage,
        pageSize: pageSize,
    });
    const history = useHistory();
    const hide = useSelector((state) => state.togglePrint);
    const getColumns = (columns, data) => columns
        .filter(({ hidden, fieldName }) => {
        return hidden ? false : !(fieldName === "actions" && hide.status);
    })
        .map((column, index) => {
        var _a, _b, _c, _d;
        const renderedColumn = {};
        const keyProperty = column.fieldName;
        if (Array.isArray(column.title)) {
            renderedColumn.title = localize(column.title);
        }
        else {
            renderedColumn.title = column.title;
        }
        renderedColumn.fieldName = keyProperty;
        if (keyProperty !== "actions")
            renderedColumn.dataIndex = keyProperty;
        if (column.sortable) {
            if (column.defaultSortOrder) {
                renderedColumn.defaultSortOrder = column.defaultSortOrder;
            }
            if (serverSorting) {
                if ((urlQueryParams === null || urlQueryParams === void 0 ? void 0 : urlQueryParams.sort) === keyProperty) {
                    renderedColumn.defaultSortOrder = urlQueryParams === null || urlQueryParams === void 0 ? void 0 : urlQueryParams.order;
                }
                renderedColumn.sorter = true;
            }
            else {
                renderedColumn.sorter = (current, next) => sortFunction(current, next, keyProperty);
            }
        }
        if (column.enableFilter) {
            if ((_b = (_a = column === null || column === void 0 ? void 0 : column.filterInfo) === null || _a === void 0 ? void 0 : _a.listing) === null || _b === void 0 ? void 0 : _b.length) {
                renderedColumn.filters = filterListingRender(column.filterInfo.listing);
            }
            if (serverFiltering) {
                if (urlQueryParams[keyProperty]) {
                    renderedColumn.defaultFilteredValue = Array.isArray(urlQueryParams[keyProperty])
                        ? urlQueryParams[keyProperty]
                        : [urlQueryParams[keyProperty]];
                }
            }
            else {
                renderedColumn.onFilter = (value, record) => {
                    var _a;
                    return clientFilterValue(value, record, keyProperty, (_a = column === null || column === void 0 ? void 0 : column.filterInfo) === null || _a === void 0 ? void 0 : _a.nestedKey);
                };
                if (!((_d = (_c = column === null || column === void 0 ? void 0 : column.filterInfo) === null || _c === void 0 ? void 0 : _c.listing) === null || _d === void 0 ? void 0 : _d.length)) {
                    renderedColumn.filters = [
                        ...new Set(overAllData === null || overAllData === void 0 ? void 0 : overAllData.map((value) => {
                            var _a, _b;
                            return ((_a = column === null || column === void 0 ? void 0 : column.filterInfo) === null || _a === void 0 ? void 0 : _a.nestedKey)
                                ? value[keyProperty][(_b = column.filterInfo) === null || _b === void 0 ? void 0 : _b.nestedKey]
                                : value[keyProperty];
                        })),
                    ]
                        .filter(Boolean)
                        .map((value) => ({ text: value, value }));
                }
            }
        }
        return Object.assign(Object.assign(Object.assign({ ellipsis: true, align: column.align && column.align }, (column.searchable &&
            getColumnSearchProps(column.fieldName, renderedColumn.title))), column), renderedColumn);
    });
    const clientFilterValue = (value, record, rootKey, nestedKey) => {
        var _a, _b;
        if (rootKey === "actions" && nestedKey) {
            // check if number then parse 1/o to true/false
            const recordValue = typeof record[nestedKey] === "number"
                ? Boolean(record[nestedKey])
                : record[nestedKey];
            return recordValue === value;
        }
        else if (nestedKey) {
            if (Array.isArray(record[rootKey]) && record[rootKey].length > 0) {
                return (_a = record[rootKey]) === null || _a === void 0 ? void 0 : _a.some((data) => data[nestedKey] === value);
            }
            else if (((_b = Object.keys(record[rootKey])) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                return record[rootKey][nestedKey] === value;
            }
        }
        else {
            return record[rootKey] === value;
        }
    };
    const filterListingRender = (filter) => {
        return typeof (filter === null || filter === void 0 ? void 0 : filter[0]) === "object"
            ? filter
            : filter.map((value) => ({
                text: value,
                value,
            }));
    };
    const onTablePropChange = (paginationProp, filters, sorter, extra) => {
        const filtersValue = Object.values(filters);
        const filtersKey = Object.keys(filters);
        var filtersArray = [];
        const [first, ...overAllFilters] = [...combineFilters];
        setCombineFilters([]);
        filtersValue === null || filtersValue === void 0 ? void 0 : filtersValue.forEach((item, index) => {
            !isEmpty(item) && !filtersArray.includes(item) && filtersArray.push({ [filtersKey[index]]: item });
        });
        setCombineFilters([first, ...filtersArray]);
        let _a = urlQueryParams !== null && urlQueryParams !== void 0 ? urlQueryParams : {}, { sort, order } = _a, queryParams = __rest(_a, ["sort", "order"]);
        if (serverPagination) {
            const { current, pageSize } = paginationProp;
            queryParams["page"] = current;
            queryParams["limit"] = pageSize;
        }
        if (serverFiltering || serverSearching) {
            let updatedParam = Object.entries(filters).reduce((prevParam, [key, value]) => {
                if (queryParams[key] && !value)
                    delete queryParams[key];
                return value ? Object.assign(Object.assign({}, prevParam), { [key]: value }) : prevParam;
            }, {});
            //Reset pagination on filtering if custom pagination applied//
            if (!serverPagination &&
                queryParams["page"] &&
                extra.action === "filter") {
                //custom pagination
                queryParams["page"] = "1";
            }
            queryParams = Object.assign(Object.assign({}, queryParams), updatedParam);
        }
        if (serverSorting) {
            const { order, field } = sorter;
            queryParams["sort"] = order ? field : undefined;
            queryParams["order"] = order;
        }
        if (!isEmpty(queryParams)) {
            handlePushParams(queryParams);
        }
    };
    const renderHighlighter = (text, column) => {
        if (searchedColumn === column && !serverSearching) {
            return (React.createElement(Highlighter, { highlightStyle: { backgroundColor: "#ffc069", padding: 0 }, searchWords: [searchText], autoEscape: true, textToHighlight: text ? text.toString() : "" }));
        }
        else {
            return text;
        }
    };
    const getColumnSearchProps = (dataIndex, placeHolder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (React.createElement("div", { style: { padding: 8 } }, serverSearching ? (React.createElement(React.Fragment, null,
            React.createElement(Input, { ref: searchInput, type: "search", placeholder: `Search ${placeHolder}`, value: selectedKeys[0], onChange: (e) => {
                    const currentSelectedKey = e.target.value
                        ? [e.target.value]
                        : [];
                    setSelectedKeys(currentSelectedKey);
                    setSearchedColumn((currentSelectedKey === null || currentSelectedKey === void 0 ? void 0 : currentSelectedKey.length) ? dataIndex : "");
                }, onPressEnter: () => {
                    confirm();
                }, style: { width: 188, marginBottom: 8, display: "block" } }),
            React.createElement(Space, null,
                React.createElement(Button, { type: "primary", onClick: () => handleSearch(selectedKeys, confirm, dataIndex), icon: React.createElement(SearchOutlined, null), size: "small", style: { width: 90 } }, "Search"),
                React.createElement(Button, { onClick: () => handleReset(clearFilters), size: "small", style: { width: 90 } }, "Reset")))) : (React.createElement(Input, { ref: searchInput, placeholder: `Search ${placeHolder}`, value: selectedKeys[0], onChange: (e) => {
                const currentSelectedKey = e.target.value ? [e.target.value] : [];
                setSelectedKeys(currentSelectedKey);
                handleSearch(currentSelectedKey, confirm, dataIndex);
            }, onPressEnter: () => confirm(), style: {
                width: 188,
                marginBottom: 8,
                display: "block",
            } })))),
        filterIcon: (filtered) => (React.createElement(SearchOutlined, { style: {
                color: filtered || (urlQueryParams === null || urlQueryParams === void 0 ? void 0 : urlQueryParams[dataIndex]) ? "#1890ff" : undefined,
            } })),
        defaultFilteredValue: serverSearching && (urlQueryParams === null || urlQueryParams === void 0 ? void 0 : urlQueryParams[dataIndex])
            ? [urlQueryParams === null || urlQueryParams === void 0 ? void 0 : urlQueryParams[dataIndex]]
            : null,
        onFilter: (value, record) => {
            if (serverSearching)
                return value;
            return record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes((Array.isArray(value) ? value[0] : value).toLowerCase())
                : "";
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => {
                    var _a;
                    (_a = searchInput.current) === null || _a === void 0 ? void 0 : _a.focus();
                }, 200);
            }
        },
        render: (text) => renderHighlighter(text, dataIndex),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm({ closeDropdown: serverSearching });
        setSearchedColumn(dataIndex);
        if (serverSearching && !(selectedKeys === null || selectedKeys === void 0 ? void 0 : selectedKeys.length)) {
            clearSearchParams(dataIndex);
        }
        setSearchText(selectedKeys[0]);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
        clearSearchParams();
    };
    const clearSearchParams = (searchKeyColumn = searchedColumn) => {
        const searchParam = Object.assign(Object.assign({}, (urlQueryParams && urlQueryParams)), { limit: 10, page: 1 });
        delete searchParam[searchKeyColumn];
        if (searchText) {
            handlePushParams(searchParam);
        }
    };
    const handlePushParams = (params) => {
        if (stateParams) {
            history.push(location.pathname, { queryParams: params });
            return;
        }
        history.push({ search: `?${queryString.stringify(params)}` });
    };
    return { getColumns, paginationConfig, onTablePropChange };
};
export default UseRenderFilterTable;
