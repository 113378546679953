import React, { useRef, useState } from "react";
import { NotificationApi } from "../../../../../core/api/notificationApi";
import usePermissions from "../../../../../core/hooks/usePermissions";
import localize from "../../../../../core/utils/translation-files";
import CustomModal from "../../../../../layout/components/customModal";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import { notifications } from "../../../../../layout/components/notifications";
import VisionCommentsForm from "./VisionCommentsForm";
const VisionCommentsPopup = ({ data, setFormChange, formChange }) => {
    const [visibility, setVisibility] = useState(false);
    const { isDelphieorSuperUser } = usePermissions();
    const valuesRef = useRef(null);
    const initialNotification = {
        visionComments: data.visionComments,
        id: data.id,
        name: data.name,
        description: data.description,
        notificationDefinitionId: data.notificationDefinitionId,
        referenceType: data.referenceType,
        referenceTypeText: data.referenceTypeText,
        recipient: data.recipient,
        enabled: data.enabled,
        categoryText: data.categoryText,
        categoryType: data.categoryType,
        sampleEmailMessage: data.sampleEmailMessage,
    };
    const closeVisionCommentModel = (resetForm = null) => {
        var _a;
        if (!((_a = valuesRef.current) === null || _a === void 0 ? void 0 : _a.isPrompt) ||
            window.confirm("Changes you made may not be saved.")) {
            if (typeof resetForm === "function") {
                resetForm();
            }
            setVisibility(false);
        }
    };
    const updateVisionCommentsHandler = (notification, resetForm) => {
        NotificationApi.updateGlobalNotificationComments(notification).then((response) => {
            if (response) {
                resetForm();
                closeVisionCommentModel();
                setFormChange(true);
                notifications.success(localize(["Notification.visionComments", "updated", "successfully"]));
            }
        });
    };
    return (React.createElement("div", null,
        isDelphieorSuperUser && (React.createElement("div", { className: "d-print-none" },
            React.createElement(RenderSvg, { icon: "Edit", onClick: () => setVisibility(true), tooltipTitle: localize(["Edit", "Notification.visionComments"]) }))),
        React.createElement(CustomModal, { visible: visibility, onCancel: closeVisionCommentModel, maskClosable: false, destroyOnClose: true, footer: null, title: [localize(["Notification.visionComments"])], width: "450px" },
            React.createElement(VisionCommentsForm, { permission: [isDelphieorSuperUser], onCancel: closeVisionCommentModel, valuesRef: valuesRef, onSubmit: updateVisionCommentsHandler, initialNotification: initialNotification }))));
};
export default VisionCommentsPopup;
