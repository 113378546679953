import React, { useRef } from "react";
import classNames from "../../../core/utils/class-names";
import "./Navbar.scss";
import useOutsideClick from "../../../core/hooks/useClickOutside";
const Navbar = ({ minHeight = 60, background, orientation, children, className = "", opened = false, color, boxed = false, onClickOutside = () => null, }) => {
    const containerRef = useRef(null);
    const handleClickOutside = () => (opened ? onClickOutside() : null);
    useOutsideClick(containerRef, handleClickOutside);
    const navClasses = classNames({
        boxed,
        opened,
        [className]: !!className,
        [orientation]: true,
    });
    const navWrapperClasses = classNames({
        [`navbar-wrap`]: true,
        [`navbar-wrap-opened`]: opened,
    });
    const navStyle = {
        background,
        minHeight,
        color,
    };
    return (React.createElement("div", { ref: containerRef, className: `navbar ${navClasses}`, style: navStyle },
        React.createElement("div", { className: navWrapperClasses }, children)));
};
export default Navbar;
