import React from "react";
import ActivityDetector from "react-activity-detector";
import { IDEAL_TIME } from "../../../config/constants";
const BrowserActivityDetector = ({ timeToIdle = IDEAL_TIME, onIdle }) => {
    const customActivityEvents = [
        "click",
        "mousemove",
        "keydown",
        "DOMMouseScroll",
        "mousewheel",
        "mousedown",
        "touchstart",
        "touchmove",
        "focus",
    ];
    return (React.createElement(ActivityDetector, { activityEvents: customActivityEvents, isActive: true, timeout: timeToIdle * 60000, signOut: onIdle }));
};
export default BrowserActivityDetector;
