import React, { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const CustomTextHighlighter = ({ text }) => {
    const searchValue = useSelector((state) => state === null || state === void 0 ? void 0 : state.searchInput);
    const location = useLocation();
    const genericSearchValue = useMemo(() => {
        var _a, _b;
        return searchValue !== null && searchValue !== void 0 ? searchValue : (_b = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.queryParams) === null || _b === void 0 ? void 0 : _b.searchInput;
    }, [searchValue, location]);
    return (React.createElement(Highlighter, { highlightStyle: { backgroundColor: "#ffc069", padding: 0 }, searchWords: [genericSearchValue], autoEscape: true, textToHighlight: text ? text.toString() : "" }));
};
export default CustomTextHighlighter;
