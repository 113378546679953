import { dashboardRoute } from "../../constants/routesPath";
import { SessionVariables } from "../../constants/SessionVariables";
import { SessionStorage } from "./sessionStorage";
const { getKey, setKey, removeKey } = SessionStorage;
const { BREADCRUMB, AUTH_TOKEN, 
// LISTING_FILTERS,
SELECTED_CATEGORY, QUERY_PARAMS, SELECTED_SUB_CATEGORY, EXPANDED_KEYS, TREE_EXPANDED_STATE, BUTTON_TEXT, } = SessionVariables;
export const getBreadCrumbSession = (pathname = null, history = null) => {
    var _a;
    let breadCrumbList = getKey(BREADCRUMB, sessionStorage);
    if (!window.location.pathname.includes("dashboard") ||
        ((_a = window.location.pathname) === null || _a === void 0 ? void 0 : _a.split("/")[3])
    // (window.location.pathname?.split("/")[3] && !window.location.pathname?.split("/")[3])
    ) {
        return breadCrumbList;
    }
    if (!breadCrumbList &&
        history // && pathname && pathname === dashboardRoute
    ) {
        clearDashboardSession();
        history.push(dashboardRoute);
    }
    // if (window.location.pathname?.split("/")[3]) {
    //   const defaultBreadCrumb = [
    //     {
    //       path: "/vertical/dashboard",
    //       name: "dashboard",
    //       category: "Dashboard Index",
    //       bandName: "X",
    //       bandColor: "darkgray",
    //     },
    //   ];
    //   setKey(BREADCRUMB, defaultBreadCrumb, sessionStorage);
    //   console.log({ defaultBreadCrumb });
    //   return defaultBreadCrumb;
    // } else
    if (breadCrumbList === null || breadCrumbList === void 0 ? void 0 : breadCrumbList.length) {
        return breadCrumbList;
    }
    else if (getKey(AUTH_TOKEN) && pathname) {
        // const defaultBreadCrumb = [
        //   {
        //     path: pathname,
        //     name: pathname?.substring(pathname?.lastIndexOf("/") + 1),
        //   },
        // ];
        const defaultBreadCrumb = [
            {
                path: "/vertical/dashboard",
                name: "dashboard",
                category: "Dashboard Index",
                bandName: "X",
                bandColor: "darkgray",
                key: -1,
                // weightFlag : false,
                // weight : 1,
            },
        ];
        setKey(BREADCRUMB, defaultBreadCrumb, sessionStorage);
        return defaultBreadCrumb;
    }
};
export const clearDashboardSession = (history = null) => {
    var _a;
    // const breadCrumbList = getBreadCrumbSession();
    removeKey(BREADCRUMB, sessionStorage);
    // if (isEmpty(breadCrumbList)) {
    //   removeKey(BREADCRUMB, sessionStorage);
    // } else {
    //   setKey(BREADCRUMB, breadCrumbList?.slice(0, 1), sessionStorage);
    // }
    // saveContext();
    // removeKey(LISTING_FILTERS, sessionStorage);
    removeKey(SELECTED_CATEGORY, sessionStorage);
    removeKey(SELECTED_SUB_CATEGORY, sessionStorage);
    removeKey(QUERY_PARAMS, sessionStorage);
    removeKey(BUTTON_TEXT, sessionStorage);
    if (!window.location.pathname.includes("dashboard") ||
        ((_a = window.location.pathname) === null || _a === void 0 ? void 0 : _a.split("/")[3])
    // (window.location.pathname?.split("/")[3] && !window.location.pathname?.split("/")[3])
    ) {
        removeKey(TREE_EXPANDED_STATE, sessionStorage);
        removeKey(EXPANDED_KEYS, sessionStorage);
    }
    // history &&
    //   window.location.pathname === dashboardRoute &&
    //   history.push(dashboardRoute);
};
export const handleSetRoadMapItemWatchListStatus = (itemId, status) => {
    const treeDataState = SessionStorage.getKey("TREE_EXPANDED_STATE", sessionStorage);
    SessionStorage.setKey("TREE_EXPANDED_STATE", handleUpdateItemHasWatchList(treeDataState, itemId, status), sessionStorage);
};
const handleUpdateItemHasWatchList = (list, selectedTrackItemId, hasWatchList) => {
    return list.map((record) => {
        var _a;
        if ((record === null || record === void 0 ? void 0 : record.key) === +selectedTrackItemId) {
            record.hasWatchList = hasWatchList;
        }
        else if (((_a = record.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            record.children = handleUpdateItemHasWatchList(record.children, selectedTrackItemId, hasWatchList);
        }
        return record;
    });
};
export const getButtonTextKey = (history = null) => {
    return getKey(BUTTON_TEXT, sessionStorage);
};
export const filterRoute = (activeIndex) => {
    var _a, _b;
    const list = getBreadCrumbSession();
    const activeItem = list[(list === null || list === void 0 ? void 0 : list.length) - 1];
    return !((_b = (_a = list[0]) === null || _a === void 0 ? void 0 : _a.path) === null || _b === void 0 ? void 0 : _b.includes("dashboard"))
        ? activeItem.parentPath
        : activeItem.path;
    // getBreadCrumbSession()
    // ?.filter((_, index) => index <= activeIndex)
    // .map(({ path }) => path)
    // .join("");
};
