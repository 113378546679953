import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, DatePicker, Input } from "antd";
import moment from "moment";
import localize from "../../../../../../core/utils/translation-files";
import RoleDropdownContainer from "../../../../../../layout/components/select/RoleDropdownContainer";
import { defaultDateFormat, defaultDateTimeFormat, } from "../../../../../../core/constants/DateTime";
import { disabledDateTime } from "../../../../../../core/validations/dateTimeValidations";
import BandDropdown from "../../../../../../layout/components/select/BandDropdown";
import { hasDelphieorSuperPermissions } from "../../../../../../core/helpers/permissionByRoleId";
import FormPermissionDropdown from "../../../../../../layout/components/select/FormPermissionDropdown";
import PromptComponent from "../../../../../../layout/components/prompt-message";
import Asterik from "../../../../shared/helper-components/Asterik";
import isEmpty from "../../../../../../core/helpers/isEmpty";
const SitesAndRoles = ({ userRoles, onSubmit, companyId, resetSiteAndRole, roles = [], permission, isGroupEnable, isUserHaveOnlyViewPermission = false, setRoleFormDirty, isUserRolesUpdated, }) => {
    const [key] = useState(1);
    const todayDate = moment().format(defaultDateTimeFormat);
    const [startCurrentDate, setStartCurrentDate] = useState(todayDate);
    const [endCurrentDate, setEndCurrentDate] = useState(todayDate);
    function disabledDate(current) {
        return current && current < moment(todayDate, defaultDateFormat); //.add(-1, "days");
    }
    let validationShape = {
        roleId: Yup.number().required(localize(["UserRole.role", "isRequired!"])),
        startDate: Yup.string().when("userRoles.id", {
            is: (x) => !userRoles.id,
            then: Yup.string()
                .required(localize(["UserRole.startDateAndTime", "isRequired!"]))
                .test("start-date-min-value", localize([
                "UserRole.startDateAndTime",
                "mustBeGreaterThan",
                "orEqualTo",
                "currentDateTime",
            ]), function (value) {
                const [selectedValue, currentDateTime] = [
                    moment(value).format(defaultDateTimeFormat),
                    moment().format(defaultDateTimeFormat),
                ];
                return moment(selectedValue, defaultDateTimeFormat).isSameOrAfter(moment(currentDateTime, defaultDateTimeFormat));
            }),
        }),
        endDate: Yup.string()
            .test("end-date-min-value", localize([
            "UserRole.endDateAndTime",
            "mustBeGreaterThan",
            "orEqualTo",
            "currentDateTime",
        ]), function (value) {
            if (isEmpty(value))
                return true;
            const [selectedValue, currentDateTime] = [
                moment(value).format(defaultDateTimeFormat),
                moment().format(defaultDateTimeFormat),
            ];
            return moment(selectedValue, defaultDateTimeFormat).isSameOrAfter(moment(currentDateTime, defaultDateTimeFormat));
        })
            .nullable(),
        groupId: Yup.number().required(localize(["Group.group", "isRequired!"])),
        companyId: Yup.number().nullable(),
        siteId: Yup.number().nullable(),
        band: Yup.number().when("roleId", {
            is: (x) => x > 0 && !hasDelphieorSuperPermissions(roles, x),
            then: Yup.number().required(localize(["User.user", "User.band", "isRequired!"])),
        }),
    };
    if (permission.isCompanyAdmin) {
        validationShape.companyId = Yup.number().required(localize(["UserRole.company", "isRequired!"]));
    }
    if (permission.isSiteAdmin) {
        validationShape.siteId = Yup.number().required(localize(["UserRole.site", "isRequired!"]));
    }
    const validationSchema = Yup.object().shape(validationShape);
    const { handleSubmit, setFieldValue, resetForm, setErrors, errors, touched, values, dirty, } = useFormik({
        validationSchema: validationSchema,
        initialValues: userRoles,
        onSubmit: (values) => {
            onSubmit(values, setErrors, resetForm);
        },
    });
    const handleChangeGroup = useCallback((value, option) => {
        setFieldValue("groupId", value);
        setFieldValue("groupName", option === null || option === void 0 ? void 0 : option.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeCompany = useCallback((value, option) => {
        var _a;
        setFieldValue("companyId", value !== null && value !== void 0 ? value : null);
        setFieldValue("companyName", (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeSite = useCallback((value, option) => {
        var _a;
        setFieldValue("siteId", value !== null && value !== void 0 ? value : null);
        setFieldValue("siteName", (_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setRoleFormDirty(dirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PromptComponent, { isPrompt: dirty || isUserRolesUpdated }),
        React.createElement("form", { "data-test": "SiteAndRolesFormComponent", className: "mb-1", name: "basic-site", onSubmit: handleSubmit, key: key },
            React.createElement(Input, { name: "index", type: "hidden" }),
            React.createElement(Input, { name: "id", type: "hidden" }),
            React.createElement(Input, { name: "type", type: "hidden" }),
            React.createElement(Input, { name: "companyId", type: "hidden" }),
            React.createElement(Input, { name: "name", type: "hidden" }),
            React.createElement(Input, { name: "siteName", type: "hidden" }),
            React.createElement("div", null,
                React.createElement("div", { className: "box-main-section" },
                    React.createElement("section", { className: "box-section" },
                        React.createElement("div", { className: "box-title" },
                            React.createElement("div", { className: "box-heading" }, localize(["UserRole.sitesAndRoles"]))),
                        React.createElement("div", { className: "box-description" },
                            React.createElement("div", { className: "row" },
                                React.createElement(FormPermissionDropdown, { groupValues: {
                                        onChangeGroup: handleChangeGroup,
                                        selectedvalue: values.groupId,
                                        disabled: !isGroupEnable || isUserHaveOnlyViewPermission,
                                        errorMessage: errors.groupId && touched.groupId
                                            ? errors.groupId
                                            : null,
                                    }, companyValues: {
                                        onChangeCompany: handleChangeCompany,
                                        selectedvalue: values.companyId,
                                        disabled: values.id > 0,
                                        errorMessage: errors.companyId && touched.companyId
                                            ? errors.companyId
                                            : null,
                                        allowClear: true,
                                    }, siteValues: {
                                        onChangeSite: handleChangeSite,
                                        selectedvalue: values.siteId,
                                        disabled: values.id > 0,
                                        errorMessage: errors.siteId && touched.siteId ? errors.siteId : null,
                                        allowClear: true,
                                    }, 
                                    // dirty={dirty}
                                    dirty: true }),
                                React.createElement("div", { className: "col-3", "data-test": "role-dropdown" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                " ",
                                                localize(["UserRole.role"]),
                                                React.createElement(Asterik, null),
                                                " ")),
                                        React.createElement(RoleDropdownContainer, { className: "mb-2 mb-md-0", companyId: companyId, selectedValue: values.roleId, onChange: (value, e) => {
                                                setFieldValue("roleId", value);
                                                setFieldValue("roleName", e === null || e === void 0 ? void 0 : e.name);
                                            }, disabled: values.id > 0, isExternal: isGroupEnable, error: errors.roleId && touched.roleId ? errors.roleId : null }))),
                                !hasDelphieorSuperPermissions(roles, values.roleId) && (React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                " ",
                                                localize(["User.user", "User.band"]),
                                                " ",
                                                React.createElement(Asterik, { hidden: isEmpty(values.roleId) }))),
                                        React.createElement(BandDropdown, { selectedBand: values.band, setSelectedBand: (value) => setFieldValue("band", value), error: errors.band && touched.band ? errors === null || errors === void 0 ? void 0 : errors.band : null, disabled: isUserHaveOnlyViewPermission })))),
                                React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                " ",
                                                localize(["UserRole.startDateAndTime"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(DatePicker, { value: values.startDate, disabledDate: disabledDate, format: defaultDateTimeFormat, showTime: true, className: `mb-2 mb-md-0 d-block ant-input ${errors.startDate && touched.startDate
                                                ? `input-error`
                                                : ``}`, placeholder: localize(["UserRole.startDateAndTime"]), onChange: (date) => setFieldValue("startDate", date ? date : ""), onSelect: (selectedDate) => {
                                                setStartCurrentDate(moment(selectedDate).format(defaultDateTimeFormat));
                                            }, disabledTime: disabledDateTime.bind({}, startCurrentDate, "startDate"), disabled: values.id > 0 &&
                                                values.startDate &&
                                                moment(values.startDate, defaultDateTimeFormat).isSameOrBefore(moment()) }),
                                        errors.startDate && touched.startDate && (React.createElement("p", { className: "error" }, errors.startDate)))),
                                React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                " ",
                                                localize(["UserRole.endDateAndTime"]),
                                                " ")),
                                        React.createElement(DatePicker, { value: values.endDate, disabledDate: disabledDate, format: defaultDateTimeFormat, showTime: true, className: `mb-2 mb-md-0 d-block ant-input ${errors.endDate && touched.endDate ? `input-error` : ``}`, placeholder: localize(["UserRole.endDateAndTime"]), onChange: (date) => setFieldValue("endDate", date ? date : ""), onSelect: (selectedDate) => {
                                                setEndCurrentDate(moment(selectedDate).format(defaultDateTimeFormat));
                                            }, disabledTime: disabledDateTime.bind({}, endCurrentDate, "endDate"), disabled: isUserHaveOnlyViewPermission }),
                                        errors.endDate && touched.endDate && (React.createElement("p", { className: "error" }, errors.endDate)))),
                                React.createElement("div", { className: "col-3 d-print-none" },
                                    React.createElement("div", { className: "form-group mt-4" },
                                        React.createElement(Button, { onClick: () => resetSiteAndRole(), htmlType: "button", type: "default", hidden: !dirty || isUserHaveOnlyViewPermission }, localize(["Button.reset"])),
                                        React.createElement(Button, { htmlType: "submit", type: "primary", hidden: isUserHaveOnlyViewPermission }, localize(["Button.assign"]))))),
                            errors["existing"] && (React.createElement("p", { className: "error" }, errors["existing"])))))))));
};
export default React.memo(SitesAndRoles);
