var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Switch, Tabs } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { UserListingTable } from "./components/userListingTable";
import PropTypes from "prop-types";
import { getUsers, inActivateUser, unlockUser, lockUser, activateUser, clearUserListingSuccess, } from "../../../../../redux/user/actions";
import { deletePendingUser, getPendingUsers, clearPendingUserListingSuccess, } from "../../../../../redux/pending-user/actions";
import UserRolesListing from "./components/userRolesListing";
import { UserStatus, UserType } from "../../../../../core/enums/UserStatus";
import { registerUserRoute, usersRoute, } from "../../../../../core/constants/routesPath";
import { AccountApi } from "../../../../../core/api/accountApi";
import { PendingUserListingTable } from "./components/pendingUserListingTable";
import { PendingUserApi } from "../../../../../core/api/pendingUserApi";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import { notifications } from "../../../../../layout/components/notifications";
import localize from "../../../../../core/utils/translation-files";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import TableActions from "../../../../../layout/components/customTable/tableActions";
import { useEffect } from "react";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { usersChanged } from "../../../../../redux/context/actions";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import { EXTERNAL_USER_ARCHIVE, EXTERNAL_USER_CREATION, USER_CREATION, USER_EDIT, USER_VIEW, } from "../../../../../core/constants/permissionConstants";
import UsePersistCustomFilters from "../../../../../core/hooks/usePersistCustomFilters";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import FiltersDropdownMenu from "../../../../../layout/components/select/FiltersDropdownMenu";
import SelectedListingFilters from "../../../../../layout/components/selectedListingFilters";
import PrintButton from "../../../../../layout/components/common/PrintButton";
import Header from "../../../../../layout/components/common/header";
import ListingSearch from "../../../../../layout/components/listingSearch";
import { clearSearchInputSuccess } from "../../../../../redux/listingSearchInput/actions";
import useDidMount from "../../../../../core/hooks/useDidMount";
const UserListingContainer = ({ users, getUsers, inActivateUser, activateUser, context, unlockUser, lockUser, pendingUsers, getPendingUsers, clearPendingUserListingSuccess, deletePendingUser, companies, usersChanged, clearUserListingSuccess, isPrintMode, clearSearchInputSuccess, }) => {
    var _a, _b;
    const [roleModelVisibility, setRoleModelVisibility] = useState(false);
    const history = useHistory();
    const [userRoles, setUserRoles] = useState();
    const [userName, setUserName] = useState();
    const { TabPane } = Tabs;
    const { handlePrint, nodeRef } = UsePrintPage();
    const [selectedTab, setSelectedTab] = useState((_a = SessionStorage.getKey(SessionVariables.SELECTED_USER_LISTING_TAB)) !== null && _a !== void 0 ? _a : "1");
    const clearListing = () => {
        clearUserListingSuccess();
        clearPendingUserListingSuccess();
        //SessionStorage.removeKey(SessionVariables.SELECTED_USER_LISTING_TAB);
    };
    const { setFilters, filters } = UsePersistCustomFilters({
        defaultValue: {},
        clearContextCallBack: clearListing,
    });
    const handleChangeTab = (activeKey) => {
        clearSearchInputSuccess();
        SessionStorage.setKey(SessionVariables.SELECTED_USER_LISTING_TAB, activeKey);
        setSelectedTab(activeKey);
    };
    const handleChangeFilters = (filterValues) => {
        setFilters(Object.assign(Object.assign({}, filters), { [selectedTab]: filterValues }));
    };
    const tabFilterValue = useCallback((tabKey) => { var _a; return (_a = filters === null || filters === void 0 ? void 0 : filters[tabKey]) !== null && _a !== void 0 ? _a : {}; }, [filters]);
    const openRoleModal = (data, userName) => {
        setUserRoles(data);
        setUserName(userName);
        setRoleModelVisibility(true);
    };
    const closeRoleModal = () => setRoleModelVisibility(false);
    const isFirstRender = useRef(true);
    useDidMount(() => {
        if (!isEmpty(filters) && pendingUsers.length > 0)
            clearPendingUserListingSuccess();
    });
    useEffect(() => {
        if (isFirstRender.current && !isEmpty(filters)) {
            applyFilters();
            // isFirstRender.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, users.length, pendingUsers.length]);
    useEffect(() => {
        if (!isEmpty(filters)) {
            applyFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, selectedTab]);
    const handleApplyFilter = (updatedFilterValues) => {
        (selectedTab === "1"
            ? clearUserListingSuccess
            : clearPendingUserListingSuccess)();
        applyFilters(updatedFilterValues, true);
    };
    const applyFilters = (updatedFilterValues = null, submitAction = false) => {
        var _a, _b;
        if (selectedTab === "1") {
            const { groupId, companyId, siteId } = updatedFilterValues !== null && updatedFilterValues !== void 0 ? updatedFilterValues : tabFilterValue("1");
            if (!isEmpty(groupId) && (!users.length || submitAction)) {
                getUsers((_a = siteId !== null && siteId !== void 0 ? siteId : companyId) !== null && _a !== void 0 ? _a : groupId).catch((error) => {
                    throw error;
                });
            }
        }
        if (selectedTab === "2") {
            const { groupId, companyId, siteId } = updatedFilterValues !== null && updatedFilterValues !== void 0 ? updatedFilterValues : tabFilterValue("2");
            if (!isEmpty(groupId) && (!pendingUsers.length || submitAction)) {
                getPendingUsers((_b = siteId !== null && siteId !== void 0 ? siteId : companyId) !== null && _b !== void 0 ? _b : groupId).catch((error) => {
                    throw error;
                });
            }
        }
    };
    const inActivateUserHandler = (data) => {
        inActivateUser(data, () => notifications.success(localize(["User.user", "inactivated", "successfully"])));
    };
    const activateUserHandler = (data) => {
        activateUser(data, () => notifications.success(localize(["User.user", "activated", "successfully"])));
    };
    const unLockUserHanlder = (data) => {
        unlockUser(data).then((res) => {
            if (res && res.code) {
                return;
            }
            else {
                notifications.success(localize(["User.user", "unlocked", "successfully"]));
            }
        });
    };
    const lockUserHanlder = (data) => {
        lockUser(data).then((res) => {
            if (res && res.code) {
                return;
            }
            else {
                notifications.success(localize(["User.user", "locked", "successfully"]));
            }
        });
    };
    const editUserRedirect = (user) => {
        var _a;
        history.push(`users/edit/${user.id}`, {
            userId: user.id,
            userGroupId: (_a = tabFilterValue("1")) === null || _a === void 0 ? void 0 : _a.groupId,
        });
    };
    const sendUsernamePassword = (user) => __awaiter(void 0, void 0, void 0, function* () {
        AccountApi.forgetPassword(user.username).then((response) => { });
    });
    const sendNewRegisterationLink = (userId) => __awaiter(void 0, void 0, void 0, function* () {
        var response = yield PendingUserApi.resendInvitationLink({
            externalUserId: userId,
            registrationLink: registerUserRoute,
        });
        if (response &&
            response.code ===
                ResponseStatusCodes.EXTERNAL_USER_INVITATION_LIMIT_REACHED) {
            return;
        }
        clearPendingUserListingSuccess();
        notifications.success("Invitaiton link has been sent to user.");
    });
    const deletePendingUserHandler = (userId) => __awaiter(void 0, void 0, void 0, function* () {
        deletePendingUser(userId).then((response) => { });
    });
    const pendingUsersActions = (user) => {
        const actionArray = [
            {
                priority: 2,
                title: ["resend", "invitation"],
                // btnType: "default",
                onClick: sendNewRegisterationLink.bind({}, user.id),
                svgIcon: "Email",
            },
            {
                priority: 1,
                title: ["delete", "invitation"],
                isModal: true,
                modalTitle: ["areYouSureTo", "delete", "this", "User.User", "?"],
                onConfirm: deletePendingUserHandler.bind({}, user.id),
                children: (React.createElement("span", { className: "icofont icofont-ui-delete", style: { color: "red", fontSize: "16px", cursor: "pointer" } })),
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    const usersActions = (user) => {
        const actionArray = [
            {
                priority: 2,
                title: ["edit", "User.user"],
                onClick: editUserRedirect.bind({}, user),
                svgIcon: "Edit",
                isAllowed: isActionAllowed([USER_VIEW]),
            },
            {
                priority: 3,
                title: ["Send Username/Password"],
                btnType: "default",
                onClick: sendUsernamePassword.bind({}, user),
                svgIcon: "Email",
                isAllowed: isActionAllowed([USER_CREATION]),
            },
            {
                priority: 4,
                title: user.status && user.status === UserStatus.ACTIVE
                    ? ["lock", "User.user"]
                    : ["unlock", "User.user"],
                isModal: true,
                isAllowed: isActionAllowed([USER_CREATION]),
                modalTitle: [
                    "areYouSureTo",
                    `${user.status === 1 ? "Lock" : "Unlock"}`,
                    "this",
                    "User.User",
                    "?",
                ],
                onConfirm: user.status && user.status === UserStatus.ACTIVE
                    ? lockUserHanlder.bind({}, user.id)
                    : unLockUserHanlder.bind({}, user.id),
                children: (React.createElement(RenderSvg, { icon: (user === null || user === void 0 ? void 0 : user.status) === UserStatus.ACTIVE ? "UnLockUser" : "LockUser", tooltipTitle: [
                        (user === null || user === void 0 ? void 0 : user.status) === UserStatus.ACTIVE ? "lock" : "unlock",
                        "User.user",
                    ] })),
            },
            {
                priority: 1,
                title: user.status === UserStatus.INACTIVE
                    ? ["unArchive", "User.user"]
                    : ["archive", "User.user"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${user.status === UserStatus.INACTIVE ? "unarchive" : "Archive"}`,
                    "this",
                    "User.User",
                    "?",
                ],
                isAllowed: isActionAllowed([
                    user.userType === UserType.Internal
                        ? USER_EDIT
                        : EXTERNAL_USER_ARCHIVE,
                ]),
                onConfirm: user.status === UserStatus.INACTIVE
                    ? activateUserHandler.bind({}, user.id)
                    : inActivateUserHandler.bind({}, user.id),
                children: (React.createElement(Switch, { className: "ml-1", checked: user.status !== UserStatus.INACTIVE })),
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    return (React.createElement("div", null,
        React.createElement("div", { ref: nodeRef },
            React.createElement(Header, { leftComponent: React.createElement(SelectedListingFilters, { filters: tabFilterValue(selectedTab) }), rightComponent: React.createElement("div", { className: "row" },
                    React.createElement(ListingSearch, null),
                    React.createElement(FiltersDropdownMenu, { onChangeGroupOptions: handleChangeFilters, groupOptionValues: tabFilterValue(selectedTab), onApply: handleApplyFilter }),
                    React.createElement(PrintButton, { printOnClick: handlePrint }),
                    React.createElement(Link, { to: {
                            pathname: usersRoute + "/add-external-user",
                        }, hidden: !isActionAllowed([EXTERNAL_USER_CREATION]) },
                        React.createElement(Button, { htmlType: "button", type: "primary", id: "invite-external-user" },
                            React.createElement(RenderSvg, { icon: "InviteUser" }),
                            localize(["User.inviteExternalUser"]))),
                    React.createElement(Link, { to: {
                            pathname: usersRoute + "/add",
                            state: { userGroupId: (_b = tabFilterValue("1")) === null || _b === void 0 ? void 0 : _b.groupId },
                        }, hidden: !isActionAllowed([USER_CREATION]) },
                        React.createElement(Button, { htmlType: "button", type: "primary", id: "add-user" },
                            React.createElement(RenderSvg, { icon: "AddUser" }),
                            localize(["add", "User.user"])))) }),
            React.createElement(Tabs, { defaultActiveKey: selectedTab, onChange: handleChangeTab, id: "userListing-tabs" },
                React.createElement(TabPane, { tab: localize(["User.users"]), key: "1", id: "users" }, selectedTab === "1" && (React.createElement(UserListingTable, { key: "userlistingtable", data: users, actions: usersActions, openRoleModal: openRoleModal, isPrintMode: isPrintMode }))),
                React.createElement(TabPane, { tab: localize(["User.pendingInvitations"]), key: "2", id: "pendingUsers", active: true }, selectedTab === "2" && (React.createElement(PendingUserListingTable, { key: "id1", data: pendingUsers, actions: pendingUsersActions, isPrintMode: isPrintMode }))))),
        React.createElement(UserRolesListing, { key: "user-role-listing", visible: roleModelVisibility, onClose: closeRoleModal, userRoles: userRoles, userName: userName })));
};
function mapStateTopProps(state) {
    var _a;
    return {
        users: state.users,
        context: state.context,
        pendingUsers: state.pendingUsers,
        companies: state.companies,
        isPrintMode: (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status,
    };
}
const mapDispatchToProps = {
    getUsers,
    inActivateUser,
    lockUser,
    unlockUser,
    getPendingUsers,
    clearPendingUserListingSuccess,
    activateUser,
    deletePendingUser,
    usersChanged,
    clearUserListingSuccess,
    clearSearchInputSuccess,
};
UserListingContainer.propTypes = {
    users: PropTypes.array.isRequired,
    pendingUsers: PropTypes.array.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(UserListingContainer);
