import { Button, Tabs } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LookupNames } from "../../../../../../core/constants/lookup";
import { addTrackedItemTypeRoute } from "../../../../../../core/constants/routesPath";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { getLookupByListing } from "../../../../../../core/helpers/lookupHanlder";
import useDidMount from "../../../../../../core/hooks/useDidMount";
import UsePrintPage from "../../../../../../core/hooks/usePrintPage";
import localize from "../../../../../../core/utils/translation-files";
import Header from "../../../../../../layout/components/common/header";
import PrintButton from "../../../../../../layout/components/common/PrintButton";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import ListingSearch from "../../../../../../layout/components/listingSearch";
import { getLookups } from "../../../../../../redux/lookup/actions";
import SystemCreatedTypesListingContainer from "./system-created-tracked-item-type";
import UserCreatedTypesListingContainer from "./user-created-tracked-item-type";
const ManageTrackedItemTypesContainer = ({ benchmarkListOptions, getLookups, }) => {
    var _a;
    const { TabPane } = Tabs;
    const [selectedTab, setSelectedTab] = useState((_a = SessionStorage.getKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB)) !== null && _a !== void 0 ? _a : "1");
    const handleChange = (activeKey) => {
        setSelectedTab(activeKey);
        SessionStorage.setKey(SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB, activeKey);
    };
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKReportTypeBenchmark],
            getLookups,
        });
    });
    const { handlePrint, nodeRef } = UsePrintPage();
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                React.createElement(ListingSearch, null),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Link, { to: {
                        pathname: addTrackedItemTypeRoute,
                    } },
                    React.createElement(Button, { htmlType: "button", type: "primary" },
                        React.createElement(RenderSvg, { icon: "AddDefault" }),
                        localize(["add", "TrackedItem.default"])))) }),
        React.createElement(Tabs, { defaultActiveKey: selectedTab, onChange: handleChange },
            React.createElement(TabPane, { tab: "Tracked Items Default", key: "1", id: "tracked-item-definitions" }, selectedTab === "1" && (React.createElement(UserCreatedTypesListingContainer, { benchmarkListOptions: benchmarkListOptions }))),
            React.createElement(TabPane, { tab: "Index/Category Defaults", key: "2", id: "tracked-item-type-defaults" },
                " ",
                selectedTab === "2" && (React.createElement(SystemCreatedTypesListingContainer, { benchmarkListOptions: benchmarkListOptions }))))));
};
function mapStateTopProps(state) {
    var _a, _b;
    return {
        benchmarkListOptions: (_b = (_a = state.lookups[LookupNames.LKReportTypeBenchmark]) === null || _a === void 0 ? void 0 : _a.map(({ text }) => text)) !== null && _b !== void 0 ? _b : [],
    };
}
const mapDispatchToProps = {
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(ManageTrackedItemTypesContainer);
