import { Breadcrumb } from "antd";
import React, { useContext, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { dashboardRoute } from "../../../core/constants/routesPath";
import { SessionVariables } from "../../../core/constants/SessionVariables";
import { browserHistoryMemoryContext } from "../../../core/contexts/browserHistoryMemoryContext";
import { dashboardNavigation } from "../../../core/helpers/dasboardRoute";
import { filterRoute, getBreadCrumbSession, } from "../../../core/helpers/localStorage/breadCrumbSession";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
function BreadcrumbNavigation({ isTrackedItem = false, description = null }) {
    var _a;
    const { BREADCRUMB } = SessionVariables;
    const { setKey, removeKey } = SessionStorage;
    const history = useHistory();
    const { pathname } = useLocation();
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const [sessionBreadCrumb, setSessionBreadCrumb] = useState(getBreadCrumbSession(pathname, history));
    const [updatedBreadcrumb] = useState(() => {
        var _a, _b, _c;
        let updated = sessionBreadCrumb;
        if (sessionBreadCrumb && (sessionBreadCrumb === null || sessionBreadCrumb === void 0 ? void 0 : sessionBreadCrumb.length)) {
            const lastCategory = (_a = sessionBreadCrumb[(sessionBreadCrumb === null || sessionBreadCrumb === void 0 ? void 0 : sessionBreadCrumb.length) - 1]) === null || _a === void 0 ? void 0 : _a.category;
            if (!((_c = (_b = sessionBreadCrumb[(sessionBreadCrumb === null || sessionBreadCrumb === void 0 ? void 0 : sessionBreadCrumb.length) - 1]) === null || _b === void 0 ? void 0 : _b.category) === null || _c === void 0 ? void 0 : _c.includes(dashboardNavigation[dashboardNavigation.length - 1].category))) {
                const index = dashboardNavigation === null || dashboardNavigation === void 0 ? void 0 : dashboardNavigation.findIndex(({ category }) => lastCategory === null || lastCategory === void 0 ? void 0 : lastCategory.includes(category));
                const remaining = dashboardNavigation.filter((item, i) => i > index);
                updated = [...sessionBreadCrumb, ...remaining];
            }
        }
        return updated;
    });
    (_a = document
        .querySelector(".activeBreadcrumb")) === null || _a === void 0 ? void 0 : _a.parentElement.classList.add("activeItem");
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumb, { className: "breadcrum-bg", 
            // separator=">"
            separator: React.createElement("div", { className: "arrow" }) }, updatedBreadcrumb === null || updatedBreadcrumb === void 0 ? void 0 : updatedBreadcrumb.map(({ name, category, bandName, bandColor, parentPath }, index) => {
            if (index === sessionBreadCrumb.length - 1 || !name) {
                return (React.createElement(Breadcrumb.Item, { className: `breadcrumb-item ${!name && index !== sessionBreadCrumb.length - 1
                        ? "breadcrumb-lable"
                        : ""} ${index === sessionBreadCrumb.length - 1
                        ? "activeBreadcrumb"
                        : ""}`, key: index },
                    bandName && (React.createElement("span", { className: "breadcrmb-item-grade", style: { background: bandColor } },
                        React.createElement("span", { style: bandName === "E" ? { color: bandColor } : { color: "white" } }, bandName))),
                    React.createElement("span", { className: "breadcrumb-values" },
                        React.createElement("span", { className: "breadcrumb-level" }, category),
                        " ",
                        name && React.createElement("strong", null, name))));
            }
            else {
                return (React.createElement(Breadcrumb.Item, { key: index, className: "breadcrumb-item" },
                    React.createElement(Link, { to: () => { var _a; return (_a = browserHistory === null || browserHistory === void 0 ? void 0 : browserHistory.location) === null || _a === void 0 ? void 0 : _a.pathname; }, onClick: (event) => {
                            event.preventDefault();
                            const isSingleChild = ((category === null || category === void 0 ? void 0 : category.includes("Group")) ||
                                (category === null || category === void 0 ? void 0 : category.includes("Company"))) &&
                                parentPath.split("/").length - 1 > index + 1;
                            const filterBreadCrumbs = [
                                ...sessionBreadCrumb.slice(0, isSingleChild
                                    ? index === 0 && category.includes("Company")
                                        ? parentPath.split("/").length - 2
                                        : parentPath.split("/").length - 1
                                    : index + 1),
                            ];
                            setSessionBreadCrumb(isSingleChild
                                ? filterBreadCrumbs
                                : filterBreadCrumbs.slice(0, index + 1));
                            removeKey(SessionVariables.WATCHLIST_ITEM_LEVEL, sessionStorage);
                            setKey(BREADCRUMB, filterBreadCrumbs, sessionStorage);
                            if (SessionStorage.getKey(SessionVariables.EXPANDED_KEYS, sessionStorage) !== null) {
                                const ids = SessionStorage.getKey(BREADCRUMB, sessionStorage).map((item) => parseInt(item.key));
                                setKey(SessionVariables.EXPANDED_KEYS, ids === null || ids === void 0 ? void 0 : ids.slice(0, -1), sessionStorage);
                            }
                            history === null || history === void 0 ? void 0 : history.push(`${dashboardRoute}${isSingleChild ? parentPath : filterRoute(index)}`);
                        } },
                        React.createElement("span", { className: "breadcrmb-item-grade", style: { background: bandColor } },
                            React.createElement("span", { style: bandName === "E" ? { color: bandColor } : { color: "white" } }, bandName)),
                        React.createElement("span", { className: "breadcrumb-values" },
                            " ",
                            React.createElement("span", { className: "breadcrumb-level" }, category),
                            " ",
                            React.createElement("strong", null,
                                " ",
                                name)))));
            }
        }))));
}
export default BreadcrumbNavigation;
