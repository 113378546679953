import React, { useState } from "react";
import { AccountApi } from "../../../core/api/accountApi";
import ForgotPasswordForm from "./components/forgotPasswordForm";
export const ForgotPasswordContainer = () => {
    const [emailSent, setEmailSent] = useState("");
    function handleForgetPassword(objForgetPassword) {
        AccountApi.forgetPassword(objForgetPassword.username).then((response) => {
            if (response.code === "OK") {
                setEmailSent(response.message);
            }
        });
    }
    return (React.createElement("div", null,
        React.createElement(ForgotPasswordForm, { handleForgetPassword: handleForgetPassword, emailSent: emailSent })));
};
