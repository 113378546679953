import { GET_GROUPS_SUCCESS, UPDATE_GROUP_SUCCESS, ADD_GROUP_SUCCESS, DELETE_GROUP_SUCCESS, ACTIVATE_GROUP_SUCCESS, IN_ACTIVATE_GROUP_SUCCESS, } from "./types";
const initialState = [];
export const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GROUPS_SUCCESS: {
            return [...action.payload];
        }
        case UPDATE_GROUP_SUCCESS: {
            const editedGroup = state.map((el) => el.id !== action.payload.id ? el : action.payload);
            return [...editedGroup];
        }
        case ADD_GROUP_SUCCESS: {
            return [Object.assign({}, action.payload), ...state];
        }
        case DELETE_GROUP_SUCCESS: {
            const tempGroups = [...state];
            const index = state.findIndex((item) => item.id === action.payload.id);
            tempGroups[index].active = action.payload.status;
            return [...tempGroups];
        }
        case ACTIVATE_GROUP_SUCCESS: {
            const tempGroups = [...state].map((group) => (Object.assign(Object.assign({}, group), { active: group.id === action.payload ? true : group.active })));
            return [...tempGroups];
        }
        case IN_ACTIVATE_GROUP_SUCCESS: {
            const tempGroups = [...state].map((group) => (Object.assign(Object.assign({}, group), { active: group.id === action.payload ? false : group.active })));
            return [...tempGroups];
        }
        default: {
            return state;
        }
    }
};
