import { first, isEmpty, last, slice } from "lodash-es";
export const requiredNumericColoredRanges = (value) => {
    if (!value || isEmpty(value) || value.length === 1) {
        return "requiredColoredRanges";
    }
    const f = first(value);
    const l = last(value);
    if (!f ||
        !f.rule.from ||
        f.rule.from.value === undefined ||
        !f.rule.to ||
        f.rule.to.value === undefined ||
        !l ||
        !l.rule.from ||
        l.rule.from.value === undefined ||
        !l.rule.to ||
        l.rule.to.value === undefined) {
        return "requiredColoredRanges";
    }
    return slice(value, 1, value.length - 1).some((v) => !v.rule.from ||
        v.rule.from.value === undefined ||
        !v.rule.to ||
        v.rule.to.value === undefined)
        ? "requiredColoredRanges"
        : undefined;
};
export const validateColoredNumericRanges = (value) => {
    if (!value || !Array.isArray(value) || isEmpty(value) || value.length === 1) {
        return "invalidNumericRange";
    }
    const l = last(value);
    const sliced = value
        .map((e) => e.rule.from && e.rule.from.value)
        .concat(l && l.rule.to && l.rule.to.value)
        .filter((e) => e !== undefined);
    for (let i = 0; i < sliced.length - 1; i++) {
        if (+sliced[i] >= +sliced[i + 1]) {
            return "invalidNumericRange";
        }
    }
};
// export const requiredRangesSpacing = (
//   value?: Array<ColoredNumericRange>
// ): string | undefined => {
//   if (!value || isEmpty(value) || value.length === 1) {
//     return "requiredColoredRanges";
//   }
//   const f = first(value)!;
//   const l = last(value)!;
//   if (
//     !f.rule.to ||
//     f.rule.to.value === undefined ||
//     !l ||
//     !l.rule.from ||
//     l.rule.from.value === undefined
//   ) {
//     return "requiredColoredRanges";
//   } else if (
//     f &&
//     f.type !== "DarkGreen" &&
//     (!f || !f.rule.from || f.rule.from.value === undefined)
//   ) {
//     return "requiredColoredRanges";
//   } else if (
//     l &&
//     l.type !== "DarkGreen" &&
//     (!l.rule.to || l.rule.to.value === undefined)
//   ) {
//     return "requiredColoredRanges";
//   }
//   return slice(value, 1, value.length - 1).some((v) =>
//     v.type !== "DarkGreen"
//       ? !v.rule.to || v.rule.to.value === undefined
//       : !v.rule.from || v.rule.from.value === undefined
//   )
//     ? "requiredColoredRanges"
//     : undefined;
// };
