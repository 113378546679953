import React from "react";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import CustomTable from "../../../../../../../layout/components/customTable";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
const NotificationRulesTable = ({ data, actions }) => {
    const columns = [
        {
            fieldName: "name",
            title: ["Notification.notification", "name"],
            sortable: true,
            searchable: true,
            width: 500,
            className: "bold-text",
        },
        {
            fieldName: "recipient",
            title: ["Notification.CurrentConfiguration"],
            render: (recipient) => {
                var _a;
                const updatedRecipient = (_a = recipient === null || recipient === void 0 ? void 0 : recipient.toString()) === null || _a === void 0 ? void 0 : _a.split(",").filter((e) => e !== TrackedItemsVariables.NOTIFICATIONS_DEFAULT_BAND);
                return (React.createElement("strong", null, !isEmpty(recipient) &&
                    recipient !== TrackedItemsVariables.NOTIFICATIONS_DEFAULT_BAND
                    ? updatedRecipient.toString()
                    : undefined));
            },
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        render: actions,
        width: 250,
    };
    const displayedColumns = actions ? [...columns, actionColumn] : columns;
    return (React.createElement("div", { "data-test": "notificationTableComponent" },
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: "any", scroll: false })));
};
export default NotificationRulesTable;
