import { Badge, Menu, Button, Skeleton } from 'antd';
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { InAppNotificationApi } from "../../../../../../core/api/inAppNotificationApi";
import { notificationEventsRoute } from "../../../../../../core/constants/routesPath";
import { getMessagesDateAndTimeStringGlobal } from '../../../../../../core/helpers/dateFormatter';
import isEmpty from '../../../../../../core/helpers/isEmpty';
import localize from '../../../../../../core/utils/translation-files';
import CustomModal from "../../../../../../layout/components/customModal";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import "./styles.scss";
const NotificationColumnComponent = ({ count = null, item, onClick = undefined, }) => {
    var _a, _b;
    const color = (_b = (_a = item === null || item === void 0 ? void 0 : item.currentRatingInfoDTO) === null || _a === void 0 ? void 0 : _a.currentStatusRgb) !== null && _b !== void 0 ? _b : "gray";
    const history = useHistory();
    const handleViewAllRecentNotifications = (v) => {
        history.push(notificationEventsRoute, {
            isAllNotification: true,
            isAllNotificationId: item === null || item === void 0 ? void 0 : item.itemId,
            isRoadmapItem: false,
            selectedItemId: null,
        });
    };
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const filterOption = {
        timeFilter: null,
        inAppStatus: null,
        flagged: null,
        pageNo: 1,
        pageSize: 3,
        fromDate: null,
        toDate: null,
        category: null,
        sortByCreatedDate: "desc",
        subject: null,
        itemId: item === null || item === void 0 ? void 0 : item.itemId,
    };
    const openNotificationModal = (item) => {
        if (item === null || item === void 0 ? void 0 : item.itemId) {
            InAppNotificationApi.getNotificationList(filterOption).then((response) => {
                setData(response === null || response === void 0 ? void 0 : response.notificationsList);
                setVisible(true);
            });
        }
    };
    const onClose = (item) => {
        setVisible(false);
        setData([]);
    };
    return (React.createElement(React.Fragment, null,
        isEmpty(count) ? (React.createElement(Skeleton.Button, { active: true, size: "default" })) : (React.createElement("div", { className: "notification-column-container", style: { backgroundColor: "transparent", borderColor: "transparent" }, 
            // onClick={onClick ? onClick.bind({}, item) : undefined}
            onClick: (e) => openNotificationModal(item) },
            React.createElement(Badge, { className: "action-badge", count: count, style: { color: "white", backgroundColor: color } },
                React.createElement(RenderSvg, { icon: `NotificationIcon`, style: { color: color, fill: color, stroke: color } })))),
        React.createElement("div", null,
            React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["Dashboard.notifications"], width: "50%" },
                React.createElement(Menu, { className: "action-menu related-notifications-list col-12" },
                    (data === null || data === void 0 ? void 0 : data.length) ? (data.map((item, index) => (React.createElement(Menu.Item, { className: "action-item d-flex w-100", key: index, style: {
                            height: "auto",
                            cursor: "text",
                        } },
                        React.createElement("span", { className: "text" },
                            React.createElement("span", { className: "sub-text" },
                                React.createElement("span", null, getMessagesDateAndTimeStringGlobal(item === null || item === void 0 ? void 0 : item.createdDate))),
                            React.createElement("span", { className: "message" }, item.subject)))))) : (React.createElement("span", { className: "empty-item" }, "No Notifications")),
                    (data === null || data === void 0 ? void 0 : data.length) ? (React.createElement("div", { className: "dropdown-actions" },
                        React.createElement(Button, { type: "primary", className: "w-100", onClick: handleViewAllRecentNotifications }, localize(["Notification.viewMoreNotifications"])))) : (""))))));
};
export default NotificationColumnComponent;
