import React from "react";
import LoadeerSvg from "../../../../public/content/805.svg";
import { usePromiseTracker } from "react-promise-tracker";
import { useSelector } from "react-redux";
const LoaderComponent = () => {
    const { promiseInProgress } = usePromiseTracker(null);
    //////Show Loader while action column is hide before/after print dialog/////
    const hidePrintActionColumn = useSelector((state) => state.togglePrint);
    return (React.createElement("div", { style: {
            display: promiseInProgress === true || hidePrintActionColumn.status
                ? "block"
                : "none",
        }, className: "loading-animation-wrapper" },
        React.createElement("img", { src: LoadeerSvg, className: "loading-animation", alt: "Loading..." })));
};
export default LoaderComponent;
