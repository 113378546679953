import store from "../../redux/configureStore";
import { CompanyType } from "../enums/CompanyType";
const isActionAllowed = (requiredPermissions, company = null) => {
    var _a;
    const { companyPermissionDTOs } = (_a = store.getState()) === null || _a === void 0 ? void 0 : _a.context;
    var userPermissions = [];
    if (company) {
        if ((company === null || company === void 0 ? void 0 : company.type) === CompanyType.Site) {
            userPermissions = companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.filter((x) => {
                var _a, _b;
                return x.companyId === (company === null || company === void 0 ? void 0 : company.id) ||
                    x.companyId === (company === null || company === void 0 ? void 0 : company.parentId) ||
                    x.companyId ===
                        ((_b = (_a = store.getState()) === null || _a === void 0 ? void 0 : _a.companies.find((x) => x.id === (company === null || company === void 0 ? void 0 : company.parentId))) === null || _b === void 0 ? void 0 : _b.parentId) ||
                    x.companyId === 0;
            });
        }
        if ((company === null || company === void 0 ? void 0 : company.type) === CompanyType.Company) {
            userPermissions = companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.filter((x) => x.companyId === (company === null || company === void 0 ? void 0 : company.id) ||
                x.companyId === (company === null || company === void 0 ? void 0 : company.parentId) ||
                x.companyId === 0);
        }
        if ((company === null || company === void 0 ? void 0 : company.type) === CompanyType.Group) {
            userPermissions = companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.filter((x) => x.companyId === (company === null || company === void 0 ? void 0 : company.id) || x.companyId === 0);
        }
        return userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.some((x) => requiredPermissions.includes(x.permission));
    }
    return companyPermissionDTOs === null || companyPermissionDTOs === void 0 ? void 0 : companyPermissionDTOs.some((x) => requiredPermissions.includes(x.permission));
};
export default isActionAllowed;
