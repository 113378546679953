import { Button } from "antd";
import React, { useState } from "react";
import { WatchlistApi } from "../../../../../../core/api/watchlistApi";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import localize from "../../../../../../core/utils/translation-files";
import CustomInputField from "../../../../../../layout/components/customInputField";
import { notifications } from "../../../../../../layout/components/notifications";
import "./styles.scss";
const MAXIMUM_WATCHLIST = 10;
const NewWatchListModalComponent = ({ handleAddNewWatchList, tabsArray, }) => {
    const [name, setName] = useState(undefined);
    const handleChange = (event) => {
        setName(event.target.value);
    };
    const createWatchList = (event) => {
        event.preventDefault();
        if (tabsArray.length === MAXIMUM_WATCHLIST) {
            notifications.warning(`Only ${MAXIMUM_WATCHLIST} watchlist can be created`);
            return;
        }
        WatchlistApi.createWatchList(name).then((response) => {
            if (response === null || response === void 0 ? void 0 : response.id) {
                handleAddNewWatchList(response);
            }
        });
    };
    return (React.createElement("div", { className: "newWatchListFormContainer" },
        React.createElement(CustomInputField, { label: localize(["Watchlist Name"]), type: "text", defaultValue: name, onChange: handleChange, errorMessage: (tabsArray === null || tabsArray === void 0 ? void 0 : tabsArray.length) <= MAXIMUM_WATCHLIST &&
                (tabsArray === null || tabsArray === void 0 ? void 0 : tabsArray.some(({ watchListName }) => watchListName.toLowerCase() === (name === null || name === void 0 ? void 0 : name.toLowerCase())))
                ? `Watchlist with same name already exist!`
                : undefined }),
        React.createElement(Button, { type: "primary", onClick: createWatchList, disabled: isEmpty(name) }, "Add Watchlist")));
};
export default NewWatchListModalComponent;
