import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { Table } from "antd";
import UseRenderFilterTable from "../../../core/hooks/useRenderFilterTable";
import { defaultScrollPosition } from "../../../core/constants/tableScrollConstants";
import { useResizableColumn } from "../../../core/hooks/useResizableColumn";
import "./customTable.scss";
import ListingFooter from "../footer/ListingFooter";
import { useSelector } from "react-redux";
import { genericSearch } from "../../../core/utils/arrayUtils";
import isEmpty from "../../../core/helpers/isEmpty";
const CustomTable = ({ columns, data, clientPagination = false, pageSize, currentPage, totalCount, serverPagination = false, serverSorting = false, serverSearching = false, serverFiltering = false, disableRowProperty, scrollPosition, scroll = true, hasChildrenExpanded = true, rowClassHandler = null, rowClickHandler = null, rowClassName = null, customTablePropChange = null, expandIconHandler = null, rowStyle, stateParams, paginationSection = null, itemEnableKey = null, searchAllColumn = true, handleSetData = null, parentHandlerAllData = null, defaultExpandKeys = null, tableWrapperClassName = ``, }) => {
    const [combineFilters, setCombineFilters] = useState([""]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [resultantData, setResultantData] = useState([]);
    const handleSetTableData = (tableData) => {
        if (handleSetData)
            return handleSetData(tableData);
        setResultantData(tableData);
    };
    useEffect(() => {
        // setResultantData(data)
        handleSetTableData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    const tableData = useMemo(() => {
        return handleSetData ? data : resultantData;
    }, [resultantData, data]);
    const overAllData = useMemo(() => {
        return handleSetData ? parentHandlerAllData : data;
    }, [parentHandlerAllData, data]);
    const { getColumns, onTablePropChange } = UseRenderFilterTable({
        serverSorting,
        serverSearching,
        serverFiltering,
        pageSize,
        currentPage,
        serverPagination,
        customTablePropChange,
        stateParams,
        setCombineFilters,
        combineFilters,
        overAllData,
    });
    const { components, resizableColumns } = useResizableColumn(getColumns(columns, tableData), tableData);
    // const firstRender = useRef(true);
    const searchValue = useSelector((state) => state === null || state === void 0 ? void 0 : state.searchInput);
    const handleCustomSearch = useCallback(() => {
        // if (searchAllColumn && overAllData) {
        //   const genericSearchParameter = genericSearch(
        //     overAllData,
        //     columns,
        //     searchValue
        //   );
        //   // setResultantData(() => genericSearch(data, columns, searchValue));
        //   // customTablePropChange(null,genericSearchParameter.length);
        //   handleSetTableData(genericSearchParameter);
        //   paginationSection?.handleChangePage?.(1);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const searchText = searchValue !== null ? [searchValue] : [""];
        const [first, ...overAllFilters] = [...combineFilters];
        setCombineFilters([]);
        const finalFilters = isEmpty(overAllFilters)
            ? [...searchText]
            : [...searchText, ...overAllFilters];
        setCombineFilters(finalFilters);
    }, [searchValue, overAllData]);
    useEffect(() => {
        var _a;
        if (searchAllColumn && overAllData) {
            let tempData = [...overAllData];
            combineFilters === null || combineFilters === void 0 ? void 0 : combineFilters.forEach((item) => {
                var _a;
                let combineTempData = [];
                typeof item === "object"
                    ? (_a = item[Object.keys(item)[0]]) === null || _a === void 0 ? void 0 : _a.forEach((child) => {
                        if (child !== true && child !== false) {
                            if (Object.keys(item)[0] === "name") {
                                tempData.forEach((childItem) => {
                                    if (childItem[Object.keys(item)[0]].includes(child)) {
                                        combineTempData = [...[childItem], ...combineTempData];
                                    }
                                });
                            }
                            else if (Object.keys(item)[0] === "benchmarkList") {
                                tempData.forEach((childItem) => {
                                    var _a;
                                    let tempFilter = [];
                                    (_a = childItem === null || childItem === void 0 ? void 0 : childItem.benchmarkList) === null || _a === void 0 ? void 0 : _a.forEach((childFilter) => {
                                        tempFilter = [
                                            ...[childFilter === null || childFilter === void 0 ? void 0 : childFilter.benchmarkName],
                                            ...tempFilter,
                                        ];
                                    });
                                    if (tempFilter === null || tempFilter === void 0 ? void 0 : tempFilter.includes(child)) {
                                        if (!combineTempData.includes(childItem))
                                            combineTempData = [...[childItem], ...combineTempData];
                                    }
                                });
                            }
                            else {
                                tempData.forEach((childItem) => {
                                    if (childItem[Object.keys(item)[0]] === child) {
                                        combineTempData = [...[childItem], ...combineTempData];
                                    }
                                });
                            }
                        }
                        else {
                            // Enabled-Disabled Filter need improvement, should be dynamic instead of static conditions
                            tempData.forEach((childItem) => {
                                if (child === true &&
                                    ((childItem === null || childItem === void 0 ? void 0 : childItem[itemEnableKey]) === 1 ||
                                        (childItem === null || childItem === void 0 ? void 0 : childItem[itemEnableKey]) === 2 ||
                                        (childItem === null || childItem === void 0 ? void 0 : childItem[itemEnableKey]) === true)) {
                                    combineTempData = [...[childItem], ...combineTempData];
                                }
                                if (child === false &&
                                    ((childItem === null || childItem === void 0 ? void 0 : childItem[itemEnableKey]) === 0 ||
                                        (childItem === null || childItem === void 0 ? void 0 : childItem[itemEnableKey]) === false)) {
                                    combineTempData = [...[childItem], ...combineTempData];
                                }
                            });
                        }
                    })
                    : (tempData = genericSearch(tempData, columns, item));
                typeof item === "object" && (tempData = combineTempData);
            });
            handleSetTableData(tempData);
            customTablePropChange &&
                customTablePropChange({
                    currentDataSource: tempData,
                    action: "filter",
                });
            (_a = paginationSection === null || paginationSection === void 0 ? void 0 : paginationSection.handleChangePage) === null || _a === void 0 ? void 0 : _a.call(paginationSection, 1);
        }
    }, [combineFilters]);
    useEffect(() => {
        handleCustomSearch();
    }, [handleCustomSearch, searchValue]);
    const onTableRowExpand = (expanded, record) => {
        let updateExpandedRowKeys = [...expandedRowKeys];
        const targetKey = (record === null || record === void 0 ? void 0 : record.id) || Object.values(record)[0];
        updateExpandedRowKeys = expanded
            ? [...updateExpandedRowKeys, targetKey]
            : updateExpandedRowKeys.filter((key) => key !== targetKey);
        setExpandedRowKeys(updateExpandedRowKeys);
    };
    useEffect(() => {
        if (hasChildrenExpanded &&
            (overAllData === null || overAllData === void 0 ? void 0 : overAllData.length)
        // && firstRender.current
        ) {
            const defualtExpandedKeys = overAllData === null || overAllData === void 0 ? void 0 : overAllData.map((value) => Object.values(value)[0]);
            setExpandedRowKeys(defualtExpandedKeys);
            // firstRender.current = false;
        }
        else if (defaultExpandKeys && (overAllData === null || overAllData === void 0 ? void 0 : overAllData.length)) {
            setExpandedRowKeys([defaultExpandKeys]);
        }
    }, [hasChildrenExpanded, overAllData, defaultExpandKeys]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { id: "resizable-table", style: rowStyle, className: tableWrapperClassName, dataSource: tableData, columns: resizableColumns, expandable: Object.assign({ expandedRowKeys, onExpand: onTableRowExpand }, (expandIconHandler && {
                expandIcon: expandIconHandler,
            })), components: components, rowKey: (record) => { var _a; return (_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : Object.values(record)[0]; }, scroll: scroll && {
                y: `calc(100vh - ${scrollPosition !== null && scrollPosition !== void 0 ? scrollPosition : defaultScrollPosition}em)`,
            }, onRow: (record, index) => ({
                onClick: () => rowClickHandler && rowClickHandler(record, index),
            }), rowClassName: disableRowProperty
                ? (record) => record[disableRowProperty] === true
                    ? "table-row-light"
                    : "table-row-dark"
                : rowClickHandler
                    ? "cursor-pointer"
                    : typeof rowClassName === "function"
                        ? (record, index) => rowClassName(record, index, expandedRowKeys)
                        : rowClassName, pagination: clientPagination || serverPagination
                ? {
                    current: currentPage,
                    pageSize: pageSize !== null && pageSize !== void 0 ? pageSize : 1,
                    total: totalCount !== null && totalCount !== void 0 ? totalCount : 10,
                    position: ["bottomRight"],
                }
                : false, onChange: onTablePropChange }),
        paginationSection && (React.createElement(ListingFooter, { paginationProps: Object.assign(Object.assign({}, paginationSection), (clientPagination &&
                searchAllColumn && { totalRecords: totalCount })) }))));
};
export default React.memo(CustomTable);
