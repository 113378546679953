import React, { useEffect, useRef } from "react";
import CustomModal from "../../../layout/components/customModal";
import NotificationSettingsForm from "./components/NotificationSettingsForm";
import "../NotificationSettings/style.scss";
import { getLookups } from "../../../redux/lookup/actions";
import useDidMount from "../../../core/hooks/useDidMount";
import { getLookupByListing } from "../../../core/helpers/lookupHanlder";
import { LookupNames } from "../../../core/constants/lookup";
import { connect, useSelector } from "react-redux";
import { NotificationSettingsApi } from "../../../core/api/notificationSettingsApi";
import { NOTIFICATION_TYPE_INDIVIDUAL } from "../../../core/constants/notificationSettingsConstants";
import { notifications } from "../../../layout/components/notifications";
import { ResponseStatusCodes } from "../../../core/constants/responseStatusCodes";
import localize from "../../../core/utils/translation-files";
import isActionAllowed from "../../../core/authorization/userPermissions";
import { REPORT_CATEGORY_CARE_DELIVERY, REPORT_CATEGORY_CARE_FINANCIALS, } from "../../../core/constants/permissionConstants";
import { TrackedItemsVariables } from "../../../core/constants/TrackedItemsConstants";
const NotificationSettingsContainer = ({ notificationSettingsModal, notificationSettingsModalClose, modalTitle, modalDescription, notificationSettings, setNotificationSettings, getLookups, }) => {
    let initialState = {
        id: 0,
        notificationType: NOTIFICATION_TYPE_INDIVIDUAL,
        categoryTypeId: null,
        notificationFrequency: null,
        timeInterval: null,
        sortId: null,
    };
    const tabOptions = useSelector((state) => {
        var _a, _b;
        return (_b = (_a = state.lookups[LookupNames.LKItemCategory]) === null || _a === void 0 ? void 0 : _a.filter(({ code, text, permissionCode }) => code !== "1" &&
            ((code === TrackedItemsVariables.FinancialCategoryCode.toString() &&
                isActionAllowed(REPORT_CATEGORY_CARE_FINANCIALS)) ||
                (code !== TrackedItemsVariables.FinancialCategoryCode.toString() &&
                    code !== "1" &&
                    isActionAllowed(REPORT_CATEGORY_CARE_DELIVERY))))) !== null && _b !== void 0 ? _b : [];
    });
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [
                LookupNames.LKCollectiveNotificationFrequency,
                LookupNames.LKCollectiveNotificationSort,
                LookupNames.LKItemCategory,
            ],
            getLookups,
        });
    });
    useEffect(() => {
        if (tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) {
            if (!notificationSettings.length) {
                let settings = [];
                for (let i = 0; i < (tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length); i++) {
                    settings.push(Object.assign(Object.assign({}, initialState), { categoryTypeId: tabOptions[i].code }));
                }
                setNotificationSettings(settings);
            }
        }
        else {
            if (!notificationSettings.length)
                setNotificationSettings([initialState]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabOptions]);
    const notificationSettingsHandler = (payload, resetForm) => {
        NotificationSettingsApi.setNotificationSettings(payload).then((res) => {
            if (res &&
                (res.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION ||
                    res.code === ResponseStatusCodes.VALIDATION_ERROR)) {
                return;
            }
            else {
                resetForm();
                notificationSettingsModalClose(false);
                notifications.success(localize([
                    "Notification.notificationsSettings",
                    "updated",
                    "successfully",
                ]));
            }
        });
    };
    const valuesRef = useRef(null);
    const closeSettingsModel = (resetForm = null) => {
        var _a;
        if (!((_a = valuesRef.current) === null || _a === void 0 ? void 0 : _a.isPrompt) ||
            window.confirm("Changes you made may not be saved.")) {
            if (typeof resetForm === "function") {
                resetForm();
            }
            notificationSettingsModalClose(false);
        }
    };
    return (React.createElement("div", null,
        React.createElement(CustomModal, { visible: notificationSettingsModal, onCancel: closeSettingsModel, maskClosable: false, destroyOnClose: true, footer: null, modelClass: "notificationSettingsModal" },
            React.createElement(NotificationSettingsForm, { initialNotificationSetting: notificationSettings, onSubmit: notificationSettingsHandler, modalTitle: modalTitle, modalDescription: modalDescription, closeModal: closeSettingsModel, tabOptions: tabOptions, valuesRef: valuesRef }))));
};
const mapDispatchToProps = {
    getLookups,
};
export default connect(null, mapDispatchToProps)(NotificationSettingsContainer);
