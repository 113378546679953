import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { notificationEventsRoute } from "../../../../../core/constants/routesPath";
import { flagNotification, getLastNNotifications, readNotification, unflagNotification, unreadNotification, } from "../../../../../redux/notification/in-app/actions";
import NotificationModel from "../components/notificationModel";
import "../style.scss";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { notifications } from "../../../../../layout/components/notifications";
import RecentNotificationList from "./components/recentNotificationList";
import { defaultNoticationsNum } from "../../../../../core/constants/notification";
import { InAppStatus } from "../../../../../core/enums/InAppNotifications";
import { getUnreadNotificationsCount, updateUnreadNotificationCount, } from "../../../../../redux/notification/unreadCount/actions";
import UseLocalStorage from "../../../../../core/hooks/useLocalStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
const RecentNotificationsContainer = ({ unreadNotificationCount, getLastNNotifications, getUnreadNotificationsCount, flagNotification, updateUnreadNotificationCount, readNotification, unflagNotification, unreadNotification, }) => {
    const [notificationModelVisibility, setnotificationModelVisibility] = useState(false);
    const [recentNotifications, setRecentNotifications] = UseLocalStorage(SessionVariables.RECENT_NOTIFICATIONS, []);
    const [selectedNotification, setSelectedNotification] = useState({});
    useEffect(() => {
        getLastNNotifications(defaultNoticationsNum, true)
            .then((res) => setRecentNotifications(res))
            .catch((error) => {
            throw error;
        });
        getUnreadNotificationsCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unreadNotificationCount]);
    const history = useHistory();
    const handleViewAll = () => {
        SessionStorage.setKey(SessionVariables.NOTIFICATION_PARAMETERS, {
            roadmap: undefined,
            roadmapId: undefined,
        });
        history.push(notificationEventsRoute);
    };
    const openNotificationModal = (notification) => {
        setSelectedNotification(notification);
        setnotificationModelVisibility(true);
    };
    const readNotificationHandler = (data) => {
        const temp = [...recentNotifications].map((el) => el.id !== data ? el : Object.assign(Object.assign({}, el), { inAppStatus: InAppStatus.read }));
        setRecentNotifications(temp);
        readNotification(data).then((response) => {
            if (response) {
                updateUnreadNotificationCount(unreadNotificationCount - 1);
            }
        });
    };
    const unreadNotificationHandler = (data) => {
        const temp = [...recentNotifications].map((el) => el.id !== data ? el : Object.assign(Object.assign({}, el), { inAppStatus: InAppStatus.unread }));
        setRecentNotifications(temp);
        unreadNotification(data).then((response) => {
            if (response) {
                updateUnreadNotificationCount(unreadNotificationCount + 1);
            }
        });
    };
    const flagNotificationHandler = (data) => {
        const temp = [...recentNotifications].map((el) => el.id !== data ? el : Object.assign(Object.assign({}, el), { flagged: true }));
        setRecentNotifications(temp);
        if (selectedNotification) {
            setSelectedNotification(Object.assign(Object.assign({}, selectedNotification), { flagged: true }));
        }
        flagNotification(data).then((response) => {
            if (response) {
                if (!isEmpty(response.Message)) {
                    notifications.success(response.Message);
                }
            }
        });
    };
    const unflagNotificationHandler = (data) => {
        const temp = [...recentNotifications].map((el) => el.id !== data ? el : Object.assign(Object.assign({}, el), { flagged: false }));
        setRecentNotifications(temp);
        if (selectedNotification) {
            setSelectedNotification(Object.assign(Object.assign({}, selectedNotification), { flagged: false }));
        }
        unflagNotification(data).then((response) => {
            if (response) {
                if (!isEmpty(response.Message)) {
                    notifications.success(response.Message);
                }
            }
        });
    };
    const closeNotificationModel = () => setnotificationModelVisibility(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(RecentNotificationList, { data: recentNotifications, openNotificationModal: openNotificationModal, handleViewAll: handleViewAll, count: unreadNotificationCount, flagNotification: flagNotificationHandler, unflagNotification: unflagNotificationHandler, readNotification: readNotificationHandler, unreadNotification: unreadNotificationHandler }),
        React.createElement(NotificationModel, { visible: notificationModelVisibility, onClose: closeNotificationModel, selectedNotification: selectedNotification, flagNotification: flagNotificationHandler, unflagNotification: unflagNotificationHandler })));
};
function mapStateTopProps(state) {
    return {
        unreadNotificationCount: state.unreadNotificationCount,
    };
}
const mapDispatchToProps = {
    getLastNNotifications,
    getUnreadNotificationsCount,
    updateUnreadNotificationCount,
    flagNotification,
    readNotification,
    unflagNotification,
    unreadNotification,
};
export default connect(mapStateTopProps, mapDispatchToProps)(RecentNotificationsContainer);
