var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo, useState } from "react";
import { Checkbox, Input } from "antd";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import { trackedItemTypesListingRoute } from "../../../../../../../core/constants/routesPath";
import MovingAveragesAndProjections from "./MovingAveragesAndProjections";
import TrackedItemTypeBenchmark from "./trackedItemTypeBenchmark";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
import { isUnique } from "../../../../../../../core/validations/uniqueFieldValidations";
import localize from "../../../../../../../core/utils/translation-files";
import { TrackedItemVariables } from "./trackedItemVariables";
import { inputDefaultLength, maxTextAreaLength, } from "../../../../../../../core/utils/default-length";
import UsePrintPage from "../../../../../../../core/hooks/usePrintPage";
import LookupChildDropdown from "../../../../../../../layout/components/select/LookupChildDropdown";
import { isPositiveValue } from "../../../../../../../core/validations/valuesComparison";
import PromptComponent from "../../../../../../../layout/components/prompt-message";
import isEmpty, { arePropertiesEmpty, } from "../../../../../../../core/helpers/isEmpty";
import FormActionButtons from "../../../../../../../layout/components/common/FormsActionButtons";
import { useHistory } from "react-router-dom";
import Asterik from "../../../../../shared/helper-components/Asterik";
import Relations from "./relations";
import { BenchmarkDirectionType } from "../../../../../../../core/enums/benchmarkDirection";
const TrackedItemTypeForm = ({ intialTrackedItemType, onSubmit, trackedItemTypes, benchmarkListOptions, isSystemCreated, }) => {
    const [key, setKey] = useState(0);
    const [direction, setDirection] = useState(intialTrackedItemType.requiredBenchmarkDirection);
    const [existingBenchmarkError, setExistingBenchmarkError] = useState({
        0: "",
    });
    const [rangeErrors, setRangeErrors] = useState({});
    let validationShape = {
        type: Yup.string()
            // .test(
            //   "len",
            //   localize(["maximum", "10", "characters", "areAllowed!"]),
            //   (val) => (val ? val.length <= 10 : 0 < 1)
            // )
            .required(localize(["type", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"]))
            .test("check", localize([
            "TrackedItem.trackedItem",
            "TrackedItem.default",
            "alreadyExist!",
        ]), function (value) {
            return isUnique(value, trackedItemTypes, intialTrackedItemType, "type");
        }),
        weight: Yup.number().required(localize(["TrackedItem.weight", "isRequired!"])),
        companyWeight: Yup.number().required(localize(["Company.company", "TrackedItem.weight", "isRequired!"])),
        groupWeight: Yup.number().required(localize(["Group.group", "TrackedItem.weight", "isRequired!"])),
        dataFrequency: Yup.number().required(localize(["TrackedItem.dataFrequency", "isRequired!"])),
        shortTermMovingAveragePeriod: Yup.number()
            .positive()
            .label(localize(["TrackedItem.shortTerm"]))
            .min(1)
            .required(localize([
            "TrackedItem.shortTerm",
            "TrackedItem.movingAverage",
            "isRequired!",
        ]))
            .when(["midTermMovingAverage"], (midTermMovingAverage, schema) => {
            if (midTermMovingAverage !== undefined) {
                return schema.max(midTermMovingAverage - 0.1, localize([
                    "TrackedItem.shortTerm",
                    "mustBe",
                    "lessThan",
                    "TrackedItem.midTerm",
                ]));
            }
        }),
        midTermMovingAverage: Yup.number()
            .positive()
            .label(localize(["TrackedItem.midTerm"]))
            .min(1)
            .required(localize(["TrackedItem.midTerm", "isRequired!"]))
            .when(["longTermMovingAverage"], (longTermMovingAverage, schema) => {
            if (longTermMovingAverage !== undefined) {
                return schema.max(longTermMovingAverage - 0.1, localize([
                    "TrackedItem.midTerm",
                    "mustBe",
                    "lessThan",
                    "TrackedItem.longTerm",
                ]));
            }
        }),
        longTermMovingAverage: Yup.number()
            .positive()
            .min(1)
            .label(localize(["TrackedItem.longTerm"]))
            .required(localize(["TrackedItem.longTerm", "isRequired!"])),
        projectionAverageUsed: Yup.number()
            .nullable()
            .when(["projectionRequired"], {
            is: (projectionRequired, id) => projectionRequired === true,
            then: Yup.number()
                .positive()
                .min(0)
                .required(localize([
                "TrackedItem.projection",
                "TrackedItem.used",
                "isRequired!",
            ])),
        }),
        projectionPoint: Yup.number()
            .nullable()
            .label(localize(["TrackedItem.projection", "TrackedItem.point"]))
            .when(["projectionRequired"], {
            is: (projectionRequired, id) => projectionRequired === true,
            then: Yup.number()
                .positive()
                .min(1)
                .required(localize([
                "TrackedItem.projection",
                "TrackedItem.point",
                "isRequired!",
            ])),
        }),
        reactionTime: Yup.number()
            .nullable()
            .label(localize(["TrackedItem.reactionTime"]))
            .when(["projectionRequired"], {
            is: (projectionRequired, id) => projectionRequired === true,
            then: Yup.number()
                .positive()
                .min(1)
                .required(localize(["TrackedItem.reactionTime", "isRequired!"])),
        }),
        requiredBenchmarkDirection: Yup.number().required(localize([
            "TrackedItem.required",
            "TrackedItem.benchmark",
            "TrackedItem.Direction",
            "isRequired!",
        ])),
        name: Yup.string()
            .trim()
            .required(localize([
            "TrackedItem.trackedItem",
            "TrackedItem.default",
            "name",
            "isRequired!",
        ]))
            // .matches(reportNameRegex.regex, reportNameRegex.message)
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"]))
            .test("check", localize([
            "TrackedItem.trackedItem",
            "TrackedItem.default",
            "name",
            "alreadyExist!",
        ]), function (value) {
            return isUnique(value, trackedItemTypes, intialTrackedItemType);
        }),
        description: Yup.string()
            .trim()
            .test("len", localize(["mustBe", "lessThan", `${maxTextAreaLength}`, "characters"]), (val) => (val ? val.length <= maxTextAreaLength : 0 < 1))
            .nullable(),
        format: Yup.number().required(localize(["TrackedItem.trackedItemFormat", "isRequired!"])),
        itemCategoryId: Yup.number().required(localize(["TrackedItem.category", "isRequired!"])),
        itemSubCategoryId: Yup.number()
            .required(localize(["TrackedItem.subCategory", "isRequired!"]))
            .nullable(),
        itemRiskLevelId: Yup.number().required(localize(["TrackedItem.levelOfRisk", "isRequired!"])),
        yaxisLabel: Yup.string()
            .nullable()
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        benchmarkList: direction === BenchmarkDirectionType.Middle
            ? Yup.array()
                .of(Yup.object({
                benchmarkCode: Yup.string().required(localize(["name", "isRequired!"])),
                gradeRanges: Yup.array()
                    .label(localize(["TrackedItem.rating"]))
                    .required(localize(["TrackedItem.rating", "isRequired!"]))
                    .min(7, localize([
                    "TrackedItem.needAtLeast",
                    "7",
                    "TrackedItem.grades",
                ])),
            }))
                .min(1, localize(["TrackedItem.needAtLeastOneBenchmark!"]))
            : Yup.array()
                .of(Yup.object({
                benchmarkCode: Yup.string().required(localize(["name", "isRequired!"])),
                gradeRanges: Yup.array()
                    .label(localize(["TrackedItem.rating"]))
                    .required(localize(["TrackedItem.rating", "isRequired!"]))
                    .min(4, localize([
                    "TrackedItem.needAtLeast",
                    "4",
                    "TrackedItem.grades",
                ])),
            }))
                .min(1, localize(["TrackedItem.needAtLeastOneBenchmark!"])),
        // associatedTrackedItems: Yup.array().of(
        //   Yup.object({
        //     itemName: Yup.string().required(
        //       localize(["TrackedItem.itemName", "isRequired!"])
        //     ),
        //   })
        // ),
        valueType: Yup.number().required(localize(["TrackedItem.valueType", "isRequired!"])),
        chartUnit: Yup.number().when("valueType", {
            is: (valueType) => valueType !== TrackedItemsVariables.TRACKED_ITEM_VALUE_PERCENTAGE,
            then: Yup.number().required(localize(["TrackedItem.label", "isRequired!"])),
        }),
        decimalPlaces: Yup.number().required(localize(["TrackedItem.decimalPlaces", "isRequired!"])),
        displayValue: Yup.number().required(localize(["TrackedItem.dispalyValue", "isRequired!"])),
    };
    const validationSchema = Yup.object().shape(validationShape
    //   , [
    //   "maxValue",
    //   "minValue",
    // ]
    );
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: intialTrackedItemType,
        onSubmit: (values, { setErrors, resetForm }) => {
            var _a, _b;
            const newValues = JSON.parse(JSON.stringify(values));
            if (arePropertiesEmpty(existingBenchmarkError) &&
                arePropertiesEmpty(rangeErrors)) {
                if ((_a = newValues === null || newValues === void 0 ? void 0 : newValues.benchmarkList) === null || _a === void 0 ? void 0 : _a.length) {
                    newValues.benchmarkList = (_b = values.benchmarkList) === null || _b === void 0 ? void 0 : _b.map((benchmark) => {
                        const { gradeRanges } = benchmark, rest = __rest(benchmark, ["gradeRanges"]);
                        return rest;
                    });
                }
                if (values.projectionRequired === false) {
                    newValues.projectionAverageUsed = undefined;
                    newValues.projectionPoint = undefined;
                    newValues.reactionTime = undefined;
                }
                onSubmit(newValues, setErrors, resetForm);
            }
        },
    });
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, resetForm, setFieldValue, dirty, setErrors, setValues, } = formik;
    const [isFormDirty, setFormDirty] = useState(false);
    const onValueTypeChange = (value) => {
        setValues(Object.assign(Object.assign({}, values), { valueType: value }));
    };
    const onWeightChange = (value) => {
        setValues(Object.assign(Object.assign({}, values), { weight: value }));
    };
    const history = useHistory();
    const { handlePrint, nodeRef } = UsePrintPage(false);
    const OnChangeParentCategory = (value) => {
        setFieldValue("itemCategoryId", value);
        setFieldValue("itemSubCategoryId", null);
    };
    const handlSetFieldValue = (event) => {
        const { name, value } = event.target;
        let updatedValue = value;
        if (isEmpty(value)) {
            updatedValue = undefined;
        }
        else if (isPositiveValue(value)) {
            updatedValue =
                (value === null || value === void 0 ? void 0 : value.length) === String(Number(value)).length ? Number(value) : value;
        }
        if (name.includes(".")) {
            setFieldValue(name, updatedValue);
            return;
        }
        setValues((prev) => (Object.assign(Object.assign({}, prev), { [name]: updatedValue })));
    };
    const isDirty = useMemo(() => dirty || isFormDirty, [dirty, isFormDirty]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FormikProvider, { value: formik },
            React.createElement(PromptComponent, { isPrompt: isDirty }),
            React.createElement("form", { "data-test": "tracked-item-type-form", className: "mb-1 customForm", name: "basic", onSubmit: handleSubmit, key: key, ref: nodeRef, id: "my-form" },
                React.createElement(Input, { name: "id", type: "hidden" }),
                React.createElement("div", { className: "box-main-section" },
                    React.createElement("section", { className: "box-section" },
                        React.createElement("div", { className: "box-title" },
                            React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.trackedItem", "information"]))),
                        React.createElement("div", { className: "box-description" },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-md-3", hidden: isSystemCreated },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["TrackedItem.apiId"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(Input, { type: "text", name: "type", placeholder: localize(["TrackedItem.apiId"]), onBlur: handleBlur, onChange: handleChange, value: values.type, className: `${errors.type && touched.type ? "input-error" : null}` }),
                                        errors.type && touched.type && (React.createElement("p", { className: "error" }, errors.type)))),
                                React.createElement("div", { className: "col-md-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["name"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(Input, { type: "text", name: "name", placeholder: localize(["name"]), onBlur: handleBlur, onChange: handleChange, value: values.name, className: `${errors.name && touched.name ? "input-error" : null}`, disabled: isSystemCreated }),
                                        errors.name && touched.name && (React.createElement("p", { className: "error" }, errors.name)))),
                                React.createElement("div", { className: "col-3", hidden: isSystemCreated },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["TrackedItem.format"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(LookupDropDown, { allowClear: false, className: "mb-2 mb-md-0", selectedValue: values.format, onChange: setFieldValue, inputName: "format", localizationKey: ["TrackedItem.format"], lookUpType: LookupNames.LKReportType, error: errors.format && touched.format ? errors.format : null }))),
                                React.createElement("div", { className: "col-3", hidden: isSystemCreated },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["TrackedItem.category"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.itemCategoryId === -1
                                                ? ""
                                                : values.itemCategoryId, customOnChangeHandler: OnChangeParentCategory, localizationKey: ["TrackedItem.category"], lookUpType: LookupNames.LKItemCategory, error: errors.itemCategoryId && touched.itemCategoryId
                                                ? errors.itemCategoryId
                                                : null }))),
                                React.createElement("div", { className: "col-3", hidden: isSystemCreated },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null,
                                                localize(["TrackedItem.subCategory"]),
                                                " ",
                                                React.createElement(Asterik, null))),
                                        React.createElement(LookupChildDropdown, { parentId: values.itemCategoryId, className: "mb-2 mb-md-0", selectedValue: values.itemSubCategoryId === -1
                                                ? ""
                                                : values.itemSubCategoryId, onChange: setFieldValue, inputName: "itemSubCategoryId", localizationKey: ["TrackedItem.subCategory"], parentLookUpType: LookupNames.LKItemCategory, error: errors.itemSubCategoryId && touched.itemSubCategoryId
                                                ? errors.itemSubCategoryId
                                                : null }))),
                                React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null, localize(["TrackedItem.levelOfRisk"]))),
                                        React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.itemRiskLevelId, onChange: setFieldValue, inputName: "itemRiskLevelId", localizationKey: ["TrackedItem.levelOfRisk"], lookUpType: LookupNames.LKItemRiskLevel, error: errors.itemRiskLevelId && touched.itemRiskLevelId
                                                ? errors.itemRiskLevelId
                                                : null }))),
                                React.createElement("div", { className: "col-md-6 descriptionField", hidden: isSystemCreated },
                                    React.createElement("div", { className: "form-group" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null, localize(["TrackedItem.trackedItem", "description"]))),
                                        React.createElement(TextArea, { name: "description", placeholder: localize(["description"]), onBlur: handleBlur, onChange: handleChange, value: values.description, className: `${errors.description && touched.description
                                                ? "input-error"
                                                : null}` }),
                                        errors.description && touched.description && (React.createElement("p", { className: "error" }, errors.description)))),
                                React.createElement("div", { className: "col-3" },
                                    React.createElement("div", { className: values.id === 0 ? "form-group" : "form-group d-none" },
                                        React.createElement("div", { className: "ant-col ant-form-item-label" },
                                            React.createElement("label", null, localize(["TrackedItem.disabled"]))),
                                        React.createElement(Checkbox, { name: "disabled", checked: values.disabled, onChange: handleChange }))))))),
                React.createElement(MovingAveragesAndProjections, { handlSetFieldValue: handlSetFieldValue, setFieldValue: setFieldValue, values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched, isSystemCreated: isSystemCreated }),
                React.createElement(TrackedItemVariables, { handlSetFieldValue: handlSetFieldValue, setFieldValue: setFieldValue, values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched, isSystemCreated: isSystemCreated, onValueTypeChange: onValueTypeChange, onWeightChange: onWeightChange }),
                !isSystemCreated && (React.createElement(Relations, { relationsList: values.associatedTrackedItems, setFieldValue: setFieldValue, type: values.type, frequency: values.dataFrequency, setFormDirty: setFormDirty })),
                React.createElement(TrackedItemTypeBenchmark, { setFieldValue: setFieldValue, handlSetFieldValue: handlSetFieldValue, values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched, benchmarkListOptions: benchmarkListOptions, setErrors: setErrors, setExistingBenchmarkError: setExistingBenchmarkError, existingBenchmarkError: existingBenchmarkError, setDirection: setDirection, isSystemCreated: isSystemCreated, rangeErrors: rangeErrors, setRangeErrors: setRangeErrors })),
            React.createElement(FormActionButtons, { onCancel: () => history.push(trackedItemTypesListingRoute), fromId: "my-form", isEdit: !isDirty || intialTrackedItemType.id > 0, isDirty: !isDirty, resetForm: resetForm, setKey: setKey, printOnClick: handlePrint, dataTest: "siteFormComponent" }))));
};
export default TrackedItemTypeForm;
