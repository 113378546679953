import { GET_GROUPS_SUCCESS, UPDATE_GROUP_SUCCESS, ADD_GROUP_SUCCESS, DELETE_GROUP_SUCCESS, IN_ACTIVATE_GROUP_SUCCESS, ACTIVATE_GROUP_SUCCESS, } from "./types";
import { GroupApi } from "../../core/api/groupApi";
import { clearGroupOptionSuccess } from "../groupOption/actions";
export const getGroupsSuccess = (data) => ({
    type: GET_GROUPS_SUCCESS,
    payload: data,
});
export const addGroupSuccess = (data) => ({
    type: ADD_GROUP_SUCCESS,
    payload: data,
});
export const updateGroupSuccess = (data) => ({
    type: UPDATE_GROUP_SUCCESS,
    payload: data,
});
export const deleteGroupSuccess = (data, status) => ({
    type: DELETE_GROUP_SUCCESS,
    payload: {
        id: data,
        status,
    },
});
export const inActivateGroupSuccess = (data) => ({
    type: IN_ACTIVATE_GROUP_SUCCESS,
    payload: data,
});
export const activateGroupSuccess = (data) => ({
    type: ACTIVATE_GROUP_SUCCESS,
    payload: data,
});
export function getGroups() {
    return function (dispatch) {
        return GroupApi.getGroups()
            .then((groups) => {
            if ((groups === null || groups === void 0 ? void 0 : groups.length) > 0) {
                dispatch(getGroupsSuccess(groups));
                return groups;
            }
        })
            .catch((error) => {
            return error;
        });
    };
}
export function addGroup(group) {
    return function (dispatch) {
        return GroupApi.createGroup(group)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(addGroupSuccess(response));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            return error;
        });
    };
}
export function editGroup(group) {
    return function (dispatch) {
        return GroupApi.updateGroup(group)
            .then((response) => {
            if (response.code) {
                return response;
            }
            dispatch(getGroups());
            dispatch(clearGroupOptionSuccess());
            // dispatch(updateGroupSuccess(response));
        })
            .catch((error) => {
            return error;
        });
    };
}
export function activateGroup(data) {
    return function (dispatch) {
        return GroupApi.activateGroup(data)
            .then((id) => {
            dispatch(activateGroupSuccess(data));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            throw error;
        });
    };
}
export function inActivateGroup(data) {
    return function (dispatch) {
        return GroupApi.inActivateGroup(data)
            .then((id) => {
            dispatch(inActivateGroupSuccess(data));
            dispatch(clearGroupOptionSuccess());
        })
            .catch((error) => {
            throw error;
        });
    };
}
