import React from "react";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import { TrackedItemsVariables } from "../../../../../../../core/constants/TrackedItemsConstants";
import localize from "../../../../../../../core/utils/translation-files";
import DecimalPlacesDropdown from "../../../../../../../layout/components/select/DecimalPlacesDropdown";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import Asterik from "../../../../../shared/helper-components/Asterik";
export const ChartVariables = ({ setFieldValue, values, handleBlur, handleChange, errors, touched, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "box-main-section" },
            React.createElement("section", { className: "box-section" },
                React.createElement("div", { className: "box-title" },
                    React.createElement("div", { className: "box-heading" }, localize(["TrackedItem.chartVariables"]))),
                React.createElement("div", { className: "box-description" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-3" },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null, localize(["TrackedItem.valueType"]))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.valueType, inputName: "valueType", localizationKey: ["TrackedItem.valueType"], lookUpType: LookupNames.LKReportValueType, disabled: true, error: errors.valueType && touched.valueType
                                        ? errors.valueType
                                        : null }))),
                        React.createElement("div", { className: "col-md-3", hidden: values.valueType === undefined ||
                                values.valueType ===
                                    TrackedItemsVariables.TRACKED_ITEM_VALUE_PERCENTAGE },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.labels"]),
                                        React.createElement(Asterik, { hidden: values.valueType ===
                                                TrackedItemsVariables.TRACKED_ITEM_VALUE_PERCENTAGE }))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.chartUnit, onChange: setFieldValue, inputName: "chartUnit", localizationKey: ["TrackedItem.label"], lookUpType: LookupNames.LKChartDisplayUnit, error: errors.chartUnit && touched.chartUnit
                                        ? errors.chartUnit
                                        : null }))),
                        React.createElement("div", { className: "col-md-3", hidden: values.valueType === undefined },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null,
                                        localize(["TrackedItem.decimalPlaces"]),
                                        React.createElement(Asterik, null))),
                                React.createElement(DecimalPlacesDropdown, { selectedValue: values.decimalPlaces, setSelectedValue: (value) => setFieldValue("decimalPlaces", value) }),
                                errors.decimalPlaces && touched.decimalPlaces && (React.createElement("p", { className: "error" }, errors.decimalPlaces)))),
                        React.createElement("div", { className: "col-md-3", hidden: values.valueType === undefined },
                            React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col ant-form-item-label" },
                                    React.createElement("label", null, localize(["TrackedItem.displayValue"]))),
                                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.displayValue, onChange: setFieldValue, inputName: "displayValue", localizationKey: ["TrackedItem.displayValue"], lookUpType: LookupNames.LKDisplayValue, error: errors.displayValue && touched.displayValue
                                        ? errors.displayValue
                                        : null })))))))));
};
export default ChartVariables;
